import React, { useState } from 'react'
import styles from './contractItemEdit.module.css'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
// import UniPopUp from '../../../../../../../popups/uniPopUp/uniPopUp'
import { isDraftObject, isMongo } from '../../../../../../../../utils/check'
import Input from '../../../../../../../inputs/input'
import InputTextArea from '../../../../../../../inputs/inputTextArea'
import InputSelect from '../../../../../../../inputs/inputSelect'
import InputAmount from '../../../../../../../inputs/inputAmount'
import InputDate from '../../../../../../../inputs/inputDate'
import Button from '../../../../../../../elements/buttons/button'
import { urlPostLeaseContractDrafts } from '../../../../../../../../utils/endPoints'
import { postLeaseContract } from '../../../../../../../../utils/contracts/contractsUtils'
import AddDocPopUp from '../../../../../../../elements/documents/addDocPopup/addDocPopup'
    const LeaseContractItemAdd = ({ data, close }) => {

    const token = useSelector( state => state.start.token );
    const objectData = useSelector(state => state.start.objectGet);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [ popAddDoc, setPopAddDoc ] = useState( false )
    // const [deletePopUp, setDeletePopUp] = useState(false)
    const [ contractNumber, setContractNumber ] = useState( !!data?.contractNumber ? data.contractNumber : '' )
    const [ title, setTitle ] = useState( !!data?.title ? isMongo(data.title) : '' )
    const [ description, setDescription ] = useState( !!data?.description ? isMongo(data.description) : '' )
    const [ contractMethod, setContractMethod ] = useState( !!data?.contractMethod ? data.contractMethod : '' )
    // const [ value, setValue ] = useState( !!data?.value ? data.value : { currency: 'UAH', amount: '', valueAddedTaxIncluded: true } )
    // const [ dateSigned, setDateSigned ] = useState( !!data?.dateSigned ? data.dateSigned : null )
    // const [ contractTime, setContractTime ] = useState( !!data?.dateSigned ? data.dateSigned : { dateFrom: null, dateTill: null } )
    // const [ status, setStatus ] = useState( !!data?.status ? data.status : 'pending' )

    const body = {
        contractNumber: contractNumber,
        contractMethod: contractMethod,
        // status: status,
        title: { uk_UA: title },
        description: { uk_UA: description },
        buyers: []
    }

    async function contractPost( body ){
        await postLeaseContract( {
            url: urlPostLeaseContractDrafts( objectData.id ),
            dispatch: dispatch,
            body: body,
            redirect: ( id )=> navigate(`/lease/create/${objectData.id}/contracts`),
            token: token.access_token
        })
        close()
    }

    // const contractStatusList = [
    //     {value: "pending", name: "Очікує публікації" },
    //     { value: "inactive", name: "Недіючий" },
    //     {value: "cancelled",name: "Розірвано" },
    //     { value: "active", name: "Діючий" }
    // ]

    const contractMethodList = [
        { value: "signedBeforeETS", name: "Публікація договорів оренди укладених до введення в дію переліків в ЕТС" },
        { value: "listTypeSecond", name: "Передача в оренду з переліку другого типу" },
        { value: "extension", name: "Продовження договору" },
        { value: "auction", name: "Передача в оренду через аукціон" }
    ]

    return (
        <div className={styles.wrapper}>
            <div className={styles.title}>Створення чернетки договору</div>
            <Input 
                label="Назва"
                value={ title }
                onChange={ event => setTitle( event ) }
                reqiured
            />
            <InputTextArea
                label="Опис"
                value={ description }
                onChange={ event => setDescription( event ) }
                reqiured
            />
            <Input 
                label="Номер договору"
                value={ contractNumber }
                onChange={ event => setContractNumber( event ) }
                reqiured
            />
            <InputSelect 
                label={"Метод укладання договору"}
                value={ contractMethod }
                onChange={ event => setContractMethod(event) }
                options={ contractMethodList }
            />
            <div className={styles.postButtonsWrap}>
                <Button onClick={ ()=> contractPost( body ) }>Створити чернетку</Button>
                <Button color='gray' 
                    onClick={ ()=> {
                        close()
                        window.scrollTo(0, 0)
                         }
                    }
                >
                    Відмінити
                </Button>
            </div>
        </div>
    )
}

export default LeaseContractItemAdd