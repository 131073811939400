import React, { useState } from 'react';
import styles from '../elems.module.css';
import FullInfoBlock from '../../../../../elements/fullInfoBlock/fullInfoBlock';
import HalfInfoBlock from '../../../../../elements/halfInfoBlock/halfInfoBlock';
import { getIdentifierType, isMongo } from '../../../../../../utils/check';
import ContactPoint from '../../../../../elements/contactPoint/contactPoint';
import Button from '../../../../../elements/buttons/button';
import AddBuyer from './addBayer/addBuyer';

const BuyerEditItem = ({ title, data, setData, index, edit, editUser, contractId }) => {
    
    const [ editPopup, setEditPopup ] = useState( false )

    return (
            <div className={styles.organizationWrap} >
                 <h3>{ title }</h3>
                <div className={styles.infoBlock}>
                    <div className={styles.infoBlockTitle}>
                    <div className={styles.organizationTitle}>{isMongo(data?.name)}</div>
                    {editUser
                        ? <div>
                            <Button onClick={ ()=> setEditPopup( true ) } >
                                Редагувати
                            </Button>
                        </div>
                        : null
                    }
                    </div>
                    <div className={styles.infoBlockContent}>
                        { !!data?.identifier && <HalfInfoBlock title={ getIdentifierType(data.identifier.scheme) } data={data.identifier.id} /> }
                        { !!data?.representativeInfo && <HalfInfoBlock title={ 'Інформація щодо підтвердження повноважень' } data={data.representativeInfo} /> }
                        <FullInfoBlock 
                            title="Адреса організації" 
                            data={`${data.address.postalCode === null ? '' : (data.address.postalCode + ", ")}
                                ${isMongo(data.address.countryName)}, 
                                ${isMongo(data.address.region)}, 
                                ${isMongo(data.address.locality)}, 
                                ${isMongo(data.address.streetAddress)}`
                            } 
                        />
                        { data?.contactPoint && <ContactPoint data={data.contactPoint} /> }
                    {/* <HalfInfoBlock title="КОАТУУ" data={data?.address?.addressID?.id} /> */}
                    { editPopup &&
                        <AddBuyer data={data} setData={ setData } index={index} contractId={contractId} close={ ()=> setEditPopup( false ) } />
                    }
                    </div>
                </div>
            </div>
        )
}

export default BuyerEditItem