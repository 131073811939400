import styles from './myAssets.module.css';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import AssetTabItem from './myAssetTabItem';
import Loader from '../../../loader/loader';
import PaginatorSimple from '../../paginator/paginatorSimple';
import { checkProp } from '../../../../utils/check';
import { getMyAssetsList } from '../../../../utils/endPoints';
import { assetsGetMy } from '../../../../redux/asset/assetActions';
// import Paginator from './paginator/paginator';

const MyAssetsHeader = () => {
    return (
        <>
            <div className={styles.myObjectsTable}>
                <div className={styles.tabHead}>
                    <div className={styles.thName}>Назва</div>
                    <div className={styles.thStat}>Ідентифікатор об'єкта</div>
                    <div className={styles.thStatus}>Статус</div>
                    <div className={styles.thRent}>Дата публікації</div>
                    <div className={styles.thActions}>Дії</div>
                </div>
            </div>
        </>
    )
}

const MyAssets = () => {
    const dispatch = useDispatch(); 
    const token = useSelector(state => state.start.token);
    const userProfile = useSelector(state => state.start.userProfiles);
    const myAssetsList = useSelector(state => state.asset.assetsMy);

    const navigate = useNavigate()

    useEffect(() => {
            dispatch( assetsGetMy( getMyAssetsList("assets") , token.access_token) );
        return () => {
            dispatch({type: 'ASSETS_GET_MY', payload: null})
        }
    }, []);

    function setObjectsList(list) {
        let objectsList;
        if (list !== null && typeof list !== 'undefined') {
            if (list.length > 0) {
                objectsList = list.map( item => {
                    return <AssetTabItem 
                                key={ item.publishedId ? item.publishedId : item.draftId }
                                id={ item.publishedId ? item.publishedId : item.draftId }
                                data={ item.publishedId ? item.publishedObject : item.draftObject } 
                            />
            });
            } else {
                objectsList = <div className={styles.tableEmpty}>У Вас немає жодного об'єкта.</div>
            }
        } else {
            objectsList = <Loader />
        }
        return objectsList
    }

    // if ( !!userProfile && userProfile.canCreateAssets === false ) return navigate("/asset/assets_list")
    // if( !myAssetsList ) return null

    return (
        <div className={styles.wrapper}>
            <div className={styles.titleWrapper}>
                <div className={styles.title}>Мої об’єкти малої приватизації</div>
                { checkProp(userProfile, 'canCreateAssets') && !!userProfile.canCreateAssets ? 
                    <Link
                        to={'/asset/create'}
                        className={styles.buttonCreate}
                    >
                        Створити об’єкт
                    </Link> : null 
                }
            </div>
            <div className={styles.tableContent}>
                <MyAssetsHeader />
                { setObjectsList( myAssetsList ) }
                <PaginatorSimple
                    data={ myAssetsList }
                    url={ getMyAssetsList("assets") }
                    get={ assetsGetMy }
                />
            </div>
        </div>
    )
}

export default MyAssets