import React, {useState, useEffect, useRef} from 'react';
import styles from './inputAmount.module.css';
import Checkbox from './checkBox';
import InputInfo from './inputInfoBlock';

// { currency: 'UAH', amount: '', valueAddedTaxIncluded: true }  <- initial state needed

const InputAmount = ( { value, onChange, title, tax, disabled, ...props } ) => {
    const [label, setLabel] = useState(props.required ? `${props.label} *` : props.label)
    // const [alertStyle, setAlertStyle] = useState(props.error ? styles.alert : styles.inPutSelect)

    const amount = useRef()

    return (
        <div className={styles.inPutWrap}>
            <div className={styles.title}>{props.required ? title + ' *' : title}</div>
            <div className={styles.content}>
                <div className={styles.inPutSelectWrap}>
                <label className={styles.labelCur} htmlFor='#currency'>Валюта</label>
                    {/* <div className={styles.inPutSelect}> */}
                        <select className={styles.inPutSelect} id='currency'
                            value={ value.currency }
                            // onChange={ e => setCurrency( e.target.value) }
                            onChange={ e => onChange( prev => ({...prev, currency: e.target.value })) }
                            disabled={ props.enableCurrent ? false : true }
                        >
                            <option value="UAH" key='UAH' default>Гривня</option>
                            <option value="USD" key='USD' >Долар США</option>
                            <option value="EUR" key='EUR' >Євро</option>
                        </select>
                    {/* </div> */}
                </div>
                <div style={{display: 'flex', flexFlow: 'column'}}>
                    <label className={styles.label} htmlFor={amount}>
                        { !!props.label ? label : 'Сума' }
                    </label>
                    <input
                        ref={amount}
                        value={ value.amount }
                        disabled={disabled}
                        className={disabled ? styles.disabled : styles.inPutAmount}
                        type='number'
                        onInput={e => {
                            let reg=/[a-zA-Zа-яА-Яа-яіІїЇєЄ]/;
                            let reg2=/^\d*(\.\d{0,2})?$/;
                            // if( reg.test(e.target.value) !== true && reg2.test(e.target.value) ) setValue(e.target.value)
                            if( reg.test(e.target.value) !== true && reg2.test(e.target.value) ) onChange( prev => ({...prev, amount: +e.target.value }))
                        }}
                    />
                </div>
                { ( tax && value.hasOwnProperty('valueAddedTaxIncluded') ) &&
                    <Checkbox
                        value={ value.valueAddedTaxIncluded }
                        onChange={ ()=> {
                           if ( value.hasOwnProperty('valueAddedTaxIncluded') ) onChange( prev => ({...prev, valueAddedTaxIncluded: !prev.valueAddedTaxIncluded }))
                        }}
                        label={'з ПДВ'}
                        disabled={disabled}
                    />
                 }
                 { !!props?.info &&
                    <InputInfo
                        data={props.info}
                    />
                 }
            </div>
            <div className={styles.descr} >{ props.descr && props.descr }</div>
        </div>
    )
    
}

export default InputAmount