import React, { useEffect } from 'react';
import styles from './assetView.module.css';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import AssetSidebarView from './sidebar/sidebarView';
import AssetViewTitle from './titleBar/titleBar';
import { assetsGetMy, assetGetPublicId } from '../../../../redux/asset/assetActions';
import { getAssetByPublicId, getMyAsset } from '../../../../utils/endPoints';
import AssetGeneral from './elems/general';
import Button from '../../../elements/buttons/button';

const AssetView =()=> {
    const dispatch = useDispatch()
    const location = useLocation()
    const assetItem = useSelector(state => state.asset.assetItem)
    const token = useSelector(state => state.start.token)

    useEffect(() => {
        const id = location.pathname.split('/')[2]
        dispatch( assetGetPublicId( getAssetByPublicId(id)))
      return () => {
        dispatch({type: 'ASSET_GET_ID', payload: null})
      }
    }, [])

    function setAssetViewInfo( path ){
        switch( path ){
            default:
                return <AssetGeneral data={ assetItem } />
        }
    }
    
    if(!!assetItem){
    return (
        <div className={styles.wrap}>
            { ( !!process.env.REACT_APP_LOCAL && !!token ) &&
                <div className={styles.syncButton}>
                    <Button color='gray' onClick={ ()=> dispatch( assetsGetMy( `${getMyAsset(assetItem.id)}/sync`, token.access_token )) } >SYNC</Button>
                </div>
            }
            <AssetViewTitle data={ assetItem } />
            <div className={styles.container}>
                { setAssetViewInfo(location.pathname.split('/')[3]) }
                <AssetSidebarView data={ assetItem } />
            </div>
        </div>
        )
    }
    return null
}

export default AssetView