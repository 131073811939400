import React, { useEffect } from 'react';
import styles from './elems.module.css';
import {useSelector, useDispatch} from 'react-redux';
import FullInfoBlock from '../../../../elements/fullInfoBlock/fullInfoBlock';
import HalfInfoBlock from '../../../../elements/halfInfoBlock/halfInfoBlock';
import { isMongo, getAssetItemType, dateTransform, getTranslate, dictionaryGet } from '../../../../../utils/check';
import PopDownBlock from '../../../../elements/popdownBlock/popDownBlock';
import MapView from './map/mapView';
// import MapEdit from './map/mapEdit';
import AssetItemPropsView from '../../create/elems/items/assetItem/itemPropsView';

const AssetItem = ( { data } ) => {
    const assetRegistrationStatus = useSelector(state => state.start.assetRegistrationStatus)
    const dispatch = useDispatch()

    useEffect( () => {
        if ( assetRegistrationStatus === null ) {
            dispatch( dictionaryGet('registrationStatus', 'ASSETT_GET_REGISTRATION_STATUS') );
        }
    }, []);

    function getAddClasification(data) { // додаткові класифікатори
        let tmp = data.map( (item)=> {
            let tmpScheme = item.scheme
            let tmpDesc = isMongo(item.description)
            let tmpId = item.id
            return <li key={tmpId}>Код додаткового класифікатора: {tmpId} - {tmpDesc}</li>
        })
        return tmp
    }

    return (
            <div className={styles.infoBlock}>
                <div className={styles.infoBlockTitle}>
                    <h3>{ data?.description && isMongo(data.description) }</h3>
                </div>
                <div className={styles.infoBlockContent}>
                    {!!data?.assetItemType &&
                        <HalfInfoBlock
                            title={`Тип активу`}
                            data={ getAssetItemType(data.assetItemType) }
                        />
                    }
                    {!!data?.quantity &&
                        <HalfInfoBlock
                            title={`Кількість одиниць`}
                            data={ `${data.quantity} ${ isMongo(data.unit.name)}` }
                        />
                    }
                    {!!data?.classification &&
                        <HalfInfoBlock
                            title={`Основний класифікатор об'єкта`}
                            data={`${data.classification.scheme} ${data.classification.id}: ${isMongo(data.classification.description)}`}
                        />
                    }
                    { !!data?.additionalClassifications &&
                        <HalfInfoBlock
                            title={`Додаткові класифікатори об'єкта`}
                            data={ getAddClasification(data.additionalClassifications) }
                        />
                    }
                    { !!data?.registrationDetails && 
                        <HalfInfoBlock 
                            title=' Стан державної реєстрації об`єкту'
                            data={`
                            ${getTranslate(data.registrationDetails.registrationStatus, assetRegistrationStatus)}
                            ${ !!data.registrationDetails?.registrationID ? ' - №' + data.registrationDetails.registrationID : '' }
                            ${ !!data.registrationDetails?.registrationDate ? dateTransform(data.registrationDetails.registrationDate) : '' }
                            `}
                        />
                    }
                    {!!data?.address &&
                        <FullInfoBlock
                            title='Місцезнаходження'
                            data={<>
                                    <div>{`${isMongo(data.address.countryName)}, 
                                        ${isMongo(data.address.region) === 'Київ' ? '' : isMongo(data.address.region) + ','}
                                        ${isMongo(data.address.postalCode)}
                                        ${isMongo(data.address.locality)}, 
                                        ${isMongo(data.address.streetAddress)}`}
                                    </div>
                                { data?.address?.addressID ? <div>{`${data.address.addressID.scheme === 'koatuu' ? 'КОАТУУ - ' : ''}${data.address.addressID.id}` }</div> : null }
                                </>
                            }
                            moreData={ data?.location ? 
                                <div>
                                    <a href={`https://www.google.com.ua/maps/@${data.location.latitude},${data.location.longitude},14z?hl=ua`} target={'blank'} >
                                        {`Широта: ${data.location.latitude.substring(0, 8)} / Довгота: ${data.location.longitude.substring(0, 8)}`}
                                    </a>
                                </div> : null
                                }
                        />
                    }
                    { !!data?.location && <PopDownBlock title={`Розташування об'єкту на мапі`} content={ <MapView data={ data.location } /> } /> }
                    { !!data?.itemProps && <AssetItemPropsView item={data} /> }
                </div>
            </div>
    )
}

export default AssetItem