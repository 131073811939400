import React,{useState, useEffect} from 'react';
// import LandItemPropsEdit from './itemProps/landProps';
import { useSelector } from 'react-redux';
import Classificator from './classificator';
import OtherPropertyPropsEdit from './itemProps/otherPropertyProps';
import VehicleItemPropsEdit from './itemProps/vahicleProps';
import RealEstatePropsEdit from './itemProps/realEstateProps';
import JointPropertyComplexPropsEdit from './itemProps/jointPropertyComplexProps';

const EditLeaseItem =({ data, close })=> {
    const objectData = useSelector(state => state.start.objectGet);
    
    const [ classificator, setClassificator ] = useState( !!data?.basicInfo.classification ? data.basicInfo.classification : null )

    function setItemContent( classif ){
        let id = classif.id[0] + classif.id[1]
        switch( id ){
            case '04':
                return <RealEstatePropsEdit data={data} close={ close } classification={ classificator } />
            case '05':
                return <JointPropertyComplexPropsEdit data={data} close={ close } classification={ classificator } />
            // case '06':
            //     return <LandItemPropsEdit data={data} close={ close } classification={ classificator } />
            case '34':
                const regEx = new RegExp(/^34[1|4|5|6|7].*$/)
                if ( classif.id.match(regEx)) {
                    return <VehicleItemPropsEdit data={data} close={ close } classification={ classificator } />
                }
                else {
                    return <OtherPropertyPropsEdit  data={data} close={ close } classification={ classificator } />
                }
            default:
                return <OtherPropertyPropsEdit  data={data} close={ close } classification={ classificator } />
        }
    }

    // function setMainClassificator( type ){
    //     switch(type){
    //         case 'vehicle': return '34'
    //         case 'realEstate': return '04'
    //         case 'jointPropertyComplex': return '05'
    //         case 'otherProperty': return null
    //     }
    // }

    return (
        <>
            <Classificator 
                data={ !!data?.basicInfo?.classification ? data.basicInfo.classification : null } 
                setData={ setClassificator }
                // main={ setMainClassificator( objectData.type ) }
            />
            { !!classificator && setItemContent( classificator ) }
        </>
    )
}



export default EditLeaseItem