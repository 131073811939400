// ASSETS_PUBLIC
export const getAssetById = ( id ) => `https://procedure-sandbox.prozorro.sale/api/registry/assets/${id}`
export const getAssetByPublicId = ( id ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/registry/asset/by_public_id/${id}`
export const getAssetListSearch = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/registry/assets/search`
export const getAnnouncementsListSearch = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/announcements/search` 
export const getAnnouncementsByPublicId = ( id ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/announcements/by_public_id/${id}` 
// ASSETS PRIVATE darfts
//export const getMyAssetsList = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/drafts/registry/assets/my/`
export const getMyAssetsList = ( type = 'asset' ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/drafts/registry/assets/my/${type}` // GET
export const getAssetsCopy = ( id ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/registry/assets/${id}/copy` 

//export const getMyAssetDraft = ( id, type = 'asset' ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/drafts/registry/${type}/${id}`
export const getMyAssetDraft = ( id, type = 'asset' ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/drafts/registry/asset/${id}`
//export const postMyAssetDraft = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/drafts/asset`
export const postMyAssetDraft = ( type = 'asset' ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/drafts/registry/assets/${type}` // POST
export const postMyAssetDraftItem = ( id ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/drafts/asset/${id}/item`
export const getMyAssetDraftItem = ( assetId, id ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/drafts/asset/${assetId}/item/${id}` // PATCH, DELETE
//export const postAssetDraftDocument = ( assetId, type = 'asset' ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/drafts/registry/${type}/${assetId}/document` // POST
export const postAssetDraftDocument = ( assetId, type = 'asset' ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/drafts/registry/assets/${assetId}/document` // POST
//export const patchAssetDraftDocument = ( assetId, docId, type = 'asset' ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/drafts/registry/${type}/${assetId}/document/${docId}` // PATCH, DELETE
export const patchAssetDraftDocument = ( assetId, docId, type = 'asset' ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/drafts/registry/assets/${assetId}/document/${docId}` // PATCH, DELETE

//assets/
// notDrafts
//export const postAssetDocument = ( assetId, type = 'asset' ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/registry/${type}/${assetId}/document` // POST
export const postAssetDocument = ( assetId, type = 'asset' ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/registry/assets/${assetId}/document` // POST
//export const patchAssetDocument = ( assetId, docId, type = 'asset' ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/registry/${type}/${assetId}/document/${docId}` // PATCH, DELETE
export const patchAssetDocument = ( assetId, docId, type = 'asset' ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/registry/assets/${assetId}/document/${docId}` // PATCH, DELETE
export const postMyAssetItem = ( id ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/asset/${id}/item`
export const getMyAssetItem = ( assetId, id ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/asset/${assetId}/item/${id}` // PATCH, DELETE

//export const sendAsset = ( assetId, type = 'asset' ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/drafts/registry/${type}/${assetId}/send` // Send to prozoro
export const sendAsset = ( assetId, type = 'asset' ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/drafts/registry/asset/${assetId}/send` // Send to prozoro

//export const getMyAsset = ( id, type = 'asset' ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/registry/${type}/${id}` // GET, PATCH
export const getMyAsset = ( id, type = 'asset' ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/registry/assets/${id}` // GET, PATCH
//export const setMyAssetStatus = ( id , status, type = 'asset' ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/registry/${type}/${id}/status/${status}` // GET, PATCH
export const setMyAssetStatus = ( id , status, type = 'asset' ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/registry/assets/${id}/status/${status}` // GET, PATCH
export const setMyAnnouncementsStatus = ( id , status ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/announcements/${id}/status/${status}` // GET, PATCH
export const setMyRedemptionsStatus = ( id , status ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/redemptions/${id}/status/${status}` // GET, PATCH

// ANNOUNCEMENTS
//drafts >>
//export const postMyAnnouncementDraft = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/drafts/announcement` //POST
export const postMyAnnouncementDraft = ( type ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/drafts/announcement/${type}` //POST
export const getMyAnnouncementDraft = ( id ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/drafts/announcements/${id}` // GET, PATCH, DELETE
export const postMyAnnouncementDraftDocument = ( id ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/drafts/announcements/${id}/document` // POST
export const getMyAnnouncementDraftDocument = ( id, docId ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/drafts/announcements/${id}/document/${docId}` // GET, PATCH, DELETE
export const getMyAnnouncementsList = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/drafts/announcements/my`

export const sendAnnouncementDraft = ( id ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/drafts/announcements/${id}/send`
//published
export const getMyAnnouncement = ( id ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/announcements/${id}` // GET, PATCH, DELETE
export const getMyAnnouncementCancel = ( id ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/announcements/${id}/cancel` // GET, PATCH, DELETE
export const postMyAnnouncementDoc = ( id ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/announcement/${id}/document` 
export const getMyAnnouncementDoc = ( id, docId ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/announcement/${id}/document/${docId}` // GET, PATCH, DELETE
//export const getAnnDocHistory = ( annId, docId ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/announcements/${annId}/documents/${docId}/history` // GET
export const getAnnDocHistory = ( annId, docId ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/announcement/${annId}/document/${docId}/history` // GET

//REDEMPTION
//drafts >>
export const postMyRedemptionDraft = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/drafts/redemptions` //POST
export const getMyRedemptionDraft = ( id ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/drafts/redemptions/${id}` // GET, PATCH, DELETE
export const postMyRedemptionDraftDocument = ( id ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/drafts/redemptions/${id}/document` // POST
export const getMyRedemptionDraftDocument = ( id, docId ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/drafts/redemptions/${id}/document/${docId}` // GET, PATCH, DELETE
export const postMyRedemptionDraftDocumentContract = ( id, contractId ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/drafts/redemptions/${id}/contracts/${contractId}/document` // POST
export const getMyRedemptionDraftDocumentContract = ( id, contractId, docId ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/drafts/redemptions/${id}/contracts/${contractId}/document/${docId}` // GET, PATCH, DELETE
export const getMyRedemptionsList = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/drafts/redemptions/my`

export const getMyRedemptionDraftContract = ( id, contractId ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/drafts/redemptions/${id}/contracts/${contractId}`  // GET, PATCH, DELETE


export const sendRedemptionDraft = ( id ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/drafts/redemptions/${id}/send`
// //published
export const getMyRedemption = ( id ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/redemptions/${id}` // GET, PATCH, DELETE
export const getMyRedemptionPublic = ( id ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/redemptions/by_public_id/${id}` // GET
export const postMyRedemptionDoc = ( id ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/redemptions/${id}/document` 
export const getMyRedemptiontDoc = ( id, docId ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/redemptions/${id}/document/${docId}` // GET, PATCH, DELETE
export const postMyRedemptionDocContract = ( id, contractId ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/redemptions/${id}/contracts/${contractId}/documents`
export const getMyRedemptiontDocContract = ( id, contractId, docId ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/redemptions/${id}/contracts/${contractId}/document/${docId}` // GET, PATCH, DELETE
export const getRedemptionDocHistory = ( annId, docId ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/redemptions/${annId}/documents/${docId}/history` // GET
export const getRedemptionDocHistoryContract = ( annId, contractId, docId ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/redemptions/${annId}/documents/${docId}/history` // GET

export const getMyRedemptionContract = ( id, contractId ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/redemptions/${id}/contracts/${contractId}`  // GET, PATCH, DELETE

export const urlRedemptionsSearch = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/redemptions/search`;




// OBJECT PUBLISHED
export const myObjectPrivate = ( id ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/object/${id}` // GET, PATCH
export const myObjectPrivateCopy = ( id ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/object/${id}/copy` // COPY
// docs
export const myObjectDocumentPost = ( id ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/object/${id}/document` // POST
export const myObjectDocument = ( id, docId ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/object/${id}/document/${docId}` // PATCH, DELETE document edit
// items
export const myObjectItemPost = ( id ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/object/${id}/item` // PATCH, DELETE document edit
export const myObjectItem = ( id, itemId ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/object/${id}/item/${itemId}` // PATCH, DELETE document edit
// lease_req
export const myLeaseReqGet = ( id ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/request/${id}` // GET
export const myLeaseRequests = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/drafts/requests/my` //GET LIST
export const myLeaseRequestPost = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/drafts/request` // POST
export const myLeaseRequestEdit = ( id ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/drafts/request/${id}` // PATCH, DELETE
export const myLeaseReqDocumentsPost = ( id ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/drafts/request/${id}/document` // POST
export const myLeaseReqDocumentEdit = ( id, doc ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/drafts/request/${id}/document/${doc}` // PATCH, DELETE
export const myObjectLeaseReq = ( obj ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/object/${obj}/requests` // PATCH, DELETE
export const pubObjectLeaseReq = ( obj ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/registry_object/${obj}/lease_requests` // PATCH, DELETE
export const pubObjectLeaseAct = ( obj ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/registry_object/${obj}/lease_actions` // PATCH, DELETE
export const pubObjectLeaseContracts = ( obj ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/registry_object/${obj}/contracts` // PATCH, DELETE


//ToMyObjectsReqyests
export const toMyObjLeaseRequests = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/objects/related/requests` //GET LIST toMyObjs
export const toMyObjLeaseRequest = ( obj ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/object/${obj}/requests` //GET LIST toMyObjs

// OBJECT DRAFTS
export const myObjectsList = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/drafts/objects/my` // GET
export const myDraftObject = ( id ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/drafts/object/${id}` // GET, PATCH, DELETE
export const myDraftObjectCopy = ( id ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/drafts/object/${id}/copy` // GET - Copy of obj
export const myDraftObjectSend = ( id ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/drafts/object/${id}/send` // GET - send yo Prozoro
export const myDraftObjectSync = ( id ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/drafts/object/${id}/sync` // GET
export const myDraftObjectDocumentPost = ( id ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/drafts/object/${id}/document` // POST document post
export const myDraftObjectDocument = ( id, docId ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/drafts/object/${id}/document/${docId}` // PATCH, DELETE document edit
export const myDraftObjectPost = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/drafts/object` // POST new object

//items draft
export const myDraftObjectItemPost = ( id ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/drafts/object/${id}/item` // POST new item
export const myDraftObjectItem = ( id, itemId ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/drafts/object/${id}/item/${itemId}` // PATCH, DELETE document edit

// items CLASSIFICATORs
// export const itemClassificator = ( type ) => `https://sandbox-registry.dto.com.ua/api/v2/uk/public/classifications/byType/${type}?limit=1000`
export const itemClassificatorMask = ( mask ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/classifications/autocomplete/classification_id?limit=1000&offset=0&classification_id=${mask}&scheme=CAV&mask=${mask}`;
export const itemClassificatorUnitCode = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/classifications/byScheme/unitCode?limit=1000&offset=0&orderBy=description&order=ASC`;

// ##PUBLIC GET
export const getObjectsListSearch = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/registry_objects/search`
export const getRequestsListSearch = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/lease_requests/search`
export const getActionsListSearch = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/lease_actions/search`
export const getContractsListSearch = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/contracts/search`
export const getObjectById = ( id ) => `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/public/registry_object/${id}`
export const getObjectByPublicId = ( id ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/registry_objects/by_public_id/${id}`
export const redirectObjectByPublicId = ( id ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/registry_objects/byGlobalId/${id}`
// export const getLeaseReqById = ( id ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/lease_request/${id}`
export const getLeaseReqById = ( id ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/request/${id}`
export const getLeaseReqByPublicId = ( id ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/lease_requests/by_public_id/${id}`
export const getLeaseActById = ( id ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/lease_action/${id}`

//DOC UPLOAD
export const uploadDocumentUrl = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/documents/upload`

//DICTIONARY
export const getDictionaryByType = ( type ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/dictionaries/${type}`

//ORGANIZATIONS
export const getUserOrganizations = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/user/profile/organizations`

//ACTIONS
export const urlGetMyActions = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/drafts/actions/my`;
    // --drafts
export const urlPostMyDraftAction = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/drafts/action`; //POST
export const urlGetMyDraftAction = ( id ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/drafts/action/${id}`; //PATCH, GET, DELETE
export const urlSendMyDraftAction = ( id ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/drafts/action/${id}/send`; //GET
export const urlPostMyDraftActionDoc = ( id ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/drafts/action/${id}/document`; //POST
export const urlPatchMyDraftActionDoc = ( id, doc ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/drafts/action/${id}/document/${doc}`; //PATCH, GET, DELETE
    // --published
export const urlGetMyAction = ( id ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/action/${id}`; //PATCH, GET, DELETE
export const postMyActionDocument = ( id ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/action/${id}/document`; //PATCH, GET, DELETE
export const patchMyActionDocument = ( id, doc ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/action/${id}/document/${doc}`; //PATCH, GET, DELETE
    // --public

export const urlGetAction = ( id ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/lease_action/${id}`

//LEASE CONRACTS
    // --drafts
export const urlMyLeaseContracts = ( obj )=> `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/drafts/object/${obj}/contracts/my`
export const urlPostLeaseContractDrafts = ( obj )=> `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/drafts/object/${obj}/contract`
export const urlPatchLeaseContractDrafts = ( obj, id ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/drafts/object/${obj}/contracts/${id}`
export const urlPostDocLeaseContractDrafts = ( obj, id )=> `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/drafts/object/${obj}/contracts/${id}/documents`
export const urlPatchDocLeaseContractDrafts = ( obj, contract, doc )=> `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/drafts/object/${obj}/contracts/${contract}/document/${doc}`
    // --published
export const urlPatchLeaseContract = ( obj, id ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/object/${obj}/contracts/${id}`
export const urlPostDocLeaseContract = ( obj, id )=> `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/object/${obj}/contracts/${id}/documents`
export const urlPatchDocLeaseContract = ( obj, contract, doc )=> `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/object/${obj}/contracts/${contract}/document/${doc}`
export const urlPatchStatusLeaseContract = ( obj, contract, status )=> `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/object/${obj}/contracts/${contract}/status/${status}`

//EXECUTIONS
export const urlExecutionsSearch = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/executions/search`;
export const urlGetExecutionPubId =( id )=> `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/executions/by_public_id/${id}`;

//--private
export const urlGetListMyExs = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/drafts/executions/my`;
export const urlGetMyExecution =( id )=> `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/executions/${id}`; //PATCH, GET, DELETE
export const urlGetOrderMyExecution =( id )=> `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/executions/${id}/order`; //PATCH, GET, DELETE
export const urlGetCompletionMyExecution =( id )=> `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/executions/${id}/completion`; //PATCH, GET, DELETE
export const urlPostOrderDocumentMyExecution = (id) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/executions/${id}/order/documents`; //PATCH, GET, DELETE
export const urlPatchOrderDocumentMyExecution = ( id, doc ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/executions/${id}/order/document/${doc}`; //PATCH, GET, DELETE
export const urlPostCompDocumentMyExecution = (id) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/executions/${id}/completion/documents`; //PATCH, GET, DELETE
export const urlPatchCompDocumentMyExecution = ( id, doc ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/executions/${id}/completion/document/${doc}`; //PATCH, GET, DELETE
export const urlPatchMyExecutionStatus = (id, status) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/executions/${id}/status/${status}`; //PATCH, GET, DELETE
export const urlPostEditContractExecution = (id) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/drafts/executions/${id}/contract`; //PATCH, GET, DELETE

export const postMyExecutionsDocContract = ( id, contractId ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/executions/${id}/contracts/${contractId}/documents`
export const getMyExecutionsDocContract = ( id, contractId, docId ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/executions/${id}/contracts/${contractId}/document/${docId}` // GET, PATCH, DELETE
//export const getMyExecutionsDocContractHistory = ( id, contractId, docId ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/executions/${id}/contracts/${contractId}/documents/${docId}/history` // GET
export const getMyExecutionsDocContractHistory = ( id, contractId, docId ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/executions/${id}/contracts/${contractId}/document/${docId}/history` // GET