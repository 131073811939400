import React from 'react';
import styles from './thirdInfoBlock.module.css';

const ThirdInfoBlock =( props )=> {

    return (
                <div className={styles.blockThird}>
                    <div  className={styles.description}>{props.title}</div>
                    <div className={styles.data}>{props.data}</div>
                    <div className={styles.data}>{props.moreData}</div>
                </div>
    )
}

export default ThirdInfoBlock