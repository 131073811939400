import React from 'react';
import styles from './elems.module.css';
import FullInfoBlock from '../../../../elements/fullInfoBlock/fullInfoBlock';
import HalfInfoBlock from '../../../../elements/halfInfoBlock/halfInfoBlock';
import { dateTransform, isMongo, getAddedTax, getCurrency } from '../../../../../utils/check';
import { getExecutionContractStatus, getRedemptionDocType } from '../../utils';
import PopDownBlock from '../../../../elements/popdownBlock/popDownBlock';
import BuyerItem from './buyerItem';
import ViewDocItem from '../../../../elements/documents/view/viewDocumentItem';
import { getRedemptionDocHistory } from '../../../../../utils/endPoints'

const ExecusionContracts = ( { data, objId } ) => {

    function getValueAddedTaxCharged(x) {return x ? 'Так' : 'Ні'}

    return <div className={styles.infoBlockOrgContent}>
                { !!data && data.length > 0 &&
                    data.map( item => <div className={styles.decisionItem} key={item.id} >
                        {!!item?.contractNumber &&
                            <HalfInfoBlock
                                title='Номер договору'
                                data={ item.contractNumber}
                            />
                        }
                        {!!item?.status &&
                            <HalfInfoBlock
                            title={'Статус договору'}
                            data={ getExecutionContractStatus( item.status ) }
                            />
                        }
                        {!!item?.title &&
                            <FullInfoBlock
                                title='Назва договору'
                                data={ isMongo(item.title)}
                            />
                        }
                        {!!item?.description &&
                            <FullInfoBlock
                                title='Опис договору'
                                data={ isMongo(item.description)}
                            />
                        }
                        {!!item?.contractTime &&
                            <HalfInfoBlock
                                title='Строк дії договору'
                                data={ `${dateTransform(item.contractTime.dateFrom)} ${!!item?.contractTime?.dateTill ? `- ${dateTransform(item.contractTime.dateTill)}` : ''}`}
                            />
                        }
                        {!!item?.dateSigned &&
                            <HalfInfoBlock
                            title={'Дата підписання договору'}
                            data={ dateTransform(item.dateSigned, 'time') }
                            />
                        }
                        {!!item?.contractTime?.dateFrom &&
                            <HalfInfoBlock
                            title={'Термін виконання договору Період з'}
                            data={ dateTransform(item.contractTime.dateFrom, 'time') }
                            />
                        }
                        {!!item?.contractTime?.dateTill &&
                            <HalfInfoBlock
                            title={'Термін виконання договору Період по'}
                            data={ dateTransform(item.contractTime.dateTill, 'time') }
                            />
                        }
                        {!!item?.value &&
                            <HalfInfoBlock
                            title={'Вартість договору'}
                            data={`${item.value.amount} ${ getCurrency( item.value.currency) } ${ getAddedTax(item.value.valueAddedTaxIncluded)}`}
                            />
                        }
                        {!!item?.value &&
                            <HalfInfoBlock
                            title={'Ціна, зменшена на суму компенсації'}
                            data={`${item.reducedValue.amount} ${ getCurrency( item.reducedValue.currency) } ${ getAddedTax(item.reducedValue.valueAddedTaxIncluded)}`}
                            />
                        }
                        {!!item?.contractTotalValue &&
                            <HalfInfoBlock
                            title={'Заключна вартість договору'}
                            data={`${item.contractTotalValue.amount} ${ getCurrency( item.contractTotalValue.currency) } ${ getAddedTax(item.contractTotalValue.valueAddedTaxIncluded)}`}
                            />
                        }
                        {!!item?.valueAddedTaxCharged &&
                            <HalfInfoBlock
                            title={'На фінальну суму нараховується ПДВ'}
                            data={`${getValueAddedTaxCharged(item.valueAddedTaxCharged)}`}
                            />
                        }
                        {!!item?.datePublished &&
                            <HalfInfoBlock
                            title={'Дата створення об\'єкту договору'}
                            data={ dateTransform(item.datePublished, 'time') }
                            />
                        }
                        {!!item?.dateModified &&
                            <HalfInfoBlock
                            title={'Остання дата редагування договору'}
                            data={ dateTransform(item.dateModified, 'time') }
                            />
                        }
                        { !!item.buyers && 
                            <PopDownBlock title={'Переможець'} 
                                content={
                                    item.buyers.map( i => <BuyerItem data={i} key={i.id} /> )
                                }
                            />
                        }
                        { ( !!item.documents && item.documents.length > 0 ) && 
                            <PopDownBlock title={`Документи (${item.documents.length})`} 
                                content={<div className={styles.docList}>
                                        { item.documents.map(
                                            (i) => {
                                                let tmp = data[0].id
                                                return <ViewDocItem 
                                                        key={i.id}
                                                        data={ i } 
                                                        specs={{
                                                            objId: data.id,
                                                            docType: getRedemptionDocType(i.documentType),
                                                            historyURL: `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/redemptions/${objId}/contracts/${tmp}/document/${i.id}/history`
                                                        }}
                                                    /> 
                                            }
                                        )}
                                    </div>
                                }
                            />
                        }
                    </div> )
                }
        </div>
}

export default ExecusionContracts