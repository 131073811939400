export function setFilterSearch(start, a, type) {
        let data = {}
        data.statusString = ''
        data.statusString += a !== undefined ? setTypeSearch(a.type) : ''
        data.statusString += type === 'SET_DATE_FROM' ? '' : setDateFrom(start.search.registry.dateFrom)
        data.statusString += type === 'SET_DATE_TILL' ? '' : setDateTill(start.search.registry.dateTill)
        data.statusString += setSearchByRelatedObjectId(start.search.registry.relatedObjId)
        data.statusString += setSearchByLeaseActionId(start.search.leaseAction.id)
        data.statusString += setSearchByObjectId(start.search.registry.objectId)
        data.statusString += setListTypeSearch(start.search.registry.listType)
        data.statusString += setObjStatusSearch(start.search.registry.status)
        data.statusString += setSearchByEDRPOU(start.search.registry.identyfier)
        data.statusString += setAssetStatusSearch(start.search.assets.status)
        
        data.version = 'v2'
        data.local = 'uk'
        data.params = `?limit=${start.regPaginatorPerPage}&offset=${0}`
    
        if (start.search.textSting !== null) {
            if (start.search.textSting !== '') {
                data.params += `&keyword=${start.search.textSting}`
            }
        }
            
        data.params += data.statusString
        
        data.header = {
            'Content-Type': 'application/json'
        }
        data.path = `${process.env.REACT_APP_END_POINT_BD}/api/${data.version}/${data.local}/public/${start.search.url}/search`
    return data
}

function setTypeSearch(a) {
    let str = ''
    for(let d in a){
        if(a[d].onOff === true){
            str += `&type[]=${a[d].id}`
        }
    }
    return str
}

function setListTypeSearch(a) {
    let str = ''
    for(let d in a){
        if(a[d].onOff === true){
            str += `&listType[]=${a[d].id}`
        }
    }
    return str
}

function setObjStatusSearch(a) {
    let str = ''
    for(let d in a){
        if(a[d].onOff === true){
            str += `&status[]=${a[d].id}`
        }
    }
    return str
}

function setAssetStatusSearch(a) {
    let str = ''
    for(let d in a){
        if(a[d].onOff === true){
            str += `&status[]=${a[d].id}`
        }
    }
    return str
}

function setDateFrom(a) {
    let str = ''
    if (a !== str) { str = `&datePublishedFrom=${a}` }
    return str
}

function setDateTill(a) {
    let str = ''
    if (a !== '') { str = `&datePublishedTo=${a}` }
    return str
}

function setSearchByObjectId(a) {
    let str = ''
    if (a !== '') { str = `&registryObjectId=${a}` }
    return str
}

function setSearchByLeaseActionId(a) {
    let str = ''
    if (a !== '') { str = `&leaseActionId=${a}` }
    return str
}

function setSearchByRelatedObjectId(a) {
    let str = ''
    if (a !== '') { str = `&relatedObjectId=${a}` }
    return str
}

function setSearchByEDRPOU(a) {
    let str = ''
    if (a !== '') { str = `&identyfier=${a}` }
    return str
}

export function setSearchReset() {
    return (
                {
                payload: {
                    registry: {
                        filtersCount: 0,
                        descriptionKeyword: '',
                        identyfier: '',
                        relatedObjId: '',
                        relatedRequestId: '',
                        relatedActionId: '',
                        objectId: '',
                        dateFrom: '',
                        dateTill: '',
                        type: [
                            { id: 'realEstate', title: "Об'єкт нерухомості", onOff: false },
                            { id: 'vehicle', title: "Транспортний засіб", onOff: false },
                            // { id: 'land', title: "Земельна ділянкa", onOff: false },
                            { id: 'otherProperty', title: "Інше окреме індивідуально визначене майно", onOff: false },
                            { id: 'jointPropertyComplex', title: "Об'єкт єдиного майнового комплексу", onOff: false },
                        ],
                        typeString: 'SET_SEARCH_OBJECT_TYPE',
                        listType: [
                            { id: 'First', title: "Перелік першого типу", onOff: false },
                            { id: 'Second', title: "Перелік другого типу", onOff: false },
                            { id: 'Undefined', title: "Не визначено", onOff: false },
                        ],
                        status:[
                            { id: 'waiting', title: "Очікує включення в перелік", onOff: false },
                            { id: 'approved', title: "Включено в перелік", onOff: false },
                            { id: 'inactive', title: "Неактивний", onOff: false },
                            { id: 'qualification', title: "Визначення орендаря", onOff: false },
                            { id: 'sold', title: "Орендовано", onOff: false },
                        ],
                        
                    },
                    statusString: '',
                    leaseAction: {
                        id: '',
                        type: [
                            { id: 'requestAnswer', title: "Запит для отримання відповіді", onOff: false },
                            { id: 'intentionNotification', title: "Повідомлення про намір", onOff: false },
                            { id: 'decisionPublication', title: "Публікація рішення", onOff: false },
                            { id: 'decisionChange', title: "Зміна рішення", onOff: false },
                            { id: 'petition', title: "Клопотання", onOff: false },
                            { id: 'noAuctionNotification', title: "Інформаційне повідомлення про передачу об’єкта оренди без проведення аукціону", onOff: false },
                        ],
                        typeString: 'SET_SEARCH_ACTION_TYPE',
                    },
                    leaseRequest: {
                        id: '',
                        type: [
                            { id: 'newLease', title: "Заява про включення до переліку", onOff: false },
                            { id: 'lease', title: "Заява на оренду об'єкта включеного в перелік", onOff: false },
                            { id: 'agreementExtension', title: "Заява про продовження договору оренди", onOff: false },
                        ],
                        typeString: 'SET_SEARCH_REQUEST_TYPE',
                    },
                    leaseContract: {
                        id: '',
                        type: [
                            { id: 'extension', title: "Продовження договору", onOff: false },
                            { id: 'listTypeSecond', title: "Передача в оренду з переліку другого типу", onOff: false },
                            { id: 'auction', title: "Передача в оренду через аукціон", onOff: false },
                            { id: 'signedBeforeETS', title: "Публікація договорів оренди укладених до введення в дію переліків в ЕТС", onOff: false },
                        ],
                        typeString: 'SET_SEARCH_CONTRACT_TYPE',
                    },
                    // regionProc: [
                    //     {id: 'Автономна Республіка Крим', title: "АВТОНОМНА РЕСПУБЛІКА КРИМ/М.СІМФЕРОПОЛЬ", onOff: false},
                    //     {id: 'Вінницька область', title: "ВІННИЦЬКА ОБЛАСТЬ/М.ВІННИЦЯ", onOff: false},
                    //     {id: 'Волинська область', title: "ВОЛИНСЬКА ОБЛАСТЬ/М.ЛУЦЬК", onOff: false},
                    //     {id: 'Дніпропетровська область', title: "ДНІПРОПЕТРОВСЬКА ОБЛАСТЬ/М.ДНІПРО", onOff: false},
                    //     {id: 'Донецька область', title: "ДОНЕЦЬКА ОБЛАСТЬ/М.ДОНЕЦЬК", onOff: false},
                    //     {id: 'Житомирська область', title: "ЖИТОМИРСЬКА ОБЛАСТЬ/М.ЖИТОМИР", onOff: false},
                    //     {id: 'Закарпатська область', title: "ЗАКАРПАТСЬКА ОБЛАСТЬ/М.УЖГОРОД", onOff: false},
                    //     {id: 'Запорізька область', title: "ЗАПОРІЗЬКА ОБЛАСТЬ/М.ЗАПОРІЖЖЯ", onOff: false},
                    //     {id: 'Івано-Франківська область', title: "ІВАНО-ФРАНКІВСЬКА ОБЛАСТЬ/М.ІВАНО-ФРАНКІВСЬК", onOff: false},
                    //     {id: 'Київська область', title: "КИЇВСЬКА ОБЛАСТЬ/М.КИЇВ", onOff: false},
                    //     {id: 'Кіровоградська область', title: "КІРОВОГРАДСЬКА ОБЛАСТЬ/М.КРОПИВНИЦЬКИЙ", onOff: false},
                    //     {id: 'Луганська область', title: "ЛУГАНСЬКА ОБЛАСТЬ/М.ЛУГАНСЬК", onOff: false},
                    //     {id: 'Львівська область', title: "ЛЬВІВСЬКА ОБЛАСТЬ/М.ЛЬВІВ", onOff: false},
                    //     {id: 'Миколаївська область', title: "МИКОЛАЇВСЬКА ОБЛАСТЬ/М.МИКОЛАЇВ", onOff: false},
                    //     {id: 'Одеська область', title: "ОДЕСЬКА ОБЛАСТЬ/М.ОДЕСА", onOff: false},
                    //     {id: 'Полтавська область', title: "ПОЛТАВСЬКА ОБЛАСТЬ/М.ПОЛТАВА", onOff: false},
                    //     {id: 'Рівненська область', title: "РІВНЕНСЬКА ОБЛАСТЬ/М.РІВНЕ", onOff: false},
                    //     {id: 'Сумська область', title: "СУМСЬКА ОБЛАСТЬ/М.СУМИ", onOff: false},
                    //     {id: 'Тернопільська область', title: "ТЕРНОПІЛЬСЬКА ОБЛАСТЬ/М.ТЕРНОПІЛЬ", onOff: false},
                    //     {id: 'Харківська область', title: "ХАРКІВСЬКА ОБЛАСТЬ/М.ХАРКІВ", onOff: false},
                    //     {id: 'Херсонська область', title: "ХЕРСОНСЬКА ОБЛАСТЬ/М.ХЕРСОН", onOff: false},
                    //     {id: 'Хмельницька область', title: "ХМЕЛЬНИЦЬКА ОБЛАСТЬ/М.ХМЕЛЬНИЦЬКИЙ", onOff: false},
                    //     {id: 'Черкаська область', title: "ЧЕРКАСЬКА ОБЛАСТЬ/М.ЧЕРКАСИ", onOff: false},
                    //     {id: 'Чернівецька область', title: "ЧЕРНІВЕЦЬКА ОБЛАСТЬ/М.ЧЕРНІВЦІ", onOff: false},
                    //     {id: 'Чернігівська область', title: "ЧЕРНІГІВСЬКА ОБЛАСТЬ/М.ЧЕРНІГІВ", onOff: false},
                    //     {id: 'Київ', title: "М.КИЇВ", onOff: false},
                    //     {id: 'Севастополь', title: "М.СЕВАСТОПОЛЬ", onOff: false},
                    // ],
                    // regionProcNew: [
                    //     {id: 'Автономна Республіка Крим', title: "АВТОНОМНА РЕСПУБЛІКА КРИМ/М.СІМФЕРОПОЛЬ", onOff: false},
                    //     {id: 'Вінницька область', title: "ВІННИЦЬКА ОБЛАСТЬ/М.ВІННИЦЯ", onOff: false},
                    //     {id: 'Волинська область', title: "ВОЛИНСЬКА ОБЛАСТЬ/М.ЛУЦЬК", onOff: false},
                    //     {id: 'Дніпропетровська область', title: "ДНІПРОПЕТРОВСЬКА ОБЛАСТЬ/М.ДНІПРО", onOff: false},
                    //     {id: 'Донецька область', title: "ДОНЕЦЬКА ОБЛАСТЬ/М.ДОНЕЦЬК", onOff: false},
                    //     {id: 'Житомирська область', title: "ЖИТОМИРСЬКА ОБЛАСТЬ/М.ЖИТОМИР", onOff: false},
                    //     {id: 'Закарпатська область', title: "ЗАКАРПАТСЬКА ОБЛАСТЬ/М.УЖГОРОД", onOff: false},
                    //     {id: 'Запорізька область', title: "ЗАПОРІЗЬКА ОБЛАСТЬ/М.ЗАПОРІЖЖЯ", onOff: false},
                    //     {id: 'Івано-Франківська область', title: "ІВАНО-ФРАНКІВСЬКА ОБЛАСТЬ/М.ІВАНО-ФРАНКІВСЬК", onOff: false},
                    //     {id: 'Київська область', title: "КИЇВСЬКА ОБЛАСТЬ/М.КИЇВ", onOff: false},
                    //     {id: 'Кіровоградська область', title: "КІРОВОГРАДСЬКА ОБЛАСТЬ/М.КРОПИВНИЦЬКИЙ", onOff: false},
                    //     {id: 'Луганська область', title: "ЛУГАНСЬКА ОБЛАСТЬ/М.ЛУГАНСЬК", onOff: false},
                    //     {id: 'Львівська область', title: "ЛЬВІВСЬКА ОБЛАСТЬ/М.ЛЬВІВ", onOff: false},
                    //     {id: 'Миколаївська область', title: "МИКОЛАЇВСЬКА ОБЛАСТЬ/М.МИКОЛАЇВ", onOff: false},
                    //     {id: 'Одеська область', title: "ОДЕСЬКА ОБЛАСТЬ/М.ОДЕСА", onOff: false},
                    //     {id: 'Полтавська область', title: "ПОЛТАВСЬКА ОБЛАСТЬ/М.ПОЛТАВА", onOff: false},
                    //     {id: 'Рівненська область', title: "РІВНЕНСЬКА ОБЛАСТЬ/М.РІВНЕ", onOff: false},
                    //     {id: 'Сумська область', title: "СУМСЬКА ОБЛАСТЬ/М.СУМИ", onOff: false},
                    //     {id: 'Тернопільська область', title: "ТЕРНОПІЛЬСЬКА ОБЛАСТЬ/М.ТЕРНОПІЛЬ", onOff: false},
                    //     {id: 'Харківська область', title: "ХАРКІВСЬКА ОБЛАСТЬ/М.ХАРКІВ", onOff: false},
                    //     {id: 'Херсонська область', title: "ХЕРСОНСЬКА ОБЛАСТЬ/М.ХЕРСОН", onOff: false},
                    //     {id: 'Хмельницька область', title: "ХМЕЛЬНИЦЬКА ОБЛАСТЬ/М.ХМЕЛЬНИЦЬКИЙ", onOff: false},
                    //     {id: 'Черкаська область', title: "ЧЕРКАСЬКА ОБЛАСТЬ/М.ЧЕРКАСИ", onOff: false},
                    //     {id: 'Чернівецька область', title: "ЧЕРНІВЕЦЬКА ОБЛАСТЬ/М.ЧЕРНІВЦІ", onOff: false},
                    //     {id: 'Чернігівська область', title: "ЧЕРНІГІВСЬКА ОБЛАСТЬ/М.ЧЕРНІГІВ", onOff: false},
                    //     {id: 'Київ', title: "М.КИЇВ", onOff: false},
                    //     {id: 'Севастополь', title: "М.СЕВАСТОПОЛЬ", onOff: false},
                    // ],

                    // searchVidjetSimpleFilterProcurementMethodTypeOpen: false,
                    
                    // filterValueAmountFrom: '',
                    // filterValueAmountFromNew: '',
                    // filterValueAmountTo: '',
                    // filterValueAmountToNew: '',
                    // searchVidjetSimpleFilterValueAmountOpen: false,

                    // enquiryPeriodTo: '',
                    // enquiryPeriodToNew: '',
                    // enquiryPeriodFrom: '',
                    // enquiryPeriodFromNew: '',
                    // searchVidjetSimpleFilterEnquiryPeriodOpen: false,

                    // auctionPeriodTo: '',
                    // auctionPeriodToNew: '',
                    // auctionPeriodFrom: '',
                    // auctionPeriodFromNew: '',
                    // searchVidjetSimpleFilterAuctionPeriodOpen: false,

                    // tenderPeriodTo: '',
                    // tenderPeriodToNew: '',
                    // tenderPeriodFrom: '',
                    // tenderPeriodFromNew: '',
                    // searchVidjetSimpleFilterTenderPeriodOpen: false,

                    // filterValueSummFrom: '',
                    // filterValueSummFromNew: '',
                    // filterValueSummTo: '',
                    // filterValueSummToNew: '',
                    // searchVidjetSimpleFilterValueSummOpen: false,

                    // plansTenderPeriodTo: '',
                    // plansTenderPeriodToNew: '',
                    // plansTenderPeriodFrom: '',
                    // plansTenderPeriodFromNew: '',
                    // plansTenderPeriodOpen: false,

                    openSortList: false,
                    /*sortListArr: [
                        {name: 'Спочатку найновіші створені', status: true, findParamName: 'date', findSortStatus: 'DESC', flagTend: true, flagPlan: true, flagDog: true},
                        {name: 'Спочатку найстарші створені', status: false, findParamName: 'date', findSortStatus: 'ASC', flagTend: true, flagPlan: true, flagDog: true},
                        {name: 'Спочатку найнижча очікувана вартість', status: false, findParamName: 'value.amount', findSortStatus: 'DESC', flagTend: true, flagPlan: true, flagDog:false},
                        {name: 'Спочатку найвища очікувана вартість', status: false, findParamName: 'value.amount', findSortStatus: 'ASC', flagTend: true, flagPlan: true, flagDog: false},
                        {name: 'Спочатку найближчі аукціони', status: false, findParamName: 'auctionPeriod.startDate', findSortStatus: 'DESC', flagTend: true, flagPlan: false, flagDog: false},
                        {name: 'Спочатку найпізніші аукціони', status: false, findParamName: 'auctionPeriod.startDate', findSortStatus: 'ASC', flagTend: true, flagPlan: false, flagDog: false},
                        {name: 'Спочатку найнижча сума договору', status: false, findParamName: 'value.amount', findSortStatus: 'DESC', flagTend: false, flagPlan: false, flagDog: true},
                        {name: 'Спочатку найвища сума договору', status: false, findParamName: 'value.amount', findSortStatus: 'ASC', flagTend: false, flagPlan: false, flagDog: true},
                    ],*/
                    sortListArr: null,

                    // openDjereloFin: false,
                    // djerelFinArr: [
                    //     {id: 'state', name: 'Державний бюджет України', status: false},
                    //     {id: 'crimea', name: 'Бюджет Автономної Республіки Крим', status: false},
                    //     {id: 'local', name: 'Місцевий бюджет', status: false},
                    //     {id: 'own', name: 'Власний бюджет (кошти від господарської діяльності підприємства)', status: false},
                    //     {id: 'fund', name: 'Бюджет цільових фондів', status: false},
                    //     {id: 'loan', name: 'Кредити та позики міжнародних валютно-кредитних організацій', status: false},
                    //     {id: 'other', name: 'Інше', status: false},
                    // ],
                    // djerelFinArrNew: [
                    //     {id: 'state', name: 'Державний бюджет України', status: false},
                    //     {id: 'crimea', name: 'Бюджет Автономної Республіки Крим', status: false},
                    //     {id: 'local', name: 'Місцевий бюджет', status: false},
                    //     {id: 'own', name: 'Власний бюджет (кошти від господарської діяльності підприємства)', status: false},
                    //     {id: 'fund', name: 'Бюджет цільових фондів', status: false},
                    //     {id: 'loan', name: 'Кредити та позики міжнародних валютно-кредитних організацій', status: false},
                    //     {id: 'other', name: 'Інше', status: false},
                    // ],

                    // rozporyadnukKoshtivSearchString: '',
                    // rozporyadnukKoshtivArrGet: null,
                    // rozporyadnukKoshtivArr: [],
                    // rozporyadnukKoshtivArrNew: [],
                    // rozporyadnukKoshtiv: false,

                    url: 'tender_searches',
                    order: "asc",
                    //orderBy: "string",
                    orderBy: null, //по какому полю сортировать для верхнего поиска
                    authorName: null,
                    auctionId: null,
                    status: 'null',
                    //status: null,
                    region: null, //Регион
                    //category: "string",
                    category: null,//'timberEnglish', 
                    classification: null,
                    priceCurrency: null,
                    //priceCurrency: null,
                    //priceFrom: 0,
                    priceFrom: null,
                    priceFromBag: false,
                    //priceTo: 0,
                    priceTo: null,
                    priceToBag: false,
                    //measures: "string",
                    measures: null, //одиници вимыру
                    //measuresFrom: 0,
                    measuresFrom: null,
                    //measuresTo: 0
                    measuresTo: null,
                    textSting: '',
                    selectTypeKeyword: null,
                    startDateFrom: null,
                    startDateTo: null,

                }, type: 'SET_ALL_RESET'
                }
    )
}

 // ----------------------------- //
export function setGetRequestParameterStatus(data, status){
    let str = ''
    if(status === true){
        for(let d = 0; d < data.statusProc.length; d++){
            if(data.statusProc[d].onOff === true){
                str += `&status[]=${data.statusProc[d].status}`
            }
        }
    }else{
        for(let d = 0; d < data.statusProcNew.length; d++){
            if(data.statusProcNew[d].onOff === true){
                str += `&status[]=${data.statusProcNew[d].status}`
            }
        }
    }
    return str
}
