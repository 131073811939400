import { 
    //Organizations
    ORGANIZATION_GET,
    ORGANIZATION_POST,
    ORGANIZATION_PUT,
    ORGANIZATION_DELETE,
    POPUP_CHOOSE_ORGANIZATION,
    POPUP_ADD_ORGANIZATION,
    KOATUU_FIND,
    ORGANIZATIONS_GET_LIST,
} from "../../types";

import {getAPI, postAPI, putAPI, deleteAPI} from '../crud';
import {setLocal} from '../support';

//Organizations
export function organizationPost(_url, token, body, action){
    const data = {
        url: _url,
        token: token,
        actionType: ORGANIZATION_POST,
        body: body,
        header: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        secondAction: action,
    }
    return postAPI(data);
}

export function organizationGet(_url, token){
    const data = {
        url: _url,
        actionType: ORGANIZATION_GET,
        header: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        }
    }
    return getAPI(data);
}

export function organizationPut(_url, token, body, action){
    const data = {
        url: _url,
        token: token,
        actionType: ORGANIZATION_PUT,
        body: body,
        header: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        secondAction: action
    }
    return putAPI(data);
}

export function organizationDelete(_url, token, action){
    const data = {
        url: _url,
        token: token,
        actionType: ORGANIZATION_DELETE,
        header: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        // redirect: redirect,
        secondAction: action,
    }
    return deleteAPI(data);
}

export function setPopupChooseOrganization(value) {
    return {
        type: POPUP_CHOOSE_ORGANIZATION,
        payload: value
    }
}
export function setPopupAddOrganization(value) {
    return {
        type: POPUP_ADD_ORGANIZATION,
        payload: value
    }
}
export function findKoatuu(_url) {
    const data = {
        url: _url,
        actionType: KOATUU_FIND,
        header: {
            'Content-Type': 'application/json'
        }
    }
    return getAPI(data);
} 
export function organizationsListGet(_url, token){
    const data = {
        url: _url,
        actionType: ORGANIZATIONS_GET_LIST,
        header: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        }
    }
    return getAPI(data);
}