import { checkProp } from "../../../../../../utils/check"

const validation = {
    // title: {
    //     test: ( a )=> {
    //         if( !checkProp(a, 'uk_UA') ) return false // текст може тут одразу?
    //         if( a.uk_UA.length < 1 ) return false
    //         return true
    //     },
    //     text: 'Заповніть назву об\'єкту',
    // },
    // description: {
    //     test: ( a )=> {
    //         if( !checkProp(a, 'uk_UA') ) return false // текст може тут одразу?
    //         if( a.uk_UA.length < 1 ) return false
    //         return true
    //     },
    //     text: 'Заповніть опис об\'єкту',
    // },
    saleCondition: {
        test: ( a )=> {
            if ( !a ) return false
            if ( a === 'no' || 'yes' ) return true
        },
        text: 'Заповніть поле "Наявність умов продажу"',
    },
    decisions: {
        test: ( a )=> {
            if ( a.length < 1 ) return false
            for ( let key in a) {
                if ( !a[key].decisionTitle ) return false
                if ( !a[key].decisionNumber ) return false
                if ( !a[key].decisionDate ) return false
            }
            return true
        },
        text: 'Додайте мінімум одне рішення',
    },
    // documents: {
    //     test: ( a )=> {
    //         if ( !a || a.length < 1 ) return false
    //         for ( let key in a) {
    //             if ( !a[key]?.title?.uk_UA ) return false
    //             // let id = a[key].classification.id[0] + a[key].classification.id[1]
    //         }
    //         return true
    //     },
    //     type: null,
    //     text: 'Додайте документи до ІП та заповніть всі обов\'якові поля',
    // },
    initialProps: {
        test: ( a ) => {
            let bank = []
            a.bankAccounts.forEach( i => { if ( [ 'registrationFee', 'guarantee', 'payment' ].includes(i.accountType) ) bank = [...bank, i.accountType] })
            if ( bank.includes('registrationFee') && bank.includes('guarantee') && bank.includes('payment') ) return true
            return false
        },
        text: 'Додайте банківські рахунки для: реєстраційного, гарантійного внеску та плати за лот'
    },
    extraSpec: {
        // test: ( a )=> {
        //     if ( !a ) return false
        //     if ( a === 'no' || 'yes' ) return true
        // },
        text: 'Заповніть поле "Наявність умов продажу"',
    }
}
const validationLP = {
    // title: {
    //     test: ( a )=> {
    //         if( !checkProp(a, 'uk_UA') ) return false // текст може тут одразу?
    //         if( a.uk_UA.length < 1 ) return false
    //         return true
    //     },
    //     text: 'Заповніть назву об\'єкту',
    // },
    // description: {
    //     test: ( a )=> {
    //         if( !checkProp(a, 'uk_UA') ) return false // текст може тут одразу?
    //         if( a.uk_UA.length < 1 ) return false
    //         return true
    //     },
    //     text: 'Заповніть опис об\'єкту',
    // },
    // saleCondition: {
    //     test: ( a )=> {
    //         if ( !a ) return false
    //         if ( a === 'no' || 'yes' ) return true
    //     },
    //     text: 'Заповніть поле "Наявність умов продажу"',
    // },
    decisions: {
        test: ( a )=> {
            if ( a.length < 1 ) return false
            for ( let key in a) {
                if ( !a[key].decisionTitle ) return false
                if ( !a[key].decisionNumber ) return false
                if ( !a[key].decisionDate ) return false
            }
            return true
        },
        text: 'Додайте мінімум одне рішення',
    },
    // documents: {
    //     test: ( a )=> {
    //         if ( !a || a.length < 1 ) return false
    //         for ( let key in a) {
    //             if ( !a[key]?.title?.uk_UA ) return false
    //             // let id = a[key].classification.id[0] + a[key].classification.id[1]
    //         }
    //         return true
    //     },
    //     type: null,
    //     text: 'Додайте документи до ІП та заповніть всі обов\'якові поля',
    // },
    initialProps: {
        test: ( a ) => {
            let bank = []
            a.bankAccounts.forEach( i => { if ( [ 'registrationFee', 'guarantee', 'payment' ].includes(i.accountType) ) bank = [...bank, i.accountType] })
            if ( bank.includes('registrationFee') && bank.includes('guarantee') && bank.includes('payment') ) return true
            return false
        },
        text: 'Додайте банківські рахунки для: реєстраційного, гарантійного внеску та плати за лот'
    },
    extraSpec: {
        // test: ( a )=> {
        //     if ( !a ) return false
        //     if ( a === 'no' || 'yes' ) return true
        // },
        text: 'Заповніть поле "Наявність умов продажу"',
    }
}

export function validateAsset( obj, asset ){
    let errArr = []
    for( let key in validation ){
        if ( obj.hasOwnProperty(key) ){
            if ( !validation[key].test( obj[key] ) ) {
                errArr = [...errArr, validation[key].text ]
            }
        }
    }
    if ( !!asset ) {
        let isAsset = false
        let hasImage = false
        asset.assetItems.forEach( i => { if (i.assetItemType === 'assets') isAsset = true } )
        if ( isAsset ){
            for ( let key in asset.documents){
                if ( asset.documents[key].documentType === 'illustration') hasImage = true
                
            }
            if ( !hasImage) errArr = [...errArr, 'Додайте в об\'єкт МП хоча б один документ з типом "Ілюстрація"']
        }
    }
    return errArr.length > 0 ? errArr : false
}

export function validateAssetLP( obj, asset ){
    let errArr = []
    for( let key in validationLP ){
        if ( obj.hasOwnProperty(key) ){
            if ( !validationLP[key].test( obj[key] ) ) {
                errArr = [...errArr, validationLP[key].text ]
            }
        }
    }
    if ( !!asset ) {
        let isAsset = false
        let hasImage = false
        asset.assetItems.forEach( i => { if (i.assetItemType === 'assets') isAsset = true } )
        if ( isAsset ){
            for ( let key in asset.documents){
                if ( asset.documents[key].documentType === 'illustration') hasImage = true
                
            }
            if ( !hasImage) errArr = [...errArr, 'Додайте в об\'єкт МП хоча б один документ з типом "Ілюстрація"']
        }
    }
    return errArr.length > 0 ? errArr : false
}