import React, { useState, useEffect } from 'react';
import styles from './statusesDecisions.module.css';
import { useDispatch, useSelector } from 'react-redux';

import Checkbox from '../../../../../../inputs/checkBox';
import InputTextArea from '../../../../../../inputs/inputTextArea';
import InputSelect from '../../../../../../inputs/inputSelect';
import InfoBlockСollapser from '../../../../../../elements/infoBlockСollapser/infoBlockСollapser';
import HalfInfoBlock from '../../../../../../elements/halfInfoBlock/halfInfoBlock';
import Button from '../../../../../../elements/buttons/button';

import { objectGetListTypes, objectGetStatusInList, 
    objectGetRegistrationStateList, objectGetVerification3List,
    // statusesDecisionsUpdate, 
    objectUpdate} from '../../../../../../../redux/actions/object/object.js';
import { checkProp, getTranslate } from '../../../../../../../utils/check.js'
import { myDraftObject, myObjectPrivate } from '../../../../../../../utils/endPoints';

const StatusesDecisions = ({ data, updateData, isDraft }) => {

    const dispatch = useDispatch();
    const pageCreate = useSelector(state => state.start.objectPage);
    const token = useSelector(state => state.start.token);
    const object = useSelector(state => state.start.objectGet);
    const objectCreate = useSelector(state => state.start.objectCreate);
    const objectListTypesList = useSelector(state => state.start.objectListTypesList);
    const objectStatusInList = useSelector(state => state.start.objectStatusInList);
    const objectRegistrationStateList = useSelector(state => state.start.objectRegistrationStateList);
    const objectVerification3List = useSelector(state => state.start.objectVerification3List);

    const urlObjectStatusInList = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/public/dictionaries/statusInList`;
    const urlObjectListTypesList = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/public/dictionaries/listType`;
    const urlObjectRegistrationStateList = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/public/dictionaries/registrationState`;
    const urlObjectVerification3List = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/public/dictionaries/verification3`;

    const [update, setUpdate] = useState(false);

    const [listType, setListType] = useState( !!data?.listType ? data.listType : null );
    const [statusInList, setStatusInList] = useState( !!data?.statusInList ? data.statusInList : null );
    const [registrationState, setRegistrationState] = useState( !!data?.registrationState ? data.registrationState : null );
    const [registrationRequisites, setRegistrationRequisites] = useState( !!data?.registrationRequisites ? data.registrationRequisites : null );
    const [propertyOwnerApproval, setPropertyOwnerApproval] = useState( !!data?.propertyOwnerApproval && checkProp( data.propertyOwnerApproval, 'verificationPresence') ? data.propertyOwnerApproval.verificationPresence : false);
    const [propertyOwnerApprovalDocuments, setPropertyOwnerApprovalDocuments] = useState(!!data?.propertyOwnerApproval?.x_verificationDocuments ? data.propertyOwnerApproval.x_verificationDocuments : null );
    const [sellingEntityApproval, setSellingEntityApproval] = useState( !!data?.sellingEntityApproval && checkProp( data.sellingEntityApproval, 'verificationPresence') ? data.sellingEntityApproval.verificationPresence : false );
    const [sellingEntityApprovalDocuments, setSellingEntityApprovalDocuments] = useState( !!data?.sellingEntityApproval?.x_verificationDocuments ? data.sellingEntityApproval.x_verificationDocuments : null );
    // const [governingEntityApproval, setGoverningEntityApproval] = useState(!!data.governingEntityApproval.verificationPresence ? true : false);
    const [governingEntityApprovalVerification, setGoverningEntityApprovalVerification] = useState(!!data?.governingEntityApproval ? data.governingEntityApproval.verificationPresence : false);
    const [governingEntityApprovalDocuments, setGoverningEntityApprovalDocuments] = useState(!!data?.governingEntityApproval ? data.governingEntityApproval.x_verificationDocuments : null);
    const [maintenanceRepairAgreement, setMaintenanceRepairAgreement] = useState(!!data?.maintenanceRepairAgreement && checkProp( data.maintenanceRepairAgreement, 'verificationPresence') ? data.maintenanceRepairAgreement.verificationPresence : false);
    const [maintenanceRepairAgreementDocuments, setMaintenanceRepairAgreementDocuments] = useState(!!data?.maintenanceRepairAgreement ? data.maintenanceRepairAgreement.x_verificationDocuments : null);
    const [privatizationDecision, setPrivatizationDecision] = useState(!!data?.privatizationDecision && checkProp( data.privatizationDecision, 'verificationPresence') ? data.privatizationDecision.verificationPresence : false);
    const [privatizationDecisionDocuments, setPrivatizationDecisionDocuments] = useState(!!data?.privatizationDecision ? data.privatizationDecision.x_verificationDocuments : null);
    const [investmentDecision, setInvestmentDecision] = useState( !!data?.investmentDecision && checkProp( data.investmentDecision, 'verificationPresence') ? data.investmentDecision.verificationPresence : false);
    const [investmentDecisionDocuments, setInvestmentDecisionDocuments] = useState(!!data?.investmentDecision ? data.investmentDecision.x_verificationDocuments : null);
    const [culturalHeritage, setCulturalHeritage] = useState(!!data?.culturalHeritage ? data.culturalHeritage : null );
    const [culturalHeritageCondition, setCulturalHeritageCondition] = useState( !!data?.culturalHeritageCondition ? data.culturalHeritageCondition : null );
    // const [approvalCulturalHeritage, setApprovalCulturalHeritage] = useState(checkProp(data.statusesDecisions.heritageApproval, 'verificationPresence') ? (data.statusesDecisions.heritageApproval.verificationPresence ? true : false ) : null);
    const [heritageApprovalVerification, setHeritageApprovalVerification] = useState( !!data?.heritageApproval ? data.heritageApproval.verificationPresence : null ); ////main
    const [heritageApprovalDocuments, setHeritageApprovalDocuments] = useState( !!data?.heritageApproval ? data.heritageApproval.x_verificationDocuments : null);

    const [errorRegistrationState, setErrorRegistrationState] = useState(null);
    // const [errorGovEntApprov, setErrorGovEntApprov] = useState(null);
    const [errorGoverningEntity, setErrorGoverningEntity] = useState(null);
    
    useEffect(() => {
        if(objectListTypesList === null || objectListTypesList === undefined) {
            dispatch(objectGetListTypes(urlObjectListTypesList));
        }
        if(objectStatusInList === null || objectStatusInList === undefined) {
            dispatch(objectGetStatusInList(urlObjectStatusInList));
        }
        if(objectRegistrationStateList === null || objectRegistrationStateList === undefined) {
            dispatch(objectGetRegistrationStateList(urlObjectRegistrationStateList));
        }
        if(objectVerification3List === null || objectVerification3List === undefined) {
            dispatch(objectGetVerification3List(urlObjectVerification3List));
        }
    }, []);

    let statusesDecisions = {};

    useEffect(() => {
        if (!checkErrors()) {
            statusesDecisions = {
                listType: listType,
                statusInList: statusInList,
                registrationState: registrationState,
                registrationRequisites: registrationRequisites,
                culturalHeritage: culturalHeritage,
                culturalHeritageCondition: culturalHeritageCondition,
                propertyOwnerApproval: {
                    x_verificationDocuments: propertyOwnerApprovalDocuments,
                    verificationPresence: propertyOwnerApproval === true || propertyOwnerApproval === 'true' ? true : false
                },
                sellingEntityApproval: {
                    x_verificationDocuments: sellingEntityApprovalDocuments,
                    verificationPresence: sellingEntityApproval === true || sellingEntityApproval === 'true' ? true : false
                },
                governingEntityApproval: {
                    x_verificationDocuments: governingEntityApprovalDocuments,
                    verificationPresence: governingEntityApprovalVerification
                },
                maintenanceRepairAgreement: {
                    x_verificationDocuments: maintenanceRepairAgreementDocuments,
                    verificationPresence: maintenanceRepairAgreement === true || maintenanceRepairAgreement === 'true' ? true : false
                },
                privatizationDecision: {
                    x_verificationDocuments: privatizationDecisionDocuments,
                    verificationPresence: privatizationDecision === true || privatizationDecision === 'true' ? true : false
                },
                investmentDecision: {
                    x_verificationDocuments: investmentDecisionDocuments,
                    verificationPresence: investmentDecision === true || investmentDecision === 'true' ? true : false
                },
                heritageApproval: {
                    x_verificationDocuments: heritageApprovalDocuments,
                    verificationPresence: heritageApprovalVerification === false ? null : heritageApprovalVerification
                }
            }
            updateData(statusesDecisions);
        }
    }, [listType, statusInList, registrationState, registrationRequisites, propertyOwnerApprovalDocuments, 
        propertyOwnerApproval, sellingEntityApprovalDocuments, sellingEntityApproval, governingEntityApprovalDocuments, 
        governingEntityApprovalVerification, maintenanceRepairAgreementDocuments, maintenanceRepairAgreement, 
        privatizationDecisionDocuments, privatizationDecision, investmentDecisionDocuments, investmentDecision, 
        culturalHeritage, culturalHeritageCondition, heritageApprovalDocuments, heritageApprovalVerification]);

    function checkErrors() {
        if (registrationState === null) {
            setErrorRegistrationState('Значення не повинно бути пустим.');
            return true
        } else {
            //Reset errors
            setErrorRegistrationState(null);
            return false
        }
    }

    function checkErrors() {
        if (objectCreate) {
            if (objectCreate.hasOwnProperty('message') === true) {
                //Reset errors
                setErrorRegistrationState('');
                // setErrorSellingEntity('');
                // setErrorOwnershipType('');
                //New errors
                for (let key in objectCreate.message) {
                    if (key === 'data.registrationState') {
                        setErrorRegistrationState(objectCreate.message[key]);
                        window.scrollTo(0, 1400);
                    }
                    // else if (key === 'relatedOrganizations.sellingEntity') {
                    //         setErrorSellingEntity(objectCreate.message[key]);
                    //         window.scrollTo(0, 1400);
                    //     } else if (key === 'relatedOrganizations.ownershipType') {
                    //         setErrorOwnershipType(objectCreate.message[key]);
                    //         window.scrollTo(0, 1400);
                    //     }
                    }
                } else {
                    //Reset errors
                    setErrorRegistrationState('');
                    // setErrorSellingEntity('');
                    // setErrorOwnershipType('');
                }
            }
    }

    function updateObject() {
        if (token !== null) {
            statusesDecisions = JSON.stringify({
                statusesDecisions: {
                    listType: listType,
                    statusInList: statusInList,
                    registrationState: registrationState,
                    registrationRequisites: registrationRequisites,
                    culturalHeritage: culturalHeritage,
                    culturalHeritageCondition: culturalHeritageCondition,
                    propertyOwnerApproval: {
                        x_verificationDocuments: propertyOwnerApprovalDocuments,
                        verificationPresence: propertyOwnerApproval === 'true' || propertyOwnerApproval === true ? true : false
                    },
                    sellingEntityApproval: {
                        x_verificationDocuments: sellingEntityApprovalDocuments,
                        verificationPresence: sellingEntityApproval === 'true' || sellingEntityApproval === true ? true : false
                    },
                    governingEntityApproval:{
                            // get x_verificationDocuments(){
                            //     if ( governingEntityApprovalVerification ) return governingEntityApprovalDocuments
                            // },
                            x_verificationDocuments: governingEntityApprovalDocuments,
                            verificationPresence: governingEntityApprovalVerification
                    },
                    maintenanceRepairAgreement: {
                        x_verificationDocuments: maintenanceRepairAgreementDocuments,
                        verificationPresence: maintenanceRepairAgreement === 'true' || maintenanceRepairAgreement === true ? true : false
                    },
                    privatizationDecision: {
                        x_verificationDocuments: privatizationDecisionDocuments,
                        verificationPresence: privatizationDecision === 'true' || privatizationDecision === true ? true : false
                    },
                    investmentDecision: {
                        x_verificationDocuments: investmentDecisionDocuments,
                        verificationPresence: investmentDecision === 'true' || investmentDecision === true ? true : false
                    },
                    heritageApproval: {
                        // get x_verificationDocuments(){
                        //     if ( heritageApprovalVerification ) return heritageApprovalDocuments
                        // },
                        x_verificationDocuments: heritageApprovalDocuments,
                        verificationPresence: heritageApprovalVerification === false ? null : heritageApprovalVerification
                    }
                }
            });
            // let urlUpdate = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/registry_object/${object.id}/statusesDecisions`;
            let redirect = `/lease/create/${object.id}`;
            const getUrl =( id )=> {
                if(isDraft) return myDraftObject(id)
                return myObjectPrivate(id)
            }
            dispatch( objectUpdate(getUrl(object.id), statusesDecisions, token.access_token, redirect));
        } else {
            alert('Авторизуйтесь');
        }
    }

    // function setUpdateValues(a) {
    //     setUpdate(a);
    // }

    const editBlock = <>
                            <InputSelect
                                value={listType}
                                label="Тип переліку, до якого віднесено об`єкт *"
                                onChange={setListType} 
                                options={objectListTypesList} 
                                dictionary={true} 
                                text="Оберіть"
                                descr={<div style={{ border: '1px solid #FFD200' , borderRadius: '4px', padding: '10px', fontSize: '12px', color: '#fgfdgg' }}>
                                        <div style={{ fontSize: '12px', color: '#92929D' }}><b>Увага!</b><br />Для організації аукціону на новий договір оренди потрібно присвоїти об'єкту тип переліку <b>"Перелік першого типу"</b></div>
                                        <div style={{ fontSize: '12px', color: '#92929D' }}>Для організації аукціону з продовження договору оренди потрібно:
                                            <li>- вказати чинного орендаря</li>
                                            <li>- присвоїти об'єкту тип переліку <b>"Не визначено"</b></li>
                                        </div>
                                    </div>
                                }
                            />
                            <InputSelect 
                                value={statusInList}
                                label="Статус об`єкту в переліку" 
                                onChange={setStatusInList} 
                                options={objectStatusInList} 
                                dictionary={true} 
                                text="Оберіть"
                            />
                            <InputSelect 
                                value={registrationState}
                                label="Стан державної реєстрації об`єкту"
                                onChange={setRegistrationState} 
                                options={objectRegistrationStateList} 
                                dictionary={true}
                                text="Оберіть"
                                error={errorRegistrationState}
                                reqiured
                            />
                            {registrationState === 'properlyRegistered' || registrationState === 'oldRegistration' ?
                                <InputTextArea
                                    value={registrationRequisites}
                                    label="Реквізити державної реєстрації об`єкту"
                                    onChange={setRegistrationRequisites}
                                    descr="Найменування органу; Номер документу; Дата документу"
                                /> : null
                            }
                                <InputSelect
                                    value={governingEntityApprovalVerification}
                                    label="Погодження уповноваженого органу управління, наявність та необхідність рішення *" 
                                    onChange={setGoverningEntityApprovalVerification} 
                                    options={objectVerification3List} 
                                    dictionary={true} 
                                    text="Оберіть"
                                    error={errorGoverningEntity}
                                />
                                {governingEntityApprovalVerification === 'present' ?
                                    <InputTextArea
                                        label="Реквізити підтверджуючого документу"
                                        value={governingEntityApprovalDocuments}
                                        onChange={setGoverningEntityApprovalDocuments}
                                        descr="Найменування органу; Номер документу; Дата документу"
                                    />
                                    : null
                                }
                            <Checkbox
                                value={propertyOwnerApproval} 
                                onChange={setPropertyOwnerApproval} 
                                label="Наявність рішення про намір передачі майна в оренду (про доцільність передачі єдиного майнового комплексу в оренду)"
                            />
                            {propertyOwnerApproval ?
                            <InputTextArea
                                value={propertyOwnerApprovalDocuments} 
                                label="Реквізити підтверджуючого документу" 
                                onChange={setPropertyOwnerApprovalDocuments} 
                                descr="Найменування органу; Номер документу; Дата документу"
                            />
                                : null
                            }
                            <Checkbox
                                value={sellingEntityApproval} 
                                onChange={setSellingEntityApproval} 
                                label="Наявність рішення про включення в перелік"
                            />
                            {sellingEntityApproval ?
                                <InputTextArea
                                    value={sellingEntityApprovalDocuments}
                                    label="Реквізити підтверджуючого документу" 
                                    onChange={setSellingEntityApprovalDocuments} 
                                    descr="Найменування органу; Номер документу; Дата документу"
                                />
                                : null
                            }
                            <Checkbox
                                value={maintenanceRepairAgreement} 
                                onChange={setMaintenanceRepairAgreement} 
                                label="Інформація про згоду на здійснення поточного та/або капітального ремонту орендованого майна"
                            />
                            {maintenanceRepairAgreement ?
                                <InputTextArea
                                    value={maintenanceRepairAgreementDocuments} 
                                    label="Реквізити підтверджуючого документу" 
                                    onChange={setMaintenanceRepairAgreementDocuments} 
                                    descr="Найменування органу; Номер документу; Дата документу"
                                />
                                : null
                            }
                            <Checkbox
                                value={privatizationDecision} 
                                onChange={setPrivatizationDecision} 
                                label="Наявність рішення про включення об’єкта до переліку майна, що підлягає приватизації"
                            />
                            {privatizationDecision ?
                                <InputTextArea
                                    value={privatizationDecisionDocuments} 
                                    label="Реквізити підтверджуючого документу" 
                                    onChange={setPrivatizationDecisionDocuments} 
                                    descr="Найменування органу; Номер документу; Дата документу"
                                />
                            : null}
                            <Checkbox
                                value={investmentDecision} 
                                onChange={setInvestmentDecision} 
                                label="Наявність рішення про проведення інвестиційного конкурсу"
                            />
                            {investmentDecision ?
                                <InputTextArea
                                    value={investmentDecisionDocuments} 
                                    label="Реквізити підтверджуючого документу" 
                                    onChange={setInvestmentDecisionDocuments} 
                                    descr="Найменування органу; Номер документу; Дата документу"
                                />
                            : null}
                            <Checkbox
                                value={culturalHeritage}
                                onChange={setCulturalHeritage} 
                                label="Чи є об`єкт пам`яткою культурної спадщини"
                            />
                            <InputSelect
                                value={heritageApprovalVerification}
                                label="Наявність та необхідність рішення про погодження органу охорони культурної спадщини *" 
                                onChange={setHeritageApprovalVerification} 
                                options={objectVerification3List} 
                                dictionary={true} 
                                text="Оберіть"
                            />
                            {culturalHeritage ?
                                <>
                                    <InputTextArea
                                        value={culturalHeritageCondition}
                                        label="Стан пам`ятки культурної спадщини" 
                                        onChange={setCulturalHeritageCondition}
                                    />
                                    {heritageApprovalVerification === 'present' ?
                                        <InputTextArea
                                            value={heritageApprovalDocuments}
                                            label="Реквізити підтверджуючого документу"
                                            onChange={setHeritageApprovalDocuments}
                                            descr="Найменування органу; Номер документу; Дата документу"
                                        /> : null
                                    }
                                </> : null
                            }
                    </>

    return <> {pageCreate === 'create' ?
            <div className={styles.wrapper}>
                <div className={styles.title}>Дані про наявність офіційних рішень, статусів тощо</div>
                {editBlock}
            </div>
            :
        <InfoBlockСollapser 
            // value={ data.statusesDecisions }/
            blockTitle={`Дані про наявність офіційних рішень, статусів тощо`}
            collapsed={false}
            button={
                !update ?
                            <Button color={'white'} height={'30px'} onClick={() => setUpdate(true)}>Редагувати</Button>
                            : 
                            <div style={{display: 'flex', gap: '8px'}}>
                                <Button color={'blue'} height={'30px'} onClick={() => updateObject()}>Зберегти</Button>
                                <Button onClick={() => setUpdate(false) } color={'gray'} height={'30px'}>X</Button>
                            </div>
            }
            content={<>
                {update ? 
                    editBlock
                    : 
                    <div className={styles.infoWrapper}>
                        {checkProp(data, 'listType') ?
                            <HalfInfoBlock
                                title='Тип переліку, до якого віднесено об`єкт'
                                data={getTranslate(data.listType, objectListTypesList)} />
                            : null
                        }
                        {checkProp(data,'statusInList') ?
                            <HalfInfoBlock 
                                title='Статус об`єкту в переліку'
                                data={getTranslate(data.statusInList, objectStatusInList)} />
                        : ''}
                        {checkProp(data,'registrationState') ?
                            <HalfInfoBlock 
                                title='Стан державної реєстрації об`єкту'
                                data={getTranslate(data.registrationState, objectRegistrationStateList)} />
                        : null}
                        {checkProp(data,'registrationRequisites') && ( registrationState === 'properlyRegistered' || registrationState === 'oldRegistration') ?
                            <HalfInfoBlock 
                                title='Реквізити державної реєстрації об`єкту'
                                data={data.registrationRequisites}
                            />
                            : null}
                        { !!data?.culturalHeritage &&
                        <HalfInfoBlock 
                            title='Чи є об`єкт пам`яткою культурної спадщини'
                                data={ data.culturalHeritage ? 'Так' : 'Ні'}
                        />
                        }
                        {checkProp(data,'culturalHeritage') ?
                                checkProp(data, 'culturalHeritageCondition') ?
                                    <HalfInfoBlock
                                        title='Стан пам`ятки культурної спадщини'
                                        data={data.culturalHeritageCondition}
                                    /> : null
                        : null}
                        { ( !!data?.heritageApproval && checkProp(data.heritageApproval, 'verificationPresence') ) ? 
                            <div className={styles.groupWrapper}>
                                <HalfInfoBlock 
                                    title='Наявність та необхідність рішення про погодження органу охорони культурної спадщини'
                                    data={getTranslate(data.heritageApproval.verificationPresence, objectVerification3List)}
                                />
                                {data.heritageApproval.verificationPresence === 'present' ?
                                    <HalfInfoBlock
                                        title='Реквізити підтверджуючого документу'
                                        data={data.heritageApproval.x_verificationDocuments}
                                    /> : null
                                }
                            </div>
                        : null}
                        { !!data?.propertyOwnerApproval?.verificationPresence &&
                            <div className={styles.groupWrapper}>
                                <HalfInfoBlock 
                                    title='Наявність рішення про намір передачі майна в оренду'
                                    data='Присутня' 
                                />
                                <HalfInfoBlock 
                                    title='Реквізити підтверджуючого документу'
                                    data={data.propertyOwnerApproval.x_verificationDocuments}
                                />
                            </div>
                        }
                        { !!data?.sellingEntityApproval && data.sellingEntityApproval.verificationPresence ? 
                            <div className={styles.groupWrapper}>
                                <HalfInfoBlock 
                                    title='Наявність рішення про включення в перелік'
                                    data='Присутня' />
                                <HalfInfoBlock 
                                    title='Реквізити підтверджуючого документу'
                                    data={data.sellingEntityApproval.x_verificationDocuments} />
                            </div>
                        : null}
                        { !!data?.governingEntityApproval && data.governingEntityApproval.verificationPresence ? 
                            <div className={styles.groupWrapper}>
                                <HalfInfoBlock 
                                    title='Погодження уповноваженого органу управління'
                                    data={getTranslate(data.governingEntityApproval.verificationPresence, objectVerification3List)} />
                                    {data.governingEntityApproval.verificationPresence === 'present' ?
                                        <HalfInfoBlock
                                            title='Реквізити підтверджуючого документу'
                                            data={data.governingEntityApproval.x_verificationDocuments}
                                        /> : null
                                    }
                            </div>
                        : null}
                        { !!data?.maintenanceRepairAgreement && data.maintenanceRepairAgreement.verificationPresence ? 
                            <div className={styles.groupWrapper}>
                                <HalfInfoBlock 
                                    title='Інформація про згоду на здійснення поточного та/або капітального ремонту орендованого майна'
                                    data={data.maintenanceRepairAgreement.verificationPresence  ? 'Так' : 'Ні'} />
                                {maintenanceRepairAgreement ? <HalfInfoBlock 
                                            title='Реквізити підтверджуючого документу'
                                            data={data.maintenanceRepairAgreement.x_verificationDocuments}
                                        /> : null 
                                }
                            </div>
                        : null}
                        { !!data?.privatizationDecision && data.privatizationDecision.verificationPresence ? 
                            <div className={styles.groupWrapper}>
                                <HalfInfoBlock 
                                    title='Наявність рішення про включення об’єкта до переліку майна, що підлягає приватизації'
                                    data={data.privatizationDecision.verificationPresence  ? 'Присутнє' : 'Вісутнє'} />
                                <HalfInfoBlock 
                                    title='Реквізити підтверджуючого документу'
                                    data={data.privatizationDecision.x_verificationDocuments} />
                            </div>
                        : null}
                        { !!data?.investmentDecision && data.investmentDecision.verificationPresence ? 
                            <div className={styles.groupWrapper}>
                                <HalfInfoBlock 
                                    title='Наявність рішення про проведення інвестиційного конкурсу'
                                    data='Присутня'
                                />
                                <HalfInfoBlock 
                                    title='Реквізити підтверджуючого документу'
                                    data={data.investmentDecision.x_verificationDocuments}
                                />
                            </div>
                        : null}
                    </div>
                }
            </>
            }
        />
    }
    </>;
}

export default StatusesDecisions;