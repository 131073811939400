import React, { useEffect, useState} from 'react';
import styles from './objectCreate.module.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import SideBlock from './blocks/sideBlock/sideBlock';
import MainBlock from './blocks/mainBlock/mainBlock';
import InactiveObjectPopup from './inactiveObjectPopup/inactiveObjectPopup';
import DeletePopup from './deletePopup/deletePopup';

import { objectGet, objectClear, objectDelete, setPageCreate, 
    setObjectCreationStep, setInactiveObjectPopup, setObjectDeletePopup
} from '../../../redux/actions/object/object';
import { checkProp, isMongo } from '../../../utils/check';
import ObjectPublish from './blocks/publishBtn/publishBtn';
import { myDraftObject, myObjectPrivate } from '../../../utils/endPoints';
import SendObjectToProzoro from './blocks/validation/sendToProzoro';

const ObjectCreate = ( props ) => {

    const dispatch = useDispatch();
    const [isDraft, setIsDraft] = useState(false)
    const token = useSelector(state => state.start.token);
    const objectCreationStep = useSelector(state => state.start.objectCreationStep);
    const inactiveObjectPopup = useSelector(state => state.start.inactiveObjectPopup);
    const objectDeletePopup = useSelector(state => state.start.objectDeletePopup);
    const objectPage = useSelector(state => state.start.objectPage);
    const draftObject = useSelector(state => state.start.objectGet);
    const { pathname } = useLocation()

    useEffect(() => {
        if (pathname.indexOf('/create/') !== -1) {
            dispatch(objectClear());
            let id = pathname.split('/create/')[1].split('/')[0];
            let items = pathname.split('/create/')[1].split('/')[1];
            if (id !== '' && token !== null) {
                if(id.indexOf('-') !== -1) setIsDraft(true)
                if (items && items.length > 0) {
                    dispatch(setPageCreate('objectItems'));
                } else {
                    dispatch(setPageCreate('update'));
                }

                const urlObject =()=> {
                    if(id.indexOf('-') !== -1) return myDraftObject(id)
                    return myObjectPrivate(id)
                } 
                dispatch( objectGet( urlObject(), token.access_token) );
            } else {
                 dispatch(setPageCreate('create'))
                //  useNavigate('/lease/objects_list')
            }
            if (pathname.indexOf('objectItems') !== -1) {
                dispatch(setPageCreate('objectItems'));
            }
            if (pathname.indexOf('documents') !== -1) {
                dispatch(setPageCreate('documents'));
            }
            if (pathname.indexOf('contracts') !== -1) {
                dispatch(setPageCreate('contracts'));
            }
        } else {
            dispatch(setObjectCreationStep(0));
        }
    }, []);

    useEffect(() => {
        if (draftObject !== null && draftObject.hasOwnProperty("message") !== true) {
            if (draftObject.registryObjectItems.length === 0) {
                dispatch(setObjectCreationStep(1))
            } else if (draftObject.documents.length === 0) {
                dispatch(setObjectCreationStep(2));
            } else {
                dispatch(setObjectCreationStep(3));
            }
        }
    }, [draftObject]);

    let path = 'Загальна інформація'
    if (window.location.href.indexOf('objectItems') !== -1) {
        path = 'Об\'єкти'
    }
    if (window.location.href.indexOf('documents') !== -1) {
        path = 'Документи'
    }
    if (window.location.href.indexOf('requests') !== -1) {
        path = 'Заяви'
    }
    if (window.location.href.indexOf('actions') !== -1) {
        path = 'Дії'
    }
    if (window.location.href.indexOf('contracts') !== -1) {
        path = 'Договори'
    }
        return (
        <div className={styles.wrapPage}>
            <div className={styles.navBlock}>
                <ul className={styles.navUl}>
                    <li><Link to={`/lease/my_objects`}>Мої об’єкти</Link></li>
                        <li>
                            {/* <Link to={`/lease/create/`}> */}
                                Створення об’єкту
                            {/* </Link> */}
                        </li>
                    <li>{path}</li>
                </ul>
                <div className={styles.titleButtonWrap}>
                    <h1 className={styles.objTitle}>{draftObject !== null ? isMongo(draftObject.title) : 'Створення об’єкту'}</h1>
                    {  ( objectPage !== 'create' && !draftObject?.registryObjectId ) && 
                        <div className={styles.buttonWrapper}>
                            <SendObjectToProzoro data={draftObject} />
                            <button className={styles.buttonDelete} onClick={() => dispatch(setObjectDeletePopup(true)) }>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M5.66331 2.7L6.33539 0.683772C6.4715 0.27543 6.85364 0 7.28407 0H12.6841C13.1145 0 13.4966 0.27543 13.6328 0.683772L14.3048 2.7H18.984C19.5363 2.7 19.984 3.14772 19.984 3.7C19.984 4.25229 19.5363 4.7 18.984 4.7H18.127L17.3931 17.1762C17.2998 18.7619 15.9867 20 14.3983 20H5.56988C3.98145 20 2.66833 18.7619 2.57506 17.1762L1.84116 4.7H1C0.447715 4.7 0 4.25229 0 3.7C0 3.14772 0.447715 2.7 1 2.7H5.66331ZM7.7715 2.7H12.1966L11.9633 2H8.00483L7.7715 2.7ZM16.1235 4.7H3.84462L4.57161 17.0587C4.6027 17.5873 5.0404 18 5.56988 18H14.3983C14.9277 18 15.3654 17.5873 15.3965 17.0587L16.1235 4.7ZM11.686 7.23762C11.7205 6.68641 12.1952 6.2675 12.7464 6.30195C13.2977 6.3364 13.7166 6.81117 13.6821 7.36238L13.2321 14.5624C13.1977 15.1136 12.7229 15.5325 12.1717 15.4981C11.6205 15.4636 11.2016 14.9888 11.236 14.4376L11.686 7.23762ZM8.73212 14.4376C8.76657 14.9888 8.34766 15.4636 7.79645 15.4981C7.24524 15.5325 6.77047 15.1136 6.73602 14.5624L6.28602 7.36238C6.25157 6.81117 6.67048 6.3364 7.22169 6.30195C7.7729 6.2675 8.24767 6.68641 8.28212 7.23762L8.73212 14.4376Z" fill="#FFA047"></path>
                                </svg>
                            </button>
                        </div>
                    }
                </div>
                <div className={styles.objNav}>
                    { objectCreationStep > 0 ?
                        checkProp(draftObject, 'id') ?
                            <Link 
                                to={`/lease/create/${draftObject.id}`} 
                                onClick={() => dispatch(setPageCreate('update'))}
                                className={objectPage === 'create' || objectPage === 'update' ? styles.activeNavItem : styles.objNavItem}>
                                Загальна інформація
                            </Link> : null
                    :
                        <div className={styles.activeNavItem}>Загальна інформація</div>
                    }
                    { objectCreationStep > 0 ?
                        checkProp(draftObject, 'id') ?
                            <Link 
                                to={`/lease/create/${draftObject.id}/objectItems`} 
                                onClick={() => dispatch(setPageCreate('objectItems'))}
                                className={objectPage === 'objectItems' ? styles.activeNavItem : styles.objNavItem}>
                                Об’єкти ({draftObject !== null ? draftObject.registryObjectItems.length : 0})
                            </Link> : null
                    :
                        <div className={checkProp(draftObject, 'registryObjectItems') ? styles.objNavItem : styles.objNavItemInactive}>Об’єкти (0)</div>
                    }
                    { objectCreationStep > 1 ?
                        checkProp(draftObject, 'id') ?
                            <Link 
                                to={`/lease/create/${draftObject.id}/documents`} 
                                onClick={() => dispatch(setPageCreate('documents'))}
                                className={objectPage === 'documents' ? styles.activeNavItem : styles.objNavItem}>
                                Документи ({draftObject !== null ? draftObject.documents.length : 0})
                            </Link> : null
                    :
                        <div className={ checkProp(draftObject, 'documents') && draftObject.registryObjectItems.length > 0 ? styles.objNavItem : styles.objNavItemInactive}>Документи (0)</div>
                    }
                    { checkProp(draftObject, 'registryObjectId') &&
                            <Link 
                                to={`/lease/create/${draftObject.id}/contracts`} 
                                onClick={() => dispatch(setPageCreate('contracts'))}
                                className={objectPage === 'contracts' ? styles.activeNavItem : styles.objNavItem}>
                                Договори 
                                {/* ({ !!draftObject ? draftObject.contracts.length : 0 }) */}
                            </Link>
                    }
                    {/* <div className={checkProp(draftObject, 'status') ? styles.objNavItem : styles.objNavItemInactive}>
                        Заяви (0)
                    </div>
                    <div className={checkProp(draftObject, 'status') ? styles.objNavItem : styles.objNavItemInactive}>
                        Дії (0)
                    </div>
                    <div className={checkProp(draftObject, 'status') ? styles.objNavItem : styles.objNavItemInactive}>
                        Договори оренди (0)
                    </div> */}
                </div>
            </div>
            <div className={styles.wrapContent}>
                <SideBlock />
                <MainBlock isDraft={isDraft} />
            </div>
            {inactiveObjectPopup &&
                <InactiveObjectPopup />
            }
            {objectDeletePopup &&
                <DeletePopup id={draftObject.id} />
            }
        </div>
        );
}

export default ObjectCreate;