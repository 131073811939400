import React ,{ useEffect } from 'react'
import { createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import {useSelector, useDispatch} from 'react-redux';
import { localGetToken, setIntervalRef, postRefToken } from '../redux/actions/default/default';


export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null)
    const userProfiles = useSelector(state => state.start.userProfiles);
    const keySetIntervalRefresh = useSelector(state => state.start.keySetIntervalRefresh);
    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
      if ( !!userProfiles ) setUser( userProfiles )
    }, [userProfiles] )

    const signin = (newUser, cb) => {
        window.localStorage.setItem(newUser, null)
        setUser(newUser)
        cb();
    }

    const signout = (cb) => {
        setUser(null)
        window.localStorage.setItem('userState', null)
        navigate('/', { replace: false } )
    }

    const value = { user, signin, signout}

    let tmpLocal = null;
    let tmpNowTimeStamp = null;
      if (localStorage.getItem('token') !== null) {
        tmpLocal = JSON.parse(localStorage.getItem('token'));
        tmpNowTimeStamp = Date.now();
        if(tmpLocal.timeStamp < tmpNowTimeStamp) {
              let tmpClearToken = {
              access_token: "",
              expires_in: 3600,
              refresh_token: "",
              timeStamp: 0,
              token_type: "",
              };
              localStorage.setItem('token', JSON.stringify(tmpClearToken));
        } else {
            if (keySetIntervalRefresh === null) {
                dispatch( localGetToken(tmpLocal) );
                let tmpInterval = setInterval(() => {
                    tmpLocal = JSON.parse( localStorage.getItem('token') );
                    dispatch( postRefToken(process.env.REACT_APP_END_POINT_BD, tmpLocal.refresh_token) );
                }, 600000);
                    dispatch( setIntervalRef(tmpInterval) );
                }
            }
      }

    return <AuthContext.Provider value={value} >
        { children }
    </AuthContext.Provider>
}