export function setRedemptionDocTypes(status ){
    // let illustration = true
    // let tmpDate = Date.now()
    let result = [
        { value: 'redemptionRights', name: 'Підтвердження права на викуп', main: false },
        { value: 'redemptionNotice', name: 'Рішення про приватизацію об\'єкта шляхом викупу', main: true },
        { value: 'contractSigned', name: 'Підписаний договір', main: true },
        { value: 'contractAnnexe', name: 'Додатки до договору', main: false },
    ]
    if (status === 'pending') result = [...result, { value: 'clarifications', name: `Документ з інформацією про виправлення помилок`, main: false } ]
    return result
}

// export function getAssetItemType(id, aucType){ // функция нужна для определения типа айтема, и вывода нужных доков
//     if (!id) return
//     let tmpId = id[0] + id[1]
//         if(['07','08'].includes(tmpId)) return 'claimRights'
//         if(['04','05','06','16','30','32','34','39','42'].includes(tmpId)) return 'asset'
//         return null
// }