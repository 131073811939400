import React from "react"

const DownLoadIcon =( { color = '#92929D', style, widthHeight = [20, 20] } )=> {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
            style={{display:'flex'}}
            className={style}
            width={widthHeight[0]}
            height={widthHeight[1]}
            viewBox="0 0 16 20"
            fill="none" 
            strokeWidth={1}
        >
            <path 
                fill={color} 
                fillRule="evenodd" 
                clipRule="evenodd" 
                d="M3.80002 0.899902C1.70134 0.899902 1.90735e-05 2.60122 1.90735e-05 4.6999V16.0999C1.90735e-05 18.1986 1.70134 19.8999 3.80002 19.8999H11.4C13.4987 19.8999 15.2 18.1986 15.2 16.0999V7.31645C15.2 6.88098 15.0504 6.45873 14.7763 6.12038L11.1171 1.60384C10.7563 1.15856 10.2139 0.899902 9.64078 0.899902H3.80002ZM11.0438 4.53159V6.0299H12.2577L11.0438 4.53159ZM3.80002 2.7999C2.75068 2.7999 1.90002 3.65056 1.90002 4.6999V16.0999C1.90002 17.1493 2.75068 17.9999 3.80002 17.9999H11.4C12.4494 17.9999 13.3 17.1493 13.3 16.0999V7.92991H11.0438C9.99443 7.92991 9.14377 7.07925 9.14377 6.0299V2.7999H3.80002ZM8.55002 12.7749L8.93002 12.4899C9.34975 12.1751 9.94521 12.2602 10.26 12.6799C10.5748 13.0996 10.4898 13.6951 10.07 14.0099L8.17769 15.4292C8.01762 15.5519 7.81734 15.6249 7.60002 15.6249C7.38269 15.6249 7.18241 15.5519 7.02234 15.4292L5.13002 14.0099C4.71028 13.6951 4.62522 13.0996 4.94002 12.6799C5.25482 12.2602 5.85028 12.1751 6.27002 12.4899L6.65002 12.7749V9.08073C6.65002 8.55606 7.07535 8.13073 7.60002 8.13073C8.12469 8.13073 8.55002 8.55606 8.55002 9.08073V12.7749Z"
            />
        </svg>
    )
}

export default DownLoadIcon