import React, {useState} from 'react';
import styles from './bankAccounts.module.css';

import {useSelector} from 'react-redux';

import DeleteIcon from '../../../../../elements/svg/deleteIcon';
import CancelIcon from '../../../../../elements/svg/cancelIcon';

import { checkProp, isMongo } from '../../../../../../utils/check';

const BankItem = ( { data, bankAccounts, setBankAccounts, group, disabledBtn, allDel, ...props} ) => {
    const bankNew = (checkProp(data, 'new') && data.new === true)
    const bankDel = (checkProp(data, 'del') && data.del === true)

    let tmpBankName = isMongo(data.bankName)
    let tmpBankDescription = isMongo(data.description)
    let tmpAccount = checkProp(data.accountIdentifications[data.accountIdentifications.length - 1], 'identifier')
    ? data.accountIdentifications[data.accountIdentifications.length - 1].identifier : data.accountIdentifications[data.accountIdentifications.length - 1].id

    function setDelStyle(color){
        if (!bankDel) return {color: color, display: 'flex', flexFlow: 'row', gap: '8px', alignItems: 'center'}
        else return {color: '#BFBFC6', display: 'flex', flexFlow: 'row', gap: '8px', alignItems: 'center'}
    }

    function setItemStyle(){
        if (bankNew) return `${styles.bankWrap} ${styles.bankNew}`
        return styles.bankWrap
    }

    return (
        <div 
            className={setItemStyle()}
            key={data.id}
        >
            {/* {bankNew ? <div className={styles.bankTmpGroup}>Банк буде додано після збереження змін</div> : null } */}
            <div className={styles.banksNameLine}>
                <div style={setDelStyle('#696974')}>{tmpBankName}</div>
                <div style={setDelStyle('#92929D')}>
                    {data.currency} 
                    <div style={{fontSize: '13px'}}>{tmpAccount}</div>
                </div>
                <div style={setDelStyle('#92929D')}>{tmpBankDescription}</div>
            </div>
            {   <button className={styles.deleteBtn}
                    // disabled={elementDisabledCreate()}
                    title={ 'Видалити' }
                    variant="contained"
                    onClick={ ()=> {
                        setBankAccounts( prev => {
                            let tmp = JSON.parse(JSON.stringify(prev))
                            // if (tmp.length === 1 && tmp.accounts.length === 1) return []
                            for ( let key in tmp){
                                if (!!tmp[key] && tmp[key].accountType === group ) {
                                    for (let i in tmp[key].accounts ) {
                                        if ( tmp[key].accounts[i].id === data.id) {
                                            // delete prev[key].accounts[i]
                                            tmp[key].accounts.splice( i, 1)
                                        }
                                        if (tmp[key].accounts.length === 0) {
                                            tmp.splice( key, 1)
                                        }
                                    }
                                }
                            }
                            return tmp
                        })
                    }}
                >
                    <DeleteIcon /> 
                </button> 
            }
        </div>
    )


}

export default BankItem