import React,{ useEffect, useState } from 'react';
import styles from './leaseActionEdit.module.css';
import { Link, useLocation } from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import LeaseActionEditTitle from './elems/titleBar/editTitleBar';
import LeaseActionEditSidebar from './elems/sidebar/editSidebar';
import LeaseActionGeneralEdit from './elems/generalEdit';
import { getObjectById, myLeaseRequestEdit, urlGetMyDraftAction } from '../../../../utils/endPoints';
import Loader from '../../../loader/loader';
import { getMyDraftLeaseAction } from '../../../../utils/actions/actionsUtils';
import { auctionGetId } from '../../../../redux/actions/auctions/auctions';

const LeaseActionEdit =( )=> {
    const token = useSelector(state => state.start.token);
    const leaseActionItem = useSelector(state => state.start.leaseActionGetId)
    const leaseObject = useSelector(state => state.start.auctionsGetId)
    const dispatch = useDispatch()
    const { pathname } = useLocation()
    // const [ entitiesArr, setEntitiesArr ] = useState([])

    useEffect(() => {
      const id = pathname.split('/')[4]
      dispatch( getMyDraftLeaseAction( urlGetMyDraftAction( id ), token.access_token) )
      return () => {
        dispatch({type: 'LEASE_ACTION_GET_ID', payload: null})
      }
    }, [])

    // useEffect(() => {
    //   if ( !!leaseActionItem?.relatedObjectId ) dispatch( auctionGetId( getObjectById( leaseActionItem.relatedObjectId ) ) )
      
    //   return () => {
    //     dispatch({type: 'AUCTIONS_GET_ID', payload: null})
    //   }
    // }, [leaseActionItem])

    function setGeneral(){
      if ( !!leaseActionItem ) return <LeaseActionGeneralEdit 
                                        data={ leaseActionItem } 
                                        // entitiesArr={ entitiesArr } 
                                      />
      else return <Loader />
    }

    return (
        <div className={styles.wrap}>
            <LeaseActionEditTitle data={ leaseActionItem } />
            <div className={styles.container}>
                <LeaseActionEditSidebar data={ leaseActionItem } object={ leaseObject } />
                { setGeneral() }
            </div>
        </div>
    )
}

export default LeaseActionEdit