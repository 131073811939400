import React, { useEffect } from 'react';
import styles from './executionView.module.css';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import ExecutionSidebarView from './sidebar/sidebarView';
import ExecutionViewTitle from './titleBar/titleBar';
import { executionGetId, executionGetPublicId } from '../../../../redux/asset/assetActions';
import { urlGetExecutionPubId, urlGetMyExecution } from '../../../../utils/endPoints';
import ExecutionGeneral from './elems/general';
import Button from '../../../elements/buttons/button';

const ExecutionView =()=> {
    const dispatch = useDispatch()
    const location = useLocation()
    const executionItem = useSelector(state => state.asset.executionItem)
    const token = useSelector(state => state.start.token)

    useEffect(() => {
        const id = location.pathname.split('/')[3]
        dispatch( executionGetPublicId( urlGetExecutionPubId(id) ) )
        // dispatch( assetGetId( getAssetById(id), token))
      return () => {
        dispatch({type: 'EXECUTION_GET_ID', payload: null})
      }
    }, [])

    function setAssetViewInfo( path ){
        switch( path ){
            default:
                return <ExecutionGeneral data={ executionItem } />
        }
    }
    
    if(!!executionItem){
    return (
        <div className={styles.wrap}>
            {/* { ( !!process.env.REACT_APP_LOCAL && !!token ) &&
                <div className={styles.syncButton}>
                    <Button color='gray' onClick={ ()=> dispatch( executionGetId( `${urlGetMyExecution(executionItem.id)}/sync`, token.access_token )) } >SYNC</Button>
                </div>
            } */}
            <ExecutionViewTitle data={ executionItem } />
            <div className={styles.container}>
                { setAssetViewInfo(location.pathname.split('/')[3]) }
                <ExecutionSidebarView data={ executionItem } />
            </div>
        </div>
        )
    }
    return null
}

export default ExecutionView