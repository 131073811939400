import React, { useEffect, useState } from 'react';
import styles from './popupLeaseRequest.module.css';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import PopDownBlock from '../../../../../elements/popdownBlock/popDownBlock';
import DocumentsLeaseRequests from './documents/documentsLeaseReq';
import InputSelect from '../../../../../inputs/inputSelect';
import Input from '../../../../../inputs/input';
import InputTextArea from '../../../../../inputs/inputTextArea';
import { setPopupLeaseRequest, leaseRequestGetType, createRequest, editRequest } from '../../../../../../redux/actions/leaseRequests/leaseRequests';
import { getDictionaryByType, myLeaseRequestEdit, myLeaseRequestPost } from '../../../../../../utils/endPoints';
import { differenceInBusinessDays } from 'date-fns';
import { postLeaseReqest } from '../../../../../../utils/requests/requestsUtils';

const PopupLeaseRequest = ({data, obj}) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const token = useSelector(state => state.start.token)
    const object = useSelector(state => state.start.auctionsGetId);
    const requestTypesList = useSelector(state => state.start.requestTypesList)
    // const userProfiles = useSelector(state => state.start.userProfiles);

    const [requestType, setRequestType] = useState( !!data ? data.type : null );
    const [description, setDescription] = useState( !!data ? data.description : '');
    
    const [name, setName] = useState( !!data?.requestor ? data?.requestor?.name : '');
    const [email, setEmail] = useState( !!data?.requestor?.email ? data?.requestor?.email : '' )
    const [telephone, setTelephone] = useState( !!data?.requestor?.email ? data?.requestor?.telephone : '' )
    const [faxNumber, setFaxNumber] = useState( !!data?.requestor?.email ? data?.requestor?.faxNumber : '' )
    const [url, setUrl] = useState( !!data?.requestor?.email ? data?.requestor?.url : '' )

    useEffect(() => {
        if (requestTypesList === null) {
            dispatch(leaseRequestGetType( getDictionaryByType('requestType') ));
        }
    }, [])


    function createLeaseRequest() {
        if ( !!token ) {
            // debugger
            const body = {
                type: requestType,
                // description: {
                //     uk_UA: description
                // },
                description: description,
                relatedObjectId: object.id,
                requestor: {
                    name: {
                        uk_UA: name
                    },
                    email: email !== '' ? email : null,
                    telephone: telephone !== '' ? telephone : null,
                    faxNumber: faxNumber !== ''? faxNumber : null,
                    url: url !== '' ? url : null
                }
                // get requestor(){ TODO: розкоментувати колись, бо зараз реквестор додається автоматично
                //     let reqTmp = !!userProfiles?.organization?.contactPoint && userProfiles.organization.contactPoint
                //     reqTmp.name = { uk_UA: reqTmp.name }
                //     return reqTmp
                // }
            };
            const reqData = {
                url: myLeaseRequestPost,
                body: body,
                token: token.access_token,
                redirect: () => navigate('/lease/my_requests'),
                // redirect: ( id ) => navigate(`/lease/lease_request/draft/${id}`),
                dispatch: dispatch
            }
            postLeaseReqest( reqData )
        } else {
            alert('Авторизуйтесь');
        }
    }

    function editLeaseRequest(){
        if (token !== null) {
            let body = {
                type: requestType,
                // description: {
                //     uk_UA: description
                // },
                description: description,
                relatedObjectId: data.relatedObjectId,
                requestor: {
                    name: {
                        uk_UA: name
                    },
                    email: email !== '' ? email : null,
                    telephone: telephone !== '' ? telephone : null,
                    faxNumber: faxNumber !== ''? faxNumber : null,
                    url: url !== '' ? url : null
                }
            }
            body = JSON.stringify(body);
            let urlRedirect = `/lease_request/${data.id}`;
            dispatch( editRequest( myLeaseRequestEdit(), body, token.access_token, urlRedirect) );
        }else {
            alert('Авторизуйтесь');
        }
    }

    const setTitle =( data )=> {
        if(!!data) {
            return <div className={styles.title}>Редагувати заяву</div>
        }
        else {
            return (
                <>
                    <div className={styles.title}>Ваша заява</div>
                    <div className={styles.subtitle}>До об’єкту {object.title.uk_UA}</div>
                </>
            )
        }
    }

    return (
        <div className={styles.backdrop}>
            <div className={styles.wrapper}>
                <button 
                    className={styles.buttonClose} 
                    onClick={() => dispatch(setPopupLeaseRequest(false))}>
                </button>
                {setTitle( data )}
                <InputSelect 
                    value={requestType}
                    label="Тип *" 
                    onChange={setRequestType}
                    options={requestTypesList}
                    dictionary={true}
                    text={requestType}
                />
                <Input
                    value={name}
                    onChange={setName}
                    label="ПІБ або назва"
                    reqiured
                />
                <InputTextArea
                    value={description}
                    onChange={setDescription}
                    label="Опис"
                />
                <Input 
                    label='Адреса електронної пошти'
                    value={ email }
                    onChange={ e => setEmail(e)}
                    reqiured
                />
                <Input 
                    label='Номер телефону'
                    value={ telephone }
                    onChange={ e => setTelephone(e)}
                    reqiured
                />
                <Input 
                    label='Номер факсу'
                    value={ faxNumber }
                    onChange={ e => setFaxNumber(e)}
                    // reqiured
                />
                <Input 
                    label='Веб адреса'
                    value={ url }
                    onChange={ e => setUrl(e)}
                    // reqiured
                />
                <div className={styles.buttonWrapper}>
                    <button className={styles.buttonCancel} onClick={() => dispatch(setPopupLeaseRequest(false)) }>Скасувати</button>
                    <button className={styles.buttonDelete} onClick={() => !!data ? editLeaseRequest() : createLeaseRequest() }>Зберегти чернетку</button>
                </div>
            </div>
        </div>
    )
}

export default PopupLeaseRequest;