import React from 'react';
import styles from './assetItemCard.module.css';
import { useSelector } from 'react-redux';
import {Link, useNavigate} from 'react-router-dom';
import InfoModal from '../infoIconBlock/infoIconBlock'
import { checkProp, numFormat, getCurrency, getAddedTax, dateTransform, 
    isMongo, getAssetStatus } from '../../../../../utils/check'
// import moment from 'moment';

const AssetItemCard = ( { item } ) => {
    const user = useSelector(state => state.start.userProfiles)
    const navigate = useNavigate()

    // фотка или нет ее
    function avatarKa( documents ) {
        let tmp = documents.filter( i => ( i.documentType === 'illustration' && i.format.includes('image') ))
        if( tmp.length > 0 ) {
            return(
                <div className={styles.avatar}>
                    <div className={styles.avatarText}>
                    { tmp.length } 
                    <br />фото</div>
                    <img 
                    src={ tmp[0].url }
                    alt="" />
                </div>
            )
        } else {
            return (
            <div className={styles.unactive}>
            </div>
            )
        }
    }

    //бейдж "Ваш обьект"
    function yourObjectBadge() {
        let status = false
        if (!user) return null
        if (user && item && item.assetCustodian) {
            if ( !!item?.assetCustodian?.identifier?.id && item.assetCustodian.identifier.id === user.organization.identifier.identifier) {
                status = true
            }
        }
        if (status) return <div className={styles.yourObjBadge}>Ваш об’єкт</div>
        else return null 
    }

    //статус
    function setStatus(a){ // статус об'єкта
        switch(a) {
            case 'pending':
                return  <div className={`${styles.rightBlock_Status} ${styles.colorGreen}`}>
                        { getAssetStatus(a) }
                        </div>
            case 'active_rectification':
                return <div className={`${styles.rightBlock_Status} ${styles.colorGreen}`}>
                        { getAssetStatus(a) }
                        </div>
            case 'active_procedure':
                return <div className={`${styles.rightBlock_Status} ${styles.colorLightGrey}`}>
                        { getAssetStatus(a) }
                        </div>
            case 'active_contracting':
                return <div className={`${styles.rightBlock_Status} ${styles.colorGreen}`}>
                        { getAssetStatus(a) }
                        </div>
            case 'complete':
                return <div className={`${styles.rightBlock_Status} ${styles.colorLightBrown}`}>
                        { getAssetStatus(a) }
                        </div>
            case 'redemption':
                return <div className={`${styles.rightBlock_Status} ${styles.colorMeedllGrey}`}>
                        { getAssetStatus(a) }
                        </div>
            case 'archive':
                return <div className={`${styles.rightBlock_Status} ${styles.colorMeedllGrey}`}>
                        { getAssetStatus(a) }
                        </div>
            case 'deleted':
                return <div className={`${styles.rightBlock_Status} ${styles.colorMeedllGrey}`}>
                        { getAssetStatus(a) }
                        </div>
            default:
                return ''
        }
    }

    //тип переліку
    function registryListType(a) {
        switch(a) {
            case 'Undefined':
                return (
                    <div className={styles.objType}><span>Тип переліку: </span>Не визначено</div>
                )

            case 'First':
                return (
                    <div className={styles.objType}><span>Тип переліку: </span>Перший ступінь</div>
                )

            case 'Second':
                return (
                    <div className={styles.objType}><span>Тип переліку: </span>Другий ступінь</div>
                )
        }
    }

    return(
        <div className={styles.objItem}>
            <div className={styles.leftBlock}>
                { item?.documents ? avatarKa( item.documents ) : <div className={styles.unactive}></div> }
            </div>
            <div className={styles.centralBlock}>
                <div className={styles.topBlock}>
                    <div className={styles.objIdAndType}>
                        <div className={styles.objIdAndType_id} >
                            {item.hasOwnProperty('assetId') && item.assetId.startsWith('UA-')
                                ?
                                <a target={"_blank"} href={`https://prozorro.sale/planning/${item.assetId}/`}>{ item.assetId }</a>
                                :
                                <Link to={`/asset/${item.assetId}`}>{ item.assetId }</Link>
                            }
                        </div>
                        { yourObjectBadge() }
                        {/* <div className={styles.objIdAndType_type}>
                            propertyType
                        </div> */}
                    </div>
                        <div className={styles.objTitle} >
                            {item.hasOwnProperty('assetId') && item.assetId.startsWith('UA-')
                                ?
                                <a target={"_blank"} href={`https://prozorro.sale/planning/${item.assetId}/`}>{ item.assetId }</a>
                                :
                                <Link to={`/asset/${item.assetId}`}>{ isMongo(item.title) }</Link>
                            }
                        </div>
                    <div className={styles.objDiscription}>
                        {/* { item?.assetItems.map( item => <li key={item.id}>{ isMongo(item.classification.description) }</li> ) } */}
                    </div>
                    {
                        checkProp(item, 'statusesDecisions') && checkProp(item.statusesDecisions, 'listType')
                            && registryListType(item.statusesDecisions.listType)
                    }
                </div>
                <div className={styles.bottomBlock}>
                    <div className={styles.objOwner}>
                        { !!item?.assetHolder && 
                            <>
                                <span>Балансоутримувач:</span>
                                { isMongo(item.assetHolder.name) }
                                    <InfoModal
                                        info={ item.assetHolder }
                                    /> 
                                </>
                        }
                    </div>
                    <div className={styles.objRenter}>
                        { !!item?.assetCustodian && 
                            <>
                                <span>Розпорядник об`єкта:</span>
                                { isMongo(item.assetCustodian.name) }
                                    <InfoModal
                                        info={ item.assetCustodian }
                                    />
                            </>
                        }
                    </div>
                </div>
            </div>
            <div className={styles.rightBlock}>
                { item?.status && setStatus( item.status ) }
                <div className={styles.rightBlock_Date}>
                    <div className={styles.rightBlock_Start}>
                        <div className={styles.rightBlock_date_text}>Дата публікації</div>
                        <div className={styles.rightBlock_date_time}>{ dateTransform( item.datePublished ) }
                            <span className={styles.rightBlock_date_timeSpan}>{dateTransform( item.datePublished, 'onlyTime' )}</span>
                        </div>
                    </div>
                </div>
                <div className={styles.rightBlock_CostAndButtons}>
                </div>
                <div className={styles.buttonsLine}>
                        <div className={styles.buttonView}
                            onClick={()=>{
                                navigate(`/asset/${item.assetId}`)
                            }}>
                                Переглянути
                        </div>
                </div>
            </div>
        </div> 
    )
    
}

export default AssetItemCard