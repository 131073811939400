import React, {useState, useEffect } from 'react';
import styles from './catalog.module.css';
import axios from 'axios'
import { useSelector } from 'react-redux';

import Paginator from '../paginator/paginatorTest';

import { useLocation, useSearchParams } from 'react-router-dom';

import AssetList from './search/asset/assetList';
import AnnouncementsList from './search/announcement/announcementList';
import AssetSearch from './search/asset/assetSearch';
import AnnouncementsSearch from './search/announcement/announcementSearch';
import LeaseObjectsList from './search/leaseObject/leaseOblectsList';
import LeaseObjectSearch from './search/leaseObject/leaseObjectSearch';
import LeaseActionsList from './search/leaseAction/leaseActionsList';
import LeaseActionSearch from './search/leaseAction/leaseActionSearch';
import LeaseRequestsList from './search/leaseRequest/leaseRequestsList';
import LeaseRequestSearch from './search/leaseRequest/leaseRequestsSearch';
import LeaseContractsList from './search/leaseContract/leaseContractsList';
import LeaseContractsSearch from './search/leaseContract/leaseContractsSearch';
import ExecutionsList from './search/execution/executionsList';
import RedemptionsList from './search/redemptions/executionsList';
import ExecutionsSearch from './search/execution/executionsSearch';
import RedemptionsSearch from './search/redemptions/executionsSearch';

const Catalog = ( ) => {

    const auctionsGetArr = useSelector(state => state.start.auctionsGet)
    const { pathname } = useLocation()
    const [ params, setParams ] = useState({
        limit: 10,
        offset: 0,
        order: 'DESC',
        type: null,
        keyword: null
    })
    // const [ searchParams, setSearchParams ] = useSearchParams( );

    let searchString = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public${setType()}/${setRoute(pathname)}/search`

    function setType(){
        let tmp = window.location.href
        if (tmp.indexOf('/asset/assets_list') !== -1) return '/registry'
        return ""
    }

    function setRoute(pathname){
        switch(true){
            case '/asset/assets_list'.includes(pathname):
                if(params.type === null || params.type !== "assets"){
                    setParams({
                        limit: 10,
                        offset: 0,
                        order: 'DESC',
                        type: 'assets',
                        keyword: null
                    })
                }
                return 'assets'
            case '/asset/assets_list_lp'.includes(pathname):
                if(params.type === null || params.type !== "large_assets"){
                    setParams({
                        limit: 10,
                        offset: 0,
                        order: 'DESC',
                        type: 'large_assets',
                        keyword: null
                    })
                }
                return 'assets'
            case '/asset/announcements_list'.includes(pathname):
                if(params.type === null || params.type !== "jas"){
                    setParams({
                        limit: 10,
                        offset: 0,
                        order: 'DESC',
                        type: "jas",
                        keyword: null
                    })
                }
                return 'announcements'
            case '/asset/announcements_list_lp'.includes(pathname):
                if(params.type === null || params.type !== "jal"){
                    setParams({
                        limit: 10,
                        offset: 0,
                        order: 'DESC',
                        type: "jal",
                        keyword: null
                    })
                }
                return 'announcements'
            case '/asset/executions_list'.includes(pathname):
                if(params.type === null || params.type !== "executions"){
                    setParams({
                        limit: 10,
                        offset: 0,
                        order: 'DESC',
                        type: "executions",
                        keyword: null
                    })
                }
                return 'executions'
            case '/asset/executions_list_lp'.includes(pathname):
                if(params.type === null || params.type !== "large_executions"){
                    setParams({
                        limit: 10,
                        offset: 0,
                        order: 'DESC',
                        type: "large_executions",
                        keyword: null
                    })
                }
                return 'executions'
            case '/asset/redemptions_list'.includes(pathname):
                if(params.type === null || params.type !== "jrs"){
                    setParams({
                        limit: 10,
                        offset: 0,
                        order: 'DESC',
                        type: "jrs",
                        keyword: null
                    })
                }
                return 'redemptions'
            case '/asset/redemptions_list_lp'.includes(pathname):
                if(params.type === null || params.type !== "jrl"){
                    setParams({
                        limit: 10,
                        offset: 0,
                        order: 'DESC',
                        type: "jrl",
                        keyword: null
                    })
                }
                return 'redemptions'
            case '/lease/objects_list'.includes(pathname):
                if(params.type !== null){
                    setParams({
                        limit: 10,
                        offset: 0,
                        order: 'DESC',
                        type: null,
                        keyword: null
                    })
                }
                return 'registry_objects'
            case '/lease/actions_list'.includes(pathname):
                if(params.type !== null){
                    setParams({
                        limit: 10,
                        offset: 0,
                        order: 'DESC',
                        type: null,
                        keyword: null
                    })
                }
                return 'lease_actions'
            case '/lease/requests_list'.includes(pathname):
                if(params.type !== null){
                    setParams({
                        limit: 10,
                        offset: 0,
                        order: 'DESC',
                        type: null,
                        keyword: null
                    })
                }
                return 'lease_requests'
            case '/lease/contracts_list'.includes(pathname):
                if(params.type !== null){
                    setParams({
                        limit: 10,
                        offset: 0,
                        order: 'DESC',
                        type: null,
                        keyword: null
                    })
                }
                return 'contracts'
            default:
                if(params.type !== null){
                    setParams({
                        limit: 10,
                        offset: 0,
                        order: 'DESC',
                        type: null,
                        keyword: null
                    })
                }
                return
        }
    }

    function viewGenerator(pathname){
        switch(true){
            case '/asset/assets_list'.includes(pathname): return <AssetList searchUrl={ searchString } params={params} />
            case '/asset/assets_list_lp'.includes(pathname): return <AssetList searchUrl={ searchString } params={params} />
            case '/asset/announcements_list'.includes(pathname): return <AnnouncementsList searchUrl={ searchString } params={params} />
            case '/asset/announcements_list_lp'.includes(pathname): return <AnnouncementsList searchUrl={ searchString } params={params} />
            case '/asset/executions_list'.includes(pathname): return <ExecutionsList searchUrl={ searchString } params={params} />
            case '/asset/executions_list_lp'.includes(pathname): return <ExecutionsList searchUrl={ searchString } params={params} />
            //case '/asset/executions_list'.includes(pathname): return <ExecutionsList searchUrl={ searchString } params={params} />
            case '/lease/objects_list'.includes(pathname): return <LeaseObjectsList searchUrl={ searchString } params={params} />
            case '/lease/actions_list'.includes(pathname): return <LeaseActionsList searchUrl={ searchString } params={params} />
            case '/lease/requests_list'.includes(pathname): return  <LeaseRequestsList searchUrl={ searchString } params={params} />
            case '/asset/redemptions_list'.includes(pathname): return <RedemptionsList searchUrl={ searchString } params={params} />
            case '/asset/redemptions_list_lp'.includes(pathname): return <RedemptionsList searchUrl={ searchString } params={params} />
            default: return
        }
    }

    function searchGenerator(pathname){
        switch(true){
            case '/asset/assets_list'.includes(pathname): 
                if(params.keyword === '') params.keyword = null
                //params.itemDescription = params.keyword
                //params.assetCustodian = params.keyword
                //params.assetHolder = params.keyword
                //params.region = params.keyword
                return <AssetSearch setParams={ setParams } params={ params } />
            case '/asset/assets_list_lp'.includes(pathname):
                if(params.keyword === '') params.keyword = null
                //params.itemDescription = params.keyword
                //params.assetCustodian = params.keyword
                //params.assetHolder = params.keyword
                //params.region = params.keyword
                return <AssetSearch setParams={ setParams } params={ params } />
            case '/asset/announcements_list'.includes(pathname): return <AnnouncementsSearch setParams={ setParams} params={ params } />
            case '/asset/announcements_list_lp'.includes(pathname): return <AnnouncementsSearch setParams={ setParams} params={ params } />
            case '/asset/executions_list'.includes(pathname): return <ExecutionsSearch setParams={ setParams} params={params} />
            case '/asset/executions_list_lp'.includes(pathname): return <ExecutionsSearch setParams={ setParams} params={params} />
            case '/asset/redemptions_list'.includes(pathname): return <RedemptionsSearch setParams={ setParams} params={params} />
            case '/asset/redemptions_list_lp'.includes(pathname): return <RedemptionsSearch setParams={ setParams} params={params} />
            case '/lease/objects_list'.includes(pathname): return <LeaseObjectSearch setParams={ setParams } params={ params } />
            case '/lease/actions_list'.includes(pathname): return <LeaseActionSearch setParams={ setParams } params={ params } />
            case '/lease/requests_list'.includes(pathname): return  <LeaseRequestSearch setParams={ setParams } params={ params } />
            case '/lease/contracts_list'.includes(pathname): return <LeaseContractsSearch setParams={ setParams } params={ params } />
            default: return
        }
    }

    return (
        <section className = { styles.wrap } >
            { searchGenerator(pathname) }
            { viewGenerator(pathname) }
            <Paginator
                params={ params } 
                setParams={ setParams }
                list={ auctionsGetArr }
            />
        </section>
    )
}

export async function getSearchList( url, params, dispatch, setLoader ){
    setLoader(true)
    try {
        const data = await axios.get( url, 
          { headers: { 'Content-Type': 'application/json' },
            params: params
          })
          dispatch({ payload: data.data, type: 'AUCTIONS_GET' })
    } catch (err) {
    }
    window.scrollTo(0, 0)
    setLoader(false)
}

export default Catalog