import React from 'react';
import styles from './titleBar.module.css';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getShorter, isMongo } from '../../../../../utils/check';
import Button from '../../../../elements/buttons/button';

const AnnouncementViewTitle =({ data })=>  {
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const user = useSelector(state => state.start.userProfiles)    
    const path = pathname.split('/')[4]

    function getLastPath(path){
        switch(path){
            case '1': return 
            default: return ' Пріоритетний викуп'
        }
    }

    return (
        <div>
                <div className={styles.navBlock}>
                    <ul>
                        <li key={1}>
                            <Link to={`/asset/redemptions_list`}
                                // onClick={()=>{
                                    // let url = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/public/registry_objects/search`
                                    // dispatch({ payload: null, type: 'AUCTIONS_GET' })
                                    // dispatch({payload: 1, type: 'SET_MY_CURRENT_PAGE'})
                                    // auctionsGetSearch(url)
                                    // }}
                                    >
                                    Перелік пріоритетних викупів
                            </Link>
                        </li>
                        {/* <li key={2} className={styles.liTitle}>{data.title ? getShorter( isMongo(data.title) , 80) : 'Новий об\'єкт'}</li> */}
                        <li key={3}>{ getLastPath(path) }</li>
                    </ul>
                    <div className={styles.titleAndSend}>
                        <div className={styles.objTitle}>
                            { !!data?.title ? isMongo( data.title ) : 'Пріоритетний викупу' }
                        </div>
                        <div className={styles.sendButton}>
                            {/* { ( !!data?.assetCustodian?.identifier?.id && !!user?.organization?.identifier?.identifier && data.assetCustodian.identifier.id === user.organization.identifier.identifier) && 
                                <Button color='gray' onClick={ ()=> navigate(`/asset/edit/${data.id}`) }>Редагувати об'єкт</Button> 
                            } */}
                        </div>
                    </div>
                    {/* <div className={styles.objNav}>
                        <div className={ !path ? styles.activeNavItem : styles.objNavItem } onClick={ ()=> navigate('') }>
                            Загальна інформація
                        </div>
                    </div> */}
                </div>
        </div>
        )
}

export default AnnouncementViewTitle