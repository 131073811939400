import {
    SET_POPUP_ADD_REQUEST,
    LEASE_REQUEST_GET_TYPE,
    REQUESTS_GET_MY,
    LEASE_REQUEST_CREATE,
    LEASE_REQUEST_EDIT,
    REQUEST_GET_MY
} from "../../types";
import { postAPI, getAPI, patchAPI } from '../crud';

export function setPopupLeaseRequest(value) {
    return {
        type: SET_POPUP_ADD_REQUEST,
        payload: value
    }
}

export function leaseRequestGetType(_url) {
    const data = {
        url: _url,
        actionType: LEASE_REQUEST_GET_TYPE,
        header: {
            'Content-Type': 'application/json'
        }
    }
    return getAPI(data);
}


export function requestsGetMy(_url, token){
    const data = {
        url: _url,
        actionType: REQUESTS_GET_MY,
        header: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        }
    }
    return getAPI(data);
}

export function requestGetMy(_url, token){
    const data = {
        url: _url,
        actionType: REQUEST_GET_MY,
        header: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        }
    }
    return getAPI(data);
}

export function createRequest(_url, body, token, redirect) {
    const data = {
        url: _url,
        // actionType: LEASE_REQUEST_CREATE,
        body: body,
        header: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        },
        navigate: redirect
    }
    return postAPI(data);
}

export function editRequest(_url, body, token, redirect) {
    const data = {
        url: _url,
        actionType: LEASE_REQUEST_EDIT,
        body: body,
        header: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        },
        redirect: redirect,
    }
    return patchAPI(data);
}