import React, { useState } from 'react';
import styles from './infoBlockСollapser.module.css';

const InfoBlockСollapser = ( { collapsed = false, blockTitle, content, blockDescription, button } )=> {
    const [collapsor, setCollapsor] = useState( collapsed )

    function infoBlockToggle() { setCollapsor(!collapsor) }

    return (
        <div className={collapsor ? styles.infoBlockMin : styles.infoBlock}>
            <div className={styles.titleBlock}>
                <div className={styles.infoBlockTitle}>
                    <h3>{ blockTitle }</h3>
                    { collapsor === styles.infoBlock ? <p className={styles.desc}>{ blockDescription }</p> : null }
                </div>
                { button !== null && !collapsor && button }
                <div 
                    className={styles.wrapBtn}
                    onClick={infoBlockToggle}
                >
                    { collapsor ? 'Розгорнути' : 'Згорнути'}
                </div>
            </div>
                { !collapsor && content }
        </div>
    )
}

export default InfoBlockСollapser