import React, {useState, useEffect} from 'react';
import styles from './buttonFilters.module.css';
import Checkbox from '../../../../inputs/checkBox';
import Button from '../../../../elements/buttons/button';
import { getExecutionStatus } from '../../../execution/utils';

const ExecutionsStatusSearch = ({ setParams, arr })=> {

    const [popup, setPopup] = useState( false )
    const [status] = useState( getExecutionStatus('dict') )

        const popUp = (
                <div className={styles.wrapMain}>
                    <div className={styles.wrap}>
                        <h3 className={styles.filterTitle}>Статус контрактингу</h3>
                        <div className={styles.content}>
                            { status.map( item => <Checkbox 
                                    value={ ( !!arr?.status && arr.status.includes(item.value) ) ? true : false }
                                    label={ item.name }
                                    onChange={ ()=> setParams( prev => {
                                        if ( !!arr?.status && arr.status.includes(item.value) ){
                                            let tmp = prev.status
                                            return {...prev, status: tmp.filter( i => i !== item.value ) }
                                        } else {
                                            if ( !!prev?.status && !!prev?.status.length === 0) {
                                                return {...prev, status: [...status, item.value] }
                                            }
                                            if ( !prev?.status) return {...prev, status: [ item.value ] }
                                            else {
                                                let tmp = prev.status
                                                tmp = [...tmp, item.value]
                                                return {...prev, status: tmp }
                                            }
                                        }
                                    } )}
                                    key={item.value}
                                />)
                            }
                        </div>
                        <div className={styles.bottomPart}>
                            <Button 
                                color='blue'
                                onClick={() => setPopup( prev => !prev) }>
                                Готово
                            </Button>
                            {/* <div className={styles.btnDone}
                                onClick={() => {
                                    setParams( prev => ({...prev, status: setResult( status ) }) )
                                    setPopup( prev => !prev )
                                }}>
                                Застосувати
                            </div> */}
                        </div>
                    </div>
                </div>
            )

    return (
        <>
            <div
                className={styles.searchFilterBlock_status}
                onClick={ () => setPopup( prev => !prev) }
            >
                <div>Статус контрактингу</div>
                { ( !!arr?.status && arr.status.length > 0 )&& 
                    <div style={{borderLeft: '1px dotted #BFBFC6', paddingLeft: '5px', marginLeft: '5px'}}>
                        { arr.status.length }
                    </div>
                }
            </div>
            { popup && popUp }
        </>
    )
}

export default ExecutionsStatusSearch
