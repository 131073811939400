import { getAssetByPublicId, getAnnouncementsByPublicId, urlGetExecutionPubId, getMyRedemptionPublic } from './../../../utils/endPoints'
import { checkProp } from '../../../utils/check'
import axios from "axios"

export async function getInfo( url ) {
    try {
        const data = await axios.get( url, { headers: {
            'Content-Type': 'application/json'
        } })
        return data.data
    } catch (err) {
        // return.
    }
}

export async function getRelatedEntities( re, setData ){
    if ( !re ) return null
    for ( let key in re ){
        if ( re[key].type === 'asset' ){
            let tmp = await getInfo( getAssetByPublicId( re[key].objectId ) )
            setData( prev => [ ...prev, { type: 'asset', data: tmp } ])
        }
        if ( re[key].type === 'large_asset' ){
            let tmp = await getInfo( getAssetByPublicId( re[key].objectId ) )
            setData( prev => [ ...prev, { type: 'large_asset', data: tmp } ])
        }
        if ( re[key].type === 'announcement' ){
            let tmp = await getInfo( getAnnouncementsByPublicId( re[key].objectId ) )
            setData( prev => [ ...prev, { type: 'announcement', data: tmp } ])
        }
        if ( re[key].type === 'large_announcement' ){
            let tmp = await getInfo( getAnnouncementsByPublicId( re[key].objectId ) )
            setData( prev => [ ...prev, { type: 'large_announcement', data: tmp } ])
        }
        if ( re[key].type === 'procedure' || typeof re[key].type === 'undefined'){
            let tmp = await getInfo( `${process.env.REACT_APP_END_POINT_DTO}/api/v2/uk/public/auction/${re[key]._id}` )
            setData( prev => [ ...prev, { type: 'procedure', data: tmp } ])
        }
        if ( re[key].type === 'execution' ){
            let tmp = await getInfo( urlGetExecutionPubId( re[key].objectId ) )
            setData( prev => [ ...prev, { type: 'execution', data: tmp } ])
        }
        if ( re[key].type === 'large_execution' ){
            let tmp = await getInfo( urlGetExecutionPubId( re[key].objectId ) )
            setData( prev => [ ...prev, { type: 'execution', data: tmp } ])
        }
        if ( re[key].type === 'redemption' ){
            let tmp = await getInfo( getMyRedemptionPublic( re[key].objectId ) )
            setData( prev => [ ...prev, { type: 'redemption', data: tmp } ])
        }
        if ( re[key].type === 'large_redemption' ){
            let tmp = await getInfo( getMyRedemptionPublic( re[key].objectId ) )
            setData( prev => [ ...prev, { type: 'redemption', data: tmp } ])
        }
    }
}

export function getAnnouncementDocType( type ){
    const dictionary = {
        "notice": { "uk_UA": "Інформаційне повідомлення", "en_US": ""},
        "evaluationCriteria": {"uk_UA": "Рішення про затвердження умов продажу","en_US": ""},
        "contractProforma": {"uk_UA": "Проект договору","en_US": ""},
        "redemptionPreContract": {"uk_UA": "Попередній договір","en_US": ""},
        "digitalSignature": {"uk_UA": "Цифровий підпис","en_US": ""},
        "clarifications": {"uk_UA": "Документ з інформацією про виправлення помилок","en_US":''}
        }
    if( checkProp(dictionary, type ) ) return dictionary[type].uk_UA
    else return type
}
