import React, {useState} from 'react';
import styles from './ToMyObjRequestsTabItem.module.css';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { checkProp, dateTransform, getShorter, getTranslate, isMongo } from '../../../../utils/check';
import CreateActionPopup from '../../leaseAction/createLeaseAction/createActionPopUp';

const ToMyObjRequestsItem = ( { data } ) => {
    
    const requestTypesList = useSelector(state => state.start.requestTypesList);
    const [ dropDomnMenu, setdropDomnMenu ] = useState(false)
    const [ createAction, setCreateAction ] = useState(false)
    

    return (
        <div className={styles.objTabItem}>
            <div className={styles.tdName}>
                <div>{ !!data?.leaseRequestId && data.leaseRequestId }</div>
                <div style={{fontWeight: 'normal'}}>{ !!data?.description && getShorter( isMongo(data.description), 200 ) }</div>
            </div>
            <div className={styles.tdType}>{ !!data?.type && getTranslate(data.type, requestTypesList) }</div>
            <div className={styles.tdDate}>
                { checkProp(data, 'datePublished') ? dateTransform(data.datePublished, 'time') : '' }
            </div>
            <div className={styles.tdRent}>{ !!data?.requestor ? isMongo(data.requestor.name) : '-'}</div>
            {/* <div className={styles.tdAction}>{!!data.registryObject && data.registryObject.registryActions.length }</div> */}
            <div className={styles.tdDeclaration}>{ !!data?.documents && data.documents.length }</div>
            <div className={styles.tdBtnWrap}>
                <div className={dropDomnMenu ? styles.btnActive : styles.tdBtn} onClick={()=> setdropDomnMenu(!dropDomnMenu)}>
                    <svg width="40" height="40" xmlns="http://www.w3.org/2000/svg" viewBox="-11 1 46 4">
                        <g>
                            <path className={styles.svgIcon} d="M5.33,2.67A2.67,2.67,0,1,0,2.67,5.34,2.66,2.66,0,0,0,5.33,2.67Z"/>
                            <path className={styles.svgIcon} d="M14.67,2.67A2.67,2.67,0,1,0,12,5.34,2.67,2.67,0,0,0,14.67,2.67Z"/>
                            <path className={styles.svgIcon} d="M24,2.67a2.67,2.67,0,1,0-2.67,2.67A2.66,2.66,0,0,0,24,2.67Z"/>
                        </g>
                    </svg>
                </div>
            </div>
            { dropDomnMenu ? 
            <div className={styles.dropDown}>
                <div className={styles.dropdownContent}>
                        <div className={styles.menuStep}><Link className={styles.link} to={`/lease/lease_request/${data.id}`} >Відкрити заявку</Link></div>
                        <div className={styles.menuStep}><Link className={styles.link} to={`/lease/object/${data.relatedObjectId}`} >Відкрити пов'язаний об'єкт</Link></div>
                        <div className={styles.menuStep} 
                            onClick={ ()=> {
                                setCreateAction( true )
                                setdropDomnMenu( false )
                            }} 
                        >
                            <div style={{width: '100%', fontWeight: '600', color: 'var(--blue)'}}>Створити дію</div>
                        </div>
                </div>
                <div className={styles.dropdownBlock} onClick={()=> setdropDomnMenu(!dropDomnMenu)}></div>
            </div> : null 
            }
            { createAction && <CreateActionPopup obj={ data } close={ ()=> setCreateAction( false ) } /> }
        </div>
    )
}

export default ToMyObjRequestsItem