import React, {useState, useEffect} from 'react';
import styles from '../search.module.css';
import Bage from './bage';

import { checkProp, getAssetStatus, dateTransform } from '../../../../../utils/check';

const BadgesSearch =({ params, setParams })=> {

    function bageCombinator( params ){
            let tmpArr = []
            if ( checkProp(params, 'keyword') ){
                let tmp = <Bage title={ params.keyword } 
                action={ ()=> setParams( prev => ({...prev, keyword: null }))} />
                tmpArr = [...tmpArr, tmp ]
            }
            if ( checkProp(params, 'status') && params.status.length > 0 ){
                params.status.forEach( i => {
                    let tmp = <Bage title={ `Статус: ${getAssetStatus(i)}` }
                    action={ ()=> setParams( prev => ({...prev, status: prev.status.filter( e => e !== i ) }))} key={i} 
                    />
                    tmpArr = [...tmpArr, tmp ]
                })
            }
            if ( checkProp(params, 'assetId') ){
                let tmp = <Bage title={ `Ідентифікатор об'єкта: ${params.assetId}` } 
                action={ ()=> setParams( prev => ({...prev, assetId: null }))} />
                tmpArr = [...tmpArr, tmp ]
            }
            if ( checkProp(params, 'title') ){
                let tmp = <Bage title={ `Назва об'єкта: ${params.title}` } 
                action={ ()=> setParams( prev => ({...prev, title: null }))} />
                tmpArr = [...tmpArr, tmp ]
            }
            if ( checkProp(params, 'description') ){
                let tmp = <Bage title={ `Опис об'єкта: ${params.description}` } 
                action={ ()=> setParams( prev => ({...prev, description: null }))} />
                tmpArr = [...tmpArr, tmp ]
            }
            if ( checkProp(params, 'assetHolder') ){
                let tmp = <Bage title={ `Балансоутримувач: ${params.assetHolder}` } 
                action={ ()=> setParams( prev => ({...prev, assetHolder: null }))} />
                tmpArr = [...tmpArr, tmp ]
            }
            if ( checkProp(params, 'assetCustodian') ){
                let tmp = <Bage title={ `Розпорядник об'єкта: ${params.assetCustodian}` } 
                action={ ()=> setParams( prev => ({...prev, assetCustodian: null }))} />
                tmpArr = [...tmpArr, tmp ]
            }
            if ( checkProp(params, 'datePublishedFrom') ){
                let tmp = <Bage title={ `Дата публікації, з: ${ dateTransform(params.datePublishedFrom) }` } 
                action={ ()=> setParams( prev => ({...prev, datePublishedFrom: null }))} />
                tmpArr = [...tmpArr, tmp ]
            }
            if ( checkProp(params, 'datePublishedTo') ){
                let tmp = <Bage title={ `Дата публікації, по: ${ dateTransform(params.datePublishedTo) }` } 
                action={ ()=> setParams( prev => ({...prev, datePublishedTo: null }))} />
                tmpArr = [...tmpArr, tmp ]
            }
        return tmpArr
    }

    return (
        <div className={styles.badgesWrapLine}>
            {/* bageCombinator( params ) */}
        </div>
    )
}

export default BadgesSearch