import React from 'react';
import styles from './app.module.css';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Auth from '../header/login/auth/auth.js';
import Header from '../header/header.js';
import Find from '../find/find.js';
import TopMenu from '../find/typeSite/typeSite';
import Footer from '../footer/footer.js';
import Pages from '../pages/pages.js';
import PopUpLoader from '../loader/loader.js';
import ObjectItemPage from '../../components/pages/objectItemPage/objectItemPage';
import ActionItemPage from '../../components/pages/objectItemPage/actionItemPage';
import ObjectCreate from '../../components/pages/objectCreate/objectCreate';
import MyAssets from '../pages/regeestry/myAssets/myAssets';
import MyAssetsLP from '../pages/regeestry/myAssets/myAssetsLp';
import MyAnnouncements from '../pages/regeestry/myAnnouncements/myAnnouncements';
import MyRedemptions from '../pages/regeestry/myRedemptions/myRedemptions';
import MyObjects from '../../components/pages/regeestry/myObjectsTab/myObjects';
import MyActions from '../../components/pages/regeestry/myActionsTab/myActions';
import MyRequests from '../../components/pages/regeestry/myRequestTab/myRequests';
// import RequestItem from '../pages/requestItemPage/requestItemPage';
import RequestItem from '../pages/leaseRequest/view/requestItemPage';
import AssetView from '../pages/asset/view/assetView';
import AssetCreate from '../pages/asset/create/assetCreate';
import AssetLPCreate from '../pages/asset/create/assetCreateLP';
import UniPopUp from '../popups/uniPopUp/uniPopUp'
import AnnouncementView from '../pages/announcement/view/announcementView';
import AnnouncementCreate from '../pages/announcement/create/announcementCreate';
import AnnouncementCreateLp from '../pages/announcement/create/announcementCreateLp';
import RedemptionView from '../pages/redemption/view/announcementView';
import RedemptionCreate from '../pages/redemption/create/announcementCreate';
import RedemptionCreateLP from '../pages/redemption/create/announcementCreateLP';

import ExecutionView from '../pages/execution/view/executionView';
// import ActionCreate from '../pages/actionCreate/actionCreate';
import LeaseRequestEdit from '../pages/leaseRequest/edit/leaseRequestEdit';
import LeaseActionEdit from '../pages/leaseAction/edit/leaseActionEdit';
import {setLogo} from '../../redux/actions/support.js';

// import {localGetToken, setIntervalRef, postRefToken} from '../../redux/actions/default/default.js';
import NotFoundPage from '../pages/errors/errors';
import { AuthProvider } from '../../hoc/AuthProvider';
import { RequireAuth } from '../../hoc/ReqireAuth';
import AssetWrap from '../pages/asset/asset';
import MyExecutions from '../pages/regeestry/myExecutions/myExecutions';
import ExecutionEdit from '../pages/execution/edit/executionEdit';
import ExecutionEditLP from '../pages/execution/edit/executionEditLP';
import ToMyObjRequests from '../pages/regeestry/requestToMyOblects/toMyObjRequests';

const App = () => {

    const dispatch = useDispatch();
    const logo = useSelector(state => state.start.logo);
    const loader = useSelector(state => state.start.loader);
    const universalError = useSelector(state => state.start.universalError);
    const userProfiles = useSelector(state => state.start.userProfiles);
    const token = useSelector(state => state.start.token);
    const { pathname } = useLocation()
    
    

//   const userType = !!userProfiles?.type && userProfiles.type
//   const keySetIntervalRefresh = useSelector(state => state.start.keySetIntervalRefresh);

//   let tmpLocal = null;
//   let tmpNowTimeStamp = null;
//     if (localStorage.getItem('token') !== null) {
//       tmpLocal = JSON.parse(localStorage.getItem('token'));
//       tmpNowTimeStamp = Date.now();
//       if(tmpLocal.timeStamp < tmpNowTimeStamp) {
//             let tmpClearToken = {
//             access_token: "",
//             expires_in: 3600,
//             refresh_token: "",
//             timeStamp: 0,
//             token_type: "",
//             };
//             localStorage.setItem('token', JSON.stringify(tmpClearToken));
//       } else {
//         if (keySetIntervalRefresh === null) {
//             dispatch(localGetToken(tmpLocal));
//             let tmpInterval = setInterval(() => {
//                 tmpLocal = JSON.parse(localStorage.getItem('token'));
//                 dispatch(postRefToken(process.env.REACT_APP_END_POINT_BD, tmpLocal.refresh_token));
//             }, 600000);
//             dispatch(setIntervalRef(tmpInterval));
//             }
//         }
//     }

    if (logo === null) {
        setLogo(window.location.pathname.length);
    } else {
            if (window.location.pathname.length === 1) {
                if (logo !== window.location.pathname.length) {
                    setLogo(null);
                }
        } else {
            if (logo !== window.location.pathname.length) {
                setLogo(window.location.pathname.length);
                }
            }
    }

    function setStartPath( userProfiles ){
        if ( !!userProfiles ){
            if ( !!userProfiles.canCreateAnnouncements || 
                !!userProfiles.canCreateAssets || 
                !!userProfiles.canCreateExecutions || 
                !!userProfiles.canCreateRedemptions ){
                return '/asset/assets_list'
            }
            if ( !!userProfiles.canCreateActions || 
                !!userProfiles.canCreateObjects || 
                !!userProfiles.canCreateRequests ){
                return '/lease/objects_list'
            }
        } else { 
            return '/lease/objects_list'
        }
    }

    return (
        <AuthProvider>
            
            <div className={styles.wrapper}>
            { !!universalError && <UniPopUp type={1}
                                    close={ ()=> dispatch( {type: 'UNIVERSAL_ERROR', payload: null }) }
                                    data={ <div>{ universalError }</div> }
                                    // data={ universalError.map( i => <div>{i}</div> ) }
                                    /> 
            }
            { !!loader && <PopUpLoader /> }
                <Header />

                <Routes>
                    {pathname.split("/")[1] === "object"
                        ? <Route path="*" element={ 
                            <Navigate replace to={ `/lease/object/${pathname.split("/")[2]}` } />
                        }/>
                        : null
                    }
                </Routes>
                <Routes>
                    <Route path="/" element={ 
                            <Navigate replace to={ setStartPath( userProfiles ) } />
                        }
                    />
                    <Route path='auth/sso'
                        element={ <> <Header /> <div className={styles.main}><div className={styles.container}><Auth /></div></div></>}
                    />
                    <Route path="asset" >

                        <Route path='my_assets_lp'
                                element={ <RequireAuth><AssetWrap>{/*<TopMenu />*/}<MyAssetsLP /></AssetWrap></RequireAuth> }
                        />
                        <Route path='my_assets'
                                element={ <RequireAuth><AssetWrap>{/*<TopMenu />*/}<MyAssets /></AssetWrap></RequireAuth> }
                        />
                        <Route path='my_announcements'
                                element={ <RequireAuth><AssetWrap>{/*<TopMenu />*/}<MyAnnouncements /></AssetWrap></RequireAuth> }
                        />
                        <Route path='my_redemptions'
                                element={ <RequireAuth><AssetWrap>{/*<TopMenu />*/}<MyRedemptions /></AssetWrap></RequireAuth> }
                        />
                        <Route path='my_executions'
                                element={ <RequireAuth><AssetWrap>{/*<TopMenu />*/}<MyExecutions /></AssetWrap></RequireAuth> }
                        />
                        <Route path='create_lp'
                            element={ <RequireAuth><AssetWrap><AssetLPCreate create={true} /></AssetWrap></RequireAuth> }
                        />
                        <Route path='create'
                            element={ <RequireAuth><AssetWrap><AssetCreate create={true} /></AssetWrap></RequireAuth> }
                        />
                        <Route path='create/:id'
                            element={ <RequireAuth><AssetWrap><AssetCreate create={false} /></AssetWrap></RequireAuth> }
                        >
                            <Route path='items'
                                element={ <RequireAuth><AssetWrap><AssetCreate create={false} /></AssetWrap></RequireAuth> }
                            />
                            <Route path='documents'
                                element={ <RequireAuth><AssetWrap><AssetCreate create={false} /></AssetWrap></RequireAuth> }
                            />
                        </Route>
                        <Route path='edit_lp/:id'
                            element={ <RequireAuth><AssetWrap><AssetLPCreate type="large_asset"/></AssetWrap></RequireAuth> }
                        >
                            <Route path='items'
                                element={ <RequireAuth><AssetWrap><AssetLPCreate type="large_asset" /></AssetWrap></RequireAuth> }
                            />
                            <Route path='documents'
                                element={ <RequireAuth><AssetWrap><AssetLPCreate type="large_asset" /></AssetWrap></RequireAuth> }
                            />
                        </Route>
                        <Route path='edit/:id'
                            element={ <RequireAuth><AssetWrap><AssetCreate type="asset" /></AssetWrap></RequireAuth> }
                        >
                            <Route path='items'
                                element={ <RequireAuth><AssetWrap><AssetCreate type="asset" /></AssetWrap></RequireAuth> }
                            />
                            <Route path='documents'
                                element={ <RequireAuth><AssetWrap><AssetCreate type="asset" /></AssetWrap></RequireAuth> }
                            />
                        </Route>
                        <Route path=':id'
                            element={ <AssetWrap><AssetView /></AssetWrap> }
                        />
                        <Route path='assets_list'
                            element={ <AssetWrap><TopMenu /><Pages /></AssetWrap> }
                        />
                        <Route path='assets_list_lp'
                            element={ <AssetWrap><TopMenu /><Pages /></AssetWrap> }
                        />
                        <Route path='announcements_list'
                            element={ <AssetWrap><TopMenu /><Pages /></AssetWrap> }
                        />
                        <Route path='announcements_list_lp'
                            element={ <AssetWrap><TopMenu /><Pages /></AssetWrap> }
                        />
                        <Route path='executions_list'
                            element={ <AssetWrap><TopMenu /><Pages /></AssetWrap> }
                        />
                        <Route path='executions_list_lp'
                            element={ <AssetWrap><TopMenu /><Pages /></AssetWrap> }
                        />
                        <Route path='redemptions_list'
                            element={ <AssetWrap><TopMenu /><Pages /></AssetWrap> }
                        />
                        <Route path='redemptions_list_lp'
                            element={ <AssetWrap><TopMenu /><Pages /></AssetWrap> }
                        />
                        <Route path='announcement'>
                            <Route path=':id'
                                element={ <AssetWrap><AnnouncementView /></AssetWrap> }
                            />
                            <Route path='create_lp'
                                element={ <RequireAuth><AssetWrap><AnnouncementCreateLp create={true} /></AssetWrap></RequireAuth> }
                            />
                            <Route path='create'
                                element={ <RequireAuth><AssetWrap><AnnouncementCreate create={true} /></AssetWrap></RequireAuth> }
                            />
                            <Route path='edit_lp/:id'
                                element={ <RequireAuth><AssetWrap><AnnouncementCreateLp /></AssetWrap></RequireAuth> }
                            >
                                {/* <Route path='items'
                                    element={ <RequireAuth><AssetWrap><AnnouncementCreate /></AssetWrap></RequireAuth> }
                                /> */}
                                <Route path='documents'
                                    element={ <RequireAuth><AssetWrap><AnnouncementCreateLp /></AssetWrap></RequireAuth> }
                                />
                            </Route>
                            <Route path='edit/:id'
                                element={ <RequireAuth><AssetWrap><AnnouncementCreate /></AssetWrap></RequireAuth> }
                            >
                                {/* <Route path='items'
                                    element={ <RequireAuth><AssetWrap><AnnouncementCreate /></AssetWrap></RequireAuth> }
                                /> */}
                                <Route path='documents'
                                    element={ <RequireAuth><AssetWrap><AnnouncementCreate /></AssetWrap></RequireAuth> }
                                />
                            </Route>
                        </Route>
                        <Route path='redemption'>
                            <Route path=':id'
                                element={ <AssetWrap><RedemptionView /></AssetWrap> }
                            />
                            <Route path='create_lp'
                                element={ <RequireAuth><AssetWrap><RedemptionCreateLP create={true} /></AssetWrap></RequireAuth> }
                            />
                            <Route path='create'
                                element={ <RequireAuth><AssetWrap><RedemptionCreate create={true} /></AssetWrap></RequireAuth> }
                            />
                            <Route path='edit_lp/:id'
                                element={ <RequireAuth><AssetWrap><RedemptionCreateLP /></AssetWrap></RequireAuth> }
                            >
                                {/* <Route path='items'
                                    element={ <RequireAuth><AssetWrap><RedemptionCreate /></AssetWrap></RequireAuth> }
                                /> */}
                                <Route path='documents'
                                    element={ <RequireAuth><AssetWrap><RedemptionCreateLP /></AssetWrap></RequireAuth> }
                                />
                            </Route>
                            <Route path='edit/:id'
                                element={ <RequireAuth><AssetWrap><RedemptionCreate /></AssetWrap></RequireAuth> }
                            >
                                {/* <Route path='items'
                                    element={ <RequireAuth><AssetWrap><RedemptionCreate /></AssetWrap></RequireAuth> }
                                /> */}
                                <Route path='documents'
                                    element={ <RequireAuth><AssetWrap><RedemptionCreate /></AssetWrap></RequireAuth> }
                                />
                            </Route>
                        </Route>
                        <Route path='execution'>
                            <Route path=':id'
                                element={ <AssetWrap><ExecutionView /></AssetWrap> }
                            />
                            <Route path='edit_lp/:id'
                                element={ <RequireAuth><AssetWrap><ExecutionEditLP /></AssetWrap></RequireAuth> }
                            >
                                <Route path='contracts'
                                element={ <RequireAuth><AssetWrap><ExecutionEditLP /></AssetWrap></RequireAuth> }
                                />
                            </Route>
                            <Route path='edit/:id'
                                element={ <RequireAuth><AssetWrap><ExecutionEdit /></AssetWrap></RequireAuth> }
                            >
                                <Route path='contracts'
                                element={ <RequireAuth><AssetWrap><ExecutionEdit /></AssetWrap></RequireAuth> }
                                />
                            </Route>
                        </Route>
                    </Route>

                    {/* <Route path='object/:id'
                        element={  <>  <div className={styles.main}><div className={styles.container}><ObjectItemPage /></div></div> </>}
                    >
                        <Route path='lease_requests'
                            element={  <>  <div className={styles.main}><div className={styles.container}><ObjectItemPage /></div></div> </>}
                        />
                        <Route path='lease_contracts'
                            element={  <><div className={styles.main}><div className={styles.container}><ObjectItemPage /></div></div> </>}
                        />
                        <Route path='lease_actions'
                            element={  <>  <div className={styles.main}><div className={styles.container}><ObjectItemPage /></div></div> </>}
                        />
                        <Route path='actions/create'
                            element={  <>  <div className={styles.main}><div className={styles.container}><ObjectItemPage /></div></div> </>}
                        />
                    </Route> */}

                    <Route path='lease' >

                        <Route path='my_objects'
                            element={ <RequireAuth> <div className={styles.main}><div className={styles.container}><TopMenu /><MyObjects /></div></div> </RequireAuth> }
                        />
                        <Route path='my_actions'
                            element={ <RequireAuth> <div className={styles.main}><div className={styles.container}><TopMenu /><MyActions /></div></div> </RequireAuth> }
                        />
                        <Route path='my_requests'
                            element={ <RequireAuth> <div className={styles.main}><div className={styles.container}><TopMenu /><MyRequests /></div></div> </RequireAuth> }
                        />
                        <Route path='requests_to_my_objects'
                            element={ <RequireAuth> <div className={styles.main}><div className={styles.container}><TopMenu /><ToMyObjRequests /></div></div> </RequireAuth> }
                        />
                        <Route
                            path='objects_list'
                            element={ <>
                                        <div className={styles.main}>
                                            <div className={styles.container}>
                                                <TopMenu />
                                                <Pages />
                                            </div>
                                        </div>
                                    </>
                        }
                        />
                        <Route path='actions_list'
                            element={ <div className={styles.main}><div className={styles.container}><TopMenu /><Pages /></div></div> }
                        />
                        <Route path='requests_list'
                            element={ <div className={styles.main}><div className={styles.container}><TopMenu /> <Pages /> </div></div> }
                        />
                        <Route path='contracts_list'
                            element={ <div className={styles.main}><div className={styles.container}><TopMenu /> <Pages /> </div></div> }
                        />
                        <Route path='object/:id'
                            element={  <>  <div className={styles.main}><div className={styles.container}><ObjectItemPage /></div></div> </>}
                        >
                            <Route path='lease_requests'
                                element={  <>  <div className={styles.main}><div className={styles.container}><ObjectItemPage /></div></div> </>}
                            />
                            <Route path='lease_contracts'
                                element={  <><div className={styles.main}><div className={styles.container}><ObjectItemPage /></div></div> </>}
                            />
                            <Route path='lease_actions'
                                element={  <>  <div className={styles.main}><div className={styles.container}><ObjectItemPage /></div></div> </>}
                            />
                            <Route path='actions/create'
                                element={  <>  <div className={styles.main}><div className={styles.container}><ObjectItemPage /></div></div> </>}
                            />
                        </Route>
                        <Route path='lease_request'>
                            {token?.access_token
                                ? <Route path='draft/:id'
                                    element={ <div className={styles.main}><div className={styles.container}><LeaseRequestEdit /></div></div> }
                                />
                                : <Route path='*'
                                    element={ <><NotFoundPage errorCode={404}/></> }
                                />
                            }
                            <Route path=':id'
                                element={ <div className={styles.main}><div className={styles.container}><RequestItem /></div></div> }
                            />
                        </Route>
                        <Route path='actionItem'
                            // element={  <> <Header /> <div className={styles.main}><div className={styles.container}><ActionItemPage /> </div></div> </>}
                        >
                            <Route path='draft/:id'
                                element={ <div className={styles.main}><div className={styles.container}><LeaseActionEdit /></div></div> }
                            />
                            <Route path=':id'
                                element={ <div className={styles.main}><div className={styles.container}><ActionItemPage /></div></div> }
                            />
                            {/* <Route path='edit'
                                element={  <><div className={styles.main}><div className={styles.container}><ActionCreate /></div></div></>}
                            /> */}
                            <Route path=':id/related'
                                element={  <><div className={styles.main}><div className={styles.container}><ActionItemPage /> </div></div></>}
                            />
                        </Route>
                        
                        <Route path='create'
                            element={ <RequireAuth> <div className={styles.main}><div className={styles.container}><ObjectCreate /> </div></div></RequireAuth> }
                        />
                        <Route path='create/:id'
                            element={ <RequireAuth> <div className={styles.main}><div className={styles.container}><ObjectCreate /> </div></div></RequireAuth> }
                        >
                            <Route path={'objectItems'} element={  <> <RequireAuth> <div className={styles.main}><div className={styles.container}><ObjectCreate /> </div></div></RequireAuth></>} />
                            <Route path={'documents'} element={  <> <RequireAuth> <div className={styles.main}><div className={styles.container}><ObjectCreate /> </div></div></RequireAuth></>} />
                            <Route path={'contracts'} element={  <> <RequireAuth> <div className={styles.main}><div className={styles.container}><ObjectCreate /> </div></div></RequireAuth></>} />
                        </Route>
                    </Route>
                    
                    <Route path='*'
                            element={ <><NotFoundPage errorCode={404}/></> }
                    />
                    <Route path="/map/" component={Map} />
                </ Routes>
                <Footer />
            </div>
        </AuthProvider>
  )
}

export default App;