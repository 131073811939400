import React, {useEffect} from 'react';
import styles from './../generalInfo/generalInfoBlock.module.css';
import { useDispatch, useSelector } from 'react-redux';
// generalInfoBlock.module.css

import { 
    objectGetFuelTypeList, 
    objectGetTransmissionTypeList, 
    objectGetEncumbrancesList,
} from '../../../../../redux/actions/object/object';

import { checkProp, isMongo } from '../../../../../utils/check';
import HalfInfoBlock from '../halfInfoBlock/halfInfoBlock';
import FullInfoBlock from '../../../../elements/fullInfoBlock/fullInfoBlock';
import PopDownBlock from '../../../../elements/popdownBlock/popDownBlock';

const ObjectItem =({data})=> {

    const dispatch = useDispatch();
    // const object = useSelector(state => state.start.objectGet);
    // const objectItemDeletePopup = useSelector(state => state.start.objectItemDeletePopup);
    const objectFuelTypeList = useSelector(state => state.start.objectFuelTypeList);
    const objectTransmissionTypeList = useSelector(state => state.start.objectTransmissionTypeList);
    // const objectOwnershipTypeList = useSelector(state => state.start.objectOwnershipTypeList);
    const objectEncumbrancesList = useSelector(state => state.start.objectEncumbrancesList);
    const objectConstructionTechnologyList = useSelector(state => state.start.objectConstructionTechnologyList);
    const urlFuelTypeList = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/public/dictionaries/fuelType`;
    const urlTransmissionTypeList = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/public/dictionaries/transmissionType`;
    const urlEncumbrancesList = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/public/dictionaries/encumbrances`;

    useEffect(() => {
        if (objectFuelTypeList === null) {
            dispatch(objectGetFuelTypeList(urlFuelTypeList));
        }
        if (objectTransmissionTypeList === null) {
            dispatch(objectGetTransmissionTypeList(urlTransmissionTypeList));
        }
        if (objectEncumbrancesList === null) {
            dispatch(objectGetEncumbrancesList(urlEncumbrancesList));
        }
        if (data.itemType === 'realEstate' && objectConstructionTechnologyList === null) {
            dispatch(objectGetEncumbrancesList(urlEncumbrancesList));
        }
    });
    
            function getAddClasification() { // додаткові класифікатори
                if (checkProp(data.basicInfo, 'additionalClassifications')){
                    let tmp = data.basicInfo.additionalClassifications.map( (item)=> {
                        let tmpScheme = item.scheme
                        let tmpDesc = isMongo(item.description)
                        let tmpId = item.id
                        return <li key={tmpId}>{tmpScheme} {tmpDesc}: {tmpId}</li>
                    })
                    return tmp
                }
            }

            function getLocationInBuilding() { // розташування у будівлі
                switch(data.reProps.locationInBuilding){
                    case 'aboveGround':
                        return 'Надземний'
                    case 'pedimental':
                        return 'Цокольний'
                    case 'basement':
                        return 'Підвальний'
                    case 'technical':
                        return 'Технічний'
                    case 'attic':
                        return 'Мансардний'
                    default: return
                }
            }

            function getСonstructionTechnology() { // технологія будівництва
                switch(data.reProps.constructionTechnology){
                    case 'monolithicFrame':
                        return 'Монолітно-каркасна'
                    case 'panel':
                        return 'Панель'
                    case 'insulatedPanel':
                        return 'Утеплена панель'
                    case 'brick':
                        return 'Цегла'
                    case 'other':
                        return 'Iнше'
                    default: return
                }
            }

            function getPowerSupplyClass() { // Ступінь потужності електромережі
                switch(data.reProps.powerSupplyClass){
                    case 'first':
                        return 'Перший ступінь'
                    case 'second':
                        return 'Другий ступінь'
                    case 'over50':
                        return 'Понад 50 кВт'
                    default: return
                }
            }

            function getFuelType() { // Тип палива
                switch(data.vehicleProps.fuelType){
                    case 'gasoline':
                        return 'Бензин'
                    case 'diesel':
                        return 'Дизель'
                    case 'naturalGas':
                        return 'Газ'
                    case 'liquefiedPetroleumGas':
                        return 'Зріджений нафтовий газ'
                    case 'electric':
                        return 'Електро'
                    case 'hybrid':
                        return 'Гібридний'
                    case 'other':
                        return 'Інший'
                    default: return
                }
            }

            function getTransmissionType() { // Тип трансмісії
                switch(data.vehicleProps.transmission){
                    case 'manual':
                        return 'Механічна'
                    case 'automatic':
                        return 'Автоматична'
                    case 'semiAutomatic':
                        return 'Напівавтоматична'
                    case 'other':
                        return 'Інший'
                    default: return
                }
            }

            function getEncumbrances(a) { // Наявність обтяжень
                switch(a){
                    case 'arrest':
                        return 'Арешт нерухомого майна'
                    case 'collateral':
                        return 'Податкова застава'
                    case 'restraintsOnAlienation':
                        return 'Заборона відчуження'
                    case 'otherEncumbrances':
                        return 'Інші обтяження'
                    case 'noEncumbrances':
                        return 'Обтяження відсутні'
                    default: return
                }
            }

            function getOwnershipType(a) { // Тип власності
                switch(a){
                    case 'state':
                        return 'Державна'
                    case 'private':
                        return 'Приватна'
                    case 'municipal':
                        return 'Комунальна'
                    case 'compatible':
                        return 'Сумісна'
                    case 'unknown':
                        return 'Невідомо'
                    default: return
                }
            }

    function showItemProps(){
        const vehicle = <>
            {   (checkProp(data.vehicleProps, 'brand') && data.vehicleProps.brand !== null)
                ? <HalfInfoBlock
                    title={`Марка транспортного засобу`}
                    data={data.vehicleProps.brand}
                /> : null
            }
            {   (checkProp(data.vehicleProps, 'model') && data.vehicleProps.model !== null)
                ? <HalfInfoBlock
                    title={`Модель транспортного засобу`}
                    data={data.vehicleProps.model}
                /> : null
            }
            {   (checkProp(data.vehicleProps, 'productionYear') && data.vehicleProps.productionYear !== null)
                ? <HalfInfoBlock
                    title={`Рік випуску`}
                    data={data.vehicleProps.productionYear}
                /> : null
            }
            {   (checkProp(data.vehicleProps, 'engineCapacity') && data.vehicleProps.engineCapacity !== null)
                ? <HalfInfoBlock
                    title={`Об’єм двигуна, см³`}
                    data={data.vehicleProps.engineCapacity}
                /> : null
            }
            {   (checkProp(data.vehicleProps, 'fuelType') && data.vehicleProps.fuelType !== null)
                ? <HalfInfoBlock
                    title={`Тип палива`}
                    data={getFuelType()}
                /> : null
            }
            {   (checkProp(data.vehicleProps, 'transmission') && data.vehicleProps.transmission !== null)
                ? <HalfInfoBlock
                    title={`Тип коробки передач`}
                    data={getTransmissionType()}
                /> : null
            }
            {   (checkProp(data.vehicleProps, 'kilometrage') && data.vehicleProps.kilometrage !== null)
                ? <HalfInfoBlock
                    title={`Пробіг, км`}
                    data={data.vehicleProps.kilometrage}
                /> : null
            }
            {   (checkProp(data.vehicleProps, 'identificationNumber') && data.vehicleProps.identificationNumber !== null)
                ? <HalfInfoBlock
                    title={`Номер кузова або шасі`}
                    data={data.vehicleProps.identificationNumber}
                /> : null
            }
            {   (checkProp(data.vehicleProps, 'condition') && data.vehicleProps.condition !== null)
                ? <HalfInfoBlock
                    title={`Технічний стан`}
                    data={data.vehicleProps.condition}
                /> : null
            }
            {   (checkProp(data.vehicleProps, 'configuration') && data.vehicleProps.configuration !== null)
                ? <HalfInfoBlock
                    title={`Комплектація`}
                    data={data.vehicleProps.configuration}
                /> : null
            }
            {   (checkProp(data.vehicleProps, 'damagePresence') && data.vehicleProps.damagePresence !== null)
                ? <HalfInfoBlock
                    title={`Потреба у ремонті`}
                    data={data.vehicleProps.damagePresence ? 'Так' : 'Ні'}
                /> : null
            }
            {   (checkProp(data.vehicleProps, 'damagedDescription') && data.vehicleProps.damagedDescription !== null)
                ? <HalfInfoBlock
                    title={`Опис пошкоджень`}
                    data={data.vehicleProps.damagedDescription}
                /> : null
            }
            {   (checkProp(data.vehicleProps, 'color') && data.vehicleProps.color !== null)
                ? <HalfInfoBlock
                    title={`Колір`}
                    data={data.vehicleProps.color}
                /> : null
            }
        </>

        const building = <>
                    {   (checkProp(data.reProps, 'totalBuildingArea') && data.reProps.totalBuildingArea !== null )
                        ? <HalfInfoBlock
                            title={`Загальна площа будівлі, до складу якої входить об'єкт оренди, кв.м.`}
                            data={data.reProps.totalBuildingArea}
                            /> : null
                    }
                    {   (checkProp(data.reProps, 'totalObjectArea') && data.reProps.totalObjectArea !== null)
                        ? <HalfInfoBlock
                            title={`Загальна площа об'єкту оренди, кв.м.`}
                            data={data.reProps.totalObjectArea}
                            /> : null
                    }
                    {   (checkProp(data.reProps, 'usableArea') && data.reProps.usableArea !== null)
                        ? <HalfInfoBlock
                            title={`Корисна площа об'єкту оренди, кв.м.`}
                            data={data.reProps.usableArea} 
                            /> : null
                    } 
                    {   (checkProp(data.reProps, 'locationInBuilding') && data.reProps.locationInBuilding !== null)
                        ? <HalfInfoBlock
                            title={`Місце розташування об’єкта в будівлі`}
                            data={getLocationInBuilding()} 
                            /> : null
                    } 
                    {   (checkProp(data.reProps, 'constructionYear') && data.reProps.constructionYear !== null)
                        ? <HalfInfoBlock
                            title={`Рік будівництва`}
                            data={data.reProps.constructionYear} 
                            /> : null
                    } 
                    {   (checkProp(data.reProps, 'constructionTechnology') && data.reProps.constructionTechnology !== null)
                        ? <HalfInfoBlock
                            title={`Технологія будівництва`}
                            data={getСonstructionTechnology()} 
                            /> : null
                    }
                    {   (checkProp(data.reProps, 'livingArea') && data.reProps.livingArea !== null)
                        ? <HalfInfoBlock
                            title={`Житлова площа, кв. м`}
                            data={data.reProps.livingArea} 
                            /> : null
                    }
                    {   (checkProp(data.reProps, 'kitchenArea') && data.reProps.kitchenArea !== null)
                        ? <HalfInfoBlock
                            title={`Площа кухні, кв. м`}
                            data={data.reProps.kitchenArea} 
                            /> : null
                    }
                    {   (checkProp(data.reProps, 'landArea') && data.reProps.landArea !== null)
                        ? <HalfInfoBlock
                            title={`Площа ділянки, кв. м`}
                            data={data.reProps.landArea} 
                            /> : null
                    }
                    {   (checkProp(data.reProps, 'floors') && data.reProps.floors !== null)
                        ? <HalfInfoBlock
                            title={`Номер поверху або поверхів`}
                            data={data.reProps.floors} 
                            /> : null
                    }
                    {   (checkProp(data.reProps, 'generalCondition') && data.reProps.generalCondition !== null)
                        ? <HalfInfoBlock
                            title={`Технічний стан об'єкта оренди`}
                            data={data.reProps.generalCondition} 
                            /> : null
                    }
                    {   (checkProp(data.reProps, 'serviceElectricity') && data.reProps.serviceElectricity !== null)
                        ? <HalfInfoBlock
                            title={`Чи приєднаний об'єкт оренди до електромережі`}
                            data={data.reProps.serviceElectricity ? 'Так' : 'Ні'} 
                            /> : null
                    }
                    {   (checkProp(data.reProps, 'powerSupplyCapacity') && data.reProps.powerSupplyCapacity !== null)
                        ? <HalfInfoBlock
                            title={`Потужність електромережі, кВт`}
                            data={data.reProps.powerSupplyCapacity} 
                            /> : null
                    }
                    {   (checkProp(data.reProps, 'powerSupplyClass') && data.reProps.powerSupplyClass !== null)
                        ? <HalfInfoBlock
                            title={`Ступінь потужності електромережі`}
                            data={getPowerSupplyClass()} 
                            /> : null
                    }
                    {   (checkProp(data.reProps, 'serviceWater') && data.reProps.serviceWater !== null)
                        ? <HalfInfoBlock
                            title={`Водозабезпечення присутнє`}
                            data={data.reProps.serviceWater ? 'Так' : 'Ні'} 
                            /> : null
                    }
                    {   (checkProp(data.reProps, 'serviceSewerage') && data.reProps.serviceSewerage !== null)
                        ? <HalfInfoBlock
                            title={`Каналізація присутня`}
                            data={data.reProps.serviceSewerage ? 'Так' : 'Ні'} 
                            /> : null
                    }
                    {   (checkProp(data.reProps, 'serviceGas') && data.reProps.serviceGas !== null)
                        ? <HalfInfoBlock
                            title={`Газифікація присутня`}
                            data={data.reProps.serviceGas ? 'Так' : 'Ні'} 
                            /> : null
                    }
                    {   (checkProp(data.reProps, 'serviceCentralHeating') && data.reProps.serviceCentralHeating !== null)
                        ? <HalfInfoBlock
                            title={`Централізоване опалення присутнє`}
                            data={data.reProps.serviceCentralHeating ? 'Так' : 'Ні'} 
                            /> : null
                    }
                    {   (checkProp(data.reProps, 'serviceAutonomousHeating') && data.reProps.serviceAutonomousHeating !== null)
                        ? <HalfInfoBlock
                            title={`Автономне опалення присутнє`}
                            data={data.reProps.serviceAutonomousHeating ? 'Так' : 'Ні'} 
                            /> : null
                    }
                    {   (checkProp(data.reProps, 'serviceHeatingCounter') && data.reProps.serviceHeatingCounter !== null)
                        ? <HalfInfoBlock
                            title={`Лічильник опалення присутній`}
                            data={data.reProps.serviceHeatingCounter ? 'Так' : 'Ні'} 
                            /> : null
                    }
                    {   (checkProp(data.reProps, 'serviceVentilation') && data.reProps.serviceVentilation !== null)
                        ? <HalfInfoBlock
                            title={`Вентиляція присутня`}
                            data={data.reProps.serviceVentilation ? 'Так' : 'Ні'} 
                            /> : null
                    }
                    {   (checkProp(data.reProps, 'serviceAirConditioning') && data.reProps.serviceAirConditioning !== null)
                        ? <HalfInfoBlock
                            title={`Кондиціонування присутнє`}
                            data={data.reProps.serviceAirConditioning ? 'Так' : 'Ні'} 
                            /> : null
                    }
                    {   (checkProp(data.reProps, 'servicePhone') && data.reProps.servicePhone !== null)
                        ? <HalfInfoBlock
                            title={`Телефонізація присутня`}
                            data={data.reProps.servicePhone ? 'Так' : 'Ні'} 
                            /> : null
                    }
                    {   (checkProp(data.reProps, 'serviceTV') && data.reProps.serviceTV !== null)
                        ? <HalfInfoBlock
                            title={`Телебачення присутнє`}
                            data={data.reProps.serviceTV ? 'Так' : 'Ні'} 
                            /> : null
                    }
                    {   (checkProp(data.reProps, 'serviceInternet') && data.reProps.serviceInternet !== null)
                        ? <HalfInfoBlock
                            title={`Інтернет присутній`}
                            data={data.reProps.serviceInternet ? 'Так' : 'Ні'} 
                            /> : null
                    }
                    {   (checkProp(data.reProps, 'serviceElevator') && data.reProps.serviceElevator !== null)
                        ? <HalfInfoBlock
                            title={`Ліфт присутній`}
                            data={data.reProps.serviceElevator ? 'Так' : 'Ні'} 
                            /> : null
                    }
                    {   (checkProp(data.reProps, 'serviceSecurityAlarm') && data.reProps.serviceSecurityAlarm !== null)
                        ? <HalfInfoBlock
                            title={`Охоронна сигналізація присутня`}
                            data={data.reProps.serviceSecurityAlarm ? 'Так' : 'Ні'} 
                            /> : null
                    }
                    {   (checkProp(data.reProps, 'serviceFireAlarm') && data.reProps.serviceFireAlarm !== null)
                        ? <HalfInfoBlock
                            title={`Пожежна сигналізація присутня`}
                            data={data.reProps.serviceFireAlarm ? 'Так' : 'Ні'} 
                            /> : null
                    }
                    {   (checkProp(data.reProps, 'servicesDescription') && data.reProps.servicesDescription !== null)
                        ? <FullInfoBlock
                            title={`Додаткова інформація щодо комунікацій, що є в об'єкті`}
                            data={data.reProps.servicesDescription} 
                            /> : null
                    }
                    {   (checkProp(data.reProps, 'servicesAccounting') && data.reProps.servicesAccounting !== null)
                        ? <FullInfoBlock
                            title={`Інформація про наявність окремих особових рахунків на об'єкт оренди, відкритих постачальниками комунальних послуг, або інформація про порядок участі орендаря у компенсації балансоутримувачу витрат на оплату комунальних послуг`}
                            data={data.reProps.servicesAccounting} 
                            /> : null
                    }
                    {   (checkProp(data.reProps, 'landTax') && data.reProps !== null && data.reProps.landTax !== null)
                        ? <FullInfoBlock
                            title={`Інформація щодо компенсації балансоутримувачу сплати земельного податку за користування земельною ділянкою, на якій розташований об'єкт оренди`}
                            data={data.reProps.landTax} 
                            /> : null
                    } 
            </>

        const jpc = <>
                {   (checkProp(data.jpcProps, 'quantityAndNomenclature') && data.jpcProps.quantityAndNomenclature !== null)
                ? <FullInfoBlock
                    title={`Обсяг та основна номенклатура продукції (робіт, послуг), у тому числі експортної`}
                    data={data.jpcProps.quantityAndNomenclature} 
                    /> : null
                }
                {   (checkProp(data.jpcProps, 'workplacesQuantity') && data.jpcProps.workplacesQuantity !== null)
                    ? <HalfInfoBlock
                        title={`Кількість робочих місць, ociб`}
                        data={data.jpcProps.workplacesQuantity} 
                        /> : null
                }
                {   (checkProp(data.jpcProps, 'landPlotInformation') && data.jpcProps.landPlotInformation !== null)
                    ? <FullInfoBlock
                        title={`Відомості про земельну ділянку, на якій розташовано єдиний майновий комплекс`}
                        data={data.jpcProps.landPlotInformation} 
                        /> : null
                }
                {   (checkProp(data.jpcProps, 'buildingsInformation') && data.jpcProps.buildingsInformation !== null)
                    ? <FullInfoBlock
                        title={`Відомості про будівлі (споруди, приміщення) єдиного майнового комплексу в обсязі, передбаченому для інформації про нерухоме майно`}
                        data={data.jpcProps.buildingsInformation} 
                        /> : null
                }
                {   (checkProp(data.jpcProps, 'mainObligations') && data.jpcProps.mainObligations !== null)
                    ? <FullInfoBlock
                        title={`Основні зобов’язання (договірні та позадоговірні)`}
                        data={data.jpcProps.mainObligations}
                        /> : null
                }
                {   (checkProp(data.jpcProps, 'leaseApproverWebsite') && data.jpcProps.leaseApproverWebsite !== null)
                    ? <FullInfoBlock
                        title={`Посилання на веб-сторінку орендодавця, на якій розміщена додаткова інформація про єдиний майновий комплекс`}
                        data={<a href={data.jpcProps.leaseApproverWebsite}>{data.jpcProps.leaseApproverWebsite}</a>}
                        /> : null
                }
                {jpcLand(data.jpcProps)}
            </>

        const land = jpcLand(data)

        switch(data.itemType){
            case 'vehicle':
                return vehicle
            case 'land':
                return land
            case 'realEstate':
                return building
            case 'jointPropertyComplex':
                return jpc
            // case 'otherProperty':
            //     return jpc
            default:
                return null
        }

    }

    function hasTrueOrFalse(data){
        if(data) return "Наявні"
        return "Відсутні"
    }

    function jpcLand(data){
        if(data === null) return null
        return <>
            {   (checkProp(data.landProps, 'landArea') && data.landProps.landArea !== null)
                ? <HalfInfoBlock
                    title={`Площа ділянки, га`}
                    data={data.landProps.landArea} 
                    /> : null
            }
            {   (checkProp(data.landProps, 'cadastralNumber') && data.landProps.cadastralNumber !== null)
                ? <HalfInfoBlock
                    title={`Кадастровий номер`}
                    data={data.landProps.cadastralNumber} 
                    /> : null
            }
            {   (checkProp(data.landProps, 'ownershipType') && data.landProps.ownershipType !== null)
                ? <HalfInfoBlock
                    title={`Тип власності`}
                    data={ getOwnershipType(data.landProps.ownershipType)} 
                    /> : null
            }
            {   (checkProp(data.landProps, 'hasEncumbrances') && data.landProps.hasEncumbrances !== null)
                ? <HalfInfoBlock
                    title={`Наявність обтяжень`}
                    data={ hasTrueOrFalse(data.landProps.hasEncumbrances)} 
                    /> : null
            }
            {   (checkProp(data.landProps, 'encumbrances') && data.landProps.encumbrances !== null)
                ? <HalfInfoBlock
                    title={`Інформація про наявність обтяжень`}
                    data={ getEncumbrances(data.landProps.encumbrances)} 
                    /> : null
            }
            {   (checkProp(data.landProps, 'hasJointOwnership') && data.landProps.hasJointOwnership !== null)
                ? <HalfInfoBlock
                    title={`Наявність співвласників`}
                    data={ hasTrueOrFalse(data.landProps.hasJointOwnership)} 
                    /> : null
            }
            {   (checkProp(data.landProps, 'jointOwnership') && data.landProps.jointOwnership !== null)
                ? <HalfInfoBlock
                    title={`Інформація про співвласників`}
                    data={data.landProps.jointOwnership} 
                    /> : null
            }
            {   (checkProp(data.landProps, 'hasUtilitiesAvailability') && data.landProps.hasUtilitiesAvailability !== null)
                ? <HalfInfoBlock
                    title={`Наявність інженерних мереж`}
                    data={ hasTrueOrFalse(data.landProps.hasUtilitiesAvailability)} 
                    /> : null
            }
            {   (checkProp(data.landProps, 'utilitiesAvailability') && data.landProps.utilitiesAvailability !== null)
                ? <HalfInfoBlock
                    title={`Інформація про інженерні мережі`}
                    data={data.landProps.utilitiesAvailability} 
                    /> : null
            }
            {   (checkProp(data.landProps, 'landIntendedUse') && data.landProps.landIntendedUse !== null)
                ? <HalfInfoBlock
                    title={`Поточне використання земельної ділянки`}
                    data={data.landProps.landIntendedUse} 
                    /> : null
            }
        </>
    }


    return (
        <div className={styles.infoBlock}>
            <h3>{isMongo(data.basicInfo.title)}</h3>
            <FullInfoBlock
                title={`Опис об'єкта`}
                data={isMongo(data.basicInfo.description)}
            />
            { checkProp(data, 'placing') && checkProp(data.placing, 'address') ?
                <FullInfoBlock
                    title='Місцезнаходження'
                    data={`${isMongo(data.placing.address.countryName)}, 
                    ${isMongo(data.placing.address.region) === 'Київ' ? '' : isMongo(data.placing.address.region) + ','} 
                    ${isMongo(data.placing.address.locality)}, 
                    ${isMongo(data.placing.address.streetAddress)}`}
                    moreData={ data?.placing?.location && `Широта: ${data.placing.location.latitude.substring(0, 8)} / Довгота: ${data.placing.location.longitude.substring(0, 8)}` }
                /> : null
            }
            { (checkProp(data.basicInfo, 'classification') && data.basicInfo.classification.length > 0) ?
                <HalfInfoBlock
                    title={`Основний класифікатор об'єкта`}
                    data={`${data.basicInfo.classification.scheme} ${data.basicInfo.classification.id}: ${isMongo(data.basicInfo.classification.description)}`}
                    /> : null
            }
            { checkProp(data.basicInfo, 'additionalClassifications') ?
                <HalfInfoBlock
                    title={`Додаткові класифікатори об'єкта`}
                    data={ getAddClasification() }
                /> : null
            }
            { (checkProp(data, 'reProps') || checkProp(data, 'vehicleProps') || checkProp(data, 'jpcProps') || checkProp(data, 'landProps')) ? // Нерухомість
                <PopDownBlock 
                    title='Специфічні поля'
                    content={showItemProps()}
                /> : null
            }
            </div>
    )
}

export default ObjectItem