import React, { useState, useEffect } from "react"
import styles from './decisionItem.module.css';
import Button from "../../../../../elements/buttons/button"
import DecisionItem from "./decisionItem"

const AssetDecisionsEdit =( { data, setData } )=> {
    // const [list, setList] = useState(!!data ? data : [{
    //     decisionTitle: '',
    //     decisionNumber: '',
    //     decisionDate: null,
    //     decisionOf: 'asset'
    // }])

    // useEffect(() => {
    //   if ( !data || data.length < 1){
    //     setData([...data, {
    //         decisionTitle: '',
    //         decisionNumber: '',
    //         decisionDate: null
    //     }])
    //   }
    // }, [data])

    return (
        <div className={styles.itemsWrap} > 
            {/* { !data || data.length < 1 && <DecisionItem data={ data } setData={ setData }/> } */}
            { !!data && data.map( ( item, count ) => <DecisionItem 
                                            data={ item }
                                            decisions={ data }
                                            key={ item.toString() }
                                            index={count}
                                            setData={ setData }
                                        />
                )
            }
            <div className={styles.addBtnWrap}>
                <Button color={'white'} 
                    onClick={ ()=> setData([...data, 
                                {
                                    decisionTitle: '',
                                    decisionNumber: '',
                                    decisionDate: null,
                                    decisionOf: 'asset'
                                }]) 
                    }
                >
                    Додати рішення
                </Button>
            </div>
        </div>
    )
}

export default AssetDecisionsEdit