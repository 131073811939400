import { GET_BANK_ACCOUNTS_LIST } from "./types";
import { getAPI, postAPI, patchAPI, deleteAPI } from "../actions/crud";

export function getBanksList(_url, token){
    let header;
    if (token) {
        header = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        }
    } else {
        header = {
            'Content-Type': 'application/json'
        }
    }
    const data = {
        url: _url,
        actionType: GET_BANK_ACCOUNTS_LIST,
        header: header,
    }
    return getAPI(data);
}