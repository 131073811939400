import React from 'react';
import styles from './setupFilter.module.css';

import { connect, useDispatch, useSelector } from 'react-redux';

import { setSearchReset, setFilterSearch } from '../../../../redux/search'

import {
    auctionsGetSearch,
    regPaginatorCountGet,
    setSearchTextSting,

    // openVidjetClassificationAuctionString,
    // openVidjetClassificationAuctionArrGet,
    // openVidjetClassificationAuctionArr,
    // openVidjetClassificationAuctionArrNew,
    // openVidjetClassificationAuction,
    // setArhStatusAuction,
    // setArhStatusAuctionNew,
    // openVidjetStatusAuction,
    // openVidjetOrganizatorAuctionString,
    // openVidjetOrganizatorAuctionArrGet,
    // openVidjetOrganizatorAuctionArr,
    // openVidjetOrganizatorAuctionArrNew,
    // openVidjetOrganizatorAuction,
    // openVidjetBuyersAuctionString,
    // openVidjetBuyersAuctionArrGet,
    // openVidjetBuyersAuctionArr,
    // openVidjetBuyersAuctionArrNew,
    // openVidjetBuyersAuction,
    // openVidjetSimpleFilterAuctionRegionOpen,
    // openVidjetSimpleFilterAuctionRegionProc,
    // openVidjetSimpleFilterAuctionRegionProcNew,
    // openVidjetProcurementMethodType,
    // openVidjetProcurementMethodTypeProc,
    // openVidjetProcurementMethodTypeProcNew,
    // openVidjetSimpleFilterAuctionTypeSubjectProcurement,
    // openVidjetSimpleFilterAuctionTypeSubjectProcurementNew,
    // openVidjetTypeSubjectProcurement,
    // openVidjetSimpleFilterAuction,
    // openVidjetSimpleFilterAuctionTypePayment,
    // openVidjetSimpleFilterAuctionTypePaymentNew,
    // openVidjetfilterValueAmountFrom,
    // openVidjetfilterValueAmountFromNew,
    // openVidjetfilterValueAmountTo,
    // openVidjetfilterValueAmountToNew,
    // openVidjetfilterValueAmountOpen,
    // openVidjetfilterEnquiryPeriodFrom,
    // openVidjetfilterEnquiryPeriodFromNew,
    // openVidjetfilterEnquiryPeriodTo,
    // openVidjetfilterEnquiryPeriodToNew,
    // openVidjetfilterEnquiryPeriodOpen,
    // openVidjetfilterAuctionPeriodFrom,
    // openVidjetfilterAuctionPeriodFromNew,
    // openVidjetfilterAuctionPeriodTo,
    // openVidjetfilterAuctionPeriodToNew,
    // openVidjetfilterAuctionPeriodOpen,
    // openVidjetfilterTenderPeriodFrom,
    // openVidjetfilterTenderPeriodFromNew,
    // openVidjetfilterTenderPeriodTo,
    // openVidjetfilterTenderPeriodToNew,
    // openVidjetfilterTenderPeriodOpen,
    // openVidjetfilterPlansTenderPeriodFrom,
    // openVidjetfilterPlansTenderPeriodFromNew,
    // openVidjetfilterPlansTenderPeriodTo,
    // openVidjetfilterPlansTenderPeriodToNew,
    // openVidjetfilterPlansTenderPeriodOpen,
    // openVidjetDjereloFinArr,
    // openVidjetDjereloFinArrNew,
    // openVidjetDjereloFin,
    // openVidjetRozporyadnukKoshtivString,
    // openVidjetRozporyadnukKoshtivArrGet,
    // openVidjetRozporyadnukKoshtivArr,
    // openVidjetRozporyadnukKoshtivArrNew,
    // openVidjetRozporyadnukKoshtiv,
    // openVidjetfilterSummFrom,
    // openVidjetfilterSummFromNew,
    // openVidjetfilterSummTo,
    // openVidjetfilterSummToNew,
    // openVidjetfilterSummOpen,
    sortListOpen,
    sortListArray
} from '../../../../redux/actions/auctions/auctions';

import {
    setCurrentUrl,
    setArraySortList,
} from '../../../../redux/filters'

import {
    changeMyCurentPage,
    changeArraySortList,
} from '../../../../redux/actions.js'

const SetupFilter = (props) => {
    const dispatch = useDispatch()
    const searchUrl  = useSelector(state => state.start.search.url)
    const start = useSelector(state => state.start)
    const registry = useSelector(state => state.start.search.registry)
    const leaseAction = useSelector(state => state.start.search.leaseAction)
    const leaseRequest = useSelector(state => state.start.search.leaseRequest)
    const leaseContract = useSelector(state => state.start.search.leaseContract)

    async function setSearch(a) {
        switch (a) {
            case 'registry_objects':
                setFilter(registry)
                break
            case 'lease_actions':
                setFilter(leaseAction)
                break
            case 'lease_requests':
                setFilter(leaseRequest)
                break
            case 'contracts':
                setFilter(leaseContract)
                break
            }
    }

    function setFilter(type) {
        let data = setFilterSearch(start, type)
        dispatch({ payload: data.statusString, type: 'SET_STATUS_STRING' })
        // dispatch(regPaginatorCountGet(data.path+data.params))
        dispatch(auctionsGetSearch(data, data.header))
        // dispatch(changeMyCurentPage(1))
    }

    return (
        <div className={styles.setupFilterWrap}>
            <div
                className={styles.filterReset}
                onClick={
                    ()=>{
                        // handleClear(props)
                        dispatch( setSearchReset() )
                        setSearch(searchUrl)
                    }
                }
            >
                Скинути все
            </div>
            {/* <div className={styles.filterUnActive}>Зберегти налаштування пошуку</div>
            <div className={styles.filterUnActive}>Отримувати оновлення на email</div> */}
        </div>
    )
}

// function handleClear(props){
//     //Поисковій запрос
// props.setSearchTextSting('')
// //Страница
// props.changeMyCurentPage(1)
// //Классификатор
// props.openVidjetClassificationAuctionString('')
// props.openVidjetClassificationAuctionArrGet(null)
// props.openVidjetClassificationAuctionArr([])
// props.openVidjetClassificationAuctionArrNew([])
// props.openVidjetClassificationAuction(false)
// //Статус
// props.setArhStatusAuction([
// 	{status: 'active.tendering', title: 'Подання пропозицій', onOff: false, type: 1},
// 	{status: 'active.auction', title: 'Аукціон', onOff: false, type: 1},
// 	{status: 'active.qualification', title: 'Кваліфікація переможця', onOff: false, type: 1},
// 	{status: 'active.rectification', title: 'Підписання договору', onOff: false, type: 1},
// 	{status: 'complete', title: 'Торги завершено', onOff: false, type: 1},
// 	{status: 'unsuccessful', title: 'Торги не відбулися', onOff: false, type: 1},
// 	{status: 'cancelled', title: 'Торги скасовано', onOff: false, type: 1},
// 	{status: 'pending', title: 'Очікується підписання Договору', onOff: false, type: 2},
// 	{status: 'active', title: 'Договір підписано', onOff: false, type: 2},
// 	{status: 'cancelled', title: 'Договір скасовано', onOff: false, type: 2},
// 	{status: 'terminated', title: 'Договір виконано', onOff: false, type: 2},
// ])
// props.setArhStatusAuctionNew([
// 	{status: 'active.tendering', title: 'Подання пропозицій', onOff: false, type: 1},
// 	{status: 'active.auction', title: 'Аукціон', onOff: false, type: 1},
// 	{status: 'active.qualification', title: 'Кваліфікація переможця', onOff: false, type: 1},
// 	{status: 'active.rectification', title: 'Підписання договору', onOff: false, type: 1},
// 	{status: 'complete', title: 'Торги завершено', onOff: false, type: 1},
// 	{status: 'unsuccessful', title: 'Торги не відбулися', onOff: false, type: 1},
// 	{status: 'cancelled', title: 'Торги скасовано', onOff: false, type: 1},
// 	{status: 'pending', title: 'Очікується підписання Договору', onOff: false, type: 2},
// 	{status: 'active', title: 'Договір підписано', onOff: false, type: 2},
// 	{status: 'cancelled', title: 'Договір скасовано', onOff: false, type: 2},
// 	{status: 'terminated', title: 'Договір виконано', onOff: false, type: 2},
// ])
// props.openVidjetStatusAuction(false)
// //Организация
// props.openVidjetOrganizatorAuctionString('')
// props.openVidjetOrganizatorAuctionArrGet(null)
// props.openVidjetOrganizatorAuctionArr([])
// props.openVidjetOrganizatorAuctionArrNew([])
// props.openVidjetOrganizatorAuction(false)
// //Постачальник
// props.openVidjetBuyersAuctionString('')
// props.openVidjetBuyersAuctionArrGet(null)
// props.openVidjetBuyersAuctionArr([])
// props.openVidjetBuyersAuctionArrNew([])
// props.openVidjetBuyersAuction(false)
//     //Простой фильтр
// 	//Регион
// 	props.openVidjetSimpleFilterAuctionRegionOpen(false)
// 	props.openVidjetSimpleFilterAuctionRegionProc([
// 		{id: 'Автономна Республіка Крим', title: "АВТОНОМНА РЕСПУБЛІКА КРИМ/М.СІМФЕРОПОЛЬ", onOff: false},
//             {id: 'Вінницька область', title: "ВІННИЦЬКА ОБЛАСТЬ/М.ВІННИЦЯ", onOff: false},
//             {id: 'Волинська область', title: "ВОЛИНСЬКА ОБЛАСТЬ/М.ЛУЦЬК", onOff: false},
//             {id: 'Дніпропетровська область', title: "ДНІПРОПЕТРОВСЬКА ОБЛАСТЬ/М.ДНІПРО", onOff: false},
//             {id: 'Донецька область', title: "ДОНЕЦЬКА ОБЛАСТЬ/М.ДОНЕЦЬК", onOff: false},
//             {id: 'Житомирська область', title: "ЖИТОМИРСЬКА ОБЛАСТЬ/М.ЖИТОМИР", onOff: false},
//             {id: 'Закарпатська область', title: "ЗАКАРПАТСЬКА ОБЛАСТЬ/М.УЖГОРОД", onOff: false},
//             {id: 'Запорізька область', title: "ЗАПОРІЗЬКА ОБЛАСТЬ/М.ЗАПОРІЖЖЯ", onOff: false},
//             {id: 'Івано-Франківська область', title: "ІВАНО-ФРАНКІВСЬКА ОБЛАСТЬ/М.ІВАНО-ФРАНКІВСЬК", onOff: false},
//             {id: 'Київська область', title: "КИЇВСЬКА ОБЛАСТЬ/М.КИЇВ", onOff: false},
//             {id: 'Кіровоградська область', title: "КІРОВОГРАДСЬКА ОБЛАСТЬ/М.КРОПИВНИЦЬКИЙ", onOff: false},
//             {id: 'Луганська область', title: "ЛУГАНСЬКА ОБЛАСТЬ/М.ЛУГАНСЬК", onOff: false},
//             {id: 'Львівська область', title: "ЛЬВІВСЬКА ОБЛАСТЬ/М.ЛЬВІВ", onOff: false},
//             {id: 'Миколаївська область', title: "МИКОЛАЇВСЬКА ОБЛАСТЬ/М.МИКОЛАЇВ", onOff: false},
//             {id: 'Одеська область', title: "ОДЕСЬКА ОБЛАСТЬ/М.ОДЕСА", onOff: false},
//             {id: 'Полтавська область', title: "ПОЛТАВСЬКА ОБЛАСТЬ/М.ПОЛТАВА", onOff: false},
//             {id: 'Рівненська область', title: "РІВНЕНСЬКА ОБЛАСТЬ/М.РІВНЕ", onOff: false},
//             {id: 'Сумська область', title: "СУМСЬКА ОБЛАСТЬ/М.СУМИ", onOff: false},
//             {id: 'Тернопільська область', title: "ТЕРНОПІЛЬСЬКА ОБЛАСТЬ/М.ТЕРНОПІЛЬ", onOff: false},
//             {id: 'Харківська область', title: "ХАРКІВСЬКА ОБЛАСТЬ/М.ХАРКІВ", onOff: false},
//             {id: 'Херсонська область', title: "ХЕРСОНСЬКА ОБЛАСТЬ/М.ХЕРСОН", onOff: false},
//             {id: 'Хмельницька область', title: "ХМЕЛЬНИЦЬКА ОБЛАСТЬ/М.ХМЕЛЬНИЦЬКИЙ", onOff: false},
//             {id: 'Черкаська область', title: "ЧЕРКАСЬКА ОБЛАСТЬ/М.ЧЕРКАСИ", onOff: false},
//             {id: 'Чернівецька область', title: "ЧЕРНІВЕЦЬКА ОБЛАСТЬ/М.ЧЕРНІВЦІ", onOff: false},
//             {id: 'Чернігівська область', title: "ЧЕРНІГІВСЬКА ОБЛАСТЬ/М.ЧЕРНІГІВ", onOff: false},
//             {id: 'Київ', title: "М.КИЇВ", onOff: false},
//             {id: 'Севастополь', title: "М.СЕВАСТОПОЛЬ", onOff: false},
// 	])
// 	// props.openVidjetSimpleFilterAuctionRegionProcNew([
// 	// 	{id: 'Автономна Республіка Крим', title: "АВТОНОМНА РЕСПУБЛІКА КРИМ/М.СІМФЕРОПОЛЬ", onOff: false},
//     //         {id: 'Вінницька область', title: "ВІННИЦЬКА ОБЛАСТЬ/М.ВІННИЦЯ", onOff: false},
//     //         {id: 'Волинська область', title: "ВОЛИНСЬКА ОБЛАСТЬ/М.ЛУЦЬК", onOff: false},
//     //         {id: 'Дніпропетровська область', title: "ДНІПРОПЕТРОВСЬКА ОБЛАСТЬ/М.ДНІПРО", onOff: false},
//     //         {id: 'Донецька область', title: "ДОНЕЦЬКА ОБЛАСТЬ/М.ДОНЕЦЬК", onOff: false},
//     //         {id: 'Житомирська область', title: "ЖИТОМИРСЬКА ОБЛАСТЬ/М.ЖИТОМИР", onOff: false},
//     //         {id: 'Закарпатська область', title: "ЗАКАРПАТСЬКА ОБЛАСТЬ/М.УЖГОРОД", onOff: false},
//     //         {id: 'Запорізька область', title: "ЗАПОРІЗЬКА ОБЛАСТЬ/М.ЗАПОРІЖЖЯ", onOff: false},
//     //         {id: 'Івано-Франківська область', title: "ІВАНО-ФРАНКІВСЬКА ОБЛАСТЬ/М.ІВАНО-ФРАНКІВСЬК", onOff: false},
//     //         {id: 'Київська область', title: "КИЇВСЬКА ОБЛАСТЬ/М.КИЇВ", onOff: false},
//     //         {id: 'Кіровоградська область', title: "КІРОВОГРАДСЬКА ОБЛАСТЬ/М.КРОПИВНИЦЬКИЙ", onOff: false},
//     //         {id: 'Луганська область', title: "ЛУГАНСЬКА ОБЛАСТЬ/М.ЛУГАНСЬК", onOff: false},
//     //         {id: 'Львівська область', title: "ЛЬВІВСЬКА ОБЛАСТЬ/М.ЛЬВІВ", onOff: false},
//     //         {id: 'Миколаївська область', title: "МИКОЛАЇВСЬКА ОБЛАСТЬ/М.МИКОЛАЇВ", onOff: false},
//     //         {id: 'Одеська область', title: "ОДЕСЬКА ОБЛАСТЬ/М.ОДЕСА", onOff: false},
//     //         {id: 'Полтавська область', title: "ПОЛТАВСЬКА ОБЛАСТЬ/М.ПОЛТАВА", onOff: false},
//     //         {id: 'Рівненська область', title: "РІВНЕНСЬКА ОБЛАСТЬ/М.РІВНЕ", onOff: false},
//     //         {id: 'Сумська область', title: "СУМСЬКА ОБЛАСТЬ/М.СУМИ", onOff: false},
//     //         {id: 'Тернопільська область', title: "ТЕРНОПІЛЬСЬКА ОБЛАСТЬ/М.ТЕРНОПІЛЬ", onOff: false},
//     //         {id: 'Харківська область', title: "ХАРКІВСЬКА ОБЛАСТЬ/М.ХАРКІВ", onOff: false},
//     //         {id: 'Херсонська область', title: "ХЕРСОНСЬКА ОБЛАСТЬ/М.ХЕРСОН", onOff: false},
//     //         {id: 'Хмельницька область', title: "ХМЕЛЬНИЦЬКА ОБЛАСТЬ/М.ХМЕЛЬНИЦЬКИЙ", onOff: false},
//     //         {id: 'Черкаська область', title: "ЧЕРКАСЬКА ОБЛАСТЬ/М.ЧЕРКАСИ", onOff: false},
//     //         {id: 'Чернівецька область', title: "ЧЕРНІВЕЦЬКА ОБЛАСТЬ/М.ЧЕРНІВЦІ", onOff: false},
//     //         {id: 'Чернігівська область', title: "ЧЕРНІГІВСЬКА ОБЛАСТЬ/М.ЧЕРНІГІВ", onOff: false},
//     //         {id: 'Київ', title: "М.КИЇВ", onOff: false},
//     //         {id: 'Севастополь', title: "М.СЕВАСТОПОЛЬ", onOff: false},
// 	// ])
// 	// //Тип процедурі
// 	// props.openVidjetProcurementMethodType(false)
// 	// props.openVidjetProcurementMethodTypeProc([
//     //     {id: 'aboveThresholdUA', title: "Відкриті торги", onOff: false},
//     //     {id: 'esco', title: "Відкриті торги для закупівлі енергоресурсу", onOff: false},
//     //     {id: 'aboveThresholdEU', title: "Відкриті торги з публікацією англійською мовою", onOff: false},
//     //     {id: 'reporting', title: "Закупівля без використання електронної системи", onOff: false},
//     //     {id: 'reporting', title: "Закупівлі по COVID-19", onOff: false},
//     //     {id: 'priceQuotation', title: "Запит ціни пропозиції", onOff: false},
//     //     {id: 'competitiveDialogueUA', title: "Конкурентний діалог 1-ий етап", onOff: false},
//     //     {id: 'competitiveDialogueEU', title: "Конкурентний діалог з публікацією англійською мовою 1-ий етап", onOff: false},
//     //     {id: 'competitiveDialogueUA.stage2', title: "Конкурентний діалог 2-ий етап", onOff: false},
//     //     {id: 'competitiveDialogueEU.stage2', title: "Конкурентний діалог з публікацією англійською мовою 2-ий етап", onOff: false},
//     //     {id: 'negotiation', title: "Переговорна процедура", onOff: false},
//     //     {id: 'negotiation.quick', title: "Переговорна процедура (скорочена)", onOff: false},
//     //     {id: 'aboveThresholdUA.defense', title: "Переговорна процедура для потреб оборони", onOff: false},
//     //     {id: 'belowThreshold', title: "Спрощені закупівлі", onOff: false},
//     //     {id: 'belowThreshold', title: "Спрощені / Допорогові закупівлі", onOff: false},
//     //     {id: 'simple.defense', title: "Спрощені торги із застосуванням електронної системи", onOff: false},
//     //     {id: 'closeFrameworkAgreementUA', title: "Укладання рамкової угоди", onOff: false},
//     //     {id: 'reporting', title: "Prozorro Market", onOff: false},
//     //     {id: 'closeFrameworkAgreementSelectionUA', title: "Відбір для закупівлі за рамковою угодою", onOff: false},
//     //     ])
// 	// props.openVidjetProcurementMethodTypeProcNew([
//     //     {id: 'aboveThresholdUA', title: "Відкриті торги", onOff: false},
//     //     {id: 'esco', title: "Відкриті торги для закупівлі енергоресурсу", onOff: false},
//     //     {id: 'aboveThresholdEU', title: "Відкриті торги з публікацією англійською мовою", onOff: false},
//     //     {id: 'reporting', title: "Закупівля без використання електронної системи", onOff: false},
//     //     {id: 'reporting', title: "Закупівлі по COVID-19", onOff: false},
//     //     {id: 'priceQuotation', title: "Запит ціни пропозиції", onOff: false},
//     //     {id: 'competitiveDialogueUA', title: "Конкурентний діалог 1-ий етап", onOff: false},
//     //     {id: 'competitiveDialogueEU', title: "Конкурентний діалог з публікацією англійською мовою 1-ий етап", onOff: false},
//     //     {id: 'competitiveDialogueUA.stage2', title: "Конкурентний діалог 2-ий етап", onOff: false},
//     //     {id: 'competitiveDialogueEU.stage2', title: "Конкурентний діалог з публікацією англійською мовою 2-ий етап", onOff: false},
//     //     {id: 'negotiation', title: "Переговорна процедура", onOff: false},
//     //     {id: 'negotiation.quick', title: "Переговорна процедура (скорочена)", onOff: false},
//     //     {id: 'aboveThresholdUA.defense', title: "Переговорна процедура для потреб оборони", onOff: false},
//     //     {id: 'belowThreshold', title: "Спрощені закупівлі", onOff: false},
//     //     {id: 'belowThreshold', title: "Спрощені / Допорогові закупівлі", onOff: false},
//     //     {id: 'simple.defense', title: "Спрощені торги із застосуванням електронної системи", onOff: false},
//     //     {id: 'closeFrameworkAgreementUA', title: "Укладання рамкової угоди", onOff: false},
//     //     {id: 'reporting', title: "Prozorro Market", onOff: false},
//     //     {id: 'closeFrameworkAgreementSelectionUA', title: "Відбір для закупівлі за рамковою угодою", onOff: false},
//     //     ])
// 	// //Вид предмету закупивли
// 	// props.openVidjetSimpleFilterAuctionTypeSubjectProcurement('null')
// 	// props.openVidjetSimpleFilterAuctionTypeSubjectProcurementNew('null')
// 	// props.openVidjetTypeSubjectProcurement(false)
// 	// //Тип оплати
// 	// props.openVidjetSimpleFilterAuction(false)
// 	// props.openVidjetSimpleFilterAuctionTypeSubjectProcurement('null')
// 	// props.openVidjetSimpleFilterAuctionTypeSubjectProcurementNew('null')
// 	// props.openVidjetSimpleFilterAuctionTypePayment('null')
// 	// props.openVidjetSimpleFilterAuctionTypePaymentNew('null')
// 	// //Очикувана вартисть
// 	// props.openVidjetfilterValueAmountFrom('')
// 	// props.openVidjetfilterValueAmountFromNew('')
// 	// props.openVidjetfilterValueAmountTo('')
// 	// props.openVidjetfilterValueAmountToNew('')
// 	// props.openVidjetfilterValueAmountOpen(false)
// 	// //Завершення прийому пропозиції
// 	// props.openVidjetfilterEnquiryPeriodFrom('')
// 	// props.openVidjetfilterEnquiryPeriodFromNew('')
// 	// props.openVidjetfilterEnquiryPeriodTo('')
// 	// props.openVidjetfilterEnquiryPeriodToNew('')
// 	// props.openVidjetfilterEnquiryPeriodOpen(false)
// 	// //Дата початку аукціону
// 	// props.openVidjetfilterAuctionPeriodFrom('')
// 	// props.openVidjetfilterAuctionPeriodFromNew('')
// 	// props.openVidjetfilterAuctionPeriodTo('')
// 	// props.openVidjetfilterAuctionPeriodToNew('')
// 	// props.openVidjetfilterAuctionPeriodOpen(false)
// 	// //Дата публікації тендеру
// 	// props.openVidjetfilterTenderPeriodFrom('')
// 	// props.openVidjetfilterTenderPeriodFromNew('')
// 	// props.openVidjetfilterTenderPeriodTo('')
// 	// props.openVidjetfilterTenderPeriodToNew('')
// 	// props.openVidjetfilterTenderPeriodOpen(false)
// 	// props.openVidjetfilterPlansTenderPeriodFrom('')
// 	// props.openVidjetfilterPlansTenderPeriodFromNew('')
// 	// props.openVidjetfilterPlansTenderPeriodTo('')
// 	// props.openVidjetfilterPlansTenderPeriodToNew('')
// 	// props.openVidjetfilterPlansTenderPeriodOpen(false)
// 	// //Джерело фінансування
// 	// props.openVidjetDjereloFinArr([
//     //         {id: 'state', name: 'Державний бюджет України', status: false},
//     //         {id: 'crimea', name: 'Бюджет Автономної Республіки Крим', status: false},
//     //         {id: 'local', name: 'Місцевий бюджет', status: false},
//     //         {id: 'own', name: 'Власний бюджет (кошти від господарської діяльності підприємства)', status: false},
//     //         {id: 'fund', name: 'Бюджет цільових фондів', status: false},
//     //         {id: 'loan', name: 'Кредити та позики міжнародних валютно-кредитних організацій', status: false},
//     //         {id: 'other', name: 'Інше', status: false},
//     //     ])
// 	// props.openVidjetDjereloFinArrNew([
//     //         {id: 'state', name: 'Державний бюджет України', status: false},
//     //         {id: 'crimea', name: 'Бюджет Автономної Республіки Крим', status: false},
//     //         {id: 'local', name: 'Місцевий бюджет', status: false},
//     //         {id: 'own', name: 'Власний бюджет (кошти від господарської діяльності підприємства)', status: false},
//     //         {id: 'fund', name: 'Бюджет цільових фондів', status: false},
//     //         {id: 'loan', name: 'Кредити та позики міжнародних валютно-кредитних організацій', status: false},
//     //         {id: 'other', name: 'Інше', status: false},
//     //     ])
// 	// props.openVidjetDjereloFin(false)
// 	// //Розпорядник коштів
// 	// props.openVidjetRozporyadnukKoshtivString('')
// 	// props.openVidjetRozporyadnukKoshtivArrGet(null)
// 	// props.openVidjetRozporyadnukKoshtivArr([])
// 	// props.openVidjetRozporyadnukKoshtivArrNew([])
// 	// props.openVidjetRozporyadnukKoshtiv(false)
// 	// //Сума договору
// 	// props.openVidjetfilterSummFrom('')
// 	// props.openVidjetfilterSummFromNew('')
// 	// props.openVidjetfilterSummTo('')
// 	// props.openVidjetfilterSummToNew('')
// 	// props.openVidjetfilterSummOpen(false)
//     //Сортировка
// 	props.sortListOpen(false)

//     props.changeArraySortList(setArraySortList(props.search.url))
// 	/*props.sortListArray([
//         {name: 'Спочатку найновіші створені', status: true, findParamName: 'date', findSortStatus: 'DESC', flagTend: true, flagPlan: true, flagDog: true},
//         {name: 'Спочатку найстарші створені', status: false, findParamName: 'date', findSortStatus: 'ASC', flagTend: true, flagPlan: true, flagDog: true},
//         {name: 'Спочатку найнижча очікувана вартість', status: false, findParamName: 'value.amount', findSortStatus: 'DESC', flagTend: true, flagPlan: true, flagDog:false},
//         {name: 'Спочатку найвища очікувана вартість', status: false, findParamName: 'value.amount', findSortStatus: 'ASC', flagTend: true, flagPlan: true, flagDog: false},
//         {name: 'Спочатку найближчі аукціони', status: false, findParamName: 'auctionPeriod.startDate', findSortStatus: 'DESC', flagTend: true, flagPlan: false, flagDog: false},
//         {name: 'Спочатку найпізніші аукціони', status: false, findParamName: 'auctionPeriod.startDate', findSortStatus: 'ASC', flagTend: true, flagPlan: false, flagDog: false},
//         {name: 'Спочатку найнижча сума договору', status: false, findParamName: 'value.amount', findSortStatus: 'DESC', flagTend: false, flagPlan: false, flagDog: true},
//         {name: 'Спочатку найвища сума договору', status: false, findParamName: 'value.amount', findSortStatus: 'ASC', flagTend: false, flagPlan: false, flagDog: true},
//         ])*/


//     let data = {}
//     data.path = setCurrentUrl(props.search)
//     data.domen = process.env.REACT_APP_END_POINT_BD
//     data.version = 'v1'
//     data.local = props.langFlag
//     data.params = `?page=1`
//     let header = {
//         'Content-Type': 'application/json'
//     }
//     window.scrollTo(0, 370)
//     props.auctionsGetSearch(data, header)
// }

const mapStateToProps = state => {
    return {
        search: state.start.search,
    }
}

const mapDispatchToProps = {
    setCurrentUrl,
    auctionsGetSearch,

    setSearchTextSting,
    changeMyCurentPage,
    // openVidjetClassificationAuctionString,
    // openVidjetClassificationAuctionArrGet,
    // openVidjetClassificationAuctionArr,
    // openVidjetClassificationAuctionArrNew,
    // openVidjetClassificationAuction,
    // setArhStatusAuction,
    // setArhStatusAuctionNew,
    // openVidjetStatusAuction,
    // openVidjetOrganizatorAuctionString,
    // openVidjetOrganizatorAuctionArrGet,
    // openVidjetOrganizatorAuctionArr,
    // openVidjetOrganizatorAuctionArrNew,
    // openVidjetOrganizatorAuction,
    // openVidjetBuyersAuctionString,
    // openVidjetBuyersAuctionArrGet,
    // openVidjetBuyersAuctionArr,
    // openVidjetBuyersAuctionArrNew,
    // openVidjetBuyersAuction,
    // openVidjetSimpleFilterAuctionRegionOpen,
    // openVidjetSimpleFilterAuctionRegionProc,
    // openVidjetSimpleFilterAuctionRegionProcNew,
    // openVidjetProcurementMethodType,
    // openVidjetProcurementMethodTypeProc,
    // openVidjetProcurementMethodTypeProcNew,
    // openVidjetSimpleFilterAuctionTypeSubjectProcurement,
    // openVidjetSimpleFilterAuctionTypeSubjectProcurementNew,
    // openVidjetTypeSubjectProcurement,
    // openVidjetSimpleFilterAuction,
    // openVidjetSimpleFilterAuctionTypeSubjectProcurement,
    // openVidjetSimpleFilterAuctionTypeSubjectProcurementNew,
    // openVidjetSimpleFilterAuctionTypePayment,
    // openVidjetSimpleFilterAuctionTypePaymentNew,
    // openVidjetfilterValueAmountFrom,
    // openVidjetfilterValueAmountFromNew,
    // openVidjetfilterValueAmountTo,
    // openVidjetfilterValueAmountToNew,
    // openVidjetfilterValueAmountOpen,
    // openVidjetfilterEnquiryPeriodFrom,
    // openVidjetfilterEnquiryPeriodFromNew,
    // openVidjetfilterEnquiryPeriodTo,
    // openVidjetfilterEnquiryPeriodToNew,
    // openVidjetfilterEnquiryPeriodOpen,
    // openVidjetfilterAuctionPeriodFrom,
    // openVidjetfilterAuctionPeriodFromNew,
    // openVidjetfilterAuctionPeriodTo,
    // openVidjetfilterAuctionPeriodToNew,
    // openVidjetfilterAuctionPeriodOpen,
    // openVidjetfilterTenderPeriodFrom,
    // openVidjetfilterTenderPeriodFromNew,
    // openVidjetfilterTenderPeriodTo,
    // openVidjetfilterTenderPeriodToNew,
    // openVidjetfilterTenderPeriodOpen,
    // openVidjetfilterPlansTenderPeriodFrom,
    // openVidjetfilterPlansTenderPeriodFromNew,
    // openVidjetfilterPlansTenderPeriodTo,
    // openVidjetfilterPlansTenderPeriodToNew,
    // openVidjetfilterPlansTenderPeriodOpen,
    // openVidjetfilterPlansTenderPeriodFrom,
    // openVidjetfilterPlansTenderPeriodFromNew,
    // openVidjetfilterPlansTenderPeriodTo,
    // openVidjetfilterPlansTenderPeriodToNew,
    // openVidjetfilterPlansTenderPeriodOpen,
    // openVidjetDjereloFinArr,
    // openVidjetDjereloFinArrNew,
    // openVidjetDjereloFin,
    // openVidjetRozporyadnukKoshtivString,
    // openVidjetRozporyadnukKoshtivArrGet,
    // openVidjetRozporyadnukKoshtivArr,
    // openVidjetRozporyadnukKoshtivArrNew,
    // openVidjetRozporyadnukKoshtiv,
    // openVidjetfilterSummFrom,
    // openVidjetfilterSummFromNew,
    // openVidjetfilterSummTo,
    // openVidjetfilterSummToNew,
    // openVidjetfilterSummOpen,
    sortListOpen,
    sortListArray,

    changeArraySortList,
    setArraySortList,
}

export default connect(mapStateToProps, mapDispatchToProps)(SetupFilter)