import React from 'react';
import { isMongo } from '../../../utils/check';
import styles from './halfInfoBlock.module.css';

const HalfInfoBlock =( { title, data, width = '50', moreData} )=> {

    return (
                <div className={styles.blockHalf} style={{width: `${width}%`}}>
                    <div className={styles.description}>{title}</div>
                    <div className={styles.data}>{ isMongo(data) }</div>
                    { !!moreData ? <div className={styles.data}>{moreData}</div> : null }
                </div>
    )
}

export default HalfInfoBlock