import {
    LEASE_REQESTS_GET,
    LEASE_ACTIONS_GET,
    LEASE_ACTION_GET_ID,
    LEASE_REQESTS_COUNT_GET,
    REG_ACTIONS_COUNT_GET,
    AUCTIONS_GET,
    AUCTIONS_GET_FLAG,
    AUCTIONS_GET_ID,
    REG_PAGINATOR_COUNT_GET,
    REG_PAGINATOR_PER_PAGE,
    OBJECT_CONTRACTS_GET,

    //search
    SET_SEARCH_FILTERS_COUNT,
    // SET_SEARCH_OBJECT_TYPE,
    // SET_SEARCH_ACTION_TYPE,
    SET_DATE_FROM,
    SET_DATE_TILL,

    SET_SEARCH_ARH_STATUS,
    SET_SEARCH_NEW_ARH_STATUS,
    SET_SEARCH_VIDJET_STATUS,
    SET_SEARCH_VIDJET_ORGANIZATOR_STRING,
    SET_SEARCH_VIDJET_ORGANIZATOR_ARR_GET,
    SET_SEARCH_VIDJET_ORGANIZATOR_ARR,
    SET_SEARCH_VIDJET_ORGANIZATOR_ARR_NEW,
    SET_SEARCH_VIDJET_ORGANIZATOR,
    SET_SEARCH_VIDJET_BUYERS_STRING,
    SET_SEARCH_VIDJET_BUYERS_ARR_GET,
    SET_SEARCH_VIDJET_BUYERS_ARR,
    SET_SEARCH_VIDJET_BUYERS_ARR_NEW,
    SET_SEARCH_VIDJET_BUYERS,
    SET_SEARCH_VIDJET_CLASSIFICATION_STRING,
    SET_SEARCH_VIDJET_CLASSIFICATION_ARR_GET,
    SET_SEARCH_VIDJET_CLASSIFICATION_ARR,
    SET_SEARCH_VIDJET_CLASSIFICATION_ARR_NEW,
    SET_SEARCH_VIDJET_CLASSIFICATION,
    SET_GMDN_CLASSIFICATION_ARR_GET,
    SET_GMDN_CLASSIFICATION_ARR,
    SET_GMDN_CLASSIFICATION_ARR_NEW,
    SET_DK_CLASSIFICATION_ARR_GET,
    SET_DK_CLASSIFICATION_ARR,
    SET_DK_CLASSIFICATION_ARR_NEW,
    SET_SEARCH_VIDJET_SIMPLE_FILTER,
    SET_SEARCH_VIDJET_SIMPLE_FILTER_REGION_OPEN,
    SET_SEARCH_VIDJET_SIMPLE_FILTER_REGION_PROC,
    SET_SEARCH_VIDJET_SIMPLE_FILTER_REGION_NEW_PROC,
    SET_SEARCH_VIDJET_SIMPLE_FILTER_TYPE_SUBJECT_PROCUREMENT,
    SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_TYPE_SUBJECT_PROCUREMENT,
    SET_SEARCH_VIDJET_TYPE_SUBJECT_PROCUREMENT_OPEN,
    SET_SEARCH_VIDJET_SIMPLE_FILTER_TYPE_PAYMENT,
    SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_TYPE_PAYMENT,
    SET_SEARCH_VIDJET_TYPE_PAYMENT_OPEN,
    SET_SEARCH_VIDJET_SIMPLE_FILTER_PROCUREMENT_METHOD_TYPE_OPEN,
    SET_SEARCH_VIDJET_SIMPLE_FILTER_PROCUREMENT_METHOD_TYPE_PROC,
    SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_PROCUREMENT_METHOD_TYPE_PROC,
    SET_SEARCH_VIDJET_SIMPLE_FILTER_VALUE_AMOUNT_FROM,
    SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_VALUE_AMOUNT_FROM,
    SET_SEARCH_VIDJET_SIMPLE_FILTER_VALUE_AMOUNT_TO,
    SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_VALUE_AMOUNT_TO,
    SET_SEARCH_VIDJET_SIMPLE_FILTER_VALUE_AMOUNT_OPEN,
    SET_SEARCH_VIDJET_SIMPLE_ENQUIRY_PERIOD_FROM,
    SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_ENQUIRY_PERIOD_FROM,
    SET_SEARCH_VIDJET_SIMPLE_FILTER_ENQUIRY_PERIOD_TO,
    SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_ENQUIRY_PERIOD_TO,
    SET_SEARCH_VIDJET_SIMPLE_FILTER_ENQUIRY_PERIOD_OPEN,
    SET_SEARCH_VIDJET_SIMPLE_AUCTION_PERIOD_FROM,
    SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_AUCTION_PERIOD_FROM,
    SET_SEARCH_VIDJET_SIMPLE_FILTER_AUCTION_PERIOD_TO,
    SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_AUCTION_PERIOD_TO,
    SET_SEARCH_VIDJET_SIMPLE_FILTER_AUCTION_PERIOD_OPEN,
    SET_SEARCH_VIDJET_SIMPLE_TENDER_PERIOD_FROM,
    SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_TENDER_PERIOD_FROM,
    SET_SEARCH_VIDJET_SIMPLE_FILTER_TENDER_PERIOD_TO,
    SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_TENDER_PERIOD_TO,
    SET_SEARCH_VIDJET_SIMPLE_FILTER_TENDER_PERIOD_OPEN,
    SET_SEARCH_VIDJET_SIMPLE_FILTER_VALUE_SUMM_FROM,
    SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_VALUE_SUMM_FROM,
    SET_SEARCH_VIDJET_SIMPLE_FILTER_VALUE_SUMM_TO,
    SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_VALUE_SUMM_TO,
    SET_SEARCH_VIDJET_SIMPLE_FILTER_VALUE_SUMM_OPEN,
    SET_SEARCH_VIDJET_SIMPLE_PLANS_TENDER_PERIOD_FROM,
    SET_SEARCH_VIDJET_SIMPLE_PLANS_NEW_TENDER_PERIOD_FROM,
    SET_SEARCH_VIDJET_SIMPLE_PLANS_TENDER_PERIOD_TO,
    SET_SEARCH_VIDJET_SIMPLE_PLANS_NEW_TENDER_PERIOD_TO,
    SET_SEARCH_VIDJET_SIMPLE_PLANS_TENDER_PERIOD_OPEN,
    SET_SEARCH_VIDJET_DJERELO_FIN_ARR,
    SET_SEARCH_VIDJET_DJERELO_FIN_ARR_NEW,
    SET_SEARCH_VIDJET_DJERELO_FIN,
    SET_SEARCH_VIDJET_ROZPORYADNUK_KOSHTIV_STRING,
    SET_SEARCH_VIDJET_ROZPORYADNUK_KOSHTIV_ARR_GET,
    SET_SEARCH_VIDJET_ROZPORYADNUK_KOSHTIV_ARR,
    SET_SEARCH_VIDJET_ROZPORYADNUK_KOSHTIV_ARR_NEW,
    SET_SEARCH_VIDJET_ROZPORYADNUK_KOSHTIV,
    SET_SEARCH_SORT_LIST_OPEN,
    // SET_SEARCH_SORT_LIST_ARRAY,


    AUCTIONS_GET_ADD,
    
    SET_SEARCH_URL,
    //Auctions
    SET_DGF_MASK,
    AUCTIONS_POST,
    AUCTIONS_PUT,
    AUCTIONS_DELETE,
    AUCTIONS_SEARCH,
    AUCTIONS_SEARCH_OLD,
    AUCTIONS_SEARCH_MIN,
    AUCTIONS_SEARCH_COUNT,
    AUCTIONS_SEARCH_OLD_COUNT,
    AUCTIONS_GET_MY,
    AUCTIONS_SWITCH_WINDOW,
    AUCTIONS_ITEMS_SWITCH_WINDOW,
    AUCTION_MY_EDIT,
    AUCTIONS_CURENT_PAGE,
    AUCTIONS_STATUS,
    CHANGE_MEASURES,
    SET_PRICE_FROM,
    SET_PRICE_FROM_BAG,
    SET_PRICE_TO,
    SET_PRICE_TO_BAG,
    SET_SEARCH_TEXT_STRING,
    SET_SEARCH_TYPE_KEYWORD,
    SET_SEARCH_DATE_START,
    SET_SEARCH_DATE_END,
    SET_SEARCH_SORT,
    SET_CLASSIFICATIONS_BY_TYPE,
    SET_CLASSIFICATIONS_BY_TYPE_ENERGY,
    SET_CLASSIFICATIONS_BY_ENERGY_GENERATION_TYPE,
    SET_CLASSIFICATIONS_BY_TIMBER_SORTMENT,
    SET_CLASSIFICATIONS_BY_TIMBER_SPECIES,
    SET_CLASSIFICATIONS_BY_TIMBER_CLASS,
    SET_CLASSIFICATIONS_BY_TIMBER_DIAMETER,
    SET_CLASSIFICATIONS_BY_TIMBER_LENGTH,
    SET_CLASSIFICATIONS_BY_TIMBER_STORAGE,
    SET_CLASSIFICATIONS_BY_TIMBER_PRODUCTION_YEAR,
    SET_CLASSIFICATIONS_BY_TIMBER_PRODUCTION_QUARTER,
    CHANGE_CLASSIFICATIONS_BY_TIMBER_SORTMENT,
    CHANGE_CLASSIFICATIONS_BY_TIMBER_SPECIES,
    CHANGE_CLASSIFICATIONS_BY_TIMBER_CLASS,
    CHANGE_CLASSIFICATIONS_BY_TIMBER_DIAMETER,
    CHANGE_CLASSIFICATIONS_BY_TIMBER_LENGTH1,
    CHANGE_CLASSIFICATIONS_BY_TIMBER_LENGTH2,
    CHANGE_CLASSIFICATIONS_BY_TIMBER_STORAGE,
    CHANGE_CLASSIFICATIONS_BY_TIMBER_PRODUCTION_YEAR,
    CHANGE_CLASSIFICATIONS_BY_TIMBER_PRODUCTION_QUARTER,
    CHANGE_UNIT_CODE,
    CHANGE_CLASSIFICATIONS_BY_GENERATION_TYPE,
    SET_CLASSIFICATIONS_BY_TYPE_DGF_ADD,

    SET_POP_UP_SEND_AUCTION,
    SET_POP_UP_DEL_AUCTION,
    AUCTIONS_GET_MY_ID,

    SET_CLASSIFICATIONS_BY_TYPE_RAILWAY,
    SET_CLASSIFICATIONS_BY_WAGON_SPECIES,

    SET_CLASSIFICATIONS_BY_TYPE_NADRA_ENGLISH,
    SET_CLASSIFICATIONS_BY_TYPE_SUBSOIL_USAGE_TYPE,

    SET_POP_UP_ERROR_AUCTION,
    SET_DELETE_AUCTION_DESCRIPTION,
    SET_POP_UP_ANSWER_AUCTION,

    CHANGE_POP_UP_AUCTION_CREATE,
    SET_CLASSIFICATIONS_BY_CARRY_TYPE,
    SET_CLASSIFICATIONS_BY_TYPE_RAILWAY_CARGO_ADD,
    SET_CLASSIFICATIONS_BY_TYPE_RAILWAY_RESTRICT,
    SET_CLASSIFICATIONS_BY_TYPE_RAILWAY_RESTRICT_TYPE,

    SET_CREATE_RESTRICTION_FLAG,
	SET_CREATE_RESTRICTION_LOAD_OBJECT,
	SET_CREATE_RESTRICTION_LOAD_OBJECT_CODE,
	SET_CREATE_RESTRICTION_LOAD_OBJECT_NAME,
	SET_CREATE_RESTRICTION_UNLOAD_OBJECT,
	SET_CREATE_RESTRICTION_UNLOAD_OBJECT_CODE,
    SET_CREATE_RESTRICTION_UNLOAD_OBJECT_NAME,
    
    SET_CREATE_RESTRICTION_EXCLUSIONS_FLAF,
	SET_CREATE_RESTRICTION_EXCLUSIONS_OBJECT,
	SET_CREATE_RESTRICTION_EXCLUSIONS_OBJECT_TYPE,
	SET_CREATE_RESTRICTION_EXCLUSIONS_OBJECT_CODE,
    SET_CREATE_RESTRICTION_EXCLUSIONS_OBJECT_NAME,
    
    SET_TIMBER_SORTMENT,
    SET_TIMBER_SPECIES,
    SET_TIMBER_CLASS,
    SET_TIMBER_DIAMETER,
    SET_TIMBER_STORAGE,
    SET_TIMBER_PRODUCTION_YEAR,
    SET_TIMBER_PRODUCTION_QUARTER,
    SET_POPUP_BID_TOGETHER_API,
    SET_TIMBER_LENGTH1,
    SET_TIMBER_LENGTH2,
    AUCTIONS_SEARCH_ELEM_ARR,

    POPUP_AUCTION_DELETE_TITLE,
    POPUP_DATE_DELETE_AUCTION
} from "../../types";
import {getAPI, postAPI, putAPI, deleteAPI} from '../crud';
import {setLocal} from '../support';

//Auctions
export function auctionPost(_url, token, datas){
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/auction`,
        token: token,
        actionType: AUCTIONS_POST,
        data: datas,
        header: {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }
    return postAPI(data);
}

export function auctionGetId(_url, token){
    let header;
    if (token) {
        header = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        }
    } else {
        header = {
            'Content-Type': 'application/json'
        }
    }
    const data = {
        url: _url,
        actionType: AUCTIONS_GET_ID,
        header: header,
    }
    return getAPI(data);
}

export function leaseReqestsGet(_url){
    const data = {
        url: _url,
        actionType: LEASE_REQESTS_GET,
        header: {
            'Content-Type': 'application/json'
        }
    }
    return getAPI(data);
}

export function leaseReqestsGetCount(_url){
    const data = {
        url: _url,
        actionType: LEASE_REQESTS_COUNT_GET,
        header: {
            'Content-Type': 'application/json'
        }
    }
    return getAPI(data);
}

export function leaseActionsGet(_url){
    const data = {
        url: _url,
        actionType: LEASE_ACTIONS_GET,
        header: {
            'Content-Type': 'application/json'
        }
    }
    return getAPI(data);
}

export function leaseActionGetId(_url, token) {
    let header;
    if (token) {
        header = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        }
    } else {
        header = {
            'Content-Type': 'application/json'
        }
    }
    const data = {
        url: _url,
        actionType: LEASE_ACTION_GET_ID,
        header: header
    }
    return getAPI(data);
}

export function regActionsCountGet(_url) {
    const data = {
        url: _url,
        actionType: REG_ACTIONS_COUNT_GET,
        header: {
            'Content-Type': 'application/json'
        }
    }
    return getAPI(data);
}

export function regPaginatorCountGet(_url) {
    const data = {
        url: _url+'&onlyCount=true',
        actionType: REG_PAGINATOR_COUNT_GET,
        header: {
            'Content-Type': 'application/json'
        }
    }
    return getAPI(data);
}

export function changeRegPaginatorPerPage(value) {
    return {
        type: REG_PAGINATOR_PER_PAGE,
        payload: value
    }
}

export function objectContractsGet(_url) {
    const data = {
        url: _url,
        actionType: OBJECT_CONTRACTS_GET,
        header: {
            'Content-Type': 'application/json'
        }
    }
    return getAPI(data);
}

export function changeFilterCount(value) {
    return {
        type: SET_SEARCH_FILTERS_COUNT,
        payload: value
    }
}

export function setSearchTextSting(value) {
    return {
        type: SET_SEARCH_TEXT_STRING,
        payload: value
    }
}

export function setSearchSelectTypeKeyword(value) {
    return {
        type: SET_SEARCH_TYPE_KEYWORD,
        payload: value
    }
}

export function changeSearchDateFrom(value) {
    return {
        type: SET_DATE_FROM,
        payload: value
    }
}

export function changeSearchDateTill(value) {
    return {
        type: SET_DATE_TILL,
        payload: value
    }
}


// -------------------- //

export function changeSearchDateStart(value) {
    return {
        type: SET_SEARCH_DATE_START,
        payload: value
    }
}

export function changeSearchDateEnd(value) {
    return {
        type: SET_SEARCH_DATE_END,
        payload: value
    }
}

// export function changeActionType(value) {
//     return {
//         type: SET_SEARCH_ACTION_TYPE,
//         payload: value
//     }
// }


/* ************* BARDAK ************** */

export function auctionGetIdToken(_url, token){
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/auction/${_url.id}`,
        actionType: AUCTIONS_GET_ID,
        header: {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }
    return getAPI(data);
}

export function auctionPut(_url, token, datas){
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/auction/${_url.id}`,
        token: token,
        actionType: AUCTIONS_PUT,
        data: datas,
        header: {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }
    return putAPI(data);
}

export function auctionDelete(_url, token){
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/auction/${_url.id}`,
        token: token,
        actionType: AUCTIONS_DELETE,
        header: {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }
    return deleteAPI(data);
}

export function auctionsGet(_url){
    const data = {
        url: _url.path,
        actionType: AUCTIONS_GET,
        header: {
            'Content-Type': 'application/json'
        }
    }
    return getAPI(data);
}

export function auctionsGetSearch(_url, header){
    const data = {
        url: _url.path + _url.params,
        actionType: AUCTIONS_GET,
        header: header
    }
    return getAPI(data);
}

export function auctionsGetSearchAdd(_url, header){
    const data = {
        url: _url.path + _url.params,
        actionType: AUCTIONS_GET_ADD,
        header: header
    }
    return getAPI(data);
}


export function auctionsSearch(_url, header, data){
    let data1 = {}
    if(data.keyword !== null) data1.keyword = data.keyword
    if(data.authorName !== null) data1.authorName = data.authorName
    if(data.classification !== null) data1.classification = data.classification
    if(data.auctionId !== null) data1.auctionId = data.auctionId
    if(data.category !== null){
        if(data.category === 'timber-english'){
            data1.sellingMethod = ['timber-english']
        }else if(data.category === 'subsoil-all'){
            data1.sellingMethod = ['subsoil-english', 'subsoil-dutch']
        }else if(data.category === 'subsoil-english'){
            data1.sellingMethod = ['subsoil-english']
        }else if(data.category === 'subsoil-dutch'){
            data1.sellingMethod = ['subsoil-dutch']
        }else if(data.category === 'railwayCargo-all'){
            data1.sellingMethod = ['railwayCargo-english', 'railwayCargo-dutch']
        }else if(data.category === 'railwayCargo-english'){
            data1.sellingMethod = ['railwayCargo-english']
        }else if(data.category === 'railwayCargo-dutch'){
            data1.sellingMethod = ['railwayCargo-dutch']
        }else if(data.category === 'dgf'){
            data1.sellingMethod = ['dgf-english', 'dgf-dutch']
        }else if(data.category === 'dgf-english'){
            data1.sellingMethod = ['dgf-english']
        }else if(data.category === 'dgf-dutch'){
            data1.sellingMethod = ['dgf-dutch']
        }
    }
    if(data.status !== null) data1.status = [data.status]
    if(data.startDateFrom !== null) data1.startDateFrom = data.startDateFrom
    if(data.startDateTo !== null) data1.startDateTo = data.startDateTo
    /*if(data.status !== null){
        data1 = JSON.stringify({
            keyword: data.keyword,
            authorName: data.authorName,
            classification: data.classification,
            auctionId: data.auctionId,
            sellingMethod: data.category,
            status: data.status,
            startDateFrom: data.startDateFrom,
            startDateTo: data.startDateTo
        })
    }else{
        data1 = JSON.stringify({
            keyword: data.keyword,
            authorName: data.authorName,
            classification: data.classification,
            auctionId: data.auctionId,
            sellingMethod: data.category,
            startDateFrom: data.startDateFrom,
            startDateTo: data.startDateTo
        })
    }*/
    return async dispatch => {
            const response = await fetch(`${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/auctions/search${_url.params}`, {
                method: 'POST',
                body: JSON.stringify(data1),
                headers: header
            });

        const json = await response.json()
        dispatch({type: AUCTIONS_SEARCH, payload: json})
    }
}


/////////////////////////////////////////////////////////////////////////////////

export function auctionsSearchTogetherAPI(_url, header, data){
    let data1 = {}
    if(data.keyword !== null) data1.keyword = data.keyword
    if(data.authorName !== null) data1.authorName = data.authorName
    if(data.classification !== null) data1.classification = data.classification
    if(data.auctionId !== null) data1.auctionId = data.auctionId
    if(data.category !== null){
        if(data.category === 'timber-english'){
            data1.sellingMethod = ['timber-english']
        }else if(data.category === 'subsoil-english'){
            data1.sellingMethod = ['subsoil-english', 'subsoil-dutch']
        }else if(data.category === 'railwayCargo-english'){
            data1.sellingMethod = ['railwayCargo-english', 'railwayCargo-dutch']
        }
    }
    if(data.startDateFrom !== null) data1.startDateFrom = data.startDateFrom
    if(data.startDateTo !== null) data1.startDateTo = data.startDateTo
    if(data.additionalClassifications !== null) data1.additionalClassifications = data.additionalClassifications
    if(data.lengthFrom !== null) data1.lengthFrom = data.lengthFrom
    if(data.lengthTo !== null) data1.lengthTo = data.lengthTo
    data1.status = ['active_tendering']

    /*keyword: data.keyword,
    authorName: data.authorName,
    classification: data.classification,
    auctionId: data.auctionId,
    sellingMethod: data.category,
    startDateFrom: data.startDateFrom,
    startDateTo: data.startDateTo,
    additionalClassifications: data.additionalClassifications,
    lengthFrom: data.lengthFrom,
    lengthTo: data.lengthTo,
    status: ['active_tendering'],*/

    return async dispatch => {
            const response = await fetch(`${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/auctions/search${_url.params}`, {
                method: 'POST',
                body: JSON.stringify(data1),
                headers: header
            });

        const json = await response.json()
        dispatch({type: AUCTIONS_SEARCH, payload: json})
    }
}

export function auctionsSearchOld(_url, header, data){
    let data1 = {}
    if(data.order !== null) data1.order = data.order
    if(data.orderBy !== null) data1.orderBy = data.orderBy
    if(data.status !== null) data1.status = [data.status]
    if(data.region !== null) data1.region = data.region
    if(data.category !== null){
        if(data.category === 'timber-english'){
            data1.sellingMethod = ['timber-english']
        }else if(data.category === 'subsoil-english'){
            data1.sellingMethod = ['subsoil-english', 'subsoil-dutch']
        }else if(data.category === 'railwayCargo-english'){
            data1.sellingMethod = ['railwayCargo-english', 'railwayCargo-dutch']
        }
    }
    if(data.priceCurrency !== null) data1.priceCurrency = data.priceCurrency
    if(data.priceFrom !== null) data1.priceFrom = data.priceFrom
    if(data.priceTo !== null) data1.priceTo = data.priceTo
    if(data.measures !== null) data1.measures = data.measures
    if(data.measuresFrom !== null) data1.measuresFrom = data.measuresFrom
    if(data.measuresTo !== null) data1.measuresTo = data.measuresTo

    /*if(data.status !== null){
        data1 = JSON.stringify({
            order: data.order,
            orderBy: data.orderBy,
            status: data.status,
            region: data.region,
            sellingMethod: data.category,
            priceCurrency: data.priceCurrency,
            priceFrom: data.priceFrom,
            priceTo: data.priceTo,
            measures: data.measures,
            measuresFrom: data.measuresFrom,
            measuresTo: data.measuresTo
        })
    }else{
        data1 = JSON.stringify({
            order: data.order,
            orderBy: data.orderBy,
            region: data.region,
            sellingMethod: data.category,
            priceCurrency: data.priceCurrency,
            priceFrom: data.priceFrom,
            priceTo: data.priceTo,
            measures: data.measures,
            measuresFrom: data.measuresFrom,
            measuresTo: data.measuresTo
        })
    }*/
    return async dispatch => {
            const response = await fetch(`${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/auctions/search/old${_url.params}`, {
                method: 'POST',
                body: JSON.stringify(data1),
                headers: header
            });

        const json = await response.json()
        dispatch({type: AUCTIONS_SEARCH_OLD, payload: json})
    }
}

export function auctionsSearchMin(value){
    return {
        type: AUCTIONS_SEARCH_MIN,
        payload: value
    }
}

export function auctionsSearchСount(_url, header, data){
    let data1 = {}
    if(data.order !== null) data1.order = data.order
    if(data.orderBy !== null) data1.orderBy = data.orderBy
    if(data.status !== null) data1.status = [data.status]
    if(data.region !== null) data1.region = data.region
    if(data.category !== null){
        if(data.category === 'timber-english'){
            data1.sellingMethod = ['timber-english']
        }else if(data.category === 'subsoil-english'){
            data1.sellingMethod = ['subsoil-english', 'subsoil-dutch']
        }else if(data.category === 'railwayCargo-english'){
            data1.sellingMethod = ['railwayCargo-english', 'railwayCargo-dutch']
        }
    }
    if(data.priceCurrency !== null) data1.priceCurrency = data.priceCurrency
    if(data.priceFrom !== null) data1.priceFrom = data.priceFrom
    if(data.priceTo !== null) data1.priceTo = data.priceTo
    if(data.measures !== null) data1.measures = data.measures
    if(data.measuresFrom !== null) data1.measuresFrom = data.measuresFrom
    if(data.measuresTo !== null) data1.measuresTo = data.measuresTo

    /*if(data.status !== null){
        data1 = JSON.stringify({
            order: data.order,
            orderBy: data.orderBy,
            status: data.status,
            region: data.region,
            sellingMethod: data.category,
            priceCurrency: data.priceCurrency,
            priceFrom: data.priceFrom,
            priceTo: data.priceTo,
            measures: data.measures,
            measuresFrom: data.measuresFrom,
            measuresTo: data.measuresTo
        })
    }else{
        data1 = JSON.stringify({
            order: data.order,
            orderBy: data.orderBy,
            region: data.region,
            sellingMethod: data.category,
            priceCurrency: data.priceCurrency,
            priceFrom: data.priceFrom,
            priceTo: data.priceTo,
            measures: data.measures,
            measuresFrom: data.measuresFrom,
            measuresTo: data.measuresTo
        })
    }*/
    return async dispatch => {
            const response = await fetch(`${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/auctions/search?limit=10&offset=0&onlyCount=1`, {
                method: 'POST',
                body: JSON.stringify(data1),
                headers: header
            });

        const json = await response.json()
        dispatch({type: AUCTIONS_SEARCH_COUNT, payload: json})
    }
}

export function auctionsSearchСountOld(_url, header, data){
    let data1 = {}
    if(data.order !== null) data1.order = data.order
    if(data.orderBy !== null) data1.orderBy = data.orderBy
    if(data.status !== null) data1.status = [data.status]
    if(data.region !== null) data1.region = data.region
    if(data.category !== null){
        if(data.category === 'timber-english'){
            data1.sellingMethod = ['timber-english']
        }else if(data.category === 'subsoil-english'){
            data1.sellingMethod = ['subsoil-english', 'subsoil-dutch']
        }else if(data.category === 'railwayCargo-english'){
            data1.sellingMethod = ['railwayCargo-english', 'railwayCargo-dutch']
        }
    }
    if(data.priceCurrency !== null) data1.priceCurrency = data.priceCurrency
    if(data.priceFrom !== null) data1.priceFrom = data.priceFrom
    if(data.priceTo !== null) data1.priceTo = data.priceTo
    if(data.measures !== null) data1.measures = data.measures
    if(data.measuresFrom !== null) data1.measuresFrom = data.measuresFrom
    if(data.measuresTo !== null) data1.measuresTo = data.measuresTo
    /*if(data.status !== null){
        data1 = JSON.stringify({
            order: data.order,
            orderBy: data.orderBy,
            status: data.status,
            region: data.region,
            sellingMethod: data.category,
            priceCurrency: data.priceCurrency,
            priceFrom: data.priceFrom,
            priceTo: data.priceTo,
            measures: data.measures,
            measuresFrom: data.measuresFrom,
            measuresTo: data.measuresTo
        })
    }else{
        data1 = JSON.stringify({
            order: data.order,
            orderBy: data.orderBy,
            region: data.region,
            sellingMethod: data.category,
            priceCurrency: data.priceCurrency,
            priceFrom: data.priceFrom,
            priceTo: data.priceTo,
            measures: data.measures,
            measuresFrom: data.measuresFrom,
            measuresTo: data.measuresTo
        })
    }*/
    return async dispatch => {
            const response = await fetch(`${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/auctions/search/old?limit=10&offset=0&onlyCount=1`, {
                method: 'POST',
                body: JSON.stringify(data1),
                headers: header
            });

        const json = await response.json()
        dispatch({type: AUCTIONS_SEARCH_OLD_COUNT, payload: json})
    }
}

export function changeLotsFlag(value) {
    return {
        type: AUCTIONS_GET_FLAG,
        payload: value
    }
}

export function changeAuctionsSwitchWindow(value) {
    return {
        type: AUCTIONS_SWITCH_WINDOW,
        payload: value
    }
}

export function changeAuctionsItemsSwitchWindow(value) {
    return {
        type: AUCTIONS_ITEMS_SWITCH_WINDOW,
        payload: value
    }
}

export function changeCurentPage(value) {
    return {
        type: AUCTIONS_CURENT_PAGE,
        payload: value
    }
}

export function changeStatusAuction(value) {
    return {
        type: AUCTIONS_STATUS,
        payload: value
    }
}

export function changeEdVum(value) {
    return {
        type: CHANGE_MEASURES,
        payload: value
    }
}

export function praiceFrom(value) {
    return {
        type: SET_PRICE_FROM,
        payload: value
    }
}

export function praiceFromBag(value) {
    return {
        type: SET_PRICE_FROM_BAG,
        payload: value
    }
}

export function praiceTo(value) {
    return {
        type: SET_PRICE_TO,
        payload: value
    }
}

export function praiceToBag(value) {
    return {
        type: SET_PRICE_TO_BAG,
        payload: value
    }
}



export function setSearchSort(value) {
    return {
        type: SET_SEARCH_SORT,
        payload: value
    }
}

export function auctionsGetClassificationsByType(_url, header) {
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/classifications/byType/timber-english?limit=100&offset=0`,
        actionType: SET_CLASSIFICATIONS_BY_TYPE,
        header: header
    }
    return getAPI(data);
}
export function auctionsGetClassificationsByTypeEnergy(_url, header) {
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/classifications/byType/renewables?limit=100&offset=0`,
        actionType: SET_CLASSIFICATIONS_BY_TYPE_ENERGY,
        header: header
    }
    return getAPI(data);
}

export function auctionsGetClassificationsByGenerationType(_url, header) {
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/classifications/byScheme/generationType?limit=100&offset=0`,
        actionType: SET_CLASSIFICATIONS_BY_ENERGY_GENERATION_TYPE,
        header: header
    }
    return getAPI(data);
}

export function auctionsGetClassificationsByTimberSortment(_url, header) {
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/classifications/byScheme/timberSortment?limit=100&offset=0`,
        actionType: SET_CLASSIFICATIONS_BY_TIMBER_SORTMENT,
        header: header
    }
    return getAPI(data);
}
export function auctionsGetClassificationsByTimberSortmentFromCashe(value) {
    return {
        type: SET_CLASSIFICATIONS_BY_TIMBER_SORTMENT,
        payload: value
    }
}

export function auctionsGetClassificationsByTimberSpecies(_url, header) {
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/classifications/byScheme/timberSpecies?limit=100&offset=0`,
        actionType: SET_CLASSIFICATIONS_BY_TIMBER_SPECIES,
        header: header
    }
    return getAPI(data);
}
export function auctionsGetClassificationsByTimberSpeciesFromCashe(value) {
    return {
        type: SET_CLASSIFICATIONS_BY_TIMBER_SPECIES,
        payload: value
    }
}

export function auctionsGetClassificationsByTimberClass(_url, header) {
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/classifications/byScheme/timberClass?limit=100&offset=0`,
        actionType: SET_CLASSIFICATIONS_BY_TIMBER_CLASS,
        header: header
    }
    return getAPI(data);
}
export function auctionsGetClassificationsByTimberClassFromCashe(value) {
    return {
        type: SET_CLASSIFICATIONS_BY_TIMBER_CLASS,
        payload: value
    }
}

export function auctionsGetClassificationsByTimberDiameter(_url, header) {
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/classifications/byScheme/timberDiameter?limit=100&offset=0`,
        actionType: SET_CLASSIFICATIONS_BY_TIMBER_DIAMETER,
        header: header
    }
    return getAPI(data);
}
export function auctionsGetClassificationsByTimberDiameterFromCashe(value) {
    return {
        type: SET_CLASSIFICATIONS_BY_TIMBER_DIAMETER,
        payload: value
    }
}

export function auctionsGetClassificationsByTimberLength(_url, header) {
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/classifications/byScheme/timberLength?limit=100&offset=0`,
        actionType: SET_CLASSIFICATIONS_BY_TIMBER_LENGTH,
        header: header
    }
    return getAPI(data);
}
export function auctionsGetClassificationsByTimberLengthFromCashe(value) {
    return {
        type: SET_CLASSIFICATIONS_BY_TIMBER_LENGTH,
        payload: value
    }
}

export function auctionsGetClassificationsByTimberStorage(_url, header) {
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/classifications/byScheme/timberStorage?limit=100&offset=0`,
        actionType: SET_CLASSIFICATIONS_BY_TIMBER_STORAGE,
        header: header
    }
    return getAPI(data);
}
export function auctionsGetClassificationsByTimberStorageFromCashe(value) {
    return {
        type: SET_CLASSIFICATIONS_BY_TIMBER_STORAGE,
        payload: value
    }
}

export function auctionsGetClassificationsByTimberProductionYear(_url, header) {
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/classifications/byScheme/timberProductionYear?limit=100&offset=0&orderBy=classificationId`,
        actionType: SET_CLASSIFICATIONS_BY_TIMBER_PRODUCTION_YEAR,
        header: header
    }
    return getAPI(data);
}
export function auctionsGetClassificationsByTimberProductionYearFromCashe(value) {
    return {
        type: SET_CLASSIFICATIONS_BY_TIMBER_PRODUCTION_YEAR,
        payload: value
    }
}

export function auctionsGetClassificationsByTimberProductionQuarter(_url, header) {
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/classifications/byScheme/timberProductionQuarter?limit=100&offset=0&orderBy=classificationId`,
        actionType: SET_CLASSIFICATIONS_BY_TIMBER_PRODUCTION_QUARTER,
        header: header
    }
    return getAPI(data);
}
export function auctionsGetClassificationsByTimberProductionQuarterFromCashe(value) {
    return {
        type: SET_CLASSIFICATIONS_BY_TIMBER_PRODUCTION_QUARTER,
        payload: value
    }
}

export function changeTimberSortment(value) {
    return {
        type: CHANGE_CLASSIFICATIONS_BY_TIMBER_SORTMENT,
        payload: value
    }
}

export function changeTimberSpecies(value) {
    return {
        type: CHANGE_CLASSIFICATIONS_BY_TIMBER_SPECIES,
        payload: value
    }
}

export function changeTimberClass(value) {
    return {
        type: CHANGE_CLASSIFICATIONS_BY_TIMBER_CLASS,
        payload: value
    }
}

export function changeTimberDiameter(value) {
    return {
        type: CHANGE_CLASSIFICATIONS_BY_TIMBER_DIAMETER,
        payload: value
    }
}

export function changeTimberLength(value, type) {
    if(type === 1){
        return {
            type: CHANGE_CLASSIFICATIONS_BY_TIMBER_LENGTH1,
            payload: value
        }
    }else{
        return {
            type: CHANGE_CLASSIFICATIONS_BY_TIMBER_LENGTH2,
            payload: value
        }
    }
}

export function changeTimberStorage(value) {
    return {
        type: CHANGE_CLASSIFICATIONS_BY_TIMBER_STORAGE,
        payload: value
    }
}

export function changeTimberProductionYear(value) {
    return {
        type: CHANGE_CLASSIFICATIONS_BY_TIMBER_PRODUCTION_YEAR,
        payload: value
    }
}

export function changeTimberProductionQuarter(value) {
    return {
        type: CHANGE_CLASSIFICATIONS_BY_TIMBER_PRODUCTION_QUARTER,
        payload: value
    }
}

export function changeCreateItemUnitCode(value) {
    return {
        type: CHANGE_UNIT_CODE,
        payload: value
    }
}

export function changeCreateItemGenerationType(value) {
    return {
        type: CHANGE_CLASSIFICATIONS_BY_GENERATION_TYPE,
        payload: value
    }
}

export function setPopUpSendAuction(value) {
    return {
        type: SET_POP_UP_SEND_AUCTION,
        payload: value
    }
}

export function setPopUpDelAuction(value) {
    return {
        type: SET_POP_UP_DEL_AUCTION,
        payload: value
    }
}

export function auctionsGetMyId(_url, header, id) {
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/auction/${id}`,
        actionType: AUCTIONS_GET_MY_ID,
        header: header
    }
    return getAPI(data);
}

export function auctionsGetClassificationsByTypeRailway(_url, header) {
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/classifications/byType/railwayCargo-english?limit=100&offset=0`,
        actionType: SET_CLASSIFICATIONS_BY_TYPE_RAILWAY,
        header: header
    }
    return getAPI(data);
}

export function auctionsGetClassificationsByWagonSpecies(_url, header) {
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/classifications/byScheme/wagonSpecies?limit=100&offset=0`,
        actionType: SET_CLASSIFICATIONS_BY_WAGON_SPECIES,
        header: header
    }
    return getAPI(data);
}
export function auctionsGetClassificationsByWagonSpeciesFromCashe(value) {
    return {
        type: SET_CLASSIFICATIONS_BY_WAGON_SPECIES,
        payload: value
    }
}

export function auctionsGetClassificationsByTypeNadraEnglish(_url, header) {
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/classifications/byType/subsoil-english?limit=100&offset=0`,
        actionType: SET_CLASSIFICATIONS_BY_TYPE_NADRA_ENGLISH,
        header: header
    }
    return getAPI(data);
}
export function auctionsGetClassificationsByTypeNadraEnglishFromCashe(value) {
    return {
        type: SET_CLASSIFICATIONS_BY_TYPE_NADRA_ENGLISH,
        payload: value
    }
}

export function auctionsGetClassificationsByByShemaSubsoilUsageType(_url, header) {
    const data = {
        //url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/classifications/byScheme/subsoil-english?limit=100&offset=0`,
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/classifications/byScheme/subsoilUsageType?limit=100&offset=0`,
        actionType: SET_CLASSIFICATIONS_BY_TYPE_SUBSOIL_USAGE_TYPE,
        header: header
    }
    return getAPI(data);
}

export function setPopUpErrorAuction(value) {
    return {
        type: SET_POP_UP_ERROR_AUCTION,
        payload: value
    }
}

export function setDeleteAuctionDescription(value) {
    return {
        type: SET_DELETE_AUCTION_DESCRIPTION,
        payload: value
    }
}

export function setPopUpAnswerAuction(value) {
    return {
        type: SET_POP_UP_ANSWER_AUCTION,
        payload: value
    }
}

export function setPopUpAuctionCreate(value) {
    return {
        type: CHANGE_POP_UP_AUCTION_CREATE,
        payload: value
    }
}

export function setPopUpAuctionMyNull() {
    return {
        type: AUCTIONS_GET_MY,
        payload: null
    }
}

export function auctionsGetClassificationsByCarryType(_url, header) {
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/classifications/byScheme/carryType?limit=100&offset=0`,
        actionType: SET_CLASSIFICATIONS_BY_CARRY_TYPE,
        header: header
    }
    return getAPI(data);
}

export function auctionsGetClassificationsByTypeRailwayCargoAdd(_url, header) {
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/classifications/byType/railwayCargoAdditional?limit=100&offset=0`,
        actionType: SET_CLASSIFICATIONS_BY_TYPE_RAILWAY_CARGO_ADD,
        header: header
    }
    return getAPI(data);
}

export function auctionsGetWagonRestrict(_url, header) {
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/classifications/byScheme/objectLoadUnloadOrExclusionRange?limit=100&offset=0`,
        actionType: SET_CLASSIFICATIONS_BY_TYPE_RAILWAY_RESTRICT,
        header: header
    }
    return getAPI(data);
}

export function auctionsGetWagonRestrictType(_url, header) {
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/classifications/byScheme/railwayCargoExclusionObjectType?limit=100&offset=0`,
        actionType: SET_CLASSIFICATIONS_BY_TYPE_RAILWAY_RESTRICT_TYPE,
        header: header
    }
    return getAPI(data);
}



/////////////////////////////////
export function setCreateRestrictionFlag(value) {
    return {
        type: SET_CREATE_RESTRICTION_FLAG,
        payload: value
    }
}

export function setCreateLoadObject(value) {
    return {
        type: SET_CREATE_RESTRICTION_LOAD_OBJECT,
        payload: value
    }
}

export function setCreateLoadObjectCode(value) {
    return {
        type: SET_CREATE_RESTRICTION_LOAD_OBJECT_CODE,
        payload: value
    }
}

export function setCreateLoadObjectName(value) {
    return {
        type: SET_CREATE_RESTRICTION_LOAD_OBJECT_NAME,
        payload: value
    }
}

export function setCreateUnloadObject(value) {
    return {
        type: SET_CREATE_RESTRICTION_UNLOAD_OBJECT,
        payload: value
    }
}

export function setCreateUnloadObjectCode(value) {
    return {
        type: SET_CREATE_RESTRICTION_UNLOAD_OBJECT_CODE,
        payload: value
    }
}

export function setCreateUnloadObjectName(value) {
    return {
        type: SET_CREATE_RESTRICTION_UNLOAD_OBJECT_NAME,
        payload: value
    }
}

export function setCreateRestrictionExclusionsFlag(value) {
    return {
        type: SET_CREATE_RESTRICTION_EXCLUSIONS_FLAF,
        payload: value
    }
}

export function setCreateRestrictionExclusionsObject(value) {
    return {
        type: SET_CREATE_RESTRICTION_EXCLUSIONS_OBJECT,
        payload: value
    }
}

export function setCreateRestrictionExclusionsObjectType(value) {
    return {
        type: SET_CREATE_RESTRICTION_EXCLUSIONS_OBJECT_TYPE,
        payload: value
    }
}

export function setCreateRestrictionExclusionsObjectCode(value) {
    return {
        type: SET_CREATE_RESTRICTION_EXCLUSIONS_OBJECT_CODE,
        payload: value
    }
}

export function setCreateRestrictionExclusionsObjectName(value) {
    return {
        type: SET_CREATE_RESTRICTION_EXCLUSIONS_OBJECT_NAME,
        payload: value
    }
}

/////////////////////////////////////////////////
export function setTimberSortment(value) {
    return {
        type: SET_TIMBER_SORTMENT,
        payload: value
    }
}

export function setTimberSpecies(value) {
    return {
        type: SET_TIMBER_SPECIES,
        payload: value
    }
}

export function setTimberClass(value) {
    return {
        type: SET_TIMBER_CLASS,
        payload: value
    }
}

export function setTimberDiameter(value) {
    return {
        type: SET_TIMBER_DIAMETER,
        payload: value
    }
}

export function setTimberStorage(value) {
    return {
        type: SET_TIMBER_STORAGE,
        payload: value
    }
}

export function setTimberProductionYear(value) {
    return {
        type: SET_TIMBER_PRODUCTION_YEAR,
        payload: value
    }
}

export function setTimberProductionQuarter(value) {
    return {
        type: SET_TIMBER_PRODUCTION_QUARTER,
        payload: value
    }
}

export function setTimberLength1(value) {
    return {
        type: SET_TIMBER_LENGTH1,
        payload: value
    }
}

export function setTimberLength2(value) {
    return {
        type: SET_TIMBER_LENGTH2,
        payload: value
    }
}

export function setPopUpBidTogetherAPI(value) {
    return {
        type: SET_POPUP_BID_TOGETHER_API,
        payload: value
    }
}

export function auctionsSearchTest(_url, header, data){
    let data1 = {}
    if(data.keyword !== null) data1.keyword = data.keyword
    if(data.authorName !== null) data1.authorName = data.authorName
    if(data.classification !== null) data1.classification = data.classification
    if(data.auctionId !== null) data1.auctionId = data.auctionId
    if(data.category !== null){
        if(data.category === 'timber-english'){
            data1.sellingMethod = ['timber-english']
        }else if(data.category === 'subsoil-english'){
            data1.sellingMethod = ['subsoil-english', 'subsoil-dutch']
        }else if(data.category === 'railwayCargo-english'){
            data1.sellingMethod = ['railwayCargo-english', 'railwayCargo-dutch']
        }
    }
    if(data.status !== null) data1.status = [data.status]
    if(data.startDateFrom !== null) data1.startDateFrom = data.startDateFrom
    if(data.startDateTo !== null) data1.startDateTo = data.startDateTo
    /*if(data.status !== null){
        data1 = JSON.stringify({
            keyword: data.keyword,
            authorName: data.authorName,
            classification: data.classification,
            auctionId: data.auctionId,
            sellingMethod: data.category,
            status: data.status,
            startDateFrom: data.startDateFrom,
            startDateTo: data.startDateTo
        })
    }else{
        data1 = JSON.stringify({
            keyword: data.keyword,
            authorName: data.authorName,
            classification: data.classification,
            auctionId: data.auctionId,
            sellingMethod: data.category,
            startDateFrom: data.startDateFrom,
            startDateTo: data.startDateTo
        })
    } */
    return async dispatch => {
            const response = await fetch(`${_url.endPoint}/api/v1/uk/auctions/search`, {
                method: 'POST',
                body: JSON.stringify(data1),
                headers: header
            });

        const json = await response.json()
        dispatch({type: AUCTIONS_SEARCH, payload: json})
    }
}

export function auctionsSearchTestElemArr(_url, header, elemArr){
    return async dispatch => {
            const response = await fetch(`${_url.domen}/api/v1/uk/auctions/search/has_bids?${elemArr}`, {
                method: 'GET',
                headers: header
            });

        const json = await response.json()
        dispatch({type: AUCTIONS_SEARCH_ELEM_ARR, payload: json})
    }
}

export function setPopUpAuctionDeleteTitle(value) {
    return {
        type: POPUP_AUCTION_DELETE_TITLE,
        payload: value
    }
}

export function setPopUpAuctionDeleteDate(value) {
    return {
        type: POPUP_DATE_DELETE_AUCTION,
        payload: value
    }
}


export function auctionsGetClassificationsByTypeDGFAdd(_url, header, mask) {
    const data = {
        //url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/classifications/autocomplete/keyword?mask=${mask}`,
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/classifications/autocomplete/classification_id?limit=1000&offset=0&classification_id=${mask}&scheme=CAV&mask=${mask}`,
        actionType: SET_CLASSIFICATIONS_BY_TYPE_DGF_ADD,
        header: header
    }
    return getAPI(data);
}

export function seDgfMask(value) {
    return {
        type: SET_DGF_MASK,
        payload: value
    }
}

export function setTypeAuctions(value) {
    return {
        type: SET_SEARCH_URL,
        payload: value
    }
}

export function openVidjetStatusAuction(value){
    return {
        type: SET_SEARCH_VIDJET_STATUS,
        payload: value
    }
}


export function openVidjetOrganizatorAuctionString(value){
    return {
        type: SET_SEARCH_VIDJET_ORGANIZATOR_STRING,
        payload: value
    }
}

export function openVidjetOrganizatorAuctionArrGet(value){
    return {
        type: SET_SEARCH_VIDJET_ORGANIZATOR_ARR_GET,
        payload: value
    }
}

export function openVidjetOrganizatorAuctionArr(value){
    return {
        type: SET_SEARCH_VIDJET_ORGANIZATOR_ARR,
        payload: value
    }
}

export function openVidjetOrganizatorAuctionArrNew(value){
    return {
        type: SET_SEARCH_VIDJET_ORGANIZATOR_ARR_NEW,
        payload: value
    }
}

export function openVidjetOrganizatorAuction(value){
    return {
        type: SET_SEARCH_VIDJET_ORGANIZATOR,
        payload: value
    }
}

export function openVidjetBuyersAuctionString(value){
    return {
        type: SET_SEARCH_VIDJET_BUYERS_STRING,
        payload: value
    }
}

export function openVidjetBuyersAuctionArrGet(value){
    return {
        type: SET_SEARCH_VIDJET_BUYERS_ARR_GET,
        payload: value
    }
}

export function openVidjetBuyersAuctionArr(value){
    return {
        type: SET_SEARCH_VIDJET_BUYERS_ARR,
        payload: value
    }
}

export function openVidjetBuyersAuctionArrNew(value){
    return {
        type: SET_SEARCH_VIDJET_BUYERS_ARR_NEW,
        payload: value
    }
}

export function openVidjetBuyersAuction(value){
    return {
        type: SET_SEARCH_VIDJET_BUYERS,
        payload: value
    }
}


export function setArhStatusAuction(value){
    return {
        type: SET_SEARCH_ARH_STATUS,
        payload: value
    }
}

export function setArhStatusAuctionNew(value){
    return {
        type: SET_SEARCH_NEW_ARH_STATUS,
        payload: value
    }
}


export function openVidjetClassificationAuctionString(value){
    return {
        type: SET_SEARCH_VIDJET_CLASSIFICATION_STRING,
        payload: value
    }
}

export function openVidjetClassificationAuctionArrGet(value){
    return {
        type: SET_SEARCH_VIDJET_CLASSIFICATION_ARR_GET,
        payload: value
    }
}

export function openVidjetClassificationAuctionArr(value){
    return {
        type: SET_SEARCH_VIDJET_CLASSIFICATION_ARR,
        payload: value
    }
}

export function openVidjetClassificationAuctionArrNew(value){
    return {
        type: SET_SEARCH_VIDJET_CLASSIFICATION_ARR_NEW,
        payload: value
    }
}

export function openVidjetClassificationAuction(value){
    return {
        type: SET_SEARCH_VIDJET_CLASSIFICATION,
        payload: value
    }
}

export function openVidjetSimpleFilterAuction(value){
    return {
        type: SET_SEARCH_VIDJET_SIMPLE_FILTER,
        payload: value
    }
}

export function openVidjetSimpleFilterAuctionRegionOpen(value){
    return {
        type: SET_SEARCH_VIDJET_SIMPLE_FILTER_REGION_OPEN,
        payload: value
    }
}

export function openVidjetSimpleFilterAuctionRegionProc(value){
    return {
        type: SET_SEARCH_VIDJET_SIMPLE_FILTER_REGION_PROC,
        payload: value
    }
}

export function openVidjetSimpleFilterAuctionRegionProcNew(value){
    return {
        type: SET_SEARCH_VIDJET_SIMPLE_FILTER_REGION_NEW_PROC,
        payload: value
    }
}

export function openVidjetSimpleFilterAuctionTypeSubjectProcurement(value){
    return {
        type: SET_SEARCH_VIDJET_SIMPLE_FILTER_TYPE_SUBJECT_PROCUREMENT,
        payload: value
    }
}

export function openVidjetSimpleFilterAuctionTypeSubjectProcurementNew(value){
    return {
        type: SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_TYPE_SUBJECT_PROCUREMENT,
        payload: value
    }
}

export function openVidjetSimpleFilterAuctionTypePayment(value){
    return {
        type: SET_SEARCH_VIDJET_SIMPLE_FILTER_TYPE_PAYMENT,
        payload: value
    }
}
export function openVidjetSimpleFilterAuctionTypePaymentNew(value){
    return {
        type: SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_TYPE_PAYMENT,
        payload: value
    }
}
export function openVidjetTypePayment(value){
    return {
        type: SET_SEARCH_VIDJET_TYPE_PAYMENT_OPEN,
        payload: value
    }
}

export function openVidjetTypeSubjectProcurement(value){
    return {
        type: SET_SEARCH_VIDJET_TYPE_SUBJECT_PROCUREMENT_OPEN,
        payload: value
    }
}

export function openVidjetProcurementMethodType(value){
    return {
        type: SET_SEARCH_VIDJET_SIMPLE_FILTER_PROCUREMENT_METHOD_TYPE_OPEN,
        payload: value
    }
}

export function openVidjetProcurementMethodTypeProc(value){
    return {
        type: SET_SEARCH_VIDJET_SIMPLE_FILTER_PROCUREMENT_METHOD_TYPE_PROC,
        payload: value
    }
}
export function openVidjetProcurementMethodTypeProcNew(value){
    return {
        type: SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_PROCUREMENT_METHOD_TYPE_PROC,
        payload: value
    }
}


export function openVidjetfilterValueAmountFrom(value){
    return {
        type: SET_SEARCH_VIDJET_SIMPLE_FILTER_VALUE_AMOUNT_FROM,
        payload: value
    }
}
export function openVidjetfilterValueAmountFromNew(value){
    return {
        type: SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_VALUE_AMOUNT_FROM,
        payload: value
    }
}
export function openVidjetfilterValueAmountTo(value){
    return {
        type: SET_SEARCH_VIDJET_SIMPLE_FILTER_VALUE_AMOUNT_TO,
        payload: value
    }
}
export function openVidjetfilterValueAmountToNew(value){
    return {
        type: SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_VALUE_AMOUNT_TO,
        payload: value
    }
}
export function openVidjetfilterValueAmountOpen(value){
    return {
        type: SET_SEARCH_VIDJET_SIMPLE_FILTER_VALUE_AMOUNT_OPEN,
        payload: value
    }
}

export function openVidjetfilterEnquiryPeriodFrom(value){
    return {
        type: SET_SEARCH_VIDJET_SIMPLE_ENQUIRY_PERIOD_FROM,
        payload: value
    }
}
export function openVidjetfilterEnquiryPeriodFromNew(value){
    return {
        type: SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_ENQUIRY_PERIOD_FROM,
        payload: value
    }
}
export function openVidjetfilterEnquiryPeriodTo(value){
    return {
        type: SET_SEARCH_VIDJET_SIMPLE_FILTER_ENQUIRY_PERIOD_TO,
        payload: value
    }
}
export function openVidjetfilterEnquiryPeriodToNew(value){
    return {
        type: SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_ENQUIRY_PERIOD_TO,
        payload: value
    }
}
export function openVidjetfilterEnquiryPeriodOpen(value){
    return {
        type: SET_SEARCH_VIDJET_SIMPLE_FILTER_ENQUIRY_PERIOD_OPEN,
        payload: value
    }
}





export function openVidjetfilterAuctionPeriodFrom(value){
    return {
        type: SET_SEARCH_VIDJET_SIMPLE_AUCTION_PERIOD_FROM,
        payload: value
    }
}
export function openVidjetfilterAuctionPeriodFromNew(value){
    return {
        type: SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_AUCTION_PERIOD_FROM,
        payload: value
    }
}
export function openVidjetfilterAuctionPeriodTo(value){
    return {
        type: SET_SEARCH_VIDJET_SIMPLE_FILTER_AUCTION_PERIOD_TO,
        payload: value
    }
}
export function openVidjetfilterAuctionPeriodToNew(value){
    return {
        type: SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_AUCTION_PERIOD_TO,
        payload: value
    }
}
export function openVidjetfilterAuctionPeriodOpen(value){
    return {
        type: SET_SEARCH_VIDJET_SIMPLE_FILTER_AUCTION_PERIOD_OPEN,
        payload: value
    }
}

export function openVidjetfilterTenderPeriodFrom(value){
    return {
        type: SET_SEARCH_VIDJET_SIMPLE_TENDER_PERIOD_FROM,
        payload: value
    }
}
export function openVidjetfilterTenderPeriodFromNew(value){
    return {
        type: SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_TENDER_PERIOD_FROM,
        payload: value
    }
}
export function openVidjetfilterTenderPeriodTo(value){
    return {
        type: SET_SEARCH_VIDJET_SIMPLE_FILTER_TENDER_PERIOD_TO,
        payload: value
    }
}
export function openVidjetfilterTenderPeriodToNew(value){
    return {
        type: SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_TENDER_PERIOD_TO,
        payload: value
    }
}
export function openVidjetfilterTenderPeriodOpen(value){
    return {
        type: SET_SEARCH_VIDJET_SIMPLE_FILTER_TENDER_PERIOD_OPEN,
        payload: value
    }
}


export function openVidjetfilterSummFrom(value){
    return {
        type: SET_SEARCH_VIDJET_SIMPLE_FILTER_VALUE_SUMM_FROM,
        payload: value
    }
}
export function openVidjetfilterSummFromNew(value){
    return {
        type: SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_VALUE_SUMM_FROM,
        payload: value
    }
}
export function openVidjetfilterSummTo(value){
    return {
        type: SET_SEARCH_VIDJET_SIMPLE_FILTER_VALUE_SUMM_TO,
        payload: value
    }
}
export function openVidjetfilterSummToNew(value){
    return {
        type: SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_VALUE_SUMM_TO,
        payload: value
    }
}
export function openVidjetfilterSummOpen(value){
    return {
        type: SET_SEARCH_VIDJET_SIMPLE_FILTER_VALUE_SUMM_OPEN,
        payload: value
    }
}




export function openVidjetfilterPlansTenderPeriodFrom(value){
    return {
        type: SET_SEARCH_VIDJET_SIMPLE_PLANS_TENDER_PERIOD_FROM,
        payload: value
    }
}
export function openVidjetfilterPlansTenderPeriodFromNew(value){
    return {
        type: SET_SEARCH_VIDJET_SIMPLE_PLANS_NEW_TENDER_PERIOD_FROM,
        payload: value
    }
}
export function openVidjetfilterPlansTenderPeriodTo(value){
    return {
        type: SET_SEARCH_VIDJET_SIMPLE_PLANS_TENDER_PERIOD_TO,
        payload: value
    }
}
export function openVidjetfilterPlansTenderPeriodToNew(value){
    return {
        type: SET_SEARCH_VIDJET_SIMPLE_PLANS_NEW_TENDER_PERIOD_TO,
        payload: value
    }
}
export function openVidjetfilterPlansTenderPeriodOpen(value){
    return {
        type: SET_SEARCH_VIDJET_SIMPLE_PLANS_TENDER_PERIOD_OPEN,
        payload: value
    }
}



export function sortListOpen(value){
    return {
        type: SET_SEARCH_SORT_LIST_OPEN,
        payload: value
    }
}
export function sortListArray(value){
    return {
        type: SET_SEARCH_VIDJET_SIMPLE_PLANS_TENDER_PERIOD_OPEN,
        payload: value
    }
}

export function openVidjetDjereloFinArr(value){
    return {
        type: SET_SEARCH_VIDJET_DJERELO_FIN_ARR,
        payload: value
    }
}

export function openVidjetDjereloFinArrNew(value){
    return {
        type: SET_SEARCH_VIDJET_DJERELO_FIN_ARR_NEW,
        payload: value
    }
}

export function openVidjetDjereloFin(value){
    return {
        type: SET_SEARCH_VIDJET_DJERELO_FIN,
        payload: value
    }
}

export function openVidjetRozporyadnukKoshtivString(value){
    return {
        type: SET_SEARCH_VIDJET_ROZPORYADNUK_KOSHTIV_STRING,
        payload: value
    }
}

export function openVidjetRozporyadnukKoshtivArrGet(value){
    return {
        type: SET_SEARCH_VIDJET_ROZPORYADNUK_KOSHTIV_ARR_GET,
        payload: value
    }
}

export function openVidjetRozporyadnukKoshtivArr(value){
    return {
        type: SET_SEARCH_VIDJET_ROZPORYADNUK_KOSHTIV_ARR,
        payload: value
    }
}

export function openVidjetRozporyadnukKoshtivArrNew(value){
    return {
        type: SET_SEARCH_VIDJET_ROZPORYADNUK_KOSHTIV_ARR_NEW,
        payload: value
    }
}

export function openVidjetRozporyadnukKoshtiv(value){
    return {
        type: SET_SEARCH_VIDJET_ROZPORYADNUK_KOSHTIV,
        payload: value
    }
}

export function setAuctionsArrayNull() {
    return {
        type: AUCTIONS_GET,
        payload: null
    }
}

export function gmdnClassificationArrGet(value){
    return {
        type: SET_GMDN_CLASSIFICATION_ARR_GET,
        payload: value
    }
}

export function gmdnClassificationArr(value){
    return {
        type: SET_GMDN_CLASSIFICATION_ARR,
        payload: value
    }
}

export function gmdnClassificationArrNew(value){
    return {
        type: SET_GMDN_CLASSIFICATION_ARR_NEW,
        payload: value
    }
}

export function dkClassificationArrGet(value){
    return {
        type: SET_DK_CLASSIFICATION_ARR_GET,
        payload: value
    }
}

export function dkClassificationArr(value){
    return {
        type: SET_DK_CLASSIFICATION_ARR,
        payload: value
    }
}

export function dkClassificationArrNew(value){
    return {
        type: SET_DK_CLASSIFICATION_ARR_NEW,
        payload: value
    }
}