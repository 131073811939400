import React, { useState } from 'react';
import styles from './pagination.module.css';

const Paginator = ( { setParams, params, list } ) => {

    let tmpArrowPrew  = <div className={styles.pageBackNav} onClick={ ()=> setParams( prev => ({...prev, offset: prev.offset - params.limit }) ) }>
                            <svg width="7" height="12" xmlns="http://www.w3.org/2000/svg" className={styles.svgIcon} viewBox="0 0 7 12">
                                <g id="Слой_2" data-name="Слой 2">
                                    <g id="Слой_1-2" data-name="Слой 1">
                                    <path className={styles.svgIcon} d="M2.41,6l4.3,4.29a1,1,0,1,1-1.42,1.42l-5-5a1,1,0,0,1,0-1.42l5-5A1,1,0,0,1,6.71,1.71Z" fill="#92929D"/></g></g>
                            </svg>
                        </div>

    let tmpArrowNext = <div className={styles.pageFwdNav} onClick={ ()=> setParams( prev => ({...prev, offset: prev.offset + params.limit }) ) }>
                            <svg width="7" height="12" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7 12">
                                <g id="Слой_2" data-name="Слой 2">
                                    <g id="Слой_1-2" data-name="Слой 1">
                                        <path className={styles.svgIcon} d="M4.59,6,.29,1.71A1,1,0,0,1,1.71.29l5,5a1,1,0,0,1,0,1.42l-5,5A1,1,0,0,1,.29,10.29Z" fill="#92929D"/></g></g>
                            </svg>
                        </div>

    function buttonPage(){
        // if(){
            return (
                <ul>
                    <li className={styles.font_active_page}>{ params.offset / params.limit + 1 }</li>
                </ul>
            )
        // }
    }

    function pageNavCounter() {
            return (
                <div className={styles.pageNavCounter} >
                    <div className={styles.pageNumber}>{ params.limit }</div>
                    <div className={styles.arrowsCountainer}>
                        <div className={styles.counterUp}
                            onClick={ () => { 
                                setParams( prev => ({...prev, limit: params.limit ===30 ? 30 : params.limit + 10 }) )
                                setParams( prev => ({...prev, offset: 0 }) )
                            }}>
                            <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path className={styles.svgIcon} fillRule="evenodd" clipRule="evenodd" d="M7.5 6C7.91202 6 8.14721 5.52962 7.9 5.2L4.4 0.533333C4.2 0.266666 3.8 0.266667 3.6 0.533333L0.1 5.2C-0.147214 5.52962 0.0879774 6 0.5 6L7.5 6Z" fill="#92929D" />
                            </svg>
                        </div>
                        <div className={styles.counterDn}
                            onClick={ () => { 
                                setParams( prev => ({...prev, limit: params.limit === 10 ? 10 : params.limit - 10 }) )
                                setParams( prev => ({...prev, offset: 0 }) )
                            }}>
                            <svg width="8" height="6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.59 6.37">
                                    <g id="Слой_2" data-name="Слой 2"><g id="Слой_1-2" data-name="Слой 1">
                                        <path className={styles.svgIcon} d="M9.09,0a.5.5,0,0,1,.39.81L5.18,6.18a.5.5,0,0,1-.78,0L.11.81A.5.5,0,0,1,.5,0Z" fill="#92929D" />
                                    </g>
                                </g>
                            </svg>
                        </div>
                    </div>
                </div>
            )
    }

    if ( !!list && list.length > 0 && !!params) {
        return (
            <div className={styles.paginationWrap}>
                <div className={styles.pagination}>
                    {/* <div className={styles.pageDiscription}>
                        <span className="">{tmpMinCurentPage}-{maxItems > (myPage * pagesQuantity) ? myPage * pagesQuantity : maxItems} </span> з
                    <span className=""> {maxItems} </span>
                        {itemsName}
                    </div> */}
                    <div className={styles.pagePagesList}>
                        { ( params.offset / params.limit + 1 ) > 1 && tmpArrowPrew }
                        { buttonPage() }
                        { list.length === params.limit && tmpArrowNext}
                    </div>
                    { pageNavCounter() }
                </div>
            </div>
        )
    }
    return null
}

export default Paginator