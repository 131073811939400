import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import styles from '../search.module.css';
import NavPart from "../../../../find/navPart/navPart"
import ExecutionStatusSearch from './statusSearch';
import BadgesSearch from './badjes';
import ExecutionAdvancedSearch from './advancedSearch';
import ExecutionsTypeSearch from './typeSearch';
import SearchSortFilter from '../../../regeestry/elems/sortFilter';
import KeywordSearch from '../elems/keyword';

import InputSelect from '../../../../inputs/inputSelect'

import { getClassification } from '../../../../../redux/asset/assetActions';
import { itemClassificatorMask } from '../../../../../utils/endPoints'

const ExecutionsSearch =({ setParams, params })=> {
    const dispatch = useDispatch()
    const classificationCAV = useSelector( state => state.asset.classificationCAV );
    const [ keyword, setKeyword ] = useState(null)

    const [ mainClassif, setMainClassif ] = useState(null)
    const [ classification, setClassification ] = useState( null )

    useEffect(() => {
        if ( keyword === '') setParams( prev => (
            {...prev, 
                keyword: null,
                offset: 0
            }
            ))
    }, [keyword])

    useEffect(() => {
        if ( !!mainClassif ){
            dispatch( getClassification( itemClassificatorMask( mainClassif ) ))
        }
    }, [ mainClassif ])

    const emptySearch = {
        limit: 10,
        offset: 0,
        order: 'DESC',
        keyword: null,
        assetId: null,
        status: [],
        title: null,
        description: null,
        assetHolder: null,
        assetCustodian: null,
        datePublishedFrom: null,
        datePublishedTo: null,
        assetItemType: null,
        classifications: null,
        classificationPrefix: null,
        region: null
    }
   
    function mainCAV(type){
        if(type === "realEstate"){
            return [
                { key:'04000000-8', value:'04', name: 'CAV 04 Нерухоме майно (та усі вкладені коди)'},
            ]
        }
        if(type === "vehicle"){
            return [
                { key:'34000000-7', value:'34', name: 'CAV 34 Транспортне обладнання та допоміжне приладдя до нього (та усі вкладені коди)'},
            ]
        }
        if(type === "land"){
            return [
                { key:'06000000-2', value:'06', name: 'CAV 06 Земельні ділянки (та усі вкладені коди)'},
            ]
        }
        if(type === "otherProperty"){
            return [
                { key:'07000000-9', value:'07', name: 'CAV 07 Права вимоги за кредитними договорами (та усі вкладені коди)'},
                { key:'08000000-6', value:'08', name: 'CAV 08 Корпоративні права (та усі вкладені коди)'},
                { key:'16000000-5', value:'16', name: 'CAV 16 Сільськогосподарська техніка (та усі вкладені коди)'},
                { key:'30000000-9', value:'30', name: 'CAV 30 Офісна та комп’ютерна техніка, устаткування та приладдя, крім меблів та пакетів програмного забезпечення (та усі вкладені коди)'},
                { key:'32000000-3', value:'32', name: 'CAV 32 Радіо-, телевізійна, комунікаційна, телекомунікаційна та супутня апаратура й обладнання (та усі вкладені коди)'},
                { key:'39000000-2', value:'39', name: 'CAV 39 Меблі (у тому числі офісні меблі), меблево-декоративні вироби, побутова техніка (крім освітлювального обладнання) та засоби для чищення (та усі вкладені коди)'},
                { key:'42000000-6', value:'42', name: 'CAV 42 Промислова техніка (та усі вкладені коди)'}
            ]
        }
        if(type === "jointPropertyComplex"){
            return [
                { key:'05000000-5', value:'05', name: 'CAV 05 Цілісний майновий комплекс або майновий комплекс - комплекс активів підприємства (та усі вкладені коди)'},
            ]
        }
        return [
            { key:'04000000-8', value:'04', name: 'CAV 04 Нерухоме майно (та усі вкладені коди)'},
            { key:'05000000-5', value:'05', name: 'CAV 05 Цілісний майновий комплекс або майновий комплекс - комплекс активів підприємства (та усі вкладені коди)'},
            { key:'06000000-2', value:'06', name: 'CAV 06 Земельні ділянки (та усі вкладені коди)'},
            { key:'07000000-9', value:'07', name: 'CAV 07 Права вимоги за кредитними договорами (та усі вкладені коди)'},
            { key:'08000000-6', value:'08', name: 'CAV 08 Корпоративні права (та усі вкладені коди)'},
            { key:'16000000-5', value:'16', name: 'CAV 16 Сільськогосподарська техніка (та усі вкладені коди)'},
            { key:'30000000-9', value:'30', name: 'CAV 30 Офісна та комп’ютерна техніка, устаткування та приладдя, крім меблів та пакетів програмного забезпечення (та усі вкладені коди)'},
            { key:'32000000-3', value:'32', name: 'CAV 32 Радіо-, телевізійна, комунікаційна, телекомунікаційна та супутня апаратура й обладнання (та усі вкладені коди)'},
            { key:'34000000-7', value:'34', name: 'CAV 34 Транспортне обладнання та допоміжне приладдя до нього (та усі вкладені коди)'},
            { key:'39000000-2', value:'39', name: 'CAV 39 Меблі (у тому числі офісні меблі), меблево-декоративні вироби, побутова техніка (крім освітлювального обладнання) та засоби для чищення (та усі вкладені коди)'},
            { key:'42000000-6', value:'42', name: 'CAV 42 Промислова техніка (та усі вкладені коди)'}
        ]
    }

    const assetItemTypes = [
        //{ value: 'realEstate', name: 'Об\'єкт нерухомості' },
        //{ value: 'vehicle', name: 'Транспортний засіб'},
        //{ value: 'land', name: 'Земельна ділянкa' },
        //{ value: 'otherProperty', name: 'Інше окреме індивідуально визначене майно' },
        //{ value: 'jointPropertyComplex', name: 'Об\'єкт єдиного майнового комплексу' },
        { value: 'assets', name: 'Майно' },
        { value: 'claimRights', name: 'Права вимоги' },
    ]

    // function searchSetup(){
    //     return {
    //         limit: 10,
    //         offset: 0,
    //         order: 'ASC',
    //     }
    // }

    function classificationIdOption(){
        let collatore = new Intl.Collator('uk-UA')
        if( classificationCAV !== null ){
            let tmp = classificationCAV
            tmp.sort(
                (a, b) => collatore.compare(a.classificationId, b.classificationId)
            )
            const regex = new RegExp(/^04[0-9]0.*$|^040[1-9].*$/)
            const regex2 = new RegExp(/^05[0-9]0.*$|^050[1-9].*$']/)
            return tmp
            .filter( i => !i.id.match(regex) )
            .filter( i => !i.id.match(regex2) )
            .map(
                (i) => {
                    return { key: i.id, value: i.id, name: `${ i.scheme + ' ' + i.id + ' ' + i.description.uk_UA }`}
                }
            )
        }
    }



    return (
        <div className={styles.search}>
            <NavPart />
            <div className={styles.bottomSection}>
            <KeywordSearch setParams={ setParams } params={ params } />
                <div className={styles.searchFilterBlock}>
                    <div className={styles.searchFilterBlock_icon}>
                        <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M11.5564 8.8105V13.3324C11.5564 13.669 11.3662 13.9768 11.0651 14.1274L7.50978 15.905C6.9188 16.2005 6.22346 15.7708 6.22346 15.11V8.8105L0.202598 1.45166C-0.272227 0.871322 0.140674 0 0.890511 0H16.8894C17.6392 0 18.0521 0.871322 17.5773 1.45166L11.5564 8.8105ZM9.77876 12.7831V8.49322C9.77876 8.28804 9.84975 8.08918 9.97968 7.93038L15.0137 1.77765H2.76615L7.8002 7.93038C7.93013 8.08918 8.00111 8.28804 8.00111 8.49322V13.6719L9.77876 12.7831Z" fill="#696974"/>
                        </svg>
                    </div>
                    <ExecutionStatusSearch setParams={ setParams } arr={ params } />
                    {/* <ExecutionsTypeSearch setParams={ setParams } arr={ params } /> */}
                    <ExecutionAdvancedSearch setParams={ setParams } params={ params } />
                </div>
                <div className={styles.searchFilterBlock}>
                    <div className={styles.searchFilterBlock_icon}>
                    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg"></svg>
                    </div>
                    <div className={styles.assetItemType}>
                        <InputSelect
                            label="Тип об'єкта"
                            value={ params.assetItemType }
                            onChange={ event => {
                                setParams( prev => ({...prev, assetItemType: event }) )
                                // if(event === "realEstate") setMainClassif('04')
                                // if(event === "vehicle") setMainClassif('34')
                                // if(event === "land") setMainClassif('06')
                                // if(event === "otherProperty"){
                                //     setMainClassif(null)
                                //     dispatch( {payload: [], type: 'CLASSIFICATION_CAV'})
                                // }
                                // if(event === "jointPropertyComplex") setMainClassif('05')
                                setClassification( null )
                                setParams( prev => ({...prev, classifications: null }) ) 
                            }}
                            options={ assetItemTypes }
                        />
                    </div>
                    <div className={styles.assetItemType}>
                        <InputSelect
                            label="Основний класифікатор"
                            value={ mainClassif }
                            onChange={ event => {
                                setMainClassif(event)
                                setClassification( null )

                                //setParams( prev => ({...prev, classifications: null }) )
                                setParams( prev => ({...prev, classificationPrefix: event}) )

                            }}
                            options={ mainCAV(params.assetItemType) }
                        />
                    </div>
                    <div className={styles.assetItemType}>
                        <InputSelect
                            label="Вкладені коди основного класифікатора"
                            value={ classification }
                            // infoBlock='Виберіть вкладений код основного класифікатора'
                            onChange={( event ) => { 
                                setClassification( event )
                                setParams( prev => ({...prev, classifications: event }) ) 
                                // setData( event )
                            }}
                            options={ classificationIdOption( ) }
                            finder={ true }
                            disabled={mainClassif === null ? true : false}
                            // cav={true}
                        />
                    </div>
                </div>
                {/*<BadgesSearch params={ params } setParams={ setParams } />*/}
                <div className={styles.setupFilterWrap}>
                    <div
                        className={styles.filterReset}
                        onClick={ ()=> setParams( emptySearch ) }
                    >
                        Скинути все
                    </div>
                    <div className={styles.filterSort}>
                        <SearchSortFilter setParams={ setParams } params={ params } />
                    </div>
                    {/* <div className={styles.filterUnActive}>Зберегти налаштування пошуку</div>
                    <div className={styles.filterUnActive}>Отримувати оновлення на email</div> */}
                </div>
            </div>
        </div>
    )
}

export default ExecutionsSearch