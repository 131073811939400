import {
    OBJECTS_GET_MY,
    OBJECT_GET_TYPES,
    OBJECT_GET_RESTRICTIONS,
    OBJECT_GET_INTENDED_USE,
    OBJECT_GET_LIST_TYPES,
    OBJECT_GET_STATUS_IN_LIST,
    OBJECT_GET_REGISTRATION_STATE_LIST,
    OBJECT_GET_VERIFICATION_3_LIST,
    OBJECT_GET_OWNERSHIP_TYPE,
    OBJECT_GET_UA_INDENTIFIERS_LIST,
    POPUP_LOCATION,
    OBJECT_CREATE,
    OBJECT_UPDATE,
    OBJECT_GET,
    OBJECT_DELETE,
    OBJECT_ADDRESS,
    SEARCH_ADDRESS,
    OBJECT_PAGE_CREATE,
    OBJECT_VALUES_CHARACTERISTICS_UPDATE,
    OBJECT_LEASE_RULES_UPDATE,
    OBJECT_STATUSES_DECISIONS_UPDATE,
    OBJECT_RELATED_ORGANIZATIONS_UPDATE,
    OBJECT_RELATED_ORGANIZATIONS_DELETE,
    SET_OBJECT_CREATION_STEP,
    INACTIVE_OBJECT_POPUP,
    OBJECT_DELETE_POPUP,
    OBJECT_GET_ENCUMBRANCES_LIST,
    OBJECT_GET_FUEL_TYPE_LIST,
    OBJECT_GET_TRANSMISSION_TYPE_LIST,
    OBJECT_GET_FLOOR_TYPE_LIST,
    OBJECT_GET_POWER_SUPPLY_CLASS_LIST,
    OBJECT_GET_CONSTRUCTION_TECHNOLOGY_LIST,
    OBJECT_GET_CAV_LIST,
} from "../../types.js";
import {postAPI,  getAPI, deleteAPI, putAPI, patchAPI} from '../crud';


export function objectsGetMy(_url, token){
    const data = {
        url: _url,
        actionType: OBJECTS_GET_MY,
        header: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        }
    }
    return getAPI(data);
}

export function objectGetTypes(_url){
    const data = {
        url: _url,
        actionType: OBJECT_GET_TYPES,
        header: {
            'Content-Type': 'application/json'
        }
    }
    return getAPI(data);
}

export function objectGetRestrictions(_url){
    const data = {
        url: _url,
        actionType: OBJECT_GET_RESTRICTIONS,
        header: {
            'Content-Type': 'application/json'
        }
    }
    return getAPI(data);
}

export function objectGetIntendedUse(_url){
    const data = {
        url: _url,
        actionType: OBJECT_GET_INTENDED_USE,
        header: {
            'Content-Type': 'application/json'
        }
    }
    return getAPI(data);
}

export function objectGetListTypes(_url){
    const data = {
        url: _url,
        actionType: OBJECT_GET_LIST_TYPES,
        header: {
            'Content-Type': 'application/json'
        }
    }
    return getAPI(data);
}

export function objectGetStatusInList(_url){
    const data = {
        url: _url,
        actionType: OBJECT_GET_STATUS_IN_LIST,
        header: {
            'Content-Type': 'application/json'
        }
    }
    return getAPI(data);
}

export function objectGetRegistrationStateList(_url){
    const data = {
        url: _url,
        actionType: OBJECT_GET_REGISTRATION_STATE_LIST,
        header: {
            'Content-Type': 'application/json'
        }
    }
    return getAPI(data);
}

export function objectGetVerification3List(_url){
    const data = {
        url: _url,
        actionType: OBJECT_GET_VERIFICATION_3_LIST,
        header: {
            'Content-Type': 'application/json'
        }
    }
    return getAPI(data);
}

export function objectGetOwnershipType(_url) {
    const data = {
        url: _url,
        actionType: OBJECT_GET_OWNERSHIP_TYPE,
        header: {
            'Content-Type': 'application/json'
        }
    }
    return getAPI(data);
}

export function objectGetEncumbrancesList(_url) {
    const data = {
        url: _url,
        actionType: OBJECT_GET_ENCUMBRANCES_LIST,
        header: {
            'Content-Type': 'application/json'
        }
    }
    return getAPI(data);
}

export function objectCreate(_url, body, token, redirect){
    const data = {
        url: _url,
        actionType: OBJECT_CREATE,
        body: body,
        header: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        },
        objectCreateRedirect: redirect
    }
    return postAPI(data);
}
export function objectUpdate(_url, body, token, redirect){
    const data = {
        url: _url,
        actionType: OBJECT_GET,
        body: body,
        header: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        },
        redirect: redirect
    }
    return patchAPI(data);
}

export function objectGet(_url, token) {
    let header;
    if (token) {
        header = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        }
    } else {
        header = {
            'Content-Type': 'application/json'
        }
    }
    const data = {
        url: _url,
        actionType: OBJECT_GET,
        header: header,
    }
    return getAPI(data);
}

export function objectDelete(_url, token, redirect) {
    const data = {
        url: _url,
        actionType: OBJECT_DELETE,
        header: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        },
        redirect: redirect,
    }
    return deleteAPI(data);
}

export function objectClear() {
    return {
        type: OBJECT_GET,
        payload: null
    }
}

export function setPopupLocation(value) {
    return {
        type: POPUP_LOCATION,
        payload: value
    }
}

export function setAddress(value) {
    return {
        type: OBJECT_ADDRESS,
        payload: value
    }
}

export function searchAddress(_url) {
    const data = {
        url: _url,
        actionType: SEARCH_ADDRESS,
        header: {
            'Accept-Language' : 'uk_UA',
            'Content-Type': 'application/json'
        }
    }
    return getAPI(data);
}

export function clearSearchAddress(value) {
    
    return {
        type: SEARCH_ADDRESS,
        payload: value
    }
}

export function setPageCreate(value) {
    return {
        type: OBJECT_PAGE_CREATE,
        payload: value
    }
}

export function valuesCharacteristicsUpdate(_url, body, token, redirect){
    const data = {
        url: _url,
        actionType: OBJECT_VALUES_CHARACTERISTICS_UPDATE,
        body: body,
        header: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        },
        redirect: redirect,
    }
    return putAPI(data);
}

export function leaseRulesUpdate(_url, body, token, redirect){
    const data = {
        url: _url,
        actionType: OBJECT_LEASE_RULES_UPDATE,
        body: body,
        header: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        },
        redirect: redirect,
    }
    return putAPI(data);
}

export function statusesDecisionsUpdate(_url, body, token, redirect){
    const data = {
        url: _url,
        actionType: OBJECT_STATUSES_DECISIONS_UPDATE,
        body: body,
        header: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        },
        redirect: redirect,
    }
    return putAPI(data);
}
export function relatedOrganizationsUpdate(_url, body, token, redirect){
    const data = {
        url: _url,
        actionType: OBJECT_GET,
        // actionType: OBJECT_RELATED_ORGANIZATIONS_UPDATE,
        body: body,
        header: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        },
        redirect: redirect,
    }
    return patchAPI(data);
}
export function relatedOrganizationsDelete(_url, token, action){
    const data = {
        url: _url,
        actionType: OBJECT_RELATED_ORGANIZATIONS_DELETE,
        header: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        },
        secondAction: action
    }
    return deleteAPI(data);
}

export function objectGetUaIdentifiersList(_url){
    const data = {
        url: _url,
        actionType: OBJECT_GET_UA_INDENTIFIERS_LIST,
        header: {
            'Content-Type': 'application/json'
        }
    }
    return getAPI(data);
}

export function setObjectCreationStep(value) {
    return {
        type: SET_OBJECT_CREATION_STEP,
        payload: value
    }
}

export function setInactiveObjectPopup(value) {
    return {
        type: INACTIVE_OBJECT_POPUP,
        payload: value
    }
}
export function setObjectDeletePopup(value) {
    return {
        type: OBJECT_DELETE_POPUP,
        payload: value
    }
}
export function objectGetFuelTypeList(_url){
    const data = {
        url: _url,
        actionType: OBJECT_GET_FUEL_TYPE_LIST,
        header: {
            'Content-Type': 'application/json'
        }
    }
    return getAPI(data);
}
export function objectGetTransmissionTypeList(_url){
    const data = {
        url: _url,
        actionType: OBJECT_GET_TRANSMISSION_TYPE_LIST,
        header: {
            'Content-Type': 'application/json'
        }
    }
    return getAPI(data);
}
export function objectGetFloorTypeList(_url){
    const data = {
        url: _url,
        actionType: OBJECT_GET_FLOOR_TYPE_LIST,
        header: {
            'Content-Type': 'application/json'
        }
    }
    return getAPI(data);
}
export function objectGetPowerSupplyClassList(_url){
    const data = {
        url: _url,
        actionType: OBJECT_GET_POWER_SUPPLY_CLASS_LIST,
        header: {
            'Content-Type': 'application/json'
        }
    }
    return getAPI(data);
}
export function objectGetConstructionTechnologyList(_url){
    const data = {
        url: _url,
        actionType: OBJECT_GET_CONSTRUCTION_TECHNOLOGY_LIST,
        header: {
            'Content-Type': 'application/json'
        }
    }
    return getAPI(data);
}
export function objectGetCavList(_url){
    const data = {
        url: _url,
        actionType: OBJECT_GET_CAV_LIST,
        header: {
            'Content-Type': 'application/json'
        }
    }
    return getAPI(data);
}
