import { ASSET_GET_ID, EXECUTION_GET_ID, ASSETS_GET_MY, CLASSIFICATION_CAV, EXECUTIONS_GET_MY,
    ANNOUNCEMENT_GET_ID, ANNOUNCEMENTS_GET_MY } from "./types";
import { getAPI, postAPI, patchAPI, patchAPINotNavigation, deleteAPI } from "../actions/crud";
import { postMyAssetItem, getMyAssetItem, postMyAssetDraftItem, getMyAssetDraftItem, 
    patchAssetDocument, patchAssetDraftDocument, urlGetListMyExs,
    getMyRedemptionDraftDocument, getMyRedemptiontDoc, getMyRedemptionDraftDocumentContract, getMyRedemptiontDocContract } from "../../utils/endPoints";
import { isDraftObject } from "../../utils/check";

export function assetGetId(_url, token){
    let header;
    if (token) {
        header = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        }
    } else {
        header = {
            'Content-Type': 'application/json'
        }
    }
    const data = {
        url: _url,
        actionType: ASSET_GET_ID,
        header: header,
    }
    return getAPI(data);
}

export function announcementGetId(_url, token){
    let header;
    if (token) {
        header = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        }
    } else {
        header = {
            'Content-Type': 'application/json'
        }
    }
    const data = {
        url: _url,
        actionType: ANNOUNCEMENT_GET_ID,
        header: header,
    }
    return getAPI(data);
}

export function assetItemClear(value = null){
    return {
        type: ASSET_GET_ID,
        payload: value
    }
}

export function assetGetPublicId(_url){
    const data = {
        url: _url,
        actionType: ASSET_GET_ID,
        header: {'Content-Type': 'application/json'},
    }
    return getAPI(data);
}

export function announcementGetPublicId(_url){
    const data = {
        url: _url,
        actionType: ANNOUNCEMENT_GET_ID,
        header: {'Content-Type': 'application/json'},
    }
    return getAPI(data);
}

export function assetCreate(_url, body, token, navigate){
    const data = {
        url: _url,
        actionType: ASSET_GET_ID,
        body: body,
        header: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        },
        navigate: navigate
    }
    return postAPI(data);
}

export function announcementCreate(_url, body, token, navigate){
    const data = {
        url: _url,
        actionType: ANNOUNCEMENT_GET_ID,
        body: body,
        header: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        },
        navigate: navigate
    }
    return postAPI(data);
}

export function assetUpdate(_url, body, token, navigate){
    const data = {
        url: _url,
        actionType: ASSET_GET_ID,
        body: body,
        header: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        },
        // navigate: navigate()
    }
    return patchAPI(data);
}

export function announcementUpdate(_url, body, token, navigate){
    const data = {
        url: _url,
        actionType: ANNOUNCEMENT_GET_ID,
        body: body,
        header: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        },
        // navigate: navigate()
    }
    return patchAPI(data);
}
export function redemptionsUpdate(_url, body, token, navigate){
    const data = {
        url: _url,
        actionType: ANNOUNCEMENT_GET_ID,
        body: body,
        header: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        },
        // navigate: navigate()
    }
    return patchAPINotNavigation(data);
}

export function assetStatusUpdate(_url, token, navigate){
    const data = {
        url: _url,
        actionType: ASSET_GET_ID,
        // body: body,
        header: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        },
        // navigate: navigate()
    }
    return patchAPI(data);
}

export function announcementsStatusUpdate(_url, token){
    const data = {
        url: _url,
        actionType: ANNOUNCEMENT_GET_ID,
        // body: body,
        header: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        },
        // navigate: navigate()
    }
    return patchAPI(data);
}

export async function assetDelete(_url, token, navigate){
    const data = {
        url: _url,
        // actionType: ASSET_GET_ID,
        header: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        },
    }
    const response = await fetch(data.url, {
                method: 'DELETE',
                headers: data.header
            });
    const json = await response.json();
        if ( json.success && !!navigate ) {
            navigate()
        }
}

export async function assetCancel(_url, token, navigate){
    const data = {
        url: _url,
        body: {
            reason: {
                uk_UA: "Скасування"
            }
        },
        header: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        },
    }
    const response = await fetch(data.url, {
        method: 'POST',
        body: JSON.stringify(data.body),
        headers: data.header
    });
    const json = await response.json();
    if ( json.success && !!navigate ) {
        navigate(`/asset/my_announcements`)
    }else{
        navigate(`/asset/my_announcements`)
    }
}

//ITEMS
export function assetItemCreate( id, body, token ){
    const data = {
        url: isDraftObject(id) ? postMyAssetDraftItem( id ) : postMyAssetItem( id ),
        actionType: ASSET_GET_ID,
        body: body,
        header: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        },
    }
    return postAPI(data);
}

export function assetItemUpdate( assetId, id, body, token ){
    const data = {
        url: isDraftObject(assetId) ? getMyAssetDraftItem( assetId, id ) : getMyAssetItem( assetId, id ),
        actionType: ASSET_GET_ID,
        body: body,
        header: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        },
    }
    return patchAPI(data);
}

export function assetItemDelete( assetId, id, token ){
    const data = {
        url: isDraftObject(assetId) ? getMyAssetDraftItem( assetId, id ) : getMyAssetItem( assetId, id ),
        actionType: ASSET_GET_ID,
        header: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        },
    }
    return deleteAPI(data);
}

export function assetDocDelete( assetId, id, token ){
    const data = {
        url: isDraftObject(assetId) ? patchAssetDraftDocument( assetId, id ) : patchAssetDocument( assetId, id ),
        actionType: ASSET_GET_ID,
        header: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        },
    }
    return deleteAPI(data);
}

export function announcementDocDelete( assetId, id, token ){
    const data = {
        url: isDraftObject(assetId) ? patchAssetDraftDocument( assetId, id ) : patchAssetDocument( assetId, id ),
        actionType: ANNOUNCEMENT_GET_ID,
        header: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        },
    }
    return deleteAPI(data);
}
export function redemptionDocDelete( assetId, id, token ){
    const data = {
        url: isDraftObject(assetId) ? getMyRedemptionDraftDocument( assetId, id ) : getMyRedemptiontDoc( assetId, id ),
        actionType: ANNOUNCEMENT_GET_ID,
        header: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        },
    }
    return deleteAPI(data);
}
export function redemptionDocDeleteContract( assetId, contractId, id, token ){
    const data = {
        url: isDraftObject(assetId) ? getMyRedemptionDraftDocumentContract( assetId, contractId, id ) : getMyRedemptiontDocContract( assetId, contractId, id ),
        actionType: ANNOUNCEMENT_GET_ID,
        header: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        },
    }
    return deleteAPI(data);
}


export function assetsGetMy(_url, token){
    const data = {
        url: _url,
        actionType: ASSETS_GET_MY,
        header: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        }
    }
    return getAPI(data);
}

export function announcementGetMy(_url, token){
    const data = {
        url: _url,
        actionType: ANNOUNCEMENTS_GET_MY,
        header: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        }
    }
    return getAPI(data);
}

export function executionsGetMy( _url, token){
    const data = {
        url: _url,
        actionType: EXECUTIONS_GET_MY,
        header: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        }
    }
    return getAPI(data);
}

export function getClassification(_url, token){
    const data = {
        url: _url,
        actionType: CLASSIFICATION_CAV,
        header: {
            // 'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        }
    }
    return getAPI(data);
}

export function executionGetPublicId(_url){
    const data = {
        url: _url,
        actionType: EXECUTION_GET_ID,
        header: {'Content-Type': 'application/json'},
    }
    return getAPI(data);
}

export function executionGetId(_url, token){
    const data = {
        url: _url,
        actionType: EXECUTION_GET_ID,
        header: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        }
    }
    return getAPI(data);
}

export function executionUpdate(_url, body, token, navigate){
    const data = {
        url: _url,
        actionType: EXECUTION_GET_ID,
        body: body,
        header: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        },
        // navigate: navigate()
    }
    return patchAPI(data);
}