import React ,{ useEffect, useRef } from "react";
import styles from './button.module.css';

const Button =({data, color, onClick, height, width = null, font, disabled, type, hover, children, title })=> {

    const btnRef = useRef()

    useEffect(() => {
        if ( !!hover && !!btnRef ){
            btnRef.current.addEventListener("mouseover", ( )=> btnRef.current.children[0].children[0].style.fill = '#fff' );
            btnRef.current.addEventListener("mouseleave", ( )=> btnRef.current.children[0].children[0].style.fill = hover );
            // return () => {
            //     btnRef.current.removeEventListener("mouseover", ( )=> btnRef.current.children[0].children[0].style.fill = '#fff' );
            //     btnRef.current.removeEventListener("mouseleave", ( )=> btnRef.current.children[0].children[0].style.fill = hover );
            //   }
        }
    }, [btnRef])

    function setColor(color){
        if(type === 'circle') return styles.circle
        if (disabled) return styles.disabled
        switch(color){
            case 'blue': return styles.blue
            case 'gray': return styles.gray
            case 'white': return styles.white
            case 'orange': return styles.orange
            default: return styles.blue
        }
    }

    return (
        <button
            style={{ height: height, fontSize: font, width: width }}
            className={ setColor(color) }
            color="primary"
            onClick={ !disabled ? onClick : undefined }
            disabled={ disabled }
            ref={ btnRef }
            title={ title }
        >
            { data }
            { children }
        </button>
    )
}

export default Button