import React from "react"

const HistoryIcon =( { color = '#92929D', style, widthHeight = [28, 28] })=> {
    return <svg xmlns="http://www.w3.org/2000/svg" 
                style={{display:'flex'}}
                width={widthHeight[0]} 
                height={widthHeight[1]} 
                fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke={ color } className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
}

export default HistoryIcon