import React, {useState} from 'react';
import { useLocation } from 'react-router-dom';
import styles from './buttonFilters.module.css';
import Checkbox from '../../../../inputs/checkBox';
import Button from '../../../../elements/buttons/button';

const AssetStatusSearch = ({ setParams, arr })=> {
    const { pathname } = useLocation()
    const [popup, setPopup] = useState( false )
    const [status] = useState( [
        { value: 'pending', name: 'Об’єкт опубліковано', check: false },
        { value: 'active_rectification', name: 'Опубліковано Інформаційне Повідомлення', check: false },
        { value: 'active_procedure', name: 'Проведення аукціону', check: false },
        { value: 'active_contracting', name: 'Виконання умов приватизації', check: false },
        { value: 'complete', name: 'Приватизація Об\'єкта завершена', check: false },
        { value: 'active_redemption', name: 'Приватизація шляхом викупу', check: false },
        { value: 'archive', name: 'Архів', check: false },
        { value: 'deleted', name: 'Об’єкт виключено із переліку', check: false },
    ])

        const popUp = (
                <div className={styles.wrapMain}>
                    <div className={styles.wrap}>
                        <h3 className={styles.filterTitle}>{ pathname.indexOf('asset') > 0 && pathname.indexOf('_list_lp') === -1 ? "Статус об'єкта МП" : "Статус об'єкта ВП" }</h3>
                        <div className={styles.content}>
                            { status.map( item => <Checkbox key={item.value}
                                    value={ ( !!arr?.status && arr.status.includes(item.value) ) ? true : false }
                                    label={ item.name }
                                    onChange={ ()=> setParams( prev => {
                                        if ( !!arr?.status && arr.status.includes(item.value) ){
                                            let tmp = prev.status
                                            return {...prev, status: tmp.filter( i => i !== item.value ) }
                                        } else {
                                            if ( !!prev?.status && !!prev?.status.length === 0) {
                                                return {...prev, status: [...status, item.value] }
                                            }
                                            if ( !prev?.status) return {...prev, status: [ item.value ] }
                                            else {
                                                let tmp = prev.status
                                                tmp = [...tmp, item.value]
                                                return {...prev, status: tmp }
                                            }
                                        }
                                    } )}
                                    // onChange={ ()=> setStatus( prev => {
                                    //     let clone = Object.assign([], prev)
                                    //     for ( let key in clone ){
                                    //         if ( clone[key].value === item.value ) {
                                    //             clone[key].check = !item.check
                                    //         }
                                    //     }
                                    //     return clone
                                    // } )}
                                />)
                            }
                        </div>
                        <div className={styles.bottomPart}>
                            <Button 
                                color='blue'
                                onClick={() => setPopup( prev => !prev) }>
                                Готово
                            </Button>
                            {/* <div className={styles.btnDone}
                                onClick={() => {
                                    setParams( prev => ({...prev, status: setResult( status ) }) )
                                    setPopup( prev => !prev )
                                }}>
                                Застосувати
                            </div> */}
                        </div>
                    </div>
                </div>
            )

    return (
        <>
            <div
                className={styles.searchFilterBlock_status}
                onClick={ () => setPopup( prev => !prev) }
            >
                <div>{ pathname.indexOf('asset') > 0 && pathname.indexOf('_list_lp') === -1 ? "Статус об'єкта МП" : "Статус об'єкта ВП" }</div>
                { ( !!arr?.status && arr.status.length > 0 )&& 
                    <div style={{borderLeft: '1px dotted #BFBFC6', paddingLeft: '5px', marginLeft: '5px'}}>
                        { arr.status.length }
                    </div> 
                }
            </div>
            { popup && popUp}
        </>
    )
}

export default AssetStatusSearch
