import React,{ useEffect, useState } from 'react';
import styles from './leaseRequestEdit.module.css';
import { Link, useLocation } from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import LeaseRequestEditTitle from './elems/titleBar/editTitleBar';
import LeaseRequestEditSidebar from './elems/sidebar/editSidebar';
import LeaseRequestGeneralEdit from './elems/generalEdit';
import { getObjectById, myLeaseRequestEdit } from '../../../../utils/endPoints';
import Loader from '../../../loader/loader';
import { getMyDraftLeaseRequest } from '../../../../utils/requests/requestsUtils'
import { auctionGetId } from '../../../../redux/actions/auctions/auctions';

const LeaseRequestEdit =( )=> {
    const token = useSelector(state => state.start.token);
    const myRequestItem = useSelector(state => state.start.myRequest)
    const leaseObject = useSelector(state => state.start.auctionsGetId)
    const dispatch = useDispatch()
    const { pathname } = useLocation()
    const [ entitiesArr, setEntitiesArr ] = useState([])

    useEffect(() => {
      const id = pathname.split('/')[4]
      dispatch( getMyDraftLeaseRequest( myLeaseRequestEdit( id ), token?.access_token) )
      return () => {
        dispatch({type: 'REQUEST_GET_MY', payload: null})
      }
    }, [])

    useEffect(() => {
      if ( !!myRequestItem?.relatedObjectId ) dispatch( auctionGetId( getObjectById( myRequestItem.relatedObjectId ) ) )
      
      return () => {
        dispatch({type: 'AUCTIONS_GET_ID', payload: null})
      }
    }, [myRequestItem])

    function setGeneral(){
      if ( !!myRequestItem ) return <LeaseRequestGeneralEdit data={ myRequestItem } entitiesArr={ entitiesArr } />
      else return <Loader />
    }

    return (
        <div className={styles.wrap}>
            <LeaseRequestEditTitle data={ myRequestItem } />
            <div className={styles.container}>
                <LeaseRequestEditSidebar data={ myRequestItem } object={ leaseObject } />
                { setGeneral() }
            </div>
        </div>
    )
}

export default LeaseRequestEdit