import React from 'react';
import styles from './assetWrap.module.css';

const AssetWrap =({ children })=>  { 
    return (
        <div className={styles.main} >
            <div className={styles.container} >
                { children }
            </div>
        </div>
        
        ) 
}

export default AssetWrap