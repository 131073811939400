//Regitstries
export const AUCTIONS_GET = 'AUCTIONS_GET';
export const AUCTIONS_GET_FLAG = 'AUCTIONS_GET_FLAG';
export const AUCTIONS_GET_ID = 'AUCTIONS_GET_ID';
export const LEASE_REQESTS_GET = 'LEASE_REQESTS_GET';
export const LEASE_REQESTS_COUNT_GET = 'LEASE_REQESTS_COUNT_GET';
export const LEASE_ACTIONS_GET = 'LEASE_ACTIONS_GET';
export const LEASE_ACTION_GET_ID = 'LEASE_ACTION_GET_ID';
export const REG_ACTIONS_COUNT_GET = 'REG_ACTIONS_COUNT_GET';
export const REG_PAGINATOR_COUNT_GET = 'REG_PAGINATOR_COUNT_GET';
export const REG_PAGINATOR_PER_PAGE = 'REG_PAGINATOR_PER_PAGE';
export const OBJECT_CONTRACTS_GET = 'OBJECT_CONTRACTS_GET';

export const SET_LOADER = 'SET_LOADER';

//Search
/* asset */
export const SET_SEARCH_ASSET_TYPE = 'SET_SEARCH_ASSET_TYPE';
export const SET_SEARCH_ASSET_STATUS = 'SET_SEARCH_ASSET_STATUS';
export const SET_SEARCH_ANNOUNCEMENTS_TYPE = 'SET_SEARCH_ANNOUNCEMENTS_TYPE';
export const SET_SEARCH_REDEMPTIONS_TYPE = 'SET_SEARCH_REDEMPTIONS_TYPE';

export const SET_SEARCH_FILTERS_COUNT = 'SET_SEARCH_FILTERS_COUNT';
export const SET_STATUS_STRING = 'SET_STATUS_STRING';
export const SET_DATE_FROM = 'SET_DATE_FROM';
export const SET_DATE_TILL = 'SET_DATE_TILL';
export const SET_SEARCH_REGISTRY_ID = 'SET_SEARCH_REGISTRY_ID';
export const SET_SEARCH_REGISTRY_STATUS = 'SET_SEARCH_REGISTRY_STATUS';
export const SET_SEARCH_REGISTRY_IDENTYFIER = 'SET_SEARCH_REGISTRY_IDENTYFIER';
export const SET_SEARCH_REGISTRY_DESC_KEYWD = 'SET_SEARCH_REGISTRY_DESC_KEYWD';
export const SET_SEARCH_ACTION_TYPE = 'SET_SEARCH_ACTION_TYPE';
export const SET_SEARCH_OBJECT_TYPE = 'SET_SEARCH_OBJECT_TYPE';
export const SET_SEARCH_OBJECT_LIST_TYPE = 'SET_SEARCH_OBJECT_LIST_TYPE';
export const SET_SEARCH_REQUEST_TYPE = 'SET_SEARCH_REQUEST_TYPE';
export const SET_SEARCH_CONTRACT_TYPE = 'SET_SEARCH_CONTRACT_TYPE';
export const SET_SEARCH_RELATED_OBJECT_ID = 'SET_SEARCH_RELATED_OBJECT_ID';
export const SET_SEARCH_RELATED_REQUEST_ID = 'SET_SEARCH_RELATED_REQUEST_ID';
export const SET_SEARCH_RELATED_ACTION_ID = 'SET_SEARCH_RELATED_ACTION_ID';
export const SET_SEARCH_ACTION_ID = 'SET_SEARCH_ACTION_ID';
export const SET_SEARCH_REQUEST_ID = 'SET_SEARCH_REQUEST_ID';
export const SET_SEARCH_CONTRACT_ID = 'SET_SEARCH_CONTRACT_ID';
export const SET_SEARCH_URL = 'SET_SEARCH_URL';
export const SET_ALL_RESET = 'SET_ALL_RESET';


export const SET_ARRAY_SORT_LIST = 'SET_ARRAY_SORT_LIST';
export const AUCTIONS_GET_ADD = 'AUCTIONS_GET_ADD';

export const SET_SEARCH_ARH_STATUS = 'SET_SEARCH_ARH_STATUS';
export const SET_SEARCH_NEW_ARH_STATUS = 'SET_SEARCH_NEW_ARH_STATUS';
export const SET_SEARCH_VIDJET_STATUS = 'SET_SEARCH_VIDJET_STATUS';
export const SET_SEARCH_VIDJET_ORGANIZATOR_STRING = 'SET_SEARCH_VIDJET_ORGANIZATOR_STRING';
export const SET_SEARCH_VIDJET_ORGANIZATOR_ARR_GET = 'SET_SEARCH_VIDJET_ORGANIZATOR_ARR_GET';
export const SET_SEARCH_VIDJET_ORGANIZATOR_ARR = 'SET_SEARCH_VIDJET_ORGANIZATOR_ARR';
export const SET_SEARCH_VIDJET_ORGANIZATOR_ARR_NEW = 'SET_SEARCH_VIDJET_ORGANIZATOR_ARR_NEW';
export const SET_SEARCH_VIDJET_ORGANIZATOR = 'SET_SEARCH_VIDJET_ORGANIZATOR';
export const SET_SEARCH_VIDJET_BUYERS_STRING = 'SET_SEARCH_VIDJET_BUYERS_STRING';
export const SET_SEARCH_VIDJET_BUYERS_ARR_GET = 'SET_SEARCH_VIDJET_BUYERS_ARR_GET';
export const SET_SEARCH_VIDJET_BUYERS_ARR = 'SET_SEARCH_VIDJET_BUYERS_ARR';
export const SET_SEARCH_VIDJET_BUYERS_ARR_NEW = 'SET_SEARCH_VIDJET_BUYERS_ARR_NEW';
export const SET_SEARCH_VIDJET_BUYERS = 'SET_SEARCH_VIDJET_BUYERS';
export const SET_SEARCH_VIDJET_CLASSIFICATION_STRING = 'SET_SEARCH_VIDJET_CLASSIFICATION_STRING';
export const SET_SEARCH_VIDJET_CLASSIFICATION_ARR_GET = 'SET_SEARCH_VIDJET_CLASSIFICATION_ARR_GET';
export const SET_SEARCH_VIDJET_CLASSIFICATION_ARR = 'SET_SEARCH_VIDJET_CLASSIFICATION_ARR';
export const SET_SEARCH_VIDJET_CLASSIFICATION_ARR_NEW = 'SET_SEARCH_VIDJET_CLASSIFICATION_ARR_NEW';
export const SET_SEARCH_VIDJET_CLASSIFICATION = 'SET_SEARCH_VIDJET_CLASSIFICATION';
export const SET_GMDN_CLASSIFICATION_ARR_GET = 'SET_GMDN_CLASSIFICATION_ARR_GET'
export const SET_GMDN_CLASSIFICATION_ARR = 'SET_GMDN_CLASSIFICATION_ARR'
export const SET_GMDN_CLASSIFICATION_ARR_NEW = 'SET_GMDN_CLASSIFICATION_ARR_NEW'
export const SET_DK_CLASSIFICATION_ARR_GET = 'SET_DK_CLASSIFICATION_ARR_GET'
export const SET_DK_CLASSIFICATION_ARR = 'SET_DK_CLASSIFICATION_ARR'
export const SET_DK_CLASSIFICATION_ARR_NEW = 'SET_DK_CLASSIFICATION_ARR_NEW'

export const SET_SEARCH_VIDJET_SIMPLE_FILTER = 'SET_SEARCH_VIDJET_SIMPLE_FILTER';
export const SET_SEARCH_VIDJET_SIMPLE_FILTER_REGION_OPEN = 'SET_SEARCH_VIDJET_SIMPLE_FILTER_REGION_OPEN';
export const SET_SEARCH_VIDJET_SIMPLE_FILTER_REGION_PROC = 'SET_SEARCH_VIDJET_SIMPLE_FILTER_REGION_PROC';
export const SET_SEARCH_VIDJET_SIMPLE_FILTER_REGION_NEW_PROC = 'SET_SEARCH_VIDJET_SIMPLE_FILTER_REGION_NEW_PROC';
export const SET_SEARCH_VIDJET_SIMPLE_FILTER_TYPE_SUBJECT_PROCUREMENT = 'SET_SEARCH_VIDJET_SIMPLE_FILTER_TYPE_SUBJECT_PROCUREMENT';
export const SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_TYPE_SUBJECT_PROCUREMENT = 'SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_TYPE_SUBJECT_PROCUREMENT';
export const SET_SEARCH_VIDJET_SIMPLE_FILTER_TYPE_PAYMENT = 'SET_SEARCH_VIDJET_SIMPLE_FILTER_TYPE_PAYMENT';
export const SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_TYPE_PAYMENT = 'SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_TYPE_PAYMENT';
export const SET_SEARCH_VIDJET_TYPE_PAYMENT_OPEN = 'SET_SEARCH_VIDJET_TYPE_PAYMENT_OPEN';
export const SET_SEARCH_VIDJET_TYPE_SUBJECT_PROCUREMENT_OPEN = 'SET_SEARCH_VIDJET_TYPE_SUBJECT_PROCUREMENT_OPEN';
export const SET_SEARCH_VIDJET_SIMPLE_FILTER_PROCUREMENT_METHOD_TYPE_OPEN = 'SET_SEARCH_VIDJET_SIMPLE_FILTER_PROCUREMENT_METHOD_TYPE_OPEN';
export const SET_SEARCH_VIDJET_SIMPLE_FILTER_PROCUREMENT_METHOD_TYPE_PROC = 'SET_SEARCH_VIDJET_SIMPLE_FILTER_PROCUREMENT_METHOD_TYPE_PROC';
export const SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_PROCUREMENT_METHOD_TYPE_PROC = 'SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_PROCUREMENT_METHOD_TYPE_PROC';
export const SET_SEARCH_VIDJET_SIMPLE_FILTER_VALUE_AMOUNT_FROM = 'SET_SEARCH_VIDJET_SIMPLE_FILTER_VALUE_AMOUNT_FROM';
export const SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_VALUE_AMOUNT_FROM = 'SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_VALUE_AMOUNT_FROM';
export const SET_SEARCH_VIDJET_SIMPLE_FILTER_VALUE_AMOUNT_TO = 'SET_SEARCH_VIDJET_SIMPLE_FILTER_VALUE_AMOUNT_TO';
export const SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_VALUE_AMOUNT_TO = 'SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_VALUE_AMOUNT_TO';
export const SET_SEARCH_VIDJET_SIMPLE_FILTER_VALUE_AMOUNT_OPEN = 'SET_SEARCH_VIDJET_SIMPLE_FILTER_VALUE_AMOUNT_OPEN';
export const SET_SEARCH_VIDJET_SIMPLE_ENQUIRY_PERIOD_FROM = 'SET_SEARCH_VIDJET_SIMPLE_ENQUIRY_PERIOD_FROM';
export const SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_ENQUIRY_PERIOD_FROM = 'SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_ENQUIRY_PERIOD_FROM';
export const SET_SEARCH_VIDJET_SIMPLE_FILTER_ENQUIRY_PERIOD_TO = 'SET_SEARCH_VIDJET_SIMPLE_FILTER_ENQUIRY_PERIOD_TO';
export const SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_ENQUIRY_PERIOD_TO = 'SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_ENQUIRY_PERIOD_TO';
export const SET_SEARCH_VIDJET_SIMPLE_FILTER_ENQUIRY_PERIOD_OPEN = 'SET_SEARCH_VIDJET_SIMPLE_FILTER_ENQUIRY_PERIOD_OPEN';
export const SET_SEARCH_VIDJET_SIMPLE_AUCTION_PERIOD_FROM = 'SET_SEARCH_VIDJET_SIMPLE_AUCTION_PERIOD_FROM';
export const SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_AUCTION_PERIOD_FROM = 'SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_AUCTION_PERIOD_FROM';
export const SET_SEARCH_VIDJET_SIMPLE_FILTER_AUCTION_PERIOD_TO = 'SET_SEARCH_VIDJET_SIMPLE_FILTER_AUCTION_PERIOD_TO';
export const SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_AUCTION_PERIOD_TO = 'SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_AUCTION_PERIOD_TO';
export const SET_SEARCH_VIDJET_SIMPLE_FILTER_AUCTION_PERIOD_OPEN = 'SET_SEARCH_VIDJET_SIMPLE_FILTER_AUCTION_PERIOD_OPEN';
export const SET_SEARCH_VIDJET_SIMPLE_TENDER_PERIOD_FROM = 'SET_SEARCH_VIDJET_SIMPLE_TENDER_PERIOD_FROM';
export const SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_TENDER_PERIOD_FROM = 'SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_TENDER_PERIOD_FROM';
export const SET_SEARCH_VIDJET_SIMPLE_FILTER_TENDER_PERIOD_TO = 'SET_SEARCH_VIDJET_SIMPLE_FILTER_TENDER_PERIOD_TO';
export const SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_TENDER_PERIOD_TO = 'SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_TENDER_PERIOD_TO';
export const SET_SEARCH_VIDJET_SIMPLE_FILTER_TENDER_PERIOD_OPEN = 'SET_SEARCH_VIDJET_SIMPLE_FILTER_TENDER_PERIOD_OPEN';
export const SET_SEARCH_VIDJET_SIMPLE_FILTER_VALUE_SUMM_FROM = 'SET_SEARCH_VIDJET_SIMPLE_FILTER_VALUE_SUMM_FROM';
export const SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_VALUE_SUMM_FROM = 'SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_VALUE_SUMM_FROM';
export const SET_SEARCH_VIDJET_SIMPLE_FILTER_VALUE_SUMM_TO = 'SET_SEARCH_VIDJET_SIMPLE_FILTER_VALUE_SUMM_TO';
export const SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_VALUE_SUMM_TO = 'SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_VALUE_SUMM_TO';
export const SET_SEARCH_VIDJET_SIMPLE_FILTER_VALUE_SUMM_OPEN = 'SET_SEARCH_VIDJET_SIMPLE_FILTER_VALUE_SUMM_OPEN';
export const SET_SEARCH_VIDJET_SIMPLE_PLANS_TENDER_PERIOD_FROM = 'SET_SEARCH_VIDJET_SIMPLE_PLANS_TENDER_PERIOD_FROM';
export const SET_SEARCH_VIDJET_SIMPLE_PLANS_NEW_TENDER_PERIOD_FROM = 'SET_SEARCH_VIDJET_SIMPLE_PLANS_NEW_TENDER_PERIOD_FROM';
export const SET_SEARCH_VIDJET_SIMPLE_PLANS_TENDER_PERIOD_TO = 'SET_SEARCH_VIDJET_SIMPLE_PLANS_TENDER_PERIOD_TO';
export const SET_SEARCH_VIDJET_SIMPLE_PLANS_NEW_TENDER_PERIOD_TO = 'SET_SEARCH_VIDJET_SIMPLE_PLANS_NEW_TENDER_PERIOD_TO'
export const SET_SEARCH_VIDJET_SIMPLE_PLANS_TENDER_PERIOD_OPEN = 'SET_SEARCH_VIDJET_SIMPLE_PLANS_TENDER_PERIOD_OPEN'
export const SET_SEARCH_VIDJET_DJERELO_FIN_ARR = 'SET_SEARCH_VIDJET_DJERELO_FIN_ARR'
export const SET_SEARCH_VIDJET_DJERELO_FIN_ARR_NEW = 'SET_SEARCH_VIDJET_DJERELO_FIN_ARR_NEW'
export const SET_SEARCH_VIDJET_DJERELO_FIN = 'SET_SEARCH_VIDJET_DJERELO_FIN'
export const SET_SEARCH_VIDJET_ROZPORYADNUK_KOSHTIV_STRING = 'SET_SEARCH_VIDJET_ROZPORYADNUK_KOSHTIV_STRING'
export const SET_SEARCH_VIDJET_ROZPORYADNUK_KOSHTIV_ARR_GET = 'SET_SEARCH_VIDJET_ROZPORYADNUK_KOSHTIV_ARR_GET'
export const SET_SEARCH_VIDJET_ROZPORYADNUK_KOSHTIV_ARR = 'SET_SEARCH_VIDJET_ROZPORYADNUK_KOSHTIV_ARR'
export const SET_SEARCH_VIDJET_ROZPORYADNUK_KOSHTIV_ARR_NEW = 'SET_SEARCH_VIDJET_ROZPORYADNUK_KOSHTIV_ARR_NEW'
export const SET_SEARCH_VIDJET_ROZPORYADNUK_KOSHTIV = 'SET_SEARCH_VIDJET_ROZPORYADNUK_KOSHTIV'

export const SET_SEARCH_SORT_LIST_OPEN = 'SET_SEARCH_SORT_LIST_OPEN'
export const SET_SEARCH_SORT_LIST_ARRAY = 'SET_SEARCH_SORT_LIST_ARRAY'




export const CREATE_ITEMS_DGF_MINNUMBEROFQUALIFIEDBIDS = 'CREATE_ITEMS_DGF_MINNUMBEROFQUALIFIEDBIDS';
export const CREATE_ITEMS_DGF_VIRTUALDATAROOM = 'CREATE_ITEMS_DGF_VIRTUALDATAROOM';
export const CREATE_ITEMS_DGF_ELIGIBILITYCRITERIA = 'CREATE_ITEMS_DGF_ELIGIBILITYCRITERIA';
export const CREATE_ITEMS_DGF_X_DGFPLATFORMLEGALDETAILS = 'CREATE_ITEMS_DGF_X_DGFPLATFORMLEGALDETAILS';
export const CREATE_ITEMS_DGF_X_DGFDECISION_ID = 'CREATE_ITEMS_DGF_X_DGFDECISION_ID';
export const CREATE_ITEMS_DGF_X_DGFDECISION_DATE = 'CREATE_ITEMS_DGF_X_DGFDECISION_DATE';

export const SET_MY_CURRENT_PAGE = 'SET_MY_CURRENT_PAGE';
export const SET_MY_CABINET_PAGE = 'SET_MY_CABINET_PAGE';

export const SET_DICTIONARIES_OWNERSHIPTYPE = 'SET_DICTIONARIES_OWNERSHIPTYPE';
export const SET_DICTIONARIES_ENCUMBRANCES = 'SET_DICTIONARIES_ENCUMBRANCES';
export const SET_DICTIONARIES_POWERSUPPLYCLASS = 'SET_DICTIONARIES_POWERSUPPLYCLASS';
export const SET_DICTIONARIES_FLOORTYPE = 'SET_DICTIONARIES_FLOORTYPE';
export const SET_DICTIONARIES_CONSTRUCTIONTECHNOLOGY = 'SET_DICTIONARIES_CONSTRUCTIONTECHNOLOGY';
export const SET_DICTIONARIES_FUELTYPE = 'SET_DICTIONARIES_FUELTYPE';
export const SET_DICTIONARIES_TRANSMISSIONTYPE = 'SET_DICTIONARIES_TRANSMISSIONTYPE';
export const SET_DICTIONARIES_LOCATIONINBUILDING = 'SET_DICTIONARIES_LOCATIONINBUILDING';
export const SET_DICTIONARIES_ELIGIBILITYCRITERIA = 'SET_DICTIONARIES_ELIGIBILITYCRITERIA';
export const SET_DICTIONARIES_UNIT_CODE = 'SET_DICTIONARIES_UNIT_CODE';

export const CREATE_ITEMS_DGF_REALESTATEPROPS_ID = 'CREATE_ITEMS_DGF_REALESTATEPROPS_ID';
export const CREATE_ITEMS_DGF_REALESTATEPROPS_TOTALBUILDINGAREA = 'CREATE_ITEMS_DGF_REALESTATEPROPS_TOTALBUILDINGAREA';
export const CREATE_ITEMS_DGF_REALESTATEPROPS_TOTALOBJECTAREA = 'CREATE_ITEMS_DGF_REALESTATEPROPS_TOTALOBJECTAREA';
export const CREATE_ITEMS_DGF_REALESTATEPROPS_USABLEAREA = 'CREATE_ITEMS_DGF_REALESTATEPROPS_USABLEAREA';
export const CREATE_ITEMS_DGF_REALESTATEPROPS_CONSTRUCTIONYEAR = 'CREATE_ITEMS_DGF_REALESTATEPROPS_CONSTRUCTIONYEAR';
export const CREATE_ITEMS_DGF_REALESTATEPROPS_CONSTRUCTIONTECHNOLOGY = 'CREATE_ITEMS_DGF_REALESTATEPROPS_CONSTRUCTIONTECHNOLOGY';
export const CREATE_ITEMS_DGF_REALESTATEPROPS_LIVINGAREA = 'CREATE_ITEMS_DGF_REALESTATEPROPS_LIVINGAREA';
export const CREATE_ITEMS_DGF_REALESTATEPROPS_KITCHENAREA = 'CREATE_ITEMS_DGF_REALESTATEPROPS_KITCHENAREA';
export const CREATE_ITEMS_DGF_REALESTATEPROPS_LANDAREA = 'CREATE_ITEMS_DGF_REALESTATEPROPS_LANDAREA';
export const CREATE_ITEMS_DGF_REALESTATEPROPS_LOCATIONINBUILDING = 'CREATE_ITEMS_DGF_REALESTATEPROPS_LOCATIONINBUILDING';
export const CREATE_ITEMS_DGF_REALESTATEPROPS_FLOORS = 'CREATE_ITEMS_DGF_REALESTATEPROPS_FLOORS';
export const CREATE_ITEMS_DGF_REALESTATEPROPS_GENERALCONDITION = 'CREATE_ITEMS_DGF_REALESTATEPROPS_GENERALCONDITION';
export const CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEELECTRICITY = 'CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEELECTRICITY';
export const CREATE_ITEMS_DGF_REALESTATEPROPS_POWERSUPPLYCAPACITY = 'CREATE_ITEMS_DGF_REALESTATEPROPS_POWERSUPPLYCAPACITY';
export const CREATE_ITEMS_DGF_REALESTATEPROPS_POWERSUPPLYCLASS = 'CREATE_ITEMS_DGF_REALESTATEPROPS_POWERSUPPLYCLASS';
export const CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEWATER = 'CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEWATER';
export const CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICESEWERAGE = 'CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICESEWERAGE';
export const CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEGAS = 'CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEGAS';
export const CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICECENTRALHEATING = 'CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICECENTRALHEATING';
export const CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEAUTONOMOUSHEATING = 'CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEAUTONOMOUSHEATING';
export const CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEHEATINGCOUNTER = 'CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEHEATINGCOUNTER';
export const CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEVENTILATION = 'CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEVENTILATION';
export const CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEAIRCONDITIONING = 'CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEAIRCONDITIONING';
export const CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEPHONE = 'CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEPHONE';
export const CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICETV = 'CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICETV';
export const CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEINTERNET = 'CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEINTERNET';
export const CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEELEVATOR = 'CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEELEVATOR';
export const CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICESECURITYALARM = 'CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICESECURITYALARM';
export const CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEFIREALARM = 'CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEFIREALARM';
export const CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICESDESCRIPTION = 'CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICESDESCRIPTION';
export const CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICESACCOUNTING = 'CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICESACCOUNTING';
export const CREATE_ITEMS_DGF_REALESTATEPROPS_LANDTAX = 'CREATE_ITEMS_DGF_REALESTATEPROPS_LANDTAX';

export const CREATE_ITEMS_DGF_VEHICLEPROPS_ID = 'CREATE_ITEMS_DGF_VEHICLEPROPS_ID';
export const CREATE_ITEMS_DGF_VEHICLEPROPS_BRAND = 'CREATE_ITEMS_DGF_VEHICLEPROPS_BRAND';
export const CREATE_ITEMS_DGF_VEHICLEPROPS_MODEL = 'CREATE_ITEMS_DGF_VEHICLEPROPS_MODEL';
export const CREATE_ITEMS_DGF_VEHICLEPROPS_PRODUCTIONYEAR = 'CREATE_ITEMS_DGF_VEHICLEPROPS_PRODUCTIONYEAR';
export const CREATE_ITEMS_DGF_VEHICLEPROPS_ENGINECAPACITY = 'CREATE_ITEMS_DGF_VEHICLEPROPS_ENGINECAPACITY';
export const CREATE_ITEMS_DGF_VEHICLEPROPS_FUELTYPE = 'CREATE_ITEMS_DGF_VEHICLEPROPS_FUELTYPE';
export const CREATE_ITEMS_DGF_VEHICLEPROPS_TRANSMISSION = 'CREATE_ITEMS_DGF_VEHICLEPROPS_TRANSMISSION';
export const CREATE_ITEMS_DGF_VEHICLEPROPS_COLOR = 'CREATE_ITEMS_DGF_VEHICLEPROPS_COLOR';
export const CREATE_ITEMS_DGF_VEHICLEPROPS_KILOMETRAGE = 'CREATE_ITEMS_DGF_VEHICLEPROPS_KILOMETRAGE';
export const CREATE_ITEMS_DGF_VEHICLEPROPS_IDENTIFICATIONNUMBER = 'CREATE_ITEMS_DGF_VEHICLEPROPS_IDENTIFICATIONNUMBER';
export const CREATE_ITEMS_DGF_VEHICLEPROPS_CONFIGURATION = 'CREATE_ITEMS_DGF_VEHICLEPROPS_CONFIGURATION';
export const CREATE_ITEMS_DGF_VEHICLEPROPS_CONDITION = 'CREATE_ITEMS_DGF_VEHICLEPROPS_CONDITION';
export const CREATE_ITEMS_DGF_VEHICLEPROPS_DAMAGEPRESENCE = 'CREATE_ITEMS_DGF_VEHICLEPROPS_DAMAGEPRESENCE';
export const CREATE_ITEMS_DGF_VEHICLEPROPS_DAMAGEDDESCRIPTION = 'CREATE_ITEMS_DGF_VEHICLEPROPS_DAMAGEDDESCRIPTION';

export const CREATE_ITEMS_DGF_LANDPROPS_ID = 'CREATE_ITEMS_DGF_LANDPROPS_ID';
export const CREATE_ITEMS_DGF_LANDPROPS_LANDAREA = 'CREATE_ITEMS_DGF_LANDPROPS_LANDAREA';
export const CREATE_ITEMS_DGF_LANDPROPS_CADASTRALNUMBER = 'CREATE_ITEMS_DGF_LANDPROPS_CADASTRALNUMBER';
export const CREATE_ITEMS_DGF_LANDPROPS_OWNERSHIPTYPE = 'CREATE_ITEMS_DGF_LANDPROPS_OWNERSHIPTYPE';
export const CREATE_ITEMS_DGF_LANDPROPS_ENCUMBRANCES = 'CREATE_ITEMS_DGF_LANDPROPS_ENCUMBRANCES';
export const CREATE_ITEMS_DGF_LANDPROPS_JOINTOWNERSHIP = 'CREATE_ITEMS_DGF_LANDPROPS_JOINTOWNERSHIP';
export const CREATE_ITEMS_DGF_LANDPROPS_UTILITIESAVAILABILITY = 'CREATE_ITEMS_DGF_LANDPROPS_UTILITIESAVAILABILITY';
export const CREATE_ITEMS_DGF_LANDPROPS_INTENDEDUSE = 'CREATE_ITEMS_DGF_LANDPROPS_INTENDEDUSE';

export const CREATE_ITEMS_DGF_JOINTPROPERTYCOMPLEXPROPS_ID = 'CREATE_ITEMS_DGF_JOINTPROPERTYCOMPLEXPROPS_ID';
export const CREATE_ITEMS_DGF_JOINTPROPERTYCOMPLEXPROPS_QUANTITYANDNOMENCLATURE = 'CREATE_ITEMS_DGF_JOINTPROPERTYCOMPLEXPROPS_QUANTITYANDNOMENCLATURE';
export const CREATE_ITEMS_DGF_JOINTPROPERTYCOMPLEXPROPS_WORKPLACESQUANTITY = 'CREATE_ITEMS_DGF_JOINTPROPERTYCOMPLEXPROPS_WORKPLACESQUANTITY';
export const CREATE_ITEMS_DGF_JOINTPROPERTYCOMPLEXPROPS_BUILDINGSINFORMATION = 'CREATE_ITEMS_DGF_JOINTPROPERTYCOMPLEXPROPS_BUILDINGSINFORMATION';
export const CREATE_ITEMS_DGF_JOINTPROPERTYCOMPLEXPROPS_LANDPLOTINFORMATION = 'CREATE_ITEMS_DGF_JOINTPROPERTYCOMPLEXPROPS_LANDPLOTINFORMATION';
export const CREATE_ITEMS_DGF_JOINTPROPERTYCOMPLEXPROPS_MAINOBLIGATIONS = 'CREATE_ITEMS_DGF_JOINTPROPERTYCOMPLEXPROPS_MAINOBLIGATIONS';
export const CREATE_ITEMS_DGF_JOINTPROPERTYCOMPLEXPROPS_LEASEAPPROVERWEBSITE = 'CREATE_ITEMS_DGF_JOINTPROPERTYCOMPLEXPROPS_LEASEAPPROVERWEBSITE';

export const CREATE_ITEMS_LOCATION_LATITUDE = 'CREATE_ITEMS_LOCATION_LATITUDE';
export const CREATE_ITEMS_LOCATION_LONGITUDE = 'CREATE_ITEMS_LOCATION_LONGITUDE';
export const CREATE_ITEMS_LOCATION_ELEVATION = 'CREATE_ITEMS_LOCATION_ELEVATION';

export const SET_DGF_MASK = 'SET_DGF_MASK';

export const SET_CLASSIFICATIONS_BY_TYPE_DGF_ADD = 'SET_CLASSIFICATIONS_BY_TYPE_DGF_ADD';

export const OPEN_BANK_WINDOW = 'OPEN_BANK_WINDOW';

export const SET_ACCORDIONS_BIDS = 'SET_ACCORDIONS_BIDS';
export const SET_ACCORDIONS_AWARDS = 'SET_ACCORDIONS_AWARDS';
export const SET_ACCORDIONS_CONTRACTS = 'SET_ACCORDIONS_CONTRACTS';
export const SET_ACCORDIONS_CHANCEL = 'SET_ACCORDIONS_CHANCEL';

export const SAVE_HISTORY_DOCS_CONTRACT = 'SAVE_HISTORY_DOCS_CONTRACT';

export const SET_SINGL_PASSWORD = 'SET_SINGL_PASSWORD';

export const SET_CONTRACT_DATE_PAID = 'SET_CONTRACT_DATE_PAID';

export const WORK_BANK_ACCOUNT_SAVE = 'WORK_BANK_ACCOUNT_SAVE';
export const WORK_BANK_ACCOUNT_ADD = 'WORK_BANK_ACCOUNT_ADD';
export const WORK_BANK_ACCOUNT_DELETE = 'WORK_BANK_ACCOUNT_DELETE';


export const PAYMENT_POP_UP_SET_BANK = 'PAYMENT_POP_UP_SET_BANK';

export const SET_NADRA_CLASSIFICATOR_ADD = 'SET_NADRA_CLASSIFICATOR_ADD';

export const PAYMENT_POP_UP_GET_DATA = 'PAYMENT_POP_UP_GET_DATA';
export const PAYMENT_POP_UP = 'PAYMENT_POP_UP';
export const PAYMENT_POP_UP_CREATE_PDF = 'PAYMENT_POP_UP_CREATE_PDF';

export const ALL_AUCTION_BIDS_ARR = 'ALL_AUCTION_BIDS_ARR';
export const ALL_AUCTION_BIDS_AUCTION_ID = 'ALL_AUCTION_BIDS_AUCTION_ID';

export const CHECKBOX_BID_PUBLISH = 'CHECKBOX_BID_PUBLISH';

export const POPUP_EDIT_APPROVE_DOC = 'POPUP_EDIT_APPROVE_DOC';
export const POPUP_EDIT_APPROVE_DOC_TITLE = 'POPUP_EDIT_APPROVE_DOC_TITLE';
export const POPUP_EDIT_APPROVE_DOC_DISCRIP = 'POPUP_EDIT_APPROVE_DOC_DISCRIP';
export const POPUP_EDIT_APPROVE_DOC_FILES = 'POPUP_EDIT_APPROVE_DOC_FILES';

export const TOGETHER_API_PAGE_AUCTION_END_PAY = 'TOGETHER_API_PAGE_AUCTION_END_PAY';

export const WORK_MY_PAGE_CURRENT_AUCTION = 'WORK_MY_PAGE_CURRENT_AUCTION';
export const WORK_MY__API_PAGE_NEXT_AUCTION = 'WORK_MY__API_PAGE_NEXT_AUCTION';
export const TOGETHER_API_PAGE_CURRENT_AUCTION = 'TOGETHER_API_PAGE_CURRENT_AUCTION';
export const TOGETHER_API_PAGE_NEXT_AUCTION = 'TOGETHER_API_PAGE_NEXT_AUCTION';

export const EDIT_AUCTION_FROM_ID = 'EDIT_AUCTION_FROM_ID';
export const POP_UP_ADDRESS_ID = 'POP_UP_ADDRESS_ID';

export const SET_POP_UP_BANK_ACCOUNT_EDIT_ID = 'SET_POP_UP_BANK_ACCOUNT_EDIT_ID';

export const CREATE_AUCTION_RAILWAY_DUTCH_STEP_QUANTITY = 'CREATE_AUCTION_RAILWAY_DUTCH_STEP_QUANTITY';
export const CREATE_AUCTION_RAILWAY_DUTCH_STEP_PERCENT = 'CREATE_AUCTION_RAILWAY_DUTCH_STEP_PERCENT';
export const CREATE_AUCTION_RAILWAY_DUTCH_STEP_VALUE_AMOUNT = 'CREATE_AUCTION_RAILWAY_DUTCH_STEP_VALUE_AMOUNT';

export const SET_END_POINT = 'SET_END_POINT';
export const DISCOUNT_ON_OFF = 'DISCOUNT_ON_OFF';

export const AWARD_DOCUMENT_STATUS = 'AWARD_DOCUMENT_STATUS';

export const HISTERY_AUCTIONS = 'HISTERY_AUCTIONS';
export const HISTERY_AWARDS = 'HISTERY_AWARDS';
export const HISTERY_CONTRACTS = 'HISTERY_CONTRACTS';
export const HISTERY_BIDS = 'HISTERY_BIDS';

export const PROXY_TIMBER_SPECIES = 'PROXY_TIMBER_SPECIES';
export const PROXY_TIMBER_SORTMENT = 'PROXY_TIMBER_SORTMENT';
export const PROXY_TIMBER_STORAGE = 'PROXY_TIMBER_STORAGE';
export const PROXY_TIMBER_DIAMETER = 'PROXY_TIMBER_DIAMETER';
export const PROXY_TIMBER_CLASS = 'PROXY_TIMBER_CLASS';


export const ECP_API_PASS = 'ECP_API_PASS';
export const TOGETHER_API_AUCTION_NULL = 'TOGETHER_API_AUCTION_NULL';
export const TOGETHER_API_LIST = 'TOGETHER_API_LIST';
export const TOGETHER_API_LIST_CA = 'TOGETHER_API_LIST_CA';

export const TOGETHER_API_PAGE_SWITCH = 'TOGETHER_API_PAGE_SWITCH';
export const TOGETHER_API_BIDS_ARR = 'TOGETHER_API_BIDS_ARR';
export const TOGETHER_API_POPUP_ONOFF = 'TOGETHER_API_POPUP_ONOFF';
export const TOGETHER_API_DOC_TITLE = 'TOGETHER_API_DOC_TITLE';
export const TOGETHER_API_DOC_DESCRIPTION = 'TOGETHER_API_DOC_DESCRIPTION';
export const TOGETHER_API_DOC_TYPE = 'TOGETHER_API_DOC_TYPE';
export const TOGETHER_API_DOC_LANG = 'TOGETHER_API_DOC_LANG';
export const TOGETHER_API_DOC_FILENAME = 'TOGETHER_API_DOC_FILENAME';
export const TOGETHER_API_DOC_FILENAME_ECP = 'TOGETHER_API_DOC_FILENAME_ECP';
export const TOGETHER_API_ECP = 'TOGETHER_API_ECP';
export const TOGETHER_API_DRAFT_BID_SUMM = 'TOGETHER_API_DRAFT_BID_SUMM';
export const TOGETHER_API_BIDS_WORK_ARR = 'TOGETHER_API_BIDS_WORK_ARR';
export const TOGETHER_API_PULL_AUC_EI = 'TOGETHER_API_PULL_AUC_EI';
export const TOGETHER_API_BASE_PULL_AUC_EI = 'TOGETHER_API_BASE_PULL_AUC_EI';
export const TOGETHER_API_SAVE_DATA_LOAD = 'TOGETHER_API_SAVE_DATA_LOAD';
export const TOGETHER_API_SAVE_VALUE = 'TOGETHER_API_SAVE_VALUE';

export const TOGETHER_API_TIMER_ROUND = 'TOGETHER_API_TIMER_ROUND';
export const TOGETHER_API_TIMER_TIME = 'TOGETHER_API_TIMER_TIME'
export const TOGETHER_API_TIMER_TIMELEFT = 'TOGETHER_API_TIMER_TIMELEFT'

export const SET_MIN_NUMBER_OF_QUALIFIED_BIDS = 'SET_MIN_NUMBER_OF_QUALIFIED_BIDS';

export const POPUP_CONTRACT_DOC_TITLE = 'POPUP_CONTRACT_DOC_TITLE';
export const POPUP_CONTRACT_DOC_TYPE = 'POPUP_CONTRACT_DOC_TYPE';
export const POPUP_CONTRACT_DOC_DESCRIPTION = 'POPUP_CONTRACT_DOC_DESCRIPTION';

export const POPUP_DATE_DELETE_AUCTION = 'POPUP_DATE_DELETE_AUCTION';
export const UNIVERSAL_ERROR = 'UNIVERSAL_ERROR';

export const ECP_POPUP = 'ECP_POPUP';
export const ECP_LINK = 'ECP_LINK';

export const POPUP_AUCTION_DELETE_TITLE = 'POPUP_AUCTION_DELETE_TITLE';

export const BID_POPUP_EDIT_DOC = 'BID_POPUP_EDIT_DOC';
export const BID_POPUP_EDIT_DOC_TITLE = 'BID_POPUP_EDIT_DOC_TITLE';
export const BID_POPUP_EDIT_DOC_DESCRIPTION = 'BID_POPUP_EDIT_DOC_DESCRIPTION';
export const BID_POPUP_EDIT_DOC_TYPE = 'BID_POPUP_EDIT_DOC_TYPE';

export const KOATUU_REGION = 'KOATUU_REGION';
export const KOATUU_GOROD = 'KOATUU_GOROD';

export const BID_POPUP_HELP_PUBLISH = 'BID_POPUP_HELP_PUBLISH';

export const CHANGE_GEOLOGIV_DATA_VALUE = 'CHANGE_GEOLOGIV_DATA_VALUE';
export const CHANGE_DOCUMENTATION_VALUE = 'CHANGE_DOCUMENTATION_VALUE';
export const CHANGE_DATE_USES = 'CHANGE_DATE_USES';

export const CHANGE_LOADER = 'CHANGE_LOADER';

export const STREET_ADDRESS = 'STREET_ADDRESS';
export const LOCALITY_ADDRESS = 'LOCALITY_ADDRESS';
export const REGION_ADDRESS = 'REGION_ADDRESS';
export const POSTAL_CODE_ADDRESS = 'POSTAL_CODE_ADDRESS';
export const COUNTRY_NAME_ADDRESS = 'COUNTRY_NAME_ADDRESS';
export const POP_UP_ADDRESS = 'POP_UP_ADDRESS';
export const SET_POP_UP_ADDRESS_ERROR = 'SET_POP_UP_ADDRESS_ERROR';
export const SET_POP_UP_ADDRESS_COMPLITE = 'SET_POP_UP_ADDRESS_COMPLITE';

export const SET_AWARD_REJECT_TITLE = 'SET_AWARD_REJECT_TITLE';
export const SET_AWARD_REJECT_DESCRIPTION = 'SET_AWARD_REJECT_DESCRIPTION';
export const AWARD_DOCUMENT_RESTRICT = 'AWARD_DOCUMENT_RESTRICT';
export const SET_AWARD_REJECT_DOCUMENT_TYPE = 'SET_AWARD_REJECT_DOCUMENT_TYPE';

export const SET_KEY_INTERVAL = 'SET_KEY_INTERVAL';
export const SET_LOGO = 'SET_LOGO';
export const SET_POPUP_LOGIN = 'SET_POPUP_LOGIN';
export const SET_SEARCH_TEXT_STRING = 'SET_SEARCH_TEXT_STRING';
export const SET_SEARCH_TYPE_KEYWORD = 'SET_SEARCH_TYPE_KEYWORD';
export const SET_SEARCH_DATE_START = 'SET_SEARCH_DATE_START';
export const SET_SEARCH_DATE_END = 'SET_SEARCH_DATE_END';
export const SET_SEARCH_SORT = 'SET_SEARCH_SORT';
export const AUCTIONS_GET_SEND = 'AUCTIONS_GET_SEND';

export const SET_AUCTIONS_PATH = 'SET_AUCTIONS_PATH';
export const SET_CATEGORY_AUCTION = 'SET_CATEGORY_AUCTION';
export const SET_PRICE_FROM = 'SET_PRICE_FROM';
export const SET_PRICE_FROM_BAG = 'SET_PRICE_FROM_BAG';
export const SET_PRICE_TO = 'SET_PRICE_TO';
export const SET_PRICE_TO_BAG = 'SET_PRICE_TO_BAG';

export const DOCUMENT_COUNNT_ARR_FILES = 'DOCUMENT_COUNNT_ARR_FILES';
export const DOCUMENT_COUNNT_FILES = 'DOCUMENT_COUNNT_FILES';

export const DOCUMENT_BID_BLOCK_STATUS = 'DOCUMENT_BID_BLOCK_STATUS';
export const DOCUMENT_BID_PUBLIC_NAME = 'DOCUMENT_BID_PUBLIC_NAME';

export const CHANGE_MENU = 'CHANGE_MENU';
export const CHANGE_WINDOW = 'CHANGE_WINDOW';
export const CHANGE_LANG = 'CHANGE_LANG';
export const CHANGE_ADVANCED_FIND = 'CHANGE_ADVANCED_FIND';
export const CHANGE_MENU_ON_OFF = 'CHANGE_MENU_ON_OFF';
export const BID_AWARDS = 'BID_AWARDS';
export const BID_QUANTITY = 'BID_QUANTITY';
export const BID_POPUP_EDIT = 'BID_POPUP_EDIT';

export const AUC_TEST = 'AUC_TEST';


export const CREATE_AUCTION_TAB = 'CREATE_AUCTION_TAB';
//Создание аукциона
export const CREATE_CHANGET_AUCTION = 'CREATE_CHANGET_AUCTION';
export const CREATE_AUCTION_TITLE = 'CREATE_AUCTION_TITLE';
export const CREATE_AUCTION_DESCRIPTION = 'CREATE_AUCTION_DESCRIPTION';
export const CREATE_AUCTION_TYPE = 'CREATE_AUCTION_TYPE';
export const CREATE_AUCTION_TENDER_ATTEMPTS = 'CREATE_AUCTION_TENDER_ATTEMPTS';
export const CREATE_AUCTION_ACCESS_DETAILS = 'CREATE_AUCTION_ACCESS_DETAILS';
export const CREATE_AUCTION_XDOCUMENT_REQUIREMENTS = 'CREATE_AUCTION_XDOCUMENT_REQUIREMENTS';
export const CREATE_AUCTION_XADDITIONAL_INFORMATION = 'CREATE_AUCTION_XADDITIONAL_INFORMATION';
export const CREATE_AUCTION_VALUE_CURRENCY = 'CREATE_AUCTION_VALUE_CURRENCY';
export const CREATE_AUCTION_VALUE_AMOUNT = 'CREATE_AUCTION_VALUE_AMOUNT';
export const CREATE_AUCTION_VALUE_TAX = 'CREATE_AUCTION_VALUE_TAX';
export const CREATE_AUCTION_GUARANTEE_CURRENCY = 'CREATE_AUCTION_GUARANTEE_CURRENCY';
export const CREATE_AUCTION_GUARANTEE_AMOUNT = 'CREATE_AUCTION_GUARANTEE_AMOUNT';
export const CREATE_AUCTION_GUARANTEE_TAX = 'CREATE_AUCTION_GUARANTEE_TAX';
export const CREATE_AUCTION_MINIMAL_STEP_CURRENCY = 'CREATE_AUCTION_MINIMAL_STEP_CURRENCY';
export const CREATE_AUCTION_MINIMAL_STEP_AMOUNT = 'CREATE_AUCTION_MINIMAL_STEP_AMOUNT';
export const CREATE_AUCTION_MINIMAL_STEP_TAX = 'CREATE_AUCTION_MINIMAL_STEP_TAX';
export const CREATE_AUCTION_DATE_START = 'CREATE_AUCTION_DATE_START';
export const CREATE_AUCTION_DATE_END = 'CREATE_AUCTION_DATE_END';
export const CREATE_AUCTION_ID = 'CREATE_AUCTION_ID';

export const CREATE_DOCUMENT_FILE_NAME = 'CREATE_DOCUMENT_FILE_NAME';

export const CREATE_ITEMS_AUCTION_ID = 'CREATE_ITEMS_AUCTION_ID';
export const CREATE_ITEMS_CLASSIFICATION_ID = 'CREATE_ITEMS_CLASSIFICATION_ID';
export const CREATE_ITEMS_UNIT_VALUE_CURRENCY = 'CREATE_ITEMS_UNIT_VALUE_CURRENCY';
export const CREATE_ITEMS_UNIT_VALUE_AMOUNT = 'CREATE_ITEMS_UNIT_VALUE_AMOUNT';
export const CREATE_ITEMS_UNIT_VALUE_TAX = 'CREATE_ITEMS_UNIT_VALUE_TAX';
export const CREATE_ITEMS_DESCRIPTION = 'CREATE_ITEMS_DESCRIPTION';

export const NUMBER_PAGE_LOT = 'NUMBER_PAGE_LOT';

export const SET_POP_UP_SEND_AUCTION = 'SET_POP_UP_SEND_AUCTION';
export const SET_POP_UP_DEL_AUCTION = 'SET_POP_UP_DEL_AUCTION';
export const AUCTIONS_GET_MY_ID = 'AUCTIONS_GET_MY_ID';

//Address
export const ADDRESS_GET = 'ADDRESS_GET';
export const ADDRESS_GET_MY = 'ADDRESS_GET_MY';
export const ADDRESS_POST = 'ADDRESS_POST';
export const ADDRESS_PUT = 'ADDRESS_PUT';
export const ADDRESS_DELETE = 'ADDRESS_DELETE';



export const AUCTIONS_POST = 'AUCTIONS_POST';

export const AUCTIONS_PUT = 'AUCTIONS_PUT';
export const AUCTIONS_DELETE = 'AUCTIONS_DELETE';
export const AUCTIONS_SEARCH = 'AUCTIONS_SEARCH';
export const AUCTIONS_SEARCH_OLD = 'AUCTIONS_SEARCH_OLD';
export const AUCTIONS_SEARCH_MIN = 'AUCTIONS_SEARCH_MIN';
export const AUCTIONS_SEARCH_COUNT = 'AUCTIONS_SEARCH_COUNT';
export const AUCTIONS_SEARCH_OLD_COUNT = 'AUCTIONS_SEARCH_OLD_COUNT';
export const AUCTIONS_GET_MY = 'AUCTIONS_GET_MY';
export const AUCTIONS_SWITCH_WINDOW = 'AUCTIONS_SWITCH_WINDOW';
export const AUCTIONS_ITEMS_SWITCH_WINDOW = 'AUCTIONS_ITEMS_SWITCH_WINDOW';
export const AUCTIONS_CURENT_PAGE = 'AUCTIONS_CURENT_PAGE';
export const AUCTIONS_STATUS = 'AUCTIONS_STATUS';
export const SET_POP_UP_ERROR_AUCTION = 'SET_POP_UP_ERROR_AUCTION';
export const SET_DELETE_AUCTION_DESCRIPTION = 'SET_DELETE_AUCTION_DESCRIPTION';
export const SET_POP_UP_ANSWER_AUCTION = 'SET_POP_UP_ANSWER_AUCTION';
export const CHANGE_POP_UP_AUCTION_CREATE = 'CHANGE_POP_UP_AUCTION_CREATE';

//Auction Items
export const AUCTION_ITEMS_GET = 'AUCTION_ITEMS_GET';
export const AUCTION_ID_ITEMS_GET = 'AUCTION_ID_ITEMS_GET';
export const AUCTION_ITEMS_POST = 'AUCTION_ITEMS_POST';
export const AUCTION_ITEMS_PUT = 'AUCTION_ITEMS_PUT';
export const AUCTION_ITEMS_DELETE = 'AUCTION_ITEMS_DELETE';
export const CREATE_ITEMS_ADDITIONAL_CLASSIFICATION_IDS = 'CREATE_ITEMS_ADDITIONAL_CLASSIFICATION_IDS';
export const CREATE_ITEMS_QUANTITY = 'CREATE_ITEMS_QUANTITY';
export const CREATE_ITEMS_IDS = 'CREATE_ITEMS_IDS';
export const CREATE_ITEMS_ADDRESS = 'CREATE_ITEMS_ADDRESS';

//Awards
export const SET_AWARDS = 'SET_AWARDS';
export const SET_AWARD_ID = 'SET_AWARD_ID';
export const SET_POPUP_AWARD_REJECT = 'SET_POPUP_AWARD_REJECT';
export const SET_POPUP_AWARD_ERROR = 'SET_POPUP_AWARD_ERROR';
export const SET_POPUP_AWARD_APPROWE = 'SET_POPUP_AWARD_APPROWE';
export const AWARD_POPUP_VIEW = 'AWARD_POPUP_VIEW';

//Auction Bid's
export const AUCTION_BID_GET = 'AUCTION_BID_GET';
export const AUCTION_ID_BID_GET = 'AUCTION_ID_BID_GET';
export const AUCTION_BID_POST = 'AUCTION_BID_POST';
export const AUCTION_BID_PUT = 'AUCTION_BID_PUT';
export const AUCTION_BID_DELETE = 'AUCTION_BID_DELETE';

export const SET_MY_BIDS = 'SET_MY_BIDS';
export const SET_CURENT_BID = 'SET_CURENT_BID';
export const SET_CURENT_BID_DOC_CVALIF = 'SET_CURENT_BID_DOC_CVALIF';
export const SET_AUCTION_ID_BID = 'SET_AUCTION_ID_BID';

export const SET_CREATE_BID_ERROR = 'SET_CREATE_BID_ERROR';

export const SET_GET_BANK_ACCOUNT = 'SET_GET_BANK_ACCOUNT';
export const SET_POP_UP_BANK_ACCOUNT = 'SET_POP_UP_BANK_ACCOUNT';
export const SET_POP_UP_BANK_ACCOUNT_COMPLITE = 'SET_POP_UP_BANK_ACCOUNT_COMPLITE';
export const SET_POP_UP_BANK_ACCOUNT_ERROR = 'SET_POP_UP_BANK_ACCOUNT_ERROR';
export const SET_POP_UP_BANK_ACCOUNT_SHEME = 'SET_POP_UP_BANK_ACCOUNT_SHEME';

export const SET_POP_UP_BANK_ACCOUNT_OPUS = 'SET_POP_UP_BANK_ACCOUNT_OPUS';
export const SET_POP_UP_BANK_ACCOUNT_NAZVA_BANK = 'SET_POP_UP_BANK_ACCOUNT_NAZVA_BANK';
export const SET_POP_UP_BANK_ACCOUNT_NAZVA = 'SET_POP_UP_BANK_ACCOUNT_NAZVA';
export const SET_POP_UP_BANK_ACCOUNT_IDENTIF = 'SET_POP_UP_BANK_ACCOUNT_IDENTIF';
export const SET_POP_UP_BANK_ACCOUNT_SHEME_ID = 'SET_POP_UP_BANK_ACCOUNT_SHEME_ID';
export const SET_POP_UP_BANK_ACCOUNT_ID = 'SET_POP_UP_BANK_ACCOUNT_ID';
export const SET_POP_UP_BANK_ACCOUNT_ID_ARR = 'SET_POP_UP_BANK_ACCOUNT_ID_ARR';

//Classifications
export const CLASSIFICATION_GET = 'CLASSIFICATION_GET';
export const CLASSIFICATIONS_GET = 'CLASSIFICATIONS_GET';
export const CLASSIFICATION_POST = 'CLASSIFICATIONS_POST';
export const CLASSIFICATION_PUT = 'CLASSIFICATIONS_PUT';
export const CLASSIFICATION_DELETE = 'CLASSIFICATIONS_DELETE';
export const CLASSIFICATIONS_BY_SCHEME_GET = 'CLASSIFICATIONS_BY_SCHEME_GET';

export const SET_CLASSIFICATIONS_BY_TYPE = 'SET_CLASSIFICATIONS_BY_TYPE';
export const SET_CLASSIFICATIONS_BY_TIMBER_SORTMENT = 'SET_CLASSIFICATIONS_BY_TIMBER_SORTMENT';
export const SET_CLASSIFICATIONS_BY_TYPE_ENERGY = 'SET_CLASSIFICATIONS_BY_TYPE_ENERGY';
export const SET_CLASSIFICATIONS_BY_ENERGY_GENERATION_TYPE = 'SET_CLASSIFICATIONS_BY_ENERGY_GENERATION_TYPE';
export const CHANGE_CLASSIFICATIONS_BY_TIMBER_SORTMENT = 'CHANGE_CLASSIFICATIONS_BY_TIMBER_SORTMENT';
export const SET_CLASSIFICATIONS_BY_TIMBER_SPECIES = 'SET_CLASSIFICATIONS_BY_TIMBER_SPECIES';
export const CHANGE_CLASSIFICATIONS_BY_TIMBER_SPECIES = 'CHANGE_CLASSIFICATIONS_BY_TIMBER_SPECIES';
export const SET_CLASSIFICATIONS_BY_TIMBER_CLASS = 'SET_CLASSIFICATIONS_BY_TIMBER_CLASS';
export const CHANGE_CLASSIFICATIONS_BY_TIMBER_CLASS = 'CHANGE_CLASSIFICATIONS_BY_TIMBER_CLASS';
export const SET_CLASSIFICATIONS_BY_TIMBER_DIAMETER = 'SET_CLASSIFICATIONS_BY_TIMBER_DIAMETER';
export const CHANGE_CLASSIFICATIONS_BY_TIMBER_DIAMETER = 'CHANGE_CLASSIFICATIONS_BY_TIMBER_DIAMETER';
export const SET_CLASSIFICATIONS_BY_TIMBER_LENGTH = 'SET_CLASSIFICATIONS_BY_TIMBER_LENGTH';
export const CHANGE_CLASSIFICATIONS_BY_TIMBER_LENGTH1 = 'CHANGE_CLASSIFICATIONS_BY_TIMBER_LENGTH1';
export const CHANGE_CLASSIFICATIONS_BY_TIMBER_LENGTH2 = 'CHANGE_CLASSIFICATIONS_BY_TIMBER_LENGTH2';
export const SET_CLASSIFICATIONS_BY_TIMBER_STORAGE = 'SET_CLASSIFICATIONS_BY_TIMBER_STORAGE';
export const CHANGE_CLASSIFICATIONS_BY_TIMBER_STORAGE = 'CHANGE_CLASSIFICATIONS_BY_TIMBER_STORAGE';
export const SET_CLASSIFICATIONS_BY_TIMBER_PRODUCTION_YEAR = 'SET_CLASSIFICATIONS_BY_TIMBER_PRODUCTION_YEAR';
export const CHANGE_CLASSIFICATIONS_BY_TIMBER_PRODUCTION_YEAR = 'CHANGE_CLASSIFICATIONS_BY_TIMBER_PRODUCTION_YEAR';
export const SET_CLASSIFICATIONS_BY_TIMBER_PRODUCTION_QUARTER = 'SET_CLASSIFICATIONS_BY_TIMBER_PRODUCTION_QUARTER';
export const CHANGE_CLASSIFICATIONS_BY_TIMBER_PRODUCTION_QUARTER = 'CHANGE_CLASSIFICATIONS_BY_TIMBER_PRODUCTION_QUARTER';
export const CHANGE_CLASSIFICATIONS_BY_UNIT_CODE = 'CHANGE_CLASSIFICATIONS_BY_UNIT_CODE';
export const CHANGE_UNIT_CODE = 'CHANGE_UNIT_CODE';
export const CHANGE_CLASSIFICATIONS_BY_GENERATION_TYPE = 'CHANGE_CLASSIFICATIONS_BY_GENERATION_TYPE';
export const CREATE_AUCTION_RAILWAY_LOT_TYPE = 'CREATE_AUCTION_RAILWAY_LOT_TYPE';

export const CREATE_AUCTION_LOT_ID = 'CREATE_AUCTION_LOT_ID';
export const SET_CLASSIFICATIONS_BY_CARRY_TYPE = 'SET_CLASSIFICATIONS_BY_CARRY_TYPE';

export const SET_CARRY_TYPE = 'SET_CARRY_TYPE';
export const SET_WAGON_SPECIES = 'SET_WAGON_SPECIES';
export const SET_LOADING_RESTRICTION = 'SET_LOADING_RESTRICTION';
export const SET_WAGON_TYPES = 'SET_WAGON_TYPES';
export const SET_DATE_FROM_1 = 'SET_DATE_FROM_1';
export const SET_DATE_TILL_1 = 'SET_DATE_TILL_1';
export const SET_DATE_FROM_2 = 'SET_DATE_FROM_2';
export const SET_DATE_TILL_2 = 'SET_DATE_TILL_2';
export const SET_ROUTE_QUANTITY = 'SET_ROUTE_QUANTITY';
export const SET_ROUTE_ACCOUNTING_ROUTE_NUMBER = 'SET_ROUTE_ACCOUNTING_ROUTE_NUMBER';
export const SET_ROUTE_ACCOUNTING_WAGONS_PER_ROUTE_QUANTITY = 'SET_ROUTE_ACCOUNTING_WAGONS_PER_ROUTE_QUANTITY';
export const SET_CLASSIFICATIONS_BY_TYPE_RAILWAY_CARGO_ADD = 'SET_CLASSIFICATIONS_BY_TYPE_RAILWAY_CARGO_ADD';
export const SET_RAILWAY_CLASSIFICATOR_ADD = 'SET_RAILWAY_CLASSIFICATOR_ADD';
export const SET_CLASSIFICATIONS_BY_TYPE_RAILWAY_RESTRICT = 'SET_CLASSIFICATIONS_BY_TYPE_RAILWAY_RESTRICT';
export const SET_CLASSIFICATIONS_BY_TYPE_RAILWAY_RESTRICT_TYPE = 'SET_CLASSIFICATIONS_BY_TYPE_RAILWAY_RESTRICT_TYPE';

export const SET_CREATE_ITEM_RESTRICT = 'SET_CREATE_ITEM_RESTRICT';
export const CHANGE_TOGETHER_API = 'CHANGE_TOGETHER_API';


export const SET_TIMBER_SORTMENT = 'SET_TIMBER_SORTMENT';
export const SET_TIMBER_SPECIES = 'SET_TIMBER_SPECIES';
export const SET_TIMBER_CLASS = 'SET_TIMBER_CLASS';
export const SET_TIMBER_DIAMETER = 'SET_TIMBER_DIAMETER';
export const SET_TIMBER_STORAGE = 'SET_TIMBER_STORAGE';
export const SET_TIMBER_PRODUCTION_YEAR = 'SET_TIMBER_PRODUCTION_YEAR';
export const SET_TIMBER_PRODUCTION_QUARTER = 'SET_TIMBER_PRODUCTION_QUARTER';
export const SET_POPUP_BID_TOGETHER_API = 'SET_POPUP_BID_TOGETHER_API';
export const SET_TIMBER_LENGTH1 = 'SET_TIMBER_LENGTH1';
export const SET_TIMBER_LENGTH2 = 'SET_TIMBER_LENGTH2';

export const AUCTIONS_SEARCH_ELEM_ARR = 'AUCTIONS_SEARCH_ELEM_ARR';

export const AWARD_ALL_USER_POPUP = 'AWARD_ALL_USER_POPUP';
export const AWARD_ALL_USER_POPUP_USER = 'AWARD_ALL_USER_POPUP_USER';


export const SET_CREATE_RESTRICTION_FLAG = 'SET_CREATE_RESTRICTION_FLAG';
export const SET_CREATE_RESTRICTION_LOAD_OBJECT = 'SET_CREATE_RESTRICTION_LOAD_OBJECT';
export const SET_CREATE_RESTRICTION_LOAD_OBJECT_CODE = 'SET_CREATE_RESTRICTION_LOAD_OBJECT_CODE';
export const SET_CREATE_RESTRICTION_LOAD_OBJECT_NAME = 'SET_CREATE_RESTRICTION_LOAD_OBJECT_NAME';
export const SET_CREATE_RESTRICTION_UNLOAD_OBJECT = 'SET_CREATE_RESTRICTION_UNLOAD_OBJECT';
export const SET_CREATE_RESTRICTION_UNLOAD_OBJECT_CODE = 'SET_CREATE_RESTRICTION_UNLOAD_OBJECT_CODE';
export const SET_CREATE_RESTRICTION_UNLOAD_OBJECT_NAME = 'SET_CREATE_RESTRICTION_UNLOAD_OBJECT_NAME';

export const SET_CREATE_RESTRICTION_EXCLUSIONS_FLAF = 'SET_CREATE_RESTRICTION_EXCLUSIONS_FLAF';
export const SET_CREATE_RESTRICTION_EXCLUSIONS_OBJECT = 'SET_CREATE_RESTRICTION_EXCLUSIONS_OBJECT';
export const SET_CREATE_RESTRICTION_EXCLUSIONS_OBJECT_TYPE = 'SET_CREATE_RESTRICTION_EXCLUSIONS_OBJECT_TYPE';
export const SET_CREATE_RESTRICTION_EXCLUSIONS_OBJECT_CODE = 'SET_CREATE_RESTRICTION_EXCLUSIONS_OBJECT_CODE';
export const SET_CREATE_RESTRICTION_EXCLUSIONS_OBJECT_NAME = 'SET_CREATE_RESTRICTION_EXCLUSIONS_OBJECT_NAME';

export const SET_CLASSIFICATIONS_BY_TYPE_RAILWAY = 'SET_CLASSIFICATIONS_BY_TYPE_RAILWAY';
export const UNITS_GET_BY_TYPE_RAILWAY = 'UNITS_GET_BY_TYPE_RAILWAY';
export const UNITS_GET_BY_TYPE_SUBSOIL = 'UNITS_GET_BY_TYPE_SUBSOIL';

export const SET_CLASSIFICATIONS_BY_WAGON_SPECIES = 'SET_CLASSIFICATIONS_BY_WAGON_SPECIES';

export const SET_CLASSIFICATIONS_BY_TYPE_NADRA_ENGLISH = 'SET_CLASSIFICATIONS_BY_TYPE_NADRA_ENGLISH';
export const SET_CLASSIFICATIONS_BY_TYPE_SUBSOIL_USAGE_TYPE = 'SET_CLASSIFICATIONS_BY_TYPE_SUBSOIL_USAGE_TYPE';

//Contacts
export const CONTACT_GET = 'CONTACT_GET';
export const CONTACTS_GET = 'CONTACTS_GET';
export const CONTACT_POST = 'CONTACT_POST';
export const CONTACT_PUT = 'CONTACT_PUT';
export const CONTACT_DELETE = 'CONTACT_DELETE';

//Contracts
export const CONTRACT_GET = 'CONTRACT_GET';
export const CONTRACT_AUCTION_ID_GET = 'CONTRACT_AUCTION_ID_GET';
export const CONTRACT_POST = 'CONTRACT_POST';
export const CONTRACT_PUT = 'CONTRACT_PUT';
export const CONTRACT_DELETE = 'CONTRACT_DELETE';

export const SET_CONTRACT_TITLE = 'SET_CONTRACT_TITLE';
export const SET_CONTRACT_DESCRIPTION = 'SET_CONTRACT_DESCRIPTION';
export const SET_CONTRACT_CONTRACT_NUMBER = 'SET_CONTRACT_CONTRACT_NUMBER';
export const SET_CONTRACT_DATE_FROM = 'SET_CONTRACT_DATE_FROM';
export const SET_CONTRACT_DATE_TILL = 'SET_CONTRACT_DATE_TILL';
export const SET_CONTRACT_START_DATE = 'SET_CONTRACT_START_DATE';
export const SET_CONTRACT_END_DATE = 'SET_CONTRACT_END_DATE';
export const SET_CONTRACT_CURRENCY = 'SET_CONTRACT_CURRENCY';
export const SET_CONTRACT_AMOUNT = 'SET_CONTRACT_AMOUNT';
export const SET_CONTRACT_DATE_SIGNED = 'SET_CONTRACT_DATE_SIGNED';

export const SET_POPUP_CONTRACT_APPROWE = 'SET_POPUP_CONTRACT_APPROWE';
export const SET_POPUP_CONTRACT_ERROR = 'SET_POPUP_CONTRACT_ERROR';

//Documents
export const DOCUMENT_GET = 'DOCUMENT_GET';
export const DOCUMENTS_GET = 'DOCUMENTS_GET';
export const DOCUMENT_POST = 'DOCUMENT_POST';
export const DOCUMENT_PUT = 'DOCUMENT_PUT';
export const DOCUMENT_PATCH = 'DOCUMENT_PATCH';
export const DOCUMENT_DELETE = 'DOCUMENT_DELETE';
export const CHANGE_DOCUMENT_ID = 'CHANGE_DOCUMENT_ID';
export const CHANGE_DOCUMENT_TITLE = 'CHANGE_DOCUMENT_TITLE';
export const CHANGE_DOCUMENT_DESCRIPTION = 'CHANGE_DOCUMENT_DESCRIPTION';
export const CHANGE_DOCUMENT_LANG = 'CHANGE_DOCUMENT_LANG';
export const CHANGE_DOCUMENT_TYPE = 'CHANGE_DOCUMENT_TYPE';
export const OLD_ARR_DOCUMENT_GET = 'OLD_ARR_DOCUMENT_GET';
export const DOCUMENT_OF_LIST = 'DOCUMENT_OF_LIST';
export const POPUP_ADD_DOCUMENT = 'POPUP_ADD_DOCUMENT';
export const POPUP_UPDATE_DOCUMENT = 'POPUP_UPDATE_DOCUMENT';
export const POPUP_DELETE_DOCUMENT = 'POPUP_DELETE_DOCUMENT';
export const ATTACH_FILE_BUTTON = 'ATTACH_FILE_BUTTON';
export const ATTACH_FILE_BLOCK = 'ATTACH_FILE_BLOCK';
export const DOCUMENT_TOKEN = 'DOCUMENT_TOKEN';

//Discount
export const CHANGE_DISCOUNT_ID = 'CHANGE_DISCOUNT_ID';
export const CHANGE_DISCOUNT_STATUS = 'CHANGE_DISCOUNT_STATUS';
export const CHANGE_DISCOUNT_PERCENT = 'CHANGE_DISCOUNT_PERCENT';
export const CHANGE_DISCOUNT_PERVIOUS_AUCTION_CURRENCY = 'CHANGE_DISCOUNT_PERVIOUS_AUCTION_CURRENCY';
export const CHANGE_DISCOUNT_PERVIOUS_AUCTION_AMOUNT = 'CHANGE_DISCOUNT_PERVIOUS_AUCTION_AMOUNT';
export const CHANGE_DISCOUNT_PERVIOUS_AUCTION_ID = 'CHANGE_DISCOUNT_PERVIOUS_AUCTION_ID';

//Identifier
export const IDENTIFIER_GET = 'IDENTIFIER_GET';
export const IDENTIFIERS_GET = 'IDENTIFIERS_GET';
export const IDENTIFIER_POST = 'IDENTIFIER_POST';
export const IDENTIFIER_PUT = 'IDENTIFIER_PUT';
export const IDENTIFIER_DELETE = 'IDENTIFIER_DELETE';

//default
export const DOC_GET = 'DOC_GET';
export const AUTHORIZE_GET = 'AUTHORIZE_GET';
export const TOKEN_POST = 'TOKEN_POST';
export const TOKEN_CLEAR = 'TOKEN_CLEAR';
export const SET_PROFILE_NULL = 'SET_PROFILE_NULL';

//Old site users
export const OLD_USER_GET = 'OLD_USER_GET';
export const OLD_USERS_GET = 'OLD_USERS_GET';
export const OLD_USER_POST = 'OLD_USER_POST';
export const OLD_USER_PUT = 'OLD_USER_PUT';
export const OLD_USER_DELETE = 'OLD_USER_DELETE';
export const USER_PROFILES_GET = 'USER_PROFILES_GET';

//Organizations
export const ORGANIZATION_GET = 'ORGANIZATION_GET';
export const ORGANIZATIONS_GET_LIST = 'ORGANIZATIONS_GET_LIST';
export const ORGANIZATION_POST = 'ORGANIZATION_POST';
export const ORGANIZATION_PUT = 'ORGANIZATION_PUT';
export const ORGANIZATION_DELETE = 'ORGANIZATION_DELETE';
export const POPUP_CHOOSE_ORGANIZATION = 'POPUP_CHOOSE_ORGANIZATION';
export const POPUP_ADD_ORGANIZATION = 'POPUP_ADD_ORGANIZATION';
export const KOATUU_FIND = 'KOATUU_FIND';
//Property owner
export const PROPERTY_OWNER = 'PROPERTY_OWNER';
export const PROPERTY_OWNER_NAME = 'PROPERTY_OWNER_NAME';
export const PROPERTY_OWNER_IDENTIFIER_IDENTIFIER = 'PROPERTY_OWNER_IDENTIFIER_IDENTIFIER';
export const PROPERTY_OWNER_IDENTIFIER_SCHEME = 'PROPERTY_OWNER_IDENTIFIER_SCHEME';
export const PROPERTY_OWNER_IDENTIFIER_LEGAL_NAME = 'PROPERTY_OWNER_IDENTIFIER_LEGAL_NAME';
export const PROPERTY_OWNER_ADDRESS_IDENTIFIER = 'PROPERTY_OWNER_ADDRESS_IDENTIFIER';
export const PROPERTY_OWNER_ADDRESS_NAME = 'PROPERTY_OWNER_ADDRESS_NAME';
export const PROPERTY_OWNER_ADDRESS_SCHEME = 'PROPERTY_OWNER_ADDRESS_SCHEME';
export const PROPERTY_OWNER_ADDRESS_STREET_ADDRESS = 'PROPERTY_OWNER_ADDRESS_STREET_ADDRESS';
export const PROPERTY_OWNER_ADDRESS_LOCALITY = 'PROPERTY_OWNER_ADDRESS_LOCALITY';
export const PROPERTY_OWNER_ADDRESS_REGION = 'PROPERTY_OWNER_ADDRESS_REGION';
export const PROPERTY_OWNER_ADDRESS_POSTAL_CODE = 'PROPERTY_OWNER_ADDRESS_POSTAL_CODE';
export const PROPERTY_OWNER_ADDRESS_COUNTRY = 'PROPERTY_OWNER_ADDRESS_COUNTRY';
export const PROPERTY_OWNER_ADDRESS_CONTACT_NAME = 'PROPERTY_OWNER_ADDRESS_CONTACT_NAME';
export const PROPERTY_OWNER_ADDRESS_CONTACT_EMAIL = 'PROPERTY_OWNER_ADDRESS_CONTACT_EMAIL';
export const PROPERTY_OWNER_ADDRESS_CONTACT_TELEPHONE = 'PROPERTY_OWNER_ADDRESS_CONTACT_TELEPHONE';
export const PROPERTY_OWNER_ADDRESS_CONTACT_FAX = 'PROPERTY_OWNER_ADDRESS_CONTACT_FAX';
export const PROPERTY_OWNER_ADDRESS_CONTACT_URL = 'PROPERTY_OWNER_ADDRESS_CONTACT_URL';
export const PROPERTY_OWNER_REPRESENTATIVE_INFO = 'PROPERTY_OWNER_REPRESENTATIVE_INFO';
//Selling entity
export const SELLING_ENTITY = 'SELLING_ENTITY';
export const SELLING_ENTITY_NAME = 'SELLING_ENTITY_NAME';
export const SELLING_ENTITY_IDENTIFIER_IDENTIFIER = 'SELLING_ENTITY_IDENTIFIER_IDENTIFIER';
export const SELLING_ENTITY_IDENTIFIER_SCHEME = 'SELLING_ENTITY_IDENTIFIER_SCHEME';
export const SELLING_ENTITY_IDENTIFIER_LEGAL_NAME = 'SELLING_ENTITY_IDENTIFIER_LEGAL_NAME';
export const SELLING_ENTITY_ADDRESS_IDENTIFIER = 'SELLING_ENTITY_ADDRESS_IDENTIFIER';
export const SELLING_ENTITY_ADDRESS_NAME = 'SELLING_ENTITY_ADDRESS_NAME';
export const SELLING_ENTITY_ADDRESS_SCHEME = 'SELLING_ENTITY_ADDRESS_SCHEME';
export const SELLING_ENTITY_ADDRESS_STREET_ADDRESS = 'SELLING_ENTITY_ADDRESS_STREET_ADDRESS';
export const SELLING_ENTITY_ADDRESS_LOCALITY = 'SELLING_ENTITY_ADDRESS_LOCALITY';
export const SELLING_ENTITY_ADDRESS_REGION = 'SELLING_ENTITY_ADDRESS_REGION';
export const SELLING_ENTITY_ADDRESS_POSTAL_CODE = 'SELLING_ENTITY_ADDRESS_POSTAL_CODE';
export const SELLING_ENTITY_ADDRESS_COUNTRY = 'SELLING_ENTITY_ADDRESS_COUNTRY';
export const SELLING_ENTITY_ADDRESS_CONTACT_NAME = 'SELLING_ENTITY_ADDRESS_CONTACT_NAME';
export const SELLING_ENTITY_ADDRESS_CONTACT_EMAIL = 'SELLING_ENTITY_ADDRESS_CONTACT_EMAIL';
export const SELLING_ENTITY_ADDRESS_CONTACT_TELEPHONE = 'SELLING_ENTITY_ADDRESS_CONTACT_TELEPHONE';
export const SELLING_ENTITY_ADDRESS_CONTACT_FAX = 'SELLING_ENTITY_ADDRESS_CONTACT_FAX';
export const SELLING_ENTITY_ADDRESS_CONTACT_URL = 'SELLING_ENTITY_ADDRESS_CONTACT_URL';
export const SELLING_ENTITY_REPRESENTATIVE_INFO = 'SELLING_ENTITY_REPRESENTATIVE_INFO';
//Governer
export const GOVERNER = 'GOVERNER';
export const GOVERNER_NAME = 'GOVERNER_NAME';
export const GOVERNER_IDENTIFIER_IDENTIFIER = 'GOVERNER_IDENTIFIER_IDENTIFIER';
export const GOVERNER_IDENTIFIER_SCHEME = 'GOVERNER_IDENTIFIER_SCHEME';
export const GOVERNER_IDENTIFIER_LEGAL_NAME = 'GOVERNER_IDENTIFIER_LEGAL_NAME';
export const GOVERNER_ADDRESS_IDENTIFIER = 'GOVERNER_ADDRESS_IDENTIFIER';
export const GOVERNER_ADDRESS_NAME = 'GOVERNER_ADDRESS_NAME';
export const GOVERNER_ADDRESS_SCHEME = 'GOVERNER_ADDRESS_SCHEME';
export const GOVERNER_ADDRESS_STREET_ADDRESS = 'GOVERNER_ADDRESS_STREET_ADDRESS';
export const GOVERNER_ADDRESS_LOCALITY = 'GOVERNER_ADDRESS_LOCALITY';
export const GOVERNER_ADDRESS_REGION = 'GOVERNER_ADDRESS_REGION';
export const GOVERNER_ADDRESS_POSTAL_CODE = 'GOVERNER_ADDRESS_POSTAL_CODE';
export const GOVERNER_ADDRESS_COUNTRY = 'GOVERNER_ADDRESS_COUNTRY';
export const GOVERNER_ADDRESS_CONTACT_NAME = 'GOVERNER_ADDRESS_CONTACT_NAME';
export const GOVERNER_ADDRESS_CONTACT_EMAIL = 'GOVERNER_ADDRESS_CONTACT_EMAIL';
export const GOVERNER_ADDRESS_CONTACT_TELEPHONE = 'GOVERNER_ADDRESS_CONTACT_TELEPHONE';
export const GOVERNER_ADDRESS_CONTACT_FAX = 'GOVERNER_ADDRESS_CONTACT_FAX';
export const GOVERNER_ADDRESS_CONTACT_URL = 'GOVERNER_ADDRESS_CONTACT_URL';
export const GOVERNER_REPRESENTATIVE_INFO = 'GOVERNER_REPRESENTATIVE_INFO';
//Current tenant
export const CURRENT_TENANT = 'CURRENT_TENANT';
export const CURRENT_TENANT_NAME = 'CURRENT_TENANT_NAME';
export const CURRENT_TENANT_IDENTIFIER_IDENTIFIER = 'CURRENT_TENANT_IDENTIFIER_IDENTIFIER';
export const CURRENT_TENANT_IDENTIFIER_SCHEME = 'CURRENT_TENANT_IDENTIFIER_SCHEME';
export const CURRENT_TENANT_IDENTIFIER_LEGAL_NAME = 'CURRENT_TENANT_IDENTIFIER_LEGAL_NAME';
export const CURRENT_TENANT_ADDRESS_IDENTIFIER = 'CURRENT_TENANT_ADDRESS_IDENTIFIER';
export const CURRENT_TENANT_ADDRESS_NAME = 'CURRENT_TENANT_ADDRESS_NAME';
export const CURRENT_TENANT_ADDRESS_SCHEME = 'CURRENT_TENANT_ADDRESS_SCHEME';
export const CURRENT_TENANT_ADDRESS_STREET_ADDRESS = 'CURRENT_TENANT_ADDRESS_STREET_ADDRESS';
export const CURRENT_TENANT_ADDRESS_LOCALITY = 'CURRENT_TENANT_ADDRESS_LOCALITY';
export const CURRENT_TENANT_ADDRESS_REGION = 'CURRENT_TENANT_ADDRESS_REGION';
export const CURRENT_TENANT_ADDRESS_POSTAL_CODE = 'CURRENT_TENANT_ADDRESS_POSTAL_CODE';
export const CURRENT_TENANT_ADDRESS_COUNTRY = 'CURRENT_TENANT_ADDRESS_COUNTRY';
export const CURRENT_TENANT_ADDRESS_CONTACT_NAME = 'CURRENT_TENANT_ADDRESS_CONTACT_NAME';
export const CURRENT_TENANT_ADDRESS_CONTACT_EMAIL = 'CURRENT_TENANT_ADDRESS_CONTACT_EMAIL';
export const CURRENT_TENANT_ADDRESS_CONTACT_TELEPHONE = 'CURRENT_TENANT_ADDRESS_CONTACT_TELEPHONE';
export const CURRENT_TENANT_ADDRESS_CONTACT_FAX = 'CURRENT_TENANT_ADDRESS_CONTACT_FAX';
export const CURRENT_TENANT_ADDRESS_CONTACT_URL = 'CURRENT_TENANT_ADDRESS_CONTACT_URL';
export const CURRENT_TENANT_REPRESENTATIVE_INFO = 'CURRENT_TENANT_REPRESENTATIVE_INFO';

//Procuring Entity
export const PROCURING_ENTITY_GET = 'PROCURING_ENTITY_GET';
export const PROCURING_ENTITIES_GET = 'PROCURING_ENTITIES_GET';
export const PROCURING_ENTITY_POST = 'PROCURING_ENTITY_POST';
export const PROCURING_ENTITY_PUT = 'PROCURING_ENTITY_PUT';
export const PROCURING_ENTITY_DELETE = 'PROCURING_ENTITY_DELETE';

//Questions
export const QUESTION_GET = 'QUESTION_GET';
export const QUESTIONS_AUCTION_ID_GET = 'QUESTIONS_AUCTION_ID_GET';
export const QUESTION_POST = 'QUESTION_POST';
export const QUESTION_PUT = 'QUESTION_PUT';
export const QUESTION_DELETE = 'QUESTION_DELETE';

export const ANSWER_TITLE = 'ANSWER_TITLE';
export const ANSWER_DISCRIPTION = 'ANSWER_DISCRIPTION';

//Item Units
export const UNIT_GET = 'UNIT_GET';
export const UNITS_GET = 'UNITS_GET';
export const UNIT_POST = 'UNIT_POST';
export const UNIT_PUT = 'UNIT_PUT';
export const UNIT_DELETE = 'UNIT_DELETE';
export const UNITS_CHANGE = 'UNITS_CHANGE';
export const CHANGE_MEASURES = 'CHANGE_MEASURES';
export const UNITS_GET_BY_TYPE = 'UNITS_GET_BY_TYPE';
export const UNITS_GET_BY_TYPE_ENERGY = 'UNITS_GET_BY_TYPE_ENERGY';

//Media
export const MEDIA_GET = 'MEDIA_GET';
export const MEDIA_ID_GET = 'MEDIA_ID_GET';
export const MEDIA_FORMATS_GET = 'MEDIA_FORMATS_GET';
export const MEDIA_BINARIES_GET = 'MEDIA_BINARIES_GET';
export const MEDIA_POST = 'MEDIA_POST';
export const MEDIA_PUT = 'MEDIA_PUT';
export const MEDIA_BINARY_CONTENT_PUT = 'MEDIA_BINARY_CONTENT_PUT';
export const MEDIA_DELETE = 'MEDIA_DELETE';


//REGEXP
export const REG_EXP_TIMBER_DIAMETR = 'REG_EXP_TIMBER_DIAMETR';

export const LEASE_CONTRACTS_GET_MY = 'LEASE_CONTRACTS_GET_MY';

//Object creation

export const OBJECTS_GET_MY = 'OBJECTS_GET_MY';
export const OBJECTS_GET_TYPE = 'OBJECTS_GET_TYPE';
export const SET_OBJECT_CREATION_STEP = 'SET_OBJECT_CREATION_STEP';
export const DRAFT_OBJECT = 'DRAFT_OBJECT';
export const OBJECT_GET_TYPES = 'OBJECT_GET_TYPES';
export const OBJECT_GET_RESTRICTIONS = 'OBJECT_GET_RESTRICTIONS';
export const OBJECT_GET_INTENDED_USE = 'OBJECT_GET_INTENDED_USE';
export const OBJECT_GET_STATUS_IN_LIST = 'OBJECT_GET_STATUS_IN_LIST';
export const OBJECT_GET_REGISTRATION_STATE_LIST = 'OBJECT_GET_REGISTRATION_STATE_LIST';
export const OBJECT_GET_VERIFICATION_3_LIST = 'OBJECT_GET_VERIFICATION_3_LIST';
export const OBJECT_GET_OWNERSHIP_TYPE = 'OBJECT_GET_OWNERSHIP_TYPE';
export const OBJECT_GET_LIST_TYPES = 'OBJECT_GET_LIST_TYPES';
export const OBJECT_GET_UA_INDENTIFIERS_LIST = 'OBJECT_GET_UA_INDENTIFIERS_LIST';
export const OBJECT_GET_ENCUMBRANCES_LIST = 'OBJECT_GET_ENCUMBRANCES_LIST';
export const OBJECT_GET_FUEL_TYPE_LIST = 'OBJECT_GET_FUEL_TYPE_LIST';
export const OBJECT_GET_TRANSMISSION_TYPE_LIST = 'OBJECT_GET_TRANSMISSION_TYPE_LIST';
export const OBJECT_GET_FLOOR_TYPE_LIST = 'OBJECT_GET_FLOOR_TYPE_LIST';
export const OBJECT_GET_POWER_SUPPLY_CLASS_LIST = 'OBJECT_GET_POWER_SUPPLY_CLASS_LIST';
export const OBJECT_GET_CONSTRUCTION_TECHNOLOGY_LIST = 'OBJECT_GET_CONSTRUCTION_TECHNOLOGY_LIST';
export const GET_DICTIONARIES_UNIT_CODE = 'GET_DICTIONARIES_UNIT_CODE';
export const ASSETT_GET_REGISTRATION_STATUS = 'ASSETT_GET_REGISTRATION_STATUS';

export const OBJECT_ADDRESS = 'OBJECT_ADDRESS';
export const SEARCH_ADDRESS = 'SEARCH_ADDRESS';
export const OBJECT_PAGE_CREATE = 'OBJECT_PAGE_CREATE';
export const INACTIVE_OBJECT_POPUP = 'INACTIVE_OBJECT_POPUP';
export const OBJECT_DELETE_POPUP = 'OBJECT_DELETE_POPUP';

export const OBJECT = 'OBJECT';
export const OBJECT_CREATE = 'OBJECT_CREATE';
export const OBJECT_UPDATE = 'OBJECT_UPDATE';
export const OBJECT_GET = 'OBJECT_GET';
export const OBJECT_DELETE = 'OBJECT_DELETE';
export const OBJECT_VALUES_CHARACTERISTICS_UPDATE = 'OBJECT_VALUES_CHARACTERISTICS_UPDATE';
export const OBJECT_LEASE_RULES_UPDATE = 'OBJECT_LEASE_RULES_UPDATE';
export const OBJECT_STATUSES_DECISIONS_UPDATE = 'OBJECT_STATUSES_DECISIONS_UPDATE';
export const OBJECT_RELATED_ORGANIZATIONS_UPDATE = 'OBJECT_RELATED_ORGANIZATIONS_UPDATE';
export const OBJECT_RELATED_ORGANIZATIONS_DELETE = 'OBJECT_RELATED_ORGANIZATIONS_DELETE';

//Object Item
export const OBJECT_ITEM_CREATE = 'OBJECT_ITEM_CREATE';
export const OBJECT_ITEM_UPDATE = 'OBJECT_ITEM_UPDATE';
export const OBJECT_ITEM_DELETE = 'OBJECT_ITEM_DELETE';
export const OBJECT_ITEM_ID_UPDATE = 'OBJECT_ITEM_ID_UPDATE';
export const OBJECT_ITEM_TYPE = 'OBJECT_ITEM_TYPE';
export const OBJECT_ITEM_DELETE_POPUP = 'OBJECT_ITEM_DELETE_POPUP';
export const OBJECT_ITEM_PAGE = 'OBJECT_ITEM_PAGE';

//Object Item basic info
export const OBJECT_ITEM_TITLE = 'OBJECT_ITEM_TITLE';
export const OBJECT_ITEM_DESCRIPTION = 'OBJECT_ITEM_DESCRIPTION';
export const OBJECT_ITEM_CLASSIFICATION_SCHEME = 'OBJECT_ITEM_CLASSIFICATION_SCHEME';
export const OBJECT_ITEM_CLASSIFICATION_DESCRIPTION = 'OBJECT_ITEM_CLASSIFICATION_DESCRIPTION';
export const OBJECT_ITEM_CLASSIFICATION_ID = 'OBJECT_ITEM_CLASSIFICATION_ID';
export const OBJECT_GET_CAV_LIST = 'OBJECT_GET_CAV_LIST';

//Object Item placing
export const OBJECT_ITEM_LOCATION_LATITUDE = 'OBJECT_ITEM_LOCATION_LATITUDE';
export const OBJECT_ITEM_LOCATION_LONGITUDE = 'OBJECT_ITEM_LOCATION_LONGITUDE';
export const OBJECT_ITEM_ADDRESS_SCHEME = 'OBJECT_ITEM_ADDRESS_SCHEME';
export const OBJECT_ITEM_ADDRESS_IDENTIFIER = 'OBJECT_ITEM_ADDRESS_IDENTIFIER';
export const OBJECT_ITEM_ADDRESS_NAME = 'OBJECT_ITEM_ADDRESS_NAME';
export const OBJECT_ITEM_STREET_ADDRESS = 'OBJECT_ITEM_STREET_ADDRESS';
export const OBJECT_ITEM_LOCALITY = 'OBJECT_ITEM_LOCALITY';
export const OBJECT_ITEM_REGION = 'OBJECT_ITEM_REGION';
export const OBJECT_ITEM_POSTAL_CODE = 'OBJECT_ITEM_POSTAL_CODE';

//Object Item Joint Property Complex
export const JOINT_PROPERTY_COMPLEX_QUANTITY_AND_NOMENCLATURE = 'JOINT_PROPERTY_COMPLEX_QUANTITY_AND_NOMENCLATURE';
export const JOINT_PROPERTY_COMPLEX_WORKPLACES_QUANTITY = 'JOINT_PROPERTY_COMPLEX_WORKPLACES_QUANTITY';
export const JOINT_PROPERTY_COMPLEX_BUILDINGS_INFORMATION = 'JOINT_PROPERTY_COMPLEX_BUILDINGS_INFORMATION';
export const JOINT_PROPERTY_COMPLEX_LAND_PLOT_INFORMATION = 'JOINT_PROPERTY_COMPLEX_LAND_PLOT_INFORMATION';
export const JOINT_PROPERTY_COMPLEX_MAIN_OBLIGATIONS = 'JOINT_PROPERTY_COMPLEX_MAIN_OBLIGATIONS';
export const JOINT_PROPERTY_COMPLEX_LEASE_APPROVER_WEBSITE = 'JOINT_PROPERTY_COMPLEX_LEASE_APPROVER_WEBSITE';

export const JOINT_PROPERTY_COMPLEX_LAND_AREA = 'JOINT_PROPERTY_COMPLEX_LAND_AREA';
export const JOINT_PROPERTY_COMPLEX_CADASTRAL_NUMBER = 'JOINT_PROPERTY_COMPLEX_CADASTRAL_NUMBER';
export const JOINT_PROPERTY_COMPLEX_OWNERSHIP_TYPE = 'JOINT_PROPERTY_COMPLEX_OWNERSHIP_TYPE';
export const JOINT_PROPERTY_COMPLEX_ENCUMBRANCES = 'JOINT_PROPERTY_COMPLEX_ENCUMBRANCES';
export const JOINT_PROPERTY_COMPLEX_JOINT_OWNERSHIP = 'JOINT_PROPERTY_COMPLEX_JOINT_OWNERSHIP';
export const JOINT_PROPERTY_COMPLEX_UTILITIES_AVIABILITY = 'JOINT_PROPERTY_COMPLEX_UTILITIES_AVIABILITY';
export const JOINT_PROPERTY_COMPLEX_LAND_INTENDED_USE = 'JOINT_PROPERTY_COMPLEX_LAND_INTENDED_USE';

//Object Item Vehicle 
export const VEHICLE_BRAND = 'VEHICLE_BRAND';
export const VEHICLE_MODEL = 'VEHICLE_MODEL';
export const VEHICLE_IDENTIFICATION_NUMBER = 'VEHICLE_IDENTIFICATION_NUMBER';
export const VEHICLE_CONDITION = 'VEHICLE_CONDITION';
export const VEHICLE_PRODUCTION_YEAR = 'VEHICLE_PRODUCTION_YEAR';
export const VEHICLE_ENGINE_CAPACITY = 'VEHICLE_ENGINE_CAPACITY';
export const VEHICLE_FUEL_TYPE = 'VEHICLE_FUEL_TYPE';
export const VEHICLE_TRANSMISSION = 'VEHICLE_TRANSMISSION';
export const VEHICLE_KILOMETRAGE = 'VEHICLE_KILOMETRAGE';
export const VEHICLE_CONFIGURATION = 'VEHICLE_CONFIGURATION';
export const VEHICLE_DAMAGE_PRESENCE = 'VEHICLE_DAMAGE_PRESENCE';
export const VEHICLE_DAMAGE_DESCRIPTION = 'VEHICLE_DAMAGE_DESCRIPTION';
export const VEHICLE_COLOR = 'VEHICLE_COLOR';

//Object Item Real Estate
export const REAL_ESTATE_TOTAL_BUILDING_AREA = 'REAL_ESTATE_TOTAL_BUILDING_AREA';
export const REAL_ESTATE_CONSTRUCTION_YEAR = 'REAL_ESTATE_CONSTRUCTION_YEAR';
export const REAL_ESTATE_TOTAL_OBJECT_AREA = 'REAL_ESTATE_TOTAL_OBJECT_AREA';
export const REAL_ESTATE_USABLE_AREA = 'REAL_ESTATE_USABLE_AREA';
export const REAL_ESTATE_LOCATION_IN_BUILDING = 'REAL_ESTATE_LOCATION_IN_BUILDING';
export const REAL_ESTATE_FLOORS = 'REAL_ESTATE_FLOORS';
export const REAL_ESTATE_GENERAL_CONDITION = 'REAL_ESTATE_GENERAL_CONDITION';
export const REAL_ESTATE_SERVICE_ELECTRICITY = 'REAL_ESTATE_SERVICE_ELECTRICITY';
export const REAL_ESTATE_POWER_SUPPLY_CAPACITY = 'REAL_ESTATE_POWER_SUPPLY_CAPACITY';
export const REAL_ESTATE_POWER_SUPPLY_CLASS = 'REAL_ESTATE_POWER_SUPPLY_CLASS';
export const REAL_ESTATE_SERVICE_WATER = 'REAL_ESTATE_SERVICE_WATER';
export const REAL_ESTATE_SERVICE_SEWERAGE = 'REAL_ESTATE_SERVICE_SEWERAGE';
export const REAL_ESTATE_SERVICE_GAS = 'REAL_ESTATE_SERVICE_GAS';
export const REAL_ESTATE_SERVICE_CENTRAL_HEATING = 'REAL_ESTATE_SERVICE_CENTRAL_HEATING';
export const REAL_ESTATE_SERVICE_AUTONOMOUS_HEATING = 'REAL_ESTATE_SERVICE_AUTONOMOUS_HEATING';
export const REAL_ESTATE_SERVICE_HEATING_COUNTER = 'REAL_ESTATE_SERVICE_HEATING_COUNTER';
export const REAL_ESTATE_SERVICE_VENTILATION = 'REAL_ESTATE_SERVICE_VENTILATION';
export const REAL_ESTATE_SERVICE_AIR_CONDITIONING = 'REAL_ESTATE_SERVICE_AIR_CONDITIONING';
export const REAL_ESTATE_SERVICE_PHONE = 'REAL_ESTATE_SERVICE_PHONE';
export const REAL_ESTATE_SERVICE_TV = 'REAL_ESTATE_SERVICE_TV';
export const REAL_ESTATE_SERVICE_INTERNET = 'REAL_ESTATE_SERVICE_INTERNET';
export const REAL_ESTATE_SERVICE_ELEVATOR = 'REAL_ESTATE_SERVICE_ELEVATOR';
export const REAL_ESTATE_SERVICE_SECURITY_ALARM = 'REAL_ESTATE_SERVICE_SECURITY_ALARM';
export const REAL_ESTATE_SERVICE_FIRE_ALARM = 'REAL_ESTATE_SERVICE_FIRE_ALARM';
export const REAL_ESTATE_SERVICE_DESCRIPTION = 'REAL_ESTATE_SERVICE_DESCRIPTION';
export const REAL_ESTATE_SERVICE_ACCOUNTING = 'REAL_ESTATE_SERVICE_ACCOUNTING';
export const REAL_ESTATE_LAND_TAX = 'REAL_ESTATE_LAND_TAX';
export const REAL_ESTATE_LIVING_AREA = 'REAL_ESTATE_LIVING_AREA';
export const REAL_ESTATE_KITCHEN_AREA = 'REAL_ESTATE_KITCHEN_AREA';
export const REAL_ESTATE_CONSTRUCTION_TECHNOLOGY = 'REAL_ESTATE_CONSTRUCTION_TECHNOLOGY';
export const REAL_ESTATE_LAND_AREA = 'REAL_ESTATE_LAND_AREA';

//Actions
export const ACTIONS_GET_MY = 'ACTIONS_GET_MY';
export const ACTION_POST_CREATE = 'ACTION_POST_CREATE';
export const ACTIONS_GET_TYPE = 'ACTIONS_GET_TYPE';
export const ACTIONS_GET_ENTITY_ROLE = 'ACTIONS_GET_ENTITY_ROLE';
export const SET_ACTION_TYPE = 'SET_ACTION_TYPE';
export const ACTION_RELATED_OBJECTS = 'ACTION_RELATED_OBJECTS';

export const ACTION_CREATE = 'ACTION_CREATE';
export const ACTION_UPDATE = 'ACTION_UPDATE';
export const ACTION_DELETE = 'ACTION_DELETE';
export const ACTION_DELETE_POPUP = 'ACTION_DELETE_POPUP';

export const POPUP_LOCATION = 'POPUP_LOCATION';

//Lease Requests
export const SET_POPUP_ADD_REQUEST = 'SET_POPUP_ADD_REQUEST';
export const LEASE_REQUEST_GET_TYPE = 'LEASE_REQUEST_GET_TYPE';
export const REQUESTS_GET_MY = 'REQUESTS_GET_MY';
export const LEASE_REQUEST_CREATE = 'LEASE_REQUEST_CREATE';
export const LEASE_REQUEST_EDIT = 'LEASE_REQUEST_EDIT';
export const REQUEST_GET_MY = 'REQUEST_GET_MY';