import React, {useState} from 'react';
import styles from './myActionsTabItem.module.css';
import {useDispatch, useSelector} from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { dateTransform, isMongo, getShorter, checkProp, isDraftObject } from '../../../../utils/check';
import { getLeaseActionType, leaseActionDelete } from '../../../../utils/actions/actionsUtils';
import UniPopUp from '../../../popups/uniPopUp/uniPopUp';
import { urlGetMyDraftAction, urlGetMyActions } from '../../../../utils/endPoints';
import { actionsGetType, actionsGetMy } from '../../../../redux/actions/leaseActions/leaseActions';


const MyActionsTabItem = ( { data } ) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const token = useSelector(state => state.start.token);

    const [dropDownMenu, setdropDownMenu] = useState(styles.noDisplay)
    const [buttonActivate, setbuttonActivate] = useState(styles.tdBtn)
    const [delPopup, setDelPopup] = useState( false )

    function dropDownToggle() {
        if (dropDownMenu === styles.noDisplay) {
            setdropDownMenu(styles.dropDown)
            setbuttonActivate(styles.btnActive)
        }
        else {
            setdropDownMenu(styles.noDisplay)
            setbuttonActivate(styles.tdBtn)
        }
    }

    return (
        <div className={styles.objTabItem}>
            <Link to={`/lease/actionItem/${data?.id}`}>
                <div className={styles.tdName}>
                    <div>{ !!data?.leaseActionId && data.leaseActionId }</div>
                    {/* Дія{data.leaseActionId === null ? '' : ' № ' + data.leaseActionId} */}
                    <div style={{fontWeight: 'normal'}}>{ !!data?.description && getShorter( isMongo(data.description), 200 ) }</div>
                </div>
            </Link>
            <div className={styles.tdStat}>{ getLeaseActionType(data?.type) }</div>
            <div className={styles.tdStatus}>{
                data?.leaseActionId === null ? 'Чернетка' : 
                <div style={{color: 'var(--green)'}}>Опубліковано</div> }
            </div>
            <div className={styles.tdRent}>
                { checkProp(data, 'datePublished') ? dateTransform(data.datePublished, 'time') : '' }
            </div>
            <div className={styles.tdAction}>{ !!data?.documents ? data.documents.length : '' }</div>
            <div width="tdBtnWrap">
                <div className={buttonActivate} onClick={dropDownToggle}>
                    <svg width="40" height="40" xmlns="http://www.w3.org/2000/svg" viewBox="-11 1 46 4">
                        <g id="Слой_2" data-name="Слой 2">
                            <g id="Слой_1-2" data-name="Слой 1">
                            <path className={styles.svgIcon} d="M5.33,2.67A2.67,2.67,0,1,0,2.67,5.34,2.66,2.66,0,0,0,5.33,2.67Z"/>
                            <path className={styles.svgIcon} d="M14.67,2.67A2.67,2.67,0,1,0,12,5.34,2.67,2.67,0,0,0,14.67,2.67Z"/>
                            <path className={styles.svgIcon} d="M24,2.67a2.67,2.67,0,1,0-2.67,2.67A2.66,2.66,0,0,0,24,2.67Z"/>
                            </g>
                        </g>
                    </svg>
                </div>
            </div>
            <div className={dropDownMenu}>
                <div className={styles.dropdownContent}>
                    <ul>
                        { isDraftObject( data?.id ) &&
                            <li><Link 
                                    to={`/lease/actionItem/draft/${data?.id}`}
                                    // status={data.apiIdentifier ? 'draft' : 'published'}
                                >
                                    Редагувати
                            </Link></li>
                        }
                        { isDraftObject( data?.id ) &&
                            <li onClick={ ()=> {
                                        setDelPopup( true )
                                        dropDownToggle()
                                    }
                                } 
                            >
                                    Видалити чернетку
                            </li>
                        }
                        { !!data?.leaseActionId &&
                            <li>
                                <Link 
                                    to={`/lease/actionItem/${data?.id}`}
                                    // status={data.apiIdentifier ? 'draft' : 'published'}
                                >
                                    Відкрити
                                </Link>
                            </li>
                        }
                        {/* <li><Link to={'/'}>Дії до цієї дії</Link></li> */}
                    </ul>
                </div>
                <div className={styles.dropdownBlock} onClick={dropDownToggle}></div>
            </div>
            { delPopup && <UniPopUp 
                                type={2}
                                data={'Ви дійсно хочете видалити чернетку?'}
                                close={ ()=> { 
                                        setDelPopup( false )
                                        dropDownToggle()
                                    }
                                }
                                action={ async ()=> {
                                        setDelPopup( false )
                                        await leaseActionDelete( urlGetMyDraftAction( data.id ), token.access_token, ()=> navigate('/lease/my_actions'), dispatch )
                                        dispatch( actionsGetMy( urlGetMyActions, token.access_token ));
                                    }
                                }
                            /> 
            }
        </div>
        )
}

export default MyActionsTabItem