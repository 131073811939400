import React from 'react';
import styles from './elems.module.css';
import { useDispatch, useSelector } from 'react-redux';

import FullInfoBlock from '../../../../elements/fullInfoBlock/fullInfoBlock';
import HalfInfoBlock from '../../../../elements/halfInfoBlock/halfInfoBlock';
import { dateTransform, isMongo } from '../../../../../utils/check';
import { getExCompletionDocType } from '../../utils';
import PopDownBlock from '../../../../elements/popdownBlock/popDownBlock';
import ViewDocItem from '../../../../elements/documents/view/viewDocumentItem';

const ExecusionCompletion= ( { data } ) => {
    const executionItem = useSelector(state => state.asset.executionItem)

    return <div className={styles.infoBlockOrgContent}>
                        {!!data?.dateSigned &&
                            <HalfInfoBlock
                            title={'Дата завершення умов виконання приватизації'}
                            data={ dateTransform( data.dateSigned, 'time' ) }
                            />
                        }
                        {!!data?.comment &&
                            <FullInfoBlock
                                title='Коментар'
                                data={ isMongo(data.comment)}
                            />
                        }
                        { ( !!data.documents && data.documents.length > 0 ) && 
                            <PopDownBlock title={`Документи (${data.documents.length})`} 
                                content={
                                    data.documents.map( i => <ViewDocItem 
                                                                key={i.id}
                                                                data={ i } 
                                                                specs={{
                                                                    objId: data.id,
                                                                    docType: getExCompletionDocType(i.documentType),
                                                                    historyURL: `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/executions/${executionItem.id}/completion/document/${i.id}/history`
                                                                }}
                                                            /> )
                                }
                            />
                        }
        </div>
}

export default ExecusionCompletion