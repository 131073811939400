import React, {useEffect, useState} from 'react';
import styles from './requestItemPage.module.css';
import { useSelector, useDispatch } from 'react-redux';

import LeaseRequestSidebarView from './sidebar/sidebarView';
// import LeaseRequestViewTitle from './titleBar/titleBar';

import { isMongo } from '../../../../utils/check';
import { useNavigate, useLocation } from 'react-router-dom';
import { getLeaseReqById, getLeaseReqByPublicId } from '../../../../utils/endPoints';
// import LeaseReqestItem from '../../objectItemPage/blocks/leaseReq/leaseRequestItem';
import LeaseReqestItem from './elems/leaseRequestItem';

const RequestItem = ( props ) => {

    const dispatch = useDispatch()
    // const request = useSelector(state => state.start.myRequest)
    const token = useSelector(state => state.start.token);

    const [edit, setEdit] = useState(false)
    const [request, setRequest] = useState(null)
    const [object, setObject] = useState(null)

    const navigate = useNavigate()
    const { pathname } = useLocation()

    let reqId = pathname.split('/').at(-1)
    let idReg = new RegExp(/[R][G][L][R]\d./)

    useEffect(() => {
        //let url = reqId.match(idReg) ? getLeaseReqByPublicId( reqId ) : getLeaseReqById( reqId )
        let url = getLeaseReqByPublicId( reqId )
        let tmpToken = !!token ? `Bearer ${token.access_token}` : null
        //let header = { 'Content-Type': 'application/json'/*, 'Authorization': tmpToken*/ }
        let header = tmpToken === null ? { 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json', 'Authorization': tmpToken }
        // const url = isDraftObject(reqId) ? getLeaseReqById(reqId) : 
        getData(url, header, setRequest )
        return () => {
            dispatch({payload: null, type: 'REQUEST_GET_MY'})
          }
    }, [])

    useEffect(() => {
        if (!!request) {
            let objUrl = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/registry_object/${request.relatedObjectId}`
            let header = { 'Content-Type': 'application/json' }
            getData(objUrl, header , setObject)
        }
    }, [request])

    function getData(url, header, action){
        fetchData(url, header, action)
    }

    async function fetchData(url, header, action){
        let result = await fetch(url, {
                method: 'GET',
                headers: header,
                // token: token
            }).then(response => {return response.json()})
            .catch( err => console.log(err))
        action(result)
        return result
    }

    // if(!token) return navigate('/')
    if(object !== null && request !== null) {
        return (
            <div className={styles.wrapPage}>
                <div className={styles.objTitle}>{`Заява до об'єкта ${isMongo(object.title)}`}</div>
                <div className={styles.mainBlock}>
                    <LeaseReqestItem item={request} obj={object} />
                    <LeaseRequestSidebarView data={ request } object={object} />
                </div>
            </div>
        )
    }
    return null
}

export default RequestItem