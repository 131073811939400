import React from 'react'
import { checkProp, isMongo } from '../../../utils/check'

import HalfInfoBlock from '../../pages/objectItemPage/blocks/halfInfoBlock/halfInfoBlock'
import PopDownBlock from '../popdownBlock/popDownBlock'

const ContactPoint = ({ data, title }) => {
    return (
        <PopDownBlock
            title={ !!title ? title : 'Контактна особа'}
            content={
                <>
                    {   checkProp(data, 'name') && !!data.name
                        && 
                        <HalfInfoBlock
                            title='ПІБ'
                            data={isMongo(data.name) }
                        />
                    }
                    {   checkProp(data, 'email') && !!data.email
                        &&
                        <HalfInfoBlock
                            title='Адреса електронної пошти'
                            data={<a href={`mailto:${data.email}`}>{data.email}</a>}
                        /> 
                    }
                    {   checkProp(data, 'telephone') && !!data.telephone
                        && 
                        <HalfInfoBlock
                            title='Номер телефону'
                            data={data.telephone}
                        />
                    }
                    {   checkProp(data, 'faxNumber') && !!data.faxNumber
                        && <HalfInfoBlock
                            title='Номер факсу'
                            data={data.faxNumber}
                        />
                    }
                    {   checkProp(data, 'url') && !!data.url
                        && <HalfInfoBlock
                            title='Веб адреса'
                            data={<a href={data.url} target='blank' rel='noreffer' >{data.url}</a>}
                        />
                    }
                </>
            }
        />
    )

}

export default ContactPoint