import React, { useEffect } from 'react';
import styles from './announcementView.module.css';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import AnnouncementSidebarView from './sidebar/sidebarView';
import AnnouncementViewTitle from './titleBar/titleBar';
import { announcementGetPublicId, assetGetPublicId, announcementGetMy } from '../../../../redux/asset/assetActions';
import { getAssetById, getAnnouncementsByPublicId, getMyAnnouncement } from '../../../../utils/endPoints';
// import AssetGeneral from '../../view/elems/general';
import AnnouncementGeneral from './elems/announcementGeneral';
import Loader from '../../../loader/loader';
import Button from '../../../elements/buttons/button';

const AnnouncementView =()=> {
    const dispatch = useDispatch()
    const location = useLocation()
    const assetItem = useSelector(state => state.asset.assetItem)
    const announcementItem = useSelector(state => state.asset.announcementItem)
    const token = useSelector(state => state.start.token)

    useEffect(() => {
        const id = location.pathname.split('/')[3]
        dispatch( announcementGetPublicId( getAnnouncementsByPublicId(id)) )
        // dispatch( assetGetId( getAssetById(id), token))
      return () => {
        dispatch({type: 'ASSET_GET_ID', payload: null})
      }
    }, [])

    function setAssetViewInfo( path ){
        switch( path ){
            default:
                return <AnnouncementGeneral data={ announcementItem } />
        }
    }
    if(!announcementItem) return <Loader />
    if(!!announcementItem){
    return (
        <div className={styles.wrap}>
            { ( !!process.env.REACT_APP_LOCAL && !!token ) &&
                <div className={styles.syncButton}>
                    <Button color='gray' onClick={ ()=> dispatch( announcementGetMy( `${getMyAnnouncement(announcementItem.id)}/sync`, token.access_token )) } >SYNC</Button>
                </div>
            }
            <AnnouncementViewTitle data={ announcementItem } />
            <div className={styles.container}>
                { setAssetViewInfo(location.pathname.split('/')[3]) }
                <AnnouncementSidebarView data={ announcementItem } />
            </div>
        </div>
        )
    }
}

export default AnnouncementView