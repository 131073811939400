import React, { useEffect, useState } from 'react';
import styles from './managingOrganizations.module.css';
import { useSelector } from 'react-redux';

import HalfInfoBlock from '../../../../../../elements/halfInfoBlock/halfInfoBlock';
import FullInfoBlock from '../../../../../../elements/fullInfoBlock/fullInfoBlock';
import ContactPoint from '../../../../../../elements/contactPoint/contactPoint';
import ChooseOrganizationNEW from '../../../../../objectCreate/blocks/mainBlock/createObject/managingOrganizations/popups/chooseOrganizationNEW/chooseOrganizationNEW';
import AddOrganizationNEW from '../../../../../objectCreate/blocks/mainBlock/createObject/managingOrganizations/popups/addOrganizationNEW/addOrganizationNEW';
import { isMongo, getIdentifierType, checkProp, isDraftObject } from '../../../../../../../utils/check';

const AssetHolder = ( { data, setData } ) => {
    const [popupChoose, setPopupChoose] = useState( false )
    const [popupAdd, setPopupAdd] = useState( false )
    const assetItem = useSelector(state => state.asset.assetItem)

    const view = ( data ) => (
                <div className={styles.organization}>
                        <div className={styles.organizationButtonWrap}>
                            <button className={styles.buttonDelete} 
                                onClick={ () => setData( null) }
                            >
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M16.6448 14.4563C17.5006 13.1827 18 11.6496 18 9.99985C18 5.58157 14.4183 1.99985 10 1.99985C8.34883 1.99985 6.8145 2.50008 5.54028 3.35726L16.6448 14.4563ZM15.3278 15.9677L4.0295 4.67496C2.76722 6.0893 2 7.95498 2 9.99985C2 14.4181 5.58172 17.9999 10 17.9999C12.0463 17.9999 13.9131 17.2316 15.3278 15.9677ZM10 -0.000152588C15.5228 -0.000152588 20 4.477 20 9.99985C20 15.5227 15.5228 19.9999 10 19.9999C4.47715 19.9999 0 15.5227 0 9.99985C0 4.477 4.47715 -0.000152588 10 -0.000152588Z" fill="#FFA047"/>
                                </svg>
                            </button>
                        </div>
                    { <div className={styles.organizationTitle}>{ data.name && isMongo(data.name) }</div> }
                    <div className={styles.organizationInfoContent}>
                        <HalfInfoBlock title={ getIdentifierType(data.identifier.scheme) } data={data.identifier.id} />
                        { checkProp(data.address, 'addressID') && checkProp(data.address.addressID, 'id') 
                                &&
                            <HalfInfoBlock title="КОАТУУ" data={data.address.addressID.id} />
                        }
                        <FullInfoBlock 
                            title="Юридична адреса" 
                            data={`${data.address.postalCode}, 
                                ${isMongo(data.address.countryName)}, 
                                ${isMongo(data.address.region)}, 
                                ${isMongo(data.address.locality)}, 
                                ${isMongo(data.address.streetAddress)}`
                            } 
                        />
                        { checkProp(data, 'representativeInfo') && 
                            <FullInfoBlock 
                                title="Інформація щодо підтвердження повноважень" 
                                data={data.representativeInfo}
                            />
                        }
                    </div>
                    { checkProp(data, 'contactPoint') && <ContactPoint data={data.contactPoint}/> }
                </div> 
        )

const buttonAdd = <>
                    <button 
                        className={styles.addOrganization}
                        style={{ display: 'flex', width: '100%'}}
                        onClick={ () => setPopupChoose(true) }
                    >
                            Додати
                        </button>
                    </>

    return ( 
        <div className={styles.organizationsWrap}>
            { !!data ? view(data) : buttonAdd }
            { popupChoose && <ChooseOrganizationNEW data={ data } setData={ setData } close={ ()=> setPopupChoose(false) } /> }
            { popupAdd && <AddOrganizationNEW data={ data } setData={ setData } close={ ()=> setPopupAdd(false) } /> }
        </div>
    )
}

export default AssetHolder