import React from 'react';
import styles from './mapStyle.module.css';

const MapView = ({ data }) => {
    const mapLink = `../../../iframeMapNotEdit.html?lat=${data.latitude}&lng=${data.longitude}`

    return (
        <div className={styles.mapWrap}>
            {/* <h3 className={styles.mapTitle}>Розташування об'єкту на мапі</h3> */}
            <iframe title='locationNotEdit' className={styles.map} id="target" src={mapLink}></iframe>
        </div>
    )
}

export default MapView