import React from 'react';
import styles from './footer.module.css';

import ProzoroSalesLogo from '../elements/svg/prozorroLogo';

// import {
//     COPYRITE_FOOTER_LANG,
//     REGULATIONS_FOOTER_LANG,
//     TARIFFS_FOOTER_LANG,
//     INSTRUCTIONS_FOOTER_LANG,
//     AGREEMENT_OFFER_ON_PROVIDING_SERVICES_FOOTER_LANG,
//     CONTACT_US_FOOTER_LANG,
//     FOLLOW_FACEBOOK_FOOTER_LANG
// } from '../../model/lang/footerLang.js';
import {connect} from 'react-redux';

const Footer = ( {langFlag} ) => {
    return (
        <footer className={styles.footer}>
            <div className={styles.footer_wrap}>
                <div className={styles.borderLine}></div>
                <div className={styles.footerTextBlock}>
                    <div><a href='https://prozorro.sale/'  rel='noreferrer' target='blank'><ProzoroSalesLogo /></a></div>
                    <div className={styles.footerCopyright}>© 2014 - 2021.<br/>Усі права захищено.</div>
                    <nav className={styles.footerMenu}>
                        <div>
                            <a href='https://www.dto.com.ua/info/Tarifi'>
                                ТАРИФИ
                            </a>
                        </div>
                        <div>
                            <a href='https://www.dto.com.ua/info/Instrukcii'>
                                ІНСТРУКЦІЇ
                            </a>
                        </div>
                        <div>
                            <a href='https://www.dto.com.ua/info/agreement'>
                                ДОГОВІР
                            </a>
                        </div>
                        <div>
                            <a href='https://www.dto.com.ua/info/rules'>
                                РЕГЛАМЕНТ
                            </a>
                        </div>
                        <div>
                            <a href='https://www.dto.com.ua/info/contacs'>
                                КОНТАКТИ
                            </a>
                        </div>
                    </nav>
                    <div className={styles.soclinc}>
                        <a href='https://www.facebook.com/derzhzakupivli.online'>
                            <img src="https://www.dto.com.ua/images/fb-ico.png" alt=""/> Слідкуйте за нами в Facebook
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    )
}

const mapStateToProps = state => {
    return {
        langFlag: state.start.langFlag
    }
}

export default connect(mapStateToProps, null)(Footer)