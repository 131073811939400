import React from 'react';
import styles from './faq.module.css';
import {useSelector} from 'react-redux';

import AuthUser from './authUser/authUser.js';
import NotAuthUser from './notAuthUser/notAuthUser.js';

const Faq = () => {
    
    const token = useSelector(state => state.start.token);

    if(token === null) {
        return <NotAuthUser />
    } else {
        return <AuthUser />
    }
}

export default Faq;