import React from 'react';
import styles from './titleBar.module.css';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getShorter, isMongo } from '../../../../../utils/check';
import Button from '../../../../elements/buttons/button';

const AssetViewTitle =({ data })=>  {
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const user = useSelector(state => state.start.userProfiles)
    const path = pathname.split('/')[4]

    function getLastPath(path){
        switch(path){
            case '1': return 
            default: return 'Загальна інформація'
        }
    }

    return (
        <div>
                <div className={styles.navBlock}>
                    <ul>
                        <li key={1}>
                            <Link to={data.assetId.indexOf("RAL") !== -1 ? `/asset/assets_list_lp` : `/asset/assets_list`} >
                                {data.assetId.indexOf("RAL") !== -1 ? "Перелік об’єктів великої приватизації" : "Перелік об’єктів малої приватизації"}
                            </Link>
                        </li>
                        <li key={2} className={styles.liTitle}>{data.title ? getShorter( isMongo(data.title) , 80) : 'Новий об\'єкт'}</li>
                        <li key={3}>{ getLastPath(path) }</li>
                    </ul>
                    <div className={styles.titleAndSend}>
                        <div className={styles.objTitle}>
                            { !!data?.title ? isMongo(data.title) : 'Створення нового об`єкту' }
                        </div>
                        <div className={styles.sendButton}>
                            { ( !!data?.assetCustodian?.identifier?.id && 
                            !!user?.organization?.identifier?.identifier && 
                            data.assetCustodian.identifier.id === user.organization.identifier.identifier && 
                            data.status === 'pending') && 
                                <Button color='gray' onClick={ ()=> navigate(`/asset/edit/${data.id}`) }>Редагувати об'єкт</Button> 
                            }
                        </div>
                    </div>
                    <div className={styles.objNav}>
                        <div className={ !path ? styles.activeNavItem : styles.objNavItem } onClick={ ()=> navigate('') }>
                            Загальна інформація
                        </div>
                    </div>
                </div>
        </div>
        )
}

export default AssetViewTitle