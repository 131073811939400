import React, { useState } from 'react';
import styles from './sidebarView.module.css';

import { checkProp, numFormat, setRedemptionStatus, getCurrency } from '../../../../../utils/check';

const AnnouncementSidebarView = ( { data } ) => {
    let images = []

    function setAmount() {
        if (checkProp(data, 'initialProps')) {
            if (checkProp(data.initialProps, 'value')) {
                return (
                    <div className={ styles.lotStartPriceBlock }>
                        <p>Стартова ціна, { data.initialProps.value.valueAddedTaxIncluded ? 'з ПДВ' : 'без ПДВ' }</p>
                        <p className={styles.lotStartPrice}>{ numFormat.format(data.initialProps.value.amount) }<span> { getCurrency(data.initialProps.value.currency) }</span></p>
                    </div>
                )
            }
        } else {
            return null
        }
    }

    return (
        <>
            <div className={styles.sideBlock}>
                <div className={styles.sidelotImage}></div>
                <div className={styles.aucStatus}>
                    <p>Статус</p>
                    <div className={styles.lotStatus}>{ setRedemptionStatus(data.status)}</div>
                </div>
                    { setAmount() }
                { data?.objectId &&
                    <div className={ styles.lotInfoBlock }>
                        <p>Номер в ЦБД</p>
                        <p className={styles.lotInfo}>{ data.objectId }</p>
                    </div>
                }
                { data?.owner &&
                    <div className={ styles.lotInfoBlock }>
                        <p>Ідентифікатор майданчика</p>
                        <p className={styles.lotInfo}>{ data.owner }</p>
                    </div>
                }
            </div>
        </>
        )
}

export default AnnouncementSidebarView