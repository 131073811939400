import React from 'react';
import styles from './news.module.css';
import {useSelector, useDispatch} from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../../hook/useAuth';

import {NEWS_TITLE_TOP, NEWS_TITLE_BOTTOM} from '../../../model/lang/topMenu.js';
import {localClearToken, setProfileNull} from '../../../redux/actions/default/default';

import SvgNews from './news.svg';
import SvgArrow from './user_menu_arrowdown.svg';

const News = () => {
    const dispatch = useDispatch();
    const langFlag = useSelector(state => state.start.langFlag);
    const token = useSelector(state => state.start.token);
    const userProfiles = useSelector(state => state.start.userProfiles);
    const { signout } = useAuth()
    const { pathname } = useLocation()
    const route = pathname.split('/')[1]

    let tmp;
    if(token === null) {
        tmp = (
            <div className={styles.wrapNews} >
                <a className={styles.korusne_link} href="https://news.dto.com.ua/">
                    <div className={styles.wrap_link}>
                        <div className={styles.korusne_img}>
                            <img src={SvgNews} alt='' />
                            <div className={styles.korusne_title}>{ NEWS_TITLE_TOP[langFlag] }</div>
                        </div>
                        <div className={styles.korusne_text}>{ NEWS_TITLE_BOTTOM[langFlag] }</div>
                    </div>
                </a>
            </div>
        )
    } else {
        tmp = (
            <div className={styles.wrapNews} >
                <div className={styles.wrap_link}>
                    <div className={styles.korusne_img}>
                        <div className={styles.korusne_title}>МІЙ КАБІНЕТ</div>
                        <img className={styles.svgArrow} src={SvgArrow} alt='' />
                    </div>
                    <div className={styles.korusne_text}>Меню користувача</div>
                    <div className={styles.popUp}>
                        { ( !!userProfiles && !!userProfiles.canCreateAssets ) &&
                            <Link to={`/asset/my_assets_lp`} 
                                className={ pathname === '/asset/my_assets_lp' ? styles.popUp_menuElemActive : styles.popUp_menuElem }>
                                Мої об'єкти великої приватизації
                            </Link>
                        }
                        { ( !!userProfiles && !!userProfiles.canCreateAssets ) &&
                            <Link to={`/asset/my_assets`} 
                                className={ pathname === '/asset/my_assets' ? styles.popUp_menuElemActive : styles.popUp_menuElem }>
                                Мої об'єкти малої приватизації
                            </Link>
                        }
                        { ( !!userProfiles && !!userProfiles.canCreateAnnouncements ) &&
                            <Link to={`/asset/my_announcements`} 
                                className={ pathname === '/asset/my_announcements' ? styles.popUp_menuElemActive : styles.popUp_menuElem }>
                                Мої інформаційні повідомлення
                            </Link>
                        }
                        { ( !!userProfiles && !!userProfiles.canCreateAnnouncements ) &&
                            <Link to={`/asset/my_redemptions`} 
                                className={ pathname === '/asset/my_redemptions' ? styles.popUp_menuElemActive : styles.popUp_menuElem }>
                                Мої пріоритетні викупи
                            </Link>
                        }
                        { ( !!userProfiles && !!userProfiles.canCreateExecutions ) &&
                            <Link to={`/asset/my_executions`} 
                                className={ pathname === '/asset/my_executions' ? styles.popUp_menuElemActive : styles.popUp_menuElem }>
                                Мої контрактинги
                            </Link>
                        }
                        { ( !!userProfiles && !!userProfiles.canCreateObjects ) &&
                            <Link to={`/lease/my_objects`} 
                                className={ pathname === '/lease/my_objects' ? styles.popUp_menuElemActive : styles.popUp_menuElem }>
                                Мої об'єкти оренди
                            </Link>
                        }
                        { ( !!userProfiles && !!userProfiles.canCreateObjects ) &&
                            <Link to={`/lease/requests_to_my_objects`} 
                                className={ pathname === '/lease/requests_to_my_objects' ? styles.popUp_menuElemActive : styles.popUp_menuElem }>
                                Заяви до моїх об'єктів
                            </Link>
                        }
                        { ( !!userProfiles && !!userProfiles.canCreateActions ) &&
                            <Link to={`/lease/my_actions`} 
                                className={ pathname === '/lease/my_actions' ? styles.popUp_menuElemActive : styles.popUp_menuElem }>
                                Мої дії
                            </Link>
                        }
                        { ( !!userProfiles && !!userProfiles.canCreateRequests ) &&
                            <Link to={`/lease/my_requests`} 
                                className={ pathname === '/lease/my_requests' ? styles.popUp_menuElemActive : styles.popUp_menuElem }>
                                Мої заяви
                            </Link>
                        }
                        <div
                            className={styles.popUp_menuElem + ' ' + styles.popUp_menuElemEnd}
                            onClick={() => {
                                dispatch(localClearToken())
                                dispatch(setProfileNull(null))
                                signout()
                            }}
                        >
                            Вихід
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    return tmp;
}

export default News;