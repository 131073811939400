import React, {useState, useEffect} from 'react'
import styles from './order.module.css'
import Input from '../../../../../inputs/input'
import InputDate from '../../../../../inputs/inputDate'
import InputTextArea from '../../../../../inputs/inputTextArea'
import moment from 'moment'
import CreateDocItem from '../../../../../elements/documents/docItem/documentItemOrder'
import { useDispatch, useSelector } from 'react-redux'
import { urlGetOrderMyExecution, urlPatchMyExecutionStatus, urlPatchOrderDocumentMyExecution, urlPostOrderDocumentMyExecution } from '../../../../../../utils/endPoints'
import { axiosDeleteAPI, axiosPatchAPI } from '../../../../../../redux/actions/crud'
import AddDocPopUp from '../../../../../elements/documents/addDocPopup/addDocPopup'
import Button from '../../../../../elements/buttons/button'
import WarningBlock from '../../../../../elements/warningBlock'

const ExecutionOrderEdit = ({ data }) => {

    const [orderData, setOrderData] = useState({ number: data.number, dateSigned: data.dateSigned, comment: data.comment })
    const [addDocPopUp, setAddDocPopUp] = useState( false )
    const [ edit, setEdit] = useState( false )
    const executionItem = useSelector(state => state.asset.executionItem)
    const token = useSelector( state => state.start.token )
    const dispatch = useDispatch()
    
    const docProps = { 
        dispatch: ( res )=> dispatch( { payload: res, type: 'EXECUTION_GET_ID'}),
        docOf: 'order',
        object: executionItem,
        docList: setOrderDocTypes( data.documents ),
        url: {
            patch: urlPatchOrderDocumentMyExecution,
            post: urlPostOrderDocumentMyExecution,
            delete: ( id ) => execOrderDocDelete( id ),
            history: (id, docId) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/executions/${executionItem.id}/order/document/${docId}/history`
        }
    }

    const docListProps = { 
        dispatch: ( res )=> dispatch( { payload: res, type: 'EXECUTION_GET_ID'}),
        docOf: 'order',
        object: executionItem,
        docList: setOrderDocTypes( data.documents, 'list' ),
        url: {
            patch: urlPatchOrderDocumentMyExecution,
            delete: ( id ) => execOrderDocDelete( id ),
            //delete: urlPatchCompDocumentMyExecution
            history: (id, docId) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/executions/${executionItem.id}/order/document/${docId}/history`
        },
        buttons: {
            //edit: executionItem.status === 'pending' || executionItem.status === "active" ? true : false,
            edit: executionItem.status === 'pending' ? true : false,
            delete: executionItem.status === 'pending' ? true : false,
            download: true
        }
    }

    function setOrderDocTypes(docs, bool = false) {
        if (bool === 'list') return [ { name: 'Наказ про приватизацію', value: 'order'}, { name: 'Додаток', value: 'addition'}]
        for ( let key in docs ) {
            if (docs[key].documentType === 'order') {
                return bool ? true : [{ name: 'Додаток', value: 'addition'}]
            }
        }
        return bool ? false : [
             { name: 'Наказ про приватизацію', value: 'order'},
             { name: 'Додаток', value: 'addition'}
        ]
    }

    function execOrderDocDelete( id ) {
        let deleteData = {
                url: urlPatchOrderDocumentMyExecution( executionItem.id, id ),
                dispatch: dispatch,
                set: ( data )=>  dispatch( { payload: data, type: 'EXECUTION_GET_ID'}),
                // body: resBody,
                // redirect: ()=> navigate(`/lease/create/${objectData.id}/contracts`),
                token: token.access_token,
        }
        axiosDeleteAPI( deleteData )
    }
    
    return (
        <div className={styles.orderWrap}>
            {executionItem.status === 'pending' &&
                <WarningBlock
                    data={
                        <div>
                            Для публікації наказу необхідно:
                            <ul>
                                <li>Заповнити поля</li>
                                <li>Додати документ "Наказ про приватизацію"</li>
                                <li>Натиснути кнопку "Опублікувати наказ" для зміни статусу контрактингу</li>
                            </ul>
                        </div>
                    }
                />
            }
             <div className={styles.twoInLine}>
                <Input 
                    value={ orderData.number } 
                    onChange={ (e)=> setOrderData( prev => ({ ...prev, number: e }) ) } 
                    label={'Номер наказу'} 
                    reqiured
                    disabled={ edit ? false : true }
                />
                <InputDate 
                    data={{
                        label: 'Дата наказу',
                        value: orderData.dateSigned,
                        onChange: e => setOrderData( prev => ({ ...prev, dateSigned: e }) ),
                        time: true,
                        disabled: edit ? false : true
                        // alert: moment(orderData.dateSigned).isBefore( moment() ) ? 'Перевірте дату' : false
                    }}
                />
                
            </div>
            <InputTextArea 
                value={ orderData.comment } 
                onChange={ (e)=> setOrderData( prev => ({ ...prev, comment: e }) ) } 
                label={'Коментар'}
                disabled={ edit ? false : true }
            />
            { executionItem.status === 'pending' &&
                <div className={styles.editButton}>
                    <Button
                        onClick={async () => {
                            if (edit) {
                                await axiosPatchAPI({
                                    url: urlGetOrderMyExecution(executionItem.id),
                                    dispatch: dispatch,
                                    set: (data) => dispatch({ payload: data, type: 'EXECUTION_GET_ID' }),
                                    body: orderData,
                                    token: token.access_token,
                                })
                            }
                            setEdit(prev => !prev)
                        }}
                        disabled={moment(orderData.dateSigned).isAfter(moment())}
                    >
                        {edit ? 'Зберегти' : 'Редагувати'}
                    </Button>
                </div>
            }
            <div className={styles.documentsWrap}>
                
                <div className={styles.documentsList}>
                    <div style={{ fontWeight: '600'}}>Документи</div>
                    { data.documents?.length < 1 &&
                        <div style={{ width: '100%', textAlign: 'center'}}>Додайте документи до наказу</div>
                    }
                    { data.documents?.map(i => <CreateDocItem data={i} key={i.id} docProps={ docListProps } />) }
                </div>
                { executionItem.status === 'pending' &&
                    <div className={styles.documentsAddButton}>
                    <Button onClick={() => setAddDocPopUp(true)} >Додати документ</Button>
                </div>
                }
                { ( setOrderDocTypes( data.documents, true ) && executionItem.status === 'pending' ) &&
                    <div className={styles.postButton}>
                        <Button
                            color={'white'} height='50px'
                            onClick={() => {
                                axiosPatchAPI({
                                    url: urlPatchMyExecutionStatus(executionItem.id, 'active'),
                                    dispatch: dispatch,
                                    set: (data) => dispatch( { payload: data, type: 'EXECUTION_GET_ID'} ),
                                    // body: orderData,
                                    token: token.access_token,
                                    success: ()=> dispatch( {type: 'UNIVERSAL_ERROR', payload: 'Наказ про приватизацію опубліковано!' })
                                })
                            } }
                        >
                            Опублікувати наказ про приватизацію
                        </Button>
                    </div>
                }
                { addDocPopUp && <AddDocPopUp docProps={docProps} close={ ()=> setAddDocPopUp( false ) } /> }
            </div>
        </div>
    )
}

export default ExecutionOrderEdit