import React from 'react';
import styles from './executionItemCard.module.css';
import { useSelector } from 'react-redux';
import {Link, useNavigate} from 'react-router-dom';
import { checkProp, numFormat, getCurrency, getAddedTax, dateTransform, isMongo } from '../../../../../utils/check'
import { setExecutionStatus } from '../../../execution/utils';

const ExecutionItemCard = ( { item } ) => {
    const user = useSelector(state => state.start.userProfiles)
    const navigate = useNavigate()

    //бейдж "Ваш обьект"
    function yourObjectBadge() {
        let status = false
        if (!user) return null
        if (user && item && item.assetCustodian) {
            if ( !!item?.assetCustodian?.identifier?.id && item.assetCustodian.identifier.id === user.organization.identifier.identifier) {
                status = true
            }
        }
        if (status) return <div className={styles.yourObjBadge}>Ваше повідомлення</div>
        else return null 
    }

    // function getAmount() {
    //     if (checkProp(item, 'initialProps')) {
    //         if (checkProp(item.initialProps, 'value')) {
            
    //             let blockTmp = item.initialProps.value
    //             let amount = checkProp(blockTmp, 'amount') ? numFormat.format(blockTmp.amount) : ' не вказано'

    //             return (
    //                 <>
    //                     <div className={styles.costText}>{`Стартова ціна, ${getAddedTax(blockTmp.valueAddedTaxIncluded)}`}
    //                     </div>
    //                     <div className={styles.costSumm}>
    //                         {`${amount} ${getCurrency(blockTmp.currency)}`}
    //                     </div>
    //                 </>
    //             )
    //         }
    //     } else {
    //         return (
    //             <>
    //                 <div className={styles.costText}>{`Стартова ціна`}
    //                 </div>
    //                 <div className={styles.costSumm}>
    //                     {`не вказано`}
    //                 </div>
    //             </>
    //         )
    //     }
    // }

    function getREntityType( i ){
        switch (i.type) {
            case 'asset':
                return <Link to={`/asset/${i.objectId}`}>{`Об'єкт МП - ${i.objectId}`}</Link>
            case 'large_asset':
                return <Link to={`/asset/${i.objectId}`}>{`Об'єкт ВП - ${i.objectId}`}</Link>
            case 'announcement':
            case 'large_announcement':
                return <Link to={`/asset/announcement/${i.objectId}`}>{`Інф. повідомлення - ${i.objectId}`}</Link>
            case 'legacy_asset':
                return <a target={"_blank"}
                          href={`https://prozorro.sale/planning/${i.objectId}/`}>{`Об'єкт - ${i.objectId}`}</a>
            case 'legacy_announcement':
                return <a target={"_blank"}
                          href={`https://prozorro.sale/planning/${i.objectId}/`}>{`Інф. повідомлення - ${i.objectId}`}</a>
            default:
                return i.type
        }
    }

    return (
        <div className={styles.objItem}>
            <div className={styles.leftBlock}>
                    <div className={styles.unactive}></div>
                </div>
                <div className={styles.centralBlock}>
                    <div className={styles.topBlock}>
                        <div className={styles.objIdAndType}>
                            <div className={styles.objIdAndType_id} >
                                {item.executionId.startsWith('UA-')
                                    ?
                                    <a target={"_blank"} href={`https://prozorro.sale/planning/${item.executionId}/`}>{ item.executionId }</a>
                                    :
                                    <Link to={`/asset/execution/${item.executionId}`}>{ item.executionId }</Link>
                                }
                            </div>
                            {/* { yourObjectBadge() } */}
                            { checkProp(item, 'title') && <div style={{marginLeft: 'auto', color: 'var(--darkGray)'}}>Контрактинг</div> }
                        </div>
                            <div className={styles.objTitle} >
                                {item.executionId.startsWith('UA-')
                                    ?
                                    <a target={"_blank"} href={`https://prozorro.sale/planning/${item.executionId}/`}>{ item.executionId }</a>
                                    :
                                    <Link to={`/asset/execution/${item.executionId}`}>
                                        { !!item?.title && isMongo( item.title ) }
                                    </Link>
                                }
                            </div>
                        <div className={styles.objDiscription}>
                            {/* { item?.assetItems.map( item => <li key={item.id}>{ isMongo(item.classification.description) }</li> ) } */}
                        </div>
                    </div>
                    <div className={styles.bottomBlock}>
                        <div style={{color: '#696974'}}>Пов'язані сутності:</div>
                        { item.relatedEntities.map( i => <div className={styles.objOwner} key={i.objectId}>
                                                            { getREntityType(i) }
                                                        </div>)
                        }
                    </div>
                </div>
                <div className={styles.rightBlock}>
                <div className={`${styles.rightBlock_Status}`}>{ item?.status && setExecutionStatus( item.status ) }</div>
                    <div className={styles.rightBlock_Date}>
                        <div className={styles.rightBlock_Start}>
                            <div className={styles.rightBlock_date_text}>Дата публікації</div>
                            <div className={styles.rightBlock_date_time}>{ dateTransform( item.datePublished ) }
                                <span className={styles.rightBlock_date_timeSpan}>{dateTransform( item.datePublished, 'onlyTime' )}</span>
                            </div>
                        </div>
                        <div className={styles.rightBlock_Start}>
                            <div className={styles.rightBlock_date_text}>Дата зміни</div>
                            <div className={styles.rightBlock_date_time}>{ dateTransform( item.dateModified ) }
                                <span className={styles.rightBlock_date_timeSpan}>{dateTransform( item.dateModified, 'onlyTime' )}</span>
                            </div>
                        </div>
                    </div>
                    {/* <div className={styles.rightBlock_CostAndButtons}>
                        { getAmount() }
                    </div> */}
                    <div className={styles.buttonsLine}>
                            <div className={styles.buttonView}
                                onClick={()=>{
                                    navigate(`/asset/execution/${item.executionId}`)
                                }}>
                                    Переглянути
                            </div>
                    </div>
                </div>
            </div> 
    )
    
}

export default ExecutionItemCard