import React, { useState, useEffect } from 'react';
import styles from './generalInfo.module.css';
import { useDispatch, useSelector } from 'react-redux';

import Input from '../../../../../../inputs/input';
import Checkbox from '../../../../../../inputs/checkBox';
import InputSelect from '../../../../../../inputs/inputSelect';
import InputTextArea from '../../../../../../inputs/inputTextArea';
import FullInfoBlock from '../../../../../../elements/fullInfoBlock/fullInfoBlock';
import HalfInfoBlock from '../../../../../../elements/halfInfoBlock/halfInfoBlock';
import Loader from '../../../../../../loader/loader';

import { objectGetTypes, objectUpdate } from '../../../../../../../redux/actions/object/object.js';
import { checkProp, getTranslate, isMongo } from '../../../../../../../utils/check';
import { myDraftObject, myObjectPrivate } from '../../../../../../../utils/endPoints';
import Button from '../../../../../../elements/buttons/button';

const GeneralInfo = ({ data, updateData, children, isDraft } ) => {

    const dispatch = useDispatch();
    const token = useSelector(state => state.start.token);
    const pageCreate = useSelector(state => state.start.objectPage);
    const object = useSelector(state => state.start.objectGet);
    const [ update, setUpdate ] = useState(false);

    const objectTypesList = useSelector(state => state.start.objectTypesList);
    const objectCreate = useSelector(state => state.start.objectCreate);
    const urlObjectTypesList = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/public/dictionaries/legitimateLeaseItemType`;
    
    // const [type, setType] = useState(data.type);
    const [title, setTitle] = useState( !!data.title ? isMongo(data.title) : '');
    const [registryDecision, setRegistryDecision] = useState( !!data.registryDecision?.verificationPresence ? data.registryDecision.verificationPresence : false );
    const [registryDecisionDocuments, setRegistryDecisionDocuments] = useState( !!data.registryDecision?.x_verificationDocuments ? data.registryDecision.x_verificationDocuments : '' );
    const [errorTitle, setErrorTitle] = useState('');
    const [errorType, setErrorType] = useState('');

    let generalInfo = {
        // type: type,
        title: {
            uk_UA: title
        },
        registryDecision: {
            get x_verificationDocuments(){ if ( !!registryDecision ) return registryDecisionDocuments },
            verificationPresence: registryDecision === 'true' || registryDecision === true ? true : false
        },
    }

    useEffect(() => {
        if( !objectTypesList ) {
            dispatch(objectGetTypes(urlObjectTypesList));
        }
    }, []);
    
    useEffect(() => {
        updateData(generalInfo);
    }, [ title, registryDecision, registryDecisionDocuments]);

    useEffect(() => {
        checkErrors();
    });

    function checkErrors() {
        if(objectCreate) {
            if(objectCreate.hasOwnProperty('message') === true) {
                //Reset errors
                setErrorType('');
                //New errors
                for (let key in objectCreate.message) {
                    if (key == 'title') {
                        setErrorTitle(objectCreate.message[key]);
                        window.scrollTo(0, 0);
                    } else if (key == 'type') {
                        setErrorType(objectCreate.message[key]);
                        window.scrollTo(0, 0);
                    }
                }
            } else {
                //Reset errors
                setErrorTitle('');
                setErrorType('');
            }
        }
    }

    function updateObject() {
        if (token !== null) {
            let body = JSON.stringify(generalInfo);
            // body.title = title;
            // body = JSON.stringify(body);
            // let urlUpdate = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/registry_object/${object.id}`;
            let redirect = `/lease/create/${object.id}`;
            const getUrl =( id )=> {
                if(isDraft) return myDraftObject(id)
                return myObjectPrivate(id)
            }
            dispatch(objectUpdate(getUrl(object.id), body, token.access_token, redirect));
        } else {
            alert('Авторизуйтесь');
        }
    }

    return(
        <div className={styles.wrapper}>
            <div className={styles.title}>Загальна інформація</div>
            {pageCreate === 'create' ? <>
                <Input
                    onChange={setTitle}
                    error={errorTitle}
                    value={title}
                    label="Назва об'єкту реєстра *"
                />
                {/* <InputSelect
                    label="Тип об'єкту в реєстрі *"
                    error={errorType}
                    onChange={setType}
                    options={objectTypesList}
                    dictionary={true}
                    text="Оберіть"
                /> */}
                <Checkbox
                    label="Наявність рішення про затвердження переліку об’єктів, або про включення нового об’єкта до переліку"
                    onChange={setRegistryDecision}
                    value={registryDecision}
                />
                { registryDecision &&
                    <InputTextArea
                        placeholder="Реквізити підтверджуючого документу"
                        onChange={setRegistryDecisionDocuments}
                        value={registryDecisionDocuments}
                    />
                }
                </>
                    :
                    <div className={styles.blockFlex}>
                        <div className={styles.titleWrap}>
                            {update ? 
                            <div>
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                    <Input onChange={setTitle} value={title} error={errorTitle} label="Назва об'єкту реєстра *" className={styles.inputWrap} />
                                    <div style={{display: 'flex', gap: '8px'}}>
                                        <Button color={'blue'} height={'30px'} onClick={() => updateObject()}>Зберегти</Button>
                                        <Button onClick={() => setUpdate(false) } color={'gray'} height={'30px'}>X</Button>
                                    </div>
                                </div>
                                    {/* <InputSelect
                                        label="Тип об'єкту в реєстрі *"
                                        error={errorType}
                                        onChange={setType}
                                        options={objectTypesList}
                                        dictionary={true}
                                        text="Оберіть"
                                        value={type}
                                    /> */}
                                    <Checkbox
                                        label="Наявність рішення про затвердження переліку об’єктів, або про включення нового об’єкта до переліку"
                                        value={registryDecision}
                                        onChange={setRegistryDecision}
                                    />
                                    { registryDecision ?
                                        <InputTextArea
                                            placeholder="Реквізити підтверджуючого документу"
                                            onChange={setRegistryDecisionDocuments}
                                            value={registryDecisionDocuments}
                                        /> : null
                                    }
                            </div>
                                : <div style={{width: '100%'}}>
                                    <div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
                                        <FullInfoBlock title='Назва об`єкту реєстра' data={ isMongo(data.title) } />
                                        <Button color={'white'} height={'30px'} onClick={() => setUpdate(true)}>Редагувати</Button>
                                    </div>
                                    {/* <FullInfoBlock title="Тип об'єкту в реєстрі" data={getTranslate(type, objectTypesList)} /> */}
                                    {checkProp(data, 'registryDecision') && checkProp(data.registryDecision, 'verificationPresence') ?
                                        <HalfInfoBlock
                                            title="Наявність рішення про затвердження переліку об’єктів, або про включення нового об’єкта до переліку"
                                            data={data.registryDecision.verificationPresence ? 'Присутня' : 'Відсутня'} /> : null
                                    }
                                    {data.registryDecision.verificationPresence ?
                                        <HalfInfoBlock title="Реквізити підтверджуючого документу" data={data.registryDecision.x_verificationDocuments} />
                                        : null
                                    }
                                </div>
                            }
                        </div>
                        
                    </div>
                }
            {children}
        </div>
    );
}

export default GeneralInfo;