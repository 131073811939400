import React, {useState, useEffect, useRef} from 'react';
import styles from './inputTime.module.css';

const Time = ({value, onChange, label, error, ...props}) => {
    const time = useRef();

    function setStyle(){
        if (error) return styles.alert
        return styles.timePick
    }

    return (
        <div className={props.className ? props.className : styles.inPutWrap}>
                        <input 
                            className={setStyle()}
                            type="time"
                            value={ value }
                            onChange={e => onChange(e.target.value)}
                            min="0"
                            max="23"
                            ref={time}
                            step={props.step}
                        />
                    <label className={styles.label} htmlFor={time}>{label}</label>
        </div>
    )
}

export default Time