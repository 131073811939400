import React, { useEffect, useState } from 'react';
import styles from './addBuyer.module.css';
import { useDispatch, useSelector } from 'react-redux';

import Input from '../../../../../../inputs/input.js'
import InputSelect from '../../../../../../inputs/inputSelect.js'
import InputTextArea from '../../../../../../inputs/inputTextArea';

import { objectGetUaIdentifiersList, objectGet } from '../../../../../../../redux/actions/object/object';
import { checkProp, isMongo } from '../../../../../../../utils/check';
import { getDictionaryByType, getUserOrganizations, urlPatchMyExecutionStatus } from '../../../../../../../utils/endPoints';
import { axiosPatchAPI } from '../../../../../../../redux/actions/crud';

const AddBuyer = ( { data, setData, close, index, contractId, ...props } ) => {
    const executionItem = useSelector(state => state.asset.executionItem)
    const dispatch = useDispatch();
    const token = useSelector(state => state.start.token);
    const object = useSelector(state => state.start.objectGet);
    // const koatuuList = useSelector(state => state.start.koatuuList);
    const regionsList = useSelector(state => state.start.regionList);

    // const organizationUrl = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/user/profile/organizations`;

    const objectUaIdentifiersList = useSelector(state => state.start.objectUaIdentifiersList);
    const urlUaIdentifiersList = getDictionaryByType('ua_identifiers');

    // const organizationsGetList = useSelector(state => state.start.organizationsGetList);
    // const urlOrganizationsList = getUserOrganizations;

    const [keyword, setKeyword] = useState('');

    const [legalName, setLegalName] = useState( !!data?.identifier?.legalName ? isMongo(data.identifier.legalName ) : null);
    const [identifier, setIdentifier] = useState( !!data?.identifier?.id ? data.identifier.id : null );
    const [schemeIdentifier, setSchemeIdentifier] = useState( !!data?.identifier?.scheme ? data.identifier.scheme : null );
    const [schemeIdentifierLegalName, setSchemeIdentifierLegalName] = useState( !!data?.identifier?.scheme?.legalName ? data.identifier.scheme.legalName : null );
    const [koatuu, setKoatuu] = useState( data?.address?.addressIdentifier?.identifier ? data.address.addressIdentifier.identifier : null );
    const [address, setAddress] = useState(checkProp(data, 'address') && checkProp(data.address, 'streetAddress') ? isMongo(data.address.streetAddress)  : null);
    const [locality, setLocality] = useState(checkProp(data, 'address') && checkProp(data.address, 'locality') ? isMongo(data.address.locality) : null);
    const [postalCode, setCode] = useState(checkProp(data, 'address') && checkProp(data.address, 'postalCode') ? data.address.postalCode : null);
    const [region, setRegion] = useState(checkProp(data, 'address') && checkProp(data.address, 'region') ? isMongo(data.address.region) : null);
    const [fullName, setFullName] = useState(checkProp(data, 'contactPoint') && checkProp(data.contactPoint, 'name') ? isMongo(data.contactPoint.name) : null); 
    const [email, setEmail] = useState(checkProp(data, 'contactPoint') && checkProp(data.contactPoint, 'email') ? data.contactPoint.email : null);
    const [phone, setPhone] = useState(checkProp(data, 'contactPoint') && checkProp(data.contactPoint, 'telephone') ? data.contactPoint.telephone : null);
    const [fax, setFax] = useState(checkProp(data, 'contactPoint') && checkProp(data.contactPoint, 'faxNumber') ? data.contactPoint.faxNumber : null);
    const [web, setWeb] = useState(checkProp(data, 'contactPoint') && checkProp(data.contactPoint, 'url') ? data.contactPoint.url : null);
    const [info, setInfo] = useState(checkProp(data, 'representativeInfo') ? data.representativeInfo : null);

    const [errorLocality, setErrorLocality] = useState('');

    useEffect(() => {
        if(objectUaIdentifiersList === null || objectUaIdentifiersList === undefined) {
            dispatch(objectGetUaIdentifiersList(urlUaIdentifiersList));
        }
    }, []);

    // async function createBuyer() {
    //         setErrorLocality('')
    //         if (locality.length < 3) {
    //             setErrorLocality('Значення занадто коротке. Повинно бути рівне 3 символам або більше.');
    //         } else {
    //             const body = {
    //                 // name: { uk_UA: name },
    //                 identifier: {
    //                     id: identifier,
    //                     scheme: schemeIdentifier,
    //                     legalName: { uk_UA: legalName }
    //                 },
    //                 address: {
    //                 //     addressID: {
    //                 //         scheme: 'koatuu',
    //                 //         identifier: koatuu,
    //                 //         // name: { uk_UA: koatuu.description }
    //                 //         name: 'КОАТУУ' 
    //                     // },
    //                     streetAddress: { uk_UA: address },
    //                     locality: { uk_UA: locality },
    //                     region: { uk_UA: region },
    //                     postalCode: postalCode,
    //                     countryName: { uk_UA: 'Україна' },
    //                 },
    //                 // additionalIdentifiers: [],
    //                 contactPoint: {
    //                     name: { uk_UA: fullName },
    //                     email: email,
    //                     telephone: phone,
    //                     faxNumber: fax,
    //                     url: web
    //                 },
    //                 representativeInfo: info
    //             }

    //             if ( !data ) setData( prev => ([ ...prev, body ]))
    //             else {
    //                 await setData( prev => ([prev[index] = body]))
    //                 axiosPatchAPI({
    //                     // url: urlPatchMyExecution
    //                 })
    //             }
    //             close()
    //     }
    // }


    async function createObjectData(){
        let body = {
            contractNumber: contractId.contractNumber,
            title: contractId.title,
            description: contractId.description,
            
            value: contractId.value,
            contractTotalValue: contractId.contractTotalValue,
            //reducedValue: contractReducedValue,
            //valueAddedTaxCharged: contractValueAddedTaxCharged,
            
            dateSigned: contractId.dateSigned,
            // contractTime: (contractTimeDateFrom === null || contractTimeDateFrom === '') && (contractTimeDateTill === null || contractTimeDateTill === '') ? null : {
            //     dateFrom: contractTimeDateFrom !== null &&  contractTimeDateFrom !== '' ? contractTimeDateFrom : null,
            //     dateTill: contractTimeDateTill !== null && contractTimeDateTill !== '' ? contractTimeDateTill : null
            // }
            contractTime: contractId.contractTime,
            buyers: [{
                // name: { uk_UA: name },
                identifier: {
                    id: identifier === null || identifier === '' ? null : identifier,
                    scheme: schemeIdentifier === null || schemeIdentifier === '' ? null : schemeIdentifier,
                    legalName: legalName === null || legalName === '' ? null : { uk_UA: legalName }
                },
                address: {
                    streetAddress: address === null || address === '' ? null : { uk_UA: address },
                    locality: locality === null || locality === '' ? null : { uk_UA: locality },
                    region: region === null || region === '' ? null : { uk_UA: region },
                    postalCode: postalCode === null || postalCode === "" ? null : postalCode,
                    countryName: { uk_UA: 'Україна' },
                },
                // additionalIdentifiers: [],
                contactPoint: {
                    name: fullName === null || fullName === '' ? null : { uk_UA: fullName },
                    email: email === null || email === '' ? null : email,
                    telephone: phone === null || phone === '' ? null : phone,
                    faxNumber: fax === null || fax === '' ? null : fax,
                    url: web === null || web === '' ? null : web
                },
                representativeInfo: info
            }],
        }

        //dispatch( announcementUpdate( urlContract(data.id, data.contracts[0].id) , JSON.stringify(bodyContract), token.access_token))
        const url = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/executions/${executionItem.id}/contracts/${contractId.id}`
        const response = await fetch(url, {
            method: 'PATCH',
            body: JSON.stringify(body),
            headers: {
                'Authorization': 'Bearer ' + token.access_token,
                'Content-Type': 'application/json'
            }
        }).catch(console.error)
        const json = await response.json();
        if(json.hasOwnProperty("message") !== true){
            await dispatch({type: 'EXECUTION_GET_ID', payload: json})
        }
        //if (response.status === 200 && data.redirect) {
            //window.location.href = data.redirect;
        //}
        //if (response.status === 200 && !!data.navigate) {
            //data.navigate()
        //}
        // dispatch( {type: data.actionType, payload: json} )
        //if (response.status === 200) dispatch( {type: data1.actionType, payload: json} );
        //if (response.status !== 200) dispatch( {type: 'UNIVERSAL_ERROR', payload: `Помилка ${response.status} - ${json.message}` });
        //if (response.status === 200 && data.secondAction) {
            //data.secondAction();
        //}

        close()
    }

    return(
        <div className={styles.backdrop}>
            <div className={styles.wrapper}>
                <div className={styles.popupBody}>
                    <button className={styles.closeButton} 
                        onClick={ close }
                    >
                    </button>
                    <div className={styles.title}>{ !!data ? "Редагування" : "Додавання"} інформації про покупця</div>
                    <Input 
                        value={ legalName } 
                        label="Повна юридична назва організації або ПІБ фізичної особи" 
                        onChange={ e => setLegalName(e) }
                        //reqiured
                    />
                    <div className={styles.subtitle}>Ідентифікатори організації *</div>
                    <InputSelect 
                        value={schemeIdentifier} 
                        onChange={ e => { 
                            setSchemeIdentifier(e.name)
                            setSchemeIdentifierLegalName(e.description.legalNameUa)
                        }}
                        options={objectUaIdentifiersList} 
                        uaIdentifiers={true} 
                        label="Ідентифікатори організації або особи *" 
                    />
                    <Input 
                        value={identifier} 
                        label="Код ЄДРПОУ або ІПН або паспорт *" 
                        onChange={ e => setIdentifier(e) }
                    />
                    <div className={styles.subtitle}>Адреса організації</div>
                    {/* <Input 
                        value={koatuu} 
                        label="КОАТУУ *" 
                        onChange={ e => setKoatuu(e) }
                    /> */}
                    {/* {koatuuList !== null && !koatuuList.hasOwnProperty('message') && koatuu === null ? 
                        <div className={styles.koatuuListWrapper}>
                            <ul className={styles.koatuuList}>
                                {showKoatuuList(koatuuList)}
                            </ul>
                        </div>
                    : ''} */}
                    <Input 
                        label="Країна  *" 
                        disabled={true} 
                        value="Україна" 
                    />
                    <InputSelect 
                        value={region} 
                        options={regionsList} 
                        label="Область *" 
                        onChange={ e => setRegion(e) }
                    />
                    <Input 
                        value={locality} 
                        label="Населений пункт *"
                        error={errorLocality} 
                        onChange={ e => setLocality(e) }
                    />
                    <Input 
                        value={address} 
                        label="Адреса *" 
                        onChange={ e => setAddress(e) }
                    />
                    <Input 
                        value={postalCode} 
                        label="Поштовий індекс"
                        onChange={(e) => {
                            if (e.length < 6){
                                setCode(e)
                            }
                        }}
                        //reqiured
                    />

                    <div className={styles.subtitle}>Контактна інформація</div>
                    <Input 
                        value={fullName} 
                        label="ПІБ" onChange={ e => setFullName(e) }
                        reqiured
                    />
                    <Input 
                        value={email} 
                        label="Адреса електронної пошти" 
                        onChange={ e => setEmail(e) }
                        reqiured
                    />
                    <Input  
                        value={phone} 
                        placeholder="+38(0__)___-__-__" 
                        label="Номер телефону" 
                        onChange={(e) => {
                            if (e.length < 14) {
                                setPhone(e);
                            }
                        }}
                        reqiured
                    />
                    <Input 
                        value={fax} 
                        placeholder="+38(0__)___-__-__" 
                        label="Номер факсу" 
                        onChange={(e) => {
                            if (e.length < 14) {
                                setFax(e);
                            }
                        }}
                    />
                    <Input 
                        value={web}
                        label="Веб адреса"
                        placeholder="http://www.google.com"
                        descr="Приклад: http://some.site.com" 
                        onChange={ e => setWeb(e) } 
                    />
                    <InputTextArea 
                        value={info} 
                        label="Інформація щодо підтвердження повноважень" 
                        onChange={ e => setInfo(e) } 
                    />
                    <div className={styles.buttonWrapper}>
                        <button 
                            className={styles.buttonCancel} 
                            onClick={ close }>
                            Скасувати
                        </button>
                        <button 
                                onClick={ createObjectData }
                                className={ styles.buttonCreate }
                            >
                                { !!data ? 'Оновити' : 'Додати' }
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddBuyer;