import React, { useState, useEffect } from "react"
import {useSelector, useDispatch} from 'react-redux';
import styles from './decisionItem.module.css';
import Button from "../../../../../elements/buttons/button"
import DecisionItem from "./decisionItem"

const AssetDecisionsEditLP =( { data, setData } )=> {
    const asset = useSelector(state => state.asset)
    // const [list, setList] = useState(!!data ? data : [{
    //     decisionTitle: '',
    //     decisionNumber: '',
    //     decisionDate: null,
    //     decisionOf: 'asset'
    // }])

    // useEffect(() => {
    //   if ( !data || data.length < 1){
    //     setData([...data, {
    //         decisionTitle: '',
    //         decisionNumber: '',
    //         decisionDate: null
    //     }])
    //   }
    // }, [data])

    return (
        <div className={styles.itemsWrap} > 
            {/* { !data || data.length < 1 && <DecisionItem data={ data } setData={ setData }/> } */}
            { !!data && data.map( ( item, count ) => <DecisionItem 
                                            data={ item }
                                            decisions={ data }
                                            key={ item.toString() }
                                            index={count}
                                            setData={ setData }
                                        />
                )
            }
            <div className={styles.addBtnWrap}>
                <Button color={'white'} 
                    onClick={ ()=> {
                        //if(asset.assetItem === null || asset.assetItem.status === null){
                        if(asset.assetItem.relatedEntities.length === 0){
                            setData([...data, 
                                    {
                                        decisionTitle: '',
                                        decisionNumber: '',
                                        decisionDate: null,
                                        decisionOf: 'large_asset'
                                        //decisionOf: 'asset'
                            }])
                        }
                    }}
                >
                    Додати рішення
                </Button>
            </div>
        </div>
    )
}

export default AssetDecisionsEditLP