import { checkProp, isDraftObject } from "../check";
import { getAPI, deleteAPI, patchAPI } from "../../redux/actions/crud";
import { myLeaseRequestEdit } from "../endPoints";
import axios from "axios";

export function getLeaseRequestType( type ){
    const dictionary = {
        "newLease": { "en_US": "Application for inclusion in the list", "uk_UA": "Заява про включення до переліку" },
        "lease": { "en_US": "Application for lease object wich is included to the list", "uk_UA": "Заява на оренду об'єкта включеного в перелік" },
        "agreementExtension": { "en_US": "Application for extension of the lease agreement", "uk_UA": "Заява про продовження договору оренди" }
      }
    if ( type === 'dict' ) {
        let tmpArr = []
        for (let key in dictionary ){
            let tmp = { value: key, name: dictionary[key].uk_UA }
            tmpArr = [ ...tmpArr, tmp ]
        }
        return tmpArr
    }
    if( checkProp(dictionary, type ) ) return dictionary[type].uk_UA
    else return type
}

//AXIOS EXAMPLE
export async function postLeaseReqest( data ){
        data.dispatch({ payload: true, type: 'SET_LOADER' })
        try {
            const responce = await axios.post( data.url, data.body,
                { 
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${data.token}`,
                    }
              })
                if ( responce.status === 200 ){
                    // data.dispatch({ payload: responce.data, type: 'AUCTIONS_GET' })
                    data.redirect( responce.data.id )
                }
        } catch (err) {
            data.dispatch( {type: 'UNIVERSAL_ERROR', payload: `Помилка ${ err.message }` });
        }
        data.dispatch({ payload: false, type: 'SET_LOADER' })
}

export async function patchLeaseReqest( data ){
        data.dispatch({ payload: true, type: 'SET_LOADER' })
        try {
            const responce = await axios.patch( data.url, data.body,
                { 
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${data.token}`,
                    }
              })
                if ( responce.status === 200 ){
                    // data.dispatch({ payload: responce.data, type: 'AUCTIONS_GET' })
                    data.redirect()
                }
        } catch (err) {
            data.dispatch( {type: 'UNIVERSAL_ERROR', payload: `Помилка ${ err.message }` });
        }
        data.dispatch({ payload: false, type: 'SET_LOADER' })
}

// export async function getMyDraftLeaseRequest( url, token ){
//     // data.dispatch({ payload: true, type: 'SET_LOADER' })
//     try {
//         const responce = await axios.get( url,
//             { 
//                 headers: { 
//                     'Content-Type': 'application/json',
//                     'Authorization': `Bearer ${token}`,
//                 }
//           })
//             if ( responce.status === 200 ){
//                 dispatch({ payload: responce.data, type: 'REQUEST_GET_MY' })
//                 // data.redirect()
//             }
//     } catch (err) {
//         data.dispatch( {type: 'UNIVERSAL_ERROR', payload: `Помилка ${ err.message }` });
//     }
//     // data.dispatch({ payload: false, type: 'SET_LOADER' })
// }

export function getMyDraftLeaseRequest(_url, token){
    const data = {
        url: _url,
        actionType: 'REQUEST_GET_MY',
        header: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        }
    }
    return getAPI(data);
}

export function deleteMyDraftLeaseRequestDocument(_url, token){
    const data = {
        url: _url,
        actionType: 'REQUEST_GET_MY',
        header: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        }
    }
    return deleteAPI(data);
}

export async function leaseRequestDelete( id, token, navigate, dispatch){
    dispatch({ payload: true, type: 'SET_LOADER' })
    const data = {
        url: myLeaseRequestEdit( id ),
        header: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        },
    }
    const response = await fetch(data.url, {
                method: 'DELETE',
                headers: data.header
            });
    const json = await response.json();
        if ( json.success && !!navigate ) {
            navigate()
        }
    dispatch({ payload: false, type: 'SET_LOADER' })
}

export function leaseRequestUpdate(_url, body, token){
    const data = {
        url: _url,
        actionType: 'REQUEST_GET_MY',
        body: body,
        header: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        },
        // navigate: navigate()
    }
    return patchAPI(data);
}