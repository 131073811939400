import React from 'react';
import styles from './editSidebar.module.css';
// import Button from '../../../../../elements/buttons/button';
import { Link } from 'react-router-dom';
import { checkProp } from '../../../../../../utils/check';
import { getLeaseActionType } from '../../../../../../utils/actions/actionsUtils';

const LeaseActionEditSidebar = ({ data, object }) => {

    function relatedObjects( objects ){
        if ( !!objects ){
            let tmp = []
            for ( let key in objects ){
                if ( objects[key].entity_type === 'registry' ){
                    const tmpItem = { 
                        id: objects[key].id, 
                        entity_type: objects[key].entity_type,
                        name: 'Об\'єкт',
                        link: <Link to={ `/lease/object/${ objects[key].id }` }>
                                { objects[key].id }
                            </Link>
                        }
                    tmp = [ ...tmp, tmpItem]
                }
                if ( objects[key].entity_type === 'lease_request' ){
                    const tmpItem = { 
                        id: objects[key].id, 
                        entity_type: objects[key].entity_type,
                        name: 'Заява',
                        link: <Link to={ `/lease/lease_request/${ objects[key].id }` }>
                                { objects[key].id }
                            </Link>
                        }
                    tmp = [ ...tmp, tmpItem]
                }
                if ( objects[key].entity_type === 'action' ){
                    const tmpItem = { 
                        id: objects[key].id, 
                        entity_type: objects[key].entity_type,
                        name: 'Дія',
                        link: <Link to={ `/lease/actionItem/${ objects[key].id }` }>
                                { objects[key].id }
                            </Link>
                        }
                    tmp = [ ...tmp, tmpItem]
                }
            }
            return tmp.map( i => <div key={i.id} >{ i.name }:  { i.link }</div> )
        }
    }

    return (
        <div className={styles.sideBlock}>
            { checkProp(data, 'type') 
                &&
                <div className={styles.objectType}> { getLeaseActionType(data.type) } </div>
            }
            <div className={styles.aucStatus}>
                <p>Статус</p>
                <div className={styles.lotStatus}>{ !!data?.leaseActionId ? `ОПУБЛІКОВАНО` : `ЧЕРНЕТКА` }</div>
            </div>
            <div className={styles.aucStatus}>
                <p>Пов'язані сутності</p>
                <div className={styles.relatedObj}>
                    { !!data?.relatedEntity && relatedObjects( data.relatedEntity ) }
                    <Link to={ `/lease/object/${ !!object && object.id }` }>
                        { !!object && object.registryObjectId }
                    </Link>
                </div>
            </div>
            <div className={styles.auctionSteps}>
            </div>
        </div>
    );
}

export default LeaseActionEditSidebar;