import React, { useEffect, useState, useRef } from 'react';
import styles from './inputSelect.module.css';

import Loader from '../loader/loader';
import { checkProp, isMongo } from '../../utils/check';

const InputSelect = ({ value, ...props } ) => {
    const [modalInfo, setModalInfo] = useState(styles.noDisplay)
    const [ selectList, setSelectList] = useState( false )
    // const [selectPopDown, setSelectPopDown] = useState(styles.noDisplay)
    const [option, setOption] = useState(!!props.text ? props.text : 'Оберіть')
    // const [complete, setComplete] = useState(`${styles.inPutSelect}`)
    const [info, setInfo] = useState(`${styles.inPutInfo}`)
    // const [wrapper, setWrapper] = useState(styles.noDisplay)
    const [wrapperInfo, setWrapperInfo] = useState(styles.noDisplay)
    const [find, setFind] = useState('');

    const options = useRef();
    const selectRef = useRef();

    useEffect(() => {
        if (value === null) { setOption('Оберіть') }
        // if ( !!value ) { selection( value ) }
        if (props.options !== null && !options.current) {
            options.current = props.options;
            if (props.cav !== null && props.cav) {
                selection(value);
            }
        }
    },[value]);

    useEffect(() => {
        if(props.error) {
            setInfo(`${styles.inPutInfo} ${styles.attent}`);
        } else {
            setInfo(`${styles.inPutInfo}`);
        }
    }, [props.error]);

    useEffect(() => {
        if( selectList) {
            let position = selectRef.current.getBoundingClientRect()
            if (selectRef.current.getBoundingClientRect().bottom > window.innerHeight) {
                selectRef.current.style.height = `${window.innerHeight - position.top - 20}px`
            } else if (selectRef.current.getBoundingClientRect().bottom < window.innerHeight) {
                selectRef.current.style.height = `auto`
            }
        }
    }, [selectList])
    
    useEffect( () => {
        if ((value !== null) && (value !== '') && (props.options !== '') && (!!props.options)) {
            if (props.dictionary) {
                let item = props.options.filter((i) => { return i.name === value });
                if (item !== null) selection(item[0]);
            } else if (props.uaIdentifiers) {
                let item = props.options.filter((i) => { return i.name === value });
                if (item !== null) selection(item[0]);
            } else if(props.cav) {
                for (let key in props.options) {
                    if (props.options[key].classificationId === value.id) {
                        selection(props.options[key])
                        break
                    }
                }
            } else {
                for (let key in props.options) {
                    if (props.options[key].value === value) {
                        selection(props.options[key])
                    }
                }
            }
           setSelectList(false);
        }
    }, [value]);
    
    useEffect( () => {
        if ((value !== null) && (value !== '') && (props.options !== '') && (!!props.options)) {
            if (props.uaIdentifiers) {
                let item = props.options.filter((item) => { return isMongo(item.name) === value });
                if (item !== null) selection(item[0]);
            } 
            setSelectList(false);
        }
    }, [props.options]);

    function infoToggle() {
        if (modalInfo === styles.noDisplay){
            setWrapperInfo(styles.wrapperInfo)
            setModalInfo(styles.inputInfoBlock)
        } else {
            setWrapperInfo(styles.noDisplay)
            setModalInfo(styles.noDisplay)
        }
    }

    function setStyle(){
        if(props.disabled) return styles.disabled
        return selectList ? `${styles.inPutSelect} ${styles.focus}` : styles.inPutSelect 
    }

    function selectToggle( state ) {
        if (props.disabled) return
        setSelectList( prev => !prev )
        if ( !selectList ) {
            setFind('')
        } 

    }

    function selection(e) {
        if (!!e) {
            if (checkProp(props,'dictionary')) {
                setOption(isMongo(e.description))
                // selectToggle( false )
                // setValue(isMongo(e.name))
                if (props.onChange) {
                    props.onChange(e.name)
                }
            } else if ( checkProp(props,'organization')) {
                setOption(isMongo(e.name))
                // selectToggle( false )
                // setValue(e.id)
                if (props.onChange) {
                    props.onChange(e)
                }
            } else if (checkProp(props,'uaIdentifiers') ) {
                setOption(isMongo(e.description.legalNameUa))
                // selectToggle( false )
                // setValue(isMongo(e.description.legalNameUa))
                if (props.onChange) {
                    props.onChange(e)
                }
            } else if (checkProp(props,'cav')) {
                setOption(`${e.classificationId} - ${isMongo(e.description)}`)
                // selectToggle( false )
                // setValue(e.classificationId)
                if (props.onChange) {
                    props.onChange(e)
                }
            } else {
                setOption(isMongo(e.name))
                // selectToggle( false )
                // setValue(e.value)
                if (props.onChange) {
                    props.onChange(e.value)
                }
            }
        }
        selectToggle( false )
    }

    function setList(list){
        let selectList
        const setStyle = (item) => {
            if ( item === value ) {
                return `${styles.somePart} ${styles.active}`
            }
            return `${styles.somePart}`
        }
        if (!!list) {
            if (props.dictionary) {
                selectList = list.map((item, i) => <div className={setStyle(item.name)} onClick={() => selection(item)} value={item.name} key={i}>{item.description.uk_UA}</div>)
            } else if (props.organization) {
                selectList = list.map((item, i) => <div className={setStyle(item.id)} onClick={() => selection(item)} value={item.id} key={i}>{item.name}</div>)
            } else if (props.uaIdentifiers) {
                selectList = list.map((item, i) => <div className={setStyle(item.description.legalNameUa)} onClick={() => selection(item)} value={item.description.legalNameUa} key={i}>{item.description.legalNameUa}</div>)
            } else if (props.cav) {
                selectList = list.map((item, i) => <div className={setStyle(item.id)} onClick={() => selection(item)} value={item.classificationId} key={i}>{item.classificationId} - {item.description}</div>)
            } 
            else if (props.finder) {
                selectList = list.filter( i => i.name.toLowerCase().includes(find.toLowerCase()) )
                .map((item, i) => <div className={setStyle(item.value)} onClick={() => selection(item)} value={item.classificationId} key={i}>{item.name}</div>)
                return selectList
            } 
            else {
                selectList = list.map((item, i) => <div className={setStyle(item.value)} onClick={() => selection(item)} value={item.value} key={i}>{item.name}</div>)
            }
        } else {
            selectList = <Loader />
        }
        return selectList
    }

    return (
        <>
            <div className={info}>
                {/* <input type="hidden" value={value} id={props.id}/> */}
                <div className={styles.inPutWrap}>
                    <div className={ setStyle() } onClick={ selectToggle }>
                        {option}
                    </div>
                    {props.label && 
                        <div className={styles.label}>
                            { props.reqiured ?  props.label + ' *': props.label }
                        </div>
                    }
                    { selectList && 
                        <div>
                            <div className={styles.wrapper} onClick={ selectToggle }></div>
                            <div className={styles.selectPopdown} ref={selectRef}>
                                { props.finder && 
                                    <input className={styles.findInput} 
                                        value={find} onChange={ e => setFind(e.target.value) }
                                        placeholder={'Почніть вводити текст для пошуку'}
                                    /> 
                                }
                                <div className={styles.listContent }>
                                    { setList( props.options ) }
                                </div>
                            </div> 
                        </div>
                    }
                </div>
                {props.infoBlock ? <>
                    <div className={styles.infoIcon} onClick={infoToggle}>
                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path className={styles.svgInfo} d="M11 14.0836L11 9.08362C11 8.53133 10.5523 8.08362 10 8.08362C9.44772 8.08362 9 8.53133 9 9.08362L9 14.0836C9 14.6359 9.44772 15.0836 10 15.0836C10.5523 15.0836 11 14.6359 11 14.0836Z" fill="#92929D"/>
                            <path className={styles.svgInfo} d="M9 6.08362C9 5.53133 9.44772 5.08362 10 5.08362C10.5523 5.08362 11 5.53133 11 6.08362C11 6.6359 10.5523 7.08362 10 7.08362C9.44772 7.08362 9 6.6359 9 6.08362Z" fill="#92929D"/>
                            <path className={styles.svgInfo} fillRule="evenodd" clipRule="evenodd" d="M8.74228e-07 10.0836C1.35705e-06 4.56077 4.47715 0.0836168 10 0.0836173C15.5228 0.0836178 20 4.56077 20 10.0836C20 15.6065 15.5228 20.0836 10 20.0836C4.47715 20.0836 3.91405e-07 15.6065 8.74228e-07 10.0836ZM18 10.0836C18 5.66534 14.4183 2.08362 10 2.08362C5.58172 2.08362 2 5.66534 2 10.0836C2 14.5019 5.58172 18.0836 10 18.0836C14.4183 18.0836 18 14.5019 18 10.0836Z" fill="#92929D"/>
                        </svg>
                    </div>
                    <div className={wrapperInfo} onClick={infoToggle}></div>
                    <div className={modalInfo}>
                        <p>{props.infoBlock}</p>
                        <div className={styles.bottomBtnBlock}>
                            <div className={styles.inputInfoBlockBtn} onClick={infoToggle}>
                                Зрозуміло
                            </div>
                        </div>
                    </div>
                </> : '' }
            </div>
            {props.error ? <p className={styles.descript}>{props.error}</p> : props.descr ? <div className={styles.descript}>{props.descr}</div> : ''}
        </>
    )
}

export default InputSelect;