// import { myLeaseReqDocumentEdit } from "../../../../../utils/endPoints";
import axios from "axios"

// export function deleteDocument(doc, req, token, action ){
//     const url =()=> {
//         switch(doc.documentOf){
//             case 'lease_request':
//                 return myLeaseReqDocumentEdit(req.id, doc.id)
//             default: 
//                 break
//         }
//     }

//     fetch(url(), {
//         method: 'DELETE',
//         headers: {
//             'Authorization': token,
//             'Content-Type': 'application/json'
//         },
//     })
//     .then( response => {
//         return response.json()
//     })
//     .then( data => {
//         if(data) action()
//         // if(data) window.location.reload()
//     })
//     .catch(( e ) => console.log(e));
// }

export async function getDocumentHistory( url, token, setData ) {
    try {
        const data = await axios.get( url, { headers: {
            'Authorization': token,
            'Content-Type': 'application/json'
        } })
        setData(data.data)
    } catch (err) {
    }
}