import React,{ useState, useEffect} from 'react';
import EditBankAccountPopup from './createBankAccount';
import style from './popupBank.module.css';

const BankAccountItem =({ data, count })=> {
    const [editPopup, setEditPopup] = useState( false )

    function viewTypeAccount(data){
        switch(data){
            case "UA-EDR": return "ЄДРПОУ"
            case "UA-MFO": return "МФО банку"
            case "UA-accountNumber": return "Банківський рахунок"
            case "UA-IBAN": return "Міжнародний номер банківського рахунку IBAN"
            case "FOR-IBAN": return "Міжнародний номер закордонного банківського рахунку IBAN"
        }
    }

    return (
        <div className={style.bankAccountElement}>
            { editPopup && <EditBankAccountPopup data={ data } close={ ()=> setEditPopup(false) } /> }
                            <div className={style.pn}>{count + 1}</div>
                            <div className={style.bankTitle}>{data.bankName}</div>
                            <div className={style.bankDescription}>{data.description}</div>
                            <div className={style.accountIdentificationsDescription}>{data.accountIdentifications !== null ? data.accountIdentifications[data.accountIdentifications.length - 1].description : null}</div>
                            <div className={style.accountIdentificationsIdentifier}>{data.accountIdentifications !== null ? data.accountIdentifications[data.accountIdentifications.length - 1].id : null}</div>
                            {/*<div className={style.accountIdentificationsIdentifier}>{data.accountIdentifications[data.accountIdentifications.length - 1].identifier}</div>*/}
                            <div className={style.accountIdentificationsShema}>{data.accountIdentifications !== null ? viewTypeAccount(data.accountIdentifications[data.accountIdentifications.length - 1].scheme) : null}</div>
                            <div 
                                className={style.editButton}
                                title='Редагувати банк'
                                onClick={ () => setEditPopup(true) }
                            >
                                <svg width="20" height="20" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path className={style.editSVG} fillRule="evenodd" clipRule="evenodd" d="M21.8146 8.12822L23.3009 6.64188L20.0249 3.36579L18.5385 4.85213L21.8146 8.12822ZM19.9389 10.0039L16.6628 6.72783L4.40753 18.9831L3.65351 23.0132L7.68361 22.2592L19.9389 10.0039ZM25.2559 4.8454C26.248 5.83757 26.248 7.44619 25.2559 8.43836L9.26898 24.4252C9.07985 24.6144 8.83795 24.7419 8.57504 24.7911L2.23753 25.9768C1.3211 26.1483 0.518454 25.3456 0.689915 24.4292L1.87564 18.0917C1.92483 17.8288 2.05236 17.5869 2.24149 17.3978L18.2284 1.41087C19.2205 0.418706 20.8292 0.418706 21.8213 1.41087L25.2559 4.8454ZM24.6799 26.0001H13.9868C12.2266 26.0001 12.2266 23.3334 13.9868 23.3334H24.6799C26.44 23.3334 26.44 26.0001 24.6799 26.0001Z" fill="#92929D"/>
                                </svg>
                            </div>
                            {/*<Button
                                className={style.buttonExitDocument}
                                variant="contained"
                                color="secondary"
                                onClick={
                                    () => {
                                        deleteBank(props, data.id)
                                    }
                                }
                            >
                                Вилучити
                            </Button>*/}
                        </div>
    )
}

export default BankAccountItem