import React,{ useState } from 'react';
import style from './login.module.css';
import { useDispatch } from 'react-redux';

import Input from '../../../inputs/input';

import {setPopUpLogin, setUniversalError} from '../../../../redux/actions.js';
import {postGetToken, setProfileNull} from '../../../../redux/actions/default/default.js';

const PopUpLogin = () => {

    const dispatch = useDispatch();
    const [login, setLogin] = useState('')
    const [password, setPassword] = useState('')

    return (
        <div className={style.popup_wrap}>
            <div className={style.popup_message}>
                <div
                    className={style.popUpExit}
                    onClick={() => {dispatch(setPopUpLogin(false))}}
                >
                    <svg  width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path className={style.closeSvg} fillRule="evenodd" clipRule="evenodd" d="M8.41421 6.99989L13.364 11.9496C13.7545 12.3402 13.7545 12.9733 13.364 13.3639C12.9734 13.7544 12.3403 13.7544 11.9497 13.3639L7 8.41411L2.05025 13.3639C1.65973 13.7544 1.02656 13.7544 0.636039 13.3639C0.245515 12.9733 0.245515 12.3402 0.636039 11.9496L5.58579 6.99989L0.636039 2.05015C0.245515 1.65962 0.245515 1.02646 0.636039 0.635934C1.02656 0.245409 1.65973 0.245409 2.05025 0.635934L7 5.58568L11.9497 0.635934C12.3403 0.245409 12.9734 0.245409 13.364 0.635934C13.7545 1.02646 13.7545 1.65962 13.364 2.05015L8.41421 6.99989Z" fill="#92929D"/>
                    </svg>
                </div>
                <div className={style.popUpTitle}>Доступ до майданчика</div>
                <div className={style.inputWrap}>
                    <Input
                        label='Логін'
                        value={login}
                        type='text'
                        onChange={setLogin}
                    />
                    <Input
                        label='Пароль'
                        value={password}
                        type='password'
                        onChange={setPassword}
                    />
                </div>
                <button
                    className={style.popup_message_button}
                    onClick={() => {
                        if(login !== '' && password !== ''){
                            postLogin(login, password)
                            dispatch(setProfileNull(null));
                            dispatch(setPopUpLogin(false));
                        } else if(password !== '') {
                            postSinglPassword(password);
                            dispatch(setProfileNull(null));
                            dispatch(setPopUpLogin(false));
                        }
                    }} 
                >Вхід
                </button>
               {  //TODO: розкоментувати колись 
                }
                {/* <button className={style.sso_button} onClick={() => loginSSO()}>
                    Вхід ССО
                </button> */}
            </div>
        </div>
    )

    function loginSSO() {
        window.open(
            `https://sso.dto.com.ua/sso?client_id=${process.env.REACT_APP_SSO_CLIENT_ID}&respons_type=code`,
            "_self", 
            "top=100, left=100, width=550, height=400, scrollbars=yes, resizable=yes"
        );
    }
    
    async function postLogin(login, password) {
        let data = {};
        data.client_id = "1";
        data.client_secret = "uHc4OVwQfGgKVYVpK1FfGOLFg36wN7ePAU258whzYws=";
        data.grant_type = "password";
        data.username = login;
        data.password = password;
        data = JSON.stringify(data);
        const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/token`
        const response = await fetch(endPoint, {
            method: "POST",
            headers: {'Content-Type': 'application/json'},
            body: data
        })
        .catch(console.error);
        if( typeof response === 'undefined') {
            let text = 'Невірний логін або пароль';
            let tmp = JSON.stringify({title: 'Помилка авторізації', message: text});
            dispatch(setUniversalError(tmp));
        } else {
            const json = await response.json()
            if(json.hasOwnProperty('message') === true) {
                let text = 'Невірний логін або пароль';
                let tmp = JSON.stringify({title: 'Помилка авторізації', message: text});
                dispatch(setUniversalError(tmp));
            } else {
                dispatch(postGetToken(json));
            }
        }
        window.location.reload()
    }
    
    async function postSinglPassword(password) {
        let data = JSON.stringify({
            "client_id" : "1",
            "client_secret" : "uHc4OVwQfGgKVYVpK1FfGOLFg36wN7ePAU258whzYws=",
            "grant_type" : "temporary_password",
            "temporary_password" : password
        });
        const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/token`;
        const response = await fetch(endPoint, {
            method: "POST",
            headers: {'Content-Type': 'application/json'},
            body: data
        })
        .catch(console.error);
        if(response === undefined || response === 'undefined'){
            let text = 'Невірний логін або пароль';
            let tmp = JSON.stringify({title: 'Помилка авторізації', message: text});
            dispatch(setUniversalError(tmp));
        }else{
            const json = await response.json();
            if(json.hasOwnProperty('message') === true) {
                let text = 'Невірний логін або пароль';
                let tmp = JSON.stringify({title: 'Помилка авторізації', message: text});
                dispatch(setUniversalError(tmp));
            } else {
                dispatch(postGetToken(json));
            }
        }
    }
}

export default PopUpLogin;