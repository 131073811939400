import React, { useState } from 'react';
import styles from './sidebarView.module.css';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { getLeaseRequestType } from '../../../../../utils/requests/requestsUtils';

import { checkProp, numFormat, getAssetStatus } from '../../../../../utils/check';
import Button from '../../../../elements/buttons/button';

const LeaseRequestSidebarView = ( { data, object } ) => {

    const user = useSelector(state => state.start.userProfiles)   

    return (
        <>
            <div className={styles.sideBlock}>
                <h2> 
                { getLeaseRequestType(data.type) }
                </h2>
                <div className={styles.sidelotImage}>
                </div>
                {/* <div className={styles.aucStatus}>
                    <p>Тип заяви</p>
                    { getLeaseRequestType(data.type) }
                </div> */}
                    {/* {setAmount()} */}
                { data?.assetId &&
                    <div className={ styles.lotInfoBlock }>
                        <p>Номер в ЦБД</p>
                        <p className={styles.lotInfo}>{ data.assetId }</p>
                    </div>
                }
                { !!data?.owner &&
                    <div className={ styles.lotInfoBlock }>
                        <p>Ідентифікатор майданчика</p>
                        <p className={styles.lotInfo}>{ data.owner }</p>
                    </div>
                }
                { !!object &&
                    <div className={ styles.lotInfoBlock }>
                        <p>Пов'язаний об'єкт</p>
                        <Link to={`/lease/object/${object.id}`}><p className={styles.lotInfo}>{ object.registryObjectId }</p></Link>
                    </div>
                }
            </div>

        </>
        )
}

export default LeaseRequestSidebarView
