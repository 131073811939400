import React, {useState, useEffect} from 'react';
import styles from '../search.module.css';
import Bage from './bage';
import { dateTransform } from '../../../../../utils/check';

import { checkProp, getLeaseObjectStatus, getPropertyType, getRegistryType } from '../../../../../utils/check';

const BadgesSearch =({ params, setParams })=> {

    function bageCombinator( params ){
            let tmpArr = []
            if ( checkProp(params, 'keyword') ){
                let tmp = <Bage title={ params.keyword } 
                action={ ()=> setParams( prev => ({...prev, keyword: null }))} />
                tmpArr = [...tmpArr, tmp ]
            }
            if ( checkProp(params, 'status') && params.status.length > 0 ){
                params.status.forEach( i => {
                    let tmp = <Bage title={ `Статус: ${ getLeaseObjectStatus(i) }` }
                    action={ ()=> setParams( prev => ({...prev, status: prev.status.filter( e => e !== i ) }))} key={i} 
                    />
                    tmpArr = [...tmpArr, tmp ]
                })
            }
            if ( checkProp(params, 'listType') && params.listType.length > 0 ){
                params.listType.forEach( i => {
                    let tmp = <Bage title={ `Тип переліку: ${ getRegistryType(i) }` }
                    action={ ()=> setParams( prev => ({...prev, listType: prev.listType.filter( e => e !== i ) }))} key={i} 
                    />
                    tmpArr = [...tmpArr, tmp ]
                })
            }
            if ( checkProp(params, 'type') && params.type.length > 0 ){
                params.type.forEach( i => {
                    let tmp = <Bage title={ `Тип об'єкта: ${ getPropertyType(i) }` }
                    action={ ()=> setParams( prev => ({...prev, type: prev.type.filter( e => e !== i ) }))} key={i} 
                    />
                    tmpArr = [...tmpArr, tmp ]
                })
            }
            if ( checkProp(params, 'registryObjectId') ){
                let tmp = <Bage title={ `Ідентифікатор об'єкта: ${params.registryObjectId}` } 
                action={ ()=> setParams( prev => ({...prev, registryObjectId: null }))} />
                tmpArr = [...tmpArr, tmp ]
            }
            if ( checkProp(params, 'title') ){
                let tmp = <Bage title={ `Назва об'єкта: ${params.title}` } 
                action={ ()=> setParams( prev => ({...prev, title: null }))} />
                tmpArr = [...tmpArr, tmp ]
            }
            if ( checkProp(params, 'description') ){
                let tmp = <Bage title={ `Опис об'єкта: ${params.description}` } 
                action={ ()=> setParams( prev => ({...prev, description: null }))} />
                tmpArr = [...tmpArr, tmp ]
            }
            if ( checkProp(params, 'propertyOwner') ){
                let tmp = <Bage title={ `Балансоутримувач: ${params.propertyOwner}` } 
                action={ ()=> setParams( prev => ({...prev, propertyOwner: null }))} />
                tmpArr = [...tmpArr, tmp ]
            }
            if ( checkProp(params, 'datePublishedFrom') ){
                let tmp = <Bage title={ `Дата публікації, з: ${ dateTransform(params.datePublishedFrom) }` } 
                action={ ()=> setParams( prev => ({...prev, datePublishedFrom: null }))} />
                tmpArr = [...tmpArr, tmp ]
            }
            if ( checkProp(params, 'datePublishedTo') ){
                let tmp = <Bage title={ `Дата публікації, по: ${ dateTransform(params.datePublishedTo) }` } 
                action={ ()=> setParams( prev => ({...prev, datePublishedTo: null }))} />
                tmpArr = [...tmpArr, tmp ]
            }
        return tmpArr
    }

    return (
        <div className={styles.badgesWrapLine}>
            { bageCombinator( params ) }
        </div>
    )
}

export default BadgesSearch