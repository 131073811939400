import React,{ useState, useEffect } from 'react';
import styles from './itemProps.module.css'
import {useSelector, useDispatch} from 'react-redux';
import { isMongo, getAdditionalClassificatorDM, dictionaryGet } from '../../../../../../../../../utils/check';
import Input from '../../../../../../../../inputs/input';
import InputTextArea from '../../../../../../../../inputs/inputTextArea';
import InputSelect from '../../../../../../../../inputs/inputSelect';
//import InputSelectCVZU from '../../../../../../../../inputs/MultiSelect/inputMultiSelectCVZULAE';
import InputSelectCVZU from '../../../../../../../../inputs/MultiSelect/inputMultiSelectCVZU';
import Checkbox from '../../../../../../../../inputs/checkBox';

import InputDate from '../../../../../../../../inputs/inputDate';
import Button from '../../../../../../../../elements/buttons/button';
import Localization from '../localization/localization';
import Loader from '../../../../../../../../loader/loader';
import { assetItemCreate, assetItemUpdate } from '../../../../../../../../../redux/asset/assetActions';
import { checkProp } from '../../../../../../../../../utils/check';

const JointPropertyComplexPropsEdit =({ data, classification, close })=> {
    const assetItem = useSelector(state => state.asset.assetItem)
    const token = useSelector(state => state.start.token);

    const objectEncumbrancesList = useSelector(state => state.start.objectEncumbrancesList)
    const objectOwnershipTypeList = useSelector(state => state.start.objectOwnershipTypeList)
    const assetRegistrationStatus = useSelector(state => state.start.assetRegistrationStatus)
    const dispatch = useDispatch()

    const [loader, setLoader] = useState( false )

    const [additionalClassificator, setAdditionalClassificator] = useState( data?.additionalClassifications[0]?.id ? data.additionalClassifications[0].id : null )
    const [description, setDescription] = useState( data?.description ? isMongo(data.description) : null)
    const [registrationStatus, setRegistrationStatus] = useState( !!data?.registrationDetails?.registrationStatus ? data.registrationDetails.registrationStatus : null)
    const [registrationID, setRegistrationID] = useState( !!data?.registrationDetails?.registrationID ? data.registrationDetails.registrationID : null)
    const [registrationDate, setRegistrationDate] = useState( !!data?.registrationDetails?.registrationDate ? data.registrationDetails.registrationDate : null)
    const [unit] = useState('HAR')
    const [quantity, setQuantity] = useState( data?.quantity ? data.quantity : null)
    const [location, setLocation] = useState( data?.location ? data.location : null)
    const [address, setAdress] = useState( data?.address ? data.address : null)

    const [quantityAndNomenclature, setQuantityAndNomenclature] = useState( data?.itemProps?.quantityAndNomenclature ?  data.itemProps.quantityAndNomenclature : null)
    const [workplacesQuantity, setWorkplacesQuantity] = useState( data?.itemProps?.workplacesQuantity ?  data.itemProps.workplacesQuantity : null)
    const [buildingsInformation, setBuildingsInformation] = useState( data?.itemProps?.buildingsInformation ?  data.itemProps.buildingsInformation : null)
    const [landPlotInformation, setLandPlotInformation] = useState( data?.itemProps?.landPlotInformation ?  data.itemProps.landPlotInformation : null)
    const [mainObligations, setMainObligations] = useState( data?.itemProps?.mainObligations ?  data.itemProps.mainObligations : null)

    const [cadastral, setCadastral] = useState( data?.itemProps?.landProps?.cadastralNumber ? data.itemProps.landProps.cadastralNumber : null)
    const [landArea, setLandArea] = useState( data?.itemProps?.landProps?.landArea ? data.itemProps.landProps.landArea : null)
    const [ownershipType, setOwnershipType] = useState( data?.itemProps?.landProps?.ownershipType ? data.itemProps.landProps.ownershipType : null)
    const [encumbrances, setEncumbrances] = useState( data?.itemProps?.landProps?.encumbrances ? data.itemProps.landProps.encumbrances : null)
    const [jointOwnership, setJointOwnership] = useState( data?.itemProps?.landProps?.jointOwnership ? data.itemProps.landProps.jointOwnership : null)
    const [utilitiesAvailability, setUtilitiesAvailability] = useState( data?.itemProps?.landProps?.utilitiesAvailability ? data.itemProps.landProps.utilitiesAvailability : null)
    const [landIntendedUse, setLandIntendedUse] = useState( data?.itemProps?.landProps?.landIntendedUse ? data.itemProps.landProps.landIntendedUse : null)

    const [hasEncumbrances, setHasEncumbrances] = useState( data?.itemProps?.landProps?.hasEncumbrances ? data.itemProps.landProps.hasEncumbrances : false)
    const [hasJointOwnership, setHasJointOwnership] = useState( data?.itemProps?.landProps?.hasJointOwnership ? data.itemProps.landProps.hasJointOwnership : false)
    const [hasUtilitiesAvailability, setHasUtilitiesAvailability] = useState( data?.itemProps?.landProps?.hasUtilitiesAvailability ? data.itemProps.landProps.hasUtilitiesAvailability : false)


    const [intendedUse, setIntendeduse] = useState(checkProp(data, 'additionalClassifications') ? loadAddClasif(data.additionalClassifications) : []);
    const [intendedUseList, setIntendedUseList] = useState([]);    
    //const [additionalClassifications, setAdditionalClassifications] = useState(checkProp(data, 'additionalClassifications') ? data.additionalClassifications : [])


    const addClassif = getAdditionalClassificatorDM(classification.id)
    
    useEffect( () => {
        if ( objectOwnershipTypeList === null ) {
            dispatch( dictionaryGet('ownershipType', 'OBJECT_GET_OWNERSHIP_TYPE') );
        }
        if ( objectEncumbrancesList === null ) {
            dispatch( dictionaryGet('encumbrances', 'OBJECT_GET_ENCUMBRANCES_LIST') );
        }
        if ( assetRegistrationStatus === null ) {
            dispatch( dictionaryGet('registrationStatus', 'ASSETT_GET_REGISTRATION_STATUS') );
        }
        setAdditionalClassificator( addClassif.id )
        async function getintendedUseList() {
            await fetch(`${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/classifications/byScheme/CVZU`)
            .then( (response) => {if (response.status === 200) return response.json()})
            .then( data => setIntendedUseList(data))
        }
        if (intendedUseList.length === 0) getintendedUseList()
    }, []);

    function loadAddClasif(data){
        let tmp = data
        .filter((e) => {
            if(e.id.indexOf(".") !== -1) return e
        })
        .map((e) => {
            return e.id
        })
        return tmp
    }

    function addClasif(){
        let tmp = intendedUseList
        .filter((e) => {
            for(let i = 0; i < intendedUse.length; i++){
                if(e.id === intendedUse[i]) return e
            }
        })
        .map((e) => {
            return e
        })
        return tmp
    }

    function registrationDetails(){
        if((registrationStatus !== null && registrationStatus !== '')
            && (registrationID !== null && registrationID !== '')
            && (registrationDate !== null && registrationDate !== '')
        ){
            return {
                registrationStatus: registrationStatus,
                registrationID: registrationID,
                registrationDate: registrationDate
            }
        }
        return null
    }

    function itemProps(){
        if(//(quantityAndNomenclature !== null && quantityAndNomenclature !== '')
            //&& (workplacesQuantity !== null && workplacesQuantity !== '')
            //&& (buildingsInformation !== null && buildingsInformation !== '')
            //&& (landPlotInformation !== null && landPlotInformation !== '')
            //&& (mainObligations !== null && mainObligations !== '')

            (landArea !== null && landArea !== '')
            && (cadastral !== null && cadastral !== '')
            //&& (ownershipType !== null && ownershipType !== '')
            //&& (jointOwnership !== null && jointOwnership !== '')
            //&& (encumbrances !== null && encumbrances !== '')
            //&& (utilitiesAvailability !== null && utilitiesAvailability !== '')
            //&& (landIntendedUse !== null && landIntendedUse !== '')
        ){
            let body = {
                // itemPropsType: 'jointPropertyComplex',
                quantityAndNomenclature: quantityAndNomenclature !== null && quantityAndNomenclature !== '' ? quantityAndNomenclature : null,
                workplacesQuantity: workplacesQuantity !== null && workplacesQuantity !== '' ? workplacesQuantity : null,
                buildingsInformation: buildingsInformation !== null && buildingsInformation !== '' ? buildingsInformation : null,
                landPlotInformation: landPlotInformation !== null && landPlotInformation !== '' ? landPlotInformation : null,
                mainObligations: mainObligations !== null && mainObligations !== '' ? mainObligations : null,
                landProps:{
                    // itemPropsType: 'land',
                    landArea: +landArea,
                    cadastralNumber: cadastral,
                    ownershipType: ownershipType !== null && ownershipType !== '' ? ownershipType : null,
                    // jointOwnership: jointOwnership === null ? 'немає' : jointOwnership,
                    // encumbrances: encumbrances === null ? 'noEncumbrances' : encumbrances,
                    // utilitiesAvailability: utilitiesAvailability === null ? 'немає' : utilitiesAvailability,
                    landIntendedUse: landIntendedUse !== null && landIntendedUse !== '' ? landIntendedUse : null,
                    hasEncumbrances: hasEncumbrances === true ? true : false,
                    hasJointOwnership: hasJointOwnership === true ? true : false,
                    hasUtilitiesAvailability: hasUtilitiesAvailability === true ? true : false,
                }
            }
            body = fHasEncumbrances(body)
            body = fHasJointOwnership(body)
            body = fHasUtilitiesAvailability(body)
            return body
        }
        return null
    }
    function fHasEncumbrances(requestBody){
        if(!hasEncumbrances) return requestBody
        requestBody.landProps.encumbrances = encumbrances
        return requestBody
    }
    function fHasJointOwnership(requestBody){
        if(!hasJointOwnership) return requestBody
        requestBody.landProps.jointOwnership = jointOwnership
        return requestBody
    }
    function fHasUtilitiesAvailability(requestBody){
        if(!hasUtilitiesAvailability) return requestBody
        requestBody.landProps.utilitiesAvailability = utilitiesAvailability
        return requestBody
    }

    const requestBody = {
        description: {
            uk_UA: description,
        },
        classification: classification,
        additionalClassifications: addClassif,
        registrationDetails: registrationDetails(),
        unit: {
            code: unit,
            name: {
                uk_UA: 'гектар', //unitValues
            }
        },
        // assetItemType: assets || claimRights,
        quantity: quantity !== null && quantity !== '' && quantity !== 0 ? +quantity : null,
        address: address,
        location: location,
        itemProps: itemProps()
    }

    function sendItemData( data ){
        requestBody.additionalClassifications = [...addClassif, ...addClasif()]
        const body = JSON.stringify(requestBody)
        if ( !data ) dispatch ( assetItemCreate(assetItem.id, body, token.access_token ))
        if ( !!data ) dispatch ( assetItemUpdate(assetItem.id, data.id, body, token.access_token ))
        setLoader( true )
        close()
    }

    function viewButtons(){
        return (
            <div className={styles.lineButtons}>
                <Button
                    data={'Зберегти зміни'}
                    color={'blue'}
                    onClick={
                        () => { 
                            sendItemData( data )
                        }
                    }
                />
                <Button
                    data={'Закрити'}
                    color={'gray'}
                    onClick={ close }
                />
            </div>
        )
    }

    if( loader ) return <Loader />
    return (
        <>
            <InputSelect
                label="Додатковий класифікатор"
                value={ additionalClassificator }
                onChange={ event => setAdditionalClassificator( event ) }
                options={ getAdditionalClassificatorDM( classification.id ) }
                dictionary
                reqiured
            />
            <InputSelectCVZU
                label="Додатковий класифікатор CVZU"
                value={ intendedUse }
                onChange={e => setIntendeduse(e)}
                //onChange={e => setLeaseRules({ ...leaseRuless, intendedUse: e })}
                options={ intendedUseList }
                dictionary={true}
                //maxLength={5}
                // text="Оберіть"

            />
            {/*<InputSelectCVZU
                label="Додатковий класифікатор CVZU"
                value={intendedUse}
                options={intendedUseList}
                dictionary={true}
                setUpLevel={setAdditionalClassifications}
                intendedUse={setIntendeduse}
            />*/}
            <InputTextArea
                value={description}
                // disabled={blokedInput(props, '01')}
                label="Опис об'єкту"
                reqiured
                onChange={ event => setDescription(event) }
            />
            <InputSelect
                label="Стан державної реєстрації об'єкту"
                value={ registrationStatus }
                onChange={ event =>  setRegistrationStatus( event ) }
                options={ assetRegistrationStatus }
                dictionary
                reqiured
            />
            <div className={styles.twoInLine}>
                <Input
                    label="Номер запису"
                    value={ registrationID }
                    onChange={ event =>  setRegistrationID( event ) }
                    reqiured={ registrationStatus === 'complete' ? true : false }
                />
                <InputDate 
                    data={{
                        label: 'Дата реєстрації',
                        value: registrationDate,
                        onChange: event =>  setRegistrationDate( event ),
                        reqiured: registrationStatus === 'complete' ? true : false
                    }}
                />
            </div>
            <div className={styles.semiTitle}>Характеристики єдиного майнового комплексу</div>
            <InputTextArea
                value={quantityAndNomenclature}
                // disabled={blokedInput(props, '01')}
                label="Обсяг та основна номенклатура продукції (робіт, послуг), у тому числі експортної"
                onChange={ event => setQuantityAndNomenclature(event) }
            />
            <Input
                value={workplacesQuantity}
                // disabled={blokedInput(props, '01')}
                label="Кількість робочих місць, ociб"
                onChange={ event => setWorkplacesQuantity(event) }
                type='number'
            />
            <InputTextArea
                value={buildingsInformation}
                // disabled={blokedInput(props, '01')}
                label="Відомості про будівлі (споруди, приміщення) єдиного майнового комплексу в обсязі, передбаченому для інформації про нерухоме майно"
                onChange={ event => setBuildingsInformation(event) }
            />
            <InputTextArea
                value={landPlotInformation}
                // disabled={blokedInput(props, '01')}
                label="Відомості про земельну ділянку, на якій розташовано єдиний майновий комплекс"
                onChange={ event => setLandPlotInformation(event) }
            />
            <InputTextArea
                value={mainObligations}
                // disabled={blokedInput(props, '01')}
                label="Основні зобов’язання (договірні та позадоговірні)"
                onChange={ event => setMainObligations(event) }
            />
            <Input
                label="Площа ділянки, га"
                value={landArea}
                onChange={ event => {
                    if(Number(event) <= 60362800){
                        let tmp = event.replace(/,/gi, '.')
                        setLandArea(tmp)
                        setQuantity(tmp)
                    }
                }}
                reqiured
            />
            <Input
                label="Кадастровий номер"
                value={cadastral}
                onChange={ event => setCadastral(event) }
                reqiured
                // error = {redBlock(props, createItems.landRental.itemProps.cadastralNumber)}
                descr={'1234567980:12:123:1234'}
                // disabled={blokedInput(props, '11')}
            />
            <InputSelect
                label={"Оберіть тип власності"}
                value={ ownershipType }
                onChange={ event => setOwnershipType(event) }
                options={ objectOwnershipTypeList }
                dictionary
            />
            <div className={styles.blokInputs}>
                <div className={styles.semiTitle}>Увага! При встановленні відмітки у полі щодо наявності обтяжень/співласників/інженерних мереж обов'язково заповніть відповідні поля з пов'язаною інформацією.</div>
                <Checkbox 
                    value={hasEncumbrances}
                    onChange={setHasEncumbrances}
                >
                    Присутні обтяження
                </Checkbox>
                <InputSelect
                    label="Наявність обтяжень"
                    value={encumbrances}
                    onChange={ (event) => {
                        if(event === "noEncumbrances") setHasEncumbrances(false)
                        setEncumbrances(event)
                    }}
                    options={ objectEncumbrancesList }
                    dictionary
                    disabled={hasEncumbrances ? false : true}
                />
                <Checkbox 
                    value={hasJointOwnership}
                    onChange={setHasJointOwnership}
                >
                    Наявні співвласники
                </Checkbox>
                <Input
                    label="Наявність співвласників"
                    value={jointOwnership}
                    onChange={ event => setJointOwnership(event) }
                    // error = {redBlock(props, createItems.landRental.itemProps.cadastralNumber)}
                    disabled={hasJointOwnership ? false : true}
                />
                <Checkbox 
                    value={hasUtilitiesAvailability}
                    onChange={setHasUtilitiesAvailability}
                >
                    Інженерні мережі наявні
                </Checkbox>
                <Input
                    label="Наявність інженерних мереж"
                    value={utilitiesAvailability}
                    onChange={ event => setUtilitiesAvailability(event) }
                    // error = {redBlock(props, createItems.landRental.itemProps.cadastralNumber)}
                    disabled={hasUtilitiesAvailability ? false : true}
                />
            </div>
            <Input
                label="Поточне використання земельної ділянки"
                value={landIntendedUse}
                onChange={ event => setLandIntendedUse(event) }
                // error = {redBlock(props, createItems.landRental.itemProps.cadastralNumber)}
                // disabled={blokedInput(props, '11')}
            />
            <Localization address={address} location={location} setLocation={setLocation} setAdress={setAdress} classification={classification} />
            { viewButtons() }
        </>
    )
}

export default JointPropertyComplexPropsEdit
