import { checkProp } from "../../../../../utils/check"

const validation = {
    relatedOrganizations: {
        test: ( a )=> {
            if( !['state', 'municipal', 'compatible'].includes(a.ownershipType) ) return 'Оберіть тип власності' // текст може тут одразу?
            if( !a?.propertyOwner ) return 'Додайте організацію Балансоутримувача' // текст може тут одразу?
            if( !a?.sellingEntity ) return 'Додайте організацію Орендодавця' // текст може тут одразу?
            return false
        }
    },
    statusesDecisions: {
        test: ( a )=> {
            if( !['present', 'absent', 'notRequired'].includes(a.governingEntityApproval.verificationPresence) ) return 'Оберіть наявність Погодження уповноваженого органу управління' // текст може тут одразу?
            if( !['present', 'absent', 'notRequired'].includes(a.heritageApproval.verificationPresence) ) return 'Оберіть наявність Погодження органу охорони культурної спадщини' // текст може тут одразу?
            // if( !a?.propertyOwner ) return 'Додайте організацію Балансоутримувача' // текст може тут одразу?
            return false
        }
    },
    leaseRules: {
        test: ( a )=> {
            if( !['onlyDescribed', 'exceptDescribed', 'noRestrictions'].includes(a.intendedUseRestrictionMethod) ) return 'Оберіть cпосіб обмеження цільового призначення об\'єкта' // текст може тут одразу?
            if ( a.intendedUseRestrictionMethod === 'exceptDescribed' && !a?.intendedUse ) return 'Оберіть обмеження щодо використання майна (заборонені цільові призначення)'
                //if ( !a?.intendedUse ) return 'Оберіть значення в полі "Спосіб обмеження цільового призначення об`єкта"'
            if( !a?.leaseDuration || a.leaseDuration.length < 2 ) return 'Оберіть cтрок оренди' // текст може тут одразу?
            if ( !a?.schedule?.other === null && (!a?.schedule?.hoursBy?.value || !a?.schedule?.daysBy?.value)) return 'Оберіть пропонований графік оренди'
            if ( !a?.schedule?.other === null) a?.schedule?.period.forEach(p => { if (p.weekdays.length === 0) return 'Додайте хоча б один день в період пропонованого графіку оренди' })
            return false
        }
    },
    valuesCharacteristics: {
        test: ( a  )=> {
            if ( !!a?.marketValue && a.marketValue.amount === 0 ) return 'Оберіть ринкову вартість об\'єкта'
            if ( !!a?.marketValue && a.marketValue.amount > 0 && ( !a?.marketValueValuationDate || !a?.marketValueValuationDate || !a?.marketValueValuationDate )) return 'Оберіть дату визначення ринкової вартості об\'єкта'
        }
    }
}

export function validateAsset( obj ){
    let errArr = []
    for( let key in validation ){
        if ( obj.hasOwnProperty(key) ){
            if ( !!validation[key].test( obj[key] ) ) {
                errArr = [...errArr, validation[key].test( obj[key] ) ]
            }
        }
    }
    return errArr.length > 0 ? errArr : false
}