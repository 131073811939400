import React, { useState, useEffect} from 'react';
import styles from './elems.module.css';
import { useSelector, useDispatch } from 'react-redux';
// import { useNavigate, useLocation } from 'react-router-dom';
import { isMongo, checkProp, isDraftObject, getCurrency, getAddedTax } from '../../../../../utils/check';
import Input from '../../../../inputs/input';
// import InputAmount from '../../../../inputs/inputAmount';
// import Checkbox from '../../../../inputs/checkBox';
// import InputSelect from '../../../../inputs/inputSelect';
import InputTextArea from '../../../../inputs/inputTextArea';
import Button from '../../../../elements/buttons/button';
import { myLeaseReqDocumentsPost, myLeaseReqDocumentEdit, myLeaseRequestEdit } from '../../../../../utils/endPoints';
import Loader from '../../../../loader/loader';
import AddDocPopUp from '../../../../elements/documents/addDocPopup/addDocPopup';
import { deleteMyDraftLeaseRequestDocument, leaseRequestUpdate } from '../../../../../utils/requests/requestsUtils';
import CreateDocItem from '../../../../elements/documents/docItem/documentItem';

const LeaseRequestGeneralEdit = ( { data } ) => {
    // const userProfiles = useSelector(state => state.start.userProfiles);
    const token = useSelector(state => state.start.token);
    // const navigate = useNavigate()
    const dispatch = useDispatch()
    // const location = useLocation()

    const [ loader, setLoader ] = useState( false )
    const [ popAddDoc, setPopAddDoc ] = useState( false )
    // const [ requestor, setRequestor ] = useState( !!data?.requestor ? data.requestor : { } )

    const [description, setDescription] = useState( !!data?.description ? isMongo(data.description) : '' )

    const [name, setName] = useState( !!data?.requestor?.name ? isMongo(data?.requestor?.name) : '' )
    const [email, setEmail] = useState( !!data?.requestor?.email ? data?.requestor?.email : '' )
    const [telephone, setTelephone] = useState( !!data?.requestor?.email ? data?.requestor?.telephone : '' )
    const [faxNumber, setFaxNumber] = useState( !!data?.requestor?.email ? data?.requestor?.faxNumber : '' )
    const [url, setUrl] = useState( !!data?.requestor?.email ? data?.requestor?.url : '' )

    function updateLeaseRequest(){
            let body = {
                // sellingEntity: sellingEntity,
                // type: "agreementExtension",
                description: description,
                requestor: {
                    name: {
                        uk_UA: name
                    },
                    email: email !== '' ? email : null,
                    telephone: telephone !== '' ? telephone : null,
                    faxNumber: faxNumber !== ''? faxNumber : null,
                    url: url !== '' ? url : null
                }
            }
            dispatch( leaseRequestUpdate( myLeaseRequestEdit(data.id) , JSON.stringify(body), token.access_token))
            setTimeout( ()=> setLoader(false), 300)
            setLoader(true)
            // if ( data.status === 'pending' ) navigate(`/asset/my_executions`)
            window.scrollTo(0, 0)
    }

    const docProps = { 
        dispatch: ( res )=> dispatch( { payload: res, type: 'REQUEST_GET_MY'}),
        docOf: 'leaseRequest',
        object: data,
        // docList: setAssetDocTypes( assetItem.assetItems, assetItem.status ),
        url: {
            patch: myLeaseReqDocumentEdit,
            post: myLeaseReqDocumentsPost,
            delete: ( id ) => dispatch( deleteMyDraftLeaseRequestDocument( myLeaseReqDocumentEdit( data.id, id ), token.access_token) )
        }
    }

    if ( loader ) return <Loader />
    // if ( data.status !== 'pending' && !location?.state ) return navigate(`/asset/my_announcements`)

    return (
    <div className={styles.blockWrap}>
        <div className={styles.infoBlock}>
            <Input 
                label='ПІБ або назва'
                value={ name }
                onChange={ e => setName(e)}
                reqiured
            />
        </div>
        <div className={styles.infoBlock}>
            <InputTextArea 
                label='Опис'
                value={ description }
                onChange={ e => setDescription(e)}
                // reqiured
            />
        </div>
        <div className={styles.infoBlock}>
            <Input 
                label='Адреса електронної пошти'
                value={ email }
                onChange={ e => setEmail(e)}
                reqiured
            />
        </div>
        <div className={styles.infoBlock}>
            <Input 
                label='Номер телефону'
                value={ telephone }
                onChange={ e => setTelephone(e)}
                reqiured
            />
        </div>
        <div className={styles.infoBlock}>
            <Input 
                label='Номер факсу'
                value={ faxNumber }
                onChange={ e => setFaxNumber(e)}
                // reqiured
            />
        </div>
        <div className={styles.infoBlock}>
            <Input 
                label='Веб адреса'
                value={ url }
                onChange={ e => setUrl(e)}
                // reqiured
            />
        </div>
        <div className={styles.infoBlock}>
            <div className={styles.infoBlockTitle}>Документи</div>
            <div style={{display: 'flex', flexFlow: 'column', width: '100%', gap: '10px'}}>
                { !!data?.documents && data.documents.map( i => <CreateDocItem data={i} docProps={docProps} /> )}
            </div>
            <div className={styles.buttonsBlock}>
            <div style={{display: 'flex', flexFlow: 'row-reverse', width: '100%', marginTop: '20px'}}>
                    <Button
                    onClick={ () => setPopAddDoc(true) }
                    >
                        Додати документ
                    </Button>
            </div>
        </div>
        </div>
        
        <div className={styles.buttonsBlock}>
            <div style={{display: 'flex', flexFlow: 'row-reverse', width: '100%'}}>
                    <Button
                    onClick={ updateLeaseRequest }
                    >
                        Зберегти
                    </Button>
            </div>
        </div>
        {
            popAddDoc && <AddDocPopUp docProps={docProps} close={ () => setPopAddDoc(false)} />
        }
    </div>
    )
}

export default LeaseRequestGeneralEdit