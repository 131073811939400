import React, { useRef, useEffect } from "react"
import styles from './keyword.module.css';

const KeywordSearch =({ setParams, params })=> {

    let search = useRef()

    useEffect(() => {
        if ( params.keyword === null ) search.current.value = ''
    }, [ params ])


    return (
            <div className={styles.searchFindBlock}>
                <div className={styles.inputKeyword}>
                    <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.4639 14.4788L11.177 11.1778C12.0221 10.2076 12.4851 8.98704 12.4851 7.71635C12.4851 4.74748 9.98372 2.33203 6.90919 2.33203C3.83467 2.33203 1.33325 4.74748 1.33325 7.71635C1.33325 10.6852 3.83467 13.1007 6.90919 13.1007C8.06341 13.1007 9.16332 12.7645 10.1037 12.1263L13.4156 15.4524C13.554 15.5913 13.7402 15.6678 13.9397 15.6678C14.1286 15.6678 14.3077 15.5983 14.4437 15.4719C14.7327 15.2034 14.7419 14.7581 14.4639 14.4788ZM6.90919 3.73663C9.18175 3.73663 11.0305 5.52189 11.0305 7.71635C11.0305 9.9108 9.18175 11.6961 6.90919 11.6961C4.63663 11.6961 2.78784 9.9108 2.78784 7.71635C2.78784 5.52189 4.63663 3.73663 6.90919 3.73663Z" fill="#92929D"/>
                    </svg>
                    <input
                        className={styles.inputKeyword_line}
                        ref={search}
                        placeholder='Пошукове слово'
                    />
                </div>
                <div className={styles.buttonSearch}
                    onClick={ ()=> {
                        let tmp = search.current.value.trim()
                        setParams( prev => ({...prev, keyword: tmp }) ) 
                    }}
                >
                    <div
                        className={styles.findStart}
                        
                    >
                        <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.4639 14.4788L11.177 11.1778C12.0221 10.2076 12.4851 8.98704 12.4851 7.71635C12.4851 4.74748 9.98372 2.33203 6.90919 2.33203C3.83467 2.33203 1.33325 4.74748 1.33325 7.71635C1.33325 10.6852 3.83467 13.1007 6.90919 13.1007C8.06341 13.1007 9.16332 12.7645 10.1037 12.1263L13.4156 15.4524C13.554 15.5913 13.7402 15.6678 13.9397 15.6678C14.1286 15.6678 14.3077 15.5983 14.4437 15.4719C14.7327 15.2034 14.7419 14.7581 14.4639 14.4788ZM6.90919 3.73663C9.18175 3.73663 11.0305 5.52189 11.0305 7.71635C11.0305 9.9108 9.18175 11.6961 6.90919 11.6961C4.63663 11.6961 2.78784 9.9108 2.78784 7.71635C2.78784 5.52189 4.63663 3.73663 6.90919 3.73663Z" fill="white"/>
                        </svg>
                        Шукати
                    </div>
                </div>
            </div>

    )
}

export default KeywordSearch