import React, { useState, useEffect } from "react"
import styles from './decisionItem.module.css';
import { isMongo } from "../../../../../../utils/check"
import Input from "../../../../../inputs/input"
import InputDate from "../../../../../inputs/inputDate"
import CloselIcon from "../../../../../elements/svg/closelIcon";

const DecisionItem =({ data, decisions, setData, index })=> {
    const [decisionTitle, setDecisionTitle] = useState( !!data?.decisionTitle ? isMongo(data.decisionTitle) : '')
    const [decisionNumber, setDecisionNumber] = useState( !!data?.decisionNumber ? data.decisionNumber : '')
    const [decisionDate, setDecisionDate] = useState( !!data?.decisionDate ? isMongo(data.decisionDate) : '')

    useEffect(() => {
        let tmp = decisions
        tmp[index].decisionTitle = decisionTitle
        tmp[index].decisionNumber = decisionNumber
        tmp[index].decisionDate = decisionDate
        tmp[index].decisionOf = 'announcement'
        setData(tmp)
    }, [decisionTitle, decisionNumber, decisionDate])

    return (
        <div className={styles.itemWrap}>
            <div className={styles.closeWrap}>
                <div className={styles.closeBtn}
                    title={ 'Видалити рішення'}
                    onClick={ async () => {
                        let tmp = decisions
                        tmp.splice( index, 1 )
                        await setData( null )
                        setData( tmp )
                    }}
                >
                    <CloselIcon />
                </div>
            </div>
            <Input 
                label='Найменування рішення' 
                value={ decisionTitle }
                onChange={ e => setDecisionTitle(e) }
                reqiured
            />
            <div style={{display: 'flex', width: '100%', gap: '15px'}}>
                <Input 
                    label='Номер рішення'
                    value={ decisionNumber }
                    onChange={ e => setDecisionNumber( e ) }
                    reqiured
                />
                <InputDate 
                    data={{
                        label: 'Дата рішення',
                        value: decisionDate,
                        onChange: e => setDecisionDate( e ),
                        reqiured: true
                    }}
                />
            </div>
        </div>
    )
}

export default DecisionItem