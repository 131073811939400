import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setUniversalError } from '../../../../redux/actions.js';
import { postGetToken } from '../../../../redux/actions/default/default.js';

const Auth = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        postSSO();
    });

    async function postSSO(){
        let data = {};
        data.client_id = "1";
        data.client_secret = "uHc4OVwQfGgKVYVpK1FfGOLFg36wN7ePAU258whzYws=";
        data.grant_type = "sso";
        data.token = window.location.search.slice(6);
        data = JSON.stringify(data);
        const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/token`;
        const response = await fetch(endPoint, {
            method: "POST",
            headers: {'Content-Type': 'application/json'},
            body: data
        })
        .catch(console.error);
        const json = await response.json()
        if (response.status === 200) {
            dispatch(postGetToken(json));
            localStorage.setItem('closeAuth', true);
            window.location = '/';
        } else {
            let text = 'Невірний логін або пароль';
            let tmp = JSON.stringify({title: 'Помилка авторізації', message: text});
            dispatch(setUniversalError(tmp));
        }
    }

    return (<>

    </>);
}

export default Auth;