import React, { useState, useEffect } from 'react';
import {useSelector, useDispatch} from 'react-redux';

import ExecutionItemCard from '../../product/redemptions/executionItemCard';
import NotFound from '../../../notFound/notFound';
import Loader from '../../../../loader/loader';
import { getSearchList } from '../../catalog';

const ExecutionsList = ({ searchUrl, params }) => {

    const dispatch = useDispatch()
    const list = useSelector( state => state.start.auctionsGet )
    const [loader, setLoader] = useState( false )

    useEffect(() => {
      getSearchList( searchUrl, params, dispatch, setLoader )
    }, [ params ])

    if (loader) return <Loader />

    if (!!list && list.length === 0) return <NotFound />
        if (!!list && list.length > 0) {
            return list.map( item => <ExecutionItemCard key={item.id} item={item}/> )
        } else {
            // return <Loader />
          return <div style={
            {width: '100%', textAlign: 'center', padding: '100px 0', fontSize: '18px', fontWeight: '600', color: 'var(--darkGray)'}
            }>
              Даних немає. Повторіть запит пізніше.
            </div>
        }
}

export default ExecutionsList