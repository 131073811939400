import React from 'react';
import styles from './elems.module.css';
import { dateTransform } from '../../../../../utils/check';
import HalfInfoBlock from '../../../objectItemPage/blocks/halfInfoBlock/halfInfoBlock';
import FullInfoBlock from '../../../../elements/fullInfoBlock/fullInfoBlock';

const AnnouncementxSpecs = ( { data, periods } ) => {

    function getUnit( unit ){
        switch( unit ){
            case 'b_day': return 'робочих днів'
            case 'c_day': return 'календарних днів'
            case 'hour': return 'годин'
            case 'minute': return 'хвилин'
            case 'second ': return 'секунд'
            default: return unit
        }
    }

    return (
                <div className={styles.infoBlockContent}>
                    <div className={styles.infoBlockContent}>
                        { !!data[0]?.periods[0]?.startDate && 
                            <HalfInfoBlock 
                                title={ 'Дата та час початку аукціону' }
                                data={ dateTransform( data[0].periods[0].startDate, 'time' ) } 
                            />
                        }
                        {   !!data[1]?.periods[0]?.duration[0]?.quantity &&
                            <HalfInfoBlock 
                                title={ 'Тривалість періоду прийому пропозицій' }
                                data={ `${ data[1].periods[0].duration[0].quantity } ${ getUnit( data[1].periods[0].duration[0].unit ) }` } 
                            />
                        }
                        {
                            !!periods?.rectificationPeriod &&
                            <FullInfoBlock 
                                title='Період редагування лота'
                                data={ `з ${ dateTransform(periods.rectificationPeriod.startDate, 'time') } по ${ dateTransform(periods.rectificationPeriod.endDate, 'time') }` }
                            />
                        }
                    </div>
                </div>
        )
}

export default AnnouncementxSpecs