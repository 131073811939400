import React, {useEffect} from 'react';
import styles from './contractsBlock.module.css';
import FullInfoBlock from '../fullInfoBlock/fullInfoBlock'
import HalfInfoBlock from '../halfInfoBlock/halfInfoBlock'
import DocItem from '../docItem/documentItem'
import PopDownBlock from '../../../../elements/popdownBlock/popDownBlock'
// import Loader from '../../../../loader/loader'
import {
    checkProp,
    dateTransform,
    getUrAdress,
    getIdentifierType,
    numFormat,
    getCurrency
} from '../../../../../utils/check';

    const ContractItem = ({ data }) => {

        function setContractMethod(a) {
            switch(a) {
                case 'extension':
                    return 'Продовження договору'
                case 'listTypeSecond':
                    return 'Передача в оренду з переліку другого типу'
                case 'auction':
                    return 'Передача в оренду через аукціон'
                case 'signedBeforeETS':
                    return 'Публікація договорів оренди укладених до введення в дію переліків в ЕТС'
                default:
                    return
            }
        }

        function setStatus(a) {
            switch(a) {
                case 'pending':
                    return <span className={`${styles.status} ${styles.colorLightBrown}`}>Очікує публікації</span>
                case 'active':
                    return <span className={`${styles.status} ${styles.colorGreen}`}>Діючий</span>
                case 'inactive':
                    return <span className={`${styles.status} ${styles.colorLightGrey}`}>Недіючий</span>
                case 'cancelled':
                    return <span className={`${styles.status} ${styles.colorDark}`}>Розірвано</span>
                default:
                    return 
            }
        }

        function getContacts(source) {
            let tmp = source.map(
                (i, count) => { return (
                        <div key={count} className={styles.infoBlock}>
                                        { (checkProp(i, 'name') && i.name !== null) ? <h3>{i.name.uk_UA}</h3> : null }
                            {   (checkProp(i.identifier, 'scheme') && i.identifier.scheme !== null)
                                ? <FullInfoBlock
                                    title={getIdentifierType(i.identifier.scheme)}
                                                data={i.identifier.id}
                                            /> : null
                                        }
                                        {(checkProp(i.address, 'addressID') && i.address.addressID.id !== null)
                                            ? <FullInfoBlock
                                                title='КОАТУУ'
                                                data={i.address.addressID.id}
                                            /> : null
                                        }
                                        {(checkProp(i, 'address') && i.address !== null)
                                            ? <FullInfoBlock
                                                title='Юридична адреса'
                                                data={getUrAdress(i)}
                                            /> : null
                                        }

                            <PopDownBlock
                                title={'Контактна особа'}
                                content={
                                    <>
                                        {   (checkProp(i.contactPoint, 'name') && i.contactPoint.name.uk_UA !== null)
                                            ? <HalfInfoBlock
                                                title='ПІБ'
                                                data={i.name.uk_UA}
                                            /> : null
                                        }
                                        {   (checkProp(i.contactPoint, 'email') && i.contactPoint.email !== null)
                                            ? <HalfInfoBlock
                                                title='Адреса електронної пошти'
                                                data={<a href={`mailto:${i.contactPoint.email}`}>{i.contactPoint.email}</a>}
                                            /> : null
                                        }
                                        {   (checkProp(i.contactPoint, 'telephone') && i.contactPoint.telephone !== null)
                                            ? <HalfInfoBlock
                                                title='Номер телефону'
                                                data={i.contactPoint.telephone}
                                            /> : null
                                        }
                                        {   (checkProp(i.contactPoint, 'faxNumber') && i.contactPoint.faxNumber !== null)
                                            ? <HalfInfoBlock
                                                title='Номер факсу'
                                                data={i.contactPoint.faxNumber}
                                            /> : null
                                        }
                                        {   (checkProp(i.contactPoint, 'url') && i.contactPoint.url !== null)
                                            ? <HalfInfoBlock
                                                title='Веб адреса'
                                                data={<a href={i.contactPoint.url}>i.contactPoint.url</a>}
                                            /> : null
                                        }
                                    </>
                                }
                            />
                        </div>
                    
            )
                }
            )
                return tmp
        }

        function getDocuments(a) {
           return <div style={{ display: 'flex', flexFlow: 'column', width: '100%', gap: '15px'}}>
                    { a.map(( item ) => <DocItem data={item} key={item.id} />) }
                  </div>
        }

        return (
                <div className={styles.infoBlock}>
                <div className={styles.infoBlockTitle}>
                    { checkProp(data, 'title') ?
                        <h3>{data.title.uk_UA}</h3> : null
                    }
                        {/* {draftBtn()} */}
                    </div>
                        {/* {checkProp(data, 'id')
                            ? <HalfInfoBlock
                                title={'Ідентифікатор у ЦБД'}
                                data={data.id}
                            /> : null
                        } */}
                        { checkProp(data, 'status') ?
                                <HalfInfoBlock
                                        title={'Статус'}
                                        data={setStatus(data.status)}
                        /> : null
                        }
                        {checkProp(data, 'datePublished')
                            ? <HalfInfoBlock
                                title={'Дата публікації'}
                                data={dateTransform(data.datePublished, 'time')}
                            /> : null
                        }
                        {checkProp(data, 'dateModified')
                            ? <HalfInfoBlock
                                title={'Остання дата зміни'}
                                data={dateTransform(data.dateModified, 'time')}
                            /> : null
                        }
                        {checkProp(data, 'contractNumber')
                            ? <HalfInfoBlock
                                title={'Номер договору'}
                                data={data.contractNumber}
                            /> : null
                        }
                {checkProp(data, 'value')
                    ? <HalfInfoBlock
                        title={`Ставка оренди відповідно до договору, ${data.value.valueAddedTaxIncluded ? 'з ПДВ' : 'без ПДВ'}`}
                        data={<b>{`${numFormat.format(data.value.amount)} ${getCurrency(data.value.currency)}`}</b>}
                            /> : null
                        }
                        {checkProp(data, 'dateSigned')
                            ? <HalfInfoBlock
                                title={'Дата підписання договору'}
                                data={dateTransform(data.dateSigned)}
                            /> : null
                        }
                        {checkProp(data, 'contractTime')
                            ? <HalfInfoBlock
                                title={'Строк дії договору (період з - по)'}
                                data={`${dateTransform(data.contractTime.dateFrom)} - ${dateTransform(data.contractTime.dateTill)}`}
                            /> : null
                        }
                        {checkProp(data, 'contractMethod')
                            ? <HalfInfoBlock
                                title={'Метод укладання договору'}
                                data={setContractMethod(data.contractMethod)}
                            /> : null
                        }
                        {checkProp(data, 'description')
                            ? <FullInfoBlock
                                title={'Опис договору'}
                                data={data.description.uk_UA}
                            /> : null
                        }
                        {checkProp(data, 'buyers')
                            ? <PopDownBlock
                                title={'Орендар'}
                                content={getContacts(data.buyers)}
                            /> : null
                        }
                        {(checkProp(data, 'documents') && Object.keys(data.documents).length > 0 )
                            ? <PopDownBlock
                                title={`Документи (${data.documents.length})`}
                                content={getDocuments(data.documents)}
                            /> : null
                        }
                </div>
        )
    }

const ContractsBlock = ( { data } ) => {

    return !!data && data.length > 0 ? 
            <>
                <h2 className={styles.titleDecPg}>Договори</h2>
                { data.map(( item, count ) => <ContractItem key={count} data={item}/> ) }
            </> : 
            <div className={styles.nothing}>Наразі договорів не знайдено</div>
}

export default ContractsBlock