import React, { useState, useEffect } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { getSearchList } from '../../catalog';
import AnnouncementItemCard from '../../product/announcement/announcementItemCard';
import NotFound from '../../../notFound/notFound';
import Loader from '../../../../loader/loader';

const AnnouncementsList = ({ searchUrl, params }) => {

    const dispatch = useDispatch()
    const list = useSelector( state => state.start.auctionsGet )
    const [loader, setLoader] = useState( false )

    useEffect(() => {
      getSearchList( searchUrl, params, dispatch, setLoader )
    }, [ params ])

    if (loader) return <Loader />
    if (!!list && list.length === 0) return <NotFound />
        if (!!list && list.length > 0) {
            return list.map( item => <AnnouncementItemCard key={item.id} item={item}/> )
        } else {
          <div style={
            {width: '100%', textAlign: 'center', padding: '100px 0', fontSize: '18px', fontWeight: '600', color: 'var(--darkGray)'}
            }>
              Даних немає. Повторіть запит пізніше.
            </div>
        }
}

// async function getList( url, params, dispatch, setLoader ){
//   setLoader(true)
//   try {
//       const data = await axios.get( url, 
//         { headers: { 'Content-Type': 'application/json' },
//           params: params
//         })
//           dispatch({ payload: data.data, type: 'AUCTIONS_GET' })
//           setLoader(false)
//   } catch (err) {
//       setLoader(false)
//   }
// }

export default AnnouncementsList