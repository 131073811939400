import React, { useState } from 'react';
import styles from './sidebarView.module.css';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { checkProp, numFormat, getAssetStatus } from '../../../../../utils/check';
import Button from '../../../../elements/buttons/button';
import { setExecutionStatus } from '../../utils';

const ExecutionSidebarView = ( { data } ) => {
    
    const [imagePopup, setImagePopup] = useState(styles.noDisplay)
    const [img, setImg] = useState(0)
    const user = useSelector(state => state.start.userProfiles)   

    // const propertyType = (checkProp(data, 'registryObjectItems') && data.registryObjectItems.length > 0) ? data.registryObjectItems['0'].itemType : null

    function togglePopup(){
        if(imagePopup === styles.noDisplay) {
            setImagePopup(styles.imageWrapperBg)
        } else {
            setImagePopup(styles.noDisplay)
            setImg(0)
        }
    }

    let images = []

    function getIllustration() {
        let tmpImg = data.documents
        
        for (let key in tmpImg){
            if (tmpImg[key].documentType.indexOf('illustration') !== -1 || tmpImg[key].documentType.indexOf('фот') !== -1 || tmpImg[key].format.indexOf('image') !== -1) {
                if (+tmpImg[key].index === 1) {
                    images.push(tmpImg[key].url)
                } else {images.push(tmpImg[key].url)}
            } 
        }
        return images
    }

    function avatarKa() {
        getIllustration()

        if (checkProp(data, 'documents') && images.length > 0) {

            function forward() {
                if (img === images.length - 1) {
                    setImg(0)
                } else {
                    setImg(img + 1)
                }
            }
            
            function backward() {
                if (img === 1) {
                    setImg(0)
                } else {
                    setImg(img - 1)
                }
            }
            
            return (
                <>
                    <div className={styles.sidelotImage}
                        onClick={togglePopup}>
                        <img src={images[0]} />
                    </div>
                    <div className={imagePopup}>
                            {img !== 0 ? <div className={styles.imageArrow}
                                onClick={backward}>&#10094;</div>
                            : <div className={styles.imageArrowPlaceholder}></div>}
                        <div className={styles.imageWrapper}>
                            <div className={styles.popUpClose} onClick={togglePopup}>&times;</div>
                            <div className={styles.image}>
                                <img src={images[img]} />
                            </div>
                        </div>
                            {images.length > 1 && img < images.length - 1 ? <div className={styles.imageArrow}
                                onClick={forward}>&#10095;</div>
                            : <div className={styles.imageArrowPlaceholder}></div>}
                    </div>
                </>
            )
        } else {
            return (
                    <div className={styles.sidelotImage}>
                    </div>
            )
        }
    }

    function setAmount() { //вартість
        if (checkProp(data, 'valuesCharacteristics')) {
            if (checkProp(data.valuesCharacteristics, 'secondListAbsoluteValue')) {
                const amount = data.valuesCharacteristics.secondListAbsoluteValue.amount
                let taxAdded = data.valuesCharacteristics.secondListAbsoluteValue.valueAddedTaxIncluded
                // let currency = data.valuesCharacteristics.secondListAbsoluteValue.currency
                return (
                    <div className={styles.lotStartPriceBlock}>
                        <p>Орендна ставка, {taxAdded ? 'з ПДВ' : 'без ПДВ'}</p>
                        <p className={styles.lotStartPrice}>{numFormat.format(amount)}<span> грн </span></p>
                    </div>
                )
            }
        } else {
            return null
        }
    }

    return (
        <>
            <div className={styles.sideBlock}>
                {/* <h2> */}
                {/* {getPropertyType(propertyType)} */}
                {/* </h2> */}
                { avatarKa() }
                <div className={styles.aucStatus}>
                    <p>Статус</p>
                    <div className={styles.lotStatus}>{ setExecutionStatus(data.status) }</div>
                </div>
                    {setAmount()}
                { data?.assetId &&
                    <div className={ styles.lotInfoBlock }>
                        <p>Номер в ЦБД</p>
                        <p className={styles.lotInfo}>{ data.assetId }</p>
                    </div>
                }
                { data?.owner &&
                    <div className={ styles.lotInfoBlock }>
                        <p>Ідентифікатор майданчика</p>
                        <p className={styles.lotInfo}>{ data.owner }</p>
                    </div>
                }
                { ( !!data?.assetCustodian?.identifier?.id && 
                    !!user?.organization?.identifier?.identifier && 
                    data.assetCustodian.identifier.id === user.organization.identifier.identifier && 
                    data.status === 'pending' ) 
                        &&
                    <Link to={'/asset/announcement/create'} state={data} >
                        <Button>Створити інформаційне повідомлення</Button>
                    </Link>
                }
                    {/* {registryType(data.type)} */}
            </div>

        </>
        )
}

export default ExecutionSidebarView