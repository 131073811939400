import React, { useState, useEffect} from 'react';
import styles from './elems.module.css';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { isMongo, checkProp, isDraftObject, getCurrency, getAddedTax } from '../../../../../utils/check';
// import Input from '../../../../inputs/input';
// import InputAmount from '../../../../inputs/inputAmount';
// import Checkbox from '../../../../inputs/checkBox';
import InputSelect from '../../../../inputs/inputSelect';
import InputTextArea from '../../../../inputs/inputTextArea';
import Button from '../../../../elements/buttons/button';
import { urlPostMyDraftActionDoc, urlPatchMyDraftActionDoc, urlGetMyDraftAction } from '../../../../../utils/endPoints';
import Loader from '../../../../loader/loader';
import AddDocPopUp from '../../../../elements/documents/addDocPopup/addDocPopup';
import { deleteMyDraftLeaseActionDocument, leaseActionUpdate, getLeaseActionEntityRole } from '../../../../../utils/actions/actionsUtils';
import CreateDocItem from '../../../../elements/documents/docItem/documentItem';

const LeaseActionGeneralEdit = ( { data } ) => {
    // const userProfiles = useSelector(state => state.start.userProfiles);
    const token = useSelector(state => state.start.token);
    const navigate = useNavigate()
    const dispatch = useDispatch()
    // const location = useLocation()

    const [ loader, setLoader ] = useState( false )
    const [ popAddDoc, setPopAddDoc ] = useState( false )
    // const [ requestor, setRequestor ] = useState( !!data?.requestor ? data.requestor : { } )

    const [description, setDescription] = useState( !!data?.description ? isMongo(data.description) : '' )
    const [actingEntityRole, setActingEntityRole] = useState( !!data?.actingEntityRole ? data.actingEntityRole : null );

    function updateLeaseRequest(){
            let body = {
                // sellingEntity: sellingEntity,
                // type: "agreementExtension",
                description: description,
            }
            dispatch( leaseActionUpdate( urlGetMyDraftAction(data.id) , JSON.stringify(body), token.access_token))
            setTimeout( ()=> setLoader(false), 300)
            setLoader(true)
            // if ( data.status === 'pending' ) navigate(`/asset/my_executions`)
            window.scrollTo(0, 0)
    }

    const docProps = { 
        dispatch: ( res )=> dispatch( { payload: res, type: 'LEASE_ACTION_GET_ID'}),
        docOf: 'action',
        object: data,
        // docList: setAssetDocTypes( assetItem.assetItems, assetItem.status ),
        url: {
            patch: urlPatchMyDraftActionDoc,
            post: urlPostMyDraftActionDoc,
            delete: ( id ) => dispatch( deleteMyDraftLeaseActionDocument( urlPatchMyDraftActionDoc( data.id, id ), token.access_token) )
        }
    }

    if ( loader ) return <Loader />
    // if ( data.status !== 'pending' && !location?.state ) return navigate(`/asset/my_announcements`)

    return (
    <div className={styles.blockWrap}>
        <div className={styles.infoBlock}>
            <InputTextArea 
                label='Опис'
                value={ description }
                onChange={ e => setDescription(e)}
                // reqiured
            />
            <InputSelect 
                    value={actingEntityRole}
                    label="Роль організації, що виконує дію" 
                    onChange={ setActingEntityRole }
                    options={ getLeaseActionEntityRole('dict') }
                    reqiured
                />
        </div>
        <div className={styles.infoBlock}>
            <div className={styles.infoBlockTitle}>Документи</div>
            <div style={{display: 'flex', flexFlow: 'column', width: '100%', gap: '10px'}}>
                { !!data?.documents && data.documents.map( i => <CreateDocItem key={i.id} data={i} docProps={docProps} /> )}
            </div>
            <div className={styles.buttonsBlock}>
            <div style={{display: 'flex', flexFlow: 'row-reverse', width: '100%', marginTop: '20px'}}>
                    <Button
                    onClick={ () => setPopAddDoc(true) }
                    >
                        Додати документ
                    </Button>
            </div>
        </div>
        </div>
        
        <div className={styles.buttonsBlock}>
            <div style={{display: 'flex', flexFlow: 'row-reverse', width: '100%', gap: '20px'}}>
                    <Button
                        onClick={ updateLeaseRequest }
                    >
                        Зберегти
                    </Button>
                    <Button
                        color='gray'
                        onClick={ ()=> navigate('/lease/my_actions') }
                    >
                        До дій
                    </Button>
            </div>
        </div>
        {
            popAddDoc && <AddDocPopUp docProps={docProps} close={ () => setPopAddDoc(false)} />
        }

    </div>
    )
}

export default LeaseActionGeneralEdit