import React, { useState } from 'react';
import styles from './objectItemPage.module.css';

import { checkProp, valueFormat } from '../../../../../utils/check';

const SideBlockView = ( { data } ) => {
    
    const [imagePopup, setImagePopup] = useState(styles.noDisplay)
    const [img, setImg] = useState(0)

    const propertyType = (checkProp(data, 'registryObjectItems') && data.registryObjectItems.length > 0) ? data.registryObjectItems['0'].itemType : null

    function togglePopup(){
        if(imagePopup === styles.noDisplay) {
            setImagePopup(styles.imageWrapperBg)
        } else {
            setImagePopup(styles.noDisplay)
            setImg(0)
        }
    }

    let images = []

    function getIllustration() {
        let tmpImg = data.documents
        //TODO: треба змінити колись або на і, - затик з форматом
        for (let key in tmpImg){
            if ( tmpImg[key].documentType.includes('illustration') || tmpImg[key].format.includes('image') ) {
                if (tmpImg[key].index == 1) {
                    images.push(tmpImg[key].url)
                } else { images.push(tmpImg[key].url) }
            }
        }
        return images
    }

    function avatarKa( docs ) {
        getIllustration()

        if (checkProp(data, 'documents') && images.length > 0) {

            function forward() {
                if ( img === images.length - 1) {
                    setImg(0)
                } else {
                    setImg(img + 1)
                }
            }
            
            function backward() {
                if (img === 1) {
                    setImg(0)
                } else {
                    setImg(img - 1)
                }
            }
            
            return (
                <>
                    <div className={styles.sidelotImage}
                        >
                        <img src={images[0]}
                            onClick={ togglePopup } 
                            onError={ (i) => { i.target.style.display = "none" } }
                        />
                    </div>
                    <div className={imagePopup}>
                            {img !== 0 ? <div className={styles.imageArrow}
                                onClick={backward}>&#10094;</div>
                            : <div className={styles.imageArrowPlaceholder}></div>}
                        <div className={styles.imageWrapper}>
                            <div className={styles.popUpClose} onClick={togglePopup}>&times;</div>
                            <div className={styles.image}>
                                <img
                                    src={images[img]}
                                    onError={ (i) => { i.target.style.display = "none" } }
                                />
                            </div>
                        </div>
                            {images.length > 1 && img < images.length - 1 ? <div className={styles.imageArrow}
                                onClick={forward}>&#10095;</div>
                            : <div className={styles.imageArrowPlaceholder}></div>}
                    </div>
                </>
            )
        } else {
            return (
                    <div className={styles.sidelotImage}></div>
            )
        }
    }

    function registryType(i) { //тип переліку
        
        switch(i) {
            case('Undefined'):
                return (
                    <div className={styles.lotInfoBlock}>
                        <p>Тип переліку</p>
                        <p className={styles.lotInfo}>Не визначено</p>
                    </div>
                )

            case ('First'):
                return (
                    <div className={styles.lotInfoBlock}>
                        <p>Тип переліку</p>
                        <p className={styles.lotInfo}>Перелік першого типу</p>
                    </div>
                )

            case ('Second'):
                return (
                    <div className={styles.lotInfoBlock}>
                        <p>Тип переліку</p>
                        <p className={styles.lotInfo}>Перелік другого типу</p>
                    </div>
                )
            
            default:
                return ''
        }
    }

    function setStatus(a){ // статус об'єкта
        if( !!a ){
            switch(a) {
                case 'waiting':
                    return  <p className={`${styles.lotStatus} ${styles.colorMeedllGrey}`}>
                            Очікується
                            </p>
                case 'approved':
                    return <p className={`${styles.lotStatus} ${styles.colorGreen}`}>
                            Включено в перелік
                            </p>
                case 'inactive':
                    return <p className={`${styles.lotStatus} ${styles.colorLightGrey}`}>
                            Неактивний
                            </p>
                case 'active':
                    return <p className={`${styles.lotStatus} ${styles.colorGreen}`}>
                            Опубліковано оголошення
                            </p>
                case 'qualification':
                    return <p className={`${styles.lotStatus} ${styles.colorLightBrown}`}>
                            Визначення орендаря
                            </p>
                case 'sold':
                    return <p className={`${styles.lotStatus} ${styles.colorMeedllGrey}`}>
                            Орендовано
                            </p>
                default:
                    return ''
            }
        } else { return null }
    }

    function getPropertyType(a) { // тип об'єкта
        switch(a) {
            case 'realEstate':
                return `Об'єкт нерухомості`
            case 'vehicle':
                return `Транспортний засіб`
            case 'land':
                return `Земельна ділянкa`
            case 'otherProperty':
                return `Інше окреме індивідуально визначене майно`
            case 'jointPropertyComplex':
                return `Об'єкт єдиного майнового комплексу`
            default:
                return a
        }
    }

    function setAmount() { //вартість
        if (checkProp(data, 'valuesCharacteristics')) {
            if (checkProp(data.valuesCharacteristics, 'secondListAbsoluteValue')) {
                const amount = data.valuesCharacteristics.secondListAbsoluteValue.amount
                let taxAdded = data.valuesCharacteristics.secondListAbsoluteValue.valueAddedTaxIncluded
                // let currency = data.valuesCharacteristics.secondListAbsoluteValue.currency
                return (
                    <div className={styles.lotStartPriceBlock}>
                        <p>Орендна ставка, { taxAdded ? 'з ПДВ' : 'без ПДВ' }</p>
                        <p className={styles.lotStartPrice}>{ valueFormat(amount) }<span> грн </span></p>
                    </div>
                )
            }
        } else {
            return null
        }
    }

    return (
        <>
            <div className={styles.sideBlock}>
            <h2>
                {getPropertyType(propertyType)}
                </h2>
                { avatarKa( data.documents ) }
                { !!data?.statusesDecisions?.statusInList && 
                    <div className={styles.aucStatus}>
                        <p>Статус</p>
                        { setStatus(data.statusesDecisions.statusInList) }
                    </div>
                }
                    {setAmount()}
                { !!data?.registryObjectId &&
                    <div className={styles.lotInfoBlock}>
                        <p>Номер в ЦБД</p>
                        <p className={styles.lotInfo}>{data.registryObjectId}</p>
                    </div>
                }
                { data?.owner &&
                    <div className={ styles.lotInfoBlock }>
                        <p>Ідентифікатор майданчика</p>
                        <p className={styles.lotInfo}>{ data.owner }</p>
                    </div>
                }
                    {registryType(data.type)}
            </div>
        </>
    )
}

export default SideBlockView