import { checkProp } from "../check";
import { getAPI, deleteAPI, patchAPI } from "../../redux/actions/crud";
import axios from "axios";

export function getMyDraftLeaseAction(_url, token){
    const data = {
        url: _url,
        actionType: 'LEASE_ACTION_GET_ID',
        header: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        }
    }
    return getAPI(data);
}

export function getLeaseActionType( type ){
    const dictionary = {
        "requestAnswer": { "en_US": "Request for an answer", "uk_UA": "Запит для отримання відповіді" },
        "intentionNotification": { "en_US": "Intention notification", "uk_UA": "Повідомлення про намір" },
        "decisionPublication": { "en_US": "Decision publication", "uk_UA": "Публікація рішення" },
        "decisionChange": { "en_US": "Decision change", "uk_UA": "Зміна рішення" },
        "petition": { "en_US": "Petition", "uk_UA": "Клопотання" },
        "noAuctionNotification": {"en_US": "No auction information notice","uk_UA": "Інформаційне повідомлення про передачу об’єкта оренди без проведення аукціону"
        }
      }
    if ( type === 'dict' ) {
        let tmpArr = []
        for (let key in dictionary ){
            let tmp = { value: key, name: dictionary[key].uk_UA }
            tmpArr = [ ...tmpArr, tmp ]
        }
        return tmpArr
    }
    if( checkProp(dictionary, type ) ) return dictionary[type].uk_UA
    else return type
}

export function getLeaseActionEntityRole( type ){
    const dictionary = {
        "propertyOwner": { "en_US": "Property owner", "uk_UA": "Балансоутримувач" },
        "sellingEntity": { "en_US": "Landlord", "uk_UA": "Орендодавець" },
        "governer": { "en_US": "Governor", "uk_UA": "Уповноважений орган управління" },
        "heritageController": { "en_US": "Heritage controller", "uk_UA": "Орган охорони культурної спадщини" }
    }
    if ( type === 'dict' ) {
        let tmpArr = []
        for (let key in dictionary ){
            let tmp = { value: key, name: dictionary[key].uk_UA }
            tmpArr = [ ...tmpArr, tmp ]
        }
        return tmpArr
    }
    if( checkProp(dictionary, type ) ) return dictionary[type].uk_UA
    else return type
}

//AXIOS EXAMPLE
export async function postLeaseAction( data ){
    data.dispatch({ payload: true, type: 'SET_LOADER' })
    try {
        const responce = await axios.post( data.url, data.body,
            { 
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${data.token}`,
                }
          })
            if ( responce.status === 200 ){
                // data.dispatch({ payload: responce.data, type: 'AUCTIONS_GET' })
                data.redirect( responce.data.id )
            }
    } catch (err) {
        data.dispatch( {type: 'UNIVERSAL_ERROR', payload: `Помилка ${ err.message }` });
    }
    data.dispatch({ payload: false, type: 'SET_LOADER' })
}

export async function patchLeaseAction( data ){
    data.dispatch({ payload: true, type: 'SET_LOADER' })
    try {
        const responce = await axios.patch( data.url, data.body,
            { 
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${data.token}`,
                }
          })
            if ( responce.status === 200 ){
                // data.dispatch({ payload: responce.data, type: 'AUCTIONS_GET' })
                data.redirect()
            }
    } catch (err) {
        data.dispatch( {type: 'UNIVERSAL_ERROR', payload: `Помилка ${ err.message }` });
    }
    data.dispatch({ payload: false, type: 'SET_LOADER' })
}

export async function leaseActionDelete(_url, token, navigate, dispatch){
    dispatch({ payload: true, type: 'SET_LOADER' })
    const data = {
        url: _url,
        header: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        },
    }
    const response = await fetch(data.url, {
                method: 'DELETE',
                headers: data.header
            });
    const json = await response.json();
        if ( json.success && !!navigate ) {
            navigate()
        }
        dispatch({ payload: false, type: 'SET_LOADER' })
}

export function deleteMyDraftLeaseActionDocument(_url, token){
    const data = {
        url: _url,
        actionType: 'LEASE_ACTION_GET_ID',
        header: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        }
    }
    return deleteAPI(data);
}

export function leaseActionUpdate(_url, body, token){
    const data = {
        url: _url,
        actionType: 'LEASE_ACTION_GET_ID',
        body: body,
        header: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        },
        // navigate: navigate()
    }
    return patchAPI(data);
}
