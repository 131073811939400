import React from 'react';
import styles from './elems.module.css';
import FullInfoBlock from '../../../../elements/fullInfoBlock/fullInfoBlock';
import HalfInfoBlock from '../../../../elements/halfInfoBlock/halfInfoBlock';
import { dateTransform, isMongo } from '../../../../../utils/check';
import AssetOrganizationItem from './assetOrganizationItem';

const AssetOrganizations = ( { data } ) => {

    return (
                <div className={styles.infoBlockOrgContent}>
                    { data?.assetHolder && data?.assetHolder !== null
                        ? <AssetOrganizationItem data={data.assetHolder} title={'Інформація про балансоутримувача'} />
                        : null
                    }
                    { data?.assetCustodian && <AssetOrganizationItem data={data.assetCustodian} title={'Інформація про розпорядника об`єкта'} /> }
                </div>
        )
}

export default AssetOrganizations