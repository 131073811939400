import React from 'react';
import styles from './sideBlock.module.css';

import { useSelector } from 'react-redux';

import { getPropertyType, getCurrency, checkProp, getLeaseObjectStatus } from '../../../../../utils/check';

const SideBlock = (props) => {

    const objectCreationStep = useSelector(state => state.start.objectCreationStep);
    const draftObject = useSelector(state => state.start.objectGet);

    const pastStepIcon = (
        <svg className="okIcon" width="24" height="24" viewBox="-8 -8 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M1.8027 2.64801C1.54104 2.38897 1.11894 2.39109 0.859898 2.65274C0.600857 2.91439 0.602971 3.33649 0.864622 3.59553L4.74819 7.44038C5.02236 7.71182 5.46901 7.69456 5.72142 7.40277L11.1712 1.10283C11.4121 0.824373 11.3816 0.403363 11.1031 0.162482C10.8247 -0.0784 10.4037 -0.0479371 10.1628 0.230522L5.17953 5.99118L1.8027 2.64801Z" fill="#00B191"/>
        </svg>
    );
    const activeStepIcon = (
        <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
            <g>
                <path fill="#00aff0" d="M0,16A16,16,0,0,1,16,0h0A16,16,0,0,1,32,16h0A16,16,0,0,1,16,32h0A16,16,0,0,1,0,16Z"/>
                <path fill="#fff" fillRule="evenodd" d="M9.33,16A6.67,6.67,0,1,0,16,9.33,6.67,6.67,0,0,0,9.33,16Zm4.56,3.19a.7.7,0,0,0,1,.21l4.35-2.81a.7.7,0,0,0,0-1.18L14.86,12.6a.76.76,0,0,0-.38-.11.7.7,0,0,0-.7.7v5.62A.7.7,0,0,0,13.89,19.19Z"/>
            </g>
        </svg>
    );
    const inactiveStepIcon = (
        <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
             <g >
                 <path fill="#e2e2ea" d="M0,16A16,16,0,0,1,16,0h0A16,16,0,0,1,32,16h0A16,16,0,0,1,16,32h0A16,16,0,0,1,0,16Z"/>
                 <path fill="#fff" fillRule="evenodd" d="M12.68,14V12.73a3.37,3.37,0,1,1,6.73,0V14h.95a1,1,0,0,1,1,1v6.75a1,1,0,0,1-1,1H11.64a1,1,0,0,1-1-1V14.93a1,1,0,0,1,1-1Zm-.8,1.21v6.29h8.24V15.16Zm6.32-1.23v-1.2a2.15,2.15,0,1,0-4.3,0v1.2Z"/>
             </g>
        </svg>
    );

    // function setStatus( status ) {
    //     getLeaseObjectStatus( data.status )
    //     return 'Чернетка'
    // }

    return(
        <div className={styles.sideBlock}>
            { checkProp( draftObject, 'type' ) ? 
                <div className={styles.objectType}>{ getPropertyType(draftObject.type) }</div> 
            : null 
            }
            <div className={styles.aucStatus}>
                <p>Статус</p>
                <p className={styles.lotStatus}>{ !!draftObject?.registryObjectId ? 
                <div style={{color: 'var(--green)'}}>Опубліковано</div> : 'Чернетка' }</p>
            </div>
            { ( checkProp(draftObject,'valuesCharacteristics') && checkProp(draftObject.valuesCharacteristics,'secondListAbsoluteValue') && 
                draftObject.valuesCharacteristics.secondListAbsoluteValue.amount !== 0 ) ? 
            <div className={styles.value}>
                <div className={styles.valueText}>Орендна ставка, {draftObject.valuesCharacteristics.secondListAbsoluteValue.valueAddedTaxIncluded ? 'з ПДВ' : 'без ПДВ'}</div>
                <div className={styles.valueAmount}>{draftObject.valuesCharacteristics.secondListAbsoluteValue.amount} <span className={styles.valueCurrency}>{getCurrency(draftObject.valuesCharacteristics.secondListAbsoluteValue.currency)}</span></div>
            </div> : null
            }
            <div className={styles.auctionSteps}>
            {   !!draftObject?.registryObjectId ?
                null
                : <>
                <h2>Кроки створення</h2>
                <div className={styles.stepBlock}>
                    <div className={styles.stepIcon} datatmp={objectCreationStep}>
                        {objectCreationStep === 0 ? activeStepIcon : pastStepIcon}
                    </div>
                    <div className={styles.stepInfo}>
                        <h3>Заповніть загальну інформацію</h3>
                        <p>Заповніть дані про об’єкт: вартість, орендодавців, наявність рішень тощо.</p>
                    </div>
                </div>
                <div className={styles.stepBlock}>
                    <div className={styles.stepIcon}>
                        {objectCreationStep < 1 ? inactiveStepIcon : objectCreationStep === 1 ? activeStepIcon : pastStepIcon}
                    </div>
                    <div className={styles.stepInfo}>
                        <h3 className={objectCreationStep < 1 ? styles.inactive : []}>Додайте об’єкти</h3>
                        <p>Додайте специфічні характеристики об’єкта чи групи об’єктів.</p>
                    </div>
                </div>
                <div className={styles.stepBlock}>
                    <div className={styles.stepIcon}>
                        {objectCreationStep < 2 ? inactiveStepIcon : objectCreationStep === 2 ? activeStepIcon : pastStepIcon}
                    </div>
                    <div className={styles.stepInfo}>
                        <h3 className={objectCreationStep < 2 ? styles.inactive : []}>Додайте документацію</h3>
                            <ul>
                                <li>Документи</li>
                                <li>Фотографії</li>
                            </ul>
                    </div>
                </div>
                <div className={styles.stepBlock}>
                    <div className={styles.stepIcon}>
                        {objectCreationStep < 3 ? inactiveStepIcon : objectCreationStep === 3 ? activeStepIcon : pastStepIcon}
                    </div>
                    <div className={styles.stepInfo}>
                        <h3 className={objectCreationStep < 3 ? styles.inactive : []}>Опублікуйте на Прозорро</h3>
                        <p>Надішліть дані в центральну базу Prozorro</p>
                    </div>
                </div>
                </>
                }
            </div>
        </div>
    );
}

export default SideBlock;