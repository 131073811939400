import React, { useState } from 'react'
import styles from './buyerView.module.css'
import { checkProp, isMongo, getIdentifierType, getUrAdress } from '../../../../../../../../../utils/check'
import FullInfoBlock from '../../../../../../../objectItemPage/blocks/fullInfoBlock/fullInfoBlock'
import HalfInfoBlock from '../../../../../../../objectItemPage/blocks/halfInfoBlock/halfInfoBlock'
import ContactPoint from '../../../../../../../../elements/contactPoint/contactPoint'

const BuyerView =({ data, index, setBuyers })=> {
    const [ edit, setEdit] = useState( false )

    return (
        <div className={styles.wrapper}>
            <h3>{ !!data?.name ? isMongo(data.name) : isMongo(data.identifier.legalName) }</h3>
            { 
                <FullInfoBlock
                    title={ getIdentifierType(data.identifier.scheme) }
                    data={ data.identifier.identifier }
                />
            }
            {  !!data?.address?.addressID?.id &&
                <FullInfoBlock
                    title='КОАТУУ'
                    data={data.address.addressID.id}
                />
            }
            {  !!data?.address &&
                <FullInfoBlock
                    title='Юридична адреса'
                    data={ getUrAdress(data) }
                />
            }
            { !!data?.representativeInfo &&
                <FullInfoBlock
                    title='Інформація щодо підтвердження повноважень'
                    data={ data.representativeInfo }
                />
            }
            { !!data?.contactPoint && <ContactPoint title={'Контактна особа'} data={ data.contactPoint } /> }
        </div>
    )
}

export default BuyerView