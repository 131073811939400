import React, {useState, useEffect} from 'react';
import styles from './buttonFilters.module.css';
import Checkbox from '../../../../inputs/checkBox';
import Button from '../../../../elements/buttons/button';

const LeaseObjectListTypeSearch = ({ setParams, arr })=> {

    const [popup, setPopup] = useState( false )
    const [listType] = useState( [
        { value: 'First', name: 'Перелік першого типу', check: false },
        { value: 'Second', name: 'Перелік другого типу', check: false },
        { value: 'Undefined', name: 'Не визначено', check: false },
        { value: 'Third', name: 'Релокація', check: false },
    ])

        const popUp = (
                <div className={styles.wrapMain}>
                    <div className={styles.wrap}>
                        <h3 className={styles.filterTitle}>Тип переліку</h3>
                        <div className={styles.content}>
                            { listType.map( item => <Checkbox 
                                    value={ ( !!arr?.listType && arr.listType.includes(item.value) ) ? true : false }
                                    label={ item.name }
                                    onChange={ ()=> setParams( prev => {
                                        if ( !!arr?.listType && arr.listType.includes(item.value) ){
                                            let tmp = prev.listType
                                            return {...prev, listType: tmp.filter( i => i !== item.value ) }
                                        } else {
                                            if ( !!prev?.listType && !!prev?.listType.length === 0) {
                                                return {...prev, listType: [...listType, item.value] }
                                            }
                                            if ( !prev?.listType) return {...prev, listType: [ item.value ] }
                                            else {
                                                let tmp = prev.listType
                                                tmp = [...tmp, item.value]
                                                return {...prev, listType: tmp }
                                            }
                                        }
                                    } )}
                                />)
                            }
                        </div>
                        <div className={styles.bottomPart}>
                            <Button 
                                color='blue'
                                onClick={() => setPopup( prev => !prev) }>
                                Готово
                            </Button>
                        </div>
                    </div>
                </div>
            )

    return (
        <>
            <div
                className={styles.searchFilterBlock_status}
                onClick={ () => setPopup( prev => !prev) }
            >
                <div>Тип переліку</div>
                { ( !!arr?.listType && arr.listType.length > 0 )&& 
                    <div style={{borderLeft: '1px dotted #BFBFC6', paddingLeft: '5px', marginLeft: '5px'}}>
                        { arr.listType.length }
                    </div> 
                }
            </div>
            { popup && popUp}
        </>
    )
}

export default LeaseObjectListTypeSearch
