import React from 'react';
import styles from './contacts.module.css';
import {useSelector} from 'react-redux';

import {CONTACTS_TITLE_TOP} from '../../../model/lang/topMenu.js';

import SvgContacts from './contacts.svg';

const Contacts = () => {

    const langFlag = useSelector(state => state.start.langFlag);

    return (
        <div className={styles.wrapContact} >
            <div className={styles.korusne_link} href="https://www.dto.com.ua/helpful/presentations">
                <div className={styles.wrap_link}>
                    <div className={styles.korusne_img}>
                        <img src={SvgContacts} alt=''/>
                        <div className={styles.korusne_title}>{CONTACTS_TITLE_TOP[langFlag]}</div>
                    </div>
                    <div className={styles.korusne_text}>
                        (044) 490-11-91
                    </div>
                    <div className={styles.korusne_text}>
                        <a className={styles.korusne_link} href="mailto:info@dto.com.ua">info@dto.com.ua</a>
                    </div>
                    <div className={styles.contacts}>
                        {/* <div className={styles.korusne_text}><span>ЗАМОВНИКАМ:</span></div> */}
                        <div className={styles.korusne_text}>
                            <a className={styles.korusne_link} href="viber://chat/?number=+380675991191" target='_blank'>
                                або напишіть нам у Viber (067) 599-11-91
                            </a>
                        </div>
                        <br />
                        {/* <div className={styles.korusne_text}><span>УЧАСНИКАМ:</span></div> */}
                        <div className={styles.korusne_text}>
                            <a className={styles.korusne_link} href="https://t.me/+380632808236" target='_blank'>
                                чи Telegram (063) 280-82-36
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contacts;