import React, { useState } from 'react';
import styles from '../elems.module.css';
import FullInfoBlock from '../../../../../elements/fullInfoBlock/fullInfoBlock';
import HalfInfoBlock from '../../../../../elements/halfInfoBlock/halfInfoBlock';
import { dateTransform, isMongo, getAddedTax, getCurrency, getFormatedValue } from '../../../../../../utils/check';
import { getExecutionContractStatus, getExecutionContractDocType } from '../../../utils';
// import PopDownBlock from '../../../../elements/popdownBlock/popDownBlock';
import BuyerEditItem from './buyerEditItem';
import ViewDocItem from '../../../../../elements/documents/view/viewDocumentItem';
import Input from '../../../../../inputs/input';
import InputTextArea from '../../../../../inputs/inputTextArea'
import InputSelect from '../../../../../inputs/inputSelect'
import InputAmount from '../../../../../inputs/inputAmount'
import InputDate from '../../../../../inputs/inputDate'

const ExecusionContractItemEdit = ({ data }) => {
    const [ contractNumber, setContractNumber ] = useState( !!data?.contractNumber ? data.contractNumber : '' )
    const [ title, setTitle ] = useState( !!data?.title ? isMongo(data.title) : '' )
    const [description, setDescription] = useState(!!data?.description ? isMongo(data.description) : '')
    const [value, setValue] = useState(!!data?.value ? data.value : { currency: 'UAH', amount: '', valueAddedTaxIncluded: true })
    const [contractTotalValue, setContractTotalValue] = useState(!!data?.contractTotalValue ? data.contractTotalValue : { currency: 'UAH', amount: '', valueAddedTaxIncluded: true })
    const [ dateSigned, setDateSigned ] = useState( !!data?.dateSigned ? data.dateSigned : null )
    const [ contractTime, setContractTime ] = useState( !!data?.contractTime ? data.contractTime : { dateFrom: null, dateTill: null } )
    const [ buyers, setByuers ] = useState( !!data?.buyers ? data.buyers : [] )

    return <div className={styles.contractItem}>
                <div style={{margin: ' 10px 0 30px', fontWeight: '600', fontSize: '18px'}}>Публікація інформації про зміни в договорі</div>
                <Input 
                    label="Назва"
                    value={ title }
                    onChange={ event => setTitle( event ) }
                    reqiured
                    // disabled={ isDraftObject( data.id ) ? false : true }
                />
                <InputTextArea
                    label="Опис"
                    value={ description }
                    onChange={ event => setDescription( event ) }
                    // disabled={ isDraftObject( data.id ) ? false : true }
                    reqiured
                />
                <Input 
                    label="Номер договору"
                    value={ contractNumber }
                    onChange={ event => setContractNumber( event ) }
                    // disabled={ isDraftObject( data.id ) ? false : true }
                    reqiured
                />
                <InputAmount 
                    title='Вартість договору'
                    value={ value }
                    onChange={ setValue }
                    tax={ true }
                    // disabled={ isDraftObject( data.id ) ? false : true }
                    required
                    // enableCurrent
                />
                <InputAmount 
                    title='Заключна вартість договору'
                    value={ contractTotalValue }
                    onChange={ setContractTotalValue}
                    tax={ true }
                    // disabled={ isDraftObject( data.id ) ? false : true }
                    required
                    // enableCurrent
                />
                <div><h4>Строк дії договору *</h4></div>
                    <div className={styles.twoBlock}>
                        <InputDate
                            data={{
                                label: "Період з",
                                value: contractTime.dateFrom,
                                // disabled: isDraftObject( data.id ) ? false : true,
                                onChange: (e)=> setContractTime( prev => ({...prev, dateFrom: e })),
                                reqiured: true
                            }}
                        />
                        <InputDate
                            data={{
                                label: "Період по",
                                value: contractTime.dateTill,
                                // disabled: isDraftObject( data.id ) ? false : true,
                                onChange: (e)=> setContractTime( prev => ({...prev, dateTill: e })),
                                reqiured: true
                            }}
                    />
                </div>
                <InputDate
                        data={{
                            label: "Дата підписання договору",
                            value: dateSigned,
                            onChange: setDateSigned ,
                            reqiured: true,
                            // disabled: isDraftObject( data.id ) ? false : true 
                        }}
                    />
                        { !!data.buyers && 
                            <div className={styles.linedBlock} >
                                <div className={styles.infoBlockTitle}>Орендар</div>
                                    {data.buyers?.map((i, index) => <BuyerEditItem data={i} setData={ setByuers }
                                                                    key={i.id} index={index}
                                                                    edit={ !data?.status || data.status === 'pending' ? true : false}
                                />)}
                            </div>
                        }
                        { ( !!data.documents && data.documents.length > 0 ) ? 
                            <div className={styles.linedBlock} >
                                <div className={styles.infoBlockTitle}>Документи</div>
                                    <div className={styles.docList}>
                                        { data.documents.map( i => <ViewDocItem 
                                                                    key={i.id}
                                                                    data={ i } 
                                                                    specs={{
                                                                        objId: data.id,
                                                                        docType: getExecutionContractDocType(i.documentType)
                                                                    }}
                                                                /> )
                                        }
                                    </div>
                            </div> : 
                            null
                        }
        </div>
}

export default ExecusionContractItemEdit