import React, { useState, useEffect } from 'react';
import styles from './toMyObjRequests.module.css';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';

import ToMyObjRequestsItem from './ToMyObjRequestsTabItem';
import Loader from '../../../loader/loader';
import { leaseRequestGetType } from '../../../../redux/actions/leaseRequests/leaseRequests'
import { getDictionaryByType, toMyObjLeaseRequests } from '../../../../utils/endPoints';
// import SearchSortFilter from '../elems/sortFilter';
import Paginator from '../../paginator/paginatorTest';


const ToMyObjRequestsHeader = () => {
    return (
        <>
            <div className={styles.myRequestTable}>
                <div className={styles.tabHead}>
                    <div className={styles.thName}>Заява</div>
                    <div className={styles.thStat}>Тип</div>
                    <div className={styles.thStatus}>Дата публікації</div>
                    <div className={styles.thRent}>Інформація про заявника</div>
                    {/* <div className={styles.thAction}>Дії</div> */}
                    <div className={styles.thDeclaration}>Документи</div>
                    <div className={styles.thActions}>Дії</div>
                </div>
            </div>
        </>
    )
}

const ToMyObjRequests = () => {
    
    const dispatch = useDispatch(); 
    const token = useSelector(state => state.start.token);
    const userProfile = useSelector(state => state.start.userProfiles);
    const myRequestsList = useSelector(state => state.start.myRequestsList);
    const requestTypesList = useSelector(state => state.start.requestTypesList);

    const [loader, setLoader] = useState( false )
    const [ params, setParams ] = useState({
        limit: 10,
        offset: 0,
        order: 'DESC',
        // order: 'DESC',
        // keyword: null
    })

    useEffect(() => {
        if(requestTypesList === null) {
            dispatch( leaseRequestGetType( getDictionaryByType('requestType') ));
        }
    }, []);

    useEffect(() => {
        if (token !== null) {
            getSearchList( params, token.access_token, dispatch )
        }
    }, [ params ]);

    function showMyRequestsList(list){
        let requestsList
        if (list !== null && list !== undefined) {
            if (list.length > 0) {
                requestsList = list.map( item => <ToMyObjRequestsItem 
                                                        key={ item.publeaseRequestId }
                                                        data={ item }
                                                        types={ requestTypesList }
                                                    />)
            } else {
                requestsList = <div className={styles.tableEmpty}>У Ваших об'єктів немає жодної заяви.</div>
            }
        } else {
            requestsList = <Loader />
        }
        return requestsList
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.titleWrapper}>
                <div className={styles.title}>Заяви до моїх об’єктів</div>
                <div className={styles.sort}>
                    {/* <SearchSortFilter params={ params } setParams={ setParams } /> */}
                </div>
            </div>
            <div className={styles.tableContent}>
                <ToMyObjRequestsHeader />
                { showMyRequestsList( myRequestsList ) }
                <Paginator setParams={ setParams } params={ params} list={ myRequestsList } />
            </div>
        </div>
    )
}

export async function getSearchList( params, token, dispatch ){
    dispatch({ payload: true, type: 'SET_LOADER' })
    try {
        const data = await axios.get( toMyObjLeaseRequests, 
          { headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
            },
            params: params
          })
          dispatch({ payload: data.data, type: 'REQUESTS_GET_MY' })
    } catch (err) {
    }
    window.scrollTo(0, 0)
    dispatch({ payload: false, type: 'SET_LOADER' })
}

export default ToMyObjRequests