import React,{ useEffect, useState } from 'react';
import styles from './executionEdit.module.css';
import { Link, useLocation } from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import ExecutionEditTitle from './elems/titleBar/editTitleBar';
import ExecutionEditSidebar from './sidebar/executionEditSidebar';
import ExecutionGeneralEdit from './elems/generalEdit';
import { urlGetMyExecution } from '../../../../utils/endPoints';
import { executionGetId } from '../../../../redux/asset/assetActions';
import Loader from '../../../loader/loader';
import ExecusionEditContracts from './elems/contracts/contractsEdit';
import { getRelatedEntities } from '../../announcement/utils';

const ExecutionEdit =( )=> {
    const token = useSelector(state => state.start.token);
    const executionItem = useSelector(state => state.asset.executionItem)
    const dispatch = useDispatch()
    const { pathname } = useLocation()
    const [ entitiesArr, setEntitiesArr ] = useState([])

    useEffect(() => {
      const id = pathname.split('/')[4]
      dispatch( executionGetId( urlGetMyExecution( id ), token.access_token))
      return () => {
        dispatch({type: 'EXECUTION_GET_ID', payload: null})
      }
    }, [])

    useEffect( () => {
      if( entitiesArr.length === 0 && !!executionItem) {
          async function getData(){
              await getRelatedEntities( executionItem.relatedEntities, setEntitiesArr )
          }
          getData()
      }
    }, [executionItem])

    function setGeneral(){
      if ( !!executionItem && pathname === `/asset/execution/edit/${executionItem.id}/contracts` ) return <ExecusionEditContracts data={ executionItem } />
      if ( !!executionItem ) return <ExecutionGeneralEdit data={ executionItem } entitiesArr={ entitiesArr } />
      else return <Loader />
    }

    return (
      <div className={styles.wrap}>
          <ExecutionEditTitle data={ executionItem } />
          <div className={styles.container}>
              <ExecutionEditSidebar data={ executionItem } />
              { setGeneral() }
          </div>
      </div>
    )
}

export default ExecutionEdit