import React from "react"

const DeleteIcon =( { color = '#FFA047', style, widthHeight = [28, 28] })=> {
    return <svg 
                className={style} 
                width={widthHeight[0]} 
                height={widthHeight[1]} 
                viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path 
                className={style}
                fillRule="evenodd" 
                clipRule="evenodd" 
                d="M8.21725 4.26675L9.11335 1.57844C9.29484 1.03399 9.80435 0.666748 10.3783 0.666748H17.5783C18.1522 0.666748 18.6617 1.03399 18.8432 1.57844L19.7393 4.26675H25.9782C26.7146 4.26675 27.3115 4.8637 27.3115 5.60008C27.3115 6.33646 26.7146 6.93341 25.9782 6.93341H24.8355L23.8569 23.5683C23.7326 25.6826 21.9818 27.3334 19.8638 27.3334H8.09267C5.97477 27.3334 4.22394 25.6826 4.09958 23.5683L3.12105 6.93341H1.9995C1.26312 6.93341 0.666168 6.33646 0.666168 5.60008C0.666168 4.8637 1.26312 4.26675 1.9995 4.26675H8.21725ZM11.0282 4.26675H16.9284L16.6172 3.33341H11.3393L11.0282 4.26675ZM22.1642 6.93341H5.79233L6.76164 23.4117C6.8031 24.1165 7.38671 24.6667 8.09267 24.6667H19.8638C20.5698 24.6667 21.1534 24.1165 21.1949 23.4117L22.1642 6.93341ZM16.2475 10.3169C16.2935 9.58196 16.9265 9.02341 17.6614 9.06934C18.3964 9.11528 18.9549 9.74831 18.909 10.4833L18.309 20.0833C18.2631 20.8182 17.63 21.3768 16.8951 21.3308C16.1601 21.2849 15.6016 20.6519 15.6475 19.9169L16.2475 10.3169ZM12.309 19.9169C12.3549 20.6519 11.7964 21.2849 11.0614 21.3308C10.3265 21.3768 9.69346 20.8182 9.64752 20.0833L9.04752 10.4833C9.00159 9.74831 9.56014 9.11528 10.2951 9.06934C11.03 9.02341 11.6631 9.58196 11.709 10.3169L12.309 19.9169Z" 
                fill={ color }
            />
        </svg>
    
}

export default DeleteIcon