import { checkProp } from "../check";
import { getAPI, deleteAPI, patchAPI, axiosGetAPI } from "../../redux/actions/crud";
import axios from "axios";
import { urlMyLeaseContracts, urlPatchLeaseContractDrafts } from "../endPoints";

export function getLeaseContractStatus( status ){
    const dictionary = {
        "pending": { "en_US": "Awaiting publication", "uk_UA": "Очікує публікації" },
        "active": { "en_US": "Active", "uk_UA": "Діючий" },
        "inactive": { "en_US": "Inactive", "uk_UA": "Недіючий" },
        "cancelled": { "en_US": "Cancelled", "uk_UA": "Розірвано" }
    }
    if ( status === 'dict' ) {
        let tmpArr = []
        for (let key in dictionary ){
            let tmp = { value: key, name: dictionary[key].uk_UA }
            tmpArr = [ ...tmpArr, tmp ]
        }
        return tmpArr
    }
    if( checkProp(dictionary, status ) ) return dictionary[status].uk_UA
    else return status
}

export function setLeaseContractMethod( method ) {
    switch( method ) {
        case 'extension':
            return 'Продовження договору'
        case 'listTypeSecond':
            return 'Передача в оренду з переліку другого типу'
        case 'auction':
            return 'Передача в оренду через аукціон'
        case 'signedBeforeETS':
            return 'Публікація договорів оренди укладених до введення в дію переліків в ЕТС'
    }
}

//AXIOS EXAMPLE
export async function postLeaseContract( data ){
    data.dispatch({ payload: true, type: 'SET_LOADER' })
    try {
        const responce = await axios.post( data.url, data.body,
            { 
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${data.token}`,
                }
          })
            if ( responce.status === 200 ){
                // data.dispatch({ payload: responce.data, type: 'AUCTIONS_GET' })
                data.redirect( responce.data.id )
            }
    } catch (err) {
        data.dispatch( {type: 'UNIVERSAL_ERROR', payload: `Помилка ${ err.message }` });
    }
    data.dispatch({ payload: false, type: 'SET_LOADER' })
}

export async function patchLeaseContract( data ){
    data.dispatch({ payload: true, type: 'SET_LOADER' })
    try {
        const responce = await axios.patch( data.url, data.body,
            { 
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${data.token}`,
                }
          })
            if ( responce.status === 200 ){
                if ( !!data?.set ) data.set( responce.data )
                // data.dispatch({ payload: responce.data, type: 'AUCTIONS_GET' })
                // if ( !!data?.redirect ) data.redirect( responce.data.id )
            }
    } catch (err) {
        data.dispatch( {type: 'UNIVERSAL_ERROR', payload: `Помилка ${ err.message }` });
    }
    data.dispatch({ payload: false, type: 'SET_LOADER' })
}

export function deleteMyLeaseContract(_url, token){
    const data = {
        url: _url,
        actionType: 'CONTRACT_GET_MY',
        header: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        }
    }
    return deleteAPI(data);
}

export function deleteMyDraftLeaseContractDocument(_url, token){
    const data = {
        url: _url,
        actionType: 'CONTRACT_GET_MY',
        header: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        }
    }
    return deleteAPI(data);
}



export function getMyLeaseContracts( objId, token){
    const data = {
        url: urlMyLeaseContracts( objId ),
        actionType: 'LEASE_CONTRACTS_GET_MY',
        header: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        }
    }
    return getAPI(data);
}

export function sendMyLeaseContract( objId, id, token, dispatch, setEditContract ){
    axiosGetAPI( {
        url: `${urlPatchLeaseContractDrafts( objId, id )}/send`,
        dispatch: dispatch,
        set: ( data )=> setEditContract( prev => ({...prev, data: data })),
        token: token,
    } )
}
