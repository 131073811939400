import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './components/app/App';
import * as serviceWorker from './serviceWorker';

import {compose, createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import {Provider} from 'react-redux';
import {rootReducer} from './redux/rootReducer';

// import { MuiPickersUtilsProvider } from '@material-ui/pickers';

// pick a date util library
// import MomentUtils from '@date-io/moment';
// import DateFnsUtils from '@date-io/date-fns';
// import LuxonUtils from '@date-io/luxon';

const store = createStore(rootReducer, compose(
    applyMiddleware(
        thunk
    ),
    //TODO: При сборках и заливках в гит отключать
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
))

console.log('1.3.35 master')

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        {/* <React.StrictMode> */}
            {/* <MuiPickersUtilsProvider utils={DateFnsUtils}> */}
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            {/* </MuiPickersUtilsProvider> */}
        {/* </React.StrictMode> */}
    </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();