import React,{ useState, useEffect} from 'react';
import style from './popupBank.module.css';
import { useSelector, useDispatch } from 'react-redux';
import EditBankAccountPopup from './createBankAccount';

// import {connect} from 'react-redux';

import Button from '../../../../../../elements/buttons/button';
import BankAccountItem from './bankAccountItem';
// import {
//     changePopUpBankAccount,
//     bankAccountGet,
//     openBankWindows,
//     setPopBankAccountNazvaBanku,
//     setPopBankAccountOpus,
//     setPopBankAccountNazva,
//     setPopBankAccountIdentif,
//     setPopBankAccountSheme,
//     setPopBankAccountEditId,

//     setPopBankAccountAccountHolderIdentifierScheme,
//     setPopBankAccountAccountHolderIdentifierLegalName,
//     setPopBankAccountAccountHolderIdentifierId,
// } from '../../../redux/actions/bankAccount/bankAccount.js';
// import {
//     setDataAddress,
// } from '../../../redux/createAddress';


const BankAccountsPopup = ( { close, ...props} ) => {
    const [bankArr, setBankArr] = useState([])
    const [addPopup, setAddPopup] = useState( false )

    const token = useSelector(state => state.start.token);
    const bankAccountsList = useSelector( state => state.asset.bankAccountsList )

    useEffect(() => {
        document.body.style.overflow = 'hidden'
        document.body.style.paddingRight = '5px'
        return () => {
            document.body.style.overflow = 'auto'
            document.body.style.paddingRight = '0'
        }
    }, [])

    function viewListBankAccounts( banks ){
        let tmp = null;
        if ( !!banks && banks.length === 0 ) return <div style={{width: '100%',height: '200px',display: 'flex',justifyContent: 'center',alignItems: 'center'}}>Рахунків не знайдено. Додайте рахунки.</div>
        else {
            return banks.map( ( item, count) => <BankAccountItem data={item} key={item.id} count={count} /> )
        }
        // if( banks !== null ){
        //     tmp = banks
        //     .map( (i, count) => { (
        //                 <div className={style.bankAccountElement}>
        //                     <div className={style.pn}>{count + 1}</div>
        //                     <div className={style.bankTitle}>{i.bankName}</div>
        //                     <div className={style.bankDescription}>{i.description}</div>
        //                     <div className={style.accountIdentificationsDescription}>{i.accountIdentifications !== null ? i.accountIdentifications[i.accountIdentifications.length - 1].description : null}</div>
        //                     <div className={style.accountIdentificationsIdentifier}>{i.accountIdentifications !== null ? i.accountIdentifications[i.accountIdentifications.length - 1].id : null}</div>
        //                     {/*<div className={style.accountIdentificationsIdentifier}>{i.accountIdentifications[i.accountIdentifications.length - 1].identifier}</div>*/}
        //                     <div className={style.accountIdentificationsShema}>{i.accountIdentifications !== null ? viewTypeAccount(i.accountIdentifications[i.accountIdentifications.length - 1].scheme) : null}</div>
        //                     <div 
        //                         className={style.editButton}
        //                         title='Редагувати банк'
        //                         // onClick={
        //                         //     () => {
        //                         //         let bankScheme = null
        //                         //         if(props.popUpBankAccountSheme !== null){
        //                         //             for(let tt = 0; tt < props.popUpBankAccountSheme.length; tt++){
        //                         //                 if(props.popUpBankAccountSheme[tt].classificationId === i.accountIdentifications[i.accountIdentifications.length - 1].scheme){
        //                         //                     bankScheme = JSON.stringify({
        //                         //                         id: props.popUpBankAccountSheme[tt].id,
        //                         //                         classificationId: props.popUpBankAccountSheme[tt].classificationId,
        //                         //                         description: props.popUpBankAccountSheme[tt].description,
        //                         //                         scheme: props.popUpBankAccountSheme[tt].scheme
    
        //                         //                     })
        //                         //                 }
        //                         //             }
        //                         //         }
        //                         //         props.setPopBankAccountNazvaBanku(i)
        //                         //         /*props.setPopBankAccountOpus(i.description)
        //                         //         props.setPopBankAccountNazva(i.accountIdentifications[i.accountIdentifications.length - 1].description)
        //                         //         //props.setPopBankAccountIdentif(i.accountIdentifications[i.accountIdentifications.length - 1].id)
        //                         //         props.setPopBankAccountIdentif(i.accountIdentifications[i.accountIdentifications.length - 1].identifier)
        //                         //         props.setDataAddress(i.currency, 'SET_POP_UP_BANK_ACCOUNT_CURRENCY')
        //                         //         //props.setPopBankAccountSheme(bankScheme)
        //                         //         props.setPopBankAccountSheme(i.accountIdentifications[i.accountIdentifications.length - 1].scheme)*/
        //                         //         props.setPopBankAccountEditId(i.id)
        //                         //         /*props.setPopBankAccountAccountHolderIdentifierScheme(i.accountHolder.identifier.scheme)
        //                         //         props.setPopBankAccountAccountHolderIdentifierLegalName(i.accountHolder.identifier.legalName)
        //                         //         props.setPopBankAccountAccountHolderIdentifierId(i.accountHolder.identifier.id)*/
    
        //                         //         props.changePopUpBankAccount(true)
        //                         //     }
        //                         // }
        //                     >
        //                         <svg width="20" height="20" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        //                             <path className={style.editSVG} fillRule="evenodd" clipRule="evenodd" d="M21.8146 8.12822L23.3009 6.64188L20.0249 3.36579L18.5385 4.85213L21.8146 8.12822ZM19.9389 10.0039L16.6628 6.72783L4.40753 18.9831L3.65351 23.0132L7.68361 22.2592L19.9389 10.0039ZM25.2559 4.8454C26.248 5.83757 26.248 7.44619 25.2559 8.43836L9.26898 24.4252C9.07985 24.6144 8.83795 24.7419 8.57504 24.7911L2.23753 25.9768C1.3211 26.1483 0.518454 25.3456 0.689915 24.4292L1.87564 18.0917C1.92483 17.8288 2.05236 17.5869 2.24149 17.3978L18.2284 1.41087C19.2205 0.418706 20.8292 0.418706 21.8213 1.41087L25.2559 4.8454ZM24.6799 26.0001H13.9868C12.2266 26.0001 12.2266 23.3334 13.9868 23.3334H24.6799C26.44 23.3334 26.44 26.0001 24.6799 26.0001Z" fill="#92929D"/>
        //                         </svg>
        //                     </div>
        //                     {/*<Button
        //                         className={style.buttonExitDocument}
        //                         variant="contained"
        //                         color="secondary"
        //                         onClick={
        //                             () => {
        //                                 deleteBank(props, i.id)
        //                             }
        //                         }
        //                     >
        //                         Вилучити
        //                     </Button>*/}
        //                 </div>
        //             )
        //         }
        //     )
        // }
        return tmp
    }
    
    return (
        <div className={style.popup_wrap}>
            { addPopup &&
                <EditBankAccountPopup close={ ()=> setAddPopup( false )} />
            }
            <div className={style.popup_message}>
                <div
                    className={style.close}
                    onClick={ ()=> close() }
                >
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path className={style.closeSVG} fillRule="evenodd" clipRule="evenodd" d="M8.41421 6.99989L13.364 11.9496C13.7545 12.3402 13.7545 12.9733 13.364 13.3639C12.9734 13.7544 12.3403 13.7544 11.9497 13.3639L7 8.41411L2.05025 13.3639C1.65973 13.7544 1.02656 13.7544 0.636039 13.3639C0.245514 12.9733 0.245515 12.3402 0.636039 11.9496L5.58579 6.99989L0.636039 2.05015C0.245515 1.65962 0.245514 1.02646 0.636039 0.635934C1.02656 0.245409 1.65973 0.245409 2.05025 0.635934L7 5.58568L11.9497 0.635934C12.3403 0.245409 12.9734 0.245409 13.364 0.635934C13.7545 1.02646 13.7545 1.65962 13.364 2.05015L8.41421 6.99989Z" fill="#92929D"/>
                    </svg>
                </div>
                <div className={style.titleBlock}>
                    <div className={style.title}>Робота з банківськими аккаунтами</div>
                    <Button
                        color="blue"
                        onClick={ () => setAddPopup(true) }
                    >
                        Створити новий банківський рахунок
                    </Button>
                    </div>
                    <div className={style.bankAccountHeader}>
                        <div>П/н</div>
                        <div className={style.bankTitle}>Назва банку</div>
                        <div className={style.bankDescription}>Опис</div>
                        <div className={style.accountIdentificationsDescription}>Опис до банківських реквізитів</div>
                        <div className={style.accountIdentificationsIdentifier}>Ідентіфікатор</div>
                        <div className={style.accountIdentificationsShema}>Тип банківського ідентифікатора:</div>
                    </div>
                    <div className={style.mainDocumentsBlock}>
                        { viewListBankAccounts( bankAccountsList ) }
                    </div>
            </div>
        </div>
    )
}



async function deleteBank(props, bankId){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/bankAccount/${bankId}`
    const response = await fetch(endPoint, {
        method: "DELETE",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        //const json = await response.json()
        let data = {
            domen: process.env.REACT_APP_END_POINT_BD,
            version: 'v1',
            local: props.langFlag,
            params: '?limit=100'
        }
        let header = {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
        props.bankAccountGet(data, header)
    }
    
}

// const mapStateToProps = state => {
//     return {
//         token: state.start.token,
//         auctions: state.start.auctionsGet,
//         langFlag: state.start.langFlag,
//         createDocuments: state.start.createDocuments,
//         bankAccount: state.start.bankAccount,
//         popUpBankAccountSheme: state.start.popUpBankAccountSheme,
//     }
// }

// const mapDispatchToProps = {
//     openBankWindows,
//     changePopUpBankAccount,
//     bankAccountGet,
//     setPopBankAccountNazvaBanku,
//     setPopBankAccountOpus,
//     setPopBankAccountNazva,
//     setPopBankAccountIdentif,
//     setPopBankAccountSheme,
//     setPopBankAccountEditId,
//     setDataAddress,

//     setPopBankAccountAccountHolderIdentifierScheme,
//     setPopBankAccountAccountHolderIdentifierLegalName,
//     setPopBankAccountAccountHolderIdentifierId,
// }

export default BankAccountsPopup