export function setAssetDocTypes( items, status ){
    let illustration = false
    // let tmpDate = Date.now()
    // if( !!items ){
    //     if(items.length > 0){
    //         let claimRights = false
    //         let asset = false
    //         for (let key in items){
    //             let tmpId = items[key].classification.id[0] + items[key].classification.id[1]
    //             if(['07','08'].includes(tmpId)) claimRights = true
    //             if( ['07','08'].includes(tmpId) === false ) asset = true
    //         }
    //         if( claimRights && !asset) illustration = false
    //     }
    // }
    let result = [
        { value: 'illustration', name: 'Ілюстрації', main: illustration },
        { value: 'x_presentation', name: 'Презентація', main: false },
        { value: 'assetNotice', name: `Рішення про затвердження переліку об'єктів МП`, main: false },
        { value: 'technicalSpecifications', name: `Інформація про об'єкт малої приватизації`, main: false }
    ]
    // if ( !!status ) result = [...result, { value: 'cancellationDetails', name: `Рішення про виключення об'єкта з переліку`, main: false } ]
    return result
}
export function setAssetDocTypesLP( items, status ){
    let illustration = false
    // let tmpDate = Date.now()
    // if( !!items ){
    //     if(items.length > 0){
    //         let claimRights = false
    //         let asset = false
    //         for (let key in items){
    //             let tmpId = items[key].classification.id[0] + items[key].classification.id[1]
    //             if(['07','08'].includes(tmpId)) claimRights = true
    //             if( ['07','08'].includes(tmpId) === false ) asset = true
    //         }
    //         if( claimRights && !asset) illustration = false
    //     }
    // }
    let result = [
        { value: 'illustration', name: 'Ілюстрації', main: illustration },
        { value: 'x_presentation', name: 'Презентація', main: false },
        { value: 'assetNotice', name: `Рішення про затвердження переліку об'єктів`, main: false },
        { value: 'technicalSpecifications', name: `Інформація про Об'єкт приватизації`, main: false }
    ]
    // if ( !!status ) result = [...result, { value: 'cancellationDetails', name: `Рішення про виключення об'єкта з переліку`, main: false } ]
    return result
}

export function getAssetItemType(id, aucType){ // функция нужна для определения типа айтема, и вывода нужных доков
    if (!id) return
    let tmpId = id[0] + id[1]
        if(['07','08'].includes(tmpId)) return 'claimRights'
        if(['04','05','06','16','30','32','34','39','42'].includes(tmpId)) return 'asset'
        return null
}