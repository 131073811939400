import React, {useState} from 'react';
import styles from './myRequestsTabItem.module.css';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import UniPopUp from '../../../popups/uniPopUp/uniPopUp';
import { leaseRequestDelete } from '../../../../utils/requests/requestsUtils';

import { checkProp, dateTransform, isMongo } from '../../../../utils/check';
import { getRequestList } from './myRequests';

const RequestTabItem = ( { data, params } ) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const token = useSelector(state => state.start.token);    
    const requestTypesList = useSelector(state => state.start.requestTypesList);
    const [dropDomnMenu, setdropDomnMenu] = useState(false)
    const [delPopup, setDelPopup] = useState( false )
    
    function setType(type) {

        if(requestTypesList !== null){
            let typeTranslate = requestTypesList.find((item) => {
                return item.name === type;
            });
            if (typeof typeTranslate !== 'undefined') {
                return isMongo(typeTranslate.description)
            } else {
                return '';
            }
        }
    }
    if ( !data ) return null

    return (
        <div className={styles.objTabItem}>
            <div className={styles.tdName}>{ !!data?.leaseRequestId ? data.leaseRequestId : `Опис: ${data.description}` }</div>
            <div className={styles.tdType}>{ setType(data.type) }</div>
            <div className={styles.tdDate}>
                { checkProp( data, 'datePublished' ) ? 
                    dateTransform( data.datePublished ) 
                    : 'Чернетка' 
                }
            </div>
            <div className={styles.tdRent}>{!!data.sellingEntity && isMongo(data.sellingEntity.name) }</div>
            {/* <div className={styles.tdAction}>{!!data.registryObject && data.registryObject.registryActions.length }</div> */}
            <div className={styles.tdDeclaration}>{ !!data?.documents && data.documents.length }</div>
            <div className={styles.tdBtnWrap}>
                <div className={ dropDomnMenu ? styles.btnActive : styles.tdBtn } onClick={()=> setdropDomnMenu(!dropDomnMenu)}>
                    <svg width="40" height="40" xmlns="http://www.w3.org/2000/svg" viewBox="-11 1 46 4">
                        <g>
                            <path className={styles.svgIcon} d="M5.33,2.67A2.67,2.67,0,1,0,2.67,5.34,2.66,2.66,0,0,0,5.33,2.67Z"/>
                            <path className={styles.svgIcon} d="M14.67,2.67A2.67,2.67,0,1,0,12,5.34,2.67,2.67,0,0,0,14.67,2.67Z"/>
                            <path className={styles.svgIcon} d="M24,2.67a2.67,2.67,0,1,0-2.67,2.67A2.66,2.66,0,0,0,24,2.67Z"/>
                        </g>
                    </svg>
                </div>
            </div>
            { dropDomnMenu ? 
            <div className={styles.dropDown}>
                <div className={styles.dropdownContent}>
                        { !data?.leaseRequestId &&
                            <div className={styles.menuStep}>
                                <Link className={styles.link} to={`/lease/lease_request/draft/${data.id}`} >
                                    Редагувати заявку
                                </Link>
                            </div>
                        }
                        { !data?.leaseRequestId &&
                            <div className={styles.menuStep} 
                                onClick={ ()=>
                                            {
                                                setDelPopup( true )
                                                setdropDomnMenu( false )
                                            }
                                        } 
                            >
                                {/* <Link className={styles.link} to={`/lease/lease_request/draft/${data.id}`} > */}
                                    Видалити заявку
                                {/* </Link> */}
                                </div>
                        }
                        { !!data?.leaseRequestId &&
                            <div className={styles.menuStep}><Link className={styles.link} to={`/lease/lease_request/${data.id}`} >Відкрити заявку</Link></div>
                        }
                        { !!data?.relatedObjectId &&
                            <div className={styles.menuStep}><Link className={styles.link} to={`/lease/object/${data.relatedObjectId}`} >Відкрити пов'язаний об'єкт</Link></div>
                        }
                </div>
                <div className={styles.dropdownBlock} onClick={()=> setdropDomnMenu(!dropDomnMenu)}></div>
            </div> : null 
            }
            { delPopup && <UniPopUp 
                                type={2}
                                data={'Ви дійсно хочете видалити чернетку?'}
                                close={ ()=> { 
                                        setDelPopup( false )
                                        setdropDomnMenu( false )
                                    }
                                }
                                action={ async ()=> {
                                        setDelPopup( false )
                                        await leaseRequestDelete(  data.id , token.access_token, ()=> navigate('/lease/my_requests'), dispatch )
                                        getRequestList( params, token.access_token, dispatch )
                                        // setParams( prev => ( { ...prev, offset: 0 } ) )
                                    }
                                }
                            /> 
            }
        </div>
        )
}

export default RequestTabItem