import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import CreateObject from './createObject/createObject';
// import ObjectItems from './objectItems/objectItems';
import LeaseItemsCreate from './items/leaseItems';
import UserLeaseContracts from './contracts/contracts';
import Documents from './documents/documents';
import Loader from '../../../../loader/loader';


const MainBlock = ( {isDraft}) => { // TODO: организовать передачу данных сверху вниз из ЭТОГО компонента и упростить структуру данных и дочерних ком-тов

    // const dispatch = useDispatch();
    const objectPage = useSelector(state => state.start.objectPage);
    const objectData = useSelector(state => state.start.objectGet);

    if ( objectPage === 'create' ) return <CreateObject isDraft={isDraft} />
    if ( objectPage === 'update' ) {
        if (objectData !== null) return <CreateObject data={objectData} isDraft={isDraft} />
        else return <Loader />
    }
    // if(objectPage === 'objectItems') return <ObjectItems isDraft={isDraft} />
    if( objectPage === 'objectItems' && !!objectData ) return <LeaseItemsCreate isDraft={isDraft} data={ objectData } />
    if( objectPage === 'documents') return <Documents isDraft={isDraft} />
    if( objectPage === 'contracts') return <UserLeaseContracts object={ objectData } />

}

export default MainBlock;