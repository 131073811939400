import { checkProp } from "../../../../../../utils/check"

const validation = {
    description: {
        test: ( a )=> {
            // if( !checkProp(a, 'uk_UA') ) return false
            // if( a.uk_UA.length < 1 ) return false
            if( !a ) return false
            return true
        },
        text: 'Заповніть опис заяви',
    },
    type: {
        test: ( a )=> {
            if (['agreementExtension','lease','newLease'].includes(a) === false ) return false
            return true
        },
        text: 'Оберіть тип заяви',
    },
    documents: {
        test: ( a )=> {
            if ( a.length < 1 ) return false
            // for ( let key in a) {
            //     if ( !a[key].decisionTitle ) return false
            //     if ( !a[key].decisionNumber ) return false
            //     if ( !a[key].decisionDate ) return false
            // }
            return true
        },
        text: 'Додайте документи',
    },
    // assetHolder: {
    //     test: ( a )=> {
    //         if ( !a ) return false
    //         return true
    //     },
    //     text: 'Додайте інформацію про балансоутримувача',
    // },
    // requestor: {
    //     test: ( a )=> {
    //         if ( !a ) return false
    //         return true
    //     },
    //     text: 'Додайте інформацію про заявника',
    // },
}

export function validateLR( obj ){
    let errArr = []
    for( let key in validation ){
        if ( obj.hasOwnProperty(key) ){
            if ( !validation[key].test( obj[key] ) ) {
                errArr = [...errArr, validation[key].text ]
            }
        }
    }
    return errArr.length > 0 ? errArr : false
}