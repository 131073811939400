import React,{ useState, useEffect } from 'react';
import styles from './itemProps.module.css'
import { checkProp, isMongo, getValues, getUnitCodes, dictionaryGet, getAdditionalClassificatorDM } from '../../../../../../../../../utils/check';
import {useSelector, useDispatch} from 'react-redux';
import Input from '../../../../../../../../inputs/input';
import InputTextArea from '../../../../../../../../inputs/inputTextArea';
import InputSelect from '../../../../../../../../inputs/inputSelect';
import InputDate from '../../../../../../../../inputs/inputDate';
import Button from '../../../../../../../../elements/buttons/button';
import Localization from '../localization/localization';
import Loader from '../../../../../../../../loader/loader';
import { assetItemCreate, assetItemUpdate } from '../../../../../../../../../redux/asset/assetActions';


const OtherPropertyPropsEdit =({ data, classification, close })=> {
    const assetItem = useSelector(state => state.asset.assetItem)
    const token = useSelector(state => state.start.token);

    const unitCodeList = useSelector(state => state.start.dictionaryUnitCode);
    const assetRegistrationStatus = useSelector(state => state.start.assetRegistrationStatus)
    const dispatch = useDispatch()

    const [loader, setLoader] = useState( false )

    const [additionalClassificator, setAdditionalClassificator] = useState( data?.additionalClassifications[0]?.id ? data.additionalClassifications[0].id : null )
    const [description, setDescription] = useState( data?.description ? isMongo(data.description) : '')
    const [registrationStatus, setRegistrationStatus] = useState( !!data?.registrationDetails?.registrationStatus ? data.registrationDetails.registrationStatus : null)
    const [registrationID, setRegistrationID] = useState( !!data?.registrationDetails?.registrationID ? data.registrationDetails.registrationID : null)
    const [registrationDate, setRegistrationDate] = useState( !!data?.registrationDetails?.registrationDate ? data.registrationDetails.registrationDate : null)

    const [unit, setUnit] = useState(data?.unit?.code ? data?.unit.code : 'H87')
    const [quantity, setQuantity] = useState( data?.quantity ? data.quantity : '')
    const [location, setLocation] = useState( data?.location ? data.location : '')
    const [address, setAdress] = useState( data?.address ? data.address : '')

    const addClassif = getAdditionalClassificatorDM(classification.id)

    useEffect( () => {
        if ( unitCodeList === null ) {
            dispatch( getUnitCodes() );
        }
        if ( assetRegistrationStatus === null ) {
            dispatch( dictionaryGet('registrationStatus', 'ASSETT_GET_REGISTRATION_STATUS') );
        }
        setAdditionalClassificator( addClassif.id )
    }, []);

    function getUnitList( data ){
        if ( !!data ){
            let tmpList = []
            for ( let key in data ){
                let tmpItem = {
                    value: data[key].id,
                    name: data[key].ukUA.name
                }
                tmpList = [...tmpList, tmpItem]
            }
            return tmpList
        }
    }

    function getUnitName( unit ){
        for ( let key in unitCodeList) {
            if (unitCodeList[key].id === unit) return unitCodeList[key].ukUA.name
        }        // unitCodeList.forEach( i => { if (i.id === unit) return i.ukUA.name } )
    }

    function registrationDetails(){
        if((registrationStatus !== null && registrationStatus !== '')
            && (registrationID !== null && registrationID !== '')
            && (registrationDate !== null && registrationDate !== '')
        ){
            return {
                registrationStatus: registrationStatus,
                registrationID: registrationID,
                registrationDate: registrationDate
            }
        }
        return null
    }

    const requestBody = {
        description: {
            uk_UA: description,
        },
        registrationDetails: registrationDetails(),
        classification: classification,
        additionalClassifications: addClassif,
        unit: {
            code: unit,
            name: {
                uk_UA: getUnitName( unit ) , //unitValues
            }
        },
        quantity: quantity !== null && quantity !== '' && quantity !== 0 ? +quantity : null,
        address: address,
        location: location,
        itemProps: null
    }

    function sendItemData( data ){
        const body = JSON.stringify(requestBody)
        if ( !data ) dispatch ( assetItemCreate(assetItem.id, body, token.access_token ))
        if ( !!data ) dispatch ( assetItemUpdate(assetItem.id, data.id, body, token.access_token ))
        setLoader( true )
        close()
    }

    function viewButtons(){
        return (
            <div className={styles.lineButtons}>
                <Button
                    data={'Зберегти зміни'}
                    color={'blue'}
                    onClick={
                        () => { sendItemData( data ) }
                    }
                />
                <Button
                    data={'Закрити'}
                    color={'gray'}
                    onClick={ close }
                />
            </div>
        )
    }

    if( loader ) return <Loader />
    return (
        <>
            <InputSelect
                label="Додатковий класифікатор"
                value={ additionalClassificator }
                onChange={ event => setAdditionalClassificator( event ) }
                options={ getAdditionalClassificatorDM( classification.id ) }
                dictionary
                reqiured
            />
            <InputTextArea
                value={description}
                // disabled={blokedInput(props, '01')}
                label="Опис об'єкту"
                reqiured
                onChange={ event => setDescription(event) }
            />
            <InputSelect
                label="Стан державної реєстрації об'єкту"
                value={ registrationStatus }
                onChange={ event =>  setRegistrationStatus( event ) }
                options={ assetRegistrationStatus }
                dictionary
                reqiured
            />
            <div className={styles.twoInLine}>
                <Input
                    label="Номер запису"
                    value={ registrationID }
                    onChange={ event =>  setRegistrationID( event ) }
                    reqiured={ registrationStatus === 'complete' ? true : false }
                />
                <InputDate 
                    data={{
                        label: 'Дата реєстрації',
                        value: registrationDate,
                        onChange: event =>  setRegistrationDate( event ),
                        reqiured: registrationStatus === 'complete' ? true : false
                    }}
                />
            </div>
            <div className={styles.twoInLine}>
                <Input
                    label="Кількість одиниць"
                    value={ quantity }
                    onChange={ event => setQuantity(event) }
                    reqiured
                    // disabled={blokedInput(props, '11')}
                />
                <InputSelect
                    label={"Одиниці виміру об'єкта"}
                    value={ unit }
                    onChange={ event => setUnit(event) }
                    options={ getUnitList( unitCodeList ) }
                    reqiured
                    finder
                />
            </div>
            <Localization address={address} location={location} setLocation={setLocation} setAdress={setAdress} classification={classification} />
            { viewButtons() }
        </>
    )
}

export default OtherPropertyPropsEdit

