import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { sendAnnouncementDraft } from '../../../../../utils/endPoints';
import Button from '../../../../elements/buttons/button'
import Loader from '../../../../loader/loader';
import UniPopUp from '../../../../popups/uniPopUp/uniPopUp'
import { validateAsset } from './validation'

const SendObjectToProzoro =({ data, asset })=> {
    const token = useSelector(state => state.start.token)
    const userProfiles = useSelector(state => state.start.userProfiles)
    const navigate = useNavigate()

    const [popUpDisabled, setPopUpDisabled] = useState( false )
    const [api, setApi] = useState( { loader: false, data: '' } )
    const [errorsPopup, setErrorsPopup] = useState( 
        {   errors: [],
            state: false
        } )

    function sendFunction( obj ){
        if ( !!validateAsset( obj ) ) {
            setErrorsPopup( prev => ({ ...prev, state: true }) )
            setErrorsPopup( prev => ({ ...prev, errors: validateAsset( obj ) }) )
        } else {
            setApi( prev => ({...prev, loader: true }))
            fetch( `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/drafts/object/${data.id}/send`, {
                    method: 'GET',
                    headers: { 'Authorization': 'Bearer ' + token.access_token, 'Content-Type': 'application/json' }
                })
                .then( res => {
                    if ( res.ok ) {
                        setApi( prev => ({...prev, loader: false }))
                        setApi( prev => ({...prev, data: 'Об\'єкт успішно опубліковано' }))
                        setApi( prev => ({...prev, ok: true }))
                    }
                    return res.json() 
                })
                .then( data => {
                    // let json = res.json()
                    if ( data?.message ) {
                        setApi( prev => ({...prev, loader: false }))
                        let tmpArr = []
                        switch (true) {
                            case (typeof data.message === 'object' || data.message instanceof Object):
                                for (const property in data.message) {
                                    tmpArr.push(`${property}: ${data.message[property]}`);
                                }
                                break

                            case (typeof data.message === 'string' || data.message instanceof String):
                                tmpArr.push(data.message)
                                break
                            default:
                                let tmpJSON = JSON.parse(data.message[0])
                                if (data.message[0].indexOf("leaseRules") !== -1) {
                                    if (data.message[0].indexOf("schedule") !== -1) {
                                        if (data.message[0].indexOf("hoursBy") !== -1) {
                                            if (data.message[0].indexOf("value") !== -1) {
                                                if (data.message[0].indexOf("Value '2.45' is not int.") !== -1) {
                                                    tmpArr.push('Кількість годин у полі "Пропонований графік оренди" має бути цілим числом')
                                                }
                                            }
                                        }
                                        if (data.message[0].indexOf("daysBy") !== -1) {
                                            if (data.message[0].indexOf("value") !== -1) {
                                                if (data.message[0].indexOf("This field is required") !== -1) {
                                                    tmpArr.push('Необхідно вказати кількість днів у "Пропонований графік оренди"')
                                                }
                                            }
                                        }
                                        if (data.message[0].indexOf("period") !== -1) {
                                            if (data.message[0].indexOf("weekdays") !== -1) {
                                                if (data.message[0].indexOf("must contain at least one object")) {
                                                    tmpArr.push('Необхідно заповнити хоча б один день тижня для графіку погодинного використання')
                                                }
                                            }
                                            if (data.message[0].indexOf("startDate") !== -1) {
                                                if (data.message[0].indexOf("Could not parse . Should be ISO 8601 or timestamp.")) {
                                                    tmpArr.push('Необхідно коректно вказати початок періоду погодинного використання')
                                                }
                                            }
                                            if (data.message[0].indexOf("endDate") !== -1) {
                                                if (data.message[0].indexOf("Could not parse . Should be ISO 8601 or timestamp.")) {
                                                    tmpArr.push('Необхідно коректно вказати кінець періоду погодинного використання')
                                                }
                                            }
                                        }
                                    }
                                }
                                if (data.message[0].indexOf("valuesCharacteristics") !== -1) {
                                    if (data.message[0].indexOf("initialBookValue") !== -1) {
                                        if (data.message[0].indexOf("amount") !== -1) {
                                            if (data.message[0].indexOf("Require int or float type, but str found") !== -1) {
                                                tmpArr.push('Необхідно заповнити поле "Первісна балансова вартість". Мінімальна допустима сума для введення 0,00 грн. Якщо дані відсутні, залиште поле пустим.')
                                            }
                                        }
                                    }
                                    if (data.message[0].indexOf("residualBookValue") !== -1) {
                                        if (data.message[0].indexOf("amount") !== -1) {
                                            if (data.message[0].indexOf("Require int or float type, but str found") !== -1) {
                                                tmpArr.push('Необхідно заповнити поле "Залишкова балансова вартість". Мінімальна допустима сума для введення 0,00 грн. Якщо дані відсутні, залиште поле пустим.')
                                            }
                                        }
                                    }
                                    if (data.message[0].indexOf("secondListAbsoluteValue") !== -1) {
                                        if (data.message[0].indexOf("amount") !== -1) {
                                            if (data.message[0].indexOf("Require int or float type, but str found") !== -1) {
                                                tmpArr.push('Необхідно заповнити поле "Орендна плата". Мінімальна допустима сума для введення 0,00 грн. Якщо дані відсутні, залиште поле пустим.')
                                            }
                                        }
                                    }
                                }

                                if (tmpJSON.hasOwnProperty("relatedOrganizations") === true) {
                                    if (tmpJSON.relatedOrganizations.hasOwnProperty("propertyOwner") === true) {
                                        if (tmpJSON.relatedOrganizations.propertyOwner.hasOwnProperty("contactPoint") === true) {
                                            if (tmpJSON.relatedOrganizations.propertyOwner.contactPoint.hasOwnProperty("telephone") === true) {
                                                if (tmpJSON.relatedOrganizations.propertyOwner.contactPoint.telephone.indexOf("String value did not match validation regex.") !== -1) {
                                                    tmpArr.push('Необхідно вказати номер телефону для Балансоутримувача у форматі +380ХХХХХХХХХ')
                                                }
                                                if (tmpJSON.relatedOrganizations.propertyOwner.contactPoint.telephone.indexOf("This field is required") !== -1) {
                                                    tmpArr.push('Вказати номер телефону для Балансоутримувача')
                                                }
                                            }
                                        }
                                    }
                                    if (tmpJSON.relatedOrganizations.hasOwnProperty("sellingEntity") === true) {
                                        if (tmpJSON.relatedOrganizations.sellingEntity.hasOwnProperty("contactPoint") === true) {
                                            if (tmpJSON.relatedOrganizations.sellingEntity.contactPoint.hasOwnProperty("telephone") === true) {
                                                if (tmpJSON.relatedOrganizations.sellingEntity.contactPoint.telephone.indexOf("This field is required") !== -1) {
                                                    tmpArr.push('Вказати номер телефону для Орендодавця')
                                                }
                                                if (tmpJSON.relatedOrganizations.sellingEntity.contactPoint.telephone.indexOf("String value did not match validation regex.") !== -1) {
                                                    tmpArr.push('Необхідно вказати номер телефону для Орендодавця у форматі +380ХХХХХХХХХ')
                                                }
                                            }
                                        }
                                    }
                                    if (tmpJSON.relatedOrganizations.hasOwnProperty("governer") === true) {
                                        if (tmpJSON.relatedOrganizations.governer.hasOwnProperty("contactPoint") === true) {
                                            if (tmpJSON.relatedOrganizations.governer.contactPoint.hasOwnProperty("faxNumber") === true) {
                                                if (tmpJSON.relatedOrganizations.governer.contactPoint.faxNumber.indexOf("String value did not match validation regex.") !== -1) {
                                                    tmpArr.push('Необхідно вказати номер факсу для Уповноваженого органу управління у форматі +380ХХХХХХХХХ')
                                                }
                                            }
                                            if (tmpJSON.relatedOrganizations.governer.contactPoint.hasOwnProperty("telephone") === true) {
                                                if (tmpJSON.relatedOrganizations.governer.contactPoint.telephone.indexOf("String value did not match validation regex.") !== -1) {
                                                    tmpArr.push('Необхідно вказати номер телефону для Уповноваженого органу управління у форматі +380ХХХХХХХХХ')
                                                }
                                                if (tmpJSON.relatedOrganizations.governer.contactPoint.telephone.indexOf("This field is required") !== -1) {
                                                    tmpArr.push('Вказати номер телефону для Уповноваженого органу управління')
                                                }
                                            }
                                        }
                                    }
                                    if (tmpJSON.relatedOrganizations.hasOwnProperty("currentTenants") === true) {
                                        if (tmpJSON.relatedOrganizations.currentTenants[0].hasOwnProperty("contactPoint") === true) {
                                            if (tmpJSON.relatedOrganizations.currentTenants[0].contactPoint.hasOwnProperty("telephone") === true) {
                                                if (tmpJSON.relatedOrganizations.currentTenants[0].contactPoint.telephone.indexOf("String value did not match validation regex.") !== -1) {
                                                    tmpArr.push('Необхідно вказати номер телефону для Чинного орендаря у форматі +380ХХХХХХХХХ')
                                                }
                                                if (tmpJSON.relatedOrganizations.currentTenants[0].contactPoint.telephone.indexOf("This field is required") !== -1) {
                                                    tmpArr.push('Вказати номер телефону для Чинного орендаря')
                                                }
                                            }
                                        }
                                    }
                                }
                                if (tmpJSON.hasOwnProperty("decisions") === true) {
                                    if (tmpJSON.decisions.hasOwnProperty("decisionTitle") === true) {
                                        if (tmpJSON.decisions.decisionTitle.indexOf("String value is too short") !== -1) {
                                            tmpArr.push('String value is too short')
                                        }
                                    }
                                    if (tmpJSON.decisions.hasOwnProperty("decisionNumber") === true) {
                                        if (tmpJSON.decisions.decisionNumber.indexOf("String value is too short") !== -1) {
                                            tmpArr.push('String value is too short')
                                        }
                                    }
                                }
                                if (tmpJSON.hasOwnProperty("registryObjectItems") === true) {
                                    for (let tt = 0; tt < tmpJSON.registryObjectItems.length; tt++) {
                                        if (tmpJSON.registryObjectItems[tt].hasOwnProperty("jpcProps") === true) {
                                            if (tmpJSON.registryObjectItems[tt].jpcProps.hasOwnProperty("landProps") === true) {
                                                if (tmpJSON.registryObjectItems[tt].jpcProps.landProps.hasOwnProperty("encumbrances") === true) {
                                                    if (tmpJSON.registryObjectItems[tt].jpcProps.landProps.encumbrances.indexOf("This field is required") !== -1) {
                                                        tmpArr.push('Необхідно внести інформацію про про наявні обтяження')
                                                    }
                                                    if (tmpJSON.registryObjectItems[tt].jpcProps.landProps.encumbrances.indexOf("noEncumbrances value not available if hasEncumbrances=True") !== -1) {
                                                        tmpArr.push('Значення "Обтяження відсутні" недоступне, якщо  у відмітці "Присутні обтяження" вказано "Так"')
                                                    }
                                                }
                                                if (tmpJSON.registryObjectItems[tt].jpcProps.landProps.hasOwnProperty("jointOwnership") === true) {
                                                    if (tmpJSON.registryObjectItems[tt].jpcProps.landProps.jointOwnership.indexOf("This field is required") !== -1) {
                                                        tmpArr.push('Необхідно внести інформацію про наявних співвласників')
                                                    }
                                                }
                                                if (tmpJSON.registryObjectItems[tt].jpcProps.landProps.hasOwnProperty("utilitiesAvailability") === true) {
                                                    if (tmpJSON.registryObjectItems[tt].jpcProps.landProps.utilitiesAvailability.indexOf("This field is required") !== -1) {
                                                        tmpArr.push('Необхідно внести інформацію про наявні інженерні мережі')
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                        }
                        if(tmpArr.length > 0){
                            setApi( prev => ({...prev, data: <><div style={{fontWeight: '600'}}>Для публікації будьласка виправте помилки:</div>{tmpArr.map( i => <div key={i}>{i}</div>)}</> }))
                        }else{
                            setApi( prev => ({...prev, data: data?.message.map( i => <div key={i}>{i}</div>) }))
                        }
                    }
                })
                .catch( error => {
                    setApi( prev => ({...prev, loader: false }))
                    setApi( prev => ({...prev, data: error }))
                })
        }
    }

    function disabledBtn( asset ){
        return false
    }


    if ( !!data?.objectId ) return null

    if ( api.loader ) return <Loader />
    if ( api.data ) return <UniPopUp 
                                type={1}
                                close={ ()=> {
                                    setApi( prev => ({...prev, data: '' }))
                                    if ( api.ok ) navigate('/lease/my_objects')
                                }}
                                data={ api.data } 
                            />

    return (
        <>
            { popUpDisabled && 
                <UniPopUp 
                    type={1}
                    close={ ()=> setPopUpDisabled(false) } 
                    data={'Заповніть всі обов\'язкові поля, додайте Об\'єкти та документи, щоб опублікувати об\'єкт'} 
                /> 
            }
            { errorsPopup.state && 
                <UniPopUp 
                    type={1}
                    close={ ()=> setErrorsPopup( prev => ({...prev, state: false})) }
                    data={
                        <>
                            <div  style={{fontWeight: '600'}}>Для публікації об'єкту необхідно:</div>
                            <ul style={{textAlign: 'left'}}>
                                { errorsPopup.errors.map( i => <li key={i.toString()}>{i}</li>) }
                            </ul>
                        </>
                    }
                /> 
            }
            {userProfiles !== null && userProfiles.isFinAuth === true
                ? <Button 
                    height={'50px'} width={'200px'} 
                    // disabled={ disabledBtn() }
                    onClick={ ()=> { disabledBtn( data ) ? setPopUpDisabled(true) : sendFunction( data, asset ) } }
                >
                    Опублікувати в Prozorro
                </Button>
                : null
            }
        </>
    )

}

export default SendObjectToProzoro