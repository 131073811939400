import React, { useState, useEffect } from 'react';
import styles from './documentItem.module.css';
import { useSelector } from 'react-redux';
import Button from '../../buttons/button';
import { checkProp, dateTransform, isMongo, getShorter } from '../../../../utils/check';
import ViewImageIcon from '../../svg/viewImagelIcon';
import ImgViewPopUp from '../../../popups/imgViewPopUp/imgViewPopUp';
import DeleteIcon from '../../svg/deleteIcon';
import DownLoadIcon from '../../svg/downloadIcon';
import EditIcon from '../../svg/editlIcon';
import AddDocPopUp from '../addDocPopup/addDocPopup';
import UniPopUp from '../../../popups/uniPopUp/uniPopUp';
import { getDocumentHistory } from './utils';
import { urlPatchCompDocumentMyExecution } from '../../../../utils/endPoints'
import HistoryIcon from '../../../elements/svg/historylIcon';
import HalfInfoBlock from '../../../elements/halfInfoBlock/halfInfoBlock';


    const infoModalBlock = ( data, close )=> { return <>
        <div className={styles.wrapperInfo} onClick={close}></div>
            <div className={styles.inputInfoBlock}>
                <div className={styles.modDocTitle}>{ isMongo(data.title) }</div>
                {checkProp(data, 'datePublished') ?
                    <div className={styles.infoField}>
                        Дата створення:<span>{dateTransform(data.datePublished)}</span>
                    </div>
                    : null
                }
                {checkProp(data, 'dateModified') ?
                    <div className={styles.infoField}>
                        Дата редагування:<span>{dateTransform(data.dateModified)}</span>
                    </div>
                    : null
                }
                { data?.documentType && data.documentType === 'illustration' ?
                    <div className={styles.infoField}>
                        Тип документу:<span>
                            Зображення
                        </span>
                    </div>
                    : null
                }
                { data?.format &&
                    <div className={styles.infoField}>
                        Формат:<span>{data.format}</span>
                    </div>
                }
                {checkProp(data, 'description') ?
                    <div className={styles.infoField}>
                        Опис документу:<span>
                            { isMongo(data.description) }
                        </span>
                    </div>
                    : null
                }
                <div className={styles.bottomBtnBlock}>
                    <Button 
                        color='blue'
                        height='28px'
                        onClick={ close }>
                            Зрозуміло
                    </Button>
                </div>
        </div>
    </>
    }

    const HistoryItem = ( { data } )=> {
        return (
            <div className={styles.historyItem}>
                <div className={styles.hisDocTitle}>{ isMongo( data.title ) }</div>
                <div className={styles.hisDocContent}>
                    <HalfInfoBlock
                        title={'Опис документу'}
                        data={ isMongo( data.description ) }
                    />
                    <HalfInfoBlock 
                        title={'Дата створення:'}
                        data={ dateTransform(data.datePublished, 'time') }
                    />
                    <Button type={'circle'}>
                        <a href={data.url} download={ isMongo(data.title) }>
                            <DownLoadIcon widthHeight={[16,20]} style={styles.svgIconDownld} />
                        </a>
                    </Button>
                </div>
                
            </div>
        )
    }

const CreateDocItem = ( { data, docProps, contractId, key } ) => {
    // const assetItem = useSelector(state => state.asset.assetItem)
    const token = useSelector( state => state.start.token )
    const [svgInfo, setSvgInfo] = useState(false)
    const [imgView, setImgView] = useState(false)
    const [uniPopup, setUniPopup] = useState(false)
    const [edit, setEdit] = useState(false)
    const [history, setHistory] = useState([])
    const [hisPopUp, setHisPopUp] = useState(false)

    useEffect(() => {
        if ( data.datePublished !== data.dateModified && !!docProps?.url?.history ) {
          getDocumentHistory( docProps.url.history( docProps.object.id, contractId, data.id ), token.access_token, setHistory )
        }
      }, [])

    function getType( type ){
        for (let key in docProps.docList) {
            if ( docProps.docList[key].value === type ) return docProps.docList[key].name
        }
    }

    //TODO: Навести колись лад з умовами показу кнопок докайтему, щоб вони приходили із-зовні

    function deleteBtnView(){
        const button = (
                    <div className={styles.deleteBtn}
                            onClick={ ()=> setUniPopup(true) }
                    >
                        <DeleteIcon 
                            widthHeight={[20,20]}
                            style={styles.svgIconDelete}
                        />
                    </div>
        )
        if ( !!docProps?.buttons?.delete ) return button
        if ( !docProps.object.datePublished ) return button
        return null
    }

    function editBtnView(){
        const button = (
                    <div className={styles.editBtn}
                            onClick={ ()=> setEdit(true) }
                            title='Редагувати документ'
                    >
                        <EditIcon widthHeight={[15,15]} style={styles.svgIconDelete} />
                    </div>
        )
        if ( docProps?.buttons?.edit === false ) return null
        if ( docProps?.contract?.status !== 'pending' ) return null
        return button
    }

    return (
        <div className={styles.docList}>
            { uniPopup && 
                <UniPopUp type={2} 
                    data={ `Ви дійсно хочете видалити документ ${isMongo(data.title)}?`}
                    action={ ()=> { 
                        if(!!contractId){
                            docProps.url.delete(docProps.object.id, data.id)
                        }else if(docProps.docOf === "completion"){
                            docProps.url.delete(docProps.object.id, data.id)
                        }else{
                            docProps.url.delete(data.id)
                        }
                        setUniPopup(false)
                    }}
                    close={ ()=> setUniPopup(false) } 
                /> 
            }
            { edit && <AddDocPopUp data={ data } asset={ docProps.object } close={()=> setEdit(false)} docProps={docProps} contractId={contractId}/> }
            <div className={styles.docBlockLft} title={ data.title && isMongo(data.title) } >
                { getShorter(isMongo(data.title)) }
            </div>
            <div className={styles.docBlockRgt} >
                { getType(data.documentType) }
            </div>
            <div className={styles.iconsBlock}>
                { data.documentType === 'illustration' && !!data.url && 
                    <Button type={'circle'} onClick={() => setImgView(true)} >
                        <ViewImageIcon 
                            title='переглянути зображення' 
                            style={styles.svgIconView}
                            widthHeight={[28,28]}
                        />
                    </Button>
                }
                {
                    history.length > 0 && 
                    <Button type={'circle'} color={'var(--darkGray)'} 
                        onClick={ () => setHisPopUp(true) }
                        title={'Переглянути історію змін'}
                    >
                            <HistoryIcon widthHeight={[30,30]} color={'var(--darkGray)'} />
                    </Button>
                }
                <div className={styles.infoWrap}>
                    <Button type={'circle'}>
                        <svg className={svgInfo ? styles.svgIconInfoActive : styles.svgIconInfo} width="20" height="20"
                            viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"
                            onClick={ ()=> setSvgInfo( true ) }
                        >
                            <path className={styles.svgIcon} d="M10.75 14.2001L10.75 9.45014C10.75 8.92547 10.3247 8.50014 9.80003 8.50014C9.27536 8.50014 8.85003 8.92547 8.85003 9.45014L8.85003 14.2001C8.85003 14.7248 9.27536 15.1501 9.80003 15.1501C10.3247 15.1501 10.75 14.7248 10.75 14.2001Z" fill="#92929D"/>
                            <path className={styles.svgIcon} d="M8.85003 6.60014C8.85003 6.07547 9.27536 5.65014 9.80003 5.65014C10.3247 5.65014 10.75 6.07547 10.75 6.60014C10.75 7.12481 10.3247 7.55014 9.80003 7.55014C9.27536 7.55014 8.85003 7.12481 8.85003 6.60014Z" fill="#92929D"/>
                            <path className={styles.svgIcon} fillRule="evenodd" clipRule="evenodd" d="M0.300034 10.4001C0.300034 5.15343 4.55333 0.900139 9.80003 0.900139C15.0467 0.900139 19.3 5.15343 19.3 10.4001C19.3 15.6469 15.0467 19.9001 9.80003 19.9001C4.55333 19.9001 0.300034 15.6469 0.300034 10.4001ZM17.4 10.4001C17.4 6.20278 13.9974 2.80014 9.80003 2.80014C5.60267 2.80014 2.20003 6.20278 2.20003 10.4001C2.20003 14.5975 5.60267 18.0001 9.80003 18.0001C13.9974 18.0001 17.4 14.5975 17.4 10.4001Z" fill="#92929D"/>
                            
                        </svg>
                    </Button>
                    
                </div>
                {!!data.url &&
                    <Button type={'circle'}>
                        <a href={data.url} download={ isMongo(data.title) }>
                            <DownLoadIcon widthHeight={[16,20]} style={styles.svgIconDownld} />
                        </a>
                    </Button>
                }
                { svgInfo && infoModalBlock(data, ()=> setSvgInfo( false )) }
                { imgView && 
                    <ImgViewPopUp 
                        close={() => setImgView(false)}
                        data={data} 
                    /> 
                }
                { editBtnView() }
                { deleteBtnView() }
            </div>

            { hisPopUp && 
                <UniPopUp 
                    close={() => setHisPopUp(false)}
                    data={ 
                    <div className={styles.historyContainer} >
                        <div>Попередні версії документу {/* specs.docType */} </div>
                        <div className={styles.historyList} >{ history.map( i => <HistoryItem data={i} key={i.id} /> )}</div>
                    </div> }
                />
            }
        </div>
    )
}

// Приклад даних:
// const docProps = { 
//     dispatch: ( res )=> dispatch( { payload: res, type: 'OBJECT_GET'}),
//     docOf: 'object',
//     object: data,
//     docList: setAssetDocTypes( assetItem.assetItems, assetItem.status ),
//     url: {
//         patch: isDraft ? myDraftObjectDocument : myObjectDocument,
//         post: isDraft ? myDraftObjectDocumentPost : myObjectDocumentPost,
//         delete: ( id ) => dispatch( assetDocDelete( data.id, id, token.access_token ) )
//     }
// }

export default CreateDocItem