import React, {useState, useEffect} from 'react';
import Button from '../../../../elements/buttons/button';
import Input from '../../../../inputs/input';
import styles from './buttonFilters.module.css';
import InputDate from '../../../../inputs/inputDate';
import { checkProp } from '../../../../../utils/check';

    const Popup =({ setParams, params, setPopup })=> {
        const [objectId, setObjectId] = useState( checkProp( params, 'registryObjectId' ) ? params.registryObjectId : null )
        const [title, setTitle] = useState( checkProp( params, 'title' ) ? params.title : null )
        const [description, setDescription] = useState( checkProp( params, 'description' ) ? params.description : null )
        const [propertyOwner, setPropertyOwner] = useState( checkProp( params, 'propertyOwner' ) ? params.propertyOwner : null )
        const [sellingEntity, setSellingEntity] = useState( checkProp( params, 'sellingEntity' ) ? params.sellingEntity : null )
    
        const [datePublishedFrom, setDatePublishedFrom] = useState( !!params?.datePublishedFrom ? params.datePublishedFrom : null )
        const [datePublishedTo, setDatePublishedTo] = useState( !!params?.datePublishedTo ? params.datePublishedTo : null )

        return (
                    <div className={styles.wrapMain}>
                        <div className={styles.wrap}>
                            <h3 className={styles.filterTitle}>Розширений пошук</h3>
                            <div className={styles.content}>
                                <Input 
                                    label='Пошук по ідентифікатору об`єкта'
                                    value={ objectId }
                                    onChange={ e => setObjectId(e) }
                                />
                                <Input 
                                    label='Пошук по назві об`єкта'
                                    value={ title }
                                    onChange={ e => setTitle(e) }
                                />
                                <Input 
                                    label='Пошук по опису об`єкта' 
                                    value={ description }
                                    onChange={ e => setDescription(e) }
                                />
                                <Input 
                                    label='Пошук по організації балансоутримувача' 
                                    value={ propertyOwner }
                                    onChange={ e => setPropertyOwner(e) }
                                />
                                <Input 
                                    label='Пошук по організації орендодавця' 
                                    value={ sellingEntity }
                                    onChange={ e => setSellingEntity(e) }
                                />
                                <InputDate
                                    data={{
                                        label: 'Дата публікації, з',
                                        value: datePublishedFrom,
                                        onChange: e => setDatePublishedFrom( e ),
                                        // time: true
                                    }}
                                />
                                <InputDate
                                    data={{
                                        label: 'Дата публікації, по',
                                        value: datePublishedTo,
                                        onChange: e => setDatePublishedTo( e ),
                                        // time: true
                                    }}
                                />
                            </div>
                            <div className={styles.bottomPart}>
                                <Button 
                                    color='gray'
                                    onClick={() => setPopup( prev => !prev) }>
                                    Закрити
                                </Button>
                                <Button
                                    color='blue'
                                    onClick={() => {
                                        setParams( prev => ({...prev,
                                            registryObjectId: objectId,
                                            title: title,
                                            description: description,
                                            propertyOwner: propertyOwner,
                                            sellingEntity: sellingEntity,
                                            datePublishedFrom: datePublishedFrom,
                                            datePublishedTo: datePublishedTo,
    
                                        }
                                        ) )
                                        setPopup( prev => !prev )
                                    }}>
                                    Застосувати
                                </Button>
                            </div>
                        </div>
                    </div>
                )
    }

const LeaseObjectAdvancedSearch = ({ setParams, params })=> {
    const [popup, setPopup] = useState( false )

    return (
        <>
            <div
                className={styles.searchFilterBlock_status}
                onClick={ () => setPopup( prev => !prev) }
            >
                <div>Розширений пошук</div>
                {/* { ( !!arr?.status && arr.status.length > 0 )&&  */}
                    <div style={{borderLeft: '1px dotted #BFBFC6', paddingLeft: '5px', marginLeft: '5px'}}>
                        {/* { arr.status.length } */}
                    </div> 
                
            </div>
            { popup && <Popup setParams={ setParams } params={ params } setPopup={ setPopup } /> }
        </>
    )
}

export default LeaseObjectAdvancedSearch
