import React, { useEffect, useState } from 'react';
import styles from './managingOrganizations.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// import Loader from '../../../../../../../loader/loader.js';
import FullInfoBlock from '../../../../../../elements/fullInfoBlock/fullInfoBlock';
// import HalfInfoBlock from '../../../../../../../elements/halfInfoBlock/halfInfoBlock.js';
import InputSelect from '../../../../../../inputs/inputSelect';
import InfoBlockСollapser from '../../../../../../elements/infoBlockСollapser/infoBlockСollapser';

import { objectGetOwnershipType, relatedOrganizationsUpdate, objectGet } from '../../../../../../../redux/actions/object/object.js';
import { checkProp, getTranslate } from '../../../../../../../utils/check';
import { getDictionaryByType, myDraftObject, myObjectPrivate  } from '../../../../../../../utils/endPoints';
import Button from '../../../../../../elements/buttons/button'
import OrganizationItem from './organizationItem';
import OrganizationItemCT from './organizationItemCTsingl';

const ManagingOrganizationsNew = ({ data, updateData, isDraft, ...props }) => {
    const dispatch = useDispatch();
    const pageCreate = useSelector(state => state.start.objectPage);
    const token = useSelector(state => state.start.token);
    const object = useSelector(state => state.start.objectGet);
    const objectCreate = useSelector(state => state.start.objectCreate);

    // const objectOwnershipTypeList = useSelector(state => state.start.objectOwnershipTypeList);
    
    // const [updateOrganization, setUpdateOrganization] = useState(null);
    

    const [ownershipType, setOwnershipType] = useState(checkProp(data, 'ownershipType') ? data.ownershipType : null);
    const [propertyOwner, setPropertyOwner] = useState( checkProp(data, 'propertyOwner') ? data.propertyOwner : null )
    const [sellingEntity, setSellingEntity] = useState( checkProp(data, 'sellingEntity') ? data.sellingEntity : null )
    //const [currentTenant, setCurrentTenant] = useState( checkProp(data, 'currentTenant') ? data.currentTenant : null )
    const [currentTenant, setCurrentTenant] = useState( checkProp(data, 'currentTenants') && data?.currentTenants.length > 0 ? data.currentTenants[0] : null )
    const [governer, setGoverner] = useState( checkProp(data, 'governer') ? data.governer : null )

    const [update, setUpdate] = useState( false );

    const navigate = useNavigate()

    // useEffect(() => {
    //     if(objectOwnershipTypeList === null || objectOwnershipTypeList === undefined) {
    //         dispatch(objectGetOwnershipType( getDictionaryByType('ownershipType') ));
    //     }
    // }, []);

    const objectOwnershipTypeList = [
        { "name": "compatible","description": {"en_US": "compatible","uk_UA": "Сумісна"} },
        { "name": "municipal", "description": { "en_US": "municipal", "uk_UA": "Комунальна" } },
        // { "name": "private", "description": { "en_US": "Private", "uk_UA": "Приватна" } },
        { "name": "state", "description": { "en_US": "State", "uk_UA": "Державна" } },
        // { "name": "unknown", "description": { "en_US": "Unknown", "uk_UA": "Невідомо" } }
    ]

    useEffect(() => {
        updateData({
                ownershipType: ownershipType,
                propertyOwner: propertyOwner,
                sellingEntity: sellingEntity,
                //currentTenant: currentTenant,
                currentTenants: currentTenant === null || currentTenant?.address === null ? null : [currentTenant],
                governer: governer
            })
    }, [ ownershipType, propertyOwner, sellingEntity, currentTenant, governer]);

    function updateObject() {
        if (token !== null) {
           const relatedOrganizations = JSON.stringify({relatedOrganizations: {
                ownershipType: ownershipType,
                propertyOwner: propertyOwner,
                sellingEntity: sellingEntity,
                //currentTenant: currentTenant,
                currentTenants: currentTenant === null || currentTenant?.address === null ? null : [currentTenant],
                governer: governer
            }});
            // let urlUpdate = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/registry_object/${data.id}/relatedOrganizations`;
            // let redirect = `/create/${data.id}`;
            const getUrl =( id )=> {
                if(isDraft) return myDraftObject(id)
                return myObjectPrivate(id)
            }
            dispatch( relatedOrganizationsUpdate( getUrl(object.id), relatedOrganizations, token.access_token) );
            // dispatch( objectGet( getUrl(object.id), token.access_token) );
            setUpdate( false )
            // navigate(`/lease/create/${object.id}`)
        } else {
            alert('Авторизуйтесь');
        }
    }

    return(
    <> {
        pageCreate === 'create' ?
        <div className={styles.wrapper}>
            <div className={styles.organizationsWrap}>
                <div className={styles.title}>Організації, задіяні в керуванні об`єктом</div>
                <InputSelect 
                    label="Тип Власності"
                    // error={errorOwnershipType}
                    value={ ownershipType }
                    onChange={ setOwnershipType }
                    options={ objectOwnershipTypeList }
                    dictionary={ true }
                    reqiured
                />
            </div>
            <OrganizationItem data={ propertyOwner } type={ 'propertyOwner' } title={ 'Балансоутримувач' } setData={ setPropertyOwner } update={true} />
            <OrganizationItem data={ sellingEntity } type={ 'sellingEntity' } title={ 'Орендодавець' } setData={ setSellingEntity } update={true} />
            <OrganizationItem data={ currentTenant } type={ 'currentTenant' } title={ 'Чинний орендар' } setData={ setCurrentTenant } update={true} />
            <OrganizationItem data={ governer } type={ 'governer' } title={ 'Уповноважений орган управління' } setData={ setGoverner } update={true} />
        </div>
    : 
    // !!data ?
        <InfoBlockСollapser 
            // value={data.statusesDecisions}
            blockTitle='Організації, задіяні в керуванні об`єктом'
            collapsed={false}
            content={
                <div className={styles.updateWrapper}>
                    {
                    update ? 
                        <div className={styles.ownershipTypeWrap}>
                            <InputSelect value={ownershipType} label="Тип Власності *" onChange={setOwnershipType} options={objectOwnershipTypeList} dictionary={true} text="Оберіть" />
                            <div style={{display: 'flex', gap: '8px'}}>
                                <Button color={'blue'} height={'30px'} onClick={() => updateObject()}>Зберегти</Button>
                                <Button 
                                    onClick={() => {
                                        // setOwnershipType( prev => (prev)) TODO: Придумати щось, щоб при відміні воно скидало стейт на попередній
                                        // setPropertyOwner( prev => (prev))
                                        // setSellingEntity( prev => (prev))
                                        // setCurrentTenant( prev => (prev))
                                        // setGoverner( prev => (prev))
                                        setUpdate(!update) 
                                    }} color={'gray'} height={'30px'}>X</Button>
                            </div>
                        </div>
                    // : ownershipType !== null ?
                    :
                        <div className={styles.ownershipTypeWrap}>
                            <FullInfoBlock 
                                title='Тип Власності'
                                data={ getTranslate( ownershipType, objectOwnershipTypeList ) }
                            />
                            <Button color={'white'} height={'30px'} 
                                onClick={() =>{
                                    // setUpdateValues();
                                    setUpdate(true);
                                }}
                            >
                                {/* {data.statusesDecisions.listTypesList} */}
                                Редагувати
                            </Button>
                        </div>
                    // : null }
                    }
                    <OrganizationItem data={ propertyOwner } type={ 'propertyOwner' } title={ 'Балансоутримувач' } setData={ setPropertyOwner } update={update} />
                    <OrganizationItem data={ sellingEntity } type={ 'sellingEntity' } title={ 'Орендодавець' } setData={ setSellingEntity } update={update} />
                    <OrganizationItem data={ currentTenant } type={ 'currentTenant' } title={ 'Чинний орендар' } setData={ setCurrentTenant } update={update} />
                    <OrganizationItem data={ governer } type={ 'governer' } title={ 'Уповноважений орган управління' } setData={ setGoverner } update={update} />
                    {/* {showPopupChooseOrganization()}
                    {showPopupAddOrganization()} */}
                </div>
            }
        />
    // : <Loader />
    }
    </>
    );


}

export default ManagingOrganizationsNew;