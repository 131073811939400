import React from 'react';
import { useLocation } from 'react-router-dom';
import styles from '../elems.module.css';
import ExecusionContractItem from './contractItem';
import ExecusionContractItemEdit from './contractItemEdit';
// import PopDownBlock from '../../../../elements/popdownBlock/popDownBlock';

const ExecusionEditContracts = ({ data }) => {
    
    const { state } = useLocation()

    return (
        <div className={styles.blockWrap}>
            { state?.edit === true && !!state?.data ?
                <ExecusionContractItemEdit data={ state?.data } />
                :
                <div className={styles.infoBlockOrgContent}>
                    { !!data?.contracts && data.contracts.length > 0 &&
                        data.contracts.map( (item, count) => <ExecusionContractItem item={item} key={item.id} count={count} />)
                    }
                </div>
            }
        </div>
    )
}

export default ExecusionEditContracts