import React from 'react';
import styles from './badges.module.css';

const Bage = ({ description }) => {
    return (
        <div
            className={styles.badgeElem}
            title={description}
        >
            <div className={styles.badgeElem_title}>
                {description}
            </div>
        </div>
    )
}

export default Bage