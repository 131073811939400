import styles from './myRedemptions.module.css';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import AnnouncementTabItem from './myRedemptionTabItem';
import Loader from '../../../loader/loader';
import { objectsGetMy, objectGetTypes } from '../../../../redux/actions/object/object';
import { setPageCreate, objectClear } from '../../../../redux/actions/object/object';
import PaginatorSimple from '../../paginator/paginatorSimple';
import { checkProp } from '../../../../utils/check';
import { getMyRedemptionsList } from '../../../../utils/endPoints';
import { announcementGetMy } from '../../../../redux/asset/assetActions';
// import Paginator from './paginator/paginator';

const MyAssetsHeader = () => {
    return (
        <>
            <div className={styles.myObjectsTable}>
                <div className={styles.tabHead}>
                    <div className={styles.thName}>Назва</div>
                    <div className={styles.thStat}>Ідентифікатор об'єкта</div>
                    <div className={styles.thStatus}>Статус</div>
                    <div className={styles.thRent}>Характеристики</div>
                    <div className={styles.thActions}>Дії</div>
                </div>
            </div>
        </>
    )
}

const MyRedemptions = () => {
    const dispatch = useDispatch(); 
    const token = useSelector(state => state.start.token);
    const userProfile = useSelector(state => state.start.userProfiles);
    const myAnnouncementsList = useSelector(state => state.asset.announcementsMy);

    const navigate = useNavigate()

    useEffect(() => {
            dispatch( announcementGetMy( getMyRedemptionsList , token.access_token) );

        return () => {
            dispatch({type: 'ANNOUNCEMENTS_GET_MY', payload: null})
        }
    }, []);

    function setObjectsList(list) {
        let objectsList;
        if (list !== null && typeof list !== 'undefined') {
            if (list.length > 0) {
                objectsList = list.map( item => {
                    return <AnnouncementTabItem 
                                key={ item.publishedId ? item.publishedId : item.draftId }
                                id={ item.publishedId ? item.publishedId : item.draftId }
                                data={ item.publishedId ? item.publishedObject : item.draftObject } 
                            />
            });
            } else {
                objectsList = <div className={styles.tableEmpty}>У Вас немає жодного пріоритетного викупу.</div>
            }
        } else {
            objectsList = <Loader />
        }
        return objectsList
    }

    // if( !!userProfile && userProfile.canCreateAnnouncements === false ) return navigate("/asset/assets_list")

    return (
        <div className={styles.wrapper}>
            <div className={styles.titleWrapper}>
                <div className={styles.title}>Мої пріоритетні викупи</div>
                {/* { checkProp(userProfile, 'canCreateAnnouncements') && !!userProfile.canCreateAnnouncements ? 
                    <Link
                        to={'/asset/create'}
                        className={styles.buttonCreate}
                        onClick={() => {
                                // dispatch(setPageCreate('create'))
                                dispatch(objectClear())
                            }
                        }
                    >
                        Створити об’єкт
                    </Link> : null 
                } */}
            </div>
            <div className={styles.tableContent}>
                <MyAssetsHeader />
                { setObjectsList( myAnnouncementsList ) }
                <PaginatorSimple
                    data={ myAnnouncementsList }
                    url={ getMyRedemptionsList }
                    get={ announcementGetMy }
                />
            </div>
        </div>
    )
}

export default MyRedemptions