import React from 'react';
import styles from './logo.module.css';
import {useSelector} from 'react-redux';

import ImgLogo from './ukraine.png';

const Logo = () => {

    const logo = useSelector(state => state.start.token);

    if(logo <= 1) {
        return (
            <div className={styles.wrap} >
                <div className={styles.logo_wrap}>
                    <div>
                        <img src={ImgLogo} alt=""/>
                    </div>
                    <div>
                        <div className={styles.titletext}>DTO</div>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className={styles.wrap}>
                <a className={styles.logoA} href={window.location.origin}>
                    <div className={styles.logo_wrap}>
                        <div>
                            <img src={ImgLogo} alt=""/>
                        </div>
                        <div>
                            <div className={styles.titletext}>DTO</div>
                        </div>
                    </div>
                </a>
            </div>
        )
    }
}


export default Logo;