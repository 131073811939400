import React,{useState, useEffect} from 'react';
import styles from './leaseItems.module.css';
import LeaseObjectItemCreate from './leaseItemItem/leaseObjectItem';
import EditLeaseItem from './leaseItemItem/editLeaseItem/editLeaseItem';
import Button from '../../../../../elements/buttons/button';

const LeaseItemsCreate =({ data })=> {

    const [edit, setEdit] = useState({ state: false, data: null })

    if ( data.registryObjectItems.length === 0 || (edit.state && !edit.data )) {
        return (
            <div className={styles.blockWrap}>
                <div className={styles.infoBlock}>
                    <div className={styles.infoBlockTitle}>Додайте об'єкт </div>
                    <EditLeaseItem 
                        data={ edit.data }
                        close={ ()=> {
                            setEdit( prev => ({ ...prev, state: false, data: null }))
                            window.scrollTo(0, 0)
                        }}
                    />
                </div>
            </div>
        )
    }

    if ( edit.state && !!edit.data ) return (
        <div className={styles.blockWrap}>
            <div className={styles.infoBlock}>
                <div className={styles.infoBlockTitle}>Редагування об'єкту </div>
                <EditLeaseItem 
                    data={ edit.data }
                    close={ ()=> {
                        setEdit( prev => ({ ...prev, state: false, data: null }))
                        window.scrollTo(0, 0)
                    }}
                />
            </div>
        </div>
    )

    return (
        <div className={styles.blockWrap}>
            <div className={styles.infoBlock}>
                <div className={styles.itemsListContent}>
                    { data.registryObjectItems.map(
                        item => <LeaseObjectItemCreate 
                                    item={ item }
                                    key={ item.id }
                                    edit={ ()=> {
                                        setEdit( prev => ({ ...prev, data: item, state: true }))
                                        // setEdit( prev => ({ ...prev, state: true }))
                                    }}
                                />
                    )}
                    <Button onClick={ ()=> {
                        setEdit( prev => ({ ...prev, data: null, state: true }))
                        // setEdit( prev => ({ ...prev, state: true }))
                        window.scrollTo(0, 0)
                        }}
                    >
                        Додати
                    </Button>
                </div>
            </div>
        </div>
    )

}

export default LeaseItemsCreate