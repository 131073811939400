import React, { useState, useEffect} from 'react';
import styles from './elems.module.css';
import {useSelector, useDispatch} from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { isMongo, checkProp, isDraftObject, getCurrency, getAddedTax } from '../../../../../utils/check';
import Input from '../../../../inputs/input';
import InputAmount from '../../../../inputs/inputAmount';
import Checkbox from '../../../../inputs/checkBox';
import InputSelect from '../../../../inputs/inputSelect';
import InputTextArea from '../../../../inputs/inputTextArea';
// import AssetOrganizationItem from '../../view/elems/assetOrganizationItem';
import Button from '../../../../elements/buttons/button';
// import AssetHolder from './organizations/orgAssetItem/orgAssetItem';
import { executionUpdate } from '../../../../../redux/asset/assetActions';
import { urlGetMyExecution, urlPostEditContractExecution } from '../../../../../utils/endPoints';
import Loader from '../../../../loader/loader';
import InputDate from '../../../../inputs/inputDate';
// import RelatedEntities from '../../../announcement/view/elems/relatedEntities';
import ExecutionRelatedEntities from './relatedEntities';
//import ExecutionCanceletionPopup from './cancelationPopUp/canceletionPopup';
import ExecutionCanceletionPopup from './cancelationPopUp/canceletionPopupContracts';
import ExecutionOrderPopup from './orderPopup/orderPopup';
import ExecutionOrderEdit from './order/order';
import ExecutionCompletion from './complation/executionCompletion';
import UniPopUp from '../../../../popups/uniPopUp/uniPopUp';
import { axiosPostAPI } from '../../../../../redux/actions/crud';
// import ExecusionEditContracts from './contractsEdit';

const ExecusionGeneralEdit = ( { data, entitiesArr } ) => {
    const userProfiles = useSelector(state => state.start.userProfiles);
    const token = useSelector(state => state.start.token);
    // const assetItem = useSelector(state => state.asset.assetItem)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    // const assetObject = location.state

    const [loader, setLoader] = useState( false )

    const [title, setTitle] = useState( !!data?.title ? isMongo(data.title) : '' )
    const [ contracts, setContracts ] = useState( !!data?.contracts ? data.contracts : [] )
    // const [ order, setOrder ] = useState( !!data?.order ? data.order : null )
    // const [ completion, setCompletion ] = useState( !!data?.completion ? data.completion : null )
    // const [ cancellations, setCancellations ] = useState( !!data?.cancellations ?  data.cancellations : [] )
    const [ relatedEntities ] = useState( !!data?.relatedEntities ? data.relatedEntities : [] )
    const [ popUp, setPopUp ] = useState( false )

    // useEffect(() => {
    //     if ( !!data && data.staus === 'deleted' ) navigate(`/asset/execution/${data.executionId}`)
    // }, [])

    function createObjectData(){
            let body = {
                title: { uk_UA: title },
                // relatedEntities: relatedEntities,
                // contracts: contracts,
                // order: null,
                // completion: null,
                // cancellations: [],
            }
            dispatch( executionUpdate( urlGetMyExecution(data.id) , JSON.stringify(body), token.access_token))
            setTimeout( ()=> setLoader(false), 300)
            setLoader(true)
            // if ( data.status === 'pending' ) navigate(`/asset/my_executions`)
            window.scrollTo(0, 0)
    }

    if ( loader ) return <Loader />
    // if ( data.status !== 'pending' && !location?.state ) return navigate(`/asset/my_announcements`)

    function postEditContract(contract) {
        axiosPostAPI({
            url: urlPostEditContractExecution(data.id),
            dispatch: dispatch,
            // set: ( data )=> setEditContract( prev => ({...prev, data: data })),
            // body: resBody,
            token: token.access_token,
            success: ()=> dispatch( {type: 'UNIVERSAL_ERROR', payload: `Пройшло)` })
        })
        
    }

    return (
        <div className={styles.blockWrap}>
            <div className={styles.infoBlock}>
                <Input 
                    label='Назва' 
                    value={title}
                    onChange={ e => setTitle(e)}
                    disabled={ ( data.status === 'sold' || data.status === 'deleted' ) ? true : false }
                    reqiured
                />
                { !( data.status === 'sold' || data.status === 'deleted' ) &&
                    <div style={{display: 'flex', flexFlow: 'row-reverse', width: '100%'}}>
                        <Button
                        onClick={ createObjectData }
                        >
                            Зберегти
                        </Button>
                    </div>
                }
            </div>
            <div className={styles.infoBlock}>
                <div className={styles.infoBlockTitle}>Пов'язані сутності</div>
                <ExecutionRelatedEntities entitiesArr={ entitiesArr } />
            </div>
            { !!data?.order?.id &&
                <div className={styles.infoBlock}>
                        <div className={styles.infoBlockTitle}>Наказ про приватизацію</div>
                        <ExecutionOrderEdit data={ data.order } />
                </div>
            }
            { ( !!data?.order?.id && data.status === 'active' ) &&
                <div className={styles.infoBlock}>
                        <div className={styles.infoBlockTitle}>Завершення виконання умов приватизації</div>
                        {/* <ExecutionOrderEdit data={data.order} /> */}
                        <ExecutionCompletion data={data.completion} />
                </div>
            }
            { !( data.status === 'sold' || data.status === 'deleted' ) &&
                <div className={`${styles.infoBlock} ${styles.blueBorder}`}>
                    <div className={styles.infoBlockTitle}>Можливі дії</div>
                    <div className={styles.buttonsBlock}>
                        {
                            ( data.status === 'pending' && !data?.order?.id ) &&
                            <Button
                                onClick={ ()=> setPopUp( 'order' )}
                            >
                                Публікація наказу про приватизацію
                            </Button>
                        }
                        {/*
                            ( data.status === 'active' ) &&
                            <Button
                                    color={'blue'}
                                    onClick={ ()=> setPopUp('editContract') }
                                    // onClick={() => {
                                    //     navigate('contracts', {
                                    //         state: {
                                    //             edit: true,
                                    //             data: data.contracts[0]
                                    //         }
                                    //     })
                                    //     window.scrollTo(0, 0)
                                    // }}
                            >
                                Публікація інформації про зміни в договорі
                            </Button>
                        */}
                        {
                            ( data.status === 'pending' || data.status === 'active') &&
                            <Button
                                color={'gray'}
                                onClick={ ()=> setPopUp( 'cancel' ) }
                            >
                                Скасування продажу
                            </Button>
                        }
                    </div>
                </div>
            }
            { popUp === 'order' &&
                <ExecutionOrderPopup object={ data } close={ ()=> setPopUp( false )} />
            }
            { popUp === 'cancel' &&
                <ExecutionCanceletionPopup object={ data } close={ ()=> setPopUp( false )} />
            }
            { popUp === 'editContract' &&
                <UniPopUp
                    data={'Ви дійсно хочете опублікувати інформацію про зміни в договорі?'}
                action={ async () => {
                        await postEditContract()
                        setPopUp( false )
                    }
                } type={2}
                    close={ ()=> setPopUp( false ) }
                />
            }
        </div>
    )
}

export default ExecusionGeneralEdit