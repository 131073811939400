import React,{useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getClassification } from '../../../../../../../../redux/asset/assetActions';
import { itemClassificatorMask } from '../../../../../../../../utils/endPoints';
import InputSelect from '../../../../../../../inputs/inputSelect';
import Loader from '../../../../../../../loader/loader';
import { isMongo } from '../../../../../../../../utils/check';

const Classificator =({ data, setData })=> {
    const dispatch = useDispatch()
    // const token = useSelector(state => state.start.token);
    const objectData = useSelector(state => state.start.objectGet);
    const classificationCAV = useSelector( state => state.asset.classificationCAV );

    const [ classification, setClassification ] = useState( data?.id ? data.id : null )
    const [ mainClassif, setMainClassif ] = useState( !!data?.id ? `${data?.id[0]}${data?.id[1]}` : null )

    useEffect(() => {
        if ( !!data ) setClassification( data.id )
        return () => {
            dispatch( {payload: null, type: 'CLASSIFICATION_CAV'})
          }
    }, [data])
    

    useEffect(() => {
      if ( !!mainClassif ){
        dispatch( getClassification( itemClassificatorMask( mainClassif ) ))
      }
    }, [ mainClassif ])

    useEffect(() => {
        if ( !!classification && !!classificationCAV ){
            // debugger
            let tmp = classificationCAV.find( i => i.id === classification )
            setData( {
                id: tmp.id,
                description: { uk_UA:isMongo( tmp.description )},
                scheme: tmp.scheme
            } )
        }
    }, [ classification, classificationCAV ])
    

    function classificationIdOption( main ){
        let collatore = new Intl.Collator('uk-UA')
        if( classificationCAV !== null  && main === '34'){
            let tmp = classificationCAV
            // let regEx = ''
            tmp.sort(
                (a, b) => collatore.compare(a.classificationId, b.classificationId)
            )
            // if ( !!main && main === '34' ){
            //     regEx = new RegExp(/^34[1|4|5|6|7]00000.*$/)
            // }
            // if ( !main && mainClassif === '34' ){
            //     regEx = new RegExp(/^34[2|3|8|9|0].*$/)
            // }
            return tmp
            // .filter( i => i.id.match(regEx) )
            .filter(
                (i) => {
                    return (
                            i.id === '34000000-7'
                            || i.id === '34100000-8'
                            || i.id === '34400000-1'
                            || i.id === '34500000-2'
                            || i.id === '34600000-3'
                            || i.id === '34700000-4'
                    )
                }
            )
            .map(
                (i) => {
                    return { key: i.id, value: i.id, name: `${ i.scheme + ' ' + i.id + ' ' + i.description.uk_UA }`}
                }
            )
        }else{
            let tmp = classificationCAV
            tmp.sort(
                (a, b) => collatore.compare(a.classificationId, b.classificationId)
            )
            return tmp
            .map(
                (i) => {
                    return { key: i.id, value: i.id, name: `${ i.scheme + ' ' + i.id + ' ' + i.description.uk_UA }`}
                }
            )
        }
    }
    
    function setSUBClassificator( mainClassif ){
        if( classificationCAV !== null ){ 
            return (<>
                    <InputSelect
                        label="Вкладені коди основного класифікатора"
                        value={ classification }
                        // infoBlock='Виберіть вкладений код основного класифікатора'
                        onChange={( event ) => { 
                            setClassification( event )
                            // setData( event )
                        }}
                        options={ classificationIdOption( mainClassif ) }
                        finder={ true }
                        reqiured
                        // cav={true}
                    />
                </>
            )
        }else{
            if( mainClassif !== 'null'){
                return (
                    <Loader small/>
                            // Зачекайте. Вкладені коди основного класифікатора завантажуються
                )
            }
        }
    }

    return (
        <>
            <InputSelect
                    label="Основний класифікатор"
                    value={ mainClassif }
                    // infoBlock='оберіть основний класифікатор лоту'
                    onChange={ event => {
                        setMainClassif( event )
                        setClassification( null )
                        setData( null )
                    }}
                    options={ mainCAV( mainClassif ) }
                    reqiured
                />
                { !!mainClassif && setSUBClassificator( mainClassif ) }
        </>
    )
}



const mainCAV =( main )=> {
    switch( main ){
        // case '04': return [{ key:'04000000-8', value:'04', name: 'CAV 04 Нерухоме майно'}]
        // case '05': return [{ key:'05000000-5', value:'05', name: 'CAV 05 Цілісний майновий комплекс або майновий комплекс - комплекс активів підприємства'}]
        // case '34': return [{ key:'34000000-7', value:'34', name: 'CAV 34 Транспортне обладнання та допоміжне приладдя до нього'}]
        default: return [
            { key:'04000000-8', value:'04', name: 'CAV 04 Нерухоме майно'},
            { key:'05000000-5', value:'05', name: 'CAV 05 Цілісний майновий комплекс або майновий комплекс - комплекс активів підприємства'},
            { key:'34000000-7', value:'34', name: 'CAV 34 Транспортне обладнання та допоміжне приладдя до нього'},
            { key:'16000000-5', value:'16', name: 'CAV 16 Сільськогосподарська техніка'},
            { key:'18000000-9', value:'18', name: 'CAV 18 Одяг, взуття, сумки та аксесуари'},
            { key:'19000000-6', value:'19', name: 'CAV 19 Шкіряні та текстильні, пластмасові та гумові матеріали'},
            { key:'22000000-0', value:'22', name: 'CAV 22 Друкована та супутня продукція'},
            { key:'30000000-9', value:'30', name: 'CAV 30 Офісна та комп’ютерна техніка, устаткування та приладдя, крім меблів та пакетів програмного забезпечення'},
            { key:'31000000-6', value:'31', name: 'CAV 31 Електротехнічне устаткування, апаратура, обладнання та матеріали; освітлювальне устаткування'},
            { key:'32000000-3', value:'32', name: 'CAV 32 Радіо-, телевізійна, комунікаційна, телекомунікаційна та супутня апаратура й обладнання'},
            { key:'33000000-0', value:'33', name: 'CAV 33 Медичне обладнання, фармацевтична продукція та засоби особистої гігієни'},
            { key:'34000000-7', value:'34', name: 'CAV 34 Транспортне обладнання та допоміжне приладдя до нього'},
            { key:'35000000-4', value:'35', name: 'CAV 35 Охоронне, протипожежне, поліцейське та оборонне обладнання'},
            { key:'37000000-8', value:'37', name: 'CAV 37 Музичні інструменти, спортивні товари, ігри, іграшки, ремісничі, художні матеріали та приладдя'},
            { key:'38000000-5', value:'38', name: 'CAV 38 Лабораторне, оптичне та високоточне обладнання (крім лінз)'},
            { key:'39000000-2', value:'39', name: 'CAV 39 Меблі (у тому числі офісні меблі), меблево-декоративні вироби, побутова техніка (крім освітлювального обладнання) та засоби для чищення'},
            { key:'42000000-6', value:'42', name: 'CAV 42 Промислова техніка'},
            { key:'43000000-3', value:'43', name: 'CAV 43 Гірничодобувне та будівельне обладнання'},
            { key:'44000000-0', value:'44', name: 'CAV 44 Конструкції та конструкційні матеріали; допоміжна будівельна продукція (крім електроапаратури)'},
            { key:'48000000-8', value:'48', name: 'CAV 48 Пакети програмного забезпечення та інформаційні системи'},
        ]
    }
}

export default Classificator