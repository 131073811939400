import React, { useState, useEffect } from 'react';
import styles from './createObject.module.css';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import GeneralInfo from './generalInfo/generalInfo';
import ValuesCharacteristics from './valuesCharacteristics/valuesCharacteristics';
import LeaseRules from './leaseRules/leaseRules';
import StatusesDecisions from './statusesDecisions/statusesDecisions';
// import ManagingOrganizations from './managingOrganizations/managingOrganizations';
import { objectCreate, setPageCreate } from '../../../../../../redux/actions/object/object';
import { checkProp } from '../../../../../../utils/check';
import { myDraftObjectPost } from '../../../../../../utils/endPoints';
import ManagingOrganizationsNew from './managingOrganizations/managingOrganizationNew';
// import { getMyLeaseContracts } from '../../../../../../utils/contracts/contractsUtils';

const CreateObject = ({ data, isDraft }) => {

    const dispatch = useDispatch();
    const token = useSelector(state => state.start.token);
    // const popupLocation = useSelector(state => state.start.popupLocation);
    const pageCreate = useSelector(state => state.start.objectPage);
    const myLeaseContracts = useSelector(state => state.start.myLeaseContracts);
    // const data = useSelector(state => state.start.objectGet);

    const [generalInfo, setGeneralInfo] = useState({
        title: !!data?.title ? data.title : '',
        // type: data.type,
        registryDecision: !!data?.registryDecision ? data.registryDecision : ''
    });
    const [valuesCharacteristics, setValuesCharacteristics] = useState( !!data?.valuesCharacteristics ? data.valuesCharacteristics : null );
    const [leaseRules, setLeaseRules] = useState( !!data?.leaseRules ? data.leaseRules : null );
    const [relatedOrganizations, setRelatedOrganizations] = useState( !!data?.relatedOrganizations ? data.relatedOrganizations : null );
    const [statusesDecisions, setStatusesDecisions] = useState( !!data?.statusesDecisions ? data.statusesDecisions : null );

    function createRegistryObject() {
        if (token !== null) {
            let redirect = `/lease/create/`;
            let data = {};
            data = JSON.stringify({
                // type: generalInfo.type,
                title: generalInfo.title,
                registryDecision: generalInfo.registryDecision,
                statusesDecisions: statusesDecisions,
                leaseRules: leaseRules,
                valuesCharacteristics: valuesCharacteristics,
                relatedOrganizations: relatedOrganizations
            });
            // let secondAction = {
            //     goToPage: function (a) { window.location.href = `/create/${a}`; }
            // }
            dispatch( objectCreate( myDraftObjectPost, data, token.access_token, redirect ) );
        } else {
            alert('Авторизуйтесь');
        }
    }

    return (
        <div className={styles.createObject}>
            <GeneralInfo updateData={setGeneralInfo} data={generalInfo} isDraft={isDraft} >
                {/* { !!data?.id &&  */}
                
                    <>
                        <ValuesCharacteristics updateData={setValuesCharacteristics} data={valuesCharacteristics} isDraft={isDraft} />
                        <LeaseRules updateData={ setLeaseRules } data={ leaseRules } isDraft={ isDraft } />
                        <ManagingOrganizationsNew updateData={setRelatedOrganizations} data={relatedOrganizations} isDraft={isDraft} />
                        <StatusesDecisions updateData={setStatusesDecisions} data={statusesDecisions} isDraft={isDraft} />
                    </>
                {/* } */}
                <div className={styles.buttonWrapper}>
                    {
                        pageCreate === 'create' ? <>
                            <Link to={`/lease/my_objects`} className={styles.buttonCancel}>Скасувати</Link>
                            <button
                                onClick={() => createRegistryObject() }
                                className={styles.buttonSave}>
                                Зберегти та продовжити
                            </button>
                        </> : <>
                            <Link to={`/lease/my_objects`} className={styles.buttonCancel}>До списку об’єктів</Link>
                            { data !== null ?
                                <Link to={`/lease/create/${data.id}/objectItems`} className={styles.buttonNext}
                                    onClick={() => dispatch(setPageCreate('objectItems'))}
                                >Перейти до об’єктів</Link>
                                : ''
                            }
                        </>
                    }
                </div>
            </GeneralInfo>
        </div>
    );
}

export default CreateObject;