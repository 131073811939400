import React, { useState, useEffect, useRef } from 'react'
import styles from '../search.module.css';
import NavPart from "../../../../find/navPart/navPart"
import BadgesSearch from './badjes';
import LeaseObjectStatusSearch from './statusSearch';
import LeaseObjectPropertyTypeSearch from './propertyTypeSearch';
import LeaseObjectAdvancedSearch from './advancedSearch';
import LeaseObjectListTypeSearch from './listTypeSearch';
import SearchSortFilter from '../../../regeestry/elems/sortFilter';
import KeywordSearch from '../elems/keyword';

const LeaseObjectSearch =({ setParams, params })=> {

    const emptySearch = {
        limit: 10,
        offset: 0,
        order: 'DESC',
        keyword: null,
        registryObjectId: null,
        title: null,
        listType: [],
        type: [],
        status: [],
        classificationId: null,
        classificationSchema: null,
        sellingEntity: null,
        description: null,
        propertyOwner: null,
        datePublishedFrom: null,
        datePublishedTo: null,
    }

   useEffect(() => {
    setParams( emptySearch )
   }, [ ])

    return (
        <div className={styles.search}>
            <NavPart />
            <div className={styles.bottomSection}>
                <KeywordSearch setParams={ setParams } params={ params } />
                <div className={styles.searchFilterBlock}>
                    <div className={styles.searchFilterBlock_icon}>
                        <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M11.5564 8.8105V13.3324C11.5564 13.669 11.3662 13.9768 11.0651 14.1274L7.50978 15.905C6.9188 16.2005 6.22346 15.7708 6.22346 15.11V8.8105L0.202598 1.45166C-0.272227 0.871322 0.140674 0 0.890511 0H16.8894C17.6392 0 18.0521 0.871322 17.5773 1.45166L11.5564 8.8105ZM9.77876 12.7831V8.49322C9.77876 8.28804 9.84975 8.08918 9.97968 7.93038L15.0137 1.77765H2.76615L7.8002 7.93038C7.93013 8.08918 8.00111 8.28804 8.00111 8.49322V13.6719L9.77876 12.7831Z" fill="#696974"/>
                        </svg>
                    </div>
                    <LeaseObjectStatusSearch setParams={ setParams } arr={ params } />
                    <LeaseObjectPropertyTypeSearch setParams={ setParams } arr={ params } />
                    <LeaseObjectListTypeSearch setParams={ setParams } arr={ params } />
                    <LeaseObjectAdvancedSearch setParams={ setParams } params={ params } />
                </div>
                <BadgesSearch params={ params } setParams={ setParams } />
                <div className={styles.setupFilterWrap}>
                    <div
                        className={styles.filterReset}
                        onClick={ ()=> setParams( emptySearch ) }
                    >
                        Скинути все
                    </div>
                    <div className={styles.filterSort}>
                        <SearchSortFilter setParams={ setParams } params={ params } />
                    </div>
                    {/* <div className={styles.filterUnActive}>Зберегти налаштування пошуку</div>
                    <div className={styles.filterUnActive}>Отримувати оновлення на email</div> */}
                </div>
            </div>
        </div>
    )
}

export default LeaseObjectSearch