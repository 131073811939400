import React, {useState, useEffect} from 'react'
import styles from './addDocPopup.module.css'

import { useSelector, useDispatch } from 'react-redux';
import { uploadDocumentUrl } from '../../../../utils/endPoints';

import ESignCheckBlock from '../ecp/ecpCheckBlock';
import SelectDocType from './docTypeSelect/docTypeSelect'

import { checkProp, isMongo } from '../../../../utils/check'
import Loader from '../../../loader/loader';
import Input from '../../../inputs/input';
import InputTextArea from '../../../inputs/inputTextArea';
import UniPopUp from '../../../popups/uniPopUp/uniPopUp';

const AddDocPopUp = ({ data, docProps, close, contractId }) => {
    const token = useSelector( state => state.start.token )
    const dispatch = useDispatch()

    const [dropArea, setDropArea] = useState({ text: 'Перетягніть сюди, або додайте файли документів', style: styles.dropZone })
    const [api, setApi] = useState({ error: null, loading: false })

    const [docData, setDocData] = useState(
        {   docType: !!data?.documentType ? data.documentType : null,
            docTitle: !!data?.title ? isMongo(data.title) : '',
            docIndex: !!data?.index ? data.index : '',
            docDesription: !!data?.description ? isMongo(data.description) : '',
            upFile: null
        })

    const [ecpView, setEcpView] = useState(false)

    useEffect(() => {
        document.body.style.overflow = 'hidden'
        document.body.style.paddingRight = '5px'
        return () => {
            document.body.style.overflow = 'auto'
            document.body.style.paddingRight = '0'
        }
    }, [])

    function dragStartHandler(e) {
        e.preventDefault()
        setDropArea({text: 'Відпустіть мишу для завантаження файлу', style: styles.dropZoneActive })
    }

    function dragLeaveHandler(e) {
        e.preventDefault()
        setDropArea({text: 'Додайте файл документу', style: styles.dropZone })
    }
    
    function onDporHandler(e) {
        e.preventDefault()
        setDocData( prev => ({...prev, upFile: e.dataTransfer.files[0]}))
        // setUpFile(e.dataTransfer.files[0])
        setDropArea({ style: styles.dropZoneActive })
    }

    let dataFilesAtribute = {}
        dataFilesAtribute.actionNo = close

    if( docData.upFile !== null ){
        dataFilesAtribute.fileName = docData.docTitle
        dataFilesAtribute.fileType = docData.docType
        dataFilesAtribute.fileDiscription = docData.docDesription
        dataFilesAtribute.fileFile = docData.upFile
        dataFilesAtribute.uploadDocumentUrl = uploadDocumentUrl
        dataFilesAtribute.uploadUrlNew = !!docProps?.url?.post ? docProps.url.post( docProps.object.id ) : null // urlNew
        if ( !!data ){
            if(!!contractId){
                dataFilesAtribute.uploadUrl = !!docProps?.url?.patch ? docProps.url.patchContract( docProps.object.id, contractId, data.id ) : null //url
                dataFilesAtribute.uploadUrlKep = !!docProps?.url?.patch ? docProps.url.patchContract( docProps.object.id, contractId, data.id ) : null //urlKep    
            }else{
                dataFilesAtribute.uploadUrl = !!docProps?.url?.patch ? docProps.url.patch( docProps.object.id, data.id ) : null //url
                dataFilesAtribute.uploadUrlKep = !!docProps?.url?.patch ? docProps.url.patch( docProps.object.id, data.id ) : null //urlKep
            }
        }
        dataFilesAtribute.documentOf = !!data ? data.documentOf : docProps.docOf
        dataFilesAtribute.id = !!data?.id ? data.id : null
        dataFilesAtribute.relatedDocument = !!data?.relatedDocument ? data.relatedDocument : null
        dataFilesAtribute.changeLoader = (payld)=> dispatch( { type: 'CHANGE_LOADER', payload: payld } )
        dataFilesAtribute.token = token
        dataFilesAtribute.viewBtns = { state: ecpView, set: setEcpView }
    }


    function activateECP( docProps ) {
        if ( !docProps?.object?.status ) return null
        if(
            docData.docTitle.trim() !== '' &&
            !!docData.docTitle &&
            docData.docType !== null &&
            docData.upFile !== null
        ){
            return viewECP(dataFilesAtribute, true)
        }
        return viewECP(dataFilesAtribute, false)
    }

    function viewECP(data, status){
        return <ESignCheckBlock 
            dataFilesAtribute={data}
            active={status}
        /> 
    }
    
    function buttonsBlock(props) {
        if(!ecpView){
            return (
                <div className={styles.bottomPart}>
                    <div className={styles.btnCancel}
                        onClick={
                            () => {
                                close()
                            }
                        }
                    >
                        Скасувати
                    </div>
                    { viewButtons( data, contractId ) }
                </div>
            )
        }
    return null
    }

    function viewButtons( data, contractId ) {

        function leaseTypeSet(docData, docProps){
            if ( !!docProps?.docList ) {
                if ( !!docData.docType ) return true
            } else {
                    if ( !docProps?.docList && ['leaseRequest','action'].includes(docProps.docOf) ) return true
                    return false
                }
            }

        if (
            data?.id && docData.docTitle.trim() !== '' && !!docData.docTitle && 
            leaseTypeSet(docData, docProps) && 
            !!docData.upFile 
        ){
            return (
                <div className={styles.btnDone}
                    onClick={() => patchNewDocFiles( docProps.object.id, data, token, close, docData, setApi, docProps, contractId )}
                >
                    Зберегти
                </div>
            )
        } else if (
            data?.id && docData.docTitle.trim() !== '' && !!docData.docTitle && 
            leaseTypeSet(docData, docProps) && 
            !docData.upFile 
        ) {
            return (
                <div className={styles.btnDone}
                    onClick={() => patchNewDocWithoutFiles( docProps.object.id, data, token, close, docData, setApi, docProps, contractId )}
                >
                    Зберегти
                </div>
            )
        } else if(
            docData.docTitle.trim() !== '' &&
            !!docData.docTitle &&
            leaseTypeSet(docData, docProps) &&
            docData.upFile !== null
        ) {
            return (
                <div className={styles.btnDone}
                    onClick={() => {
                        uploadNewDoc( docProps.object.id, token, close, docData, setApi, docProps, contractId )
                    }}
                >
                    Зберегти
                </div>
            )
        }
        return null
    }

    if ( !!api.loading ) return <Loader />
    if ( !!api.error ) return <UniPopUp type={'1'} text={ api.error } close={ ()=> setApi( prev => ({...prev, error: null }) ) } />

    return (
        <div className={styles.wrapMain}>
            <div className={styles.wrap}>
                <div
                    className={styles.close}
                    onClick={ ()=> close() }
                >
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path className={styles.closeSVG} fillRule="evenodd" clipRule="evenodd" d="M8.41421 6.99989L13.364 11.9496C13.7545 12.3402 13.7545 12.9733 13.364 13.3639C12.9734 13.7544 12.3403 13.7544 11.9497 13.3639L7 8.41411L2.05025 13.3639C1.65973 13.7544 1.02656 13.7544 0.636039 13.3639C0.245514 12.9733 0.245515 12.3402 0.636039 11.9496L5.58579 6.99989L0.636039 2.05015C0.245515 1.65962 0.245514 1.02646 0.636039 0.635934C1.02656 0.245409 1.65973 0.245409 2.05025 0.635934L7 5.58568L11.9497 0.635934C12.3403 0.245409 12.9734 0.245409 13.364 0.635934C13.7545 1.02646 13.7545 1.65962 13.364 2.05015L8.41421 6.99989Z" fill="#92929D"/>
                    </svg>
                </div>
                    { !!data?.id ? <div className={styles.popupTitle}>Редагувати документ</div> : <div className={styles.popupTitle}>Додати документ</div> }
                <div className={styles.bidLotCost_input}>
                    { !!docProps?.docList &&
                        <SelectDocType 
                            data={{
                                label: 'Тип документу',
                                target: docData.docType,
                                action: (e)=> setDocData({ ...docData, docType: e }),
                                necessarily: true,
                                docList: docProps.docList,
                                disabled: !!data ? true : false
                            }}
                        />
                    }
                    <Input 
                        value={ docData.docTitle } 
                        onChange={ (e)=> setDocData({ ...docData, docTitle: e }) } 
                        label={ 'Публічна назва документу' } 
                        reqiured={true} 
                    />
                    <InputTextArea 
                        value={ docData.docDesription } 
                        onChange={ (e)=> setDocData({ ...docData, docDesription: e }) } 
                        label={ 'Опис документу' } 
                    />
                    { docData.docType === 'illustration' && 
                            <Input 
                            value={ docData.docIndex } 
                            onChange={ (e)=> setDocData({ ...docData, docIndex: e }) } 
                            label={ 'Порядок сортування зображень' } 
                            minMax={[1, 99]} type={'number'} 
                            />
                    }
                    <div className={dropArea.style}
                        onDragStart={ e => dragStartHandler(e)}
                        onDragLeave={ e => dragLeaveHandler(e)}
                        onDragOver={ e => dragStartHandler(e)}
                        onDrop={ e => onDporHandler(e)}
                    >
                        <div>
                            {docData.upFile ? <>
                                        <div>{`Додано файл:
                                            ${docData.upFile.name.length > 45 ? docData.upFile.name.substr(0, 50) + '...' : docData.upFile.name}`}
                                        </div>
                                        <div>
                                            {`розмір: ${(docData.upFile.size / 1000000).toFixed(2)} Mb, тип: ${docData.upFile.type}`}
                                        </div>
                                    </> : dropArea.text
                            }
                        </div>
                        <label className={styles.btnPin}>Прикріпити
                            <input type="file"
                                name="file"
                                id="inputFile"
                                onInput={(e) => {
                                    e.preventDefault()
                                    setDocData( prev => ({ ...prev, upFile: e.target.files[0] }))
                                    setDropArea({ style: styles.dropZoneActive })
                                }}>
                            </input>
                        </label>
                    </div>
                    { activateECP( docProps ) }
                </div>
                { buttonsBlock( docData ) }
            </div>
        </div>
    )
}

async function uploadNewDoc( assetId, token, close, docData, setApi, docProps ){
    await setApi( prev => ({...prev, loading: true}))
    const formData = new FormData();
    formData.append('documentType', docData.docType)
    formData.append('format', docData.upFile.type )
    let uPFile = docData.upFile
    // uPFile.name = ` ${upFile.name}` //TODO: Пофиксить бл**ский язык файлов
    formData.append('file', uPFile)
    const response = await fetch( uploadDocumentUrl , {
        method: "POST",
        body: formData,
        headers: {
            'Authorization': token.access_token,
        }
    }).catch( error => setApi( prev => ({...prev, error: error })) )
    if( typeof response === 'undefined' || response === 'undefined'){
        setApi( prev => ({...prev, error: 'При збереженні файлу виникла помилка :(' }))
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при збереженні файлу', message: JSON.stringify(json)})
            setApi( prev => ({...prev, error: tmp }))
            await setApi( prev => ({...prev, loading: false }))
        }else{
            let body = {}
            if( !!docData.docTitle.trim() ) body.title = { uk_UA: docData.docTitle.trim() }
            if( !!docData.docDesription.trim() ) body.description = { uk_UA: docData.docDesription.trim() }
            if( !!docData.docType ) body.documentType = docData.docType
            if( !!docData.docIndex.trim() ) body.index = docData.docIndex.trim()
            body.language = 'uk'
            body.documentOf = (!!docData?.docType && (docData?.docType === 'contractAnnexe' || docData?.docType === 'contractSigned'))
                ? docProps.object.hasOwnProperty("contracts")
                    ? 'contract'
                    : docProps.docOf
                : docProps.docOf
            body.token = json.jwt
            body.format = docData.upFile.type
            const url = (!!docData?.docType && (docData?.docType === 'contractAnnexe' || docData?.docType === 'contractSigned'))
                ? docProps.object.hasOwnProperty("contracts")
                    ? docProps.url.postContract(assetId, docProps.object.contracts[0].id)
                    : docProps.url.post(assetId)
                : docProps.url.post(assetId)
            //const response1 = await fetch( docProps.url.post(assetId), {
            const response1 = await fetch( url, {
                method: "POST",
                body: JSON.stringify(body),
                headers: {
                    'Authorization': token.access_token,
                    'Content-Type': 'application/json'
                }
            }).catch(console.error)
            if(response1 === undefined || response1 === 'undefined'){
            }else{
                const json2 = await response1.json()
                if(json2.hasOwnProperty('message') === true){
                    let tmp = JSON.stringify({title: 'Помилка при збереженні документу', message: JSON.stringify(json2)})
                    setApi( prev => ({...prev, error: tmp }))
                    await setApi( prev => ({...prev, loading: false}))
                }else{
                    docProps.dispatch(json2)
                }
            }
        }
    }
    await setApi( prev => ({...prev, loading: false }))
    close()
}

async function patchNewDocWithoutFiles( assetId, data, token, close, docData, setApi, docProps, contractId ){
    await setApi( prev => ({...prev, loading: true}))
    let method = "PATCH"
    let body = {}

    if( !!docData.docTitle.trim() ) body.title = { uk_UA: docData.docTitle.trim() }
    if( !!docData.docDesription.trim() ) body.description = { uk_UA: docData.docDesription.trim() }
    if( !!docData.docType ) body.documentType = docData.docType
    if( !!docData?.docIndex ) body.index = docData.docIndex
    body.language = 'uk'
    body.documentOf = data.documentOf

    const urlContract = !!contractId ? docProps.url.patchContract( assetId, contractId, data.id ) : docProps.url.patch( assetId, data.id )
    //const response = await fetch( docProps.url.patch( assetId, data.id ), {
    const response = await fetch( urlContract, {
        method: method,
        body: JSON.stringify(body),
        headers: {
            'Authorization': token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(typeof response === 'undefined' || response === 'undefined'){
        setApi( prev => ({...prev, error: 'Виникла помилка'}))
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при збереженні документу', message: JSON.stringify(json)})
            setApi( prev => ({...prev, error: tmp}))
            await setApi( prev => ({...prev, loading: false}))
        }else{
            docProps.dispatch(json)
        }
    }
    await close()
    await setApi( prev => ({...prev, loading: false}))
}

async function patchNewDocFiles( assetId, data, token, close, docData, setApi, docProps, contractId ){
    await setApi( prev => ({...prev, loading: true}))
    let method = "PATCH"


    const formData = new FormData();
    formData.append('documentType', docData.docType )
    formData.append('file', docData.upFile )
    formData.append('format', docData.upFile.type )
    const response = await fetch( uploadDocumentUrl, {
        method: "POST",
        body: formData,
        headers: {
            'Authorization': token.access_token,
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при збереженні файлу', message: JSON.stringify(json)})
            setApi( prev => ({...prev, error: tmp }))
            await setApi( prev => ({...prev, loading: false}))
        } else {
            let body = {}
            if( !!docData.docTitle.trim() ) body.title = { uk_UA: docData.docTitle.trim() }
            if( !!docData.docDesription.trim() ) body.description = { uk_UA: docData.docDesription.trim() }
            if( !!docData.docType ) body.documentType = docData.docType
            if( !!docData?.docIndex ) body.index = docData.docIndex
            body.language = 'uk'
            body.documentOf = data.documentOf 
            body.token = json.jwt
            // body.format = docData.upFile.type
            const urlContract = !!contractId ? docProps.url.patchContract( assetId, contractId, data.id ) : docProps.url.patch( assetId, data.id )
            //const response1 = await fetch( docProps.url.patch( assetId, data.id ), {
            const response1 = await fetch( urlContract, {
                method: method,
                body: JSON.stringify(body),
                headers: {
                    'Authorization': token.access_token,
                    'Content-Type': 'application/json'
                }
            }).catch(console.error)
            if(typeof response1 === 'undefined' || response1 === 'undefined'){
            }else{
                const json2 = await response1.json()
                if(json2.hasOwnProperty('message') === true){
                    let tmp = JSON.stringify({title: 'Помилка при збереженні документу', message: JSON.stringify(json2)})
                    setApi( prev => ({...prev, error: tmp }))
                    await setApi( prev => ({...prev, loading: false}))
                }else{
                    docProps.dispatch(json2)
                }
            }
        }
    }
    await setApi( prev => ({...prev, loading: false }))
    close()
}

// Приклад даних:
// const docProps = { 
//     dispatch: ( res )=> dispatch( { payload: res, type: 'OBJECT_GET'}),
//     docOf: 'object',
//     object: data,
//     docList: setAssetDocTypes( assetItem.assetItems, assetItem.status ),
//     url: {
//         patch: isDraft ? myDraftObjectDocument : myObjectDocument,
//         post: isDraft ? myDraftObjectDocumentPost : myObjectDocumentPost,
//         delete: ( id ) => dispatch( assetDocDelete( data.id, id, token.access_token ) )
//     }
// }

export default AddDocPopUp