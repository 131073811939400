import React, {useState, useEffect} from 'react';
import styles from '../search.module.css';
import Bage from './bage';
import { dateTransform } from '../../../../../utils/check';

import { checkProp } from '../../../../../utils/check';
import { getLeaseRequestType } from '../../../../../utils/requests/requestsUtils';

const BadgesSearch =({ params, setParams })=> {

    function bageCombinator( params ){
            let tmpArr = []
            if ( checkProp(params, 'keyword') ){
                let tmp = <Bage title={ params.keyword } key={params.keyword}
                action={ ()=> setParams( prev => ({...prev, keyword: null }))} />
                tmpArr = [...tmpArr, tmp ]
            }
            // if ( checkProp(params, 'type') && params.type.length > 0 ){
            //     params.type.forEach( i => {
            //         let tmp = <Bage title={ `Тип заяви: ${ getLeaseRequestType(i) }` } key={i.type}
            //         action={ ()=> setParams( prev => ({...prev, type: prev.type.filter( e => e !== i ) }))} key={i} 
            //         />
            //         tmpArr = [...tmpArr, tmp ]
            //     })
            // }
            if ( checkProp(params, 'title') ){
                let tmp = <Bage title={ `Назва договору: ${params.title}` } key={params.title}
                action={ ()=> setParams( prev => ({...prev, title: null }))} />
                tmpArr = [...tmpArr, tmp ]
            }
            if ( checkProp(params, 'contractNumber') ){
                let tmp = <Bage title={ `Номер договору: ${params.contractNumber}` } key={params.contractNumber}
                action={ ()=> setParams( prev => ({...prev, contractNumber: null }))} />
                tmpArr = [...tmpArr, tmp ]
            }
            if ( checkProp(params, 'datePublishedFrom') ){
                let tmp = <Bage title={ `Дата публікації, з: ${ dateTransform(params.datePublishedFrom) }` } key={params.datePublishedFrom}
                action={ ()=> setParams( prev => ({...prev, datePublishedFrom: null }))} />
                tmpArr = [...tmpArr, tmp ]
            }
            if ( checkProp(params, 'datePublishedTo') ){
                let tmp = <Bage title={ `Дата публікації, по: ${ dateTransform(params.datePublishedTo) }` } key={params.datePublishedTo}
                action={ ()=> setParams( prev => ({...prev, datePublishedTo: null }))} />
                tmpArr = [...tmpArr, tmp ]
            }
        return tmpArr
    }

    return (
        <div className={styles.badgesWrapLine}>
            { bageCombinator( params ) }
        </div>
    )
}

export default BadgesSearch