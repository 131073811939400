import React, { useEffect, useState } from 'react';
import styles from './addBuyer.module.css';
import { useDispatch, useSelector } from 'react-redux';

import Input from '../../../../../../inputs/input.js'
import InputSelect from '../../../../../../inputs/inputSelect.js'
import InputTextArea from '../../../../../../inputs/inputTextArea';
import InputAmount from '../../../../../../inputs/inputAmount'
import InputDate from '../../../../../../inputs/inputDate'

import { objectGetUaIdentifiersList, objectGet } from '../../../../../../../redux/actions/object/object';
import { checkProp, isMongo } from '../../../../../../../utils/check';
import { getDictionaryByType, getUserOrganizations, urlPatchMyExecutionStatus } from '../../../../../../../utils/endPoints';
import { axiosPatchAPI } from '../../../../../../../redux/actions/crud';
import { getAPI, postAPI, patchAPI, deleteAPI } from "../../../../../../../redux/actions/crud";

const AddBuyer = ( { data, setData, close, index, ...props } ) => {
    const token = useSelector(state => state.start.token);
    const executionItem = useSelector(state => state.asset.executionItem)
    const dispatch = useDispatch()

    const [ contractNumber, setContractNumber ] = useState( !!data?.contractNumber ? data.contractNumber : '' )
    const [ title, setTitle ] = useState( !!data?.title ? isMongo(data.title) : '' )
    const [ description, setDescription ] = useState(!!data?.description ? isMongo(data.description) : '')
    const [ value, setValue ] = useState(!!data?.value ? data.value : { currency: 'UAH', amount: '', valueAddedTaxIncluded: true })
    const [ contractTotalValue, setContractTotalValue ] = useState(!!data?.contractTotalValue ? data.contractTotalValue : { currency: 'UAH', amount: '', valueAddedTaxIncluded: true })
    const [ dateSigned, setDateSigned ] = useState( !!data?.dateSigned ? data.dateSigned : null )
    const [ contractTime, setContractTime ] = useState( !!data?.contractTime ? data.contractTime : { dateFrom: null, dateTill: null } )

    // const dispatch = useDispatch();
    // const token = useSelector(state => state.start.token);
    // const object = useSelector(state => state.start.objectGet);
    // const regionsList = useSelector(state => state.start.regionList);


    // const objectUaIdentifiersList = useSelector(state => state.start.objectUaIdentifiersList);
    // const urlUaIdentifiersList = getDictionaryByType('ua_identifiers');

    // const [keyword, setKeyword] = useState('');

    // const [legalName, setLegalName] = useState( !!data?.identifier?.legalName ? isMongo(data.identifier.legalName ) : null);
    // const [identifier, setIdentifier] = useState( !!data?.identifier?.id ? data.identifier.id : null );
    // const [schemeIdentifier, setSchemeIdentifier] = useState( !!data?.identifier?.scheme ? data.identifier.scheme : null );
    // const [schemeIdentifierLegalName, setSchemeIdentifierLegalName] = useState( !!data?.identifier?.scheme?.legalName ? data.identifier.scheme.legalName : null );
    // const [koatuu, setKoatuu] = useState( data?.address?.addressIdentifier?.identifier ? data.address.addressIdentifier.identifier : null );
    // const [address, setAddress] = useState(checkProp(data, 'address') && checkProp(data.address, 'streetAddress') ? isMongo(data.address.streetAddress)  : null);
    // const [locality, setLocality] = useState(checkProp(data, 'address') && checkProp(data.address, 'locality') ? isMongo(data.address.locality) : null);
    // const [postalCode, setCode] = useState(checkProp(data, 'address') && checkProp(data.address, 'postalCode') ? data.address.postalCode : null);
    // const [region, setRegion] = useState(checkProp(data, 'address') && checkProp(data.address, 'region') ? isMongo(data.address.region) : null);
    // const [fullName, setFullName] = useState(checkProp(data, 'contactPoint') && checkProp(data.contactPoint, 'name') ? isMongo(data.contactPoint.name) : null); 
    // const [email, setEmail] = useState(checkProp(data, 'contactPoint') && checkProp(data.contactPoint, 'email') ? data.contactPoint.email : null);
    // const [phone, setPhone] = useState(checkProp(data, 'contactPoint') && checkProp(data.contactPoint, 'telephone') ? data.contactPoint.telephone : null);
    // const [fax, setFax] = useState(checkProp(data, 'contactPoint') && checkProp(data.contactPoint, 'faxNumber') ? data.contactPoint.faxNumber : null);
    // const [web, setWeb] = useState(checkProp(data, 'contactPoint') && checkProp(data.contactPoint, 'url') ? data.contactPoint.url : null);
    // const [info, setInfo] = useState(checkProp(data, 'representativeInfo') ? data.representativeInfo : null);

    //const [errorLocality, setErrorLocality] = useState('');

    // useEffect(() => {
    //     if(objectUaIdentifiersList === null || objectUaIdentifiersList === undefined) {
    //         dispatch(objectGetUaIdentifiersList(urlUaIdentifiersList));
    //     }
    // }, []);

    // async function createBuyer() {
    //         setErrorLocality('')
    //         if (locality.length < 3) {
    //             setErrorLocality('Значення занадто коротке. Повинно бути рівне 3 символам або більше.');
    //         } else {
    //             const body = {
    //                 // name: { uk_UA: name },
    //                 identifier: {
    //                     id: identifier,
    //                     scheme: schemeIdentifier,
    //                     legalName: { uk_UA: legalName }
    //                 },
    //                 address: {
    //                 //     addressID: {
    //                 //         scheme: 'koatuu',
    //                 //         identifier: koatuu,
    //                 //         // name: { uk_UA: koatuu.description }
    //                 //         name: 'КОАТУУ' 
    //                     // },
    //                     streetAddress: { uk_UA: address },
    //                     locality: { uk_UA: locality },
    //                     region: { uk_UA: region },
    //                     postalCode: postalCode,
    //                     countryName: { uk_UA: 'Україна' },
    //                 },
    //                 // additionalIdentifiers: [],
    //                 contactPoint: {
    //                     name: { uk_UA: fullName },
    //                     email: email,
    //                     telephone: phone,
    //                     faxNumber: fax,
    //                     url: web
    //                 },
    //                 representativeInfo: info
    //             }

    //             if ( !data ) setData( prev => ([ ...prev, body ]))
    //             else {
    //                 await setData( prev => ([prev[index] = body]))
    //                 axiosPatchAPI({
    //                     // url: urlPatchMyExecution
    //                 })
    //             }
    //             close()
    //     }
    // }

    async function createObjectData(){
        let body = {
            contractNumber: contractNumber === null || contractNumber === '' ? null : contractNumber,
            title: title === null || title === '' ? null : { uk_UA: title },
            description: description === null || description === '' ? null : { uk_UA: description },
            
            value: value,
            contractTotalValue: contractTotalValue,
            //reducedValue: contractReducedValue,
            //valueAddedTaxCharged: contractValueAddedTaxCharged,
            
            dateSigned: dateSigned === null || dateSigned === '' ? null : dateSigned,
            // contractTime: (contractTimeDateFrom === null || contractTimeDateFrom === '') && (contractTimeDateTill === null || contractTimeDateTill === '') ? null : {
            //     dateFrom: contractTimeDateFrom !== null &&  contractTimeDateFrom !== '' ? contractTimeDateFrom : null,
            //     dateTill: contractTimeDateTill !== null && contractTimeDateTill !== '' ? contractTimeDateTill : null
            // }
            contractTime: contractTime
        }

        //dispatch( announcementUpdate( urlContract(data.id, data.contracts[0].id) , JSON.stringify(bodyContract), token.access_token))
        const url = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/executions/${executionItem.id}/contracts/${data.id}`
        const response = await fetch(url, {
            method: 'PATCH',
            body: JSON.stringify(body),
            headers: {
                'Authorization': 'Bearer ' + token.access_token,
                'Content-Type': 'application/json'
            }
        }).catch(console.error)
        const json = await response.json();
        if(json.hasOwnProperty("message") !== true){
            await dispatch({type: 'EXECUTION_GET_ID', payload: json})
        }
        //if (response.status === 200 && data.redirect) {
            //window.location.href = data.redirect;
        //}
        //if (response.status === 200 && !!data.navigate) {
            //data.navigate()
        //}
        // dispatch( {type: data.actionType, payload: json} )
        //if (response.status === 200) dispatch( {type: data1.actionType, payload: json} );
        //if (response.status !== 200) dispatch( {type: 'UNIVERSAL_ERROR', payload: `Помилка ${response.status} - ${json.message}` });
        //if (response.status === 200 && data.secondAction) {
            //data.secondAction();
        //}

        close()
    }

    return(
        <div className={styles.backdrop}>
            <div className={styles.wrapper}>
                <div className={styles.popupBody}>
                    <button className={styles.closeButton} 
                        onClick={ close }
                    >
                    </button>
                    <div className={styles.title}>{ !!data ? "Редагування" : "Додавання"} договору</div>

                    <Input 
                        label="Назва договору"
                        value={ title }
                        onChange={ event => setTitle( event ) }
                        //reqiured
                        // disabled={ isDraftObject( data.id ) ? false : true }
                    />
                    <InputTextArea
                        label="Опис договору"
                        value={ description }
                        onChange={ event => setDescription( event ) }
                        // disabled={ isDraftObject( data.id ) ? false : true }
                        //reqiured
                    />
                    <Input 
                        label="Номер договору"
                        value={ contractNumber }
                        onChange={ event => setContractNumber( event ) }
                        // disabled={ isDraftObject( data.id ) ? false : true }
                        //reqiured
                    />
                    <InputAmount 
                        title='Вартість договору'
                        value={ value }
                        onChange={ setValue }
                        tax={ true }
                        // disabled={ isDraftObject( data.id ) ? false : true }
                        required
                        // enableCurrent
                    />
                    <InputAmount 
                        title='Заключна вартість договору'
                        value={ contractTotalValue }
                        onChange={ setContractTotalValue}
                        tax={ true }
                        // disabled={ isDraftObject( data.id ) ? false : true }
                        //required
                        // enableCurrent
                    />
                    <div><h4>Строк виконання умов приватизації</h4></div>
                        <div className={styles.twoBlock}>
                            <InputDate
                                data={{
                                    label: "Період з",
                                    value: contractTime.dateFrom,
                                    // disabled: isDraftObject( data.id ) ? false : true,
                                    onChange: (e)=> setContractTime( prev => ({...prev, dateFrom: e })),
                                    reqiured: executionItem.saleCondition === "yes" ? true : false
                                }}
                            />
                            <InputDate
                                data={{
                                    label: "Період по",
                                    value: contractTime.dateTill,
                                    // disabled: isDraftObject( data.id ) ? false : true,
                                    onChange: (e)=> setContractTime( prev => ({...prev, dateTill: e })),
                                    reqiured: executionItem.saleCondition === "yes" ? true : false
                                }}
                        />
                    </div>
                    <InputDate
                        data={{
                            label: "Дата підписання договору",
                            value: dateSigned,
                            onChange: setDateSigned ,
                            //reqiured: true,
                            // disabled: isDraftObject( data.id ) ? false : true 
                        }}
                    />
                    
                    <div className={styles.buttonWrapper}>
                        <button 
                            className={styles.buttonCancel} 
                            onClick={ close }>
                            Скасувати
                        </button>
                        { 
                            executionItem.saleCondition === "yes" 
                            && (contractTime.dateFrom === null || contractTime.dateFrom === '')
                            && (contractTime.dateTill === null || contractTime.dateTill === '')
                                ? null
                                : <button 
                                    onClick={ createObjectData }
                                    className={ styles.buttonCreate }
                                >
                                    { !!data ? 'Оновити' : 'Додати' }
                                </button>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddBuyer;