import React from 'react';
import { isMongo } from '../../../utils/check';
import styles from './fullInfoBlock.module.css';

const FullInfoBlock =( {title, data, moreData} )=> {

    return (
                <div className={styles.blockFull}>
                    <div  className={styles.description}>{title}</div>
                    <div className={styles.data}>{ isMongo(data) }</div>
                    { !!moreData ? <div className={styles.data}>{moreData}</div> : null }
                </div>
    )
}

export default FullInfoBlock