import React, {useState, useEffect} from 'react'
import styles from './completion.module.css'
// import Input from '../../../../../inputs/input'
import InputDate from '../../../../../inputs/inputDate'
import InputTextArea from '../../../../../inputs/inputTextArea'
import moment from 'moment'
import CreateDocItem from '../../../../../elements/documents/docItem/documentItemOrder'
import { useDispatch, useSelector } from 'react-redux'
import { urlGetCompletionMyExecution, urlPatchMyExecutionStatus, urlPatchCompDocumentMyExecution, urlPostCompDocumentMyExecution } from '../../../../../../utils/endPoints'
import { axiosDeleteAPI, axiosPatchAPI } from '../../../../../../redux/actions/crud'
import AddDocPopUp from '../../../../../elements/documents/addDocPopup/addDocPopup'
import Button from '../../../../../elements/buttons/button'
import WarningBlock from '../../../../../elements/warningBlock'
import { useNavigate } from 'react-router-dom'

const ExecutionCompletion = ({ data }) => {

    const [complationData, setComplationData] = useState({
        dateSigned: data?.dateSigned ? data.dateSigned : moment().toISOString(),
        comment: data?.comment ? data.comment : null
    })
    const [addDocPopUp, setAddDocPopUp] = useState( false )
    const executionItem = useSelector(state => state.asset.executionItem)
    const [ edit, setEdit] = useState( executionItem?.completion === null ? true : false )
    const token = useSelector( state => state.start.token )
    const dispatch = useDispatch()
    const navigate = useNavigate()
    
    const docProps = { 
        dispatch: ( res )=> dispatch( { payload: res, type: 'EXECUTION_GET_ID'}),
        docOf: 'completion',
        object: executionItem,
        contract: data,
        docList: [
            { name: 'Підстави зняття з контролю', value: 'decontrolReason'},
            { name: 'Додаток', value: 'addition'}
        ],
        url: {
            patch: urlPatchCompDocumentMyExecution,
            post: urlPostCompDocumentMyExecution,
            delete: ( id, docId ) => execOrderDocDelete( id, docId ),
            history: (id, docId) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/executions/${executionItem.id}/completion/document/${docId}/history`
        }
    }

    const docListProps = { 
        dispatch: ( res )=> dispatch( { payload: res, type: 'EXECUTION_GET_ID'}),
        docOf: 'completion',
        object: executionItem,
        contract: data,
        docList: !!data?.documents && setComplationDocTypes( data.documents, 'list' ),
        url: {
            patch: urlPatchCompDocumentMyExecution,
            delete: ( id, docId ) => execOrderDocDelete( id, docId ),
            history: (id, docId) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/executions/${executionItem.id}/completion/document/${docId}/history`
        },
        buttons: {
            edit: executionItem?.status === 'active' ? true : false,
            delete: executionItem?.status === 'active' ? true : false,
            download: true
        }
    }

    function setComplationDocTypes(docs, bool = false) {
        if (bool === 'list') return [ { name: 'Підстави зняття з контролю', value: 'decontrolReason'}, { name: 'Додаток', value: 'addition'}]
        for ( let key in docs ) {
            if (docs[key].documentType === 'decontrolReason') {
                return bool ? true : [{ name: 'Додаток', value: 'addition'}]
            }
        }
        return bool ? false : [
             { name: 'Підстави зняття з контролю', value: 'decontrolReason'},
             { name: 'Додаток', value: 'addition'}
        ]
    }

    function execOrderDocDelete( id, docId ) {
        let deleteData = {
                //url: urlPostCompDocumentMyExecution( executionItem.id, id ),
                url: urlPatchCompDocumentMyExecution( id, docId ),
                dispatch: dispatch,
                set: ( data )=>  dispatch( { payload: data, type: 'EXECUTION_GET_ID'}),
                // body: resBody,
                // redirect: ()=> navigate(`/lease/create/${objectData.id}/contracts`),
                token: token.access_token,
        }
        axiosDeleteAPI( deleteData )
    }
    
    return (
        <div className={styles.orderWrap}>
            <WarningBlock
                    data={
                            <div>
                                Для завершення виконання умов приватизації необхідно:
                                <ul>
                                    {/* <li>Заповнити поля</li> */}
                                    <li>Додати документ "Підстави зняття з контролю"</li>
                                    <li>Натиснути кнопку "Умови приватизації виконано" для зміни статусу контрактингу</li>
                                </ul>
                            </div>
                        }
            />
             <div className={styles.twoInLine}>
                <InputDate 
                    data={{
                        label: 'Дата наказу',
                        value: complationData.dateSigned,
                        onChange: e => setComplationData( prev => ({ ...prev, dateSigned: e }) ),
                        time: true,
                        disabled: edit ? false : true
                        // alert: moment(complationData.dateSigned).isBefore( moment() ) ? 'Перевірте дату' : false
                    }}
                />
                
            </div>
            <InputTextArea 
                value={ complationData.comment } 
                onChange={ (e)=> setComplationData( prev => ({ ...prev, comment: e }) ) } 
                label={'Коментар'}
                disabled={ edit ? false : true }
            />
            { (executionItem.status === 'active' && executionItem.completion !== null) &&
                <div className={styles.editButton}>
                    <Button
                        onClick= {async () => {
                            if (edit) {
                                await axiosPatchAPI({
                                    url: urlGetCompletionMyExecution(executionItem.id),
                                    dispatch: dispatch,
                                    set: (data) => dispatch({ payload: data, type: 'EXECUTION_GET_ID' }),
                                    body: complationData,
                                    token: token.access_token,
                                })
                            }
                            setEdit(prev => !prev)
                        }}
                        disabled={moment(complationData.dateSigned).isAfter(moment())}
                    >
                        {edit ? 'Зберегти' : 'Редагувати'}
                    </Button>
                </div>
            }
            { (executionItem.status === 'active' && executionItem.completion === null) &&
                <div className={styles.editButton}>
                    <Button
                        onClick= {async () => {
                            if (edit) {
                                await axiosPatchAPI({
                                    url: urlGetCompletionMyExecution(executionItem.id),
                                    dispatch: dispatch,
                                    set: (data) => dispatch({ payload: data, type: 'EXECUTION_GET_ID' }),
                                    body: complationData,
                                    token: token.access_token,
                                })
                            }
                        }}
                        disabled={moment(complationData.dateSigned).isAfter(moment())}
                    >
                        Створити
                    </Button>
                </div>
            }
            <div className={styles.documentsWrap}>
                
                <div className={styles.documentsList}>
                    <div style={{ fontWeight: '600'}}>Документи</div>
                    { !!data?.documents && data.documents?.length < 1 &&
                        <div style={{ width: '100%', textAlign: 'center'}}>Додайте документи для завершення виконання умов приватизації</div>
                    }
                    { !!data?.documents && data.documents?.map(
                        (i) => {
                            return <CreateDocItem data={i} key={i.id} docProps={ docListProps } />
                        }) 
                    }
                </div>
                { executionItem.completion !== null 
                    ? <div className={styles.documentsAddButton}>
                        <Button onClick={() => setAddDocPopUp(true)} >Додати документ</Button>
                    </div>
                    : null
                }
                {
                    ( !!data?.documents && setComplationDocTypes(data.documents, true) && executionItem.status === 'active' ) &&
                    <div className={styles.postButton}>
                        <Button
                            color={'orange'} height='50px'
                            onClick={() => {
                                axiosPatchAPI({
                                    url: urlPatchMyExecutionStatus(executionItem.id, 'sold'),
                                    dispatch: dispatch,
                                    set: (data) => dispatch( { payload: data, type: 'EXECUTION_GET_ID'} ),
                                    // body: complationData,
                                    token: token.access_token,
                                    success: () => {
                                        dispatch({ type: 'UNIVERSAL_ERROR', payload: 'Умови приватизації виконано!' })
                                        navigate(`/asset/execution/${executionItem.executionId}`, { replace: true })
                                    }
                                })
                            } }
                        >
                            УМОВИ ПРИВАТИЗАЦІЇ ВИКОНАНО
                        </Button>
                    </div>
                }
                { addDocPopUp && <AddDocPopUp docProps={docProps} close={ ()=> setAddDocPopUp( false ) } /> }
            </div>
        </div>
    )
}

export default ExecutionCompletion