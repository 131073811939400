import React,{useState, useEffect} from 'react';
import LandItemPropsEdit from './itemProps/landProps';
import Classificator from './classificator';
import OtherPropertyPropsEdit from './itemProps/otherPropertyProps';
import VehicleItemPropsEdit from './itemProps/vahicleProps';
import RealEstatePropsEdit from './itemProps/realEstateProps';
import JointPropertyComplexPropsEdit from './itemProps/jointPropertyComplexProps';

const EditAssetItem =({ data, close })=> {
    
    const [ classificator, setClassificator ] = useState( !!data?.classification ? data.classification : null)

    function setItemContent( classif ){
        let id = classif.id[0] + classif.id[1]
        switch( id ){
            case '04':
                return <RealEstatePropsEdit data={data} close={ close } classification={ classificator } />
            case '05':
                return <JointPropertyComplexPropsEdit data={data} close={ close } classification={ classificator } />
            case '06':
                return <LandItemPropsEdit data={data} close={ close } classification={ classificator } />
            case '34':
                return <VehicleItemPropsEdit data={data} close={ close } classification={ classificator } />
            default:
                return <OtherPropertyPropsEdit  data={data} close={ close } classification={ classificator } />
        }
    }

    return (
        <>
            <Classificator data={ data?.classification ? data.classification : null } setData={ setClassificator } />
            { !!classificator && setItemContent( classificator ) }
        </>
    )
}



export default EditAssetItem