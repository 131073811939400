import React, { useState} from 'react'
import styles from './contractItemCard.module.css'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import UniPopUp from '../../../../../../../popups/uniPopUp/uniPopUp'
import HalfInfoBlock from '../../../../../../../elements/halfInfoBlock/halfInfoBlock'
import FullInfoBlock from '../../../../../../../elements/fullInfoBlock/fullInfoBlock'
import { checkProp,
    dateTransform,
    getUrAdress,
    numFormat,
    getCurrency, isMongo, isDraftObject } from '../../../../../../../../utils/check'
import PopDownBlock from '../../../../../../../elements/popdownBlock/popDownBlock'
import ViewDocItem from '../../../../../../../elements/documents/view/viewDocumentItem'
import Button from '../../../../../../../elements/buttons/button'
import BuyerView from './buyer/buyerView'
import { deleteMyLeaseContract, setLeaseContractMethod } from '../../../../../../../../utils/contracts/contractsUtils'
import { urlPatchLeaseContract, urlPatchLeaseContractDrafts } from '../../../../../../../../utils/endPoints'
import ValidateSendLeaseContract from '../elems/validateSendContract'

const LeaseContractItemCard = ({ data, setEditContract, close }) => {

    const token = useSelector(state => state.start.token);
    const objectData = useSelector(state => state.start.objectGet);
    const dispatch = useDispatch()
    const [deletePopUp, setDeletePopUp] = useState(false)

    //статус
    function setStatus(a) {
        switch(a) {
            case 'pending':
                return <span className={`${styles.status} ${styles.colorLightBrown}`}>Очікує публікації</span>
            case 'active':
                return <span className={`${styles.status} ${styles.colorGreen}`}>Діючий</span>
            case 'inactive':
                return <span className={`${styles.status} ${styles.colorLightGrey}`}>Недіючий</span>
            case 'cancelled':
                return <span className={`${styles.status} ${styles.colorDark}`}>Розірвано</span>
            default:
                return <span className={`${styles.status} ${styles.colorLightGrey}`}>Чернетка</span>
        }
    }

    return (
        <div className={styles.wrapper}>
            {/* { deletePopUp && 
                <UniPopUp
                    data={'Ви дійсно хочете видалити контракт?'}
                    type={2}
                    action={ ()=> {
                        // setLoader( true )
                            dispatch( deleteMyLeaseContract( 
                                ( isDraftObject(data.id) ? urlPatchLeaseContractDrafts(objectData.id, data.id) : urlPatchLeaseContract( objectData.id, data.id ) )
                                , token.access_token ) 
                            )
                            setDeletePopUp(false)
                            reload()
                            
                        }
                    }
                    close={ ()=> {
                        setDeletePopUp(false) 
                    }}
                />
            } */}
            {/* { loader && <Loader /> } */}
            <div className={styles.buttonWrap}>
                <ValidateSendLeaseContract data={ data } setEditContract={ setEditContract } />
                <button className={styles.buttonEdit} 
                    onClick={ ()=> setEditContract( prev => ({ ...prev, data: data, edit: 'edit' })) }>
                    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M15.861 5.59595L16.9757 4.4812L14.5187 2.02413L13.4039 3.13889L15.861 5.59595ZM14.4542 7.00273L11.9971 4.54566L2.80565 13.7371L2.24014 16.7597L5.26272 16.1942L14.4542 7.00273ZM18.4419 3.13384C19.186 3.87796 19.186 5.08443 18.4419 5.82856L6.45174 17.8187C6.30989 17.9606 6.12847 18.0562 5.93129 18.0931L1.17816 18.9824C0.490832 19.111 -0.111152 18.509 0.0174442 17.8217L0.90674 13.0686C0.943632 12.8714 1.03928 12.69 1.18113 12.5481L13.1713 0.557942C13.9154 -0.186184 15.1219 -0.186184 15.866 0.557942L18.4419 3.13384ZM18.0099 18.9999H9.9901C8.66997 18.9999 8.66997 16.9999 9.9901 16.9999H18.0099C19.33 16.9999 19.33 18.9999 18.0099 18.9999Z" fill="#92929D"></path>
                    </svg>
                </button>
                <button className={styles.buttonDelete} onClick={ close }>
                <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17 6.99998L1 6.99999C0.447716 6.99999 -3.1019e-09 7.4477 2.10392e-08 7.99999C4.51804e-08 8.55227 0.447716 8.99999 1 8.99999L17 8.99999C17.5523 8.99999 18 8.55227 18 7.99998C18 7.4477 17.5523 6.99998 17 6.99998Z" fill="#FFA047"/>
                    <path d="M2.51859 8.00888L9.65903 1.7521C10.0744 1.38812 10.1161 0.75633 9.75211 0.340951C9.38814 -0.074429 8.75634 -0.116101 8.34096 0.247874L0.340964 7.25784C-0.114082 7.65658 -0.113582 8.36491 0.342027 8.763L8.34203 15.753C8.75792 16.1164 9.38965 16.0739 9.75304 15.658C10.1164 15.2421 10.0739 14.6103 9.65797 14.2469L2.51859 8.00888Z" fill="#FFA047"/>
                </svg>

                </button>
            </div>
            <div className={styles.title}>{ isMongo(data.title) }</div>



            <div style={{display: 'flex', flexFlow: 'row wrap'}}>
                        { checkProp(data, 'status') &&
                                <HalfInfoBlock
                                    title={'Статус'}
                                    data={ setStatus(data.status) }
                                /> 
                        }
                        { checkProp(data, 'datePublished') && 
                            <HalfInfoBlock
                                title={'Дата публікації'}
                                data={dateTransform(data.datePublished, 'time')}
                            />
                        }
                        { checkProp(data, 'dateModified') && 
                            <HalfInfoBlock
                                title={'Остання дата зміни'}
                                data={dateTransform(data.dateModified, 'time')}
                            />
                        }
                        { checkProp(data, 'contractNumber') && 
                            <HalfInfoBlock
                                title={'Номер договору'}
                                data={data.contractNumber}
                            />
                        }
                        { checkProp(data, 'value') && 
                            <HalfInfoBlock
                                title={`Ставка оренди відповідно до договору, ${data.value.valueAddedTaxIncluded ? 'з ПДВ' : 'без ПДВ'}`}
                                data={<b>{`${numFormat.format(data.value.amount)} ${getCurrency(data.value.currency)}`}</b>}
                            />
                        }
                        { checkProp(data, 'dateSigned') && 
                            <HalfInfoBlock
                                title={'Дата підписання договору'}
                                data={dateTransform(data.dateSigned)}
                            />
                        }
                        { ( !!data?.contractTime && !!data.contractTime?.dateFrom && !!data.contractTime?.dateTill ) &&
                            <HalfInfoBlock
                                title={'Строк дії договору (період з - по)'}
                                data={`${dateTransform(data.contractTime.dateFrom)} - ${dateTransform(data.contractTime.dateTill)}`}
                            />
                        }
                        { !!data?.contractMethod && 
                            <HalfInfoBlock
                                title={'Метод укладання договору'}
                                data={ setLeaseContractMethod(data.contractMethod) }
                            />
                        }
                        { checkProp(data, 'description') && 
                            <FullInfoBlock
                                title={'Опис договору'}
                                data={data.description.uk_UA}
                            />
                        }
                        { ( !!data?.buyers && data.buyers.length > 0 )  && 
                            <PopDownBlock
                                title={ `Орендар${data.buyers.length > 1 ? 'і' : ''} ${data.buyers.length > 0 ? `( ${data.buyers.length} )` : ''} `}
                                content={ 
                                    <div className={styles.list} >
                                        { data.buyers.map( i => <BuyerView data={ i } key={ i.name } />) } 
                                    </div> 
                                }
                            />
                        }
                        { (checkProp(data, 'documents') && data.documents.length > 0 ) && 
                            <PopDownBlock
                                title={`Документи ( ${data.documents.length} )`}
                                content={
                                    <div style={{ display: 'flex', flexFlow: 'column', width: '100%', gap: '15px'}}>
                                        { data.documents.map(( item ) => <ViewDocItem 
                                                                data={item}
                                                                specs={{
                                                                        objId: !!objectData && objectData.id,
                                                                        // docType: getAnnouncementDocType(doc.documentType) --> тут ми дістаємо тип документа
                                                                        // historyURL: `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/asset/${id.annId}/document/${id.docId}/history`
                                                                    }}
                                                            />)
                                        }
                                    </div>
                                }
                            />
                        }
            </div>


        </div>
    )
}

export default LeaseContractItemCard