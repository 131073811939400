import { getAPI } from '../redux/actions/crud'

export function checkProp(obj, prop) {
    if (obj !== null && typeof obj !== 'undefined') {
        if (obj.hasOwnProperty(prop)) {
            if (obj[prop] !== null && typeof obj[prop] !== 'undefined') {
                return true
            }
        }
    }
    return false
}

export function isMongo(data, prop = 'uk_UA'){
    if ( data && data.hasOwnProperty(prop) ) return data[prop]
    return data
}

/* DICTIONARIES */
export function dictionaryFind(dictionary, data){
    if( !!dictionary && checkProp(dictionary, data)) return isMongo(dictionary[data])
    else return data
}

export function getValues(array, value, prop1, prop2){
    for(let key in array){
        if(array[key][prop1] === value){
            return array[key][prop2]
        }
    }
}

export function dictionaryGet( dictionary, actionType){
    const data = {
        url: `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/dictionaries/${dictionary}`,
        actionType: actionType,
        header: {
            'Content-Type': 'application/json'
        }
    }
    return getAPI(data);
}

export function getUnitCodes( ){
    const data = {
        url: `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/classifications/byScheme/unitCode?limit=1000&offset=0&orderBy=description&order=ASC`,
        actionType: 'GET_DICTIONARIES_UNIT_CODE',
        header: {
            'Content-Type': 'application/json'
        }
    }
    return getAPI(data);
}

export function dateTransform(a, b) {
    let tmpStamp = Date.parse(a)

    let res = new Date(tmpStamp)
    let tmpYear = res.getFullYear()
    let tmpMonth = res.getMonth()+1 < 10 ? `0${res.getMonth()+1}` : res.getMonth()+1
    let tmpDay = res.getDate() < 10 ? `0${res.getDate()}` : res.getDate()
    let tmpTime = `${res.getHours() < 10 ? `0${res.getHours()}` : res.getHours()}:${res.getMinutes() < 10 ? `0${res.getMinutes()}` : res.getMinutes()}`
    
    //вывод времени при необходимости
    switch(b){
    case 'time':
        return `${tmpDay}.${tmpMonth}.${tmpYear} ${tmpTime}`
    case 'onlyTime':
        return `${tmpTime}`
    default:
        return `${tmpDay}.${tmpMonth}.${tmpYear}`
    }
}

export function getShorter(source, length = 60) {
        let tmp = source
        if (tmp.length > length) {
            tmp = tmp.substring(0,length)
            return `${tmp}...`
        } else {
            return tmp
        }
}

export function getIdentifierType(x){
    switch(x){
        case "UA-EDR": 
          return "Код ЄДРПОУ"
        case "UA-IPN":
          return "ІПН"
        case "UA-PASSPORT":
          return "Паспорт"
        case "UA-ID-CARD":
          return "ID-карта"
        case "UA-IPN-FOP":
          return "ФОП-ІПН"
        default:
            return ""
      }
}

export function getUrAdress(a) {
    let postal = ''
    let country = ''
    let region = ''
    let street = ''
    let city = ''

    if ( !!a?.address?.postalCode && a.address.postalCode !== null ) { postal = isMongo(a.address.postalCode) }
    if ( !!a?.address?.locality && a.address.locality !== null ) { city = isMongo(a.address.locality) }
    if ( !!a?.address?.region && a.address.region !== null ) { region = isMongo(a.address.region) }

    if ( !!a?.address?.countryName && a.address.countryName !== null ) { country = isMongo(a.address.countryName) }
    if ( !!a?.address?.streetAddress && a.address.streetAddress !== null ) { street = isMongo(a.address.streetAddress) }

    return(`${postal !== '' ? postal + ', ' : postal }${country}${ !!region ? ', ' + region : '' }${ !!city ? ', ' + city : '' }${ !!street ? ', ' + street : '' }`)
}

export function getDictionary(source, name, actionType) {
    let data = {}
    let result = []
    data.header = {
        'Content-Type': 'application/json'
    }
    data.actionType = actionType
    data.url = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/public/dictionaries/${name}?limit=100&orderBy=id&order=DESC`
    result = getAPI(data.url)

    for (let key in result) {
        if (source === result[key].name) {
            return result[key].description.uk_UA
        }
    }
}

export function getCurrency(x){
    switch(x){
        case "UAH": 
          return "грн"
        case "USD":
          return "дол. США"
        case "EUR":
          return "євро"
      }
}

export function getAddedTax(x) { return x ? 'з ПДВ' : 'без ПДВ' }

export const numFormat = new Intl.NumberFormat()

export function getFormatedValue( data ){
    const value = !!data?.amount ? valueFormat(data.amount) : ''
    const currency = !!data?.currency ? getCurrency(data.currency) : ''
    const tax = !!data?.valueAddedTaxIncluded ? getAddedTax(data.valueAddedTaxIncluded) : false
    return !!tax ? `${value} ${currency}${', ' + tax}` : `${value} ${currency}`
}

export function valueFormat( value ){
    return numFormat.format( value )
}

export function getTranslate(type, typeList) {
    if (typeList !== null) {
        let list = typeList.filter(obj => obj.name.includes(type))
        let result = list.length > 0 ? list[0].description.uk_UA : ''
        return result;
    } else return '';
}

export function getPropertyType(a) {
    switch(a) {
        case 'realEstate':
            return `Об'єкт нерухомості`
        case 'vehicle':
            return `Транспортний засіб`
        case 'land':
            return `Земельна ділянкa`
        case 'otherProperty':
            return `Інше окреме індивідуально визначене майно`
        case 'jointPropertyComplex':
            return `Об'єкт єдиного майнового комплексу`
        default:
            return a
    }
}

export function getOwnershipType(a) { // Тип власності
    switch(a){
        case 'state':
            return 'Державна'
        case 'private':
            return 'Приватна'
        case 'municipal':
            return 'Комунальна'
        case 'compatible':
            return 'Сумісна'
        case 'unknown':
            return 'Невідомо'
    }
}

export function getRegistryType(i) { //тип переліку
    if (!i) return null
    switch(i) {
        case 'First':
            return 'Перелік першого типу'
        case 'Second':
            return 'Перелік другого типу'
        case 'Undefined':
            return 'Не визначено'
        case 'Third':
            return 'Релокація'
        default:
            return 'Не визначено'
    }
}

export function getIdType(a) {
    switch (a) {
        case 'UA-EDR':
            return 'ЄДРПОУ'
        case 'UA-IPN':
            return 'ІПН'
        case 'UA-PASSPORT':
            return 'Паспорт'
        case 'UA-ID-CARD':
            return 'ID-карта'
        case 'UA-IPN-FOP':
            return 'ІПН'
    }
}

export function isDraftObject(id){
    if ( !!id && id.indexOf('-') !== -1) return true
    return false
}

export function getAssetItemType(type){
    const dictionary = {
        "assets": {"uk_UA": "Майно","en_US": "Property"},
        "claimRights": {"uk_UA": "Права вимоги","en_US": "Claim rights"}
      }
    return dictionary[type].uk_UA
}

export function getRegistrationStatus(type){
    const dictionary = {
        "properlyRegistered": {"en_US": "Properly registered","uk_UA": "Зареєстровано в Державному реєстрі речових прав на нерухоме майно"},
        "oldRegistration": {"en_US": "Old registration","uk_UA": "Зареєстровано до 1 січня 2013 р. відповідно до законодавства, що діяло на момент виникнення речового права на майно"},
        "notRegistered": {"en_US": "Not registered","uk_UA": "Не зареєстровано"},
        "registrationIsNotRequired": {"en_US": "Registration is not required","uk_UA": "Реєстрація не вимагається"}
      }
    if( checkProp(dictionary, type ) ) return dictionary[type].uk_UA
    else return type
}

export function getLeaseObjectStatus(status){
    const dictionary = {
        "waiting": { "en_US": "Waiting", "uk_UA": "Очікує включення в перелік" },
        "approved": { "en_US": "Approved", "uk_UA": "Включено в перелік" },
        "inactive": { "en_US": "Inactive", "uk_UA": "Неактивний" },
        "active": { "en_US": "Active", "uk_UA": "Опубліковано оголошення" },
        "qualification": { "en_US": "Qualification", "uk_UA": "Визначення орендаря" },
        "sold": { "en_US": "Rented", "uk_UA": "Орендовано" },
        "deleted": {"en_US": "Deleted","uk_UA": "Видалено"}
      }
      if( checkProp(dictionary, status ) ) return dictionary[status].uk_UA
      if( !status ) return 'ЧЕРНЕТКА'
}

export function getAssetStatus(status){
    const dictionary = {
        "draft": {"en_US": "Draft","uk_UA": "Чернетка"},
        "pending": {"uk_UA": "Об’єкт опубліковано","en_US": "Asset published"},
        "active_rectification": {"uk_UA": "Опубліковано Інформаційне Повідомлення","en_US": "Announcement published"},
        "active_procedure": {"uk_UA": "Проведення аукціону","en_US": "Auction published"},
        "active_contracting": {"uk_UA": "Виконання умов приватизації","en_US": "Contracting"},
        "complete": {"uk_UA": "Приватизація Об'єкта завершена","en_US": "Privatization completed"},
        "active_redemption": {"uk_UA": "Приватизація шляхом викупу","en_US": "Privatization by redemption"},
        "archive": {"uk_UA": "Архів","en_US": "Archive"},
        "deleted": {"uk_UA": "Об’єкт виключено із переліку","en_US": "Asset deleted from Registry"}
      }
      if( checkProp(dictionary, status ) ) return dictionary[status].uk_UA
      if( !status ) return dictionary['draft'].uk_UA
}

export function getAnnouncementStatus(status){
    const dictionary = {
    "draft": {"en_US": "Draft","uk_UA": "Чернетка"},
    "pending": { "uk_UA": "Інформаційне Повідомлення опубліковано", "en_US": "Announcement published" },
    "active_auction": { "uk_UA": "Аукціон опубліковано", "en_US": "Auction" }, 
    "active_contracting": { "uk_UA": "Виконання умов приватизації", "en_US": "Auction finished. Fulfillment of privatization" }, 
    "sold": { "uk_UA": "Об'єкт продано", "en_US": "Asset sold" }, 
    "dissolved": { "uk_UA": "Об’єкт не продано", "en_US": "Asset is not sold" }, 
    "deleted": { "uk_UA": "Інформаційне Повідомлення розформовано", "en_US": "Announcement deleted" } }

      if( checkProp(dictionary, status ) ) return dictionary[status].uk_UA
      if( !status ) return dictionary['draft'].uk_UA
    //   return STATUS
}

export function getRedemptionStatus(status){
    const dictionary = {
    "draft": {"en_US": null,"uk_UA": "Чернетка"},
    "pending": { "uk_UA": "Опубліковано рішення про викуп", "en_US": null },
    "active_contracting": { "uk_UA": "Виконання умов приватизації", "en_US": null }, 
    "sold": { "uk_UA": "Об'єкт продано", "en_US": null }, 
    "deleted": { "uk_UA": "Продаж скасовано", "en_US": null } }

      if( checkProp(dictionary, status ) ) return dictionary[status].uk_UA
      if( !status ) return dictionary['draft'].uk_UA
    //   return STATUS
}

export function setRedemptionStatus(status){ // статус об'єкта
    switch(status) {
        case 'pending':
            return  <div style={{color: 'var(--green)'}}>
                    { getRedemptionStatus(status) }
                    </div>
        case 'active_contracting':
            return <div style={{color: 'var(--red)'}}>
                    { getRedemptionStatus(status) }
                    </div>
        case 'sold':
            return <div style={{color: 'var(--darkBlue)'}}>
                    { getRedemptionStatus(status) }
                    </div>
        case 'deleted':
            return <div style={{color: 'var(--darkGray)'}}>
                    { getRedemptionStatus(status) }
                    </div>
        default:
            return <div style={{color: 'var(--darkGray)'}}>
                        ЧЕРНЕТКА
                    </div>
    }
}

export function setAnnouncementStatus(status){ // статус об'єкта
    switch(status) {
        case 'pending':
            return  <div style={{color: 'var(--green)'}}>
                    { getAnnouncementStatus(status) }
                    </div>
        case 'active_auction':
            return <div style={{color: 'var(--green)'}}>
                    { getAnnouncementStatus(status) }
                    </div>
        case 'active_contracting':
            return <div style={{color: 'var(--red)'}}>
                    { getAnnouncementStatus(status) }
                    </div>
        case 'sold':
            return <div style={{color: 'var(--darkBlue)'}}>
                    { getAnnouncementStatus(status) }
                    </div>
        case 'dissolved':
            return <div  style={{color: 'var(--attentionText)'}}>
                    { getAnnouncementStatus(status) }
                    </div>
        case 'deleted':
            return <div style={{color: 'var(--darkGray)'}}>
                    { getAnnouncementStatus(status) }
                    </div>
        default:
            return <div style={{color: 'var(--darkGray)'}}>
                        ЧЕРНЕТКА
                    </div>
    }
}

export function setAuctionAssetStatus(status){
    switch(status) {
        case 'active_tendering':
            return  <div style={{color: 'var(--green)'}}>
                        Прийняття заяв на участь
                    </div>
        case 'active_auction':
            return <div style={{color: 'var(--red)'}}>
                        Аукціон
                    </div>
        case 'active_qualification':
            return <div style={{color: 'var(--green)'}}>
                        Очікується опублікування протоколу
                    </div>
        case 'pending_payment':
            return <div style={{color: 'var(--darkBlue)'}}>
                        Очікується оплата
                    </div>
        case 'active_awarded':
            return <div  style={{color: 'var(--darkBlue)'}}>
                        Очікується підписання договору
                    </div>
        case 'complete':
            return <div style={{color: 'var(--darkGray)'}}>
                        Аукціон завершено. Договір підписано
                    </div>
        case 'cancelled':
            return <div style={{color: 'var(--lightGray)'}}>
                        Аукціон відмінено
                    </div>
        case 'unsuccessful':
            return <div style={{color: 'var(--lightGray)'}}>
                        Аукціон не відбувся
                    </div>
    }
}

export function getOwnerShipType(type){
    const dictionary = {
        "state": { "en_US": "State", "uk_UA": "Державна" },
        "private": { "en_US": "Private", "uk_UA": "Приватна" },
        "municipal": { "en_US": "municipal", "uk_UA": "Комунальна" },
        "compatible": { "en_US": "compatible", "uk_UA": "Сумісна" },
        "unknown": { "en_US": "Unknown", "uk_UA": "Невідомо" }
    }
    if( checkProp(dictionary, type ) ) return dictionary[type].uk_UA
    else return type
}

export function getAdditionalClassificatorDM( classifID ){
    let tmp = {}
    const id = classifID[0] + classifID[1]
    const dm = {
        "101": {"en_US": "Іndividual immovable asset","uk_UA": "Окреме нерухоме майно"},
        "102": {"en_US": "Іndividual movable asset","uk_UA": "Окреме рухоме майно"},
        "200": {"en_US": "Asset complexes of state enterprises, their structural units","uk_UA": "Єдині майнові комплекси державних підприємств, їх структурні підрозділи"},
        "301": {"en_US": "Packages of shares of joint-stock companies created in the process of privatization or corporatization","uk_UA": "Пакети акцій акціонерних товариств, утворених у процесі приватизації або корпоратизації"},
        "302": {"en_US": "Shares (parts) belonging to the state in the authorized capital of organizations founded on the basis of the pooling of asset of various forms of ownership","uk_UA": "Акції (частки), що належать державі у статутному капіталі господарських організацій, заснованих на базі об'єднання майна різних форм власності"},
        "400": {"en_US": "Objects of unfinished construction, preserved objects of various forms of ownership","uk_UA": "Об’єкти незавершеного будівництва, законсервовані об’єкти різних форм власності"},
        "500": {"en_US": "Socio-cultural objects","uk_UA": "Об’єкти соціально-культурного призначення"},
        "900": {"en_US": "Other objects","uk_UA": "Інші об'єкти"}
      }

    //   if (classifID === '42990000-2') tmp = { id: '102', uk_UA: dm['102'].uk_UA } 
      if (classifID === '42990000-2') return [{ id: '102', description: { uk_UA: dm['102'].uk_UA }, scheme: 'dm' }]
    //   if (classifID === '08110000-0') tmp = { id: '301', uk_UA: dm['301'].uk_UA }
      if (classifID === '08110000-0') return [{ id: '301', description: { uk_UA: dm['301'].uk_UA }, scheme: 'dm' }]
    //   if (classifID === '08160000-5') tmp = { id: '302', uk_UA: dm['302'].uk_UA }
      if (classifID === '08160000-5') return [{ id: '302', description: { uk_UA: dm['302'].uk_UA }, scheme: 'dm' }]
    //   if (id === '05') tmp = { id: '200', uk_UA: dm['200'].uk_UA }
      if (id === '05') return [{ id: '200', description: { uk_UA: dm['200'].uk_UA }, scheme: 'dm' }]
      if (id === '04') return [
        {value: '101', name: "Окреме нерухоме майно" },
        {value: '400', name: "Об’єкти незавершеного будівництва, законсервовані об’єкти різних форм власності" },
        {value: '500', name: "Об’єкти соціально-культурного призначення" }
      ]
      else tmp = { id: '900', uk_UA: dm['900'].uk_UA }

      return [{ id: tmp.id, description: { uk_UA: tmp.uk_UA }, scheme: 'dm' }]
}

export function setTypeOfBankAccount( type ){
    const dictionary = {
        "UA-EDR": {"en_US": "EDRPOU","uk_UA": "ЄДРПОУ банку","pattern": "^[0-9]{8}$"},
        "UA-MFO": {"en_US": "MFO","uk_UA": "МФО банку","pattern": "^[0-9]{6}$"},
        "UA-accountNumber": {"en_US": "Account number","uk_UA": "Банківський рахунок","pattern": "^[0-9]{5,}$"},
        "UA-IBAN": {"en_US": "IBAN","uk_UA": "Номер банківського рахунку в форматі IBAN","pattern": "^[a-zA-Z]{2}[0-9]{27}$"}
    }
    if( checkProp(dictionary, type ) ) return dictionary[type].uk_UA
    else return type
}

export function getPaymentType( type ){
    const dictionary = {
        "registrationFee": {"en_US": "Registration fee","uk_UA": "Реєстраційний внесок"},
        "guarantee": {"en_US": "Guarantee","uk_UA": "Гарантійний внесок"},
        "organizer": {"en_US": "Organizer","uk_UA": "Організатор"},
        "advancePayment": {"en_US": "Advance payment","uk_UA": "Авансовий внесок"},
        "securityDeposit": {"en_US": "Security deposit","uk_UA": "Забезпечувальний депозит"},
        "improvementCompensation": {"en_US": "Compensation for permanent improvements","uk_UA": "Компенсація невід’ємних поліпшень"},
        "lease": {"en_US": "Lease payments","uk_UA": "Орендні платежі"},
        "other": {"en_US": "Other payments","uk_UA": "Інші платежі"},
        "payment": {"en_US": "Lot payments","uk_UA": "Плата за лот"},
        "preparationPayment": {"en_US": "Lot preparation payments","uk_UA": "Плата за підготовку лота"}
    }
    if( checkProp(dictionary, type ) ) return dictionary[type].uk_UA
    else return type
}