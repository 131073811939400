import React, {useEffect, useState} from 'react';
import styles from './objectItemPage.module.css';
import {useSelector, useDispatch} from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
    auctionGetId,
    // leaseReqestsGetCount,
    auctionsGetSearch,
    // regActionsCountGet,
    // objectContractsGet
} from '../../../redux/actions/auctions/auctions';
import { checkProp, getShorter, isMongo } from '../../../utils/check';
import GeneralInfo from './blocks/generalInfo/generalInfoBlock';
import LeaseReqBlock from './blocks/leaseReq/leaseReqBlock';
import ActionsBlock from './blocks/actions/actionsBlock';
import ContractsBlock from './blocks/contracts/contractsBlock';
import ActionCreate from '../actionCreate/actionCreate'
import SideBlockView from './blocks/sideBlock/sideBlockView';
import { getObjectById, getObjectByPublicId, pubObjectLeaseAct, pubObjectLeaseReq, pubObjectLeaseContracts } from '../../../utils/endPoints';
import { getDataPub } from '../../../redux/actions/crud';
import Button from '../../elements/buttons/button';

const ObjectItem = () => {
    const dispatch = useDispatch()
    // const token = useSelector(state => state.start.token)
    const data = useSelector(state => state.start.auctionsGetId)
    const user = useSelector(state => state.start.userProfiles)
    
    const [objectPublic, setObjectPublic] = useState(false)
    // const [path, setPath] = useState('')
    const [leaseRequests, setLeaseRequests] = useState([])
    const [leaseActions, setLeaseActions] = useState([])
    // const [leaseContracts, setLeaseContracts] = useState( !!data?.contracts ? data.contracts : [] )
    const { pathname } = useLocation()
    const navigate = useNavigate()

    // let amount // вартість
    let title // назва
    let image //фото
    let status //статус
    let type //тип
    let propertyType //тип об'єкта
    const lastPath = ( pathname )=> {
        if (pathname.split('/').at(-1) === 'lease_contracts') return 'Договори'
        if (pathname.split('/').at(-1) === 'lease_actions') return 'Дії'
        if (pathname.split('/').at(-1) === 'lease_requests') return 'Заяви'
        if (pathname.split('/').at(-1) === data.registryObjectId || pathname.split('/').at(-1) === data.id ) return 'Загальна інформація'
    }

    useEffect(() => {
        // if ( !!data?.contracts ) setLeaseContracts( data.contracts )
        let id = null
        if(pathname.split("/")[1] === 'object'){
            id = pathname.split('/')[2]
        }else{
            id = pathname.split('/')[3]
        }
        let idReg = new RegExp(/[R][G][L]\d./)
        let url = id.match(idReg) ? getObjectByPublicId( id ) : getObjectById( id )
        dispatch( auctionGetId( url ) )
            if ( !!data?.id ) getDataPub( pubObjectLeaseReq( data.id ), setLeaseRequests )
            if ( !!data?.id ) getDataPub( pubObjectLeaseAct( data.id ), setLeaseActions )
            // if ( !!data?.id ) getDataPub( pubObjectLeaseContracts( data.id ), setLeaseContracts )

        return () => {
            dispatch( { type: 'AUCTIONS_GET_ID', payload: null } ) // redux clear
        }
      }, [ ])

    if ( !!data && typeof data !== 'undefined') {
        title = checkProp(data, 'title') ? isMongo(data.title) : null
        status = checkProp(data, 'statusesDecisions') ? data.statusesDecisions.statusInList : null
        type = checkProp(data, 'statusesDecisions') ? data.statusesDecisions.listType : null
        propertyType = (checkProp(data, 'registryObjectItems') && data.registryObjectItems.length > 0) ? data.registryObjectItems['0'].itemType : null

        function mainBlock(){
            switch(pathname.split('/')[4]){
                case 'lease_requests':
                    return <LeaseReqBlock data={ leaseRequests } setLeaseRequests={setLeaseRequests} objId={data.id}/>
                case 'lease_actions':
                    return <ActionsBlock data={ leaseActions } />
                case 'actions':
                    return <ActionCreate />
                case 'lease_contracts':
                    return <ContractsBlock data={ data.contracts } />
                default:
                    return <GeneralInfo data={ data } />
            }
        }

        return (
            <div className={styles.wrapPage}>
                <div className={styles.navBlock}>
                    <div style={{display: 'flex', flexFlow: 'row', flex: 1, justifyContent: 'space-between'}}>
                        <ul>
                            <li key={1}>
                                <Link to={`/lease/objects_list`}
                                    onClick={()=>{
                                        let url = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/public/registry_objects/search`
                                        dispatch({ payload: null, type: 'AUCTIONS_GET' })
                                        dispatch({payload: 1, type: 'SET_MY_CURRENT_PAGE'})
                                        auctionsGetSearch(url)
                                        }}>Перелік об’єктів
                                </Link>
                            </li>
                            <li key={2} className={styles.liTitle}>{ title ? getShorter(title, 80) : 'Новий об\'єкт' }</li>
                            <li key={3}>{ lastPath( pathname ) }</li>
                        </ul>
                        { 
                            !!user &&
                            ( ( 
                            !!data?.relatedOrganizations?.propertyOwner?.identifier?.id && 
                            !!data?.relatedOrganizations?.sellingEntity?.identifier?.id && 
                            !!user?.organization?.identifier?.identifier 
                            ) && 
                                data.relatedOrganizations?.propertyOwner.identifier.id === user.organization.identifier.identifier ||
                                data.relatedOrganizations?.sellingEntity.identifier.id === user.organization.identifier.identifier 
                            ) && 
                            <Button color='gray' onClick={ ()=> navigate(`/lease/create/${data.id}`) }>
                                Редагувати об'єкт
                            </Button> 
                        }
                    </div>
                    <h1 className={styles.objTitle}>{ title }</h1>
                    <div className={styles.objNav}>
                        <div className={ !pathname.split('/')[4] ? styles.activeNavItem : styles.objNavItem } onClick={ ()=> navigate('') }>
                            Загальна інформація
                        </div>
                        <div className={ pathname.split('/')[4] === 'lease_requests' ? styles.activeNavItem : styles.objNavItem } 
                            onClick={ ()=> navigate('lease_requests') } >
                            Заяви { leaseRequests.length > 0 && `(${leaseRequests.length})` }
                        </div>
                        <div className={ pathname.split('/')[4] === 'lease_actions' ? styles.activeNavItem : styles.objNavItem } 
                            onClick={ ()=> navigate('lease_actions')} >
                            Дії { leaseActions.length > 0 && `(${leaseActions.length})` }
                        </div>
                        {  !!data?.contracts && data.contracts.length > 0 
                                        &&
                                <div className={ pathname.split('/')[4] === 'lease_contracts' ? styles.activeNavItem : styles.objNavItem } 
                                    onClick={ ()=> navigate('lease_contracts')}>
                                    Договори ({data.contracts.length})
                                </div> 
                        }
                    </div>
                </div>
                <div className={styles.mainBlock}>
                    { !objectPublic ? 
                        <>
                            <div className={styles.objContent}>
                                { mainBlock() }
                            </div>
                            <SideBlockView data={data} />
                            </> : 
                        <>
                            <SideBlockView data={data} />
                            <div className={styles.objContent}>
                                { mainBlock() }
                            </div>
                        </> 
                    }
                </div>
            </div>
        )
    }

    return null

}

export default ObjectItem