import React from 'react';
import styles from './elems.module.css';
import FullInfoBlock from '../../../../elements/fullInfoBlock/fullInfoBlock';
import HalfInfoBlock from '../../../../elements/halfInfoBlock/halfInfoBlock';
import { dateTransform, isMongo } from '../../../../../utils/check';

const AssetDecisions = ( { data } ) => {

    function setDecisionType( type ){
        switch(type){
            case 'asset': return 'Майно'
            case 'large_asset': return 'Великий актив'
            case 'announcement': return 'Оголошення'
            case 'procedure': return 'Оголошення'
            default: return type
        }

    }

    return <div className={styles.infoBlockOrgContent}>
                { !!data && data.length > 0 ?
                    data.map( item => <div className={styles.decisionItem} key={item} >
                        {!!item?.decisionTitle &&
                            <FullInfoBlock
                                title='Найменування рішення'
                                data={ isMongo(item.decisionTitle)}
                            />
                        }
                        {!!item?.decisionDate &&
                            <HalfInfoBlock
                                title='Тип рішення'
                                width='33'
                                data={ setDecisionType(item.decisionOf) }
                            />
                        }
                        {!!item?.decisionNumber &&
                            <HalfInfoBlock
                                title='Номер рішення'
                                width='33'
                                data={ item.decisionNumber}
                            />
                        }
                        {!!item?.decisionDate &&
                            <HalfInfoBlock
                                title='Дата рішення'
                                width='33'
                                data={ dateTransform(item.decisionDate, 'time')}
                            />
                        }
                    </div> )
                    : 'Рішень немає'
                }
        </div>
}

export default AssetDecisions