import React from 'react';
import styles from './typeSite.module.css';

import { useDispatch } from 'react-redux';

import { Link, useLocation } from 'react-router-dom';

const TypeSite = ( props ) => {
    const dispatch = useDispatch()
    const { pathname } = useLocation()

    return (
        <div className={styles.categoryWrapMain}>
            <div className={styles.categoryWrap}>
                <a href='https://beta.dto.com.ua/'>
                    <div className={styles.category}>
                        Аукціони
                    </div>
                </a>
                <Link to='/asset/assets_list'>
                    <div className={ pathname.indexOf('asset') > 0 && pathname.indexOf('_list_lp') === -1 ? styles.activeCategory : styles.category }>
                        Об’єкти малої приватизації
                    </div>
                </Link>
                <Link to='/asset/assets_list_lp'>
                    <div className={ pathname.indexOf('asset') > 0 && pathname.indexOf('_list_lp') > 0 ? styles.activeCategory : styles.category } >
                        Об’єкти великої приватизації
                    </div>
                </Link>
                {/* <a href='https://privatization.gov.ua/mapa-ob-yektiv/'>
                    <div className={styles.category}>
                        <div className={styles.text}>Мапа об’єктів приватизації</div>
                    </div>
                </a> */}
                <Link to='/lease/objects_list'>
                    <div 
                        className={ pathname.indexOf('lease') > 0 ? styles.activeCategory : styles.category } 
                        onClick={ ()=> dispatch({payload: null, type: 'AUCTIONS_GET'}) } 
                    >
                            Перелік об’єктів оренди
                    </div>
                </Link>
                
            </div>
        </div>
    )
}

export default TypeSite