import React, {useState} from 'react';
import styles from './infoIconBlock.module.css';
import { checkProp, getIdentifierType, isMongo } from '../../../../../utils/check';

const InfoModal = ({info, ...props}) => {
    const [modalInfo, setModalInfo] = useState(false)
    const [svg, setSvg] = useState(styles.svgIcon)

    if (modalInfo) {
        document.body.style.overflow = 'hidden'
    } else { document.body.style.overflow = 'auto'}

    function infoToggle() {
        if (!modalInfo) {
            setModalInfo(!modalInfo)
            setSvg(styles.svgIconActive)
        } else {
            setModalInfo(!modalInfo)
            setSvg(styles.svgIcon)
        }
    }

    function getContent() {
        return (<>{
                info ?
                <>
                {checkProp(info.contactPoint, 'name') ? <p>Контактна особа: {isMongo(info.contactPoint.name)}</p> : null}
                {checkProp(info.contactPoint, 'telephone') ?
                    <p>{info.contactPoint.telephone},
                        {checkProp(info.contactPoint, 'email') ?
                            <a href={`mailto:${info.contactPoint.email}`}>
                                {` ${info.contactPoint.email}`}
                            </a> : null
                        }
                    </p>
                    : null
                }
                {checkProp(info.address, 'region') ? <p>Область: {isMongo(info.address.region)}</p> : null}
                {checkProp(info.identifier, 'scheme') ? <p>{getIdentifierType(info.identifier.scheme)}: {info.identifier.id}</p> : null}
            </> : null
        }</>)
    }

    return (
            <div className={styles.infoModal}>
                <svg
                    className={styles.svgInfo}
                    onClick={infoToggle}
                    width="16" height="16" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path className={svg} d="M8.66675 11.1667L8.66675 7.83341C8.66675 7.46522 8.36827 7.16675 8.00008 7.16675C7.63189 7.16675 7.33342 7.46522 7.33342 7.83341L7.33342 11.1667C7.33342 11.5349 7.63189 11.8334 8.00008 11.8334C8.36827 11.8334 8.66675 11.5349 8.66675 11.1667Z" fill="#92929D"/>
                        <path className={svg} d="M7.33342 5.83341C7.33342 5.46522 7.63189 5.16675 8.00008 5.16675C8.36827 5.16675 8.66675 5.46522 8.66675 5.83341C8.66675 6.20161 8.36827 6.50008 8.00008 6.50008C7.63189 6.50008 7.33342 6.20161 7.33342 5.83341Z" fill="#92929D"/>
                        <path className={svg} fillRule="evenodd" clipRule="evenodd" d="M1.33342 8.50008C1.33342 4.81818 4.31818 1.83342 8.00008 1.83342C11.682 1.83342 14.6667 4.81818 14.6667 8.50008C14.6667 12.182 11.682 15.1667 8.00008 15.1667C4.31818 15.1667 1.33342 12.182 1.33342 8.50008ZM13.3334 8.50008C13.3334 5.55456 10.9456 3.16675 8.00008 3.16675C5.05456 3.16675 2.66675 5.55456 2.66675 8.50008C2.66675 11.4456 5.05456 13.8334 8.00008 13.8334C10.9456 13.8334 13.3334 11.4456 13.3334 8.50008Z" fill="#92929D"/>
                </svg>
            {modalInfo ?
                <>
                    <div className={styles.wrapperInfo} onClick={infoToggle}></div>
                    <div className={styles.infoBlock}>
                    {getContent()}
                    {props.infodata ? props.infodata : null}
                        <div className={styles.bottomBtnBlock}>
                            <div className={styles.ondistandBtn}
                                onClick={infoToggle}
                            >Зрозуміло</div>
                        </div>
                    </div>
                </> : null
            }
            </div>
    )
}

export default InfoModal