import React, { useEffect } from 'react';
import styles from './authUser.module.css';
import { useSelector, useDispatch } from 'react-redux';
import { useAuth } from '../../../../hook/useAuth';
import { useNavigate } from 'react-router-dom';

import SvgAuthUser from './authUser.png';

import { userProfileGet } from '../../../../redux/actions/oldSiteUsers/oldSiteUsers.js';
import { getShorter, isMongo } from '../../../../utils/check';

const AuthUser = () => {
    
    const dispatch = useDispatch();
    const token = useSelector(state => state.start.token);
    const userProfiles = useSelector(state => state.start.userProfiles);
    const navigate = useNavigate()
    const { signin } = useAuth()

    useEffect(() => {
        if(!userProfiles) {
            let data = {
              domen: process.env.REACT_APP_END_POINT_BD,
              version: 'v1',
              local: 'uk',
            }
            dispatch( userProfileGet(data, token.access_token) );
            // if(!!userProfiles) signin(userProfiles, ()=> navigate('/', {replace: true}));
        }
    }, []);

    //     useEffect(() => {
    //     if(!!userProfiles) {
    //         if ( !userProfiles?.type ) {
    //             let tmpType = ''
    //             if ( userProfiles.canCreateAnnouncements || 
    //                 userProfiles.canCreateAssets || 
    //                 userProfiles.canCreateExecutions || 
    //                 userProfiles.canCreateRedemptions ){
    //                     tmpType = 'asset'
    //             }
    //             if ( userProfiles.canCreateActions || 
    //                 userProfiles.canCreateObjects || 
    //                 userProfiles.canCreateRequests ){
    //                     tmpType = 'lease'
    //             }
    //             let body = {...userProfiles, type: tmpType }
    //             dispatch({ type: 'USER_PROFILES_GET', payload: body })
    //         }
    //     }
    // }, [ userProfiles ])


    let userOrgName = 'Дані відсутні';
    let oldUserId = 'Дані відсутні';
    if( !userProfiles ) {
        return ( 
            <div className={styles.wrapFaq}>
                <div className={styles.wrap_link}>
                    <div className={styles.korusne_img}>
                        <img className={styles.imgAuthUser} src={SvgAuthUser} />
                        <div className={styles.korusne_title}>Дані відсутні</div>
                    </div>
                    <div className={styles.korusne_text}>Ваш Id: Дані відсутні</div>
                </div>
            </div>
        )
    } else {
        if( !!userProfiles.organization ) {
            userOrgName = isMongo( userProfiles.organization.name )
            oldUserId = userProfiles.oldUserId
        }
        return ( 
            <div className={styles.wrapFaq} >
                <div className={styles.wrap_link}>
                    <div className={styles.korusne_img}>
                        <img className={styles.imgAuthUser} src={SvgAuthUser} />
                        <div className={styles.korusne_title}>{ getShorter( userOrgName , 40 ) }</div>
                    </div>
                    <div className={styles.korusne_text}>Ваш Id: {oldUserId}</div>
                </div>
            </div>
        )
    }
}


export default AuthUser;