import React from 'react';
import styles from './deletePopup.module.css';
import {useSelector, useDispatch} from 'react-redux';

import { objectDelete, setObjectDeletePopup } from '../../../../redux/actions/object/object';
import { myDraftObject, myObjectPrivate } from '../../../../utils/endPoints';

const DeletePopup = (props) => {

    const dispatch = useDispatch()
    const token = useSelector(state => state.start.token)

    function deleteDraftObject() {

        const url =( id)=> {
            if (id.indexOf('-') !== -1) return myDraftObject(id)
            else return myObjectPrivate(id)
        }

        let redirectUrl = `/lease/my_objects`
        dispatch( objectDelete( url(props.id), token.access_token, redirectUrl) );
    }

    return (
        <div className={styles.backdrop}>
            <div className={styles.wrapper}>
                <button className={styles.buttonClose} onClick={() => dispatch(setObjectDeletePopup(false))}></button>
                <div className={styles.title}>Видалення об’єкта</div>
                <div className={styles.text}>Ви впевені, що хочете видалити об’єкт?</div>
                <div className={styles.buttonWrapper}>
                    <button className={styles.buttonCancel} onClick={() => dispatch(setObjectDeletePopup(false))}>Скасувати</button>
                    <button className={styles.buttonDelete} onClick={() => deleteDraftObject()}>Підтвердити</button>
                </div>
            </div>
        </div>
    )
}

export default DeletePopup;