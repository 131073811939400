import React, {useState, useEffect} from 'react';
import Button from '../../../../elements/buttons/button';
import Input from '../../../../inputs/input';
import styles from './buttonFilters.module.css';
import InputDate from '../../../../inputs/inputDate';
import { checkProp } from '../../../../../utils/check';

    const Popup =({ setParams, params, setPopup })=> {
        const [title, setTitle] = useState( checkProp( params, 'title' ) ? params.title : null )
        const [contractNumber, setСontractNumber] = useState( checkProp( params, 'contractNumber' ) ? params.contractNumber : null )
        const [datePublishedFrom, setDatePublishedFrom] = useState( !!params?.datePublishedFrom ? params.datePublishedFrom : null )
        const [datePublishedTo, setDatePublishedTo] = useState( !!params?.datePublishedTo ? params.datePublishedTo : null )

        return (
                    <div className={styles.wrapMain}>
                        <div className={styles.wrap}>
                            <h3 className={styles.filterTitle}>Розширений пошук</h3>
                            <div className={styles.content}>
                                <Input 
                                    label='Пошук назві договору'
                                    value={ title }
                                    onChange={ e => setTitle(e) }
                                />
                                <Input 
                                    label='Пошук по номеру договору'
                                    value={ contractNumber }
                                    onChange={ e => setСontractNumber(e) }
                                />
                                <InputDate
                                    data={{
                                        label: 'Дата публікації, з',
                                        value: datePublishedFrom,
                                        onChange: e => setDatePublishedFrom( e ),
                                        // time: true
                                    }}
                                />
                                <InputDate
                                    data={{
                                        label: 'Дата публікації, по',
                                        value: datePublishedTo,
                                        onChange: e => setDatePublishedTo( e ),
                                        // time: true
                                    }}
                                />
                            </div>
                            <div className={styles.bottomPart}>
                                {/* <div className={styles.filterReset}
                                     onClick={() => {
                                        setParams( prev => ({...prev,
                                            leaseRequestId: null,
                                            description: null,
                                            relatedObjectId: null,
                                            datePublishedFrom: null,
                                            datePublishedTo: null,
                                            }
                                        ) )
                                    }}
                                >Очистити
                                </div> 
                                */}
                                <Button 
                                    color='gray'
                                    onClick={() => setPopup( prev => !prev) }>
                                    Закрити
                                </Button>
                                <Button
                                    color='blue'
                                    onClick={() => {
                                        setParams( prev => ({...prev,
                                            title: title,
                                            contractNumber: contractNumber,
                                            datePublishedFrom: datePublishedFrom,
                                            datePublishedTo: datePublishedTo,
    
                                        }
                                        ) )
                                        setPopup( prev => !prev )
                                    }}>
                                    Застосувати
                                </Button>
                            </div>
                        </div>
                    </div>
                )
    }

const LeaseContractsAdvancedSearch = ({ setParams, params })=> {
    const [popup, setPopup] = useState( false )

    return (
        <>
            <div
                className={styles.searchFilterBlock_status}
                onClick={ () => setPopup( prev => !prev) }
            >
                <div>Пошук</div>
                {/* { ( !!arr?.status && arr.status.length > 0 )&&  */}
                    <div style={{borderLeft: '1px dotted #BFBFC6', paddingLeft: '5px', marginLeft: '5px'}}>
                        {/* { arr.status.length } */}
                    </div> 
                
            </div>
            { popup && <Popup setParams={ setParams } params={ params } setPopup={ setPopup } /> }
        </>
    )
}

export default LeaseContractsAdvancedSearch
