import React,{useEffect, useState} from 'react';
import styles from './editSidebar.module.css';

import { Link } from 'react-router-dom';

// import Button from '../../../../../elements/buttons/button';
import { useNavigate } from 'react-router-dom';

// import { useSelector } from 'react-redux';

import { checkProp } from '../../../../../../utils/check';
import { getLeaseRequestType } from '../../../../../../utils/requests/requestsUtils';
// import { setExecutionStatus } from '../../../utils';


const LeaseRequestEditSidebar = ({ data, object }) => {

    // if( !data && !create ) return null

    return (
        <div className={styles.sideBlock}>
            { checkProp(data, 'type') 
                &&
                <div className={styles.objectType}> { getLeaseRequestType(data.type) } </div>
            }
            <div className={styles.aucStatus}>
                <p>Статус</p>
                <div className={styles.lotStatus}>{ `ЧЕРНЕТКА` }</div>
            </div>
            <div className={styles.aucStatus}>
                <p>Пов'язаний об'єкт</p>
                <div className={styles.lotStatus}>
                    <Link to={ `/lease/object/${ !!object && object.id }` }>
                        { !!object && object.registryObjectId }
                    </Link>
                </div>
            </div>
            {/* <div className={styles.auctionSteps}>
            </div> */}
        </div>
    );
}

export default LeaseRequestEditSidebar;