import React from 'react';
import styles from './elems.module.css';
import {  getAssetStatus, setAnnouncementStatus, setAuctionAssetStatus, getRedemptionStatus } from '../../../../../utils/check';
import HalfInfoBlock from '../../../objectItemPage/blocks/halfInfoBlock/halfInfoBlock';
import Loader from '../../../../loader/loader';
import { setExecutionStatus } from '../../../execution/utils';

const ExecutionRelatedEntities = ( { entitiesArr } ) => {

    const ListItem =({ data, type })=> {
        return (
          <div className={styles.listEntitiesItemWrap}>
              <div style={{flex: 1, display: 'flex', flexFlow: 'row nowrap', width: '100%'}}>
                <HalfInfoBlock 
                    title={ getName( type ) }
                    data={ data.assetId ? data.assetId : data.objectId ? data.objectId : data.auctionId } 
                    link={ getLink(data, type) }
                />
                <HalfInfoBlock 
                    title={ 'Статус:' }
                    data={ getStatus(type, data.status) }
                />
              </div>
          </div>
        )
    }

    function getStatus( type, status){
        switch( type ){
            case 'announcement': return setAnnouncementStatus(status)
            case 'large_announcement': return setAnnouncementStatus(status)
            case 'asset': return getAssetStatus(status)
            case 'large_asset': return getAssetStatus(status)
            case 'procedure': return setAuctionAssetStatus(status)
            case 'execution': return setExecutionStatus(status)
            case 'large_execution': return setExecutionStatus(status)
            case 'redemption': return getRedemptionStatus(status)
            case 'large_redemption': return getRedemptionStatus(status)
            default: return null
        }
    }

    function getLink( data, type ){
        switch(type){
            case 'announcement': return `/asset/announcement/${data.objectId}`
            case 'large_announcement': return `/asset/announcement/${data.objectId}`
            case 'asset': return `/asset/${data.assetId}`
            case 'large_asset': return `/asset/${data.assetId}`
            case 'procedure': return `${process.env.REACT_APP_END_POINT_DTO}/auctions/${data.auctionId}`
            case 'execution': return `/asset/execution/${data.executionId}`
            case 'large_execution': return `/asset/execution/${data.executionId}`
            case 'redemption': return `/asset/redemption/${data.objectId}`
            case 'large_redemption': return `/asset/redemption/${data.objectId}`
            default: return null
        }
    }

    function getName( type ){
        switch(type){
            case 'announcement': return `Інформаційне повідомлення`
            case 'large_announcement': return `Інформаційне повідомлення`
            case 'asset': return `Об'єкт МП`
            case 'large_asset': return `Об'єкт ВП`
            case 'procedure': return `Аукціон`
            case 'execution': return `Контрактинг`
            case 'large_execution': return `Контрактинг`
            case 'redemption': return 'Викуп'
            case 'large_redemption': return 'Викуп'
            default: return type
        }
    }

    return (
                <div className={styles.infoBlockContent}>
                    { !entitiesArr && <Loader small={true} /> }
                    <div className={styles.relatedEntitiesList}>
                    { !!entitiesArr && entitiesArr
                            // .filter((i) => {
                            //     return i.type !== "procedure"
                            // })
                            .map( obj => {
                                if ( !!obj?.data ) return <ListItem key={obj.data.id} data={obj.data} type={obj.type} /> 
                                else return null
                            }
                        )
                    }
                    </div>
                </div>
        )
}

export default ExecutionRelatedEntities