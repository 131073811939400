import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FullInfoBlock from '../../../../../../elements/fullInfoBlock/fullInfoBlock';
import HalfInfoBlock from '../../../../../../elements/halfInfoBlock/halfInfoBlock';
import PopDownBlock from '../../../../../../elements/popdownBlock/popDownBlock';
import { checkProp, dictionaryGet, getTranslate } from '../../../../../../../utils/check';

const ObjectItemPropsView =({ item })=> {

    const dispatch = useDispatch();
    const objectFuelTypeList = useSelector(state => state.start.objectFuelTypeList);
    const objectTransmissionTypeList = useSelector(state => state.start.objectTransmissionTypeList);
    const objectOwnershipTypeList = useSelector(state => state.start.objectOwnershipTypeList);
    const objectEncumbrancesList = useSelector(state => state.start.objectEncumbrancesList);
    const objectConstructionTechnologyList = useSelector(state => state.start.objectConstructionTechnologyList);
    const floorTypeList = useSelector(state => state.start.objectFloorTypeList)
    const objectPowerSupplyClassList = useSelector(state => state.start.objectPowerSupplyClassList)

    useEffect( () => {
        if ( objectOwnershipTypeList === null ) {
            dispatch( dictionaryGet('ownershipType', 'OBJECT_GET_OWNERSHIP_TYPE') );
        }
        if ( objectFuelTypeList === null ) {
            dispatch( dictionaryGet('fuelType', 'OBJECT_GET_FUEL_TYPE_LIST') );
        }
        if ( objectTransmissionTypeList === null ) {
            dispatch( dictionaryGet('transmissionType', 'OBJECT_GET_TRANSMISSION_TYPE_LIST') );
        }
        if ( objectEncumbrancesList === null ) {
            dispatch( dictionaryGet('encumbrances', 'OBJECT_GET_ENCUMBRANCES_LIST') );
        }
        if ( objectConstructionTechnologyList === null ) {
            dispatch( dictionaryGet('constructionTechnology', 'OBJECT_GET_CONSTRUCTION_TECHNOLOGY_LIST') );
        }
        if ( floorTypeList === null ) {
            dispatch( dictionaryGet('floorType', 'OBJECT_GET_FLOOR_TYPE_LIST') );
        }
        if ( objectPowerSupplyClassList === null ) {
            dispatch( dictionaryGet('powerSupplyClass', 'OBJECT_GET_POWER_SUPPLY_CLASS_LIST') );
        }
    }, []);

    // let titleSpecs = ''

    const vehicleProps =( item )=> {
        if ( !!item?.vehicleProps ){
            return (
                <>
                    { !!item.vehicleProps.brand  &&
                        <HalfInfoBlock title='Марка транспортного засобу' data={ item.vehicleProps.brand} />
                    }
                    { !!item.vehicleProps.model  &&
                        <HalfInfoBlock title='Модель транспортного засобу' data={ item.vehicleProps.model} />
                    }
                    { !!item.vehicleProps.condition  &&
                        <FullInfoBlock title='Технічний стан' data={ item.vehicleProps.condition} />
                    }
                    { !!item.vehicleProps.identificationNumber  &&
                        <HalfInfoBlock title='Номер кузова або шасі' data={ item.vehicleProps.identificationNumber} />
                    }
                    { !!item.vehicleProps.productionYear  &&
                        <HalfInfoBlock title='Рік випуску' data={ item.vehicleProps.productionYear} />
                    }
                    { !!item.vehicleProps.engineCapacity &&
                        <HalfInfoBlock title='Об’єм двигуна' data={`${ item.vehicleProps.engineCapacity} см³`} />
                    }
                    { !!item.vehicleProps.fuelType  &&
                        <HalfInfoBlock title='Тип палива' data={ getTranslate( item.vehicleProps.fuelType ,objectFuelTypeList ) } />
                    }
                    { !!item.vehicleProps.transmission  &&
                        <HalfInfoBlock title='Тип коробки передач' data={ getTranslate( item.vehicleProps.transmission, objectTransmissionTypeList ) } />
                    }
                    { !!item.vehicleProps.kilometrage &&
                        <HalfInfoBlock title='Пробіг' data={`${ item.vehicleProps.kilometrage} км`} />
                    }
                    { !!item.vehicleProps.configuration  &&
                        <HalfInfoBlock title='Комплектація' data={ item.vehicleProps.configuration} />
                    }
                    { !!item.vehicleProps.color  &&
                        <HalfInfoBlock title='Колір' data={ item.vehicleProps.color} />
                    }
                    { !!item.vehicleProps.damagePresence && 
                        <>
                            <HalfInfoBlock title='Потреба у ремонті' data="Присутня" />
                            { !!item.vehicleProps.damagedDescription  &&
                                <FullInfoBlock title='Опис пошкоджень' data={ item.vehicleProps.damagedDescription} />
                            }
                        </>
                    }
                </>)
        } else { return null }
    }

    const realEstateProps =( item )=> {
        if ( !!item?.reProps ){ 
            return (
                <>
                { checkProp(item.reProps,'landArea') &&
                    <HalfInfoBlock title='Площа ділянки' data={`${ item.reProps.landArea} кв.м.`} />
                }
                { checkProp(item.reProps,'constructionYear') &&
                    <HalfInfoBlock title='Рік будівництва' data={ item.reProps.constructionYear} />
                }
                { checkProp(item.reProps,'generalCondition') &&
                    <FullInfoBlock title='Технічний стан об`єкта оренди' data={ item.reProps.generalCondition} />
                }
                { checkProp(item.reProps, 'constructionTechnology') &&
                        <HalfInfoBlock
                            title='Технологія будівництва'
                            data={ getTranslate(item.reProps.constructionTechnology,objectConstructionTechnologyList ) }
                        />
                }
                { checkProp(item.reProps,'totalBuildingArea') &&
                    <HalfInfoBlock title='Загальна площа будівлі, до складу якої входить об`єкт оренди' data={`${ item.reProps.totalBuildingArea} кв.м.`} />
                }
                { checkProp(item.reProps,'totalObjectArea') &&
                    <HalfInfoBlock title='Загальна площа об`єкту оренди *' data={`${ item.reProps.totalObjectArea} кв.м.`} />
                }
                { checkProp(item.reProps,'usableArea') &&
                    <HalfInfoBlock title='Корисна площа об`єкту оренди' data={`${ item.reProps.usableArea} кв.м.`} />
                }
                { checkProp(item.reProps,'livingArea') &&
                    <HalfInfoBlock title='Житлова площа' data={`${ item.reProps.livingArea} кв.м.`} />
                }
                { checkProp(item.reProps,'kitchenArea') &&
                    <HalfInfoBlock title='Площа кухні' data={ item.reProps.kitchenArea} />
                }
                { checkProp(item.reProps,'locationInBuilding') &&
                    <HalfInfoBlock title='Місце розташування об’єкта в будівлі' data={ getTranslate( item.reProps.locationInBuilding, floorTypeList )} />
                }
                { checkProp(item.reProps,'floors') &&
                    <HalfInfoBlock title='Номер поверху або поверхів' data={ item.reProps.floors} />
                }
                { checkProp(item.reProps,'powerSupplyCapacity') &&
                    <HalfInfoBlock title='Чи приєднаний об`єкт оренди до електромережі' data='Так' />
                }
                { checkProp(item.reProps,'powerSupplyCapacity') &&
                        <HalfInfoBlock title='Потужність електромережі' data={`${ item.reProps.powerSupplyCapacity} кВт`} />
                }
                { checkProp(item.reProps,'powerSupplyClass') ?
                        <HalfInfoBlock title='Ступінь потужності електромережі' data={  getTranslate(item.reProps.powerSupplyClass,objectPowerSupplyClassList) } />
                : <HalfInfoBlock title='Чи приєднаний об`єкт оренди до електромережі' data='Ні' />
                }

                <HalfInfoBlock title='Водозабезпечення присутнє' data={ item.reProps.serviceWater ? 'Так' : 'Ні'} />
                <HalfInfoBlock title='Каналізація присутня' data={ item.reProps.serviceSewerage ? 'Так' : 'Ні'} />
                <HalfInfoBlock title='Газифікація присутня' data={ item.reProps.serviceGas ? 'Так' : 'Ні'} />
                <HalfInfoBlock title='Централізоване опалення присутнє' data={ item.reProps.serviceCentralHeating ? 'Так' : 'Ні'} />
                <HalfInfoBlock title='Автономне опалення присутнє' data={ item.reProps.serviceAutonomousHeating ? 'Так' : 'Ні'} />
                <HalfInfoBlock title='Лічильник опалення присутній' data={ item.reProps.serviceHeatingCounter ? 'Так' : 'Ні'} />
                <HalfInfoBlock title='Вентиляція присутня' data={ item.reProps.serviceVentilation ? 'Так' : 'Ні'} />
                <HalfInfoBlock title='Кондиціонування присутнє' data={ item.reProps.serviceAirConditioning ? 'Так' : 'Ні'} />
                <HalfInfoBlock title='Телефонізація присутня' data={ item.reProps.servicePhone ? 'Так' : 'Ні'} />
                <HalfInfoBlock title='Телебачення присутнє' data={ item.reProps.serviceTV ? 'Так' : 'Ні'} />
                <HalfInfoBlock title='Інтернет присутній' data={ item.reProps.serviceInternet ? 'Так' : 'Ні'} />
                <HalfInfoBlock title='Ліфт присутній' data={ item.reProps.serviceElevator ? 'Так' : 'Ні'} />
                <HalfInfoBlock title='Охоронна сигналізація присутня' data={ item.reProps.serviceSecurityAlarm ? 'Так' : 'Ні'} />
                <HalfInfoBlock title='Пожежна сигналізація присутня' data={ item.reProps.serviceFireAlarm ? 'Так' : 'Ні'} />
                { checkProp(item.reProps,'servicesDescription') &&
                        <FullInfoBlock
                            title='Додаткова інформація щодо комунікацій, що є в об`єкті'
                            data={ item.reProps.servicesDescription} 
                        />
                }
                { checkProp(item.reProps,'servicesAccounting') &&
                        <FullInfoBlock
                            title='Інформація про наявність окремих особових рахунків на об`єкт оренди, відкритих постачальниками комунальних послуг, або інформація про порядок участі орендаря у компенсації балансоутримувачу витрат на оплату комунальних послуг'
                            data={ item.reProps.servicesAccounting} 
                        />
                }
                { checkProp(item.reProps,'landTax') &&
                        <FullInfoBlock title='Інформація щодо компенсації балансоутримувачу сплати земельного податку за користування земельною ділянкою, на якій розташований об`єкт оренди'
                            data={ item.reProps.landTax} 
                        />
                }
            </>
                )
        } else { return null }
    }

    function hasTrueOrFalse(data){
        if(data) return "Наявні"
        return "Відсутні"
    }

    const jointPropertyComplexProps =( item )=> {
        if ( !!item?.jpcProps){
            return (
                <>
                    { checkProp(item.jpcProps,'ownershipType') &&
                        <HalfInfoBlock title='Тип власності' data={ getTranslate( item.jpcProps.landProps.ownershipType, objectOwnershipTypeList )} />
                    }
                    { checkProp(item.jpcProps.landProps,'landArea') &&
                        <HalfInfoBlock title='Площа ділянки' data={`${ item.jpcProps.landProps.landArea} га`} />
                    }
                    { checkProp(item.jpcProps.landProps,'cadastralNumber') &&
                        <HalfInfoBlock title='Кадастровий номер' data={ item.jpcProps.landProps.cadastralNumber} />
                    }

                    { checkProp(item.jpcProps.landProps,'hasEncumbrances') &&
                        <HalfInfoBlock title='Наявність обтяжень' data={hasTrueOrFalse(item.jpcProps.landProps.hasEncumbrances)} />
                    }
                    { checkProp(item.jpcProps.landProps,'encumbrances') &&
                        <HalfInfoBlock title='Інформація про наявність обтяжень' data={ getTranslate( item.jpcProps.landProps.encumbrances, objectEncumbrancesList )} />
                    }
                    { checkProp(item.jpcProps.landProps,'hasJointOwnership') &&
                        <HalfInfoBlock title='Наявність співвласників' data={hasTrueOrFalse(item.jpcProps.landProps.hasJointOwnership)} />
                    }
                    { checkProp(item.jpcProps.landProps,'jointOwnership') &&
                        <HalfInfoBlock title='Інформація про співвласників' data={ item.jpcProps.landProps.jointOwnership} />
                    }
                    { checkProp(item.jpcProps.landProps,'hasUtilitiesAvailability') &&
                        <HalfInfoBlock title='Наявність інженерних мереж' data={hasTrueOrFalse(item.jpcProps.landProps.hasUtilitiesAvailability)} />
                    }
                    { checkProp(item.jpcProps.landProps,'utilitiesAvailability') &&
                        <HalfInfoBlock title='Інформація про інженерні мережі' data={ item.jpcProps.landProps.utilitiesAvailability} />
                    }
                    { checkProp(item.jpcProps.landProps,'landIntendedUse') &&
                        <HalfInfoBlock title='Поточне використання земельної ділянки' data={ item.jpcProps.landProps.landIntendedUse} />
                    }
                    { checkProp(item.jpcProps,'quantityAndNomenclature') &&
                        <FullInfoBlock title='Обсяг та основна номенклатура продукції (робіт, послуг), у тому числі експортної' data={ item.jpcProps.quantityAndNomenclature} />
                    }
                    { checkProp(item.jpcProps,'workplacesQuantity') &&
                        <HalfInfoBlock title='Кількість робочих місць' data={`${ item.jpcProps.workplacesQuantity} осіб`} />
                    }
                    { checkProp(item.jpcProps,'buildingsInformation') &&
                        <FullInfoBlock title='Відомості про будівлі (споруди, приміщення) єдиного майнового комплексу в обсязі, передбаченому для інформації про нерухоме майно' data={ item.jpcProps.buildingsInformation} />
                    }
                    { checkProp(item.jpcProps,'landPlotInformation') &&
                        <FullInfoBlock title='Відомості про земельну ділянку, на якій розташовано єдиний майновий комплекс' data={ item.jpcProps.landPlotInformation} />
                    }
                    { checkProp(item.jpcProps,'mainObligations') &&
                        <FullInfoBlock title='Основні зобов’язання (договірні та позадоговірні)' data={ item.jpcProps.mainObligations} />
                    }
                    { checkProp(item.jpcProps,'leaseApproverWebsite') &&
                        <FullInfoBlock title='Посилання на веб-сторінку орендодавця, на якій розміщена додаткова інформація про єдиний майновий комплекс' data={ item.jpcProps.leaseApproverWebsite} />
                    }
                </>
            )
        } else { return null }
    }

    function titleSwitcher( item ){
        switch( true ){
            case !!item?.vehicleProps: return 'Технічні характеристики транспортного засобу'
            case !!item?.reProps: return 'Технічні характеристики нерухомості'
            case !!item?.jpcProps: return 'Технічні характеристики єдиного майнового комплексу'
            // case 'land': return 'Технічні характеристики земельної ділянки'
            default: return null
        }
    }

    // function propsSwitcher(type){
    //     switch(type){
    //         case 'vehicle': return vehicleProps(item.vehicleProps)
    //         case 'realEstate': return realEstateProps(item.reProps)
    //         case 'jointPropertyComplex': return jointPropertyComplexProps(item.jpcProps)
    //         // case 'land': return landProps(item.landProps)
    //         default: return null
    //     }
    // }

    if ( item.itemType === 'otherProperty' ) return null

    return (
        <PopDownBlock 
            title={ titleSwitcher( item ) }
            content={ 
                <>
                { vehicleProps(item) }
                { realEstateProps(item) }
                { jointPropertyComplexProps(item) }

                </>
             }
        />
    )
}

export default ObjectItemPropsView