import React,{useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '../../../../../elements/buttons/button';
import styles from './assetDocuments.module.css';
import CreateDocItem from '../../../../../elements/documents/docItem/documentItem';
import AddDocPopup from '../../../../../elements/documents/addDocPopup/addDocPopup';
import { postAssetDraftDocument, patchAssetDraftDocument, patchAssetDocument, postAssetDocument } from '../../../../../../utils/endPoints';
import { setAssetDocTypes, setAssetDocTypesLP } from './utils';
import { assetDocDelete } from '../../../../../../redux/asset/assetActions';
import { isDraftObject } from '../../../../../../utils/check';

const AssetDocuments =({ data, type })=> {
    const [addPopup, setAddPopup] = useState(false)
    const assetItem = useSelector(state => state.asset.assetItem)
    const token = useSelector( state => state.start.token )
    const dispatch = useDispatch()

    const docProps = {
        dispatch: ( res )=> dispatch( { payload: res, type: 'ASSET_GET_ID'}),
        //docOf: 'asset',
        docOf: type,
        object: assetItem,
        docList: data.decisions[0].decisionOf === "large_asset" ? setAssetDocTypesLP( assetItem.assetItems, assetItem.status ) : setAssetDocTypes( assetItem.assetItems, assetItem.status ),
        url: {
            patch: isDraftObject(assetItem.id) ? patchAssetDraftDocument : patchAssetDocument,
            post: isDraftObject(assetItem.id) ? postAssetDraftDocument : postAssetDocument,
            delete: ( id ) => dispatch( assetDocDelete( assetItem.id, id, token.access_token ) )
        }
    }

    if ( !data.documents ) return (
        <div className={styles.blockWrap}>
            { addPopup && <AddDocPopup close={ ()=> setAddPopup( false )} docProps={docProps} /> }
        <div className={styles.infoBlock}>
                <div className={styles.docContentWrap}>
                    <div className={styles.empty}>Наразі ще немає доданих документів</div>
                    <div className={styles.addButtonBlock}>
                        <Button onClick={ ()=> setAddPopup( prev => !prev )}>Додати документ</Button>
                    </div>
                </div>
            </div>
        </div>
    )

    return (
        <div className={styles.blockWrap}>
            { addPopup && <AddDocPopup asset={ assetItem } close={ ()=> setAddPopup( false )} docProps={docProps} /> }
            <div className={styles.infoBlock}>
                <div className={styles.docContentWrap}>
                    { data.documents.length === 0 && <div className={styles.empty}>Наразі ще немає доданих документів</div> }
                    { data.documents.length > 0 && <div className={styles.title}>Додані документи</div> }
                    <div className={styles.docList}>
                        { data.documents.map( i => <CreateDocItem data={i} key={i.id} docProps={docProps} /> ) }
                    </div>
                    <div className={styles.addButtonBlock}>
                        <Button onClick={ ()=> setAddPopup( prev => !prev )}>Додати документ</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AssetDocuments