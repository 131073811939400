//TODO: Почистить файл от лишних подключенных функций, подключить свой цсс и почистить его
import React, {useState, useEffect}  from 'react';
import styles from '../search.module.css';

const Bage = ({ title, action }) => {

    return (
        <div
            className={ styles.badgeElem }
            title={ title }
        >
            <div className={styles.badgeElem_title}>
                { title }
            </div>
            <div className={styles.closeSvg}
                onClick={ action }>
                <svg className={styles.svgIcon} width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path className={styles.svgIcon} fillRule="evenodd" clipRule="evenodd" d="M5.94074 5.00037L9.45879 8.5216C9.71902 8.78207 9.71883 9.20418 9.45837 9.46441C9.1979 9.72464 8.77579 9.72445 8.51556 9.46398L4.99723 5.94247L1.47073 9.46374C1.21019 9.7239 0.788076 9.72358 0.52792 9.46304C0.267764 9.2025 0.268078 8.78039 0.528621 8.52023L4.05485 4.99924L0.530156 1.47135C0.269925 1.21089 0.270115 0.788777 0.530583 0.528545C0.79105 0.268313 1.21316 0.268504 1.47339 0.528972L4.99836 4.05713L8.51563 0.545083C8.77618 0.284927 9.19829 0.285241 9.45844 0.545784C9.7186 0.806327 9.71828 1.22844 9.45774 1.48859L5.94074 5.00037Z" fill="#696974" />
                </svg>
            </div>
        </div>
    )

}

export default Bage