import React,{useEffect, useState} from 'react';
import styles from './assetCreate.module.css';
import { Link, useLocation } from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import AssetCreateTitle from './elems/titleBar/createTitleBarLP';
import AssetSideBarCreate from './sidebar/sideBarCreate';
import AssetLPGeneralCreate from './elems/generalCreateLp';
import { getMyAssetDraft, getMyAsset } from '../../../../utils/endPoints';
import { assetGetId } from '../../../../redux/asset/assetActions';
import Loader from '../../../loader/loader';
import AssetItemsCreate from './elems/items/assetItems';
import AssetDocuments from './elems/documents/assetDocuments';
import { isDraftObject } from '../../../../utils/check';
import { getRelatedEntities } from '../../announcement/utils';

const AssetLPCreate =( { create } )=> {
    const token = useSelector(state => state.start.token);
    // const userProfile = useSelector(state => state.start.userProfiles);
    const assetItem = useSelector(state => state.asset.assetItem)
    const dispatch = useDispatch()
    const { pathname } = useLocation()
    const { state: enableEdit } = useLocation()
    const [ entitiesArr, setEntitiesArr ] = useState([])

    useEffect( () => {
        if( !!assetItem && entitiesArr.length === 0 ) getRelatedEntities( assetItem.relatedEntities, setEntitiesArr )
    }, [])

    useEffect(() => {
      if ( !create ) {
        const id = pathname.split('/')[3]
        const url =( id )=> {
          if ( isDraftObject(id)) return getMyAssetDraft( id )
          if ( !isDraftObject(id)) return getMyAsset( id )
        }
        dispatch( assetGetId( url( id ), token.access_token))
      }
      return () => {
        dispatch({type: 'ASSET_GET_ID', payload: null})
      }
    }, [])

    function setGeneral(){
      if (create) return <AssetLPGeneralCreate data={ null } create={create} />
      if (!create && !!assetItem && pathname === `/asset/create/${assetItem.id}/items` ) return <AssetItemsCreate data={ assetItem } />
      if (!create && !!assetItem && pathname === `/asset/edit_lp/${assetItem.id}/items` ) return <AssetItemsCreate data={ assetItem } />
      if (!create && !!assetItem && pathname === `/asset/create/${assetItem.id}/documents` ) return <AssetDocuments data={ assetItem } type="large_asset"/>
      if (!create && !!assetItem && pathname === `/asset/edit_lp/${assetItem.id}/documents` ) return <AssetDocuments data={ assetItem } type="large_asset"/>
      if (!create && !!assetItem ) return <AssetLPGeneralCreate data={ assetItem } enableEdit={ enableEdit }/>
      else return <Loader />
    }

    if ( !!assetItem ) {
      if ( ( (assetItem.status === 'pending' || assetItem.status === 'active_rectification') || !assetItem.status || enableEdit )  ) {
        return (
            <div className={styles.wrap}>
                <AssetCreateTitle data={ assetItem } entitiesArr={ entitiesArr } />
                <div className={styles.container}>
                    <AssetSideBarCreate data={ assetItem } />
                    { setGeneral() }
                </div>
            </div>
        )
      }
      else {
        return <div className={styles.wrap}> Редагування об'єкту неможливо </div>
      }
    } else if ( create ) { 
      return (
        <div className={styles.wrap}>
            <AssetCreateTitle data={ null } />
            <div className={styles.container}>
                <AssetSideBarCreate data={ null } />
                { setGeneral() }
            </div>
        </div>
      )
    }
}

export default AssetLPCreate