//TODO: Почистить файл от лишних подключенных функций, подключить свой цсс и почистить его
import React from 'react';
import styles from './mainFind.module.css';

import {connect} from 'react-redux';

import {
    auctionsSearch,
    auctionsSearchTest,
    auctionsSearchСount,
    auctionsSearchOld,
    auctionsSearchСountOld,
    changeCurentPage,
    setSearchTextSting,
    setSearchSelectTypeKeyword,
    changeSearchDateStart,
    changeSearchDateEnd,
    changeStatusAuction,
    auctionsGetSearch,
    openVidjetStatusAuction,
    openVidjetOrganizatorAuctionArr,
    openVidjetOrganizatorAuction,
    openVidjetBuyersAuctionArr,
    openVidjetBuyersAuction,
    setArhStatusAuction,
    setArhStatusAuctionNew,
    openVidjetClassificationAuction,
    openVidjetSimpleFilterAuction,
    openVidjetClassificationAuctionArr,
    openVidjetSimpleFilterAuctionRegionProc,
    openVidjetSimpleFilterAuctionTypeSubjectProcurement,
    openVidjetSimpleFilterAuctionTypeSubjectProcurementNew,
    openVidjetSimpleFilterAuctionTypePayment,
    openVidjetSimpleFilterAuctionTypePaymentNew,
    openVidjetProcurementMethodTypeProc,
    openVidjetProcurementMethodTypeProcNew,
    openVidjetfilterValueAmountFrom,
    openVidjetfilterValueAmountFromNew,
    openVidjetfilterValueAmountTo,
    openVidjetfilterValueAmountToNew,
    openVidjetfilterEnquiryPeriodFrom,
    openVidjetfilterEnquiryPeriodTo,
    openVidjetfilterEnquiryPeriodOpen,
    openVidjetfilterAuctionPeriodFrom,
    openVidjetfilterAuctionPeriodTo,
    openVidjetfilterTenderPeriodFrom,
    openVidjetfilterTenderPeriodFromNew,
    openVidjetfilterTenderPeriodTo,
    openVidjetfilterTenderPeriodToNew,
    openVidjetfilterTenderPeriodOpen,
    openVidjetfilterSummFrom,
    openVidjetfilterSummFromNew,
    openVidjetfilterSummTo,
    openVidjetfilterSummToNew,
    openVidjetfilterSummOpen,
    openVidjetfilterPlansTenderPeriodFrom,
    openVidjetfilterPlansTenderPeriodFromNew,
    openVidjetfilterPlansTenderPeriodTo,
    openVidjetfilterPlansTenderPeriodToNew,
    openVidjetfilterPlansTenderPeriodOpen,
    openVidjetRozporyadnukKoshtivString,
    openVidjetRozporyadnukKoshtivArrGet,
    openVidjetRozporyadnukKoshtivArr,
    openVidjetRozporyadnukKoshtivArrNew,
    openVidjetDjereloFinArr,
    openVidjetDjereloFinArrNew,

    openVidjetSimpleFilterAuctionRegionProcNew,
    openVidjetfilterAuctionPeriodFromNew,
    openVidjetfilterAuctionPeriodToNew,
    openVidjetfilterEnquiryPeriodFromNew,
    openVidjetfilterEnquiryPeriodToNew,

    openVidjetClassificationAuctionArrGet,
    openVidjetClassificationAuctionArrNew,

    gmdnClassificationArrGet,
    gmdnClassificationArr,
    gmdnClassificationArrNew,
    dkClassificationArrGet,
    dkClassificationArr,
    dkClassificationArrNew,
} from '../../../../redux/actions/auctions/auctions';

import {changeMyCurentPage} from '../../../../redux/actions.js'

import {setAuctionCategory} from '../../../../redux/actions/support.js';

import {
    setCurrentUrl,
    setGetRequestParameterPaymentType,
    setGetRequestParameterTypeSubjectProcurement,
    setGetRequestParameterRegion,
    setGetRequestParameterProcuringEntity,
    setGetRequestParameterClassification,
    setGetRequestParameterStatus,
    setGetRequestParameterProcurementMethodType,
    setGetRequestValueAmountFrom,
    setGetRequestValueAmountTo,
    setGetRequestEnquiryPeriodFrom,
    setGetRequestEnquiryPeriodTo,
    setGetRequestAuctionPeriodFrom,
    setGetRequestAuctionPeriodTo,
    setGetRequestTenderPeriodFrom,
    setGetRequestTenderPeriodTo,
    setGetRequestValueSummFrom,
    setGetRequestValueSummTo,
    setGetRequestPlansTenderPeriodFrom,
    setGetRequestPlansTenderPeriodTo,
    setOrder,
    setGetRequestParameterRozporyadnukKoshtiv,
    setGetRequestParameterDjereloFin,
} from '../../../../redux/filters'

const BageKeywords = ( props ) => {
    return (viewBadgeKeywords(props))
}

function viewBadgeKeywords(props){
    if(props.search.textSting !== ''){
        return (
            <div
                className={styles.badgeElem}
                onClick={
                    () => {
                        let statusString = ''
                        // statusString += setGetRequestParameterStatus(props.search, true)
                        // statusString += setGetRequestParameterClassification(props.search, true)
                        // statusString += setGetRequestParameterProcuringEntity(props.search, true)
                        // statusString += setGetRequestParameterRegion(props.search, true)
                        // statusString += setGetRequestParameterTypeSubjectProcurement(props.search, true)
                        // statusString += setGetRequestParameterPaymentType(props.search, true)
                        // statusString += setGetRequestParameterProcurementMethodType(props.search, true)
                        // statusString += setGetRequestValueAmountFrom(props.search, true)
                        // statusString += setGetRequestValueAmountTo(props.search, true)
                        // statusString += setGetRequestEnquiryPeriodFrom(props.search, true)
                        // statusString += setGetRequestEnquiryPeriodTo(props.search, true)
                        // statusString += setGetRequestAuctionPeriodFrom(props.search, true)
                        // statusString += setGetRequestAuctionPeriodTo(props.search, true)
                        // statusString += setGetRequestTenderPeriodFrom(props.search, true)
                        // statusString += setGetRequestTenderPeriodTo(props.search, true)
                        // statusString += setGetRequestValueSummFrom(props.search, true)
                        // statusString += setGetRequestValueSummTo(props.search, true)
                        // statusString += setGetRequestPlansTenderPeriodFrom(props.search, true)
                        // statusString += setGetRequestPlansTenderPeriodTo(props.search, true)
                        // statusString += setOrder(props.search.sortListArr)
                        // statusString += setGetRequestParameterRozporyadnukKoshtiv(props.search, true)
                        // statusString += setGetRequestParameterDjereloFin(props.search, true)

                        let data = {}
                        let searchUrl = props.search.url
                        data.domen = process.env.REACT_APP_END_POINT_BD
                        data.version = 'v1'
                        data.local = props.langFlag
                        data.params = `?limit=10&offset=0`

                        if(props.search.textSting !== null){
                            if(props.search.textSting !== ''){
                                data.params += `&keyword=${props.search.textSting}`
                            }
                        }
                        
                        data.params += statusString
                        
                        if(data.params === ''){
                            data.params = `?page=${props.myPage}`
                        }
                        data.path = `${data.domen}/api/${data.version}/${data.local}/public/${searchUrl}/search`
                        let header = {
                            'Content-Type': 'application/json'
                        }
                        //props.auctionsGetMy(data, header)
                        window.scrollTo(0, 370)
                        //getPageSearch(props, searchData(props, tmp))
                        props.auctionsGetSearch(data, header)
                        props.changeMyCurentPage(1)
                        props.setSearchTextSting('')
                    }
                }
                title='Пошуковий запит'
            >
                <div className={styles.badgeElem_title}>{props.search.textSting}</div>
                <div>
                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M5.94074 5.00037L9.45879 8.5216C9.71902 8.78207 9.71883 9.20418 9.45837 9.46441C9.1979 9.72464 8.77579 9.72445 8.51556 9.46398L4.99723 5.94247L1.47073 9.46374C1.21019 9.7239 0.788076 9.72358 0.52792 9.46304C0.267764 9.2025 0.268078 8.78039 0.528621 8.52023L4.05485 4.99924L0.530156 1.47135C0.269925 1.21089 0.270115 0.788777 0.530583 0.528545C0.79105 0.268313 1.21316 0.268504 1.47339 0.528972L4.99836 4.05713L8.51563 0.545083C8.77618 0.284927 9.19829 0.285241 9.45844 0.545784C9.7186 0.806327 9.71828 1.22844 9.45774 1.48859L5.94074 5.00037Z" fill="#696974"/>
                    </svg>
                </div>
            </div>
        )
    }else{
        return null
    }
}

const mapStateToProps = state => {
    return {
        langFlag: state.start.langFlag,
        search: state.start.search,
        myPage: state.start.myPage,
    }
}

const mapDispatchToProps = {
    auctionsSearch,
    auctionsSearchTest,
    auctionsSearchСount,
    auctionsSearchOld,
    auctionsSearchСountOld,
    changeCurentPage,
    setSearchTextSting,
    setSearchSelectTypeKeyword,
    changeSearchDateStart,
    changeSearchDateEnd,
    changeStatusAuction,

    setAuctionCategory,
    auctionsGetSearch,
    openVidjetStatusAuction,
    openVidjetOrganizatorAuctionArr,
    openVidjetOrganizatorAuction,
    setArhStatusAuction,
    openVidjetBuyersAuctionArr,
    openVidjetBuyersAuction,
    setArhStatusAuction,
    setArhStatusAuctionNew,
    changeMyCurentPage,
    openVidjetClassificationAuction,
    openVidjetSimpleFilterAuction,
    openVidjetClassificationAuctionArr,
    openVidjetSimpleFilterAuctionRegionProc,
    openVidjetSimpleFilterAuctionTypeSubjectProcurement,
    openVidjetSimpleFilterAuctionTypeSubjectProcurementNew,
    openVidjetSimpleFilterAuctionTypePayment,
    openVidjetSimpleFilterAuctionTypePaymentNew,
    openVidjetProcurementMethodTypeProc,
    openVidjetProcurementMethodTypeProcNew,
    openVidjetfilterEnquiryPeriodFrom,
    openVidjetfilterEnquiryPeriodFromNew,
    openVidjetfilterEnquiryPeriodTo,
    openVidjetfilterEnquiryPeriodToNew,
    openVidjetfilterEnquiryPeriodOpen,
    openVidjetfilterAuctionPeriodFrom,
    openVidjetfilterAuctionPeriodTo,
    openVidjetfilterTenderPeriodFrom,
    openVidjetfilterTenderPeriodFromNew,
    openVidjetfilterTenderPeriodTo,
    openVidjetfilterTenderPeriodToNew,
    openVidjetfilterTenderPeriodOpen,
    openVidjetfilterSummFrom,
    openVidjetfilterSummFromNew,
    openVidjetfilterSummTo,
    openVidjetfilterSummToNew,
    openVidjetfilterSummOpen,
    openVidjetfilterPlansTenderPeriodFrom,
    openVidjetfilterPlansTenderPeriodFromNew,
    openVidjetfilterPlansTenderPeriodTo,
    openVidjetfilterPlansTenderPeriodToNew,
    openVidjetfilterPlansTenderPeriodOpen,
    openVidjetRozporyadnukKoshtivString,
    openVidjetRozporyadnukKoshtivArrGet,
    openVidjetRozporyadnukKoshtivArr,
    openVidjetRozporyadnukKoshtivArrNew,
    openVidjetDjereloFinArr,
    openVidjetDjereloFinArrNew,



    setCurrentUrl,
    setGetRequestParameterPaymentType,
    setGetRequestParameterTypeSubjectProcurement,
    setGetRequestParameterRegion,
    setGetRequestParameterProcuringEntity,
    setGetRequestParameterClassification,
    setGetRequestParameterStatus,
    setGetRequestParameterProcurementMethodType,
    setGetRequestValueAmountFrom,
    setGetRequestValueAmountTo,
    openVidjetfilterValueAmountFrom,
    openVidjetfilterValueAmountFromNew,
    openVidjetfilterValueAmountTo,
    openVidjetfilterValueAmountToNew,
    setGetRequestEnquiryPeriodFrom,
    setGetRequestEnquiryPeriodTo,
    setGetRequestAuctionPeriodFrom,
    setGetRequestAuctionPeriodTo,
    setGetRequestTenderPeriodFrom,
    setGetRequestTenderPeriodTo,
    setGetRequestValueSummFrom,
    setGetRequestValueSummTo,
    setGetRequestPlansTenderPeriodFrom,
    setGetRequestPlansTenderPeriodTo,
    setOrder,
    setGetRequestParameterRozporyadnukKoshtiv,
    setGetRequestParameterDjereloFin,

    openVidjetSimpleFilterAuctionRegionProcNew,
    openVidjetfilterAuctionPeriodFromNew,
    openVidjetfilterAuctionPeriodToNew,
    openVidjetfilterEnquiryPeriodFromNew,
    openVidjetfilterEnquiryPeriodToNew,

    openVidjetClassificationAuctionArrGet,
    openVidjetClassificationAuctionArrNew,

    gmdnClassificationArrGet,
    gmdnClassificationArr,
    gmdnClassificationArrNew,
    dkClassificationArrGet,
    dkClassificationArr,
    dkClassificationArrNew,
}

export default connect(mapStateToProps, mapDispatchToProps)(BageKeywords)