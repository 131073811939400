import React from "react";
import Button from "../../elements/buttons/button";
import styles from './uniPopUp.module.css'

const UniPopUp = ({ data, type = 1, action, close }) => {

    const typeOne = <div className={styles.btnBlock}>
                    <Button 
                        onClick={close}
                        data={'Закрити'}
                    />
                    </div>
    
    const typeTwo = <div className={styles.btnBlock}>
                        <Button 
                            color={'gray'}
                            onClick={ close }
                            data={'Ні'}
                        />
                        <Button
                            color={'blue'}
                            onClick={ action }
                            data={'Так'}
                        />
                    </div>

    return <div className={styles.backdrop}>
                <div className={styles.wrapper}>
                    <div className={styles.cont}>
                        <div className={styles.text}>{data}</div>
                        {type === 2 ? typeTwo : typeOne}
                    </div>
                </div>
            </div>
}

export default UniPopUp