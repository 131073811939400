import React from 'react';
import styles from './deletePopup.module.css';
import {useSelector, useDispatch} from 'react-redux';

import { deleteAction, setActionDeletePopup } from '../../../../../../redux/actions/leaseActions/leaseActions';

const DeletePopup = (props) => {

    const dispatch = useDispatch()
    const token = useSelector(state => state.start.token)

    function deleteLeaseAction() {
        let urlDelete = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/lease_action/${props.actionId}`;
        let redirectUrl = '/my_actions';
        dispatch( deleteAction(urlDelete, token.access_token, redirectUrl) );
    }

    return (
        <div className={styles.backdrop}>
            <div className={styles.wrapper}>
                <button className={styles.buttonClose} onClick={() => dispatch(setActionDeletePopup(false))}></button>
                <div className={styles.title}>Видалення дії</div>
                <div className={styles.text}>Ви впевені, що хочете видалити дію?</div>
                <div className={styles.buttonWrapper}>
                    <button className={styles.buttonCancel} onClick={() => dispatch(setActionDeletePopup(false))}>Скасувати</button>
                    <button className={styles.buttonDelete} onClick={() => deleteLeaseAction()}>Підтвердити</button>
                </div>
            </div>
        </div>
    )
}

export default DeletePopup;