import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './valuesCharacteristics.module.css';

import InputMoney from '../../../../../../inputs/inputMoney';
import InputAmount from '../../../../../../inputs/inputAmount';
import InputDate from '../../../../../../inputs/inputDate';
import InputTextArea from '../../../../../../inputs/inputTextArea';
import Input from '../../../../../../inputs/input';
import InfoBlockСollapser from '../../../../../../elements/infoBlockСollapser/infoBlockСollapser';
import FullInfoBlock from '../../../../../../elements/fullInfoBlock/fullInfoBlock';
import HalfInfoBlock from '../../../../../../elements/halfInfoBlock/halfInfoBlock';
import Loader from '../../../../../../loader/loader';
import { checkProp } from '../../../../../../../utils/check';
import { objectUpdate } from '../../../../../../../redux/actions/object/object.js';
import { getCurrency, dateTransform } from '../../../../../../../utils/check';
import { myDraftObject, myObjectPrivate  } from '../../../../../../../utils/endPoints';
import Button from '../../../../../../elements/buttons/button';

const ValuesCharacteristics = ({data, updateData, isDraft}) => {

    const dispatch = useDispatch();
    const pageCreate = useSelector(state => state.start.objectPage);
    const token = useSelector(state => state.start.token);
    const object = useSelector(state => state.start.objectGet);

    const [update, setUpdate] = useState(false);

    const [initialBookValue, setInitialBookValue] = useState(checkProp(data, 'initialBookValue') ? data.initialBookValue : { currency: 'UAH', amount: '', valueAddedTaxIncluded: true });
    const [residualBookValue, setResidualBookValue] = useState(checkProp(data, 'residualBookValue') ? data.residualBookValue : { currency: 'UAH', amount: '', valueAddedTaxIncluded: true });
    const [marketValue, setMarketValue] = useState(checkProp(data, 'marketValue') ? data.marketValue : { currency: 'UAH', amount: '', valueAddedTaxIncluded: true });
    const [marketValueValuationDate, setMarketValueValuationDate] = useState( checkProp(data, 'marketValueValuationDate') ? data.marketValueValuationDate : '');
    const [marketValueValuationDetails, setMarketValueValuationDetails] = useState(checkProp(data, 'marketValueValuationDetails') ? data.marketValueValuationDetails : '');
    const [secondListMethodology, setSecondListMethodology] = useState( checkProp(data, 'secondListMethodology') ? data.secondListMethodology : '');
    const [secondListAbsoluteValue, setSecondListAbsoluteValue] = useState( checkProp(data, 'secondListAbsoluteValue') ? data.secondListAbsoluteValue : { currency: 'UAH', amount: '', valueAddedTaxIncluded: true });
    const [secondListPercentValue, setSecondListPercentValue] = useState(checkProp(data, 'secondListPercentValue') ? data.secondListPercentValue : '');
    const [secondListDecisionDescription, setSecondListDecisionDescription] = useState(checkProp(data, 'secondListDecisionDescription') ? data.secondListDecisionDescription : '');

    let valuesCharacteristics = {}

    useEffect(() => {
        valuesCharacteristics = {
            initialBookValue: initialBookValue?.amount && (initialBookValue.amount !== '' && initialBookValue.amount !== 0) ? initialBookValue : null,
            residualBookValue: residualBookValue?.amount && (residualBookValue.amount !== '' && residualBookValue.amount !== 0) ? residualBookValue : null,
            marketValue: marketValue,
            marketValueValuationDate: marketValueValuationDate,
            marketValueValuationDetails: marketValueValuationDetails,
            secondListMethodology: secondListMethodology,
            secondListAbsoluteValue: secondListAbsoluteValue?.amount && (secondListAbsoluteValue.amount !== '' && secondListAbsoluteValue.amount !== 0) ? secondListAbsoluteValue : null,
            secondListPercentValue: secondListPercentValue,
            secondListDecisionDescription: secondListDecisionDescription
        }
        updateData({
            initialBookValue: initialBookValue?.amount && (initialBookValue.amount !== '' && initialBookValue.amount !== 0) ? initialBookValue : null,
            residualBookValue: residualBookValue?.amount && (residualBookValue.amount !== '' && residualBookValue.amount !== 0) ? residualBookValue : null,
            get marketValue() {
                if (!!marketValue?.amount && marketValue.amount != 0) {
                    let tmp = marketValue
                    tmp.amount = +marketValue.amount
                    return tmp
                }
            },
            get marketValueValuationDate() {
                if( !!marketValue?.amount && marketValue.amount != 0 ) return marketValueValuationDate
            },
            get marketValueValuationDetails() {
                if ( marketValueValuationDetails !== '') return marketValueValuationDetails
            },
            get secondListMethodology() {
                if ( secondListMethodology !== '') return secondListMethodology
            },
            secondListAbsoluteValue: secondListAbsoluteValue?.amount && (secondListAbsoluteValue.amount !== '' && secondListAbsoluteValue.amount !== 0) ? secondListAbsoluteValue : null,
            secondListPercentValue: secondListPercentValue,
            secondListDecisionDescription: secondListDecisionDescription
        });
    }, [initialBookValue, residualBookValue, marketValue, 
        marketValueValuationDate, marketValueValuationDetails, secondListMethodology, 
        secondListAbsoluteValue, secondListPercentValue, secondListDecisionDescription]);

    function updateObject() {
        if (token !== null) {
            valuesCharacteristics = {
                valuesCharacteristics: {
                    get initialBookValue(){
                        let tmp = initialBookValue
                        if(initialBookValue.amount !== '' && initialBookValue.amount !== 0){
                            tmp.amount = +initialBookValue.amount
                        }else{
                            tmp = null
                        }
                        return tmp
                    },
                    get residualBookValue(){
                        let tmp = residualBookValue
                        if(residualBookValue.amount !== '' && residualBookValue.amount !== 0){
                            tmp.amount = +residualBookValue.amount
                        }else{
                            tmp = null
                        }
                        return tmp
                    },
                    get marketValue(){
                        if ( !!marketValue?.amount && marketValue.amount != 0 ) {
                            let tmp = marketValue
                            tmp.amount = +marketValue.amount
                            return tmp
                        }
                    },
                    get marketValueValuationDate(){
                        if( !!marketValue?.amount && marketValue.amount != 0 ) return marketValueValuationDate
                    },
                    get marketValueValuationDetails() {
                            if ( marketValueValuationDetails !== '') return marketValueValuationDetails
                    },
                    secondListMethodology: secondListMethodology,
                    get secondListAbsoluteValue(){
                        let tmp = secondListAbsoluteValue
                        if(secondListAbsoluteValue.amount !== '' && secondListAbsoluteValue.amount !== 0){
                            tmp.amount = +secondListAbsoluteValue.amount
                        }else{
                            tmp = null
                        }
                        return tmp
                    },
                    secondListPercentValue: secondListPercentValue,
                    secondListDecisionDescription: secondListDecisionDescription
                }
            };
            // let urlUpdate = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/registry_object/${object.id}/valuesCharacteristics`;
            let redirect = `/lease/create/${object.id}`;
            const getUrl =( id )=> {
                if(isDraft) return myDraftObject(id)
                return myObjectPrivate(id)
            }
            dispatch( objectUpdate(getUrl(object.id), JSON.stringify(valuesCharacteristics), token.access_token, redirect));
        } else {
            alert('Авторизуйтесь');
        }
    }

    function setUpdateValues(a) {
        // setInitialBookValue(data.initialBookValue);
        // setResidualBookValue(data.residualBookValue);
        // setMarketValue(data.marketValue);
        // setMarketValueValuationDate(data.marketValueValuationDate);
        // setMarketValueValuationDetails(data.marketValueValuationDetails);
        // setSecondListMethodology(data.secondListMethodology);
        // setSecondListPercentValue(data.secondListPercentValue);
        // setSecondListAbsoluteValue(data.secondListAbsoluteValue);
        // setSecondListDecisionDescription(data.secondListDecisionDescription);
        setUpdate(a);
    }

    const updateDataList = <>
                        <InputAmount 
                            title='Первісна балансова вартість'
                            value={ initialBookValue }
                            onChange={ setInitialBookValue }
                            tax={ true }
                            required
                            // enableCurrent
                        />
                        <InputAmount 
                            title='Залишкова балансова вартість'
                            value={ residualBookValue }
                            onChange={ setResidualBookValue }
                            tax={ true }
                            required
                            // enableCurrent
                        />
                        <InputAmount 
                            title='Ринкова вартість'
                            value={ marketValue }
                            onChange={ setMarketValue }
                            tax={ true }
                            // required
                            // enableCurrent
                        />
                        { ( !!marketValue?.amount && marketValue.amount !== 0 ) &&
                            <InputDate
                                data={{
                                    label: "Дата визначення ринкової вартості",
                                    value: marketValueValuationDate,
                                    onChange: setMarketValueValuationDate
                                }}
                            />
                        }
                        <InputTextArea
                            value={ marketValueValuationDetails }
                            label="Інформація про оцінювача та необхідність компенсації оцінки орендарем"
                            onChange={ setMarketValueValuationDetails }
                        />
                        <InputTextArea
                            value={ secondListMethodology }
                            label="Посилання на пункт Методики розрахунку орендної плати, яким встановлена орендна
                        ставка для запропонованого цільового призначення"
                            onChange={setSecondListMethodology}
                        />
                        <Input
                            value={ secondListPercentValue }
                            label="Значення орендної ставки у відсотках"
                            type={'number'}
                            onChange={setSecondListPercentValue}
                        />
                        <InputAmount 
                            title='Орендна плата'
                            value={ secondListAbsoluteValue }
                            onChange={ setSecondListAbsoluteValue }
                            tax={ true }
                            required
                            // enableCurrent
                        />
                        <InputTextArea
                            value={ secondListDecisionDescription}
                            label="Інші відомості щодо визначення орендної ставки"
                            onChange={setSecondListDecisionDescription}
                        />
    </>
    
    const viewDataList =   <div className={styles.infoWrapper}>
                                { !!data?.initialBookValue?.amount &&
                                    <HalfInfoBlock 
                                        title={`Первісна балансова вартість, ${getCurrency(data.initialBookValue.currency)} ${data.initialBookValue.valueAddedTaxIncluded ? 'з' : 'без'} ПДВ`} 
                                        data={data.initialBookValue.amount} />
                                }
                                { !!data?.residualBookValue?.amount &&
                                    <HalfInfoBlock 
                                        title={`Залишкова балансова вартість, ${getCurrency(data.residualBookValue.currency)} ${data.residualBookValue.valueAddedTaxIncluded ? 'з' : 'без'} ПДВ`} 
                                        data={data.residualBookValue.amount} />
                                }
                                { !!data?.marketValue?.amount &&
                                    <HalfInfoBlock 
                                        title={`Ринкова вартість, ${getCurrency(data.marketValue.currency)} ${data.marketValue.valueAddedTaxIncluded ? 'з' : 'без'} ПДВ`} 
                                        data={data.marketValue.amount} />
                                }
                                { !!data?.marketValueValuationDate &&
                                    <HalfInfoBlock 
                                        title="Дата визначення ринкової вартості" 
                                        data={dateTransform(data.marketValueValuationDate)} />
                                }
                                { !!data?.marketValueValuationDetails &&
                                    <FullInfoBlock 
                                        title="Інформація про оцінювача, вартість оцінки, необхідність компенсації оцінки орендарем та суму компенсації" 
                                        data={ data.marketValueValuationDetails } />
                                }
                                { !!data?.secondListMethodology  &&
                                    <FullInfoBlock 
                                        title="Посилання на пункт Методики розрахунку орендної плати, яким встановлена орендна 
                                        ставка для запропонованого цільового призначення" 
                                        data={ data.secondListMethodology } />
                                }
                                { !!data?.secondListAbsoluteValue?.amount &&
                                    <HalfInfoBlock
                                        title={`Фактичне значення орендної ставки, ${getCurrency(data.secondListAbsoluteValue.currency)} ${data.secondListAbsoluteValue.valueAddedTaxIncluded ? 'з' : 'без'} ПДВ`} 
                                        data={ data.secondListAbsoluteValue.amount } />
                                }
                                { !!data?.secondListPercentValue &&
                                    <HalfInfoBlock 
                                        title="Значення орендної ставки у відсотках" 
                                        data={ data.secondListPercentValue } />
                                }
                                { !!data?.secondListDecisionDescription &&
                                    <FullInfoBlock 
                                        title="Інші відомості щодо визначення орендної ставки" 
                                        data={ data.secondListDecisionDescription } />
                                }
                            </div>

    return(<>
        { pageCreate === 'create' ?
            <div className={styles.wrapper}>
                <div className={styles.title}>Вартісні характеристики</div>
                {updateDataList}
            </div>
        : <>
                <InfoBlockСollapser 
                    blockTitle={`Вартісні характеристики`}
                    collapsed={false}
                    button={
                        !update ?
                            <Button color={'white'} height={'30px'} onClick={() => setUpdateValues(true)}>Редагувати</Button>
                            : 
                            <div style={{display: 'flex', gap: '8px'}}>
                                <Button color={'blue'} height={'30px'} onClick={() => updateObject() }>Зберегти</Button>
                                <Button onClick={() => setUpdateValues(false) } color={'gray'} height={'30px'}>X</Button>
                            </div>
                    }
                    content={<>
                        {update ?
                            updateDataList :
                            <div className={styles.blockFlex}>
                                {viewDataList}
                            </div>
                        }
                    </>}
                />
            </>
        }
    </>);
}

export default ValuesCharacteristics;