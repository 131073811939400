import React, { useState } from 'react';
import styles from './createTitleBar.module.css';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getShorter, isDraftObject, isMongo } from '../../../../../../utils/check';
import Button from '../../../../../elements/buttons/button';
import DeleteIcon from '../../../../../elements/svg/deleteIcon';
import { getMyAnnouncementDraft, setMyAnnouncementsStatus } from '../../../../../../utils/endPoints';
import { assetDelete, announcementsStatusUpdate } from '../../../../../../redux/asset/assetActions';
import UniPopUp from '../../../../../popups/uniPopUp/uniPopUp';
import SendAssetToProzoro from '../validation/sendToProzoro';

const AnnouncementCreateTitle =({ data, create })=>  {
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const dispatch = useDispatch()
    const assetItem = useSelector(state => state.asset.assetItem)
    const token = useSelector(state => state.start.token);
    const path = pathname.split('/')[5]
    const [delPopup, setDelPopup] = useState( false )
    const [popup, setPopup] = useState( { type: '', action: '' } )

    function getLastPath(path){
        switch(path){
            case 'items': return 'Об`єкти'
            case 'documents': return 'Документи'
            default: return 'Загальна інформація'
        }
    }

    // const itemsPad = ` Об\'єкти ${ !!data?.assetItems && data.assetItems.length > 0 ? `( ${ data.assetItems.length } )` : ''}`
    const docsPad = ` Документи ${ !!data?.documents && data.documents.length > 0 ? `( ${ data.documents.length } )` : ''}`

    function setBookmarkStyle(loc){
        if(!data) return styles.inactiveObjNavItem
        if( path === loc) return styles.activeNavItem
        return styles.objNavItem
    }

    function createPad(loc, content, onclick){
       return <div className={ setBookmarkStyle(loc) } onClick={ setBookmarkStyle(loc) === styles.objNavItem ? onclick : undefined } >
                { content }
            </div>
    }

    function deleteDraftObject( data ){
        if( !!data && isDraftObject( data.id ) ) {
            return (
                <Button 
                    height={'50px'} 
                    color='orange' 
                    onClick={ ()=> setDelPopup( true ) }
                    hover={ '#FFA047' }
                    title='Видалити об`єкт'
                >
                    <DeleteIcon /> 
                </Button>
            )
        }
    }

    function removeObject(data){
        if (!!data && data.status === 'pending') {
            return (
                <Button 
                    height={'50px'} 
                    color='orange' 
                    onClick={()=> setPopup({type: 'remove', action: ()=> {
                        dispatch( announcementsStatusUpdate( setMyAnnouncementsStatus(data.id, 'deleted'), token.access_token))
                        navigate('/asset/my_announcements')
                        setPopup({ type: null })
                    } }) }
                    // hover={ '#FFA047' }
                    title='Виключити інформаційне повідомлення з переліку'
                >
                    Виключити інформаційне повідомлення з переліку
                </Button>
            )
        }
    }


    function isEditable( data ){
        let view = false
        if ( !!data && data.status === 'pending' && !!data?.documents ){
            data.documents.forEach( i => { if ( i.documentType === 'clarifications') view = true })
        }
        if ( !!data && !data.status ) view = true
       return view
    }

    return (
        <div className={styles.navBlock}>
            { delPopup && <UniPopUp 
                                type={2}
                                data={'Ви дійсно хочете видалити чернетку?'} 
                                close={ ()=> setDelPopup( false ) }
                                action={ ()=> {
                                        assetDelete( getMyAnnouncementDraft( data.id ), token.access_token, ()=> navigate('/asset/my_announcements') )
                                        setDelPopup( false )
                                    }
                                }
                            /> 
            }
            { popup.type && <UniPopUp 
                                type={2}
                                data={ 'Ви дійсно хочете виключити інформаційне повідомлення?' } 
                                close={ ()=> setPopup( prev => ( { ...prev, type: '' } ) ) }
                                action={ ()=> popup.action() }
                            /> 
            }
            <div className={styles.titleBlock}>

                <div className={styles.pathString}>
                    <ul className={styles.navUlList}>
                        <li key={1}>
                            <Link to={`/asset/my_announcements`}>
                                    Мої інформаційні повідомлення
                            </Link>
                        </li>
                        <li key={2} className={styles.liTitle}>{ !!data?.title ? getShorter( isMongo(data.title) , 80) : 'Новий об\'єкт'}</li>
                        <li key={3}>{ getLastPath(path) }</li>
                    </ul>
                </div>

                <div className={styles.titleAndSend}>
                    <div className={styles.objTitle}>
                        { !!data?.title ? isMongo(data.title) : ( create ? 'Створення інформаційного повідомлення' : 'Редагування інформаційного повідомлення' ) }
                    </div>
                    <div className={styles.sendButton}>
                        <SendAssetToProzoro data={ data } asset={ assetItem } />
                        { deleteDraftObject( data ) }
                        { removeObject( data ) }
                        {/* { archivateObject(data) } */}
                    </div>
                </div>
               
            </div>

            <div className={styles.objNav}>
                { isEditable( data ) && <div className={ !path ? styles.activeNavItem : styles.objNavItem } onClick={ ()=> navigate('') }>
                    Загальна інформація
                </div>
                }
                {/* { createPad( 'items', itemsPad, ()=> navigate('items') ) } */}
                { createPad( 'documents', docsPad, ()=> navigate('documents') ) }
            </div>

        </div>
    )
}

export default AnnouncementCreateTitle