import React, { useState } from 'react';
import styles from './mapStyle.module.css';

const MapEdit = ({ data, setData }) => {
    const [latitude, setLatitude] = useState( !!data?.latitude ? data.latitude : '50.44395')
    const [longitude, setLongitude] = useState( !!data?.longitude ? data.longitude : '30.51589')

    let mapLink = `../../../iframeMap.html?lat=${ latitude }&lng=${ longitude }`

    return (
        <div className={styles.mapWrap}>
            <h3 className={styles.terminuTitle}>Розташування об'єкту на мапі</h3>
            <iframe title='locationEdit' className={styles.map} id="target" src={ mapLink }></iframe>
        </div>
    )
}

export default MapEdit