import React, { useState } from 'react';
import styles from './contractsListItem.module.css';
// import Loader from '../../../../loader/loader'
import { dateTransform, getFormatedValue, isMongo } from '../../../../../../../utils/check';
import { getLeaseContractStatus, setLeaseContractMethod } from '../../../../../../../utils/contracts/contractsUtils';
import UniPopUp from '../../../../../../popups/uniPopUp/uniPopUp';
import { axiosDeleteAPI } from '../../../../../../../redux/actions/crud';
import { useDispatch, useSelector } from 'react-redux';
import { urlPatchLeaseContractDrafts } from '../../../../../../../utils/endPoints';

const LeaseContractListItem = ({ data, setEditContract }) => {

    const [uniPopup, setUniPopup] = useState(false)
    const dispatch = useDispatch()
    const token = useSelector( state => state.start.token )
    const objectData = useSelector(state => state.start.objectGet)

        function buttonsBlock(){
            
             return (
                <div className={styles.buttonWrap}>
                    <button className={styles.buttonEdit} title='Редагувати'
                        onClick={ ()=> {
                            window.scrollTo(0, 0)
                            setEditContract( prev => ({ ...prev, data: data, edit: 'edit' }) )
                        }}
                    >
                        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M15.861 5.59595L16.9757 4.4812L14.5187 2.02413L13.4039 3.13889L15.861 5.59595ZM14.4542 7.00273L11.9971 4.54566L2.80565 13.7371L2.24014 16.7597L5.26272 16.1942L14.4542 7.00273ZM18.4419 3.13384C19.186 3.87796 19.186 5.08443 18.4419 5.82856L6.45174 17.8187C6.30989 17.9606 6.12847 18.0562 5.93129 18.0931L1.17816 18.9824C0.490832 19.111 -0.111152 18.509 0.0174442 17.8217L0.90674 13.0686C0.943632 12.8714 1.03928 12.69 1.18113 12.5481L13.1713 0.557942C13.9154 -0.186184 15.1219 -0.186184 15.866 0.557942L18.4419 3.13384ZM18.0099 18.9999H9.9901C8.66997 18.9999 8.66997 16.9999 9.9901 16.9999H18.0099C19.33 16.9999 19.33 18.9999 18.0099 18.9999Z" fill="#92929D"></path>
                        </svg>
                    </button>
                    <button className={styles.buttonView} title='Переглянути'
                        onClick={ ()=> {
                            window.scrollTo(0, 0)
                            setEditContract( prev => ({ ...prev, data: data, edit: 'view' }) )
                            
                        }}
                    >
                        <svg width="22" height="20" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M0.812817 10.2499C3.27182 4.21083 8.34513 0.333252 13.9995 0.333252C19.6539 0.333252 24.7272 4.21083 27.1862 10.2499C27.3817 10.7301 27.3817 11.2697 27.1862 11.7499C24.7272 17.789 19.6539 21.6666 13.9995 21.6666C8.34513 21.6666 3.27182 17.789 0.812817 11.7499C0.617285 11.2697 0.617285 10.7301 0.812817 10.2499ZM13.9995 19.1995C18.5582 19.1995 22.7378 16.0489 24.866 10.9999C22.7378 5.95092 18.5582 2.80037 13.9995 2.80037C9.44077 2.80037 5.26117 5.95092 3.13295 10.9999C5.26117 16.0489 9.44077 19.1995 13.9995 19.1995ZM13.9995 15.8616C11.3611 15.8616 9.22232 13.6849 9.22232 10.9999C9.22232 8.31489 11.3611 6.13824 13.9995 6.13824C16.6379 6.13824 18.7767 8.31489 18.7767 10.9999C18.7767 13.6849 16.6379 15.8616 13.9995 15.8616ZM13.9995 13.3945C15.299 13.3945 16.3524 12.3224 16.3524 10.9999C16.3524 9.67744 15.299 8.60536 13.9995 8.60536C12.7 8.60536 11.6466 9.67744 11.6466 10.9999C11.6466 12.3224 12.7 13.3945 13.9995 13.3945Z" fill="#139DD9"/>
                        </svg>

                    </button>
                    { !data?.datePublished && 
                    <button className={styles.buttonDelete} title='Видалити'
                        onClick={ () => setUniPopup(true) }
                    >
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M16.6448 14.4563C17.5006 13.1827 18 11.6496 18 9.99985C18 5.58157 14.4183 1.99985 10 1.99985C8.34883 1.99985 6.8145 2.50008 5.54028 3.35726L16.6448 14.4563ZM15.3278 15.9677L4.0295 4.67496C2.76722 6.0893 2 7.95498 2 9.99985C2 14.4181 5.58172 17.9999 10 17.9999C12.0463 17.9999 13.9131 17.2316 15.3278 15.9677ZM10 -0.000152588C15.5228 -0.000152588 20 4.477 20 9.99985C20 15.5227 15.5228 19.9999 10 19.9999C4.47715 19.9999 0 15.5227 0 9.99985C0 4.477 4.47715 -0.000152588 10 -0.000152588Z" fill="#FFA047"></path>
                        </svg>
                    </button>
                    }
                </div>
            )
        }

        function getStatusColor(a) {
            switch(a) {
                case 'pending': return 'var(--brightBlue)'
                case 'active': return 'var(--green)'
                case 'inactive': return 'var(--lightGray)'
                case 'cancelled': return 'var(--attantion)'
                default:
                    return 
            }
        }

        return (
                <div className={styles.itemWrap}>
                    { uniPopup && 
                        <UniPopUp type={2} 
                            data={ `Ви дійсно хочете видалити документ ${isMongo(data.title)}?`}
                            action={ ()=> { 
                                axiosDeleteAPI({
                                    url: urlPatchLeaseContractDrafts( objectData.id, data.id ),
                                    dispatch: dispatch,
                                    set: ( data )=> setEditContract( prev => ({...prev, data: data })),
                                    token: token.access_token,
                                })
                                setUniPopup(false)
                            }}
                            close={ ()=> setUniPopup(false) } 
                        /> 
                    }
                    <div className={styles.left}>
                        <div className={styles.title}>
                            <div className={styles.number}>Номер договору: { data.contractNumber }</div>
                            <div className={styles.titleName}>{ isMongo( data.title )} </div>
                            <div className={styles.number}>{ setLeaseContractMethod( data.contractMethod ) }</div>
                            { !!data?.value?.amount && <div className={styles.value}>Сума договору: { getFormatedValue( data.value ) } </div> }
                        </div>
                    </div>

                    <div className={styles.right}>
                        { !data?.datePublished && 
                            <div className={styles.dates}>
                                <div className={styles.draft}>ЧЕРНЕТКА</div>
                            </div>
                        }
                        <div className={styles.status}>
                            <div className={styles.subTitle}>Статус:</div>
                            <div className={styles.statusName} style={{ color: getStatusColor( data.status ) }}>{ getLeaseContractStatus( data.status ) } </div>
                        </div>
                        { !!data?.dateSigned &&
                            <div className={styles.termin}>
                                <div className={styles.subTitle}>Дата підписання договору:</div>
                                    <div className={styles.name}>{ dateTransform( data.dateSigned ) } </div>
                            </div>
                        }
                        { ( !!data?.contractTime?.dateFrom && !!data?.contractTime?.dateTill ) &&
                            <div className={styles.termin}>
                                <div className={styles.subTitle}>Термін дії договору:</div>
                                
                                    <div className={styles.name}>{ `з ${ dateTransform( data.contractTime.dateFrom ) } по ${ dateTransform( data.contractTime.dateTill ) }` } </div>
                            </div>
                        }
                        { !!data?.datePublished &&
                            <div className={styles.dates}>
                                <div className={styles.subTitle}>Дата публікації:</div>
                                <div className={styles.name}>{ dateTransform( data.datePublished, 'time' ) } </div>
                            { data.datePublished !== data.dateModified &&
                                    <>
                                        <div className={styles.subTitle}>Дата останньої зміни:</div>
                                        <div className={styles.name}>{ dateTransform( data.datePublished, 'time' ) } </div>
                                    </>
                                }
                            </div>
                        }
                    </div>

                    <div className={styles.third}>
                            { buttonsBlock() }
                    </div>

                </div>
        )
    }

export default LeaseContractListItem