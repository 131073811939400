import React, { useState, useEffect } from 'react';
import styles from './leaseRules.module.css';
import { useDispatch, useSelector } from 'react-redux';

import Input from '../../../../../../inputs/input';
import Checkbox from '../../../../../../inputs/checkBox';
import InputTextArea from '../../../../../../inputs/inputTextArea';
import InputSelect from '../../../../../../inputs/inputSelect';
import MultiSelect from '../../../../../../inputs/MultiSelect/inputMultiSelect';
import Period from './period/period';
import InfoBlockСollapser from '../../../../../../elements/infoBlockСollapser/infoBlockСollapser';
import { objectGetRestrictions, objectGetIntendedUse, objectUpdate } from '../../../../../../../redux/actions/object/object.js';
import { leaseRulesUpdate } from '../../../../../../../redux/actions/object/object.js';
import ViewLeaseRules from '../../../../../objectItemPage/blocks/generalInfo/leaseRules/viewLeaseRules';
import { checkProp } from '../../../../../../../utils/check';
import { myDraftObject, myObjectPrivate  } from '../../../../../../../utils/endPoints';
import moment from 'moment';
import Button from '../../../../../../elements/buttons/button';

const LeaseRules = ({ data, updateData, isDraft }) => {

    const dispatch = useDispatch();
    // const object = useSelector(state => state.start.object);
    const object = useSelector(state => state.start.objectGet);
    const token = useSelector(state => state.start.token);
    const objectRestrictionsList = useSelector(state => state.start.objectRestrictionsList);
    const objectIntendedUseList = useSelector(state => state.start.objectIntendedUseList);
    const pageCreate = useSelector(state => state.start.objectPage);
    const urlObjectRestrictionsList = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/public/dictionaries/leaseRestrictions`;
    const urlObjectIntendedUseList = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/public/dictionaries/intendedUse`;

    const [leaseRuless, setLeaseRules] = useState({
        // intendedUse: [],
            leaseDuration: !!data?.leaseDuration ? data.leaseDuration : null , // Строк оренди P2Y11M
            intendedUseRestrictionMethod: !!data?.intendedUseRestrictionMethod ? data.intendedUseRestrictionMethod : null,
            intendedUseRestrictionDescription: !!data?.intendedUseRestrictionDescription ? data.intendedUseRestrictionDescription : null,
            additionalLeaseConditions: !!data?.additionalLeaseConditions ? data.additionalLeaseConditions : null,
            subleaseAllowed: !!data?.subleaseAllowed ? data.subleaseAllowed : null
    });

    const dur = moment.duration( !!data?.leaseDuration ? data.leaseDuration : null )
    
    const [hoursBy, setHoursBy] = useState( !!data?.schedule && checkProp(data.schedule, 'hoursBy') ? data.schedule.hoursBy : { by: 'day', value: null });
    const hoursByVal = [
        { name: 'День', value: 'day'},
        { name: 'Тиждень', value: 'week'},
        { name: 'Місяць', value: 'month'}
    ]
    const [daysBy, setDaysBy] = useState( !!data?.schedule && checkProp(data.schedule, 'daysBy') ? data.schedule.daysBy : { by: 'week', value: null });
    const daysByVal = [
        { name: 'Тиждень', value: 'week'},
        { name: 'Місяць', value: 'month'}
    ]
    const [schedule, setSchedule] = useState(
        { //Пропонований графік оренди
            other: !!data?.schedule && checkProp(data.schedule, 'other') ? data.schedule.other : null, // Інший графік використання
            hoursBy: !!data?.schedule && checkProp(data.schedule, 'other') && data.schedule.other !== null ? null : hoursBy,
            daysBy: !!data?.schedule && checkProp(data.schedule, 'other') && data.schedule.other !== null ? null :  daysBy,
            period: period
        });
    const [graphicHourStatus, setGraphicHourStatus] = useState( !!data?.schedule && checkProp(data.schedule, 'period') && data.schedule.period.length > 0 ? true : false);
    // const [graphicEventStatus, setGraphicEventStatus] = useState(false);
    const [update, setUpdate] = useState(false);

    const [period, setPeriod] = useState(!!data?.schedule && checkProp(data.schedule, 'period') ? data.schedule.period : []);
    
    const [years, setYears] = useState(dur._data.years || null);
    const [months, setMonths] = useState(dur._data.months || null);
    const [days, setDays] = useState(dur._data.days || null);
    // const [intendedUse, setIntendeduse] = useState( !!data?.intendedUse ? data.intendedUse : null);

    useEffect(() => {
        if(objectRestrictionsList === null || objectRestrictionsList === undefined) {
            dispatch(objectGetRestrictions(urlObjectRestrictionsList));
        }
        if(objectIntendedUseList=== null || objectIntendedUseList === undefined) {
            dispatch(objectGetIntendedUse(urlObjectIntendedUseList));
        }
    }, []);

    useEffect( () => {
            setSchedule((prev) => ({ ...prev, daysBy: daysBy }))
    }, [daysBy]);

    useEffect(() => {
        setSchedule((prev) => ({ ...prev, hoursBy: hoursBy }))
    }, [hoursBy]);

    useEffect(() => {
        setSchedule((prev) => ({ ...prev, period: period }))
    }, [period]);

    useEffect(() => {
        setLeaseRules( prev => ({...prev, schedule: schedule}))
    }, [schedule]);



    // useEffect(() => { //TODO: Убрать? ХЗ
    //     if (intendedUse !== null && intendedUse !== '' && intendedUse.length > 0) setLeaseRules({ ...leaseRuless, intendedUse: intendedUse })
    // }, [intendedUse]);

    useEffect(() => {
        let tmp = null
        if (+years >= 0 || +months >= 0 || +days >= 0) {
            tmp = `P${years > 0 ? years + 'Y' : ''}${months > 0 ? months + 'M' : ''}${days > 0 ? days + 'D' : ''}`
        }
        setLeaseRules({ ...leaseRuless, leaseDuration: tmp })
    }, [years, months, days]);

    useEffect(() => {
        updateData(leaseRuless)
    }, [leaseRuless]);

    function updateObject() {
        if (token !== null) {
            let leaseRules = JSON.stringify({leaseRules: leaseRuless });
            let redirect = `/lease/create/${object.id}`;
            const getUrl =( id )=> {
                if(isDraft) return myDraftObject(id)
                return myObjectPrivate(id)
            }
            dispatch(objectUpdate( getUrl(object.id), leaseRules, token.access_token, redirect ));
        } else {
            alert('Авторизуйтесь');
        }
    }

    function getLeseDuration(value, type){
        if(type === 'hours'){
            switch(value){
            case 'day': return 24
            case 'week': return 168
            case 'month': return 744
            default: return
            }
        }
        if(type === 'days'){
            switch(value){
            case 'week': return 7
            case 'month': return 31
            default: return
            }
        }
    }

    const editBlock = <div className={styles.wrapper}>
                        <div className={styles.title}>Правила та умови передачі об`єкта в оренду</div>
                        <div className={styles.subtitle}>Строк оренди</div>
                        <div className={styles.fullWidth}>
                            <div className={styles.thirdWidth}>
                            <Input
                                value={years}
                                type='number'
                                label="Років"
                                minMax={[0, 100]}
                                onChange={setYears} />
                            </div>
                            <div className={styles.thirdWidth}>
                            <Input
                                value={months}
                                type='number'
                                label="Місяців"
                                minMax={[0, 12]}
                                onChange={setMonths} />
                            </div>
                            <div className={styles.thirdWidth}>
                            <Input
                                value={days}
                                type='number'
                                label="Днів"
                                minMax={[0, 365]}
                                onChange={setDays} />
                            </div>
                        </div>
                    <div className={styles.subtitle}>Пропонований графік оренди</div>
                    { !schedule.other ? <div>
                        <div className={styles.scheduleBy}>
                            <div className={styles.scheduleHours}>
                                <Input
                                    value={hoursBy.value}
                                    type='number'
                                    label="Годин"
                                    minMax={[0, getLeseDuration(hoursBy.by, 'hours')]}
                                    onChange={ e => { 
                                        if( +e <= getLeseDuration(hoursBy.by, 'hours')) setHoursBy({ ...hoursBy, value: e })
                                        if( +e > getLeseDuration(hoursBy.by, 'hours')) setHoursBy({ ...hoursBy, value: getLeseDuration(hoursBy.by, 'hours') })
                                    }}
                                    maxlength={3}
                                    reqiured
                                />
                                <InputSelect
                                    label="На"
                                    value={hoursBy.by}
                                    onChange={e => setHoursBy({ ...hoursBy, by: e })}
                                    options={hoursByVal}
                                    text="Не вказано"
                                />
                            </div>
                            <div className={styles.scheduleDays}>
                                <Input
                                    value={daysBy.value}
                                    type='number'
                                    label="Днів"
                                    minMax={[0, getLeseDuration(daysBy.by, 'days')]}
                                    onChange={e => { 
                                        if(+e <= getLeseDuration(daysBy.by, 'days')) setDaysBy({ ...daysBy, value: e })
                                        if(+e > getLeseDuration(daysBy.by, 'days')) setDaysBy({ ...daysBy, value: getLeseDuration(daysBy.by, 'days') })
                                        }}
                                    maxlength={2}
                                    reqiured
                                />
                                <InputSelect
                                    label="На"
                                    value={daysBy.by}
                                    onChange={e => setDaysBy({ ...daysBy, by: e })}
                                    options={daysByVal}
                                    text="Не вказано"
                                />
                            </div>
                        </div>
                            <Checkbox 
                                value={graphicHourStatus}
                                onChange={ e => setGraphicHourStatus(!graphicHourStatus)} 
                                label="Графік погодинного використання" 
                            />
                            {graphicHourStatus ?
                                <>
                                    { period !== null && period.length > 0 ?
                                    period.map((i, count) => {
                                        return <Period 
                                            key={i}
                                            index={count}
                                            data={i}
                                            period={period}
                                            setPeriod={setPeriod}
                                        />
                                    }) : null
                                    }
                                    {/* {addPeriod(period)} */}
                                    <button 
                                        className={styles.addPeriodButton} 
                                        onClick={() => { 
                                            let tmp = {
                                                startDate: null,
                                                endDate: null,
                                                weekdays:[]
                                            }
                                            setPeriod([...period, tmp]) 
                                        }}
                                    >
                                        Додати період
                                    </button>
                                </>
                                : null
                            }
                        </div> : null 
                        }
                        { !hoursBy.value && !daysBy.value && (period && period.length === 0 ) ?
                        <Input 
                            value={schedule.other}
                            label="Інший графік використання"
                            onChange={e => { 
                                //setSchedule({ ...schedule, other: e })
                                if(e !== null && e !== ''){
                                    setSchedule(
                                        { //Пропонований графік оренди
                                            other: e,
                                            hoursBy: null,
                                            daysBy: null,
                                            period: null
                                        }    
                                    )
                                }else{
                                    setSchedule({ ...schedule, other: null })
                                }
                            }}
                            infoBlock={'При заповненні цього поля всі інші дані графіку оренди будуть відхилені'}
                            // reqiured
                        /> : null
                        }
                        <div className={styles.subtitle}>Цільове призначення об`єкта</div>
                        <InputSelect
                            label="Спосіб обмеження цільового призначення об`єкта *"
                            value={leaseRuless.intendedUseRestrictionMethod}
                            onChange={e => setLeaseRules( { ...leaseRuless, intendedUseRestrictionMethod: e })}
                            dictionary={true}
                            options={objectRestrictionsList}
                            text="Не вказано"
                        />
                        {leaseRuless.intendedUseRestrictionMethod === 'exceptDescribed' ?
                            <MultiSelect
                                label="Додавання цільового призначення *"
                                value={ data.intendedUse }
                                // onChange={e => setIntendeduse(e)}
                                onChange={e => setLeaseRules({ ...leaseRuless, intendedUse: e })}
                                options={ objectIntendedUseList }
                                dictionary={true}
                                maxLength={5}
                                // text="Оберіть"

                             /> : null
                        }
                        {leaseRuless.intendedUseRestrictionMethod === 'exceptDescribed' || leaseRuless.intendedUseRestrictionMethod === 'onlyDescribed' ?
                        <InputTextArea
                            value={leaseRuless.intendedUseRestrictionDescription}
                            onChange={e => setLeaseRules({ ...leaseRuless, intendedUseRestrictionDescription: e })}
                            label="Опис обмеженнь цільового призначення об`єкта (за наявності)"
                        /> : null}
                        <InputTextArea
                            value={leaseRuless.additionalLeaseConditions}
                            onChange={e => setLeaseRules({ ...leaseRuless, additionalLeaseConditions: e })}
                            label="Додаткові умови оренди майна (за наявності)"
                        />
                        <Checkbox
                            onChange={e => setLeaseRules({ ...leaseRuless, subleaseAllowed: e })}
                            value={leaseRuless.subleaseAllowed}
                            label="Можливість суборенди"
                        />
                    </div>

    return (
        pageCreate === 'create' ?
            editBlock :
         <InfoBlockСollapser
            blockTitle={`Правила та умови передачі об\`єкта в оренду`}
            blockDescription={`Дані про графік оренди та наявні обмеження цільового використання`}
            collapsed={false}
            button={!update ?
                <Button color={'white'} height={'30px'} onClick={() => setUpdate(!update)}>Редагувати</Button>
                : <div style={{display: 'flex', gap: '8px'}}>
                    <Button color={'blue'} height={'30px'} onClick={() => updateObject()}>Зберегти</Button>
                    <Button onClick={() => setUpdate(!update) } color={'gray'} height={'30px'}>X</Button>
                    </div>
                }
            content={update ? editBlock : <ViewLeaseRules data={object.leaseRules}/> }
        />
    );
}

export default LeaseRules;