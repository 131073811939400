import React, {useState} from 'react';
import styles from './leaseReqBlock.module.css';
import { useSelector, useDispatch } from 'react-redux';
// import { useNavigate } from 'react-router-dom';

import FullInfoBlock from '../../../../elements/fullInfoBlock/fullInfoBlock'
import HalfInfoBlock from '../../../../elements/halfInfoBlock/halfInfoBlock';
import ViewDocItem from '../../../../elements/documents/view/viewDocumentItem';
import PopDownBlock from '../../../../elements/popdownBlock/popDownBlock';
import ContactPoint from '../../../../elements/contactPoint/contactPoint';
import ContactPointSellingEntity from '../../../../elements/contactPoint/contactPointSellingEntity';

import { checkProp, dateTransform } from '../../../../../utils/check';

const LeaseReqestItem = ({item, obj, ...props}) => {
    const [askDelete, setaAskDelete] = useState(false)

    const dispatch = useDispatch()
    const token = useSelector(state => state.start.token);
    const user = useSelector(state => state.start.userProfiles)
    // const navigate = useNavigate()
    //TODO:Тут надо продумать вывод заявителя и документов. т.к. это не пубдличная инфа

    function yourRequestBadge() {
        let status = false
        if (!user) return null
        if (user && item && item.sellingEntity) {
            if ( checkProp(item.sellingEntity, 'identifier') && item.sellingEntity.identifier.id === user.organization.identifier.identifier) {
                status = true
            }
        }
        if (status) return <div className={styles.yourObjBadge}>Ваша заява</div>
        else return null 
    }

    function setRequestType(a) {
        switch(a) {
            case 'newLease':
                return 'Заява про включення до переліку'
            case 'lease':
                return 'Заява про включення до переліку'
            case 'agreementExtension':
                return 'Заява про продовження договору оренди'
            default:
                return a
        }
    }

    // const statusDraft = <div style={{color: 'gray', fontWeight: '600'}}>ЧЕРНЕТКА</div>
    // const statusActive = <div style={{color: 'gray', fontWeight: '600'}}>ОПУБЛІКОВАНО</div>
    //

    return (<>
        {/* <a name={item.leaseRequestId}></a> */}
        <div className={styles.infoBlock}>
            <div className={styles.infoBlock}>
                <div className={styles.infoBlockTitle}>
                    <h3>Заява</h3>
                    {/* { yourRequestBadge() && draftBtn(item)} */}
                </div>
                <div className={styles.content}>
                    {checkProp(item, 'leaseRequestId')
                        ? <HalfInfoBlock
                            title={'Ідентифікатор у ЦБД'}
                            data={item.leaseRequestId}
                        /> : null
                    }
                    {/* <HalfInfoBlock
                            title={'Статус'}
                            data={item.leaseRequestId === null ? statusDraft : statusActive }
                    /> */}
                    {checkProp(item, 'type')
                        ? <FullInfoBlock
                            title={'Тип заяви'}
                            data={setRequestType(item.type)}
                        /> : null
                    }
                    {checkProp(item, 'datePublished')
                        ? <HalfInfoBlock
                            title={'Дата публікації'}
                            data={dateTransform(item.datePublished, 'time')}
                        /> : null
                    }
                    {checkProp(item, 'dateModified')
                        ? <HalfInfoBlock
                            title={'Остання дата зміни'}
                            data={dateTransform(item.dateModified, 'time')}
                        /> : null
                    }
                    {(checkProp(item, 'description') && item.description !== null)
                        ? <FullInfoBlock
                            title={'Опис заяви'}
                            data={item.description}
                        /> : null
                    }
                </div>
                { item?.requestor &&
                    <ContactPoint data={item.requestor} title={'Інформація про заявника'} /> 
                }
                {/* item?.sellingEntity &&
                    <ContactPointSellingEntity data={item.sellingEntity} title={'Інформація про заявника'} /> 
                */}
                {
                   ( !!item?.documents && item.documents.length > 0 ) &&
                    <PopDownBlock
                        title={`${ item.documents.length > 0 ? 'Документи ( ' + item.documents.length + ' )' : yourRequestBadge() ? 'Додайте документи' : '' }`}
                        content={ 
                            item.documents.map( i => <ViewDocItem data={i} 
                                specs={{
                                        objId: item.id,
                                        docType: null
                                    //     historyURL: `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/asset/${id.annId}/document/${id.docId}/history`
                                    }}
                                /> )
                        }
                        openDefault={true}
                    />
                }
            </div>
        </div>
    </>)
}

export default LeaseReqestItem