import React, {useState, useEffect} from 'react';
import Button from '../../../../elements/buttons/button';
import Input from '../../../../inputs/input';
import styles from './buttonFilters.module.css';
import InputDate from '../../../../inputs/inputDate';
import { checkProp } from '../../../../../utils/check';

import InputSelect from '../../../../inputs/inputSelect'

    const Popup =( { setParams, params, setPopup } )=> {
    const [assetId, setAssetId] = useState( checkProp( params, 'assetId' ) ? params.assetId : null )
    const [title, setTitle] = useState( checkProp( params, 'title' ) ? params.title : null )
    const [description, setDescription] = useState( checkProp( params, 'description' ) ? params.description : null )
    const [assetHolder, setAssetHolder] = useState( checkProp( params, 'assetHolder' ) ? params.assetHolder : null )
    const [assetCustodian, setAssetCustodian] = useState( checkProp( params, 'assetCustodian' ) ? params.assetCustodian : null )
    const [datePublishedFrom, setDatePublishedFrom] = useState( !!params?.datePublishedFrom ? params.datePublishedFrom : null )
    const [datePublishedTo, setDatePublishedTo] = useState( !!params?.datePublishedTo ? params.datePublishedTo : null )
    const [region, setRegion] = useState( checkProp( params, 'region' ) ? params.region : null )


    const regionsList = [
        { name: 'Севастополь', value: 'Севастополь', key: "8500000000"},
        { name: 'Київ', value: 'Київ', key: "8000000000"},
        { name: 'Чернігівська область', value: 'Чернігівська область', key: "7400000000"},
        { name: 'Чернівецька область', value: 'Чернівецька область', key: "7300000000"},
        { name: 'Черкаська область', value: 'Черкаська область', key: "7100000000"},
        { name: 'Хмельницька область', value: 'Хмельницька область', key: "6800000000"},
        { name: 'Херсонська область', value: 'Херсонська область', key: "6500000000"},
        { name: 'Харківська область', value: 'Харківська область', key: "6300000000"},
        { name: 'Тернопільська область', value: 'Тернопільська область', key: "6100000000"},
        { name: 'Сумська область', value: 'Сумська область', key: "5900000000"},
        { name: 'Рівненська область', value: 'Рівненська область', key: "5600000000"},
        { name: 'Полтавська область', value: 'Полтавська область', key: "5300000000"},
        { name: 'Одеська область', value: 'Одеська область', key: "5100000000"},
        { name: 'Миколаївська область', value: 'Миколаївська область', key: "4800000000"},
        { name: 'Львівська область', value: 'Львівська область', key: "4600000000"},
        { name: 'Луганська область', value: 'Луганська область', key: "4400000000"},
        { name: 'Кіровоградська область', value: 'Кіровоградська область', key: "3500000000"},
        { name: 'Київська область', value: 'Київська область', key: "3200000000"},
        { name: 'Івано-Франківська область', value: 'Івано-Франківська область', key: "2600000000"},
        { name: 'Запорізька область', value: 'Запорізька область', key: "2300000000"},
        { name: 'Закарпатська область', value: 'Закарпатська область', key: "2100000000"},
        { name: 'Житомирська область', value: 'Житомирська область', key: "1800000000"},
        { name: 'Донецька область', value: 'Донецька область', key: "1400000000"},
        { name: 'Дніпропетровська область', value: 'Дніпропетровська область', key: "1200000000"},
        { name: 'Волинська область', value: 'Волинська область', key: "0700000000"},
        { name: 'Вінницька область', value: 'Вінницька область', key: "0500000000"},
        { name: 'Автономна Республіка Крим', value: 'Автономна Республіка Крим', key: "0100000000"},
    ]

    return (
            <div className={styles.wrapMain}>
                <div className={styles.wrap}>
                    <h3 className={styles.filterTitle}>Розширений пошук</h3>
                    <div className={styles.content}>
                        <Input 
                            label='Пошук по ідентифікатору об`єкта'
                            value={ assetId }
                            onChange={ e => setAssetId(e) }
                        />
                        {/*<Input 
                            label='Пошук по назві об`єкта'
                            value={ title }
                            onChange={ e => setTitle(e) }
                        />
                        <Input 
                            label='Пошук по опису об`єкта' 
                            value={description}
                            onChange={ e => setDescription(e)}
                        />*/}
                        <InputSelect
                            label="Регіон"
                            value={ region }
                            onChange={ event => {
                                setRegion(event)
                                //setMainClassif(event)
                                //setClassification( null )

                                setParams( prev => ({...prev, region: event }) )
                                //setParams( prev => ({...prev, classifications: event}) )

                            }}
                            options={ regionsList }
                        />
                        <Input 
                            label='Пошук по організації балансоутримувача' 
                            value={assetHolder}
                            onChange={ e => setAssetHolder(e)}
                        />
                        <Input 
                            label='Пошук по організації розпорядника об`єкта' 
                            value={assetCustodian}
                            onChange={ e => setAssetCustodian(e)}
                        />
                        <InputDate
                            data={{
                                label: 'Дата публікації, з',
                                value: datePublishedFrom,
                                onChange: e => setDatePublishedFrom( e ),
                                // time: true
                            }}
                        />
                        <InputDate
                            data={{
                                label: 'Дата публікації, по',
                                value: datePublishedTo,
                                onChange: e => setDatePublishedTo( e ),
                                // time: true
                            }}
                        />
                    </div>
                    <div className={styles.bottomPart}>
                        <Button 
                            color='gray'
                            onClick={() => setPopup( prev => !prev) }>
                            Закрити
                        </Button>
                        <Button
                            color='blue'
                            onClick={() => {
                                setParams( prev => ({...prev,
                                    assetId: assetId,
                                    title: title,
                                    description: description,
                                    assetHolder: assetHolder,
                                    assetCustodian: assetCustodian,
                                    datePublishedFrom: datePublishedFrom,
                                    datePublishedTo: datePublishedTo
                                }
                                ) )
                                setPopup( prev => !prev )
                            }}>
                            Застосувати
                        </Button>
                    </div>
                </div>
            </div>
        )
    }

const AssetAdvancedSearch = ({ setParams, params })=> {
    const [popup, setPopup] = useState( false )


    return (
        <>
            <div
                className={styles.searchFilterBlock_status}
                onClick={ () => setPopup( prev => !prev) }
            >
                <div>Розширений пошук</div>
                {/* { ( !!arr?.status && arr.status.length > 0 )&&  */}
                    <div style={{borderLeft: '1px dotted #BFBFC6', paddingLeft: '5px', marginLeft: '5px'}}>
                        {/* { arr.status.length } */}
                    </div> 
                
            </div>
            { popup && <Popup setParams={ setParams } params={ params } setPopup={ setPopup } /> }
        </>
    )
}

export default AssetAdvancedSearch
