import React from 'react';
import styles from './navPart.module.css';
import {useSelector, useDispatch} from 'react-redux';

import { Link , Route, useLocation } from 'react-router-dom'
import {changeMyCurentPage } from '../../../redux/actions.js'

const NavPart =( props ) => {

    const dispatch = useDispatch()
    const { pathname } = useLocation()

    function setTitleCurentCategory() {
        switch( true ) {
            case '/asset/assets_list'.includes(pathname):
                return 'Перелік об’єктів малої приватизації'
            case '/asset/assets_list_lp'.includes(pathname):
                return 'Перелік об’єктів великої приватизації'
            case '/asset/announcements_list'.includes(pathname):
            case '/asset/announcements_list_lp'.includes(pathname):
                return 'Інформаційні повідомлення'
            case '/asset/executions_list'.includes(pathname):
            case '/asset/executions_list_lp'.includes(pathname):
                return 'Контрактинг'
            case '/asset/redemptions_list'.includes(pathname):
            case '/asset/redemptions_list_lp'.includes(pathname):
                return 'Пріоритетний викуп'
            case '/lease/objects_list'.includes(pathname):
                return 'Перелік об’єктів: Реєстр'
            case '/lease/actions_list'.includes(pathname):
                return 'Перелік об’єктів: Дії'
            case '/lease/requests_list'.includes(pathname):
                return 'Перелік об’єктів: Заяви'
            case '/lease/contracts_list'.includes(pathname):
                return 'Перелік об’єктів: Договори'
            default:
                return null
        }
    }

    function cleaner() {
        dispatch({payload: null, type: 'AUCTIONS_GET'})
    }

    const assets = <>
                        <Link to={'/asset/assets_list'}
                            onClick={ ()=> {
                                if ( pathname !== '/asset/assets_list' ) {
                                    cleaner()
                                    // dispatch( changeMyCurentPage(1) )
                                }
                            }}
                        >
                            <div className={ pathname.indexOf('assets_list') !== -1 ? styles.activeMenuItem :  styles.topMenuItem }>
                                    Об'єкти малої приватизації
                            </div>
                        </Link>
                        <Link to={'/asset/announcements_list'}  
                            onClick={ ()=> {
                                if ( pathname !== '/asset/announcements_list' ) {
                                    cleaner()
                                    // dispatch( changeMyCurentPage(1) )
                                }
                            }}
                        >
                            <div className={ pathname.indexOf('announcements_list') !== -1 ? styles.activeMenuItem :  styles.topMenuItem }>
                                    Інформаційні повідомлення
                            </div>
                        </Link>
                        <Link to={'/asset/executions_list'}  
                            onClick={ ()=> {
                                if ( pathname !== '/asset/executions_list' ) {
                                    cleaner()
                                    // dispatch( changeMyCurentPage(1) )
                                }
                            }}
                        >
                            <div className={ pathname.indexOf('executions_list') !== -1 ? styles.activeMenuItem :  styles.topMenuItem }>
                                Контрактинг
                            </div>
                        </Link>
                        <Link to={'/asset/redemptions_list'}  
                            onClick={ ()=> {
                                if ( pathname !== '/asset/redemptions_list' ) {
                                    cleaner()
                                    // dispatch( changeMyCurentPage(1) )
                                }
                            }}
                        >
                            <div className={ pathname.indexOf('redemptions_list') !== -1 ? styles.activeMenuItem :  styles.topMenuItem }>
                                Пріоритетний викуп
                            </div>
                        </Link>
                        <a href={'https://privatization.gov.ua/mapa-ob-yektiv/'}>
                            <div className={ styles.topMenuItem }>
                                Мапа об’єктів малої приватизації
                            </div>
                        </a>
                    </>
    const assetsLp = <>
                        <Link to={'/asset/assets_list_lp'}
                            onClick={ ()=> {
                                if ( pathname !== '/asset/assets_list_lp' ) {
                                    cleaner()
                                    // dispatch( changeMyCurentPage(1) )
                                }
                            }}
                        >
                            <div className={ pathname.indexOf('assets_list_lp') !== -1 ? styles.activeMenuItem :  styles.topMenuItem }>
                                    Об'єкти великої приватизації
                            </div>
                        </Link>
                        <Link to={'/asset/announcements_list_lp'}  
                            onClick={ ()=> {
                                if ( pathname !== '/asset/announcements_list_lp' ) {
                                    cleaner()
                                    // dispatch( changeMyCurentPage(1) )
                                }
                            }}
                        >
                            <div className={ pathname.indexOf('announcements_list_lp') !== -1 ? styles.activeMenuItem :  styles.topMenuItem }>
                                    Інформаційні повідомлення
                            </div>
                        </Link>
                        <Link to={'/asset/executions_list_lp'}  
                            onClick={ ()=> {
                                if ( pathname !== '/asset/executions_list_lp' ) {
                                    cleaner()
                                    // dispatch( changeMyCurentPage(1) )
                                }
                            }}
                        >
                            <div className={ pathname.indexOf('executions_list_lp') !== -1 ? styles.activeMenuItem :  styles.topMenuItem }>
                                Контрактинг
                            </div>
                        </Link>
                        <Link to={'/asset/redemptions_list_lp'}  
                            onClick={ ()=> {
                                if ( pathname !== '/asset/redemptions_list_lp' ) {
                                    cleaner()
                                    // dispatch( changeMyCurentPage(1) )
                                }
                            }}
                        >
                            <div className={ pathname.indexOf('redemptions_list_lp') !== -1 ? styles.activeMenuItem :  styles.topMenuItem }>
                                Пріоритетний викуп
                            </div>
                        </Link>
                        {/*<a href={'https://privatization.gov.ua/mapa-ob-yektiv/'}>
                            <div className={ styles.topMenuItem }>
                                Мапа об’єктів великої приватизації
                            </div>
                        </a>*/}
                    </>

    const lease = <>
                    <Link to={'/lease/objects_list'}  
                        onClick={ ()=> {
                            if (pathname !== '/lease/objects_list') {
                                cleaner()
                                // dispatch(changeMyCurentPage(1))
                            }
                        }}
                    >
                        <div className={ pathname.indexOf('objects') !== -1 ? styles.activeMenuItem :  styles.topMenuItem }>
                            Об'єкти оренди
                        </div>
                    </Link>
                    <Link to={'/lease/actions_list'} 
                        onClick={ ()=> {
                            if (pathname !== '/lease/actions_list') {
                                cleaner()
                                // dispatch(changeMyCurentPage(1))
                            }
                        }}
                    >
                        <div className={ pathname.indexOf('actions') !== -1 ? styles.activeMenuItem :  styles.topMenuItem }>
                            Дії
                        </div>
                    </Link>
                    <Link to={'/lease/requests_list'} 
                        onClick={ ()=> {
                            if (pathname !== '/lease/requests_list') {
                                cleaner()
                                // dispatch(changeMyCurentPage(1))
                            }
                        }}
                    >
                        <div className={ pathname.indexOf('requests') !== -1 ? styles.activeMenuItem :  styles.topMenuItem }>
                            Заяви
                        </div>
                    </Link>
                    <Link to={'/lease/contracts_list'} 
                        onClick={ ()=> {
                            if (pathname !== '/lease/contracts_list') {
                                cleaner()
                                // dispatch(changeMyCurentPage(1))
                            }
                        }}
                    >
                        <div className={ pathname.indexOf('contracts') !== -1 ? styles.activeMenuItem :  styles.topMenuItem }>
                            Договори
                        </div>
                    </Link>
                </>

    function typeAssets(){
        if(pathname.indexOf('asset') !== -1){
            if(pathname.indexOf('assets_list_lp') !== -1
            || pathname.indexOf('announcements_list_lp') !== -1
            || pathname.indexOf('executions_list_lp') !== -1
            || pathname.indexOf('redemptions_list_lp') !== -1
            ) return assetsLp
            if(pathname.indexOf('assets_list') !== -1
            || pathname.indexOf('announcements_list') !== -1
            || pathname.indexOf('executions_list') !== -1
            || pathname.indexOf('redemptions_list') !== -1
            ) return assets
        }
        
    }
    return (
            <div className={styles.topSection}>
                <div className={styles.titleBlock}>
                    <h1>{ setTitleCurentCategory() }</h1>
                    {/* <div className={styles.createObjectBtn}>Додати об’єкт</div> */}
                </div>
                <div className={styles.topMenu}>
                    { typeAssets() }
                    { pathname.indexOf('lease') !== -1 && lease }
                </div>
            </div>
        )
}

export default NavPart
