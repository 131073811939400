import React, {useState, useEffect} from 'react'
import styles from './canceletionPopup.module.css'
// import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Loader from '../../../../../loader/loader';
import Input from '../../../../../inputs/input';
import InputTextArea from '../../../../../inputs/inputTextArea';
import UniPopUp from '../../../../../popups/uniPopUp/uniPopUp';
import InputDate from '../../../../../inputs/inputDate';
import Button from '../../../../../elements/buttons/button';
import { getMyAsset, uploadDocumentUrl } from '../../../../../../utils/endPoints';

const AssetCanceletionPopup = ({ data, object, close }) => {
    const token = useSelector( state => state.start.token )
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [api, setApi] = useState( { error: null, loading: false } )
    const [dropArea, setDropArea] = useState( { text: 'Перетягніть сюди, або додайте файл документу "Рішення про виключення об\'єкта з реєстру" ', style: styles.dropZone } )
    const [ cancelData, setCancelData ] = useState( { reason: '', datePublished: new Date } )

    const [docData, setDocData] = useState(
        {   docType: "cancellationDetails",
            docTitle: '',
            docDesription: '',
            upFile: null
        })

    useEffect(() => {
        document.body.style.overflow = 'hidden'
        document.body.style.paddingRight = '5px'
        return () => {
            document.body.style.overflow = 'auto'
            document.body.style.paddingRight = '0'
        }
    }, [])

    function dragStartHandler(e) {
        e.preventDefault()
        setDropArea({text: 'Відпустіть мишу для завантаження файлу', style: styles.dropZoneActive })
    }

    function dragLeaveHandler(e) {
        e.preventDefault()
        setDropArea({text: 'Додайте файл документу', style: styles.dropZone })
    }
    
    function onDporHandler(e) {
        e.preventDefault()
        setDocData( prev => ({...prev, upFile: e.dataTransfer.files[0]}))
        // setUpFile(e.dataTransfer.files[0])
        setDropArea({ style: styles.dropZoneActive })
    }

    const docProps = { 
        dispatch: ( res )=> dispatch( { payload: res, type: 'ASSET_GET_ID'}),
        docOf: 'cancellation',
        object: object,
        url: {
            post: `${ getMyAsset(object.id) }/cancel`,
        }
    }


    if ( !!api.loading ) return <Loader />
    if ( !!api.error ) return <UniPopUp type={'1'} text={ api.error } close={ ()=> setApi( prev => ({...prev, error: null }) ) } />

    return (
            <div className={styles.wrapMain}>
                {/* {   !!api.error &&
                    <UniPopUp data={ api.error.message } close={ ()=> setApi( prev => ({...prev, error: null }))} />
                } */}
                <div className={styles.wrap}>
                    <div
                        className={styles.close}
                        onClick={ close }
                    >
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path className={styles.closeSVG} fillRule="evenodd" clipRule="evenodd" d="M8.41421 6.99989L13.364 11.9496C13.7545 12.3402 13.7545 12.9733 13.364 13.3639C12.9734 13.7544 12.3403 13.7544 11.9497 13.3639L7 8.41411L2.05025 13.3639C1.65973 13.7544 1.02656 13.7544 0.636039 13.3639C0.245514 12.9733 0.245515 12.3402 0.636039 11.9496L5.58579 6.99989L0.636039 2.05015C0.245515 1.65962 0.245514 1.02646 0.636039 0.635934C1.02656 0.245409 1.65973 0.245409 2.05025 0.635934L7 5.58568L11.9497 0.635934C12.3403 0.245409 12.9734 0.245409 13.364 0.635934C13.7545 1.02646 13.7545 1.65962 13.364 2.05015L8.41421 6.99989Z" fill="#92929D"/>
                        </svg>
                    </div>
                    <div className={styles.popupTitle}>Виключення Об'єкта з реєстру</div>
                    <div className={styles.bidLotCost_input}>
                        <Input 
                            value={ cancelData.reason } 
                            onChange={ (e)=> setCancelData( prev => ({ ...prev, reason: e }) ) } 
                            label={ 'Причина виключення з реєстру' }
                            reqiured
                        />
                        <InputDate 
                            data={{
                                label: 'Дата виключення з реєстру *',
                                value: cancelData.datePublished,
                                onChange: e => setCancelData( prev => ({ ...prev, datePublished: e }) ),
                                time: true,
                            }}
                        />
                        <div className={styles.docSectionWrap}>
                            <Input 
                                value={ docData.docTitle } 
                                onChange={ (e)=> setDocData( prev => ({ ...prev, docTitle: e }) ) } 
                                label={ 'Назва документу' }
                                reqiured={true}
                            />
                            <InputTextArea 
                                value={ docData.docDesription } 
                                onChange={ (e)=> setDocData( prev => ({ ...prev, docDesription: e }) ) } 
                                label={ 'Опис документу' } 
                            />
                            <div className={styles.docBlock}>
                                <div className={dropArea.style}
                                    onDragStart={ e => dragStartHandler(e)}
                                    onDragLeave={ e => dragLeaveHandler(e)}
                                    onDragOver={ e => dragStartHandler(e)}
                                    onDrop={ e => onDporHandler(e)}
                                >
                                    <div>
                                        {docData.upFile ? <>
                                                    <div>{`Додано файл:
                                                        ${docData.upFile.name.length > 45 ? docData.upFile.name.substr(0, 50) + '...' : docData.upFile.name}`}
                                                    </div>
                                                    <div>
                                                        {`розмір: ${(docData.upFile.size / 1000000).toFixed(2)} Mb`}
                                                    </div>
                                                </> : dropArea.text
                                        }
                                    </div>
                                    <label className={styles.btnPin}>Прикріпити
                                        <input type="file"
                                            name="file"
                                            id="inputFile"
                                            onInput={(e) => {
                                                e.preventDefault()
                                                setDocData( prev => ({ ...prev, upFile: e.target.files[0] }))
                                                setDropArea({ style: styles.dropZoneActive })
                                            }}>
                                        </input>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.bottomPart}>
                        <Button 
                            color={'blue'}
                            onClick={ ()=> postCanceletions( token, close, docData, setApi, docProps, cancelData, navigate )}
                            disabled={ 
                                cancelData.reason === ""
                                || cancelData.reason === null
                                || cancelData.datePublished === ""
                                || cancelData.datePublished === null
                                || !docData.upFile ? true : false}
                        >
                            Виключити об'єкт з реєстру
                        </Button>
                        <Button 
                            color={'gray'}
                            onClick={ close }
                        >
                            Скасувати
                        </Button>
                    </div>
                </div>
            </div>
    )
}

// async function postCanceletions( url, body, dispatch, token, setApi, docData ){
//     setApi( prev => ({ ...prev, loading: true }))
//     try {
//         const data = await axios.post( `${url}/cancel`, body,
//             {   headers: {
//                 'Content-Type': 'application/json',
//                 'Authorization': `Bearer ${token.access_token}`
//             }}
//         )
//             dispatch({ payload: data.data, type: 'ASSET_GET_ID' })

//     } catch (err) {
//         setApi( prev => ({ ...prev,
//             error: err,
//             loading: false
//         }))
//     }
//     window.scrollTo(0, 0)
//     setApi( prev => ({ ...prev, loading: false }))
// }

async function postCanceletions( token, close, docData, setApi, docProps, cancelData, navigate ){
    await setApi( prev => ({...prev, loading: true}))
    const formData = new FormData();
    formData.append('documentType', docData.docType)
    let uPFile = docData.upFile
    formData.append('file', uPFile)
    const response = await fetch( uploadDocumentUrl , {
        method: "POST",
        body: formData,
        headers: {
            'Authorization': token.access_token,
        }
    }).catch( error => setApi( prev => ({...prev, error: error })) )
    if( typeof response === 'undefined' || response === 'undefined'){
        setApi( prev => ({...prev, error: 'При збереженні файлу виникла помилка :(' }))
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при збереженні файлу', message: JSON.stringify(json)})
            setApi( prev => ({...prev, error: tmp }))
            await setApi( prev => ({...prev, loading: false }))
        }else{

            let cancelDoc = {
                title: { uk_UA: docData.docTitle.trim() },
                language: 'uk',
                documentOf: 'cancellation',
                token: json.jwt,
                documentType: docData.docType
            }
            if( !!docData.docDesription.trim() ) cancelDoc.description = { uk_UA: docData.docDesription.trim() }

            let body = {
                reason: {
                    uk_UA: cancelData.reason,
                },
                datePublished: cancelData.datePublished,
                documents: [ cancelDoc ]
            }
            
            const response1 = await fetch( docProps.url.post, {
                method: "POST",
                body: JSON.stringify(body),
                headers: {
                    'Authorization': token.access_token,
                    'Content-Type': 'application/json'
                }
            }).catch(console.error)
            if( typeof response1 === 'undefined' || response1 === 'undefined' ){
                setApi( prev => ({...prev, error: 'При збереженні файлу виникла помилка :(' }))
            }else{
                const json2 = await response1.json()
                if(json2.hasOwnProperty('message') === true){
                    let tmp = JSON.stringify({title: 'Помилка при збереженні документу', message: JSON.stringify(json2)})
                    setApi( prev => ({...prev, error: tmp }))
                    await setApi( prev => ({...prev, loading: false}))
                }else{
                    // dispatch( { payload: json2, type: 'ASSET_GET_ID'})
                    docProps.dispatch(json2)
                }
            }
        }
    }
    await setApi( prev => ({...prev, loading: false }) )
    navigate(`/asset/${docProps.object.assetId}`)
    close()
}

export default AssetCanceletionPopup