import React, {useState, useEffect} from 'react';
import Button from '../../../../elements/buttons/button';
import Input from '../../../../inputs/input';
import styles from './buttonFilters.module.css';
import InputDate from '../../../../inputs/inputDate';
import { checkProp } from '../../../../../utils/check';

    const Popup =({ setParams, params, setPopup })=> {
        const [leaseActionId, setLeaseActionId] = useState( checkProp( params, 'leaseActionId' ) ? params.leaseActionId : null )
        const [description, setDescription] = useState( checkProp( params, 'description' ) ? params.description : null )
        const [relatedObjectId, setRelatedObjectId] = useState( checkProp( params, 'relatedObjectId' ) ? params.relatedObjectId : null )
        const [relatedRequestId, setRelatedRequestId] = useState( checkProp( params, 'relatedRequestId' ) ? params.relatedRequestId : null )
        const [relatedActionId, setRelatedActionId] = useState( checkProp( params, 'relatedActionId' ) ? params.relatedActionId : null )
    
        const [datePublishedFrom, setDatePublishedFrom] = useState( !!params?.datePublishedFrom ? params.datePublishedFrom : null )
        const [datePublishedTo, setDatePublishedTo] = useState( !!params?.datePublishedTo ? params.datePublishedTo : null )

        return (
                    <div className={styles.wrapMain}>
                        <div className={styles.wrap}>
                            <h3 className={styles.filterTitle}>Розширений пошук</h3>
                            <div className={styles.content}>
                                <Input 
                                    label='Пошук по ідентифікатору дії'
                                    value={ leaseActionId }
                                    onChange={ e => setLeaseActionId(e) }
                                />
                                <Input 
                                    label='Пошук по опису дії' 
                                    value={ description }
                                    onChange={ e => setDescription(e) }
                                />
                                <Input 
                                    label='Пошук по ідентифікатору пов`язаного об`єкту' 
                                    value={ relatedObjectId }
                                    onChange={ e => setRelatedObjectId(e) }
                                />
                                <Input 
                                    label='Пошук по ідентифікатору пов`язаної заяви' 
                                    value={ relatedRequestId }
                                    onChange={ e => setRelatedRequestId(e) }
                                />
                                <Input 
                                    label='Пошук по ідентифікатору пов`язаної дії' 
                                    value={ relatedActionId }
                                    onChange={ e => setRelatedActionId(e) }
                                />
                                <InputDate
                                    data={{
                                        label: 'Дата публікації, з',
                                        value: datePublishedFrom,
                                        onChange: e => setDatePublishedFrom( e ),
                                        // time: true
                                    }}
                                />
                                <InputDate
                                    data={{
                                        label: 'Дата публікації, по',
                                        value: datePublishedTo,
                                        onChange: e => setDatePublishedTo( e ),
                                        // time: true
                                    }}
                                />
                            </div>
                            <div className={styles.bottomPart}>
                                <Button 
                                    color='gray'
                                    onClick={() => setPopup( prev => !prev) }>
                                    Закрити
                                </Button>
                                <Button
                                    color='blue'
                                    onClick={() => {
                                        setParams( prev => ({...prev,
                                            leaseActionId: leaseActionId,
                                            description: description,
                                            relatedObjectId: relatedObjectId,
                                            relatedRequestId: relatedRequestId,
                                            relatedActionId: relatedActionId,
                                            datePublishedFrom: datePublishedFrom,
                                            datePublishedTo: datePublishedTo,
    
                                        }
                                        ) )
                                        setPopup( prev => !prev )
                                    }}>
                                    Застосувати
                                </Button>
                            </div>
                        </div>
                    </div>
                )
    }

const LeaseActionsAdvancedSearch = ({ setParams, params })=> {
    const [popup, setPopup] = useState( false )

    return (
        <>
            <div
                className={styles.searchFilterBlock_status}
                onClick={ () => setPopup( prev => !prev) }
            >
                <div>Розширений пошук</div>
                {/* { ( !!arr?.status && arr.status.length > 0 )&&  */}
                    <div style={{borderLeft: '1px dotted #BFBFC6', paddingLeft: '5px', marginLeft: '5px'}}>
                        {/* { arr.status.length } */}
                    </div> 
                
            </div>
            { popup && <Popup setParams={ setParams } params={ params } setPopup={ setPopup } /> }
        </>
    )
}

export default LeaseActionsAdvancedSearch
