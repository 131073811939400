import { getAPI, deleteAPI, patchAPI, postAPI } from "../../redux/actions/crud";
import { OBJECT_GET } from "../../redux/types";
import { isDraftObject } from "../check";
import { myDraftObjectItemPost, myDraftObjectItem, myObjectItemPost, myObjectItem } from "../endPoints";

//ITEMS
export function objectItemCreate( id, body, token ){
    const data = {
        url: isDraftObject(id) ? myDraftObjectItemPost( id ) : myObjectItemPost( id ),
        actionType: OBJECT_GET,
        body: body,
        header: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        },
    }
    return postAPI(data);
}

export function objectItemUpdate( objId, id, body, token ){
    const data = {
        url: isDraftObject(objId) ? myDraftObjectItem( objId, id ) : myObjectItem( objId, id ),
        actionType: OBJECT_GET,
        body: body,
        header: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        },
    }
    return patchAPI(data);
}

export function objectItemDelete( objId, id, token ){
    const data = {
        url: isDraftObject(objId) ? myDraftObjectItem( objId, id ) : myObjectItem( objId, id ),
        actionType: OBJECT_GET,
        header: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        },
    }
    return deleteAPI(data);
}

export function objectContractDelete( objId, id, token ){
    const data = {
        url: isDraftObject(objId) ? myDraftObjectItem( objId, id ) : myObjectItem( objId, id ),
        actionType: OBJECT_GET,
        header: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        },
    }
    return deleteAPI(data);
}

export function getLeaseObjectDocTypes( list, type ){
    let result = [
        { value: 'contractProforma', name: 'Проект договору оренди', main: false },
        { value: 'illustration', name: 'Фотографічне зображення майна', main: false },
        // { value: 'notice', name: 'Паспорт торгів', main: false },
        { value: 'technicalSpecifications', name: 'Технічні специфікації', main: false },
        // { value: 'evaluationCriteria', name: 'Кваліфікаційні вимоги', main: false },
        { value: 'x_presentation', name: 'Презентація', main: false },
        { value: 'x_approvedDocument', name: 'Копія рішень, дозволів та згоди', main: false },
        { value: 'x_agreementCopy', name: 'Копії договорів', main: false },
        { value: 'x_leaseObjectInformation', name: 'Характеристики та документи об’єкта оренди', main: false },
        { value: 'x_assessmentDocument', name: 'Документи щодо оцінки об\'єкта', main: false },
        { value: 'x_currentTenantDocument', name: 'Документи щодо прав чинного орендаря', main: false },
        // { value: 'cancellationDetails', name: 'Причини скасування', main: false },
    ]
    if ( list ) return result
    for ( let key in result ){
        if ( type === result[key].value) return result[key].name
    }
}

let result = [
    { value: 'contractProforma', name: 'Проект договору оренди', main: false },
    { value: 'illustration', name: 'Фотографічне зображення майна', main: false },
    { value: 'notice', name: 'Паспорт торгів', main: false },
    { value: 'technicalSpecifications', name: 'Технічні специфікації', main: false },
    { value: 'evaluationCriteria', name: 'Кваліфікаційні вимоги', main: false },
    { value: 'x_presentation', name: 'Презентація', main: false },
    { value: 'x_approvedDocument', name: 'Копія рішень, дозволів та згоди', main: false },
    { value: 'x_agreementCopy', name: 'Копії договорів', main: false },
    { value: 'x_leaseObjectInformation', name: 'Характеристики та документи об’єкта оренди', main: false },
    { value: 'x_assessmentDocument', name: 'Документи щодо оцінки об\'єкта', main: false },
    { value: 'x_currentTenantDocument', name: 'Документи щодо прав чинного орендаря', main: false },
]