import React, {useState, useEffect} from 'react';
import styles from './actionCreate.module.css';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// import {connect} from 'react-redux';

import InputSelect from '../../inputs/inputSelect';
import InputTextArea from '../../inputs/inputTextArea';
import PopDownBlock from '../../elements/popdownBlock/popDownBlock';
import Documents from '../actionCreate/documents/documentsAction'
import { actionRelatedObjects, createAction, updateAction, actionsGetType, actionsGetEntityRole } from '../../../redux/actions/leaseActions/leaseActions.js';

const ActionCreate = ( props ) => {

    const dispatch = useDispatch();
    const token = useSelector(state => state.start.token);
    const relatedObjects = useSelector(state => state.start.actionRelatedObjects);
    const actionCreate = useSelector(state => state.start.actionCreate);
    const actionId = useSelector(state => state.start.leaseActionGetId);
    const actionTypesList = useSelector(state => state.start.actionTypesList);
    const actionEntityRolesList = useSelector(state => state.start.actionEntityRolesList);

    const [relatedObjectTitle, setRelatedObjectTitle] = useState('');
    const [actionType, setActionType] = useState('');
    const [actingEntityRole, setActingEntityRole] = useState('');
    const [description, setDescription] = useState('');
    const [errorType, setErrorType] = useState('');
    const [errorEntityRole, setErrorEntityRole] = useState('');

    const urlActionTypeList = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/public/dictionaries/actionType`;
    const urlActingEntityRoleList = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/public/dictionaries/actingEntityRole`;

    if (window.location.href.indexOf('create') != -1) {
        var pageType = 'create';
    } else {
        var pageType = 'update';
    }

    useEffect(() => {
        if(actionTypesList === null || actionTypesList === undefined) {
            dispatch(actionsGetType(urlActionTypeList));
        }
        if(actionEntityRolesList === null || actionEntityRolesList === undefined) {
            dispatch(actionsGetEntityRole(urlActingEntityRoleList));
        }
    }, []);
    useEffect(() => {
        checkErrors();
    });

    function getRelated() {
        if (window.location.search) {
            let relatedSearch = window.location.search.split('?')[1].split('&');
            let data = {};
            relatedSearch.forEach((item) =>  {
                let relatedItem = item.split('=')[0];
                let relatedItemId = item.split('=')[1];
                if (relatedItem == 'relatedObject') {
                    data.object = relatedItemId;
                } else if (relatedItem == 'relatedAction') {
                    data.action = relatedItemId;
                } else if (relatedItem == 'relatedRequest') {
                    data.request = relatedItemId;
                }
            });
            dispatch(actionRelatedObjects(data));
        } else {
            window.location = '/actions';
        }
    }

    function createLeaseAction(action) {
        if (token !== null) {
            let data = {};
            data.actingEntityId = 0;
            data.actingEntityRole = actingEntityRole;
            data.type = actionType;
            data.description = description;
            data.relatedObjectId = props.relatedObjectId ? props.relatedObjectId : '60940669e6dff0a901fe2239';
            data.relatedRequestId = props.relatedRequestId ? props.relatedRequestId : '';
            data.relatedActionId = props.relatedActionId ? props.relatedActionId : '';
            if (action == 'create') {
                data = JSON.stringify(data);
                let urlCreateAction = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/lease_action`;
                let urlRedirect = '/my_actions';
                dispatch(createAction(urlCreateAction, data, token.access_token, urlRedirect));
            } else {
                data = JSON.stringify(data);
                let urlUpdateAction = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/lease_action/${actionId.id}`;
                let urlRedirect = '/my_actions';
                dispatch(updateAction(urlUpdateAction, data, token.access_token, urlRedirect));
            }
        } else {
            alert('Авторизуйтесь');
        }
    }
    function checkErrors() {
        if(actionCreate) {
            if(actionCreate.hasOwnProperty('message') === true) {
                //Reset errors
                setErrorType('');
                setErrorEntityRole('');
                //New errors
                for (let key in actionCreate.message) {
                    if (key == 'actingEntityRole') {
                        setErrorEntityRole(actionCreate.message[key]);
                    } else if (key == 'type') {
                        setErrorType(actionCreate.message[key]);
                    }
                }
            } else {
                //Reset errors
                setErrorType('');
                setErrorEntityRole('');
            }
        }
    }

    function RelatedBlock(props) {
        return (
            <div className={styles.relatedObjectWrapper}>
                <div className={styles.relatedObjectDescription}>{props.description}</div>
                <Link to={`/lease/object/${props.id}`} className={styles.relatedObjectLink}>{props.id}</Link>
            </div>
        )
    }

    function RelatedObjects() {
        if(props.relatedActionId) {
            return (<>
                <RelatedBlock type="object" title={relatedObjectTitle} description={`Пов'язаний об'єкт`} id={relatedObjects.object}/>
                <RelatedBlock type="action" description="Пов'язана дія" />
            </>)
        } else if (props.relatedRequestId) {
            return (<>
                <RelatedBlock type="object" title={relatedObjectTitle} description="Пов'язаний об'єкт" id={relatedObjects.object}/>
                <RelatedBlock type="request" description="Пов'язана заява" />
            </>)
        } else {
            return <RelatedBlock type="object" title={relatedObjectTitle} description={`Пов'язаний об'єкт`}   />
        }
    }

    return (
        <>
            <div className={styles.wrap}>
                <div className={styles.title}>{pageType == 'create' ? 'Додавання дії' : 'Оновлення дії'}</div>
                <InputSelect label="Тип дії *" error={errorType} onChange={setActionType} dictionary={true} options={actionTypesList} id="actionType" text="Оберіть" />
                <InputSelect label="Роль організації, що виконує дію *"  error={errorEntityRole} onChange={setActingEntityRole} dictionary={true} options={actionEntityRolesList} id="actingEntityRole" text="Оберіть" />
                <InputTextArea id="actionDescription" onChange={setDescription} placeholder="Опис дії *" />
                {pageType == 'create' ?
                    <PopDownBlock 
                        title={'Пов’язані об’єкти (' + (props.relatedActionId || props.relatedRequestId ? 2 : 1) + ')'}
                        content={<RelatedObjects />} 
                    />
                : null}
                <PopDownBlock 
                    title={'Документи (' + "0" + ')'}
                    content={
                        <Documents data={actionId.documents} />
                    }
                />
            </div>
            <div className={styles.buttonWrapper}>
                <Link to={'lease_actions'} className={styles.buttonCancel}>Скасувати</Link>
                <button 
                    onClick={() => createLeaseAction(pageType)} 
                    className={styles.buttonSave}>Зберегти та продовжити
                </button>
            </div>
        </>
    );

}

export default ActionCreate;