import React, { useState} from 'react';
import styles from './login.module.css';
import { useSelector, useDispatch } from 'react-redux';
// import { useLocation } from 'react-router-dom';
import { useAuth } from '../../../hook/useAuth';

import {LOGIN_TITLE_TOP, LOGIN_TITLE_BOTTOM_FIRST, LOGIN_TITLE_BOTTOM_SECOND} from '../../../model/lang/topMenu.js';

import {localClearToken, setProfileNull, setIntervalRef, setSinglPasword} from '../../../redux/actions/default/default';
import {setPopUpLogin, setTogetherApi} from '../../../redux/actions.js';

import svgSetting from './user_menu_settings.svg';
import svgNotif from './user_menu_notifications.svg';
import svgExit from './user_menu_exit.svg';

import PopUpLogin from './popupLogin/login';

const Login = () => {

    const dispatch = useDispatch();
    const [redirect, setRedirect] = useState(false)
    const langFlag = useSelector(state => state.start.langFlag);
    const token = useSelector(state => state.start.token);
    const popUpStatus = useSelector(state => state.start.popUpStatus);
    const keySetIntervalRefresh = useSelector(state => state.start.keySetIntervalRefresh);
    const togetherApi = useSelector(state => state.start.togetherApi);
    const singlPassword = useSelector(state => state.start.singlPassword);
    // const location = useLocation()
    const { signout } = useAuth()

    return (
        <div className={styles.wrapLogin} >
            {popUpLoginForm()}
            {viewButtonLogin()}
        </div>
    )

    function popUpLoginForm(){
        if(popUpStatus.login === true) return <PopUpLogin />
    }
    
    function viewButtonLogin() {
        let tmp = null;
        if(token === null) {
            tmp = (
                <div className={styles.beforeLogin}>
                    <div
                        className={styles.login}
                        onClick={ () => { dispatch( setPopUpLogin(true) ) }}
                    >
                        { LOGIN_TITLE_TOP[langFlag] }
                    </div>
                    <div className={styles.register}>
                        {LOGIN_TITLE_BOTTOM_SECOND[langFlag]}
                        &nbsp;
                        <a href="https://www.dto.com.ua/registration/sellers" className={styles.signin}>
                            {LOGIN_TITLE_BOTTOM_FIRST[langFlag]}
                        </a>
                    </div>
                </div>
            )
        } else {
            tmp = (
                <>
                    <div className={styles.afterLogin}>
                        <div className={styles.exit}></div>
                        <img className={styles.svgLink} src={svgSetting} alt=''/>
                        <img className={styles.svgLink} src={svgNotif} alt=''/>
                        <img 
                            className={styles.svgLink}
                            src={svgExit}
                            onClick={
                                () => {
                                    dispatch(setProfileNull(null));
                                    dispatch(localClearToken());
                                    if(keySetIntervalRefresh !== null) {
                                        clearInterval(keySetIntervalRefresh);
                                        dispatch(setIntervalRef(null));
                                    }
                                    if (togetherApi === true) dispatch(setTogetherApi());
                                    setRedirect(true)
                                    signout()
                                }
                            }
                            alt=''
                        />
                        {/* <div>
                            <div
                            className={styles.singlUsePassword}
                            onClick={
                                ()=>{
                                    getOneUsePassword()
                                }
                            }
                        >
                            Створити пароль разового доступу
                            </div>
                        </div> */}
                    </div>
                    {popUpSinglePass()}
                </>
            )
        }
        return tmp
    }
    
    function popUpSinglePass(){
        if(singlPassword !== null){
            return (
                <div className={styles.wrapBidPopUPEditDoc}>
                    <div className={styles.wrapBidPopUPEditDoc_blank}>
                        <div className={styles.wrapBidPopUPEditDoc_text}>
                            Пароль відправлено на вашу пошту
                        </div>
                        <div
                            className={styles.login + ' ' + styles.closeSingolPass}
                            onClick={() => {
                                dispatch(setSinglPasword(null));
                            }}
                        >
                            Закрити
                        </div>
                    </div>
                </div>
            )
        }
    }
    
    async function getOneUsePassword() {
        const url = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/user/temporary_pass`;
        const response = await fetch(url, {
            method: "GET",
            headers: {
                'Authorization': 'Bearer' + token.access_token,
                'Content-Type': 'application/json'
            }
        }).catch(console.error)
        if( typeof response === 'undefined') {

        } else {
            const json = await response.json()
            if(json.hasOwnProperty('message') === true) {
                //let tmp = JSON.stringify({title: 'Помилка при відхиленні аварду', message: JSON.stringify(json2)})
                //props.setUniversalError(tmp)
            } else if( json.hasOwnProperty('success') === true) {
                dispatch(setSinglPasword('Пароль надіслано на вашу пошту'));
            }
        }
    }
}

export default Login;