import styles from './myObjects.module.css';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';


import ObjectTabItem from './myObjTabItem';
import Loader from '../../../loader/loader';
import { objectGetTypes } from '../../../../redux/actions/object/object';
import { setPageCreate, objectClear } from '../../../../redux/actions/object/object';
// import PaginatorSimple from '../../paginator/paginatorSimple';
import { checkProp } from '../../../../utils/check';
import { myObjectsList } from '../../../../utils/endPoints';
import Paginator from '../../paginator/paginatorTest';
// import SearchSortFilter from '../elems/sortFilter';
import MyObjectsFilter from '../elems/myObjectsFilter';
import Button from '../../../elements/buttons/button';
// import Input from '../../../inputs/input'

const MyObjectsHeader = () => {
    return (
        <>
            <div className={styles.myObjectsTable}>
                <div className={styles.tabHead}>
                    <div className={styles.thName}>Назва</div>
                    <div className={styles.thStat}>Тип майна /<br/> Тип переліку</div>
                    <div className={styles.thStatus}>Статус</div>
                    <div className={styles.thRent}>Орендна ставка</div>
                    {/* <div className={styles.thAction}>Дій</div>
                    <div className={styles.thDeclaration}>Заяв</div> */}
                    <div className={styles.thActions}>Дії</div>
                </div>
            </div>
        </>
    )
}

const MyObjects = () => {
    const dispatch = useDispatch(); 
    const token = useSelector(state => state.start.token);
    const userProfile = useSelector(state => state.start.userProfiles);
    const myObjectsList = useSelector(state => state.start.myObjectsList);
    const objectTypesList = useSelector(state => state.start.objectTypesList);
    const urlObjectTypesList = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/public/dictionaries/legitimateLeaseItemType`;

    const [ keyword, setKeyword] = useState('')
    const [ params, setParams ] = useState({
        limit: 10,
        offset: 0,
        order: 'DESC',
        // keyword: null
    })

    const navigate = useNavigate()

    useEffect(() => {
        // if (token !== null) {
            // dispatch(objectsGetMy(urlMyObjects, token.access_token));
            if(objectTypesList === null) {
                dispatch(objectGetTypes(urlObjectTypesList));
            }
        // }
        return () => {
            dispatch({type: 'OBJECTS_GET_MY', payload: null})
        }
    }, []);

    useEffect(() => {
        if (token !== null) {
            getObjectsList( params, token.access_token, dispatch )
        }
    }, [ params ]);

    function setObjectsList(list) {
        let objectsList;
        if (list !== null && list !== undefined) {
            if (list.length > 0) {
                objectsList = list.map( item => {
                return <ObjectTabItem 
                            key={ item.publishedId ? item.publishedId : item.draftId }
                            data={ item.publishedId ? item.publishedObject : item.draftObject } 
                            types={ objectTypesList } 
                        />
            });
            } else {
                objectsList = <div className={styles.tableEmpty}>У Вас немає жодного об'єкта.</div>
            }
        } else {
            objectsList = <Loader />
        }
        return objectsList
    }

    // if( !!userProfile && userProfile.canCreateObjects === false ) return navigate("/lease/objects_list")

    return (
        <div className={styles.wrapper}>
            <div className={styles.titleWrapper}>
                <div className={styles.title}>Мої об’єкти оренди</div>
                <div className={styles.sort}>
                    {/* <MyObjectsFilter params={ params } setParams={ setParams } /> */}
                </div>
                { checkProp(userProfile, 'canCreateObjects') && userProfile.canCreateObjects ? 
                    <Link
                        to={'/lease/create'}
                        className={styles.buttonCreate}
                        onClick={() => {
                                dispatch(setPageCreate('create'))
                                dispatch(objectClear())
                            }
                        }
                    >
                        <Button height={40}>Створити об’єкт</Button>
                    </Link> : null 
                }
            </div>
            <div className={styles.tableContent}>
                <MyObjectsHeader/>
                { setObjectsList(myObjectsList) }
                <Paginator params={ params } setParams={ setParams } list={ myObjectsList } />
            </div>
        </div>
    )
}

export async function getObjectsList( params, token, dispatch ){
    dispatch({ payload: true, type: 'SET_LOADER' })
    try {
        const data = await axios.get( myObjectsList, 
          { headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
            },
            params: params
          })
          dispatch({ payload: data.data, type: 'OBJECTS_GET_MY' })
    } catch (err) {
    }
    window.scrollTo(0, 0)
    dispatch({ payload: false, type: 'SET_LOADER' })
}

export default MyObjects