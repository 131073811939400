import React,{useState} from 'react';
import styles from './popDownBlock.module.css';

const PopDownBlock = ( { openDefault = false, title, content } ) => {
    const [open, setPopDown] = useState( openDefault )

    function popDownToggle(){
        setPopDown( prev => !prev )
    }

    return (
            <div className={styles.popDownBlock}>
                <div className={styles.popDownTitle}
                    onClick={popDownToggle}>
                    { title }
                    <svg className={open ? styles.svgChevUP : styles.svgChevron} width="12" height="7"xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 7">
                        <g><g><path className={styles.svgIcon} fill="#92929d" fillRule="evenoddd" d="M6,4.59,10.29.29a1,1,0,1,1,1.42,1.42l-5,5a1,1,0,0,1-1.42,0l-5-5A1,1,0,0,1,1.71.29Z"/></g></g>
                    </svg>
                </div>
                <div className={open ? styles.popDownContent : styles.hidden}>
                    { content }
                </div>
            </div>
    )
}

export default PopDownBlock