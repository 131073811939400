import React, {useState} from 'react';
import styles from './leaseReqBlock.module.css';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import FullInfoBlock from '../../../../elements/fullInfoBlock/fullInfoBlock'
import HalfInfoBlock from '../../../../elements/halfInfoBlock/halfInfoBlock';
// import DocItem from '../docItem/documentItem';
import ViewDocItem from '../../../../elements/documents/view/viewDocumentItem';
import PopDownBlock from '../../../../elements/popdownBlock/popDownBlock';
import ContactPoint from '../../../../elements/contactPoint/contactPoint';

import { checkProp, dateTransform } from '../../../../../utils/check';

import EditIcon from '../../../../elements/svg/editlIcon';
import DeleteIcon from '../../../../elements/svg/deleteIcon';
import Button from '../../../../elements/buttons/button';
import { setPopupAddDocument } from '../../../../../redux/actions/documents/documents';
import PopupAddDocument from '../../../../popups/popupAddDocument/popupAddDocument';
import UniPopUp from '../../../../popups/uniPopUp/uniPopUp';
import PopupLeaseRequest from './popupLeaseRequest/popupLeaseRequest';
import { setPopupLeaseRequest } from '../../../../../redux/actions/leaseRequests/leaseRequests';
import { deleteDocument } from '../docItem/utils';
import { myLeaseReqDocumentsPost } from '../../../../../utils/endPoints';


const LeaseReqestItem = ({item, obj, ...props}) => {
    const [askDelete, setaAskDelete] = useState(false)

    const dispatch = useDispatch()
    const popupAddDocument = useSelector(state => state.start.popupAddDocument);
    const popupAddLeaseRequest = useSelector(state => state.start.popupAddLeaseRequest)
    const token = useSelector(state => state.start.token);
    const user = useSelector(state => state.start.userProfiles)
    const navigate = useNavigate()
    //TODO:Тут надо продумать вывод заявителя и документов. т.к. это не пубдличная инфа

    // function draftBtn(item) {
    //     if (true) {
    //         return (
    //             <div className={styles.draftBtnBlock}>
    //                 {sendToRpozorroBtn(item)}
    //                 <div className={styles.editIcon}
    //                     onClick={ ()=> dispatch(setPopupLeaseRequest(true)) }
    //                 >
    //                     <EditIcon 
    //                         style={styles.svgIcon}
    //                     />
    //                 </div>
    //                 <div className={styles.deleteIcon}
    //                     onClick={ ()=> setaAskDelete(true)}
    //                 >
    //                     <DeleteIcon 
    //                         style={styles.delIcon} 
    //                         widthHeight={[20,20]}
    //                     />
    //                 </div>
    //             </div>
    //         )
    //     }
    // }

    function yourRequestBadge() {
        let status = false
        if (!user) return null
        if (user && item && item.sellingEntity) {
            if ( checkProp(item.sellingEntity, 'identifier') && item.sellingEntity.identifier.id === user.organization.identifier.identifier) {
                status = true
            }
        }
        if (status) return <div className={styles.yourObjBadge}>Ваша заява</div>
        else return null 
    }

    // function sendToRpozorroBtn(item){
    //     let closed = true
    //     if( item.documents.length > 0 && !!item.type && item.description.length > 0) closed = false

    //     let url = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/lease_request/${item.id}/send`

    //     const sender =()=> {
    //         fetch(url, 
    //             {
    //             method: 'GET',
    //             headers: {
    //                 'Authorization': token.access_token,
    //                 'Content-Type': 'application/json'
    //             },
    //         }
    //         )
    //         .then( response => {
    //             return response.json()
    //         })
    //         .then( data => {
    //             if(data) window.location.reload()
    //         })
    //         .catch(( e ) => console.log(e));
    //     }

    //     return (
    //         <Button
    //             font='14px'
    //             height='38px'
    //             disabled={closed}
    //             onClick={ sender }
    //         >
    //             Надіслати в Prozorro
    //         </Button>
    //     )
    // }

    // async function deleteDraftRequest(id){
    //     const response = await fetch(`${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/lease_request/${id}`,
    //      {
    //         method: 'DELETE',
    //         headers: {
    //             'Authorization': 'Bearer ' + token.access_token,
    //             'Content-Type': 'application/json',
    //         }
    //     });
    //     const json = await response.json();
       
    //     setaAskDelete(false)
    //     if(json.success) navigate('/my_requests')
    // }

    function setRequestType(a) {
        switch(a) {
            case 'newLease':
                return 'Заява про включення до переліку'
            case 'lease':
                return 'Заява про включення до переліку'
            case 'agreementExtension':
                return 'Заява про продовження договору оренди'
            default:
                return a
        }
    }

    // function getDocuments(a) {
    //     const req = item
    //     let tmp = a.map(( doc ) => {
    //         if(yourRequestBadge()){
    //          return (
    //             <DocItem
    //                 data={doc}
    //                 deleteDoc={ () => deleteDocument(doc, req, token.access_token, () => navigate(`/lease/lease_request/${item.id}`)) }
    //             />
    //         )
    //          } else {
    //             return <DocItem data={doc} />
    //          }
    //     })
    //     return <>
    //                 <div className={styles.bottomBtnBlock}>
    //                     { yourRequestBadge() &&
    //                         <Button
    //                             color='blue'
    //                             onClick={() => dispatch(setPopupAddDocument(true))}
    //                         >
    //                             Додати документ
    //                         </Button>
    //                     }
    //                 </div>
    //                 <div className={styles.docListBlock}>
    //                     {tmp}
    //                 </div>
    //             </>
    // }

    const statusDraft = <div style={{color: 'gray', fontWeight: '600'}}>ЧЕРНЕТКА</div>
    const statusActive = <div style={{color: 'gray', fontWeight: '600'}}>ОПУБЛІКОВАНО</div>

    return (<>
        {/* <a name={item.leaseRequestId}></a> */}
        <div className={styles.infoBlock}>
            <div className={styles.infoBlock}>
                <div className={styles.infoBlockTitle}>
                    <h3>Заява</h3>
                    {/* { yourRequestBadge() && draftBtn(item)} */}
                </div>
                <div className={styles.content}>
                    {checkProp(item, 'leaseRequestId')
                        ? <HalfInfoBlock
                            title={'Ідентифікатор у ЦБД'}
                            data={item.leaseRequestId}
                        /> : null
                    }
                    <HalfInfoBlock
                            title={'Статус'}
                            data={item.leaseRequestId === null ? statusDraft : statusActive }
                    />
                    {checkProp(item, 'type')
                        ? <FullInfoBlock
                            title={'Тип заяви'}
                            data={setRequestType(item.type)}
                        /> : null
                    }
                    {checkProp(item, 'datePublished')
                        ? <HalfInfoBlock
                            title={'Дата публікації'}
                            data={dateTransform(item.datePublished, 'time')}
                        /> : null
                    }
                    {checkProp(item, 'dateModified')
                        ? <HalfInfoBlock
                            title={'Остання дата зміни'}
                            data={dateTransform(item.dateModified, 'time')}
                        /> : null
                    }
                    {(checkProp(item, 'description') && item.description !== null)
                        ? <FullInfoBlock
                            title={'Опис заяви'}
                            data={item.description}
                        /> : null
                    }
                </div>
                {(checkProp(item, 'requestor') && item.requestor !== null)
                        ?
                    <ContactPoint data={item.requestor} title={'Інформація про заявника'} /> 
                        : null
                }
                {
                   ( !!item?.documents && item.documents.length > 0 ) &&
                    <PopDownBlock
                        title={`${ item.documents.length > 0 ? 'Документи ( ' + item.documents.length + ' )' : yourRequestBadge() ? 'Додайте документи' : ''}`}
                        content={ 
                            item.documents.map( i => <ViewDocItem data={i} /> )
                        }
                        openDefault={true}
                    />
                }
            </div>
        </div>
        {/* {popupAddDocument === true && item !== null && 
            <PopupAddDocument 
                // data={}
                type={'lease_request'} 
                url={ myLeaseReqDocumentsPost(item.id) } 
                id={item.id} 
            />
        }
        { askDelete &&
            <UniPopUp
                close={() => setaAskDelete(false)}
                action={() => deleteDraftRequest(item.id)}
                type={2}
                data={`Ви дійсно хочете видалити заяву?`}
            />  
        }
        { popupAddLeaseRequest && <PopupLeaseRequest data={item} /> } */}
    </>)
}

export default LeaseReqestItem