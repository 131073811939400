import React, { useEffect } from 'react';
import styles from '../generalInfoBlock.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { checkProp, dateTransform, isMongo } from '../../../../../../utils/check';
import FullInfoBlock from '../../../../../elements/fullInfoBlock/fullInfoBlock'
import HalfInfoBlock from '../../../../../elements/halfInfoBlock/halfInfoBlock'
import ThirdInfoBlock from '../../thirdInfoBlock/thirdInfoBlock';
import { objectGetIntendedUse } from '../../../../../../redux/actions/object/object';
import moment from 'moment';


const ViewLeaseRules = ({data}) => {
    const objectIntendedUseList = useSelector(state => state.start.objectIntendedUseList);
    const dispatch = useDispatch()

    useEffect(() => {
       if(objectIntendedUseList === null || objectIntendedUseList === undefined) {
            dispatch(objectGetIntendedUse(`${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/public/dictionaries/intendedUse`));
        }
    }, [])

    function getLeaseDuration(data) {
        let tmpYears, tmpMonth, tmpDays

        const dur = moment.duration(data)
        tmpYears = dur._data.years
        tmpMonth = dur._data.months
        tmpDays = dur._data.days

        return (
                <div style={{display: 'flex', width: '100%', flexFlow: 'column'}}>
                    <div className={styles.titleBlock}>Строк оренди</div>
                    <div style={{display: 'flex', width: '100%', flexFlow: 'row noWrap'}}>
                            {tmpYears !== null && tmpYears > 0 ?
                                <ThirdInfoBlock
                                    title={`Років`}
                                    data={tmpYears}
                                /> : null
                            }
                            {tmpMonth !== null && tmpMonth > 0 ?
                                <ThirdInfoBlock
                                    title={`Місяців`}
                                    data={tmpMonth}
                                /> : null
                            }
                            {tmpDays !== null && tmpDays > 0 ?
                                <ThirdInfoBlock
                                    title={`Днів`}
                                    data={tmpDays}
                                /> : null
                            }
                        </div>
                </div>
            )
    }

    function getLeasePeriod(data) {
        let tmp = null
        if (checkProp(data.schedule, 'period') && data.schedule.period.length > 0) {
            tmp = data.schedule.period.map( (item, count)=> {
                return <div className={styles.leaseRulesPeriodBlock} key={ item.toString() + count } >
                            <div className={styles.infoBlockTitle}>Графік погодинного використання</div>
                            <HalfInfoBlock
                                title={'Початок періоду'}
                                data={dateTransform(item.startDate, 'time')}
                             />
                            <HalfInfoBlock
                                title={'Кінець періоду'}
                                data={dateTransform(item.endDate, 'time')}
                             />
                            <div style={{display: 'flex', width: '100%', flexFlow: 'row wrap', justifyContent: 'space-between' }}>
                            { !!item?.weekdays && item.weekdays.map( ( i, count ) => { 
                                return <HalfInfoBlock
                                            title={ isMongo(i.weekday) }
                                            data={`${i.startTime} - ${i.endTime}`}
                                            width='20'
                                            key={ count }
                                        />
                            })
                            }
                            </div>
                        </div>
            })
        }
        return tmp
    }

    function getRestrictionMethod(a) {
        switch(a){
            case 'onlyDescribed':
                return 'Тільки зазначене'
            case 'exceptDescribed':
                return 'Окрім зазначеного'
            case 'noRestrictions':
                return 'Без обмежень'
            default:
                return
        }
    }

    function getIntendedUse(a) {
        let tmp = []
        if (!!objectIntendedUseList) {
            tmp = a.map(item => {
            for (let key in objectIntendedUseList) {
                if (item === objectIntendedUseList[key].name) {
                    return (
                        <li key={item.toString()}>{objectIntendedUseList[key].description.uk_UA}</li>
                    )
                }
            }
        })
        }
        return tmp
    }

    function getShedule(a) {
        switch (a) {
            case 'day':
                return 'день'
            case 'week':
                return 'тиждень'
            case 'month':
                return 'місяць'
        }
    }

    if (data != null) {
        return (<>
            {checkProp(data,'leaseDuration') ? getLeaseDuration(data.leaseDuration) : null}
            {/* <p className={styles.titleBlock}>Пропонований графік оренди</p> */}
            { checkProp(data, 'schedule') && 
                (checkProp(data.schedule, 'hoursBy') || checkProp(data.schedule, 'daysBy')) &&
                ( !!data?.schedule?.hoursBy?.value || !!data?.schedule?.daysBy?.value) ?
            <>
                <p className={styles.titleBlock}>Пропонований графік оренди</p>
                { checkProp(data.schedule, 'hoursBy') ?
                    <HalfInfoBlock
                        title={`Годин на ${getShedule(data.schedule.hoursBy.by)}`}
                        data={data.schedule.hoursBy.value}
                    /> : null
                }
                { checkProp(data.schedule, 'daysBy') ?
                    <HalfInfoBlock
                        title={`Днів на ${getShedule(data.schedule.daysBy.by)}`}
                        data={data.schedule.daysBy.value}
                    /> : null
                }
            </> : null
            }
            {getLeasePeriod(data)}
            {checkProp(data.schedule, 'other') ?
                            <FullInfoBlock
                                    title={`Інший графік використання`}
                                    data={ data.schedule.other }
                                />
                : null
            }
            {checkProp(data, 'intendedUseRestrictionMethod') ?
                <>
                    <p className={styles.titleBlock}>Цільове призначення об`єкта</p>
                    <FullInfoBlock
                        title={`Спосіб обмеження цільового призначення об'єкта`}
                        data={getRestrictionMethod(data.intendedUseRestrictionMethod)}
                    />
                    {
                        checkProp(data, 'intendedUse') && data.intendedUse.length > 0 ?
                        <ul className={styles.infoBlockList}>
                            {getIntendedUse(data.intendedUse)}
                        </ul> : null
                    }
                </> : null
            }
            {checkProp(data, 'intendedUseRestrictionDescription') ?
                <>
                    <FullInfoBlock
                        title={`Опис обмеженнь цільового призначення об'єкта (за наявності)`}
                        data={data.intendedUseRestrictionDescription}
                    />
                </> : null
            }
            {checkProp(data, 'additionalLeaseConditions') ?
                <>
                    <FullInfoBlock
                        title={`Додаткові умови оренди майна`}
                        data={data.additionalLeaseConditions}
                    />
                </> : null
            }
            {checkProp(data, 'subleaseAllowed') ?
                <>
                    <FullInfoBlock
                        title={`Можливість суборенди`}
                        data={data.subleaseAllowed ? 'Присутня' : 'Відсутня'}
                    />
                </> : null
            }
        </>)
    }
    return null
}

export default ViewLeaseRules