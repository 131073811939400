import {
    ACTION_POST_CREATE,
    ACTIONS_GET_TYPE,
    ACTIONS_GET_ENTITY_ROLE,
    SET_ACTION_TYPE,
    ACTIONS_GET_MY,
    ACTION_CREATE,
    ACTION_UPDATE,
    LEASE_ACTION_GET_ID,
    ACTION_DELETE_POPUP,
    ACTION_RELATED_OBJECTS,
} from "../../types";
import {postAPI, getAPI, deleteAPI, putAPI} from '../crud';

export function actionsGetType(_url){
    const data = {
        url: _url,
        actionType: ACTIONS_GET_TYPE,
        header: {
            'Content-Type': 'application/json'
        }
    }
    return getAPI(data);
}
export function setActionType(value){
    return {
        type: SET_ACTION_TYPE,
        payload: value
    }
}
export function actionsGetEntityRole(_url){
    const data = {
        url: _url,
        actionType: ACTIONS_GET_ENTITY_ROLE,
        header: {
            'Content-Type': 'application/json'
        }
    }
    return getAPI(data);
}

export function actionsPost(_url, body, header){
    const data = {
        //url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/auctions${_url.params}`,
        //url: `https://oldtest.dzo.com.ua/api/tenders?page=1`,
        url: _url.path,
        actionType: ACTION_POST_CREATE,
        body: body,
        header: header
    }
    return postAPI(data);
}

export function actionsGetMy(_url, token){
    const data = {
        url: _url,
        actionType: ACTIONS_GET_MY,
        header: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        }
    }
    return getAPI(data);
}

export function setActionTypesList(value) {
    return {
        type: ACTIONS_GET_TYPE,
        payload: value
    }
}

export function createAction(_url, body, token, redirect) {
    const data = {
        url: _url,
        actionType: ACTION_CREATE,
        body: body,
        header: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        },
        redirect: redirect,
    }
    return postAPI(data);
}

export function updateAction(_url, body, token, redirect) {
    const data = {
        url: _url,
        actionType: ACTION_UPDATE,
        body: body,
        header: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        },
        redirect: redirect,
    }
    return putAPI(data);
}

export function deleteAction(_url, token) {
    const data = {
        url: _url,
        actionType: LEASE_ACTION_GET_ID,
        header: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        },
        // redirect: redirect,
    }
    return deleteAPI(data);
}
export function setActionDeletePopup(value){
    return {
        type: ACTION_DELETE_POPUP,
        payload: value
    }
}
export function actionRelatedObjects(value){
    return {
        type: ACTION_RELATED_OBJECTS,
        payload: value
    }
}