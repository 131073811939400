import React, { useState, useEffect } from 'react';
import styles from './bankAccounts.module.css';

// import { checkProp, isMongo } from '../../../../../../utils/check';
import BankGroup from './bankGroup';

const BankAccountsGroups = ( { bankAccounts, setBankAccounts, newBanks, setNewBankArr, deleteBanks, setDelBankArr, ...props} ) => {

    let tmp = null;

    function setBankGroup( type ){
        
        return bankAccounts.filter( i => i.accountType === type )
    }

    return (
        <div className={styles.bankAccountsGroups}>
            { setBankGroup( 'registrationFee' ).map( i => <BankGroup data={i} key={i} bankAccounts={bankAccounts} setBankAccounts={setBankAccounts} />)}
            { setBankGroup( 'guarantee' ).map( i => <BankGroup data={i} key={i} bankAccounts={bankAccounts} setBankAccounts={setBankAccounts} />)}
            { setBankGroup( 'payment' ).map( i => <BankGroup data={i} key={i} bankAccounts={bankAccounts} setBankAccounts={setBankAccounts} />)}
            {/* { tmp } */}
            {/* {helpMessageNeedBanks(props)} */}
        </div>
    )
}

export default BankAccountsGroups
