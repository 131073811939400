import React,{useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '../../../../../elements/buttons/button';
import styles from './announcementDocuments.module.css';
import AddDocPopup from '../../../../../elements/documents/addDocPopup/addDocPopup';
import CreateDocItem from '../../../../../elements/documents/docItem/documentItem';
import { 
    postMyRedemptionDraftDocument,
    getMyRedemptionDraftDocument,
    getMyRedemptiontDoc,
    postMyRedemptionDoc,
    getRedemptionDocHistory,
    getMyRedemptionDraftDocumentContract,
    postMyRedemptionDraftDocumentContract,
    getMyRedemptiontDocContract,
    postMyRedemptionDocContract
} from '../../../../../../utils/endPoints';
import { setRedemptionDocTypes } from './utils';
import { redemptionDocDelete, redemptionDocDeleteContract } from '../../../../../../redux/asset/assetActions';
import { isDraftObject } from '../../../../../../utils/check';
import WarningBlock from '../../../../../elements/warningBlock';

const RedemptionDocuments =({ data })=> {
    const [addPopup, setAddPopup] = useState(false)
    const assetItem = useSelector(state => state.asset.assetItem)
    const announcementItem = useSelector(state => state.asset.announcementItem)
    const token = useSelector( state => state.start.token )
    const dispatch = useDispatch()

    const docProps = {
        dispatch: ( res )=> dispatch( { payload: res, type: 'ANNOUNCEMENT_GET_ID'}),
        docOf: typeDoc(),
        object: announcementItem,
        docList: setRedemptionDocTypes( announcementItem.status ),
        url: {
            patch: isDraftObject(announcementItem.id) ? getMyRedemptionDraftDocument : getMyRedemptiontDoc,
            post: isDraftObject(announcementItem.id) ? postMyRedemptionDraftDocument : postMyRedemptionDoc,
            patchContract: isDraftObject(announcementItem.id) ? getMyRedemptionDraftDocumentContract : getMyRedemptiontDocContract,
            postContract: isDraftObject(announcementItem.id) ? postMyRedemptionDraftDocumentContract : postMyRedemptionDocContract,
            delete: ( id, contractId=null ) => {
                if(contractId === null){
                    dispatch( redemptionDocDelete( announcementItem.id, id, token.access_token ) )
                }else{
                    dispatch( redemptionDocDeleteContract( announcementItem.id, announcementItem.contracts[0].id, announcementItem.contracts[0].documents[0].id, token.access_token ) )
                }
            },
            history: getRedemptionDocHistory
        }
    }
    function typeDoc(){
        if(
            data.pipelineMethod === 'large_redemption'
            || data.pipelineMethod === 'large_redemption-fast'
            || data.pipelineMethod === 'large_redemption-manual'
            || data.pipelineMethod === 'large_redemption-ultrafast'
        ) return 'large_redemption'
        return 'redemption'
    }

    function isEditable( data ){
        let view = true
        if ( !!data && !data.status ) return false
        if ( !!data && data.status === 'pending' && !!data?.documents ){
            data.documents.forEach( i => { if ( i.documentType === 'clarifications') return false })
        }
       return true
    }

    if ( !data.documents ) return (
        <div className={styles.blockWrap}>
            { addPopup && <AddDocPopup asset={ announcementItem } close={ ()=> setAddPopup( false )} docProps={docProps} /> }
        <div className={styles.infoBlock}>
                <div className={styles.docContentWrap}>
                    <div className={styles.empty}>Наразі ще немає доданих документів</div>
                    <div className={styles.addButtonBlock}>
                        <Button onClick={ ()=> setAddPopup( prev => !prev )}>Додати документ</Button>
                    </div>
                </div>
            </div>
        </div>
    )

    return (
        <div className={styles.blockWrap}>
            { addPopup && <AddDocPopup asset={ announcementItem } close={ ()=> setAddPopup( false )} docProps={docProps} /> }
            <div className={styles.infoBlock}>
                <div className={styles.docContentWrap}>
                    { isEditable( data ) && <WarningBlock data={'Щоб редагувати опублікований пріоритетний викуп необхідно додати документ "Документ з інформацією про виправлення помилок" - дія не обов\'язкова'}/>}
                    { data.documents.length === 0 && <div className={styles.empty}>Наразі ще немає доданих документів</div> }
                    { data.documents.length > 0 && <div className={styles.title}>Додані документи</div> }
                    <div className={styles.docList}>
                        { data.documents.map( i => <CreateDocItem data={i} key={i.id} docProps={docProps} /> ) }
                        { !!data?.contracts[0]?.documents ? data.contracts[0].documents.map( i => <CreateDocItem data={i} key={i.id} docProps={docProps} contractId={data.contracts[0].id}/> ) : null }
                    </div>
                    <div className={styles.addButtonBlock}>
                        <Button onClick={ ()=> setAddPopup( prev => !prev )}>Додати документ</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RedemptionDocuments