import React, { useState, useEffect} from 'react';
import styles from './elems.module.css';
import {useSelector, useDispatch} from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { isMongo, checkProp, isDraftObject, getCurrency, getAddedTax } from '../../../../../utils/check';
import Input from '../../../../inputs/input';
import InputAmount from '../../../../inputs/inputAmount';
import Checkbox from '../../../../inputs/checkBox';
import InputSelect from '../../../../inputs/inputSelect';
import InputTextArea from '../../../../inputs/inputTextArea';
// import AssetOrganizationItem from '../../view/elems/assetOrganizationItem';
import Button from '../../../../elements/buttons/button';
// import AssetHolder from './organizations/orgAssetItem/orgAssetItem';
import { announcementCreate, announcementUpdate } from '../../../../../redux/asset/assetActions';
import { getMyAnnouncement, getMyAnnouncementDraft, postMyAnnouncementDraft } from '../../../../../utils/endPoints';
import Loader from '../../../../loader/loader';
import AnnouncementDecisionsEdit from './decisions/decisionsEdit';
import BankAccounts from './bankAccounts/bankAccounts';
import InputDate from '../../../../inputs/inputDate';

const AnnouncementGeneralCreate = ( { data, create } ) => {
    const userProfiles = useSelector(state => state.start.userProfiles);
    const token = useSelector(state => state.start.token);
    // const assetItem = useSelector(state => state.asset.assetItem)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    const assetObject = location.state

    const [loader, setLoader] = useState( false )

    const [title, setTitle] = useState( () => {
        if( create && !!assetObject ) return isMongo( assetObject.title )
        else return !!data?.title ? isMongo(data.title) : ''
    })
    const [description, setDescription] = useState( ()=> {
        if( create && !!assetObject ) return isMongo( assetObject.description )
        else return !!data?.description ? isMongo(data.description) : ''
    })
    const [ pipelineMethod, setPipelineMethod ] = useState( !!data?.pipelineMethod ? data.pipelineMethod : 'announcement' )
    const [ saleCondition, setSaleCondition ] = useState( !!data?.saleCondition ? data.saleCondition : null )
    const [ value, setValue ] = useState( !!data?.initialProps?.value ? data.initialProps.value : { currency: 'UAH', amount: '', valueAddedTaxIncluded: true } )
    const [ guarantee, setGuarantee ] = useState( !!data?.initialProps?.guarantee ? data.initialProps.guarantee : { currency: 'UAH', amount: ''} )
    const [ registrationFee, setRegistrationFee ] = useState( !!data?.initialProps?.registrationFee ? data.initialProps.registrationFee : { currency: 'UAH', amount: 1420 } )
    const [ minimalStep, setMinimalStep ] = useState( !!data?.initialProps?.minimalStep ? data.initialProps.minimalStep : { currency: 'UAH', amount: '' } )
    // const [ minNumberOfQualifiedBids, setMinNumberOfQualifiedBids ] = useState( !!data?.initialProps?.minNumberOfQualifiedBids ? data.initialProps.minNumberOfQualifiedBids : 1 )
    const [ valueAddedTaxCharged, setValueAddedTaxCharged ] = useState( checkProp(data, 'initialProps') && checkProp(data.initialProps, 'valueAddedTaxCharged') ? data.initialProps.valueAddedTaxCharged : true )
    const [ dutchStepQuantity, setDutchStepQuantity ] = useState( !!data?.initialProps?.dutchStep?.dutchStepQuantity ? data.initialProps.dutchStep.dutchStepQuantity : 99 )
    const [ dutchStepValue, setDutchStepValue ] = useState( !!data?.initialProps?.dutchStep?.dutchStepValue ? data.initialProps.dutchStep.dutchStepValue : { currency: 'UAH', amount: '' } )
    const [ accessDetails, setAccessDetails] = useState( !!data?.initialProps?.accessDetails ? isMongo(data.initialProps.accessDetails) : '' )
    const [ bankAccounts, setBankAccounts] = useState( !!data?.initialProps?.bankAccounts ? data.initialProps.bankAccounts : [] )
    const [ startDate, setStartDate] = useState( !!data?.extraSpecs[0]?.periods[0]?.startDate ? data.extraSpecs[0].periods[0].startDate : '' )
    const [ periodDays, setPeriodDays] = useState( !!data?.extraSpecs[1]?.periods[0]?.duration[0]?.quantity ? data.extraSpecs[1].periods[0].duration[0].quantity : '' )
    const [ periodUnit, setPeriodUnit] = useState( !!data?.extraSpecs[1]?.periods[0]?.duration[0]?.unit ? data.extraSpecs[1].periods[0].duration[0].unit : 'b_days' )
    
    const [decisions, setDecisions] = useState( !!data?.decisions ? data.decisions : [{
            decisionTitle: '',
            decisionNumber: '',
            decisionDate: null,
            decisionOf: 'announcement'
        }] )

    const [relatedEntities, setRelatedEntities] = useState( ()=> {
        if( create && !!assetObject ) return [{type: 'asset', _id: assetObject.id, objectId: assetObject.assetId, archiveId: null, url: null }]
        else return !!data?.relatedEntities ? data.relatedEntities : null
    })
    // const [assetCustodian, setAssetCustodian] = useState( null )

    useEffect(() => {
      if (!!userProfiles && userProfiles?.organization ){
        // setAssetCustodian( userProfiles.organization)
      }
    }, [userProfiles])

    useEffect(() => {
        // let tmp = +value.amount * 0.0
        let tmp = (value.amount * 0.01).toFixed(2)
        setDutchStepValue( { currency: 'UAH', amount: tmp } )
        // tmp = tmp.toFixed(2)
        // if ( guarantee.amount === '') setGuarantee( prev => ({...prev, amount: tmp }))
    }, [value])
    

    function createObjectData(){
            let body = {
                title: { uk_UA: title },
                description: { uk_UA: description },
                pipelineMethod: pipelineMethod,
                saleCondition: saleCondition,
                initialProps: {
                    value: value,
                    guarantee: guarantee,
                    registrationFee: registrationFee,
                    minimalStep: minimalStep,
                    // minNumberOfQualifiedBids: 1,
                    valueAddedTaxCharged: valueAddedTaxCharged,
                    accessDetails: { uk_UA: accessDetails },
                    dutchStep: {
                        dutchStepValue: null,//dutchStepValue,
                        dutchStepQuantity: dutchStepQuantity,
                        dutchStepPercent: null
                     },
                    get bankAccounts(){
                        let tmp = bankAccounts
                            for ( let key in tmp ){
                                tmp[key].accounts.forEach(
                                    item => { 
                                        item.bankName = { uk_UA: isMongo(item.bankName)}
                                        item.description = { uk_UA: isMongo(item.description)}
                                        item.accountHolder.identifier.legalName = { uk_UA: isMongo(item.accountHolder.identifier.legalName) }
                                    }
                                )
                            }
                        return tmp
                    }
                },
                relatedEntities: relatedEntities,
                decisions: decisions,
                extraSpecs: [
                    { periods: [{ startDate: startDate, endDate: null, periodName: "auctionPeriod",
                          duration: [] }]},
                    { periods: [
                        { startDate: null,endDate: null,periodName: "tenderPeriod",
                          duration: [{ unit: periodUnit, quantity: periodDays } ] }]},
                    { periods: [ { startDate: null, endDate: null, periodName: "tenderPeriod",
                          duration: [ { unit: periodUnit, quantity: periodDays } ] }]},
                    { periods: [{ startDate: null, endDate: null, periodName: "tenderPeriod",
                          duration: [ { unit: periodUnit, quantity: periodDays } ] }]}
                ]
            }
            function setNavigate(id){
                return navigate(`/asset/announcement/edit/${id}`)
            }
            if ( create ) {
                dispatch( announcementCreate( postMyAnnouncementDraft('jas'), JSON.stringify(body), token.access_token, setNavigate ) )
                setTimeout( ()=> setLoader(false), 500)
                setLoader(true)
            } else {
            const url = ( id ) => isDraftObject(id) ? getMyAnnouncementDraft(id) : getMyAnnouncement(id)
            dispatch( announcementUpdate( url(data.id) , JSON.stringify(body), token.access_token))
            setTimeout( ()=> setLoader(false), 300)
            setLoader(true)
            if ( data.status === 'pending' ) navigate(`/asset/my_announcements`)
            }
            window.scrollTo(0, 0)
    }

    if ( loader ) return <Loader />
    // if ( data.status !== 'pending' && !location?.state ) return navigate(`/asset/my_announcements`)

    function optionTime(){
        if( process.env.REACT_APP_END_POINT_BD.includes('dev') || process.env.REACT_APP_END_POINT_BD.includes('sandbox') ){
            return [
                { value: "b_day",name: "Робочі дні" },
                { value: "c_day",name: "Календарні дні" },
                { value: "hour",name: "Години" },
                { value: "minute",name: "Хвилини" },
                { value: "second ",name: "Секунди" },
            ]
        }
        return [
            { value: "b_day",name: "Робочі дні" },
            { value: "c_day",name: "Календарні дні" },
        ]
    }

    return (
    <div className={styles.blockWrap}>
        <div className={styles.infoBlock}>
        <div className={styles.infoBlockTitle}>Загальні характеристики</div>
            <Input 
                label='Назва' 
                value={title}
                onChange={ e => setTitle(e)}
                reqiured
            />
            <InputTextArea 
                label='Опис'
                value={description}
                onChange={ e => setDescription(e)}
                reqiured
            />
            { ( process.env.REACT_APP_END_POINT_BD.includes('dev') || process.env.REACT_APP_END_POINT_BD.includes('sandbox') ) &&
                <InputSelect 
                    label='pipelineMethod' // "Value must be one of ['state', 'municipal', 'compatible']
                    value={ pipelineMethod } 
                    onChange={ e => setPipelineMethod(e)}
                    dictionary={true}
                    options={[
                        {name: "announcement",description: {uk_UA: "announcement"}},
                        {name: "announcement-fast",description: {uk_UA:"announcement-fast"}},
                        {name: "announcement-manual",description: {uk_UA:"announcement-manual"}},
                        {name: "announcement-ultrafast",description: {uk_UA:"announcement-ultrafast"}},
                    ]}
                    reqiured
                    infoBlock={
                    <>
                        {/* <h4>УВАГА!! Обрати можна лише при створенні, не редагується!!</h4> */}
                        <li><b>announcement</b> - rectificationPeriod: 2дні, процедура smallPrivatization-english</li>
                        <li><b>announcement-fast</b> - rectificationPeriod: 2хв, процедура smallPrivatization-english-fast</li>
                        <li><b>announcement-manual</b> - rectificationPeriod: 10хв, процедура smallPrivatization-english-manual</li>
                        <li><b>announcement-ultrafast</b> - rectificationPeriod: 5сек, процедура smallPrivatization-english-fast</li>
                    </>
                    }
                    descr={<span style={{ color: 'var(--red)', fontWeight: '600'}}>УВАГА!! Обрати можна лише при створенні, не редагується!!</span>}
                    disabled={ create ? false : true }
                />
            }
            <InputSelect 
                label='Наявність умов продажу'
                value={ saleCondition }
                onChange={ e => setSaleCondition(e) }
                options={[
                    { value: "yes", name: "Так" },
                    { value: "no", name: "Ні" }
                ]}
                reqiured
            />
        </div>

        <div className={styles.infoBlock}>
        {/* <div className={styles.infoBlockTitle}>Initial props *</div> */}
        <InputAmount 
                title='Стартова ціна'
                value={ value }
                onChange={ setValue }
                tax={ true }
                required
                // enableCurrent
            />
            { value.amount !== '' &&
                <div className={styles.auctionIhfo}>
                    <div className={styles.aucInfoItem}>
                    {`1-й аукціон  - Стартова ціна: ${value.amount} ${getCurrency(value.currency) } ${getAddedTax(value.valueAddedTaxIncluded)}`}
                    </div>
                    <div className={styles.aucInfoItem}>
                    {`2-й аукціон  - Стартова ціна: ${ +value.amount * 0.5 } ${getCurrency(value.currency) } ${getAddedTax(value.valueAddedTaxIncluded)}`}
                    </div>
                    <div className={styles.aucInfoItem}>
                    {`3-й аукціон  - Стартова ціна: ${ +value.amount * 0.5 } ${getCurrency(value.currency) } ${getAddedTax(value.valueAddedTaxIncluded)}`}
                    </div>
                    <div className={styles.aucInfoItem}>
                    {`4-й аукціон  - Стартова ціна: ${+value.amount * 0.5 } ${getCurrency(value.currency) } ${getAddedTax(value.valueAddedTaxIncluded)}`}
                    </div>
                </div>
            }
            <InputAmount 
                title='Гарантійний внесок'
                value={ guarantee }
                onChange={ setGuarantee }
                required
                descr={ value !== '' && `Рекомендований гарантійний внесок: ${( +value.amount * 0.2 ).toFixed('2')} грн`}
            />
            <InputAmount 
                title='Реєстраційний внесок'
                value={ registrationFee }
                onChange={ setRegistrationFee }
                required
            />
            <InputAmount 
                title='Розмір кроку аукціону'
                value={ minimalStep }
                onChange={ setMinimalStep }
                required
            />
            {/*<InputAmount 
                title='Розмір кроку голландського раунду, грн'
                value={ dutchStepValue }
                onChange={ setDutchStepValue }
                disabled
                // required
            />*/}
            {/* <div className={styles.twoBlock}> */}
                {/* <Input 
                    value={ minNumberOfQualifiedBids }
                    onChange={ e => setMinNumberOfQualifiedBids(e) }
                    label='Мінімальна кількість заяв'
                    disabled={true}
                /> */}
                <Checkbox
                    value={ valueAddedTaxCharged }
                    onChange={setValueAddedTaxCharged}
                    label='На фінальну суму нараховується ПДВ'
                    // disabled
                />
            {/* </div> */}
            <Input 
                value={ dutchStepQuantity }
                onChange={ e => setDutchStepQuantity(e) }
                type='number'
                label='Кількість кроків'
                // disabled={true}
                descr='Від 1 до 99'
            />
            {/* <Input 
                value={ dutchStepValue }
                onChange={ e => setDutchStepValue(e) }
                type='number'
                label='Розмір кроку аукціону ( dutchStep )'
                // disabled={true}
                descr='Від 1 до 99'
            /> */}
            <InputTextArea
                value={ accessDetails }
                onChange={ e => setAccessDetails(e) }
                label='Порядок ознайомлення з майном, час і місце проведення огляду об’єкта'
            />
            <div className={styles.periodsBlock}>
                <InputDate
                    data={{
                        label: 'Дата старту першого аукціону',
                        value: startDate,
                        onChange: e => setStartDate( e ),
                        reqiured: true,
                        time: true
                    }}
                />
                <div className={styles.twoBlock}>
                    <Input 
                        value={ periodDays }
                        onChange={ e => setPeriodDays(e) }
                        type='number'
                        label='Період на подачу пропозицій, днів'
                        descr='Значення загальне для 2, 3, 4-ї процедури. Не менше 3-х робочих днів'
                        reqiured
                    />
                    <InputSelect 
                        label='Одиниці виміру періоду'
                        value={ periodUnit } 
                        onChange={ e => setPeriodUnit(e)}
                        options={optionTime()}
                        reqiured
                        // descr={'УВАГА!! Обрати можна лише при створенні, не редагується!!'}
                    />
                </div>
            </div>
        </div>

        <div className={styles.infoBlock}>
            <div className={styles.infoBlockTitle}>Рішення органу приватизації про затвердження умов продажу *</div>
            <AnnouncementDecisionsEdit data={ decisions } setData={ setDecisions } />
        </div>
        <div className={styles.infoBlock}>
            <div className={styles.infoBlockTitle}>Банківські рахунки організатора *</div>
            <BankAccounts bankAccounts={ bankAccounts } setBankAccounts={ setBankAccounts } />
        </div>
        <Button
            onClick={ createObjectData }
        >
            { !!data ? 'Зберегти' : 'Зберегти та продовжити' }
        </Button>
    </div>
    )
}

export default AnnouncementGeneralCreate