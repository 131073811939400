import React, { useState } from 'react'
import {useSelector, useDispatch} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { sendAsset } from '../../../../../../utils/endPoints';
import Button from '../../../../../elements/buttons/button'
import Loader from '../../../../../loader/loader';
import UniPopUp from '../../../../../popups/uniPopUp/uniPopUp'
import { validateAsset, validateAssetLP } from './validation'

const SendAssetToProzoro =({ data })=> {
    const token = useSelector(state => state.start.token)
    const userProfiles = useSelector(state => state.start.userProfiles)
    const navigate = useNavigate()

    const [popUpDisabled, setPopUpDisabled] = useState( false )
    const [api, setApi] = useState( { loader: false, data: '' } )
    const [errorsPopup, setErrorsPopup] = useState( 
        {   errors: [],
            state: false
        } )

    function sendFunction( asset ){
        validateAsset( asset )
        if ( !!validateAsset( asset )) {
            setErrorsPopup( prev => ({ ...prev, state: true }) )
            setErrorsPopup( prev => ({ ...prev, errors: validateAsset( asset ) }) )
        } else {
            setApi( prev => ({...prev, loader: true }))
            fetch( sendAsset( data.id ), {
                    method: 'GET',
                    headers: { 'Authorization': 'Bearer ' + token.access_token, 'Content-Type': 'application/json' }
                })
                .then( res => {
                    if ( res.ok ) {
                        setApi( prev => ({...prev, loader: false }))
                        setApi( prev => ({...prev, data: 'Об\'єкт успішно опубліковано' }))
                        setApi( prev => ({...prev, ok: true }))
                    }
                    return res.json() 
                })
                .then( data => {
                    // let json = res.json()
                    if ( data?.message ) {
                        setApi( prev => ({...prev, loader: false }))
                        let tmpArr = []
                        switch (true) {
                            case (typeof data.message === 'object' || data.message instanceof Object):
                                for (const property in data.message) {
                                    tmpArr.push(`${property}: ${data.message[property]}`);
                                }
                                break

                            case (typeof data.message === 'string' || data.message instanceof String):
                                tmpArr.push(data.message)
                                break

                            default:
                                let tmpJSON = JSON.parse(data.message[0])
                                if (tmpJSON.hasOwnProperty("assetItems") === true) {
                                    if (Array.isArray(tmpJSON.assetItems)) {
                                        for (let tt = 0; tt < tmpJSON.assetItems.length; tt++) {
                                            if (tmpJSON.assetItems[tt].hasOwnProperty("itemProps") === true) {
                                                if (tmpJSON.assetItems[tt].itemProps.hasOwnProperty("landProps") === true) {
                                                    if (tmpJSON.assetItems[tt].itemProps.landProps.hasOwnProperty("encumbrances") === true) {
                                                        if (tmpJSON.assetItems[tt].itemProps.landProps.encumbrances.indexOf("This field is required") !== -1) {
                                                            tmpArr.push('Необхідно внести інформацію про про наявні обтяження')
                                                        }
                                                        if (tmpJSON.assetItems[tt].itemProps.landProps.encumbrances.indexOf("noEncumbrances value not available if hasEncumbrances=True") !== -1) {
                                                            tmpArr.push('Значення "Обтяження відсутні" недоступне, якщо у відмітці "Присутні обтяження" вказано "Так"')
                                                        }
                                                    }
                                                    if (tmpJSON.assetItems[tt].itemProps.landProps.hasOwnProperty("jointOwnership") === true) {
                                                        if (tmpJSON.assetItems[tt].itemProps.landProps.jointOwnership.indexOf("This field is required") !== -1) {
                                                            tmpArr.push('Необхідно внести інформацію про наявних співвласників')
                                                        }
                                                    }
                                                    if (tmpJSON.assetItems[tt].itemProps.landProps.hasOwnProperty("utilitiesAvailability") === true) {
                                                        if (tmpJSON.assetItems[tt].itemProps.landProps.utilitiesAvailability.indexOf("This field is required") !== -1) {
                                                            tmpArr.push('Необхідно внести інформацію про наявні інженерні мережі')
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    } else {
                                        if (tmpJSON.assetItems.hasOwnProperty("1") === true) {
                                            if (tmpJSON.assetItems["1"].hasOwnProperty("itemProps") === true) {
                                                if (tmpJSON.assetItems["1"].itemProps.hasOwnProperty("encumbrances") === true) {
                                                    if (tmpJSON.assetItems["1"].itemProps.encumbrances.indexOf("This field is required") !== -1) {
                                                        tmpArr.push('Необхідно внести інформацію про про наявні обтяження')
                                                    }
                                                    if (tmpJSON.assetItems["1"].itemProps.encumbrances.indexOf("noEncumbrances value not available if hasEncumbrances=True") !== -1) {
                                                        tmpArr.push('Значення "Обтяження відсутні" недоступне, якщо у відмітці "Присутні обтяження" вказано "Так"')
                                                    }
                                                }
                                                if (tmpJSON.assetItems["1"].itemProps.hasOwnProperty("jointOwnership") === true) {
                                                    if (tmpJSON.assetItems["1"].itemProps.jointOwnership.indexOf("This field is required") !== -1) {
                                                        tmpArr.push('Необхідно внести інформацію про наявних співвласників')
                                                    }
                                                }
                                                if (tmpJSON.assetItems["1"].itemProps.hasOwnProperty("utilitiesAvailability") === true) {
                                                    if (tmpJSON.assetItems["1"].itemProps.utilitiesAvailability.indexOf("This field is required") !== -1) {
                                                        tmpArr.push('Необхідно внести інформацію про наявні інженерні мережі')
                                                    }
                                                }

                                                if (tmpJSON.assetItems["1"].itemProps.hasOwnProperty("landProps") === true) {
                                                    if (tmpJSON.assetItems["1"].itemProps.landProps.hasOwnProperty("encumbrances") === true) {
                                                        if (tmpJSON.assetItems["1"].itemProps.landProps.encumbrances.indexOf("This field is required") !== -1) {
                                                            tmpArr.push('Необхідно внести інформацію про про наявні обтяження')
                                                        }
                                                        if (tmpJSON.assetItems["1"].itemProps.landProps.encumbrances.indexOf("noEncumbrances value not available if hasEncumbrances=True") !== -1) {
                                                            tmpArr.push('Значення "Обтяження відсутні" недоступне, якщо у відмітці "Присутні обтяження" вказано "Так"')
                                                        }
                                                    }
                                                    if (tmpJSON.assetItems["1"].itemProps.landProps.hasOwnProperty("jointOwnership") === true) {
                                                        if (tmpJSON.assetItems["1"].itemProps.landProps.jointOwnership.indexOf("This field is required") !== -1) {
                                                            tmpArr.push('Необхідно внести інформацію про наявних співвласників')
                                                        }
                                                    }
                                                    if (tmpJSON.assetItems["1"].itemProps.landProps.hasOwnProperty("utilitiesAvailability") === true) {
                                                        if (tmpJSON.assetItems["1"].itemProps.landProps.utilitiesAvailability.indexOf("This field is required") !== -1) {
                                                            tmpArr.push('Необхідно внести інформацію про наявні інженерні мережі')
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                if (tmpJSON.hasOwnProperty("classifications") === true) {
                                    if (tmpJSON.classifications.indexOf("For classificators with '06' at least one of item classificators must be in ['04', '05']") !== -1) {
                                        tmpArr.push("При обраному класифікаторі 06 Земельні ділянки обовʼязково необхідно додати лот з класифікатором 04 Нерухоме майно або 05 Цілісний майновий комплекс")
                                    }
                                }
                        }
                        setApi( prev => ({...prev, loader: false }))
                        // setApi( prev => ({...prev, data: data?.message.map( i => <div key={i}>{i}</div>) }))
                        if(tmpArr.length > 0){
                            setApi( prev => ({...prev, data: <><div style={{fontWeight: '600'}}>Для публікації обʼєкта оренди необхідно:</div>{tmpArr.map( i => <div key={i}>{i}</div>)}</> }))
                        }else{
                            setApi( prev => ({...prev, data: data?.message.map( i => <div key={i}>{i}</div>) }))
                        }
                    }
                })
                .catch( error => {
                    setApi( prev => ({...prev, loader: false }))
                    setApi( prev => ({...prev, data: error }))
                })
        }
    }
    function sendFunctionLP( asset ){
        validateAssetLP( asset )
        if ( !!validateAssetLP( asset )) {
            setErrorsPopup( prev => ({ ...prev, state: true }) )
            setErrorsPopup( prev => ({ ...prev, errors: validateAssetLP( asset ) }) )
        } else {
            setApi( prev => ({...prev, loader: true }))
            fetch( sendAsset( data.id ), {
                    method: 'GET',
                    headers: { 'Authorization': 'Bearer ' + token.access_token, 'Content-Type': 'application/json' }
                })
                .then( res => {
                    if ( res.ok ) {
                        setApi( prev => ({...prev, loader: false }))
                        setApi( prev => ({...prev, data: 'Об\'єкт успішно опубліковано' }))
                        setApi( prev => ({...prev, ok: true }))
                    }
                    return res.json() 
                })
                .then( data => {
                    // let json = res.json()
                    if ( data?.message ) {
                        setApi( prev => ({...prev, loader: false }))
                        let tmpJSON = JSON.parse(data.message[0])
                        let tmpArr = []
                        if(tmpJSON.hasOwnProperty("assetItems") === true){
                            if(Array.isArray(tmpJSON.assetItems)){
                                for(let tt=0; tt<tmpJSON.assetItems.length; tt++){
                                    if(tmpJSON.assetItems[tt].hasOwnProperty("itemProps") === true){
                                        if(tmpJSON.assetItems[tt].itemProps.hasOwnProperty("encumbrances") === true){
                                            if(tmpJSON.assetItems[tt].itemProps.encumbrances.indexOf("This field is required") !== -1){
                                                tmpArr.push('Необхідно внести інформацію про про наявні обтяження')
                                            }
                                            if(tmpJSON.assetItems[tt].itemProps.encumbrances.indexOf("noEncumbrances value not available if hasEncumbrances=True") !== -1){
                                                tmpArr.push('Значення "Обтяження відсутні" недоступне, якщо у відмітці "Присутні обтяження" вказано "Так"')
                                            }
                                        }
                                        if(tmpJSON.assetItems[tt].itemProps.hasOwnProperty("jointOwnership") === true){
                                            if(tmpJSON.assetItems[tt].itemProps.jointOwnership.indexOf("This field is required") !== -1){
                                                tmpArr.push('Необхідно внести інформацію про наявних співвласників')
                                            }
                                        }
                                        if(tmpJSON.assetItems[tt].itemProps.hasOwnProperty("utilitiesAvailability") === true){
                                            if(tmpJSON.assetItems[tt].itemProps.utilitiesAvailability.indexOf("This field is required") !== -1){
                                                tmpArr.push('Необхідно внести інформацію про наявні інженерні мережі')
                                            }
                                        }

                                        if(tmpJSON.assetItems[tt].itemProps.hasOwnProperty("landProps") === true){
                                            if(tmpJSON.assetItems[tt].itemProps.landProps.hasOwnProperty("encumbrances") === true){
                                                if(tmpJSON.assetItems[tt].itemProps.landProps.encumbrances.indexOf("This field is required") !== -1){
                                                    tmpArr.push('Необхідно внести інформацію про про наявні обтяження')
                                                }
                                                if(tmpJSON.assetItems[tt].itemProps.landProps.encumbrances.indexOf("noEncumbrances value not available if hasEncumbrances=True") !== -1){
                                                    tmpArr.push('Значення "Обтяження відсутні" недоступне, якщо у відмітці "Присутні обтяження" вказано "Так"')
                                                }
                                            }
                                            if(tmpJSON.assetItems[tt].itemProps.landProps.hasOwnProperty("jointOwnership") === true){
                                                if(tmpJSON.assetItems[tt].itemProps.landProps.jointOwnership.indexOf("This field is required") !== -1){
                                                    tmpArr.push('Необхідно внести інформацію про наявних співвласників')
                                                }
                                            }
                                            if(tmpJSON.assetItems[tt].itemProps.landProps.hasOwnProperty("utilitiesAvailability") === true){
                                                if(tmpJSON.assetItems[tt].itemProps.landProps.utilitiesAvailability.indexOf("This field is required") !== -1){
                                                    tmpArr.push('Необхідно внести інформацію про наявні інженерні мережі')
                                                }
                                            }
                                        }
                                    }
                                }
                            }else{
                                if(tmpJSON.assetItems.hasOwnProperty("1") === true){
                                    if(tmpJSON.assetItems["1"].hasOwnProperty("itemProps") === true){
                                        if(tmpJSON.assetItems["1"].itemProps.hasOwnProperty("encumbrances") === true){
                                            if(tmpJSON.assetItems["1"].itemProps.encumbrances.indexOf("This field is required") !== -1){
                                                tmpArr.push('Необхідно внести інформацію про про наявні обтяження')
                                            }
                                            if(tmpJSON.assetItems["1"].itemProps.encumbrances.indexOf("noEncumbrances value not available if hasEncumbrances=True") !== -1){
                                                tmpArr.push('Значення "Обтяження відсутні" недоступне, якщо у відмітці "Присутні обтяження" вказано "Так"')
                                            }
                                        }
                                        if(tmpJSON.assetItems["1"].itemProps.hasOwnProperty("jointOwnership") === true){
                                            if(tmpJSON.assetItems["1"].itemProps.jointOwnership.indexOf("This field is required") !== -1){
                                                tmpArr.push('Необхідно внести інформацію про наявних співвласників')
                                            }
                                        }
                                        if(tmpJSON.assetItems["1"].itemProps.hasOwnProperty("utilitiesAvailability") === true){
                                            if(tmpJSON.assetItems["1"].itemProps.utilitiesAvailability.indexOf("This field is required") !== -1){
                                                tmpArr.push('Необхідно внести інформацію про наявні інженерні мережі')
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        if(tmpJSON.hasOwnProperty("classifications") === true){
                            if(tmpJSON.classifications.indexOf("For classificators with '06' at least one of item classificators must be in ['04', '05']") !== -1){
                                tmpArr.push("При обраному класифікаторі 06 Земельні ділянки обовʼязково необхідно додати лот з класифікатором 04 Нерухоме майно або 05 Цілісний майновий комплекс")
                            }
                        }
                        setApi( prev => ({...prev, loader: false }))
                        // setApi( prev => ({...prev, data: data?.message.map( i => <div key={i}>{i}</div>) }))
                        if(tmpArr.length > 0){
                            setApi( prev => ({...prev, data: <><div style={{fontWeight: '600'}}>Для публікації обʼєкта оренди необхідно:</div>{tmpArr.map( i => <div key={i}>{i}</div>)}</> }))
                        }else{
                            setApi( prev => ({...prev, data: data?.message.map( i => <div key={i}>{i}</div>) }))
                        }
                    }
                })
                .catch( error => {
                    setApi( prev => ({...prev, loader: false }))
                    setApi( prev => ({...prev, data: error }))
                })
        }
    }

    function disabledBtn( asset ){
        return false
    }


    if (!!data?.assetId) return null

    if ( api.loader ) return <Loader />
    if ( api.data ) {
        return <UniPopUp 
                type={1}
                close={ ()=> {
                    setApi( prev => ({...prev, data: '' }))
                    if ( api.ok ){
                        if(data.decisions[0].decisionOf === "large_asset"){
                            navigate('/asset/my_assets_lp/')
                        }else{
                            navigate('/asset/my_assets/')
                        }
                    }
                }}
                data={ api.data } 
            />
    }

    return (
        <>
            { popUpDisabled && 
                <UniPopUp 
                    type={1}
                    close={ ()=> setPopUpDisabled(false) } 
                    data={'Заповніть всі обов\'язкові поля, додайте Об\'єкти та документи, щоб опублікувати об\'єкт'} 
                /> 
            }
            { errorsPopup.state && 
                <UniPopUp 
                    type={1}
                    close={ ()=> setErrorsPopup( prev => ({...prev, state: false})) }
                    data={
                        <>
                            <div  style={{fontWeight: '600'}}>Для публікації об'єкта необхідно:</div>
                            <ul style={{textAlign: 'left'}}>
                                { errorsPopup.errors.map( i => <li key={i.toString()}>{i}</li>) }
                            </ul>
                        </>
                    }
                /> 
            }
            {userProfiles !== null && userProfiles.isFinAuth === true
                ? <Button 
                    height={'50px'} width={'200px'} 
                    // disabled={ disabledBtn() }
                    onClick={ ()=> {
                        if(disabledBtn( data )){
                            setPopUpDisabled(true)
                        }else{
                            if(data.decisions[0].decisionOf === "large_asset"){
                                sendFunctionLP( data )
                            }else{
                                sendFunction( data )
                            }
                        }
                    }}
                >
                    Опублікувати в Prozorro
                </Button>
                : null
            }
        </>
    )

}

export default SendAssetToProzoro