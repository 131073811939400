import React, { useState } from 'react';
import styles from './elems.module.css';
import FullInfoBlock from '../../../../elements/fullInfoBlock/fullInfoBlock';
import HalfInfoBlock from '../../../../elements/halfInfoBlock/halfInfoBlock';
import { dateTransform, isMongo } from '../../../../../utils/check';
import InfoBlockСollapser from '../../../../elements/infoBlockСollapser/infoBlockСollapser';
import RelatedEntities from '../../../announcement/view/elems/relatedEntities';
import ViewDocItem from '../../../../elements/documents/view/viewDocumentItem';
// import Button from '../../../../elements/buttons/button';
import ExecusionContracts from './contracts';
import ExecutionOrder from './order';
import ExecusionCompletion from './completion';
import ExecusionCancellations from './cancellations';
import {getExecutionContractDocType} from '../../utils'

const ExecutionGeneral = ( { data } ) => {

    const generalInfo = <div className={styles.infoBlock + ' ' + styles.generalInfo}>
                                {/* <div className={styles.infoBlockTitle}>
                                    <h3>Загальна інформація</h3>
                                </div> */}
                                    <div className={styles.infoBlockContent}>
                                        {/* {!!data?.title &&
                                            <FullInfoBlock
                                                title='Назва'
                                                data={ isMongo(data.title)}
                                            />
                                        } */}
                                        {!!data?.description &&
                                            <FullInfoBlock
                                                title='Опис'
                                                data={ isMongo(data.description)}
                                            />
                                        }
                                        <HalfInfoBlock
                                            title='Дата створення'
                                            data={ dateTransform(data.datePublished, 'time') }
                                        />
                                        <HalfInfoBlock
                                            title='Дата останнього редагування'
                                            data={ dateTransform(data.dateModified, 'time') }
                                        />
                                    </div>
                            </div>

    return (
            <div className={styles.blockWrap}>
                <div>
                    {/* <Button onClick></Button> */}
                </div>
                { generalInfo }
                { (!!data?.contracts && data?.contracts.length > 0 ) && 
                    <InfoBlockСollapser 
                        blockTitle={'Умови приватизації'}
                        content={ <ExecusionContracts data={ data.contracts } obj={data.id} /> }
                    />
                }
                { (!!data?.cancellations && data?.cancellations.length > 0 ) && 
                    <InfoBlockСollapser 
                        blockTitle={'Скасування приватизації'}
                        content={ <ExecusionCancellations data={ data.cancellations } /> }
                    />
                }
                { !!data?.order && 
                    <InfoBlockСollapser 
                        blockTitle={'Дані щодо наказу про приватизацію'}
                        content={ <ExecutionOrder data={ data.order } /> }
                    />
                }
                { !!data?.completion && 
                    <InfoBlockСollapser 
                        blockTitle={'Завершення виконання умов приватизації'}
                        content={ <ExecusionCompletion data={ data.completion } /> }
                    />
                }
                { !!data?.relatedEntities && data.relatedEntities.length > 0 &&
                    <InfoBlockСollapser 
                        blockTitle={ 'Список пов`язаних сутностей' }
                        content={ <RelatedEntities data={ data.relatedEntities } /> }
                    />
                }
                { data?.documents &&
                    <InfoBlockСollapser 
                        blockTitle={ <>Документи { data.documents.length > 0 && `( ${data.documents.length} )` }</> }
                        content={   
                                <div style={{display: 'flex', gap: '10px', flexFlow: 'column', width: '100%'}}>
                                    {
                                        data.documents.length > 0 
                                    ?
                                        // data.documents.map( doc => <AssetDocItem data={doc} key={doc.id} /> )
                                        data.documents.map( doc => <ViewDocItem data={doc} key={doc.id} 
                                            specs={{
                                                objId: data.id,
                                                docType: getExecutionContractDocType(doc.documentType),
                                                //historyURL: `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/executions/${data.id}/contracts/${contractId}/document/${docId}/history`
                                            }}/> )
                                    : 'Документів не знайдено'
                                    }
                                </div>
                        }
                    />
                }
            </div>
        )
}

export default ExecutionGeneral