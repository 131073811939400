import styles from './documents.module.css';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setPopupAddDocument } from '../../../../redux/actions/documents/documents';
import PopupAddDocument from '../../../popups/popupAddDocument/popupAddDocument';
import AddDocPopUp from '../../../elements/documents/addDocPopup/addDocPopup';
import { isDraftObject } from '../../../../utils/check';
import { patchMyActionDocument, postMyActionDocument, urlPatchMyDraftActionDoc, urlPostMyDraftAction, urlPostMyDraftActionDoc } from '../../../../utils/endPoints';
import { deleteAction } from '../../../../redux/actions/leaseActions/leaseActions';
import CreateDocItem from '../../../elements/documents/docItem/documentItem';

const DocumentsAction = ({data}) => {

    const dispatch = useDispatch();
    // const popupAddDocument = useSelector(state => state.start.popupAddDocument);
    const token = useSelector(state => state.start.token);
    const actionId = useSelector(state => state.start.leaseActionGetId);
    const [popAddDoc, setPopAddDoc] = useState(false)

    const docProps = {
        dispatch: ( res )=> dispatch( { payload: res, type: 'LEASE_ACTION_GET_ID'}),
        docOf: 'action',
        object: actionId,
        // docList: setAssetDocTypes( assetItem.assetItems, assetItem.status ),
        url: {
            patch: isDraftObject(actionId.id) ? urlPatchMyDraftActionDoc : patchMyActionDocument,
            post: isDraftObject(actionId.id) ? urlPostMyDraftActionDoc : postMyActionDocument,
            delete: ( id ) => dispatch( deleteAction( patchMyActionDocument( actionId.id, id), token.access_token ) )
        }
    }

    return <>
        <div className={styles.docListContainer}>
            { !!data && data.map( i => <CreateDocItem data={ i } key={i.id} docProps={ docProps } /> ) }
        </div>
        <button
            className={styles.addDocumentButton}
            onClick={ () => setPopAddDoc(true) }>
                Додати документ
        </button>
        {/* {popupAddDocument === true ? <PopupAddDocument type={'action'} /> : null} */}
        { popAddDoc && 
            <AddDocPopUp docProps={ docProps } close={ ()=> setPopAddDoc(false) }/> 
        }
    </>
}

export default DocumentsAction;