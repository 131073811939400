import React,{ useState, useEffect } from 'react';
import styles from './elems.module.css';
import {  getAssetStatus, setAnnouncementStatus, setAuctionAssetStatus, setRedemptionStatus } from '../../../../../utils/check';
// import { Link } from 'react-router-dom';
import { getRelatedEntities } from '../../utils';
import HalfInfoBlock from '../../../objectItemPage/blocks/halfInfoBlock/halfInfoBlock';
import Loader from '../../../../loader/loader';
import { setExecutionStatus } from '../../../execution/utils';



const RelatedEntities = ( { data } ) => {
    const [ entitiesArr, setEntitiesArr ] = useState([])
    const [ loader, setLoader ] = useState(false)

    useEffect( () => {
        if( entitiesArr.length === 0 ) {
            async function getData(){
                setLoader( true )
                await getRelatedEntities( data, setEntitiesArr )
                setLoader( false )
            }
            getData()
        }
        // if( entitiesArr.length === data.length ) setLoader( false )
        // setLoader( false )
    }, [])

    const ListItem =({ data, type })=> {
        return (
          <div className={styles.listEntitiesItemWrap}>
              <div style={{flex: 2, display: 'flex', flexFlow: 'row wrap'}}>
                <HalfInfoBlock 
                    title={ getName( type ) }
                    data={ getId( data, type ) }
                    link={ getLink(data, type) }
                />
                <HalfInfoBlock 
                    title={ 'Статус:' }
                    data={ getStatus(type, data.status) }
                />
              </div>
          </div>
        )
    }

    function getStatus( type, status){
        switch( type ){
            case 'announcement': return setAnnouncementStatus(status)
            case 'large_announcement': return setAnnouncementStatus(status)
            case 'asset': return getAssetStatus(status)
            case 'large_asset': return getAssetStatus(status)
            case 'procedure': return setAuctionAssetStatus(status)
            case 'execution': return setExecutionStatus(status)
            case 'large_execution': return setExecutionStatus(status)
            case 'redemption': return setRedemptionStatus(status)
            case 'large_redemption': return setRedemptionStatus(status)
            
            default: return null
        }
    }

    function getLink( data, type ){
        switch(type){
            case 'announcement': return `/asset/announcement/${data.objectId}`
            case 'large_announcement': return `/asset/announcement/${data.objectId}`
            case 'asset': return `/asset/${data.assetId}`
            case 'large_asset': return `/asset/${data.assetId}`
            case 'procedure': return `${process.env.REACT_APP_END_POINT_DTO}/auctions/${data.auctionId}`
            case 'execution': return `/asset/execution/${data.executionId}`
            case 'large_execution': return `/asset/execution/${data.executionId}`
            case 'redemption': return `/asset/objectId/${data.executionId}`
            case 'large_redemption': return `/asset/objectId/${data.executionId}`
            default: return null
        }
    }

    function getId( data, type ){
        switch(type){
            case 'announcement': return data.objectId
            case 'large_announcement': return data.objectId
            case 'asset': return data.assetId
            case 'large_asset': return data.assetId
            case 'procedure': return data.auctionId
            case 'execution': return data.executionId
            case 'large_execution': return data.executionId
            case 'redemption': return data.executionId
            case 'large_redemption': return data.executionId
            default: return null
        }
    }

    function getName( type ){
        switch(type){
            case 'announcement': return `Інформаційне повідомлення`
            case 'large_announcement': return `Інформаційне повідомлення`
            case 'asset': return `Об'єкт МП`
            case 'large_asset': return `Об'єкт МП`
            case 'procedure': return `Аукціон`
            case 'execution': return `Контрактинг`
            case 'large_execution': return `Контрактинг`
            case 'redemption': return `Пріоритетний викуп`
            case 'large_redemption': return `Пріоритетний викуп`
            default: return type
        }
    }

    return (
                <div className={styles.infoBlockContent}>
                    { loader && <Loader small={true} /> }
                    <div className={styles.relatedEntitiesList}>
                    { entitiesArr.map( obj => {
                                if ( !!obj?.data ) return <ListItem key={obj.data.id} data={obj.data} type={obj.type} /> 
                                else return null
                            }
                        ) 
                    }
                    </div>
                </div>
        )
}

export default RelatedEntities