import React from 'react';
import styles from './elems.module.css';
import { useDispatch, useSelector } from 'react-redux';

import FullInfoBlock from '../../../../elements/fullInfoBlock/fullInfoBlock';
import HalfInfoBlock from '../../../../elements/halfInfoBlock/halfInfoBlock';
import { dateTransform, isMongo, getAddedTax, getCurrency } from '../../../../../utils/check';
import { getExecutionContractStatus, getExecutionContractDocType } from '../../utils';
import PopDownBlock from '../../../../elements/popdownBlock/popDownBlock';
import BuyerItem from './buyerItem';
import ViewDocItem from '../../../../elements/documents/view/viewDocumentItem';

const ExecusionContracts = ( { data, obj } ) => {
    const executionItem = useSelector(state => state.asset.executionItem)

    return <div className={styles.infoBlockOrgContent}>
                { !!data && data.length > 0 &&
                    data.map( item => <div className={styles.decisionItem} key={item.id} >
                        {!!item?.contractNumber &&
                            <HalfInfoBlock
                                title='Номер договору'
                                data={ item.contractNumber}
                            />
                        }
                        {!!item?.status &&
                            <HalfInfoBlock
                            title={'Статус договору'}
                            data={ getExecutionContractStatus( item.status ) }
                            />
                        }
                        {!!item?.title &&
                            <FullInfoBlock
                                title='Назва договору'
                                data={ isMongo(item.title)}
                            />
                        }
                        {!!item?.description &&
                            <FullInfoBlock
                                title='Опис договору'
                                data={ isMongo(item.description)}
                            />
                        }
                        {!!item?.contractTime &&
                            <HalfInfoBlock
                                title='Строк виконання умов приватизації'
                                data={ `${dateTransform(item.contractTime.dateFrom)} - ${dateTransform(item.contractTime.dateTill)}`}
                            />
                        }
                        {!!item?.dateSigned &&
                            <HalfInfoBlock
                            title={'Дата підписання договору'}
                            data={ dateTransform(item.dateSigned, 'time') }
                            />
                        }
                        {!!item?.value &&
                            <HalfInfoBlock
                            title={'Вартість договору'}
                            data={`${item.value.amount} ${ getCurrency( item.value.currency) } ${ getAddedTax(item.value)}`}
                            />
                        }
                        {!!item?.contractTotalValue &&
                            <HalfInfoBlock
                            title={'Заключна вартість договору'}
                            data={`${item.contractTotalValue.amount} ${ getCurrency( item.contractTotalValue.currency) } ${ getAddedTax(item.contractTotalValue)}`}
                            />
                        }
                        {!!item?.datePublished &&
                            <HalfInfoBlock
                            title={'Дата створення об\'єкту договору'}
                            data={ dateTransform(item.datePublished, 'time') }
                            />
                        }
                        {!!item?.dateModified &&
                            <HalfInfoBlock
                            title={'Остання дата редагування договору'}
                            data={ dateTransform(item.dateModified, 'time') }
                            />
                        }
                        { !!item.buyers && 
                            <PopDownBlock title={'Покупець'} 
                                content={
                                    item.buyers.map( i => <BuyerItem data={i} key={i.id} /> )
                                }
                            />
                        }
                        { ( !!item.documents && item.documents.length > 0 ) && 
                            <PopDownBlock title={`Документи (${item.documents.length})`} 
                                content={<div className={styles.docList}>
                                        { item.documents.map( i => <ViewDocItem 
                                                                    key={i.id}
                                                                    data={ i } 
                                                                    specs={{
                                                                        objId: data.id,
                                                                        docType: getExecutionContractDocType(i.documentType),
                                                                        historyURL: `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/executions/${executionItem.id}/contracts/${item.id}/document/${i.id}/history`
                                                                    }}
                                                                /> )
                                        }
                                    </div>
                                }
                            />
                        }
                    </div> )
                }
        </div>
}

export default ExecusionContracts