import { getAssetByPublicId, getAnnouncementsByPublicId, urlGetExecutionPubId } from './../../../utils/endPoints'
import { checkProp } from '../../../utils/check'
import axios from "axios"

export function getRedemptionStatus(status){
    const dictionary = {
            "active_redemption": { "uk_UA": "Привітизація об'єкта шляхом викупа", "en_US": "" },
            "active_contracting": { "uk_UA": "Виконання умов приватизації", "en_US": "" },
            "sold": { "uk_UA": "Об'єкт продано", "en_US": "" },
            "dissolved": { "uk_UA": "Об'єкт не продано", "en_US": "Privatization cancelled" }
        }
    if ( status === 'dict' ) {
        let tmpArr = []
        for (let key in dictionary ){
            let tmp = { value: key, name: dictionary[key].uk_UA }
            tmpArr = [ ...tmpArr, tmp ]
        }
        return tmpArr
    }
    if( checkProp(dictionary, status ) ) return dictionary[status].uk_UA
    if( !status ) return "Чернетка"
}

export async function getInfo( url ) {
    try {
        const data = await axios.get( url, { headers: {
            'Content-Type': 'application/json'
        } })
        return data.data
    } catch (err) {
        // return.
    }
}

export async function getRelatedEntities( re, setData ){
    if ( !re ) return null
    for ( let key in re ){
        if ( re[key].type === 'asset' ){
            let tmp = await getInfo( getAssetByPublicId( re[key].objectId ) )
            setData( prev => [ ...prev, { type: 'asset', data: tmp } ])
        }
        if ( re[key].type === 'large_asset' ){
            let tmp = await getInfo( getAssetByPublicId( re[key].objectId ) )
            setData( prev => [ ...prev, { type: 'large_asset', data: tmp } ])
        }
        if ( re[key].type === 'announcement' ){
            let tmp = await getInfo( getAnnouncementsByPublicId( re[key].objectId ) )
            setData( prev => [ ...prev, { type: 'announcement', data: tmp } ])
        }
        if ( re[key].type === 'large_announcement' ){
            let tmp = await getInfo( getAnnouncementsByPublicId( re[key].objectId ) )
            setData( prev => [ ...prev, { type: 'large_announcement', data: tmp } ])
        }
        if ( re[key].type === 'procedure' || typeof re[key].type === 'undefined'){
            let tmp = await getInfo( `${process.env.REACT_APP_END_POINT_DTO}/api/v2/uk/public/auction/${re[key]._id}` )
            setData( prev => [ ...prev, { type: 'procedure', data: tmp } ])
        }
        if ( re[key].type === 'execution' ){
            let tmp = await getInfo( urlGetExecutionPubId( re[key].objectId ) )
            setData( prev => [ ...prev, { type: 'execution', data: tmp } ])
        }
        if ( re[key].type === 'large_execution' ){
            let tmp = await getInfo( urlGetExecutionPubId( re[key].objectId ) )
            setData( prev => [ ...prev, { type: 'large_execution', data: tmp } ])
        }
    }
}

export function getAnnouncementDocType( type ){
    const dictionary = {
        "notice": { "uk_UA": "Інформаційне повідомлення", "en_US": ""},
        "evaluationCriteria": {"uk_UA": "Рішення про затвердження умов продажу","en_US": ""},
        "contractProforma": {"uk_UA": "Проект договору","en_US": ""},
        "redemptionPreContract": {"uk_UA": "Попередній договір","en_US": ""},
        "digitalSignature": {"uk_UA": "Цифровий підпис","en_US": ""},
        "clarifications": {"uk_UA": "Документ з інформацією про виправлення помилок","en_US":''}
        }
    if( checkProp(dictionary, type ) ) return dictionary[type].uk_UA
    else return type
}

export function getExecutionContractStatus(status){
    const dictionary = {
        "draft": {"en_US": null,"uk_UA": "Чернетка"},
        "pending": { "uk_UA": "Опубліковано рішення про викуп", "en_US": null },
        "active_contracting": { "uk_UA": "Виконання умов приватизації", "en_US": null }, 
        "sold": { "uk_UA": "Об'єкт продано", "en_US": null }, 
        "deleted": { "uk_UA": "Продаж скасовано", "en_US": null }
    }

    if( checkProp(dictionary, status ) ) return dictionary[status].uk_UA
    if( !status ) return "Чернетка"
}

export function getExecutionContractDocType(type){
    const dictionary = {
        "additionalAgreement": { "uk_UA": "Додаткова угода", "en_US": "Additional agreement" },
        "contractSigned": { "uk_UA": "Підписаний договір", "en_US": "Signed contract" },
        "contractNotice": { "uk_UA": "Повідомлення про договір", "en_US": "Contract notice" },
        "contractAnnexe": { "uk_UA": "Додатки до договору", "en_US": "Contract annexe" }
    }
    if ( type === 'dict' ) {
        let tmpArr = []
        for (let key in dictionary ){
            let tmp = { value: key, name: dictionary[key].uk_UA }
            tmpArr = [ ...tmpArr, tmp ]
        }
        return tmpArr
    }
    if( checkProp(dictionary, type ) ) return dictionary[type].uk_UA
    if( !type ) return type
}

export function getRedemptionDocType( type ){
    const dictionary = {
        "redemptionRights": {"uk_UA": "Підтвердження права на викуп","en_US": ""},
        "clarifications": {"uk_UA": "Документ з інформацією про виправлення помилок","en_US":''},
        "digitalSignature": {"uk_UA": "Цифровий підпис","en_US": ""},
        "redemptionNotice": {"uk_UA": "Рішення про приватизацію об\'єкта шляхом викупу","en_US": ""},
        "contractSigned": {"uk_UA": "Підписаний договір","en_US": ""},
        "contractAnnexe": {"uk_UA": "Додатки до договору","en_US": ""},
        }
    if( checkProp(dictionary, type ) ) return dictionary[type].uk_UA
    else return type
}