export function setAnnouncementDocTypes( items, status ){
    // let illustration = true
    // let tmpDate = Date.now()
    let result = [
        { value: 'notice', name: 'Інформаційне повідомлення', main: false },
        { value: 'evaluationCriteria', name: 'Рішення про затвердження умов продажу', main: false },
        { value: 'contractProforma', name: `Проєкт договору`, main: false },
        { value: 'clarifications', name: `Документ з інформацією про виправлення помилок`, main: false },
        { value: 'redemptionPreContract', name: `Попередній договір`, main: false }
    ]
    // if (status === 'pending') result = [...result, { value: 'cancellationDetails', name: `Рішення про виключення об'кта з переліку`, main: false } ]
    return result
}

export function setAnnouncementDocTypesLP( items, status ){
    // let illustration = true
    // let tmpDate = Date.now()
    let result = [
        { value: 'notice', name: 'Інформаційне повідомлення', main: false },
        { value: 'evaluationCriteria', name: 'Рішення про затвердження умов продажу', main: false },
        { value: 'contractProforma', name: `Проєкт договору`, main: false },
        { value: 'clarifications', name: `Документ з інформацією про виправлення помилок`, main: false },
        /*{ value: 'redemptionPreContract', name: `Попередній договір`, main: false }*/
    ]
    // if (status === 'pending') result = [...result, { value: 'cancellationDetails', name: `Рішення про виключення об'кта з переліку`, main: false } ]
    return result
}

// export function getAssetItemType(id, aucType){ // функция нужна для определения типа айтема, и вывода нужных доков
//     if (!id) return
//     let tmpId = id[0] + id[1]
//         if(['07','08'].includes(tmpId)) return 'claimRights'
//         if(['04','05','06','16','30','32','34','39','42'].includes(tmpId)) return 'asset'
//         return null
// }