import React, { useState, useEffect} from 'react';
import styles from './elems.module.css';
import {useSelector, useDispatch} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { isMongo, checkProp, isDraftObject } from '../../../../../utils/check';
import Input from '../../../../inputs/input';
import InputSelect from '../../../../inputs/inputSelect';
import InputTextArea from '../../../../inputs/inputTextArea';
import AssetOrganizationItem from '../../view/elems/assetOrganizationItem';
import Button from '../../../../elements/buttons/button';
import InputDate from '../../../../inputs/inputDate';
import AssetHolder from './organizations/orgAssetItem/orgAssetItem';
import InfoBlockСollapser from '../../../../elements/infoBlockСollapser/infoBlockСollapser';
import WarningBlock from '../../../../elements/warningBlock';
import { assetCreate, assetUpdate } from '../../../../../redux/asset/assetActions';
import { getMyAsset, getMyAssetDraft, postMyAssetDraft } from '../../../../../utils/endPoints';
import Loader from '../../../../loader/loader';
import AssetDecisionsEdit from './decisions/decisionsEdit';


const AssetGeneralCreate = ( { data, create, enableEdit } ) => {
    const userProfiles = useSelector(state => state.start.userProfiles);
    const token = useSelector(state => state.start.token);
    // const assetItem = useSelector(state => state.asset.assetItem)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [loader, setLoader] = useState( false )

    const [title, setTitle] = useState( !!data?.title ? isMongo(data.title) : '')
    const [description, setDescription] = useState( !!data?.description ? isMongo(data.description) : '')
    const [ownershipType, setOwnershipType] = useState( !!data?.ownershipType ? data.ownershipType : '')
    const [decisions, setDecisions] = useState( !!data?.decisions ? data.decisions : [{
            decisionTitle: '',
            decisionNumber: '',
            decisionDate: null,
            decisionOf: 'asset'
        }] )

    const [assetHolder, setAassetHolder] = useState( !!data?.assetHolder ? data.assetHolder : null )
    const [assetCustodian, setAssetCustodian] = useState( null )

    useEffect(() => {
      if (!!userProfiles && userProfiles?.organization ){
        setAssetCustodian( userProfiles.organization)
      }

    }, [userProfiles])

    function createObjectData(){

        const createLocale =( data )=> {
            const findLocale =(data)=>{
                if ( data.hasOwnProperty('uk_UA') ) return data
                else return { uk_UA: data }
            }
            data.name = findLocale(data.name)
            data.address.streetAddress = findLocale(data.address.streetAddress)
            data.address.locality = findLocale(data.address.locality)
            data.address.region = findLocale(data.address.region)
            data.address.countryName = findLocale(data.address.countryName)
            if( data.address.addressIdentifier ) { data.address.addressIdentifier.name = findLocale(data.address.addressIdentifier.name) }
            data.identifier.legalName = findLocale(data.identifier.legalName)
            data.identifier.id = !!data?.identifier?.identifier ? data.identifier.identifier : data.identifier.id
            data.contactPoint.name = findLocale(data.contactPoint.name)
            delete data.identifier.identifier
            delete data.contactPoint.id
            delete data.additionalIdentifiers
            return data
        }

        let body = {
            title: { uk_UA: title },
            description: { uk_UA: description },
            ownershipType: ownershipType,
            assetHolder: assetHolder,
            assetCustodian: createLocale( assetCustodian ),
            decisions: decisions,
        }
        if(!data) body.assetItems = []
        if(!data) body.documents = []
        if ( enableEdit )  delete body.decisions
        function setNavigate(id){
            return navigate(`/asset/edit/${id}`)
        }
        if ( create ) {
            //dispatch( assetCreate( postMyAssetDraft, JSON.stringify(body), token.access_token, setNavigate ) )
            dispatch( assetCreate( postMyAssetDraft('asset'), JSON.stringify(body), token.access_token, setNavigate ) )
            setTimeout( ()=> setLoader(false), 500)
            setLoader(true)
        } else {
        const url = ( id ) => isDraftObject(id) ? getMyAssetDraft(id) : getMyAsset(id)
        dispatch( assetUpdate( url(data.id) , JSON.stringify(body), token.access_token))
        setTimeout( ()=> setLoader(false), 300)
        setLoader(true)
        }
        window.scrollTo(0, 0)
    }

    if ( loader ) return <Loader />

    return (
    <div className={styles.blockWrap}>
        <div className={styles.infoBlock}>
        <div className={styles.infoBlockTitle}>Загальні характеристики</div>
            <Input 
                label='Найменування обʼєкту' 
                value={title}
                onChange={ e => setTitle(e)}
                reqiured
            />
            <InputTextArea 
                label='Опис об`єкту'
                value={description}
                onChange={ e => setDescription(e)}
                reqiured
            />
            <InputSelect 
                label='Тип власності об’єкту малої приватизації'
                value={ownershipType} 
                onChange={ e => setOwnershipType(e)}
                dictionary={true}
                options={[
                    {name: "state",description: {uk_UA: "Державна"}},
                    {name: "municipal",description: {uk_UA:"Комунальна"}},
                    {name: "compatible",description: {uk_UA:"Сумісна"}},
                ]}
                reqiured
            />
        </div>
        <div className={styles.infoBlock}>
        <div className={styles.infoBlockTitle}>Рішення органу приватизації про затвердження умов продажу *</div>
        <AssetDecisionsEdit data={ decisions } setData={ setDecisions } />
        </div>
        <div className={styles.infoBlock}>
            <div className={styles.infoBlockTitle}>Інформація про балансоутримувача *</div>
                <AssetHolder data={assetHolder} setData={ setAassetHolder } />
        </div>
        <div className={styles.infoBlock}>
            <div className={styles.infoBlockTitle}>Інформація про розпорядника об'єкта</div>
            <WarningBlock data={'Інформація підтягується автоматично, змінити її ви можете безпосередньо у своєму профілі користувача'} />
            { !!assetCustodian ? <AssetOrganizationItem data={assetCustodian} /> : null }
        </div>
        <Button
            onClick={ createObjectData }
        >
            { !!data ? 'Зберегти' : 'Зберегти та продовжити' }
        </Button>
    </div>
    )
}

export default AssetGeneralCreate