import React, {useEffect, useState} from 'react';
import styles from './leaseReqBlock.module.css';
import { useSelector, useDispatch } from 'react-redux';
// import { leaseReqestsGet } from '../../../../../redux/actions/auctions/auctions';
import { setPopupLeaseRequest } from '../../../../../redux/actions/leaseRequests/leaseRequests';
import { requestsGetMy } from '../../../../../redux/actions/leaseRequests/leaseRequests';

import PopupLeaseRequest from './popupLeaseRequest/popupLeaseRequest';
import { checkProp, dateTransform } from '../../../../../utils/check';
import LeaseReqestItem from './leaseRequestItem';
import Button from '../../../../elements/buttons/button';

import { postAPI, getAPI, patchAPI } from '../../../../../redux/actions/crud';

const LeaseReqBlock = ({ data, objId, setLeaseRequests, ...props }) => {
    const dispatch = useDispatch()
    const userProfiles = useSelector(state => state.start.userProfiles)
    const token = useSelector(state => state.start.token);
    // const request = useSelector(state => state.start.leaseRequestsGet)
    const object = useSelector(state => state.start.auctionsGetId)
    const popupAddLeaseRequest = useSelector(state => state.start.popupAddLeaseRequest)

    useEffect(() => {
        getRequests(token)
        window.scrollTo(0, 0)
    }, [])

    function popupLeaseRequest() {
        if(popupAddLeaseRequest === true) return <PopupLeaseRequest objectId={object.id} />
    }

    async function getRequests(token){
        const response = await fetch( `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/object/${objId}/requests`, {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + token.access_token,
            }
        }).catch(console.error)
        if(response === undefined || response === 'undefined'){
        }else{
            const json = await response.json()
            if(json.hasOwnProperty('message') === true){
                // let tmp = JSON.stringify({title: 'Помилка при збереженні файлу', message: JSON.stringify(json)})
                // setApi( prev => ({...prev, error: tmp }))
                // await setApi( prev => ({...prev, loading: false}))
            } else {
                setLeaseRequests(json)
            }
        }


        //setLeaseRequests([])
    }

    return (
        <>
            { !!data && data.length > 0 ?
                data.map( ( item ) => <LeaseReqestItem key={item.id} item={item} /> ) :
                <div className={styles.nothing}>Наразі заяв не надходило</div>
            }
            { ( checkProp(userProfiles, 'canCreateObjects') && !!userProfiles.canCreateRequests ) && //TODO: userProfiles трохи папіздаватаму - пєрєдєлать
                <div className={styles.bottomBtnBlock}>
                    <Button onClick={() => dispatch( setPopupLeaseRequest( true ) )}>Подати заяву на обʼєкт</Button>
                </div>
            }
            { popupLeaseRequest(props.id) }
        </>
    )
}

export default LeaseReqBlock