import React, { useState } from 'react'
import styles from './buyers.module.css'
import Buyer from './buyer/buyer'
import Button from '../../../../../../../../elements/buttons/button'
import AddBuyer from './addBayer/addBuyer'

const Buyers =({ data, setBuyers, update, isDraftObject })=> {
    const [ popAddBuyer, setPopAddBuyer ] = useState( false )

    return (
        <div className={styles.infoBlock}>
            <div className={styles.infoBlockTitle}> { ( !!data && data.length > 1 ) ? 'Орендарі' : 'Орендар' } </div>
            {/* { !data && data.length === 0 && <div>Додайте орендаря</div>} */}
            { !!data && 
                <div className={styles.itemsListContent}>
                    { data.map( (i, count ) => <Buyer data={ i } key= { i.identifier.identifier } index={ count } setBuyers={ setBuyers } isDraftObject={isDraftObject} /> ) }
                </div>
            }
            { isDraftObject &&
            <div className={styles.buttonsBlock}>
                <div className={styles.postButtonsWrap}>
                    <Button onClick={ () => setPopAddBuyer(true) } >
                        Додати орендаря
                    </Button>
                </div>
            </div>
            }
            {
                popAddBuyer && <AddBuyer close={ ()=> setPopAddBuyer(false)} setData={ setBuyers } update={ ()=> update( { buyers: data }) } />
            }
        </div>
    )
}

export default Buyers