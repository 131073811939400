import React,{useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getClassification } from '../../../../../../../../redux/asset/assetActions';
import { itemClassificatorMask } from '../../../../../../../../utils/endPoints';
import InputSelect from '../../../../../../../inputs/inputSelect';
import Loader from '../../../../../../../loader/loader';
import { isMongo } from '../../../../../../../../utils/check';

const Classificator =({ data, setData })=> {
    const dispatch = useDispatch()
    // const token = useSelector(state => state.start.token);
    const assetItem = useSelector(state => state.asset.assetItem)
    const classificationCAV = useSelector( state => state.asset.classificationCAV );

    const [ classification, setClassification ] = useState( data?.id ? data.id : null )
    const [ mainClassif, setMainClassif ] = useState( data?.id ? `${data?.id[0]}${data?.id[1]}` : null)

    useEffect(() => {
        if ( !!data ) setClassification( data.id )
        return () => {
            dispatch( {payload: null, type: 'CLASSIFICATION_CAV'})
          }
    }, [data])
    

    useEffect(() => {
      if ( !!mainClassif ){
        dispatch( getClassification( itemClassificatorMask( mainClassif ) ))
      }
    }, [ mainClassif ])

    useEffect(() => {
        if ( !!classification && !!classificationCAV ){
            // debugger
            let tmp = classificationCAV.find( i => i.id === classification )
            setData( {
                id: tmp.id,
                description: { uk_UA:isMongo( tmp.description )},
                scheme: tmp.scheme
            } )
        }
    }, [ classification, classificationCAV ])
    
    function classificationIdOption(){
        let collatore = new Intl.Collator('uk-UA')
        if( classificationCAV !== null ){
            let tmp = classificationCAV
            tmp.sort(
                (a, b) => collatore.compare(a.classificationId, b.classificationId)
            )
            const regex = new RegExp(/^04[0-9]0.*$|^040[1-9].*$/)
            const regex2 = new RegExp(/^05[0-9]0.*$|^050[1-9].*$']/)
            return tmp
            .filter( i => !i.id.match(regex) )
            .filter( i => !i.id.match(regex2) )
            .map(
                (i) => {
                    return { key: i.id, value: i.id, name: `${ i.scheme + ' ' + i.id + ' ' + i.description.uk_UA }`}
                }
            )
        }
    }
    
    function setSUBClassificator( mainClassif ){
        if( classificationCAV !== null ){ 
            return (<>
                    <InputSelect
                        label="Вкладені коди основного класифікатора"
                        value={ classification }
                        // infoBlock='Виберіть вкладений код основного класифікатора'
                        onChange={( event ) => { 
                            setClassification( event )
                            // setData( event )
                        }}
                        options={ classificationIdOption( ) }
                        finder={ true }
                        reqiured
                        // cav={true}
                    />
                </>
            )
        }else{
            if( mainClassif !== 'null'){
                return (
                    <Loader small/>
                            // Зачекайте. Вкладені коди основного класифікатора завантажуються

                )
            }
        }
    }

    return (
        <>
            <InputSelect
                    label="Основний класифікатор"
                    value={ mainClassif }
                    // infoBlock='оберіть основний класифікатор лоту'
                    onChange={ event => {
                        setMainClassif( event )
                        setClassification( null )
                        setData( null )
                    }}
                    options={ mainCAV( assetItem.assetItems ) }
                    reqiured
                />
                { !!mainClassif && setSUBClassificator( mainClassif ) }
        </>
    )
}



const mainCAV =( items )=> {
    let classifTmp = [
        { key:'04000000-8', value:'04', name: 'CAV 04 Нерухоме майно (та усі вкладені коди)'},
        { key:'05000000-5', value:'05', name: 'CAV 05 Цілісний майновий комплекс або майновий комплекс - комплекс активів підприємства (та усі вкладені коди)'},
        { key:'06000000-2', value:'06', name: 'CAV 06 Земельні ділянки (та усі вкладені коди)'},
        { key:'08000000-6', value:'08', name: 'CAV 08 Корпоративні права (та усі вкладені коди)'},
        { key:'16000000-5', value:'16', name: 'CAV 16 Сільськогосподарська техніка (та усі вкладені коди)'},
        { key:'30000000-9', value:'30', name: 'CAV 30 Офісна та комп’ютерна техніка, устаткування та приладдя, крім меблів та пакетів програмного забезпечення (та усі вкладені коди)'},
        { key:'32000000-3', value:'32', name: 'CAV 32 Радіо-, телевізійна, комунікаційна, телекомунікаційна та супутня апаратура й обладнання (та усі вкладені коди)'},
        { key:'34000000-7', value:'34', name: 'CAV 34 Транспортне обладнання та допоміжне приладдя до нього (та усі вкладені коди)'},
        { key:'39000000-2', value:'39', name: 'CAV 39 Меблі (у тому числі офісні меблі), меблево-декоративні вироби, побутова техніка (крім освітлювального обладнання) та засоби для чищення (та усі вкладені коди)'},
        { key:'42000000-6', value:'42', name: 'CAV 42 Промислова техніка (та усі вкладені коди)'}
    ]
    for (let key in items ){
        let id = items[key].classification.id[0] + items[key].classification.id[1]
        // if ( id === '04' ){
        //     classifTmp = [ ...classifTmp, { key:'06000000-2', value:'06', name: 'CAV 06 Земельні ділянки (та усі вкладені коди)'}]
        //     break
        // }
        if ( id === '05' ){
            //classifTmp = [ ...classifTmp, { key:'06000000-2', value:'06', name: 'CAV 06 Земельні ділянки (та усі вкладені коди)'}]
            classifTmp = [ ...classifTmp, { key:'07000000-9', value:'07', name: 'CAV 07 Права вимоги за кредитними договорами (та усі вкладені коди)'}]
            break
        }
        
    }
    return classifTmp.sort((a, b) => +a.value - +b.value)
}

export default Classificator