import React,{ useState, useEffect } from 'react';
import styles from './itemProps.module.css'
import { isMongo } from '../../../../../../../../../utils/check';
import {useSelector, useDispatch} from 'react-redux';
import Input from '../../../../../../../../inputs/input';
import InputTextArea from '../../../../../../../../inputs/inputTextArea';
import Button from '../../../../../../../../elements/buttons/button';
import Localization from '../localization/localization';
import Loader from '../../../../../../../../loader/loader';
import { objectItemCreate, objectItemUpdate } from '../../../../../../../../../utils/objects/objectsUtils';


const OtherPropertyPropsEdit =({ data, classification, close })=> {
    const objectData = useSelector(state => state.start.objectGet);
    const token = useSelector(state => state.start.token);

    // const unitCodeList = useSelector(state => state.start.dictionaryUnitCode);
    // const assetRegistrationStatus = useSelector(state => state.start.assetRegistrationStatus)
    const dispatch = useDispatch()

    const [loader, setLoader] = useState( false )

    // const [additionalClassificator, setAdditionalClassificator] = useState( data?.additionalClassifications[0]?.id ? data.additionalClassifications[0].id : null )
    const [title, setTitle] = useState( data?.basicInfo.title ? isMongo(data.basicInfo.title) : null )
    const [description, setDescription] = useState( data?.basicInfo.description ? isMongo(data.basicInfo.description) : null )
    const [location, setLocation] = useState( data?.placing.location ? data.placing.location : null )
    const [address, setAdress] = useState( data?.placing.address ? data.placing.address : null )

    // const addClassif = getAdditionalClassificatorDM(classification.id)

    // useEffect( () => {
    //     // if ( unitCodeList === null ) {
    //     //     dispatch( getUnitCodes() );
    //     // }
    //     // if ( assetRegistrationStatus === null ) {
    //     //     dispatch( dictionaryGet('registrationStatus', 'ASSETT_GET_REGISTRATION_STATUS') );
    //     // }
    //     // setAdditionalClassificator( addClassif.id )
    // }, []);

    const requestBody = {
        itemType: 'otherProperty',
        basicInfo: {
            description: {
                uk_UA: description,
            },
            title: { uk_UA: title, },
            classification: classification,
        },
        placing: { 
            location: location,
            address: address
        }
    }

    function sendItemData( data ){
        const body = JSON.stringify(requestBody)
        if ( !data ) dispatch ( objectItemCreate(objectData.id, body, token.access_token ))
        if ( !!data ) dispatch ( objectItemUpdate(objectData.id, data.id, body, token.access_token ))
        setLoader( true )
        close()
    }

    function viewButtons(){
        return (
            <div className={styles.lineButtons}>
                <Button
                    data={'Зберегти зміни'}
                    color={'blue'}
                    onClick={
                        () => { sendItemData( data ) }
                    }
                />
                <Button
                    data={'Закрити'}
                    color={'gray'}
                    onClick={ close }
                />
            </div>
        )
    }

    if( loader ) return <Loader />
    return (
        <>
            <Input
                label="Назва об'єкта"
                value={ title }
                onChange={ event => setTitle( event ) }
                reqiured
            />
            <InputTextArea
                value={description}
                // disabled={blokedInput(props, '01')}
                label="Опис об'єкту"
                reqiured
                onChange={ event => setDescription(event) }
            />
            <Localization address={address} location={location} setLocation={setLocation} setAdress={setAdress} classification={classification} />
            { viewButtons() }
        </>
    )
}

export default OtherPropertyPropsEdit

