import React, { useState, useEffect } from 'react';
import styles from './myAssetTabItem.module.css';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { checkProp, isMongo, getRegistryType, getAssetStatus, dateTransform } from '../../../../utils/check';
import { assetDelete, assetStatusUpdate } from '../../../../redux/asset/assetActions';
import { getMyAssetDraft, setMyAssetStatus } from '../../../../utils/endPoints';
import UniPopUp from '../../../popups/uniPopUp/uniPopUp';
import { getRelatedEntities } from '../../announcement/utils';

import { getMyAssetsList, getAssetsCopy } from '../../../../utils/endPoints';
import { assetsGetMy } from '../../../../redux/asset/assetActions';


const AssetTabItem = ( { data, id } ) => {
    
    const token = useSelector(state => state.start.token);
    const dispatch = useDispatch()

    const [dropDomnMenu, setdropDomnMenu] = useState(false)
    const [objPopUp, setObjPopup] = useState(false)
    const [popup, setPopup] = useState( { type: '', action: '' } )
    const navigate = useNavigate()
    // const [entitiesArr, setEntitiesArr] = useState([]) //це та юзеффект лишилось тут від архівації

    // useEffect( () => {
    //     if( entitiesArr.length === 0 ) getRelatedEntities( data.relatedEntities, setEntitiesArr )
    // }, [])

    function canArchive( arr ){
        for ( let key in arr ) {
            if ( arr[key].type === 'announcement') {
                if (arr[key].data.status === 'dissolved' ) return true
            }
            if ( arr[key].type === 'procedure') {
                if ( arr[key].data.status === 'unsuccessful' || arr[key].data.status === 'cancelled' ) return true
            }
            return false
        }
    }

    function dropDowmMenu(props) {
        if (dropDomnMenu) {
            const enableEdit = ( data.status === 'pending' || !data.status ) ? true : false
            return <div className={styles.dropDown}>
                <div className={styles.dropdownContent}>
                    <ul>
                        { !!data.assetId && <li><Link to={ `/asset/${data.assetId}` }> Переглянути </Link> </li> }
                        { enableEdit && <li> <Link to={ `/asset/edit/${data.id}` }> Редагувати </Link> </li> }
                        { enableEdit && <li><Link to={`/asset/edit/${data.id}/items`}>Об'єкти</Link></li> }
                        { enableEdit && <li><Link to={`/asset/edit/${data.id}/documents`}>Документи</Link></li> }
                        {/* { ( data.status === 'pending' || canArchive( entitiesArr ) ) && <li>
                                <a onClick={ ()=> setPopup(
                                        { type: 'archive', 
                                            action: ()=> {
                                                dispatch( assetStatusUpdate( setMyAssetStatus(data.id, 'archive'), token.access_token))
                                                setPopup({type: '', action: '' })
                                                setdropDomnMenu( false )
                                                navigate('/asset/my_assets')
                                            }
                                        })}>Архівувати об’єкт</a>
                            </li> 
                        } */}
                        { data.status === 'pending' && <li><Link to={'/asset/announcement/create'} state={data} ><strong>Створити інформаційне повідомлення</strong></Link></li> }
                        { data.status === 'pending' && <li><Link to={'/asset/redemption/create'} state={data} ><strong>Створити пріоритетний викуп</strong></Link></li> }
                        { data.status === 'pending' && createCopyObj(data) }
                    </ul>
                </div>
                <div className={styles.dropdownBlock} onClick={ ()=> setdropDomnMenu(!dropDomnMenu) }></div>
            </div>
        }
        return null
    }

    function createCopyObj(data){
        if(data.status === null) return null
        return (
            <li onClick={()=>{
                getCopy( getAssetsCopy(data.id))
            }}>Створити копію об'єкту</li>
        )
    }

    async function getCopy(urlContract){
        const response = await fetch( urlContract, {
            method: "GET",
            headers: {
                'Authorization': token.access_token,
                'Content-Type': 'application/json'
            }
        }).catch(console.error)
        if(typeof response === 'undefined' || response === 'undefined'){
        //     //setApi( prev => ({...prev, error: 'Виникла помилка'}))
        }else{
            const json = await response.json()
            if(json.hasOwnProperty('message') === true){
            //     // let tmp = JSON.stringify({title: 'Помилка при збереженні документу', message: JSON.stringify(json)})
            //     // setApi( prev => ({...prev, error: tmp}))
            //     // await setApi( prev => ({...prev, loading: false}))
            }else{
                dispatch( assetsGetMy( getMyAssetsList("large_assets") , token.access_token) );
                return () => {
                    dispatch({type: 'ASSETS_GET_MY', payload: null})
                }
            }
        }
    }

    function setStatus(a){ // статус об'єкта
        switch(a) {
            case 'pending':
                return  <div style={{ color: 'var(--green)'}}>
                        { getAssetStatus(a) }
                        </div>
            case 'active_rectification':
                return <div style={{ color: 'var(--attantion)'}}>
                        { getAssetStatus(a) }
                        </div>
            case 'active_procedure':
                return <div style={{ color: 'var(--red)'}}>
                        { getAssetStatus(a) }
                        </div>
            case 'active_contracting':
                return <div style={{ color: 'var(--green)'}}>
                        { getAssetStatus(a) }
                        </div>
            case 'complete':
                return <div style={{ color: 'var(--blue)'}}>
                        { getAssetStatus(a) }
                        </div>
            case 'active_redemption':
                return <div style={{ color: 'var(--attentionText)'}}>
                        { getAssetStatus(a) }
                        </div>
            case 'archive':
                return <div style={{ color: 'var(--lightGray)'}}>
                        { getAssetStatus(a) }
                        </div>
            case 'deleted':
                return <div style={{ color: 'var(--lightGray)'}}>
                        { getAssetStatus(a) }
                        </div>
            default:
                return <div style={{ color: 'var(--darkGray)'}}>
                            ЧЕРНЕТКА
                        </div>
        }
    }

    const objectPopUp = ( items, title )=> { return (
         <div className={styles.popUpOn}>
                    <div className={styles.objectPopUp}>
                        <div className={styles.closeBtn} onClick={()=> setObjPopup(!objPopUp)}>
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path className={styles.closeBtnSvg} fillRule="evenodd" clipRule="evenodd" d="M8.41421 6.99989L13.364 11.9496C13.7545 12.3402 13.7545 12.9733 13.364 13.3639C12.9734 13.7544 12.3403 13.7544 11.9497 13.3639L7 8.41411L2.05025 13.3639C1.65973 13.7544 1.02656 13.7544 0.636039 13.3639C0.245514 12.9733 0.245515 12.3402 0.636039 11.9496L5.58579 6.99989L0.636039 2.05015C0.245515 1.65962 0.245514 1.02646 0.636039 0.635934C1.02656 0.245409 1.65973 0.245409 2.05025 0.635934L7 5.58568L11.9497 0.635934C12.3403 0.245409 12.9734 0.245409 13.364 0.635934C13.7545 1.02646 13.7545 1.65962 13.364 2.05015L8.41421 6.99989Z" fill="#92929D"/>
                            </svg>
                        </div>
                        <div className={styles.popUpTitle}>
                            { title && title}
                            {/* <span>(Кількість об'єктів: {data.assetItems.length})</span> */}
                        </div>
                            { items &&
                                <ol>
                                    {
                                    items.length > 0 ?
                                            items.map( i => <li key={i.id}>{ `${isMongo(i.description)}, ${isMongo(i.address.locality)}, ${isMongo(i.address.streetAddress)}` }</li> )
                                        :
                                            <div className={styles.tableEmpty}>У Вас немає жодного об'єкта.</div>
                                    }
                                </ol>
                            }
                    </div>
                <div className={styles.popUpBlock} onClick={()=> setObjPopup(!objPopUp)}></div>
            </div>
        )}

    if( !data ) return (
        <div className={styles.objTabItem}>
            <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                <div>Виникла помилка - дані відсутні</div>
                <div style={{color: 'red'}} onClick={ () => assetDelete( getMyAssetDraft( id ), token.access_token, ()=> navigate('/asset/my_assets') )}> видалити </div>
            </div>
        </div>
    )

    return (
        <div className={styles.objTabItem}>
            { !!popup.type && <UniPopUp 
                                type={2}
                                data={ popup.type === 'remove' ? 
                                    `Ви дійсно хочете вилучити об\'єкт "${isMongo(data.title)}"?` : 
                                    `Ви дійсно хочете архівувати об\'єкт "${isMongo(data.title)}"?` 
                                } 
                                close={ ()=> setPopup( {type: '', action: '' } ) }
                                action={ ()=> popup.action() }
                            />
            }
            <div className={styles.tdName}>
                <Link className={styles.aName} to={ !data.assetId ? `/asset/edit/${data.id}` : `/asset/${data.assetId}` }>{ isMongo(data.title) }</Link>
                <p>
                <a onClick={() => setObjPopup(!objPopUp)} className={styles.link}>Кількість об'єктів ({data.assetItems.length})</a></p>
            </div>
            <div className={styles.tdType}>
                <div style={{fontWeight: '600'}}>{ data.assetId }</div>
            </div>
            <div className={styles.tdStatus}>
                { setStatus(data.status) }
            </div>
            <div className={styles.tdRent}>
                { !!data?.datePublished && dateTransform(data.datePublished, 'time') }
            </div>
            <div className={styles.tdBtnWrap}>
                <div className={ dropDomnMenu ? styles.btnActive : styles.tdBtn } onClick={ ()=> setdropDomnMenu( !dropDomnMenu ) }>
                    <svg width="40" height="40" xmlns="http://www.w3.org/2000/svg" viewBox="-11 1 46 4">
                        <g id="Слой_2" data-name="Слой 2">
                            <g id="Слой_1-2" data-name="Слой 1">
                                <path className={styles.svgIcon} d="M5.33,2.67A2.67,2.67,0,1,0,2.67,5.34,2.66,2.66,0,0,0,5.33,2.67Z"/>
                                <path className={styles.svgIcon} d="M14.67,2.67A2.67,2.67,0,1,0,12,5.34,2.67,2.67,0,0,0,14.67,2.67Z"/>
                                <path className={styles.svgIcon} d="M24,2.67a2.67,2.67,0,1,0-2.67,2.67A2.66,2.66,0,0,0,24,2.67Z"/>
                            </g>
                        </g>
                    </svg>
                </div>
            </div>
            { dropDowmMenu() }
            { objPopUp && objectPopUp(data.assetItems, isMongo(data.title)) }
        </div>
    )
}

export default AssetTabItem