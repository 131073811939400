import React,{ useState, useEffect } from 'react';
import styles from './itemProps.module.css'
import {useSelector, useDispatch} from 'react-redux';
import { checkProp, isMongo, getValues } from '../../../../../../../../../utils/check';
import Input from '../../../../../../../../inputs/input';
import InputTextArea from '../../../../../../../../inputs/inputTextArea';
import InputSelect from '../../../../../../../../inputs/inputSelect';
import InputDate from '../../../../../../../../inputs/inputDate';
import Button from '../../../../../../../../elements/buttons/button';
import Localization from '../localization/localization';
import Checkbox from '../../../../../../../../inputs/checkBox';
import { dictionaryGet, getUnitCodes, getAdditionalClassificatorDM } from '../../../../../../../../../utils/check';
import { assetItemCreate, assetItemUpdate } from '../../../../../../../../../redux/asset/assetActions';
import Loader from '../../../../../../../../loader/loader';

const VehicleItemPropsEdit =({ data, classification, close })=> {
    const assetItem = useSelector(state => state.asset.assetItem)
    const token = useSelector(state => state.start.token);

    const objectTransmissionTypeList = useSelector(state => state.start.objectTransmissionTypeList);
    const objectFuelTypeList = useSelector(state => state.start.objectFuelTypeList);
    const unitCodeList = useSelector(state => state.start.dictionaryUnitCode);
    const assetRegistrationStatus = useSelector(state => state.start.assetRegistrationStatus)
    const dispatch = useDispatch()

    const [loader, setLoader] = useState( false )

    const [additionalClassificator, setAdditionalClassificator] = useState( data?.additionalClassifications[0]?.id ? data.additionalClassifications[0].id : null )
    const [description, setDescription] = useState( data?.description ? isMongo(data.description) : null)
    const [registrationStatus, setRegistrationStatus] = useState( !!data?.registrationDetails?.registrationStatus ? data.registrationDetails.registrationStatus : null)
    const [registrationID, setRegistrationID] = useState( !!data?.registrationDetails?.registrationID ? data.registrationDetails.registrationID : null)
    const [registrationDate, setRegistrationDate] = useState( !!data?.registrationDetails?.registrationDate ? data.registrationDetails.registrationDate : null)
    const [unit, setUnit] = useState( !!data?.unit?.code ? data.unit.code : 'H87')
    const [quantity, setQuantity] = useState( data?.quantity ? data.quantity : null)
    const [location, setLocation] = useState( data?.location ? data.location : null)
    const [address, setAdress] = useState( data?.address ? data.address : null)

    const [brand, setBrand] = useState( data?.itemProps?.brand ? data.itemProps.brand : null)
    const [model, setModel] = useState( data?.itemProps?.model ? data.itemProps.model : null)
    const [productionYear, setProductionYear] = useState( data?.itemProps?.productionYear ? data.itemProps.productionYear : null)
    const [engineCapacity, setEngineCapacity] = useState( data?.itemProps?.engineCapacity ? data.itemProps.engineCapacity : null)
    const [fuelType, setFuelType] = useState( data?.itemProps?.fuelType ? data.itemProps.fuelType : null)
    const [transmission, setTransmission] = useState( data?.itemProps?.transmission ? data.itemProps.transmission : null)
    const [kilometrage, setKilometrage] = useState( data?.itemProps?.kilometrage ? data.itemProps.kilometrage : null)
    const [identificationNumber, setIdentificationNumber] = useState( data?.itemProps?.identificationNumber ? data.itemProps.identificationNumber : null)
    const [condition, setCondition] = useState( data?.itemProps?.condition ? data.itemProps.condition : null)
    const [configuration, setConfiguration] = useState( data?.itemProps?.configuration ? data.itemProps.configuration : null)
    const [damagePresence, setDamagePresence] = useState( checkProp(data?.itemProps, 'damagePresence') ? data.itemProps.damagePresence : false)
    const [damagedDescription, setDamagedDescription] = useState( data?.itemProps?.damagedDescription ? data.itemProps.damagedDescription : null)
    const [color, setColor] = useState( data?.itemProps?.color ? data.itemProps.color : null)

    const addClassif = getAdditionalClassificatorDM(classification.id)

    useEffect( () => {
        if ( objectFuelTypeList === null ) {
            dispatch( dictionaryGet('fuelType', 'OBJECT_GET_FUEL_TYPE_LIST') );
        }
        if ( objectTransmissionTypeList === null ) {
            dispatch( dictionaryGet('transmissionType', 'OBJECT_GET_TRANSMISSION_TYPE_LIST') );
        }
        if ( unitCodeList === null ) {
            dispatch( getUnitCodes() );
        }
        if ( assetRegistrationStatus === null ) {
            dispatch( dictionaryGet('registrationStatus', 'ASSETT_GET_REGISTRATION_STATUS') );
        }
        setAdditionalClassificator( addClassif.id )
    }, []);

    function getUnitList( data ){
        if ( !!data ){
            let tmpList = []
            for ( let key in data ){
                let tmpItem = {
                    value: data[key].id,
                    name: data[key].ukUA.name
                }
                tmpList = [...tmpList, tmpItem]
            }
            return tmpList
        }
    }

    function getUnitName( unit, short ){
        for ( let key in unitCodeList) {
            if (unitCodeList[key].id === unit && short ) return unitCodeList[key].ukUA.symbol
            if (unitCodeList[key].id === unit) return unitCodeList[key].ukUA.name
        }
    }

    function registrationDetails(){
        if((registrationStatus !== null && registrationStatus !== '')
            && (registrationID !== null && registrationID !== '')
            && (registrationDate !== null && registrationDate !== '')
        ){
            return {
                registrationStatus: registrationStatus,
                registrationID: registrationID,
                registrationDate: registrationDate
            }
        }
        return null
    }

    function itemProps(){
        if((brand !== null && brand !== '')
            && (model !== null && model !== '')
            && (productionYear !== null && productionYear !== '')
            && (engineCapacity !== null && engineCapacity !== '')
            && (fuelType !== null && fuelType !== '')
            && (transmission !== null && transmission !== '')
            && (kilometrage !== null && kilometrage !== '')
            && (identificationNumber !== null && identificationNumber !== '')
            && (condition !== null && condition !== '')
            && (configuration !== null && configuration !== '')
            && (damagePresence !== null && damagePresence !== '')
            && (damagedDescription !== null && damagedDescription !== '')
            && (color !== null && color !== '')
        ){
            return {
                // itemPropsType: 'vehicle',
                brand: brand,
                model: model,
                productionYear: productionYear,
                engineCapacity: +engineCapacity,
                fuelType: fuelType,
                transmission: transmission,
                kilometrage: +kilometrage,
                identificationNumber: identificationNumber,
                condition: condition,
                configuration: configuration,
                damagePresence: damagePresence,
                damagedDescription: damagePresence ? damagedDescription : null,
                color: color
            }
        }
        return null
    }

    const requestBody = {
        description: {
            uk_UA: description,
        },
        classification: classification,
        additionalClassifications: addClassif,
        registrationDetails: registrationDetails(),
        unit: {
            code: unit,
            name: {
                uk_UA: getUnitName( unit ), //unitValues
            }
        },
        // assetItemType: assets || claimRights,
        quantity: quantity !== null && quantity !== '' && quantity !== 0 ? +quantity : null,
        address: address,
        location: location,
        itemProps: itemProps()
    }

    function sendItemData( data ){
        const body = JSON.stringify(requestBody)
        if ( !data ) dispatch ( assetItemCreate(assetItem.id, body, token.access_token ))
        if ( !!data ) dispatch ( assetItemUpdate(assetItem.id, data.id, body, token.access_token ))
        setLoader( true )
        close()
    }

    function viewButtons(){
        return (
            <div className={styles.lineButtons}>
                <Button
                    data={'Зберегти зміни'}
                    color={'blue'}
                    onClick={
                        () => { sendItemData( data ) }
                    }
                />
                <Button
                    data={'Закрити'}
                    color={'gray'}
                    onClick={ close }
                />
            </div>
        )
    }

    if( loader ) return <Loader />
    return (
        <>
            <InputSelect
                label="Додатковий класифікатор"
                value={ additionalClassificator }
                onChange={ event => setAdditionalClassificator( event ) }
                options={ getAdditionalClassificatorDM( classification.id ) }
                dictionary
                reqiured
            />
            <InputTextArea
                value={description}
                // disabled={blokedInput(props, '01')}
                label="Опис об'єкту"
                required
                onChange={ event => setDescription(event) }
                reqiured
            />
            <InputSelect
                label="Стан державної реєстрації об'єкту"
                value={ registrationStatus }
                onChange={ event =>  setRegistrationStatus( event ) }
                options={ assetRegistrationStatus }
                dictionary
                reqiured
            />
            <div className={styles.twoInLine}>
                <Input
                    label="Номер запису"
                    value={ registrationID }
                    onChange={ event =>  setRegistrationID( event ) }
                    reqiured={ registrationStatus === 'complete' ? true : false }
                />
                <InputDate 
                    data={{
                        label: 'Дата реєстрації',
                        value: registrationDate,
                        onChange: event =>  setRegistrationDate( event ),
                        reqiured: registrationStatus === 'complete' ? true : false
                    }}
                />
            </div>
            <div className={styles.twoInLine}>
                <Input
                    label="Кількість одиниць"
                    value={ quantity }
                    onChange={ event => setQuantity(event) }
                    reqiured
                    // disabled={blokedInput(props, '11')}
                />
                <InputSelect
                    label={"Одиниці виміру об'єкта"}
                    value={ unit }
                    onChange={ event => setUnit(event) }
                    options={ getUnitList( unitCodeList ) }
                    finder
                    reqiured
                />
            </div>
            <div className={styles.semiTitle}>Характеристики транспортного засобу</div>
            <Input
                label="Марка транспортного засобу"
                value={ brand }
                onChange={ event => setBrand(event) }
            />
            <Input
                label="Модель транспортного засобу"
                value={ model }
                onChange={ event => setModel(event) }
                required
            />
            <div className={styles.twoInLine}>
                <Input
                    label="Рік випуску"
                    value={ productionYear }
                    onChange={ event => setProductionYear(event) }
                    required
                />
                <Input
                    label="Об’єм двигуна, см³"
                    value={ engineCapacity }
                    onChange={ event => setEngineCapacity(event) }
                />
            </div>
            <div className={styles.twoInLine}>
                <InputSelect
                    label={"Тип палива"}
                    value={ fuelType }
                    onChange={ event => setFuelType(event) }
                    options={ objectFuelTypeList }
                    dictionary={true}
                />
                <InputSelect
                    label={"Тип коробки передач"}
                    value={ transmission }
                    onChange={ event => setTransmission(event) }
                    options={ objectTransmissionTypeList }
                    dictionary={true}
                />
            </div>
            <div className={styles.twoInLine}>
                <Input
                    label="Пробіг, км³"
                    value={ kilometrage }
                    onChange={ event => setKilometrage(event) }
                />
                <Input
                    label="Номер кузова або шасі"
                    value={ identificationNumber }
                    onChange={ event => setIdentificationNumber(event) }
                />
            </div>
            <Input
                label="Технічний стан"
                value={ condition }
                onChange={ event => setCondition(event) }
            />
            <Input
                label="Комплектація"
                value={ configuration }
                onChange={ event => setConfiguration(event) }
            />
            <Checkbox 
                label="Потреба у ремонті"
                value={ damagePresence }
                onChange={ event => setDamagePresence(event) }
            />
            { damagePresence &&
                <InputTextArea
                    label="Опис пошкоджень"
                    value={ damagedDescription }
                    onChange={ event => setDamagedDescription(event) }
                />
            }
            <Input
                label="Колір"
                value={ color }
                onChange={ event => setColor(event) }
            />

            <Localization address={address} location={location} setLocation={setLocation} setAdress={setAdress} classification={classification} />

            { viewButtons() }
        </>
    )
}

export default VehicleItemPropsEdit
