import React from "react";
import { isMongo } from "../../../utils/check";
import styles from './imgViewPopUp.module.css'

const ImgViewPopUp = ({ data, type = 1, close }) => {

    return <div 
                className={styles.backdrop}
                onClick={close}
            >
                <div className={styles.wrapper}>
                    <div className={styles.cont}>
                        <div className={styles.imageContainer}>
                            <img className={styles.image} src={data.url} />
                        </div>
                        <div className={styles.text}>{isMongo(data.description)}</div>
                    </div>
                    
                </div>
            </div>
}

export default ImgViewPopUp