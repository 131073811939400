import React,{ useEffect, useState } from 'react'
import { useLocation, Navigate} from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useAuth } from '../hook/useAuth'

const RequireAuth = ({ children }) => {
    const [ready, setReady] = useState(false)
    const location = useLocation()
    const { user } = useAuth()
    const token = useSelector(state => state.start.token);
    

    const tmpUser = () => {
        if( !!window.localStorage.userState && window.localStorage.userState !== 'null') return true
        else return false
    }

    if( !token ) return <Navigate to='/' state={{from: location}} />

    return children

}

export { RequireAuth }