import React, { useState } from 'react';
import styles from './documents.module.css';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import ObjectPublish from '../../publishBtn/publishBtn'

import { documentDelete } from '../../../../../../redux/actions/documents/documents';
import { myDraftObjectDocumentPost, myObjectDocumentPost, myDraftObjectDocument, myObjectDocument } from '../../../../../../utils/endPoints';
import AddDocPopUp from '../../../../../elements/documents/addDocPopup/addDocPopup';
import CreateDocItem from '../../../../../elements/documents/docItem/documentItem';
import { getLeaseObjectDocTypes } from '../../../../../../utils/objects/objectsUtils';

const Documents = ( { isDraft } ) => {

    const dispatch = useDispatch();
    const data = useSelector(state => state.start.objectGet);
    const token = useSelector(state => state.start.token);
    const [ addDocPopup, setAddDocPopup ] = useState( false );

    const docProps = {
        dispatch: ( res )=> dispatch( { payload: res, type: 'OBJECT_GET'}),
        docOf: 'object',
        object: data,
        docList: getLeaseObjectDocTypes( true ),
        url: {
            patch: isDraft ? myDraftObjectDocument : myObjectDocument,
            post: isDraft ? myDraftObjectDocumentPost : myObjectDocumentPost,
            delete: ( id ) => dispatch( documentDelete( ( isDraft ? myDraftObjectDocument(data.id, id) : myObjectDocument(data.id, id) ), token.access_token ) )
        }
    }

    function showDocuments(documents) {
        if (documents && documents.length > 0) {
            // return documents.map( (item) => <DocumentItem key={item.id} data={item} />);
            return documents.map( (item) => <CreateDocItem  key={item.id} data={item} docProps={docProps} />);
        }
        return null;
    }



    return(<>
        <div className={styles.wrapper}>
            <div className={styles.title}>Перелік документів</div>
            {data !== null ? <>
                {/* <div className={styles.attention}>
                    <span className={styles.attentionIcon}>
                        <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                            <path fill="#fff" d="M11,14V9A1,1,0,0,0,9,9v5a1,1,0,0,0,2,0Z"></path>
                            <path fill="#fff" d="M9,6a1,1,0,1,1,1,1A1,1,0,0,1,9,6Z"></path>
                            <path fill="#fff" fillRule="evenodd" d="M0,10A10,10,0,1,1,10,20,10,10,0,0,1,0,10Zm18,0a8,8,0,1,0-8,8A8,8,0,0,0,18,10Z"></path>
                        </svg>
                    </span>
                    <div className={styles.attentionContent}>
                        <div className={styles.attentionTitle}>Зверніть увагу!</div>
                        <div>Будь-який перший завантажений файл, в типі документу якого буде зазанчено “Ілюстрації”, відображатиметься як головне фото об’єкту.</div>
                    </div>
                </div> */}
                <div className={styles.docListContainer}>
                    { showDocuments( data.documents ) }
                </div>
                {/* <button className={styles.buttonAdd} onClick={() => dispatch( setPopupAddDocument(true) ) }>Додати документ</button> */}
                <button className={styles.buttonAdd} onClick={ () => setAddDocPopup(true) }>Додати документ</button>
                <div className={styles.buttonWrapper}>
                    <Link to={`/lease/my_objects`} className={styles.buttonCancel}>До списку переліків</Link>
                    {/* <ObjectPublish object={data} /> */}
                </div>
            </> : null}
        </div>
        {/* { popupAddDocument === true && data !== null ? 
            <PopupAddDocument 
                // data={}
                type={'object'} 
                url={ setDocUploadUrl(data.id) } 
                id={data.id} 
                isDraft={isDraft}
            /> 
            
            : null
        } */}
        { addDocPopup && 
            <AddDocPopUp close={ ()=> setAddDocPopup( false ) } docProps={ docProps } />
        }
    </>);
}

export default Documents;