import React, { useState } from 'react';
import styles from './createTitleBar.module.css';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getShorter, isDraftObject, isMongo } from '../../../../../../utils/check';
import Button from '../../../../../elements/buttons/button';
import DeleteIcon from '../../../../../elements/svg/deleteIcon';
import { urlGetMyDraftAction } from '../../../../../../utils/endPoints';
import UniPopUp from '../../../../../popups/uniPopUp/uniPopUp';
// import { leaseRequestDelete } from '../../../../../../utils/requests/requestsUtils';
import { leaseActionDelete } from '../../../../../../utils/actions/actionsUtils';
import SendLeaseActionToProzoro from '../validation/sendToProzoro';

const LeaseActionEditTitle =({ data })=>  {
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const dispatch = useDispatch()
    const token = useSelector(state => state.start.token);
    const path = pathname.split('/')[5]
    const [delPopup, setDelPopup] = useState( false )
    // const [popup, setPopup] = useState( { type: '', action: '' } )

    function setBookmarkStyle(loc){
        if(!data) return styles.inactiveObjNavItem
        if( path === loc) return styles.activeNavItem
        return styles.objNavItem
    }

    function createPad(loc, content, onclick){
       return <div className={ setBookmarkStyle(loc) } onClick={ setBookmarkStyle(loc) === styles.objNavItem ? onclick : undefined } >
                { content }
            </div>
    }

    function deleteDraftObject( data ){
        if( !!data && isDraftObject( data.id ) ) {
            return (
                <Button 
                    height={'50px'} 
                    color='orange' 
                    onClick={ ()=> setDelPopup( true ) }
                    hover={ '#FFA047' }
                    title='Видалити чернетку'
                >
                    <DeleteIcon /> 
                </Button>
            )
        }
    }

    return (
        <div className={styles.navBlock}>
            { delPopup && <UniPopUp 
                                type={2}
                                data={'Ви дійсно хочете видалити чернетку?'}
                                close={ ()=> setDelPopup( false ) }
                                action={ ()=> {
                                        setDelPopup( false )
                                        leaseActionDelete( urlGetMyDraftAction( data.id ), token.access_token, ()=> navigate('/lease/my_actions'), dispatch )
                                    }
                                }
                            /> 
            }
            <div className={styles.titleBlock}>

                <div className={styles.pathString}>
                    <ul className={styles.navUlList}>
                        <li key={1}>
                            <Link to={`/lease/my_actions`}>
                                    Мої дії
                            </Link>
                        </li>
                        <li key={2} className={styles.liTitle}>Чернетка</li>
                        <li key={3}>{ !!data?.description && getShorter( isMongo(data.description) , 80) }</li>
                    </ul>
                </div>

                <div className={styles.titleAndSend}>
                    <div className={styles.objTitle}>
                        { !!data?.title ? isMongo(data.title) : 'Редагування дії' }
                    </div>
                    <div className={styles.sendButton}>
                        <SendLeaseActionToProzoro data={ data } />
                        { deleteDraftObject( data ) }
                    </div>
                </div>
               
            </div>
            <div className={styles.objNav}>
            </div>
        </div>
    )
}

export default LeaseActionEditTitle