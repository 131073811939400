import React, {useState, useEffect} from 'react';
import styles from './inputDate.module.css';

// porps: value, label, time, reqiured, desc
const InputDate = (props) => {

    let xTime = new Date()
    let currentxTime = Math.abs(xTime.getTimezoneOffset()) / 60 // поправка на часовой пояс -3

    const [datePopdown, setdatePopdown] = useState(false)

    const [wrapper, setWrapper] = useState(styles.noDisplay)
    // const [placeholder, setPlaceholder] = useState('рррр-мм-дд')
    const [ complete, setComplete ] = useState( () => {
        if ( props.data.alert ) return styles.inPutAlert
        if ( props.data.disabled ) return styles.disabled
        return styles.inPutDate
    })
    const [alert, setAlert] = useState( props?.data?.alertText ? { view: true, text: props.data.alertText } : { view: false, text: '' })
    const [MONTH, setMonthCurrent] = useState(() => {
            if (props.data.value !== '' && props.data.value !== null && props.data.value !== undefined && props.data.value !== 'undefined') {
                let tmpStamp = Date.parse(props.data.value)
                let tmpDiff = 3600000 * currentxTime
                let tmpRes = tmpStamp// + tmpDiff
                let res = new Date(tmpRes)

                let tmpMonth = res.getMonth()
                return Number(tmpMonth)
            }
            let tmp = new Date()
            return  tmp.getMonth() 
        }
    )
    const [month, setMonth] = useState(() => {
        if (MONTH === undefined || MONTH === 'undefined') {
            let tmp = new Date()
            return tmp.getMonth()
        } else {
            if (MONTH !== '') {
                return MONTH
            } else {
                let tmp = new Date()
                return tmp.getMonth()
            }
        }
    }
    )
    const [YEAR, setYearCurrent] = useState(() => {
            if (props.data.value !== '' && props.data.value !== null && props.data.value !== undefined && props.data.value !== 'undefined') {
                let tmpStamp = Date.parse(props.data.value)
                let tmpDiff = 3600000 * currentxTime
                let tmpRes = tmpStamp + tmpDiff
                let res = new Date(tmpRes)

                let tmpYear = res.getFullYear()
                return Number(tmpYear)
            }
            let tmp = new Date()
            return  tmp.getFullYear()
        }
    )
    const [year, setYear] = useState(() => {
        if (YEAR === undefined || YEAR === 'undefined') {
            let tmp = new Date()
            return tmp.getFullYear()
        } else {
            if (YEAR !== '') {
                return YEAR
            } else {
                let tmp = new Date()
                return tmp.getFullYear()
            }
        }
    }
    )
    const [monthSumDay, setMonthSumDay] = useState(null)
    const [calendarDay, setCalendarDay] = useState(() => {
        if (props.data.value !== '' && props.data.value !== null && props.data.value !== undefined && props.data.value !== 'undefined') {
            let tmpStamp = Date.parse(props.data.value)
            let tmpDiff = 3600000 * currentxTime
            let tmpRes = tmpStamp + tmpDiff
            let res = new Date(tmpRes)

            let tmpDay = res.getDate()
            return Number(tmpDay)
        }
        let tmp = new Date()
        return  tmp.getDate()
    })
    const [calendarCurrentDay, setCalendarCurrentDay] = useState(()=>{
        let tmp = new Date()
        return  tmp.getDate()
    })
    const [time, setTime] = useState(() => {
            let date = new Date()
            if (props.data.time){
                let tmp = `${date.getHours()}:${date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()}`
                if (props.data.value !== '' &&
                    props.data.value !== null &&
                    props.data.value !== undefined &&
                    props.data.value !== 'undefined') {
                    tmp = dateTransForm(props.data.value, 1)
                    return tmp
                }
                return tmp
            }
        }
    )
    const monthToday = new Date().getMonth()
    const yearToday = new Date().getFullYear()
    const [value, setValue] = useState(() => {
        let tmp = ''
        if ( props.data.value !== '' &&
             props.data.value !== null &&
             typeof props.data.value !== 'undefined') {
            tmp = dateTransForm(props.data.value)
            return tmp
        }
        return tmp
        }
    )
    useEffect(() => {
        if( value === '' || value === null ) setValue('Вкажіть дату')
    }, [value])

    useEffect(() => {
        if(alert.view) setComplete(styles.inPutAlert)
        if(props.data.alert === true) setComplete(styles.inPutAlert)
        // if(props.data.styles.disabled === true) setComplete(styles.inPutAlert)
        // else setComplete(styles.inPutDate)
    }, [props.data.alert])

    function dateToggle() {
        if (props.data.disabled) return
        if ( !datePopdown ){
            setWrapper(styles.wrapper)
            setdatePopdown(!datePopdown)
            setComplete(props.data.alert ? styles.inPutAlert : `${styles.inPutDate} ${styles.focus}`)
        } else {
            setWrapper(styles.noDisplay)
            setdatePopdown(!datePopdown)
            setComplete(props.data.alert ? styles.inPutAlert : styles.inPutDate)
        }
    }

    function dateCancel() {
        dateToggle()
      }

    function dateTransForm(x, t) { // сюда еще надо вставить разницу времени и тогда она будет универсальной
        let tmpStamp = Date.parse(x)
        let tmpDiff = 3600000 * currentxTime
        let tmpRes = tmpStamp + tmpDiff
        let res = new Date(tmpRes).toISOString()

        let tmpDate = res.substr(0, 10)
        let tmpTime = res.substr(11, 5)

        if (t === 1) return tmpTime
        if (props.data.time) return `${tmpDate} ${tmpTime}`
        return tmpDate
    }

    let tmpDateStr = ''
    let tmpDateDisp = ''

    if(value === '' || value === null){
        if(year !== null && month !== null && calendarDay !== null){
            let tmpStrMonth = ''
            let tmpStrDay = ''
            if(MONTH+1 < 10){
                tmpStrMonth = MONTH + 1
                tmpStrMonth = '0' + tmpStrMonth
            }else{
                tmpStrMonth = MONTH + 1
            }
            if(calendarDay < 10){
                tmpStrDay = '0' + calendarDay
            }else{
                tmpStrDay = calendarDay
            }
            if (props.data.time && time === '') {
                setTime('00:00')
            }

            tmpDateStr = props.data.time ? `${YEAR}-${tmpStrMonth}-${tmpStrDay} ${time}` : `${YEAR}-${tmpStrMonth}-${tmpStrDay}`
            tmpDateDisp = `${YEAR}-${tmpStrMonth}-${tmpStrDay}T${props.data.time ? time : '00:00'}:00.000Z`
        }
    }else{
            let tmpStrMonth = ''
            let tmpStrDay = ''
            // let tmpTime = time
            if(MONTH+1 < 10){
                //tmpStrMonth = month + 1
                tmpStrMonth = MONTH + 1
                tmpStrMonth = '0' + tmpStrMonth
            }else{
                tmpStrMonth = MONTH + 1
            }
            if(calendarDay < 10){
                tmpStrDay = '0' + calendarDay
            }else{
                tmpStrDay = calendarDay
            }
            if (props.data.time && time === '') {
                setTime('09:00')
            }
        
            tmpDateStr = props.data.time ? `${YEAR}-${tmpStrMonth}-${tmpStrDay} ${time}` : `${YEAR}-${tmpStrMonth}-${tmpStrDay}`
            tmpDateDisp = `${YEAR}-${tmpStrMonth}-${tmpStrDay}T${props.data.time ? time : '09:00'}:00.000Z`
    }

    function setDate(a) {
        // setCalendarData(tmpDateStr)
        dateToggle()
        // setComplete(`${styles.inPutDate} ${styles.complete}`)
        setValue(a)
    }

    function changeMonth(props, flag){
        if(flag === 1){
            let tmp = month + 1
            if(tmp === 12) {
                tmp = 0
                setYear(year + 1)
            }
            setMonth(tmp)
        }else{
            let tmp = month - 1
            if(tmp === -1) {
                tmp = 11
                setYear(year - 1)
            }
            setMonth(tmp)
        }
    }

    function createDay(){
        return (
            <div className={styles.titleCalendar}>
                <div className={styles.daysTitle}>ПН</div>
                <div className={styles.daysTitle}>ВТ</div>
                <div className={styles.daysTitle}>СР</div>
                <div className={styles.daysTitle}>ЧТ</div>
                <div className={styles.daysTitle}>ПТ</div>
                <div className={styles.daysTitle}>СБ</div>
                <div className={styles.daysTitle}>НД</div>
            </div>
        )
    }

    function setDayGrid(props){
        let tmpDate = []
        for (let i = 0; i < monthSumDay; i++){
            if (calendarDay === i + 1 && month === MONTH && year === YEAR) {
                tmpDate[i] = <div key={i}
                    className={styles.days + ' ' + styles.daysCurent}
                    onClick={
                        ()=>{
                            setCalendarDay(i+1)
                            setYearCurrent(year)
                            setMonthCurrent(month)
                        }
                    }
                >{i+1}</div>
            } else if (calendarCurrentDay === i+1 && month === monthToday && year === yearToday){
                tmpDate[i] = <div key={i}
                    className={styles.days + ' ' + styles.daysToday}
                    onClick={
                        ()=>{
                            setCalendarDay(i+1)
                            setYearCurrent(year)
                            setMonthCurrent(month)
                        }
                    }
                >{i+1}</div>
            }else{
                tmpDate[i] = <div key={i}
                    className={styles.days}
                    onClick={
                        ()=>{
                            setCalendarDay(i+1)
                            setYearCurrent(year)
                            setMonthCurrent(month)
                        }
                    }
                >{i+1}</div>
            }
        }
        let tmpDateFirst = new Date(year, month, 1).getDay()
        if(tmpDateFirst === 0) tmpDateFirst = 7
        let emptyBlock = []
        for(let i = 1; i < tmpDateFirst; i++){
            emptyBlock[i] = <div key={i} className={styles.days + ' ' + styles.inactive}></div>
        }

        if((tmpDate.length + emptyBlock.length) === 28){
            return (
                <div className={styles.calendarBlockVerySmall}>
                    {emptyBlock}
                    {tmpDate}
                </div>
            )
        }else if((tmpDate.length + emptyBlock.length) < 37){
            return (
                <div className={styles.calendarBlockSmall}>
                    {emptyBlock}
                    {tmpDate}
                </div>
            )
        }else{
            return (
                <div className={styles.calendarBlock}>
                    {emptyBlock}
                    {tmpDate}
                </div>
            )
        }
    }

    function isLeapYear (year) {
        return year % 4 == 0 && ( year % 100 != 0 || year % 400 == 0 )
    }

    function viewTextMonth(props, data){
        switch(data){
            case 0:
                if(monthSumDay !== 31)
                setMonthSumDay(31)
                return "січень"
            case 1:
                if(isLeapYear(year) === true){
                    if(monthSumDay !== 29)
                setMonthSumDay(29)
                }else{
                    if(monthSumDay !== 28)
                setMonthSumDay(28)
                }
                return "лютий"
            case 2:
                if(monthSumDay !== 31)
                setMonthSumDay(31)
                return "березень"
            case 3:
                if(monthSumDay !== 30)
                setMonthSumDay(30)
                return "квітень"
            case 4:
                if(monthSumDay !== 31)
                setMonthSumDay(31)
                return "травень"
            case 5:
                if(monthSumDay !== 30)
                setMonthSumDay(30)
                return "червень"
            case 6:
                if(monthSumDay !== 31)
                setMonthSumDay(31)
                return "липень"
            case 7:
                if(monthSumDay !== 31)
                setMonthSumDay(31)
                return "серпень"
            case 8:
                if(monthSumDay !== 30)
                setMonthSumDay(30)
                return "вересень"
            case 9:
                if(monthSumDay !== 31)
                setMonthSumDay(31)
                return "жовтень"
            case 10:
                if(monthSumDay !== 30)
                setMonthSumDay(30)
                return "листопад"
            case 11:
                if(monthSumDay !== 31)
                setMonthSumDay(31)
                return "грудень"
        }
    }
    
    const timePicker = props.data.time ? ( <div className={styles.timePicker}>
        Час:
        <input 
            type="time"
            className={styles.timeField}
            value={
                time
            }
            onInput={e => setTime(e.target.value)}
            className={styles.hours} min="0" max="23" />
    </div>) : null
    
    // function setPlaceHolder(placeholdr) {
    //     if (placeholdr === props.placeholder) {
    //         setLabel(props.placeholder)
    //         setPlaceholder('рррр-мм-дд')
    //     } else if (placeholdr === 'рррр-мм-дд') {
    //         setPlaceholder(props.placeholder)
    //         setLabel('')
    //     }
    // }

    function dateChecker(a, b) {
        let reg = ''
        if (a !== '') {
            if (props.data.time) { reg = /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1]) (2[0-3]|[01][0-9]):[0-5][0-9]$/ }
            else { reg = /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/ }
            if (reg.test(a)) {
                setAlert({ view: false, text: '' })
                dateDispatcher(b)
            } else {
                setAlert({ view: true, text: 'Не вірно введена дата' })
            }
        }
        else { setAlert({ view: false, text: '' }) }
    }

    function dateDispatcher(a) {
        let tmpStamp = Date.parse(a)
        let tmpDiff = 3600000 * currentxTime
        let tmpRes = tmpStamp - tmpDiff
        let res = new Date(tmpRes).toISOString()
        props.data.onChange(res)
    }

    return (
        <>
            <div className={styles.inPutWrap}>
                <div className={complete}
                    // onClick={dateToggle}
                    title='Натисніть для вводу дати'>
                    <div className={styles.beforeInput}
                        onClick={dateToggle}
                        title='Натисніть для вводу дати'
                    >
                        
                    </div>
                    <div 
                        className={ value === 'Вкажіть дату' || props.data.disabled ? styles.empty : styles.hiddenInput}
                        onClick={dateToggle}
                    >
                        {value}
                    </div>
                    <div className={styles.afterInput}
                        // onClick={dateToggle}
                        title='Натисніть для вводу дати'>
                        { alert.view ? 
                            alert.text : 
                             !props.data.disabled && 
                             <div 
                                style={{color: 'var(--disabledGrey)'}}
                                onClick={ ()=> { 
                                    setValue(null)
                                    props.data.onChange(null)
                                }
                            }>
                                Скинути
                            </div>
                            
                        }
                        
                    </div>
                </div>
                        <div className={styles.label}>
                            { props.data.reqiured ? `${props.data.label} *` : props.data.label }
                        </div>
                        <div className={wrapper}>
                            {/* date popdown */}
                            { datePopdown && 
                            <div className={styles.datePopdown}>
                                <div className={styles.dateTitle}>{props.data.reqiured ? `${props.data.label} *` : props.data.label}</div>
                                <div className={styles.topControls}>
                                    <div className={styles.fieldMonth}>
                                        <div className={styles.backWd}
                                        onClick={
                                            ()=>{changeMonth(props, 2)}
                                        }
                                        >
                                            <div className={styles.bwd}></div>
                                        </div>
                                        {viewTextMonth(props, month)}
                                        <div className={styles.forWd}
                                        onClick={
                                            ()=>{changeMonth(props, 1)}
                                        }>
                                            <div className={styles.fwd}></div>
                                        </div>
                                    </div>
                                    <div className={styles.fieldYear}>
                                        <div className={styles.backWd}
                                        onClick={
                                            ()=>{setYear(year - 1)}
                                        }
                                        >
                                            <div className={styles.bwd}></div>
                                        </div>
                                        {year}
                                        <div className={styles.forWd}
                                        onClick={
                                            ()=>{setYear(year + 1)}
                                        }
                                        >
                                            <div className={styles.fwd}></div>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.caleendarWrap}>
                                    {createDay()}
                                    {setDayGrid(props)}
                                        {timePicker}
                                    <div className={styles.preview}>{tmpDateStr}</div>
                                    <div className={styles.bottomPart}>
                                        <div className={styles.greyBtn}
                                            onClick={() => {
                                                dateCancel()
                                            }}
                                        >
                                            ВІДМІНИТИ
                                        </div>
                                        <div className={styles.blueBtn}
                                            onClick={() => {
                                                dateChecker(tmpDateStr, tmpDateDisp)
                                                setDate(tmpDateStr)
                                            }}
                                        >
                                            ПРИЙНЯТИ
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        }
                    </div>
                {props.data.desc ? <div className={styles.descript}>{props.data.desc}</div> : null}
            </div>
        </>
    )
}

export default InputDate