import React, { useState, useEffect, useRef } from 'react';
import styles from './period.module.css';

import Time from '../../../../../../../inputs/inputTime';
import { checkProp } from '../../../../../../../../utils/check';
import InputSelect from '../../../../../../../inputs/inputSelect';
import DeleteIcon from '../../../../../../../elements/svg/deleteIcon';

const WeekDayPeriod = ({data, name, weekDaysPeriod, setWeekDaysPeriod, index, ...props}) => {
    const [hourStart, setHourStart] = useState(checkProp(data,'startTime') ? data.startTime : '00:00');
    const [hourEnd, setHourEnd] = useState(checkProp(data,'endTime') ? data.endTime : '00:00');
    const [weekday, setWeekday] = useState(checkProp(data,'weekday') ? data.weekday : null);

    // useEffect(() => {
    //     let tmp = {
    //         weekday: weekday,
    //         endTime: hourEnd,
    //         startTime: hourStart
    //     }
    //     let tmp2 = weekDaysPeriod
    //     setWeekDaysPeriod(tmp2.splice(index, 1, tmp))
    //   }, [weekday, hourStart, hourEnd])
    

    const weekDaysList = [
        {name: 'Понеділок', value: { uk_UA: 'Понеділок' } }, 
        {name: 'Вівторок', value: { uk_UA: 'Вівторок' } }, 
        {name: 'Середа', value: { uk_UA: 'Середа' } }, 
        {name: 'Четвер', value: { uk_UA: 'Четвер' } }, 
        {name: 'П\'ятниця', value: { uk_UA: 'П\'ятниця' } }, 
        {name: 'Субота', value: { uk_UA: 'Субота' } }, 
        {name: 'Неділя', value: { uk_UA: 'Неділя' } }
    ]

    function checkValues(){
        let start = +hourStart.replace(':', '')
        let end = +hourEnd.replace(':', '')
        if ((start !== 0) && (end !== 0) && (end - start <= 5)) return true
        return false
    }

    return (<div className={styles.weekDaysEdit}>
                <div className={styles.weekDaysContent}>
                    <InputSelect
                        value={weekday}
                        onChange={ async (e) => {
                            setWeekday(e)
                            let tmp = weekDaysPeriod
                            tmp.forEach( (element, count) => {
                                if(index === count ) element.weekday = e
                            });
                            // await setWeekDaysPeriod(null)
                            await setWeekDaysPeriod(tmp)
                         }}
                        label={'Оберіть день тижня'}
                        options={weekDaysList}
                    />
                    <Time
                        value={hourStart}
                        onChange={ async (e) => {
                                setHourStart(e)
                                let tmp = weekDaysPeriod
                                tmp.forEach( (element, count) => {
                                    if(index === count ) element.startTime = e
                                });
                                // await setWeekDaysPeriod(null)
                                await setWeekDaysPeriod(tmp)
                        }}
                        label={'Час початку'}
                    />
                    <Time
                        value={hourEnd}
                        onChange={ async (e) => {
                            setHourEnd(e)
                            let tmp = weekDaysPeriod
                            tmp.forEach( (element, count) => {
                                if(index === count ) element.endTime = e
                            });
                            // await setWeekDaysPeriod(null)
                            await setWeekDaysPeriod(tmp)
                    }}
                        label={'Час завершення'}
                        error={checkValues()}
                    />
                    <div className={styles.weekDaysDelete} 
                            title='Видалити графік'
                            onClick={ async ()=>{
                                // alert(index)
                                    let tmp = weekDaysPeriod
                                    tmp.splice(index, 1)
                                    // alert(JSON.stringify(tmp))
                                    await setWeekDaysPeriod(null)
                                    await setWeekDaysPeriod(tmp)
                            }}
                    >
                        <DeleteIcon 
                            color={'#BFBFC6'}
                            style={styles.weekDaysDeleteSVG}
                            widthHeight={[20,20]}
                            
                        />
                    </div>
                </div>
                { checkValues() && <div className={styles.alertBlock}>Час закінчення має бути більшим за час початку щонайменше на 5 хвилин</div> }
            </div>
    )
}


export default WeekDayPeriod;