import React from 'react';
import InputSelect from '../../../inputs/inputSelect';
import styles from './sortSearchFilter.module.css';

const SearchSortFilter = ( { params, setParams } ) => {

    return (
        <div className={styles.sortWrapper}>
            <InputSelect
                label='Сортувати'
                value={ params.order }
                onChange={ ( e )=> setParams( prev => ({...prev, order: e }))}
                options={ [
                    { name: 'Спочатку нові', value: 'DESC'},
                    { name: 'Спочатку старі', value: 'ASC'}
                ]}
            />
        </div>
    )
}

export default SearchSortFilter