import React from 'react';
import styles from './elems.module.css';
import FullInfoBlock from '../../../../elements/fullInfoBlock/fullInfoBlock';
import HalfInfoBlock from '../../../../elements/halfInfoBlock/halfInfoBlock';
import { dateTransform, isMongo, getAddedTax, getCurrency } from '../../../../../utils/check';
import { getExCancellationDocType } from '../../utils';
import PopDownBlock from '../../../../elements/popdownBlock/popDownBlock';
import BuyerItem from './buyerItem';
import ViewDocItem from '../../../../elements/documents/view/viewDocumentItem';

const ExecusionCancellations = ( { data } ) => {

    return <div className={styles.infoBlockOrgContent}>
                { !!data && data.length > 0 &&
                    data.map( item => <div className={styles.decisionItem} key={item.id} >
                        {!!item?.reason &&
                            <FullInfoBlock
                                title='Причина скасування'
                                data={ isMongo(item.reason)}
                            />
                        }
                        {!!item?.datePublished &&
                            <HalfInfoBlock
                            title={'Дата скасування'}
                            data={ dateTransform(item.datePublished, 'time') }
                            />
                        }
                        { ( !!item.documents && item.documents.length > 0 ) && 
                            <PopDownBlock title={`Документи (${item.documents.length})`} 
                                content={<div className={styles.docList}>
                                        { item.documents.map( i => <ViewDocItem 
                                                                    key={i.id}
                                                                    data={ i } 
                                                                    specs={{
                                                                        objId: data.id,
                                                                        docType: getExCancellationDocType(i.documentType)
                                                                    }}
                                                                /> )
                                        }
                                    </div>
                                }
                            />
                        }
                    </div> )
                }
        </div>
}

export default ExecusionCancellations