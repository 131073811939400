import React, {useState, useEffect} from 'react';
import styles from './infoBlockСollapser.module.css';

const InfoBlockСollapser =( props )=> {
    const [infoBlockMinimizer, setInfoBlockMinimizer] = useState(styles.infoBlock)
    const [infoBlockBtnTxt, setinfoBlockBtnTxt] = useState('Згорнути')

    function setUpСollapser() {
        if (props.collapsed) {
            setInfoBlockMinimizer(styles.infoBlockMin)
            setinfoBlockBtnTxt('Розгорнути')
        }
    }

    useEffect(() => {
        setUpСollapser()
      }, []
    )

    function infoBlockToggle() {
        if (infoBlockMinimizer === styles.infoBlock) {
            setInfoBlockMinimizer(styles.infoBlockMin)
            setinfoBlockBtnTxt('Розгорнути')
        } else {
            setInfoBlockMinimizer(styles.infoBlock)
            setinfoBlockBtnTxt('Згорнути')
        }
    }

    return (
        <div className={infoBlockMinimizer}>
                <div className={styles.infoBlockTitle}>
                    <h3>{props.blockTitle}</h3>
                    {infoBlockMinimizer === styles.infoBlock ? <p className={styles.desc}>{props.blockDescription}</p> : null}
                </div>
                <div 
                    className={styles.wrapBtn}
                    onClick={infoBlockToggle}
                >
                    {infoBlockBtnTxt}
                </div>
                {infoBlockMinimizer === styles.infoBlock ? props.content : null}
                
        </div>
    )
}

export default InfoBlockСollapser