import React, { useState, useEffect, useRef } from 'react';
import styles from './assetItem.module.css';
import {useSelector, useDispatch} from 'react-redux';
import { isMongo, checkProp, dictionaryGet, getTranslate, dateTransform  } from '../../../../../../../utils/check';
import FullInfoBlock from '../../../../../../elements/fullInfoBlock/fullInfoBlock';
import HalfInfoBlock from '../../../../../../elements/halfInfoBlock/halfInfoBlock';
import AssetItemPropsView from './itemPropsView';
import UniPopUp from '../../../../../../popups/uniPopUp/uniPopUp';
import { assetItemDelete } from '../../../../../../../redux/asset/assetActions';
import Loader from '../../../../../../loader/loader';

const AssetItemCreate =({ item, edit })=> {
    const assetRegistrationStatus = useSelector(state => state.start.assetRegistrationStatus)
    const assetItem = useSelector(state => state.asset.assetItem)
    const token = useSelector(state => state.start.token);
    const dispatch = useDispatch()
    const [deletePopUp, setDeletePopUp] = useState(false)
    const [loader, setLoader] = useState( false )

    useEffect( () => {
        if ( assetRegistrationStatus === null ) {
            dispatch( dictionaryGet('registrationStatus', 'ASSETT_GET_REGISTRATION_STATUS') );
        }
    }, []);


return (
        <div className={styles.wrapper}>
            { deletePopUp && 
                <UniPopUp
                    data={'Ви дійсно хочете видалити об\'єкт?'}
                    type={2}
                    action={ ()=> {
                        setLoader( true )
                        dispatch( assetItemDelete(assetItem.id, item.id, token.access_token ))}
                    }
                    close={ ()=> {
                        setDeletePopUp(false) 
                    }}
                />
            }
            { loader && <Loader /> }
            <div className={styles.buttonWrap}>
                <button className={styles.buttonEdit} 
                    onClick={ edit }>
                    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M15.861 5.59595L16.9757 4.4812L14.5187 2.02413L13.4039 3.13889L15.861 5.59595ZM14.4542 7.00273L11.9971 4.54566L2.80565 13.7371L2.24014 16.7597L5.26272 16.1942L14.4542 7.00273ZM18.4419 3.13384C19.186 3.87796 19.186 5.08443 18.4419 5.82856L6.45174 17.8187C6.30989 17.9606 6.12847 18.0562 5.93129 18.0931L1.17816 18.9824C0.490832 19.111 -0.111152 18.509 0.0174442 17.8217L0.90674 13.0686C0.943632 12.8714 1.03928 12.69 1.18113 12.5481L13.1713 0.557942C13.9154 -0.186184 15.1219 -0.186184 15.866 0.557942L18.4419 3.13384ZM18.0099 18.9999H9.9901C8.66997 18.9999 8.66997 16.9999 9.9901 16.9999H18.0099C19.33 16.9999 19.33 18.9999 18.0099 18.9999Z" fill="#92929D"></path>
                    </svg>
                </button>
                <button className={styles.buttonDelete} onClick={() => setDeletePopUp(true) }>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M16.6448 14.4563C17.5006 13.1827 18 11.6496 18 9.99985C18 5.58157 14.4183 1.99985 10 1.99985C8.34883 1.99985 6.8145 2.50008 5.54028 3.35726L16.6448 14.4563ZM15.3278 15.9677L4.0295 4.67496C2.76722 6.0893 2 7.95498 2 9.99985C2 14.4181 5.58172 17.9999 10 17.9999C12.0463 17.9999 13.9131 17.2316 15.3278 15.9677ZM10 -0.000152588C15.5228 -0.000152588 20 4.477 20 9.99985C20 15.5227 15.5228 19.9999 10 19.9999C4.47715 19.9999 0 15.5227 0 9.99985C0 4.477 4.47715 -0.000152588 10 -0.000152588Z" fill="#FFA047"></path>
                    </svg>
                </button>
            </div>
            {/* <div className={styles.title}>{ isMongo(item.title) }</div> */}
            <div style={{display: 'flex', flexFlow: 'row wrap'}}>
            {checkProp(item,'description') ? <FullInfoBlock title="Опис об'єкта" data={isMongo(item.description)} /> : null}
            <FullInfoBlock 
                title="Місцезнаходження" 
                data={<>
                        {item.address !== null ? isMongo(item.address.countryName) : ''}, 
                        {item.address !== null ? isMongo(item.address.region) : ''},
                        {item.address !== null ? isMongo(item.address.locality) : ''},
                        {item.address !== null ? isMongo(item.address.streetAddress) : ''}
                        <br/>
                        {item.location.latitude !== '0' && item.location.longitude !== '0' &&
                        item.location.latitude !== '' && item.location.longitude !== '' ? 
                        <>
                            Широта: {item.location.latitude.substring(0, 8)} / Довгота: {item.location.longitude.substring(0, 8)}
                        </> : null}
                    </>} 
            />
            { item.additionalClassifications && item.additionalClassifications.length > 0 ? 
                <div className={styles.halfBlockWrap}>
                    <HalfInfoBlock title="Основний класифікатор об`єкта" data={`
                        ${item.classification.scheme} ${item.classification.id}: ${isMongo(item.classification.description)}
                    `} />
                    <HalfInfoBlock title="Додаткові класифікатори об`єкта" data={<ul className={styles.additionalClassifications}>
                        {item.additionalClassifications.map( (item, count) => <li key={count}>
                        Код додаткового класифікатора: {item.id} - {isMongo(item.description)}
                        </li>)}
                    </ul>} />
                    
                </div> 
                : 
                <FullInfoBlock title="Основний класифікатор об`єкта" data={`
                    ${item.classification.scheme} ${item.classification.id}: ${isMongo(item.classification.description)}
                `} />
            }
                <HalfInfoBlock title="Кількість" 
                    data={`${ item.quantity } ${ isMongo(item.unit.name) }
                `} />
            { !!item?.registrationDetails && 
                <HalfInfoBlock 
                    title=' Стан державної реєстрації об`єкту'
                    data={`
                    ${ getTranslate(item.registrationDetails.registrationStatus, assetRegistrationStatus) }
                    ${ !!item.registrationDetails?.registrationID ? ' - №' + item.registrationDetails.registrationID : '' }
                    ${ !!item.registrationDetails?.registrationDate ? dateTransform(item.registrationDetails.registrationDate) : '' }
                    `}
                />
            }
            { !!item.itemProps && <AssetItemPropsView item={item} />}
            </div>
        </div>
    )
}

export default AssetItemCreate