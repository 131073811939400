import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './myActions.module.css';
import {useDispatch, useSelector} from 'react-redux';

import MyActionsTabItem from './myActionsTabItem';
import Loader from '../../../loader/loader';
import { urlGetMyActions } from '../../../../utils/endPoints';
import Paginator from '../../paginator/paginatorTest';

const MyActionsHeader = () => {

    return (
        <>
            <div className={styles.myActionsTable}>
                <div className={styles.tabHead}>
                    <div className={styles.thName}>Дія</div>
                    <div className={styles.thStat}>Тип</div>
                    <div className={styles.thStatus}>Статус</div>
                    <div className={styles.thRent}>Дата публікації</div>
                    <div className={styles.thAction}>Документи</div>
                    <div className={styles.thActions}>Дії</div>
                </div>
            </div>
        </>
    )
}

const MyActions = () => {

    const dispatch = useDispatch(); 
    const token = useSelector(state => state.start.token);
    const myActionsList = useSelector(state => state.start.myActionsList);

    const [ params, setParams ] = useState({
        limit: 10,
        offset: 0,
        order: 'DESC',
        // keyword: null
    })

    useEffect(() => {
        if (token !== null) {
            getActionsList( params, token.access_token, dispatch )
        }
    }, [ params ]);

    function regisrtryActions(list){
        let actionsList
        if (!!list ) {
            if (list.length > 0) {
                actionsList = list.map( item => <MyActionsTabItem 
                    key={ item.publishedId ? item.publishedId : item.draftId } 
                    data={ item.publishedId ? item.publishedObject : item.draftObject }
                    id={ item.publishedId ? item.publishedId : item.draftId }
                />)
            } else {
                actionsList = <div className={styles.tableEmpty}>У Вас немає жодної дії.</div>
            }
        } else {
            actionsList = <Loader />
        }
        return actionsList
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.titleWrapper}>
                <div className={styles.title}>Мої дії</div>
            </div>
            <div className={styles.tableContent}>
                <MyActionsHeader/>
                { regisrtryActions(myActionsList) }
                <Paginator params={ params } setParams={ setParams } list={ myActionsList } />
            </div>
        </div>
        )
}

export async function getActionsList( params, token, dispatch ){
    dispatch({ payload: true, type: 'SET_LOADER' })
    try {
        const data = await axios.get( urlGetMyActions, 
          { headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
            },
            params: params
          })
          dispatch({ payload: data.data, type: 'ACTIONS_GET_MY' })
    } catch (err) {
    }
    window.scrollTo(0, 0)
    dispatch({ payload: false, type: 'SET_LOADER' })
}

export default MyActions;