import React,{useState, useEffect} from 'react';
import style from './createBankAccount.module.css';

import { useSelector, useDispatch } from 'react-redux';

// import Input from '../../../elements/inputs/input';
import Input  from '../../../../../../inputs/input';
import InputSelect from '../../../../../../inputs/inputSelect';
import Button from '../../../../../../elements/buttons/button';
import { getBanksList } from '../../../../../../../redux/asset/bankActions';
import { isMongo } from '../../../../../../../utils/check';
import DeleteIcon from '../../../../../../elements/svg/deleteIcon';

const EditBankAccountPopup = ( {data, close, props} ) => {
    const token = useSelector(state => state.start.token);
    const dispatch = useDispatch()

    const [bankName, setBankName] = useState( !!data?.bankName ? data.bankName : '' )
    const [description, setDescription] = useState( !!data?.description ? data.description : '' )
    const [currency, setCurrency] = useState( !!data?.currency ? data.currency : '' )
    
    const [identifierScheme, setIdentifierScheme] = useState( !!data?.accountHolder?.identifier?.scheme ? data.accountHolder.identifier.scheme : '' )
    const [identifierLegalName, setIdentifierLegalName] = useState( !!data?.accountHolder?.identifier?.legalName ? isMongo(data.accountHolder.identifier.legalName) : '' )
    const [identifierId, setIdentifierId] = useState( !!data?.accountHolder?.identifier?.id ? data.accountHolder.identifier.id : '' )

    const [accountIdentificationsDescription, setAaccountIdentificationsDescription] = useState( !!data?.accountIdentifications[0]?.description ? data.accountIdentifications[0].description : '')
    const [accountIdentificationsScheme, setAccountIdentificationsScheme] = useState(!!data?.accountIdentifications[0]?.scheme ? data.accountIdentifications[0].scheme : '')
    const [accountIdentificationsId, setAccountIdentificationsId] = useState(!!data?.accountIdentifications[0]?.id ? data.accountIdentifications[0].id : '')

    let body = {
        bankName: bankName,
        description: description,
        accountHolder: {
            identifier: {
                scheme: identifierScheme,
                legalName: {uk_UA: identifierLegalName},
                id: identifierId
            }
        },
        accountIdentifications: [{
            description: accountIdentificationsDescription,
            scheme: accountIdentificationsScheme,
            id: accountIdentificationsId
        }],
        currency : currency
    }
    
    const namePopUp =( data )=> {
        if( !!data ){
            return 'Редагування банківського рахунку'
        }else{
            return 'Створення банківського рахунку'
        }
    }

    function regExpTest(){
        let pattern = ''
        if(accountIdentificationsScheme === 'UA-EDR'){
            pattern = /^[0-9]{8}$/
            if(accountIdentificationsId !== null && accountIdentificationsId.match(pattern) !== null && accountIdentificationsId.length === 8) return true
        }else if(accountIdentificationsScheme === 'UA-MFO'){
            pattern = /^[0-9]{6}$/
            if(accountIdentificationsId !== null && accountIdentificationsId.match(pattern) !== null && accountIdentificationsId.length === 6) return true
        }else if(accountIdentificationsScheme === 'UA-accountNumber'){
            pattern = /^[0-9]{5,}$/
            if(accountIdentificationsId !== null && accountIdentificationsId.match(pattern) !== null && accountIdentificationsId.length === 5) return true
        }else if(accountIdentificationsScheme === 'UA-IBAN'){
            pattern = /^[a-zA-Z]{2}[0-9]{27}$/
            if(accountIdentificationsId !== null && accountIdentificationsId.match(pattern) !== null && accountIdentificationsId.length === 29) return true
        }else if(accountIdentificationsScheme === 'FOR-IBAN'){
            pattern = /.+/
            if(accountIdentificationsId !== null && accountIdentificationsId.match(pattern) !== null) return true
        }

        if(identifierScheme === 'UA-EDR'){
            pattern = /^[0-9]{8}$/
            if(identifierId !== null && identifierId.match(pattern) !== null && identifierId.length === 8) return true
        }else if(identifierScheme === 'UA-IPN'){
            pattern = /^[0-9]{10}$/
            if(identifierId !== null && identifierId.match(pattern) !== null && identifierId.length === 10) return true
        }else if(identifierScheme === 'UA-PASSPORT'){
            pattern = /^[А-ЯA-Z]{2}[0-9]{6}$/
            if(identifierId !== null && identifierId.match(pattern) !== null && identifierId.length === 6) return true
        }else if(identifierScheme === 'UA-ID-CARD'){
            pattern = /^[0-9]{9}$/
            if(identifierId !== null && identifierId.match(pattern) !== null && identifierId.length === 9) return true
        }else if(identifierScheme === 'UA-IPN-FOP'){
            pattern = /^[0-9]{10}$/
            if(identifierId !== null && identifierId.match(pattern) !== null && identifierId.length === 10) return true
        }
    }

    function buttonSave(props){
        if(
            bankName !== null && bankName !== ''
            && description !== null && description !== ''
            && currency !== null && currency !== ''
            && identifierScheme !== null && identifierScheme !== ''
            && identifierLegalName !== null && identifierLegalName !== ''
            && identifierId !== null && identifierId !== ''
            && accountIdentificationsDescription !== null && accountIdentificationsDescription !== ''
            && accountIdentificationsScheme !== null && accountIdentificationsScheme !== ''
            && accountIdentificationsId !== null && accountIdentificationsId !== ''
            && regExpTest()
        ) {
            return (
                <Button
                    color='blue'
                    onClick={
                        () => {
                            regExpTest()
                            if( !!data ){
                                editBankAccount(data, body, token, dispatch)
                            } else {
                                createBankAccount(data, body, token, dispatch)
                            }
                            close()
                        }
                    }
                >
                    Зберегти
                </Button>
            )
        }
        return (
            <Button
                color='gray'
            >
                Зберегти
            </Button>
        )
    }

    return (
        <div className={style.popup_wrap}>
            <div className={style.popup_message}>
            <div className={style.closeBtn}
                onClick={ close }
            >
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path className={style.closeSVG} fillRule="evenodd" clipRule="evenodd" d="M8.41421 6.99989L13.364 11.9496C13.7545 12.3402 13.7545 12.9733 13.364 13.3639C12.9734 13.7544 12.3403 13.7544 11.9497 13.3639L7 8.41411L2.05025 13.3639C1.65973 13.7544 1.02656 13.7544 0.636039 13.3639C0.245514 12.9733 0.245515 12.3402 0.636039 11.9496L5.58579 6.99989L0.636039 2.05015C0.245515 1.65962 0.245514 1.02646 0.636039 0.635934C1.02656 0.245409 1.65973 0.245409 2.05025 0.635934L7 5.58568L11.9497 0.635934C12.3403 0.245409 12.9734 0.245409 13.364 0.635934C13.7545 1.02646 13.7545 1.65962 13.364 2.05015L8.41421 6.99989Z" fill="#92929D"/>
                </svg>
            </div>
            <div className={style.content}>
                    <div className={style.popupTitle}>{ namePopUp( data ) }</div>
                    <Input 
                        value={bankName}
                        label="Назва банку"
                        onChange={ e => setBankName(e) }
                    />
                    <Input
                        value={description}
                        label="Опис"
                        onChange={ e => setDescription(e) }
                        // descr='ЄДРПОУ отримувача'
                    />
                    <InputSelect 
                        value={currency}
                        label='Валюта'
                        onChange={ e => setCurrency(e) }
                        options={[
                            // {value: null, name: 'Обрати', disabled: true},
                            {value: "UAH", name: 'Гривня'},
                            /*{value: "USD", name: 'Долар США'},
                            {value: "EUR", name: 'Євро'}*/
                        ]}
                    />

                    <div className={style.popupSubTitle}>Реквізити одержувача</div>
                    <InputSelect 
                        value={ identifierScheme }
                        label='Ідентифікатор'
                        onChange={ e => setIdentifierScheme(e) }
                        options={bankAccountSchemeHolder()}
                    />
                    <Input
                        value={ identifierLegalName }
                        label="Повна юридична назва організації"
                        onChange={ e => setIdentifierLegalName(e) }
                    />
                    <Input
                        value={ identifierId }
                        label={'Код ЄДРПОУ або ІПН або паспорт'}
                        onChange={ e => setIdentifierId(e)}
                    />

                    <div className={style.popupSubTitle}>Банківські реквізити</div>
                    <Input
                        value={ accountIdentificationsDescription }
                        label="Опис до банківських реквизитів"
                        onChange={ e => setAaccountIdentificationsDescription(e) }
                    />
                    <InputSelect 
                        value={ accountIdentificationsScheme }
                        label='Тип банківського ідентифікатора'
                        onChange={ e => setAccountIdentificationsScheme(e) }
                        options={ bankAccountScheme() }
                    />
                    <Input
                        value={ accountIdentificationsId }
                        label={'Ідентифікатор'}
                        onChange={ e => setAccountIdentificationsId(e)} // UA22666666555550000012345678901234
                    />
                    <div className={ style.bottomPart }>
                        <div style={{ marginRight: 'auto' }}>
                        { !!data && 
                            <Button 
                                height='37px'
                                color='gray'
                                onClick={ ()=> {
                                    deleteBankAccount(data, token, dispatch)
                                    close()
                                }}
                            >
                                <DeleteIcon widthHeight={['18px','18px']}/>
                            </Button>
                        }
                        </div>
                        <Button
                            color='gray'
                            onClick={ close }
                        >
                            Закрити
                        </Button>
                        { buttonSave(props) }
                    </div>
                </div>
            </div>
        </div>
    )
}

function bankAccountScheme(){
    return [
        {
            value: "UA-EDR",
            name: "ЄДРПОУ",
            pattern: "^[0-9]{8}$"
        },
        {
            value: "UA-MFO",
            name: "МФО банку",
            pattern: "^[0-9]{6}$"
        },
        {
            value: "UA-accountNumber",
            name: "Банківський рахунок",
            pattern: "^[0-9]{5,}$"
        },
        {
            value: "UA-IBAN",
            name: "Міжнародний номер банківського рахунку IBAN",
            pattern: "^[a-zA-Z]{2}[0-9]{27}$"
        },
        {
            value: "FOR-IBAN",
            name: "Міжнародний номер закордонного банківського рахунку IBAN",
            pattern: ".+"
        }
    ]
}

function bankAccountSchemeHolder(){
    return [
        {
            value: "UA-EDR",
            name: "ЄДРПОУ",
            pattern: "^[0-9]{8}$"
        },
        {
            value: "UA-IPN",
            name: "ІПН/РНОКПП",
            pattern: "^[0-9]{10}$"
        },
        {
            value: "UA-PASSPORT",
            name: "Паспорт",
            pattern: "^[А-ЯA-Z]{2}[0-9]{6}$"
        },
        {
            value: "UA-ID-CARD",
            name: "ID-карта",
            pattern: "^[0-9]{9}$"
        },
        {
            value: "UA-IPN-FOP",
            name: "ІПН/РНОКПП (ФОП)",
            pattern: "^[0-9]{10}$"
        }
    ]
}

async function createBankAccount( data, dataBank, token, dispatch){
    //const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/bankAccount`;
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/userProfile/bankAccount`;
    //let tt = JSON.parse(props.bankAccountShemeId)
    const response = await fetch(endPoint, {
        method: "POST",
        body: JSON.stringify(dataBank),
        headers: {
            'Authorization': token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if( typeof response === 'undefined' || response === 'undefined'){
        dispatch({type: 'UNIVERSAL_ERROR', payload: 'Щось пішло не так, спробуйте пізніше' })
    } else {
        const json1 = await response.json()
        if(json1.hasOwnProperty('message') === true){
            dispatch({type: 'UNIVERSAL_ERROR', payload: json1.message})
            // await props.setPopBankAccountError(json1)
            // props.setPopBankAccountNazvaBanku('')
        }
        if(json1.hasOwnProperty('id') === true){
            dispatch( getBanksList(`${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/userProfile/bankAccounts`, token.access_token) )
            // await props.setPopBankAccountComplite(true)
            // props.setPopBankAccountNazvaBanku('')
        }
    }
}

async function editBankAccount(data, dataBank, token, dispatch){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/userProfile/bankAccount/${data.id}`;
    const response = await fetch(endPoint, {
        method: "PUT",
        body: JSON.stringify(dataBank),
        headers: {
            'Authorization': token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if( typeof response === 'undefined' || response === 'undefined'){
        dispatch({type: 'UNIVERSAL_ERROR', payload: 'Щось пішло не так, спробуйте пізніше' })
    }else{
        const json1 = await response.json()
        if(json1.hasOwnProperty('message') === true){
            dispatch({type: 'UNIVERSAL_ERROR', payload: json1.message})
        }
        if(json1.hasOwnProperty('id') === true){
            dispatch( getBanksList(`${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/userProfile/bankAccounts`, token.access_token) )
        }
    }
}

async function deleteBankAccount(data, token, dispatch){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/userProfile/bankAccount/${data.id}`;
    //let tt = JSON.parse(props.bankAccountShemeId)
    const response = await fetch(endPoint, {
        method: "DELETE",
        headers: {
            'Authorization': token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if( typeof response === 'undefined' || response === 'undefined'){
        dispatch({type: 'UNIVERSAL_ERROR', payload: 'Щось пішло не так, спробуйте пізніше' })
    }else{
        const json1 = await response.json()
        if(json1.hasOwnProperty('message') === true){
            dispatch({type: 'UNIVERSAL_ERROR', payload: json1.message})
        }
        if(json1.hasOwnProperty('id') === true){
            dispatch( getBanksList(`${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/userProfile/bankAccounts`, token.access_token) )
        }
    }
}

export default EditBankAccountPopup