import React, { useState, useEffect } from 'react'
import styles from './contractItemEdit.module.css'
// import { Link, Navigate, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
// import UniPopUp from '../../../../../../../popups/uniPopUp/uniPopUp'
import { isDraftObject, isMongo } from '../../../../../../../../utils/check'
import Input from '../../../../../../../inputs/input'
import InputTextArea from '../../../../../../../inputs/inputTextArea'
import InputSelect from '../../../../../../../inputs/inputSelect'
import InputAmount from '../../../../../../../inputs/inputAmount'
import InputDate from '../../../../../../../inputs/inputDate'
import Button from '../../../../../../../elements/buttons/button'
import { urlPatchLeaseContractDrafts, urlPostDocLeaseContractDrafts, urlPostDocLeaseContract, 
    urlPatchDocLeaseContractDrafts, urlPatchDocLeaseContract, urlPatchLeaseContract, urlPatchStatusLeaseContract } from '../../../../../../../../utils/endPoints'
import { patchLeaseContract } from '../../../../../../../../utils/contracts/contractsUtils'
import CreateDocItem from '../../../../../../../elements/documents/docItem/documentItem'
import AddDocPopUp from '../../../../../../../elements/documents/addDocPopup/addDocPopup'
import Buyers from './buyers/buyers'
import { axiosDeleteAPI } from '../../../../../../../../redux/actions/crud'
import ValidateSendLeaseContract from '../elems/validateSendContract'


const LeaseContractItemEdit = ({ data, setEditContract, close }) => {

    const token = useSelector( state => state.start.token );
    const objectData = useSelector(state => state.start.objectGet);
    const dispatch = useDispatch()
    // const navigate = useNavigate()
    const [ popAddDoc, setPopAddDoc ] = useState( false )
    // const [deletePopUp, setDeletePopUp] = useState(false)
    const [ contractNumber, setContractNumber ] = useState( !!data?.contractNumber ? data.contractNumber : '' )
    const [ title, setTitle ] = useState( !!data?.title ? isMongo(data.title) : '' )
    const [ description, setDescription ] = useState( !!data?.description ? isMongo(data.description) : '' )
    const [ contractMethod, setContractMethod ] = useState( !!data?.contractMethod ? data.contractMethod : '' )
    const [ value, setValue ] = useState( !!data?.value ? data.value : { currency: 'UAH', amount: '', valueAddedTaxIncluded: true } )
    const [ dateSigned, setDateSigned ] = useState( !!data?.dateSigned ? data.dateSigned : null )
    const [ contractTime, setContractTime ] = useState( !!data?.contractTime ? data.contractTime : { dateFrom: null, dateTill: null } )
    const [ status, setStatus ] = useState( !!data?.status ? data.status : 'pending' )
    const [ buyers, setBuyers ] = useState( !!data?.buyers ? data.buyers : null )

    useEffect(() => {
        if ( !!buyers && isDraftObject( data.id ) ) patchLeaseContract( apiData( { buyers: buyers } ) )
    }, [ buyers ])

    const body = {
        contractNumber: contractNumber,
        contractMethod: contractMethod,
        status: status,
        dateSigned: dateSigned,
        contractTime: contractTime,
        title: { uk_UA: title },
        description: { uk_UA: description },
        value: value,
        // buyers: buyers
    }

    const apiData = ( resBody ) => {
        return {
            url: isDraftObject( data.id ) ? urlPatchLeaseContractDrafts( objectData.id, data.id ) : urlPatchStatusLeaseContract(objectData.id, data.id, status),
            dispatch: dispatch,
            set: ( data )=> setEditContract( prev => ({...prev, data: data })),
            body: isDraftObject( data.id ) ? resBody : {},
            // redirect: ()=> navigate(`/lease/create/${objectData.id}/contracts`),
            token: token.access_token,
        }
    }

    function contractUpdate( data ){
        // isDraftObject( data.id ) ?
        patchLeaseContract( apiData( body ) )
    }

    const contractStatusList = [
        { value: "inactive", name: "Недіючий" },
        { value: "cancelled",name: "Розірвано" },
        { value: "active", name: "Діючий" }
    ]
    if(data.status !== 'active' && data.status !== 'inactive' && data.status !== 'cancelled'){

        contractStatusList[contractStatusList.length] = { value: "pending", name: "Очікує публікації" }
    }


    const contractMethodList = [
        { value: "signedBeforeETS", name: "Публікація договорів оренди укладених до введення в дію переліків в ЕТС" },
        { value: "listTypeSecond", name: "Передача в оренду з переліку другого типу" },
        { value: "extension", name: "Продовження договору" },
        { value: "auction", name: "Передача в оренду через аукціон" }
    ]

    const docProps = { 
        dispatch: ( res )=> setEditContract( prev => ({...prev, data: res })),
        // dispatch: ( res )=> dispatch( { payload: res, type: 'CONTRACT_GET_MY'}),
        docOf: 'object',
        object: data,
        docList: [
            {name: 'Повідомлення про договір', value: 'contractNotice'},
            {name: 'Підписаний договір', value: 'contractSigned'},
            {name: 'Додаток до договору', value: 'contractAnnexe'},
        ],
        url: {
            patch: isDraftObject( data.id ) ? 
                    ( cont, doc ) => urlPatchDocLeaseContractDrafts( objectData.id, cont, doc ) :
                    ( cont, doc ) => urlPatchDocLeaseContract( objectData.id, cont, doc ),

            post: ( ) => { 
                if( isDraftObject( data.id ) ) return urlPostDocLeaseContractDrafts( objectData.id, data.id )
                else return urlPostDocLeaseContract( objectData.id, data.id )
            },
            delete: ( id )=> {
                axiosDeleteAPI({
                    url: isDraftObject( data.id ) ? urlPatchDocLeaseContractDrafts( objectData.id, data.id, id ) : urlPatchDocLeaseContract( objectData.id, data.id, id ),
                    dispatch: dispatch,
                    set: ( data )=> setEditContract( prev => ({...prev, data: data })),
                    // body: resBody,
                    token: token.access_token,
                })
            }
        }
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.title}>{ `Редагування договору: ${ data.contractNumber }` }</div>
            <Input 
                label="Назва"
                value={ title }
                onChange={ event => setTitle( event ) }
                reqiured
                disabled={ isDraftObject( data.id ) ? false : true }
            />
            <InputTextArea
                label="Опис"
                value={ description }
                onChange={ event => setDescription( event ) }
                disabled={ isDraftObject( data.id ) ? false : true }
                reqiured
            />
            <Input 
                label="Номер договору"
                value={ contractNumber }
                onChange={ event => setContractNumber( event ) }
                disabled={ isDraftObject( data.id ) ? false : true }
                reqiured
            />
            <InputSelect 
                label={"Метод укладання договору"}
                value={ contractMethod }
                onChange={ event => setContractMethod(event) }
                disabled={ isDraftObject( data.id ) ? false : true }
                options={ contractMethodList }
            />
            <InputSelect 
                label={"Статус договору"}
                value={ status }
                onChange={ event => setStatus(event) }
                // disabled={ isDraftObject( data.id ) ? false : true }
                options={ contractStatusList }
            />
            <InputAmount 
                title='Ставка оренди відповідно до договору'
                value={ value }
                onChange={ setValue }
                tax={ true }
                disabled={ isDraftObject( data.id ) ? false : true }
                required
                // enableCurrent
            />
            <InputDate
                data={{
                    label: "Дата підписання договору",
                    value: dateSigned,
                    onChange: setDateSigned ,
                    reqiured: true,
                    disabled: isDraftObject( data.id ) ? false : true 
                }}
            />
            <div><h4>Строк дії договору *</h4></div>
            <div className={styles.twoBlock}>
                <InputDate
                    data={{
                        label: "Період з",
                        value: contractTime.dateFrom,
                        disabled: isDraftObject( data.id ) ? false : true,
                        onChange: (e)=> setContractTime( prev => ({...prev, dateFrom: e })),
                        reqiured: true
                    }}
                />
                <InputDate
                    data={{
                        label: "Період по",
                        value: contractTime.dateTill,
                        disabled: isDraftObject( data.id ) ? false : true,
                        onChange: (e)=> setContractTime( prev => ({...prev, dateTill: e })),
                        reqiured: true
                    }}
                />
            </div>

            <Buyers 
                data={ buyers } 
                update={ ( data )=> patchLeaseContract( apiData( data ) )}
                setBuyers={ setBuyers }
                isDraftObject={isDraftObject( data.id )}
            />
            <div className={styles.infoBlock}>
                <div className={styles.infoBlockTitle}>Документи</div>
                    <div className={styles.itemsListContent}>
                        { !!data?.documents && data.documents.map( i => <CreateDocItem data={i} docProps={docProps} /> )}
                    </div>
                    <div className={styles.buttonsBlock}>
                        <div className={styles.postButtonsWrap}>
                    
                            <Button
                                onClick={ () => setPopAddDoc(true) }
                            >
                                Додати документ
                            </Button>
                        </div>
                    </div>
            </div>


            <div className={styles.postButtonsWrap}>
                <Button onClick={ ()=> {
                                    contractUpdate( data )
                                    // reload()
                                    close()
                        }
                    }
                >Зберегти зміни</Button>
                <Button color='gray' 
                    onClick={ () => close() }
                >
                    Відмінити
                </Button>
            </div>
            {/* <div className={styles.postButtonsWrap}>
                <ValidateSendLeaseContract data={data} setEditContract={setEditContract} />
            </div> */}
            {
                popAddDoc && <AddDocPopUp docProps={ docProps } close={ ()=> setPopAddDoc(false) } />
            }
        </div>
    )
}

export default LeaseContractItemEdit