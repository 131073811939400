import React from 'react';
import styles from '../generalInfoBlock.module.css';

import FullInfoBlock from '../../fullInfoBlock/fullInfoBlock';
import ContactPoint from '../../../../../elements/contactPoint/contactPoint';
import { checkProp, isMongo, getUrAdress, getIdType } from '../../../../../../utils/check';

const ManageOrganizations =({ data, title })=> {

    if (!data || typeof data === 'undefined') return null


    return data.map(
        (e) => {
            return (
                <>
                    <h3 className={styles.infoBlockTitle}>{title}</h3>
                    <div className={styles.infoBlockInner}>
                        <div className={styles.infoBlockInnerTitle}>
                            <h3>{checkProp(e,'name') ? isMongo(e.name.uk_UA) : null}</h3>
                        </div>
                        {checkProp(e, 'identifier') && checkProp(e.identifier, 'scheme') && checkProp(e.identifier, 'id')
                            ? <FullInfoBlock
                                title={getIdType(e.identifier.scheme)}
                                data={e.identifier.id}
                            /> : null
                        }
                        {checkProp(e, 'address') && checkProp(e.address, 'addressID')
                        ? <FullInfoBlock
                                title='КОАТУУ'
                                data={e.address.addressID.id}
                            /> : null
                        }
                        {checkProp(e, 'address')
                        ? <FullInfoBlock
                                title='Юридична адреса'
                                data={getUrAdress(e)}
                            /> : null
                        }
                        {checkProp(e, 'contactPoint')
                            && 
                        <ContactPoint data={e.contactPoint} /> 
                        }
                    </div>
                </>
            )
        }
    )
}

export default ManageOrganizations