import React from 'react';
import styles from './elems.module.css';
import FullInfoBlock from '../../../../elements/fullInfoBlock/fullInfoBlock';
import { dateTransform, isMongo } from '../../../../../utils/check';
import ViewDocItem from '../../../../elements/documents/view/viewDocumentItem';

const AssetCancellation = ( { data } ) => {
    
    return <div className={styles.infoBlock}>
                <div className={styles.infoBlockTitle}>
                    <h3>Виключення Об'єкта з реєстру</h3>
                </div>
                { !!data?.reason && 
                    <FullInfoBlock
                        title='Причина виключення з реєстру'
                        data={ isMongo(data.reason) }
                    />
                }
                { !!data.datePublished &&
                <FullInfoBlock
                    title='Дата виключення з реєстру'
                    data={ dateTransform(data.datePublished, 'time') }
                />
                }
                {
                    data.documents.length > 0 
                ?
                    data.documents.map( doc => <ViewDocItem data={doc} key={doc.id} 
                        specs={{
                            objId: data.id,
                            get docType(){
                                const docTypeList = [{ value: 'cancellationDetails', name: `Рішення про виключення об'єкта з реєстру`, main: false }]
                                for (let key in docTypeList) {
                                    if ( docTypeList[key].value === doc.documentType ) return docTypeList[key].name
                                }
                            }
                        }}/> )
                : 'Документів не знайдено'
                }
            </div>
}

export default AssetCancellation