import React from 'react';
import styles from './elems.module.css';
import { dateTransform, isMongo } from '../../../../../utils/check';

const AssetDisqualifiedBids = ( { data } ) => {

    return (
                <div className={styles.infoBlockContent}>
                    { data.map( item => <div key={item}>{ item }</div> ) }
                </div>
        )
}

export default AssetDisqualifiedBids