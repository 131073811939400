import React from 'react';
import { isMongo } from '../../../../../utils/check';
import styles from './fullInfoBlock.module.css';

const FullInfoBlock =( {title, data, moreData} )=> {
    if (typeof data === 'undefined') return null

    return (
                <div className={styles.blockFull}>
                    <div  className={styles.description}>{title}</div>
                    <div className={styles.data}>{isMongo(data)}</div>
                    <div className={styles.data}>{moreData}</div>
                </div>
    )
}

export default FullInfoBlock