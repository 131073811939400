import React from 'react';
import styles from './header.module.css';
import {useSelector, useDispatch} from 'react-redux';

import Logo from './logo/logo.js';
import Faq from './faq/faq.js';
import News from './news/news.js';
import Contacts from './contacts/contacts.js';
import Login from './login/login.js';

// import {userProfileGet} from '../../redux/actions/oldSiteUsers/oldSiteUsers.js';

const Header = () => {

    // const dispatch = useDispatch();
    // const token = useSelector(state => state.start.token);
    // const langFlag = useSelector(state => state.start.langFlag);
    // const userProfiles = useSelector(state => state.start.userProfiles);

    return (
        <>
            <div className={styles.wrap} 
                // onClick={ () => { 
                //     if(token !== null && userProfiles === null) {
                //         let data = {
                //             domen: process.env.REACT_APP_END_POINT_BD,
                //             version: 'v1',
                //             local: langFlag,
                //         };
                //         dispatch(userProfileGet(data, token.access_token));
                //     }
                        
                // }}
            >
                <div className={styles.content}>
                    <Logo />
                    <Faq />
                    <News />
                    <Contacts />
                    <Login />
                </div>
            </div>
        </>
    )
}

export default Header;