import React from "react"

const AddlIcon =( { color = '#92929D', style, widthHeight = [28, 28] })=> {
    return <svg style={{display:'flex'}}
                width={widthHeight[0]} 
                height={widthHeight[1]} 
                viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path 
                    className={style}
                    fillRule="evenodd" clipRule="evenodd" d="M9 1C9 0.447715 8.55229 0 8 0C7.44772 0 7 0.447715 7 1V7H1C0.447715 7 0 7.44772 0 8C0 8.55229 0.447715 9 1 9H7V15C7 15.5523 7.44771 16 8 16C8.55228 16 9 15.5523 9 15V9H15C15.5523 9 16 8.55229 16 8C16 7.44772 15.5523 7 15 7H9V1Z" 
                    fill={color}
                />
            </svg>
            
    
}

export default AddlIcon