import React, { useState } from 'react';
import styles from './createTitleBar.module.css';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getShorter, isDraftObject, isMongo } from '../../../../../../utils/check';
import Button from '../../../../../elements/buttons/button';
import DeleteIcon from '../../../../../elements/svg/deleteIcon';
import { getMyAssetDraft, setMyAssetStatus } from '../../../../../../utils/endPoints';
import { assetDelete, assetStatusUpdate } from '../../../../../../redux/asset/assetActions';
import UniPopUp from '../../../../../popups/uniPopUp/uniPopUp';
import SendAssetToProzoro from '../validation/sendToProzoro';
import CanceletionPopup from '../cancelationPopUp/canceletionPopup';

const AssetCreateTitle =({ data })=>  {
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const dispatch = useDispatch()
    const token = useSelector(state => state.start.token);
    const path = pathname.split('/')[4]
    const [delPopup, setDelPopup] = useState( false )
    const [cancelPopup, setCancelPopup] = useState( false )
    const [popup, setPopup] = useState( { type: '', action: '', text: '' } )

    function getLastPath(path){
        switch(path){
            case 'items': return 'Об`єкти'
            case 'documents': return 'Документи'
            default: return 'Загальна інформація'
        }
    }

    const itemsPad = ` Об\'єкти ${ !!data?.assetItems && data.assetItems.length > 0 ? `( ${ data.assetItems.length } )` : ''}`
    const docsPad = ` Документи ${ !!data?.documents && data.documents.length > 0 ? `( ${ data.documents.length } )` : ''}`

    function setBookmarkStyle(loc){
        if(!data) return styles.inactiveObjNavItem
        if( path === loc) return styles.activeNavItem
        return styles.objNavItem
    }

    function createPad(loc, content, onclick){
       return <div className={ setBookmarkStyle(loc) } onClick={ setBookmarkStyle(loc) === styles.objNavItem ? onclick : undefined } >
                { content }
            </div>
    }

    function deleteDraftObject( data ){
        if( !!data && isDraftObject( data.id ) ) {
            return (
                <Button 
                    height={'50px'} 
                    color='orange' 
                    onClick={ ()=> setDelPopup( true ) }
                    hover={ '#FFA047' }
                    title='Видалити об`єкт'
                >
                    <DeleteIcon /> 
                </Button>
            )
        }
    }

    function removeObject(data){
        if (!!data && data.status === 'pending') {
            return (
                <Button 
                    height={'50px'} 
                    color='orange' 
                    onClick={()=> {
                        setCancelPopup( true )
                        // let docDelete = false
                        // data.documents.forEach( doc => { if ( doc.documentType === 'cancellationDetails' ) docDelete = true });
                        // if ( docDelete ) {
                        //     setPopup(
                        //         {type: 'remove',
                        //          text: 'Ви дійсно хочете вилучити об\'єкт?',
                        //         action: ()=> {
                        //             dispatch( assetStatusUpdate( setMyAssetStatus(data.id, 'deleted'), token.access_token))
                        //             navigate('/asset/my_assets')
                        //             setPopup({ type: null })
                        //         }}
                        //     )
                        // } else {
                        //     setPopup({   type: 'ask', 
                        //             text: `Для вилучення об'єкту необхідно перейти на вкладку "Документи" та додати документ "Рішення про виключення об'єкту з переліку"`,
                        //             action: '' }
                        //     )
                        // }
                    }}
                    // hover={ '#FFA047' }
                    title='Виключити об’єкт з переліку'
                >
                    Виключити об’єкт з переліку 
                </Button>
            )
        }
    }

    function archivateObject(data){
        if (!!data && data.status === 'pending') {
            return (
                <Button 
                    height={'50px'} 
                    color='gray' 
                    onClick={ ()=> setPopup( {
                        type: 'archive',
                        text: 'Ви дійсно хочете архівувати об\'єкт?',
                        action: ()=> {
                            dispatch( assetStatusUpdate( setMyAssetStatus(data.id, 'archive'), token.access_token))
                            setPopup({ type: null })
                        }
                    } )}
                    // hover={ '#FFA047' }
                    title='Архівувати Об’єкт'
                >
                    Архівувати Об’єкт
                </Button>
            )
        }
    }

    return (
        <div className={styles.navBlock}>
            { cancelPopup && <CanceletionPopup data={ data.cancellations[0] } close={ ()=> setCancelPopup( false ) } object={ data } /> }
            { delPopup && <UniPopUp 
                                type={2}
                                data={'Ви дійсно хочете видалити чернетку?'} 
                                close={ ()=> setDelPopup( false ) }
                                action={ ()=> {
                                        assetDelete( getMyAssetDraft( data.id ), token.access_token, ()=> navigate('/asset/my_assets') )
                                        setDelPopup( false )
                                    }
                                }
                            /> 
            }
            { popup.type && <UniPopUp 
                                type={ popup.type === 'ask' ? 1 : 2 }
                                data={ popup.text } 
                                close={ ()=> setPopup( prev => ( { ...prev, type: '' } ) ) }
                                action={ ()=> popup.action() }
                            /> 
            }
            <div className={styles.titleBlock}>

                <div className={styles.pathString}>
                    <ul className={styles.navUlList}>
                        <li key={1}>
                            <Link to={`/asset/my_assets`}>
                                    Мої об’єкти малої приватизації
                            </Link>
                        </li>
                        <li key={2} className={styles.liTitle}>{ !!data?.title ? getShorter( isMongo(data.title) , 80) : 'Новий об\'єкт'}</li>
                        <li key={3}>{ getLastPath(path) }</li>
                    </ul>
                </div>

                <div className={styles.titleAndSend}>
                    <div className={styles.objTitle}>
                        { !!data?.title ? isMongo(data.title) : 'Створення нового об`єкту' }
                    </div>
                    <div className={styles.sendButton}>
                        <SendAssetToProzoro data={ data } />
                        { deleteDraftObject( data ) }
                        { removeObject( data ) }
                        { archivateObject(data) }
                    </div>
                </div>
               
            </div>

            <div className={styles.objNav}>
                <div className={ !path ? styles.activeNavItem : styles.objNavItem } onClick={ ()=> navigate('') }>
                    Загальна інформація
                </div>
                { createPad( 'items', itemsPad, ()=> navigate('items') ) }
                { createPad( 'documents', docsPad, ()=> navigate('documents') ) }
            </div>

        </div>
    )
}

export default AssetCreateTitle