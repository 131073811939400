import React, { useEffect, useState } from 'react';
import styles from './createActionPopUp.module.css';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import InputSelect from '../../../inputs/inputSelect';
import InputTextArea from '../../../inputs/inputTextArea';
import { urlPostMyDraftAction, getDictionaryByType } from '../../../../utils/endPoints';
// import { differenceInBusinessDays } from 'date-fns';
// import { postLeaseReqest } from '../../../../../../utils/requests/requestsUtils';
import { postLeaseAction, getLeaseActionType, getLeaseActionEntityRole } from '../../../../utils/actions/actionsUtils';

const CreateActionPopup = ( { data, obj, close } ) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const token = useSelector(state => state.start.token)
    const userProfiles = useSelector(state => state.start.userProfiles);

    const [actionType, setActionType] = useState( !!data?.type ? data.type : null );
    const [description, setDescription] = useState( !!data?.description ? data.description : '');
    const [actingEntityRole, setActingEntityRole] = useState( !!data?.actingEntityRole ? data.actingEntityRole : null );

    function createLeaseAction() {
        if ( !!token ) {
            // debugger
            const body = {
                type: actionType,
                // description: { uk_UA: description },
                actingEntityRole: actingEntityRole,
                description: description, //TODO: ЗРОБИТИ ДОДАВАННЯ ПОВʼЯЗАНИХ СУТНОСТЕЙ ЛОГІЧНИМ
                get relatedEntity(){
                    if ( !!obj?.leaseActionId ) return [{ id: obj.id, entity_type: 'action' }]
                    if ( !!obj?.registryObjectId ) return [{ id: obj.id, entity_type: 'registry' }]
                    if ( !!obj?.leaseRequestId ) {
                        let tmp = []
                        tmp = [...tmp, { id: obj.id, entity_type: 'lease_request' }]
                        tmp = [...tmp, { id: obj.relatedObjectId, entity_type: 'registry' }]
                        return tmp
                    }
                },
                get actingEntity(){
                    if(!!userProfiles?.organization){
                        let reqTmp = {
                            name: { uk_UA: userProfiles.organization.name },
                            identifier: {
                                scheme: userProfiles.organization.identifier.scheme,
                                id: userProfiles.organization.identifier.identifier,
                                legalName: { uk_UA: userProfiles.organization.identifier.legalName }
                            },
                            address: {
                                streetAddress: { uk_UA: userProfiles.organization.address.streetAddress },
                                locality: { uk_UA: userProfiles.organization.address.locality },
                                region: { uk_UA: userProfiles.organization.address.region },
                                countryName: { uk_UA: userProfiles.organization.address.countryName },
                                postalCode:  userProfiles.organization.address.postalCode
                            },
                            contactPoint: {
                                name: { uk_UA: userProfiles.organization.contactPoint.name },
                                email: userProfiles.organization.contactPoint.email,
                                telephone: userProfiles.organization.contactPoint.telephone,
                                faxNumber: userProfiles.organization.contactPoint.faxNumber,
                                url: userProfiles.organization.contactPoint.url === null || userProfiles.organization.contactPoint.url === '' ? null : userProfiles.organization.contactPoint.url,
                            },
                            representativeInfo: userProfiles.organization.representativeInfo
                        }
                        return reqTmp
                    }
                }
            };
            const reqData = {
                url: urlPostMyDraftAction,
                body: body,
                token: token.access_token,
                redirect: () => navigate('/lease/my_actions'),
                dispatch: dispatch
            }
            postLeaseAction( reqData )
            close()
        } else {
            alert('Авторизуйтесь');
        }
    }

    return (
        <div className={styles.backdrop}>
            <div className={styles.wrapper}>
                <button 
                    className={styles.buttonClose} 
                    onClick={ close }>
                </button>
                <>
                    <div className={styles.title}>Створення дії</div>
                    {/* <div className={styles.subtitle}>До об’єкту {obj.id}</div> */}
                </>
                <InputSelect 
                    value={actionType}
                    label="Тип" 
                    onChange={ setActionType }
                    options={ getLeaseActionType('dict') }
                    reqiured
                />
                <InputTextArea
                    value={description}
                    onChange={ e => setDescription(e) }
                    label="Опис"
                />
                <InputSelect 
                    value={actingEntityRole}
                    label="Роль організації, що виконує дію" 
                    onChange={ setActingEntityRole }
                    options={ getLeaseActionEntityRole('dict') }
                    reqiured
                />
                {/* <PopDownBlock 
                    title={'Документи (' + "0" + ')'}
                    content={
                        <DocumentsLeaseRequests type={'leaseRequest'} />
                    } 
                /> */}
                <div className={styles.buttonWrapper}>
                    <button className={styles.buttonCancel} onClick={ close }>Скасувати</button>
                    <button className={styles.buttonDelete} onClick={() => createLeaseAction() }>Зберегти чернетку</button>
                </div>
            </div>
        </div>
    )
}

export default CreateActionPopup;