import { ASSET_GET_ID, 
        ASSETS_GET_MY,
        CLASSIFICATION_CAV,
        ANNOUNCEMENT_GET_ID,
        GET_BANK_ACCOUNTS_LIST,
        ANNOUNCEMENTS_GET_MY,
        EXECUTION_GET_ID,
        EXECUTIONS_GET_MY
    // ASSET_CREATE 
} from './types.js'

const initialState = {
    //Asset
   assetItem: null,
   assetsMy: null,
   classificationCAV: null,
   announcementItem: null,
   announcementsMy: null,
   bankAccountsList: null,
   executionItem: null,
   myExecutions: null
   
}

export const assetReducer = ( state = initialState, action ) => {
    switch (action.type) {
        case ASSET_GET_ID:
            return { ...state, assetItem: action.payload }
        case ASSETS_GET_MY:
            return { ...state, assetsMy: action.payload }
        case CLASSIFICATION_CAV:
            return { ...state, classificationCAV: action.payload }
        case ANNOUNCEMENT_GET_ID:
            return { ...state, announcementItem: action.payload }
        case GET_BANK_ACCOUNTS_LIST:
            return { ...state, bankAccountsList: action.payload }
        case ANNOUNCEMENTS_GET_MY:
            return { ...state, announcementsMy: action.payload }
        case EXECUTION_GET_ID:
            return { ...state, executionItem: action.payload }
        case EXECUTIONS_GET_MY:
            return { ...state, myExecutions: action.payload }
        default: return state
    }
}