import React from 'react'

const WarningBlock = ({ data }) => {
    const style = {
        wrapper: {
            display: 'flex',
            borderRadius: '4px',
            border: '1px solid #FFD200',
            padding: '12px',
            marginBottom: '15px'
        },
        icon: {
            display: 'flex',
            flex: '1',
            justifyContent: 'center',
            alignItems: 'center'
        },
        content: {
            display: 'flex',
            flex: '8',
            padding: '0 12px',
        }
    }
    return (
        <div style={style.wrapper} >
            <div style={style.icon}>
            <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="44" height="44" rx="22" fill="#FFD200"/>
                <path d="M23 26L23 21C23 20.4477 22.5523 20 22 20C21.4477 20 21 20.4477 21 21L21 26C21 26.5523 21.4477 27 22 27C22.5523 27 23 26.5523 23 26Z" fill="white"/>
                <path d="M21 18C21 17.4477 21.4477 17 22 17C22.5523 17 23 17.4477 23 18C23 18.5523 22.5523 19 22 19C21.4477 19 21 18.5523 21 18Z" fill="white"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M12 22C12 16.4772 16.4772 12 22 12C27.5228 12 32 16.4772 32 22C32 27.5228 27.5228 32 22 32C16.4772 32 12 27.5228 12 22ZM30 22C30 17.5817 26.4183 14 22 14C17.5817 14 14 17.5817 14 22C14 26.4183 17.5817 30 22 30C26.4183 30 30 26.4183 30 22Z" fill="white"/>
            </svg>

            </div>
            <div style={style.content}>{ data }</div>

        </div>
    )
}

export default WarningBlock