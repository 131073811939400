import React, {useState, useEffect} from 'react';
import styles from './bankAccounts.module.css';
import { useSelector, useDispatch } from 'react-redux';

import {connect} from 'react-redux';

import Button from '../../../../../elements/buttons/button';
import InputSelect from '../../../../../inputs/inputSelect';

import BankAccountsGroups from './bankAccountsGroups'
import BankAccountsPopup from './popup/popupBank';
import { getBanksList } from '../../../../../../redux/asset/bankActions';
import WarningBlock from '../../../../../elements/warningBlock';

const BankAccounts = ( { data, bankAccounts, setBankAccounts, ...props } ) => {
    const dispatch = useDispatch()
    const token = useSelector(state => state.start.token)
    const bankAccountsList = useSelector( state => state.asset.bankAccountsList )

    const [banksGroupe, setBanksGroupe] = useState(null)
    const [bank, setBank] = useState(null)
    const [bankPopUp, setBankPopUp] = useState( false )
    
    useEffect(() => {
        dispatch( getBanksList(`${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/userProfile/bankAccounts`, token.access_token) )
      return () => {
        dispatch({type: 'GET_BANK_ACCOUNTS_LIST', payload: null})
      }
    }, [])
    

    function viewCreatGroup(props){
        if( banksGroupe !== null ){
            return (
                <InputSelect 
                    label="Рахунки"
                    value={ bank } 
                    descr="Обрати банківський рахунок"
                    onChange={ e => setBank(e) }
                    options={ selectBankAuction( bankAccountsList ) }
                />
            )
        }
        return null
    }

    // if(props.bankAccountZU !== null){
        return <div style={{display: 'flex', width: '100%', flexFlow: 'column'}}>
            { bankPopUp && <BankAccountsPopup close={ ()=> setBankPopUp( false )}/> }
            <WarningBlock 
                data={<div style={{display: 'flex', flexFlow: 'column'}}>
                        <p>Для публікації Пріоритетного Викупу потрібно обов'язково  додати банківський рахунок:  Плата за лот.</p>
                        <p>Зміни доступні лише після збереження</p>
                    </div>
                }
            />
            <BankAccountsGroups 
                bankAccounts={bankAccounts}
                setBankAccounts={setBankAccounts}
            />
            <div style={{display: 'flex', width: '100%', flexFlow: 'column'}}></div>
            <InputSelect 
                label="Банківські рахунки"
                value={ banksGroupe }
                descr="Обрати банківський рахунок"
                onChange={ e => setBanksGroupe(e) }
                options={ optionBankAccount(props) }
                // disabled={elementDisabledCreate(props)}
            />
            { viewCreatGroup(props) }
            <div className={styles.editBanks}>
                <Button
                    color="blue"
                    onClick={ () => { 
                        setBankAccounts( prev => {
                            if ( prev.length > 0 ) {
                                for (let key in prev ){
                                    if( prev[key].accountType === banksGroupe ) {
                                        prev[key].accounts.push(bank)
                                        return prev
                                    } else {
                                        return [...prev, { accountType: banksGroupe, accounts: [ bank ] }]
                                    }
                                }
                            } else {
                                return [...prev, { accountType: banksGroupe, accounts: [ bank ] }]
                            }
                        })
                        setBanksGroupe(null)
                        setBank(null)
                        //window.scrollTo(0, 3000)
                    } }
                    disabled={(banksGroupe === null || bank === null) ? true : false}
                >
                    Додати
                </Button>
                <Button
                    // disabled={ elementDisabledCreate(props) }
                    color={'gray'}
                    onClick={ () => setBankPopUp(true) }
                >
                    Створити та редагувати банківський рахунок
                </Button>
            </div>
        </div>
    // }
}

function optionBankAccount(){
        return [
                /*{value: 'registrationFee', name: 'Реєстраційний внесок', style: 'bold'},
                {value: 'guarantee', name: 'Гарантійний внесок', style: 'bold'},*/
                {value: 'payment', name: 'Плата за лот'}
            ]
}

// function getBank(props, data){
//     return [
//             {value: 'registrationFee', name: 'Реєстраційний внесок', style: 'bold'},
//             {value: 'guarantee', name: 'Гарантійний внесок', style: 'bold'},
//             {value: 'payment', name: 'Платежі', style: 'bold'}
//         ]
// }

function selectBankAuction( banks ){
    let tmp = null;
    if( !!banks){
        tmp = banks
        .map(
            (i) => {
                let block = <div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                                <div >
                                    {i.bankName}
                                </div>
                                <div style={{marginRight: '0', color: '#92929D'}}>
                                    {`${i.currency} - 
                                    ${i.accountIdentifications ? i.accountIdentifications[i.accountIdentifications.length - 1].id : null
                                    /*i.accountIdentifications[i.accountIdentifications.length - 1].id}*/}`}
                                </div>
                            </div>
                return {value: i, name: block}
            }
        )
    }
    return tmp
}

// function elementDisabled(props){
//     let tmpStatus = null;
//     if(props.auctionsMy.status !== null){
//         if(props.auctionsMy.status !== 'active_rectification'){
//             tmpStatus = 'disabled'
//         }
//     }
//     return tmpStatus
// }

export default BankAccounts