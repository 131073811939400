import React from 'react';
import styles from '../generalInfoBlock.module.css';

import FullInfoBlock from '../../fullInfoBlock/fullInfoBlock';
import ContactPoint from '../../../../../elements/contactPoint/contactPoint';
import { checkProp, isMongo, getUrAdress, getIdType } from '../../../../../../utils/check';

const ManageOrganizations =({ data, title })=> {

    if (!data || typeof data === 'undefined') return null

    return <>
            <h3 className={styles.infoBlockTitle}>{title}</h3>
            <div className={styles.infoBlockInner}>
                <div className={styles.infoBlockInnerTitle}>
                    <h3>{checkProp(data,'name') ? isMongo(data.name.uk_UA) : null}</h3>
                </div>
                {checkProp(data, 'identifier') && checkProp(data.identifier, 'scheme') && checkProp(data.identifier, 'id')
                    ? <FullInfoBlock
                        title={getIdType(data.identifier.scheme)}
                        data={data.identifier.id}
                    /> : null
                }
                {checkProp(data, 'address') && checkProp(data.address, 'addressID')
                   ? <FullInfoBlock
                        title='КОАТУУ'
                        data={data.address.addressID.id}
                    /> : null
                }
                {checkProp(data, 'address')
                   ? <FullInfoBlock
                        title='Юридична адреса'
                        data={getUrAdress(data)}
                    /> : null
                }
                {checkProp(data, 'contactPoint')
                    && 
                <ContactPoint data={data.contactPoint} /> 
                }
            </div>
        </>
}

export default ManageOrganizations