import React from 'react';
import styles from './executionEditSidebar.module.css';


// import { useSelector } from 'react-redux';

import { getPropertyType, getCurrency, checkProp } from '../../../../../utils/check';
import { setExecutionStatus } from '../../utils';


const ExecutionEditSidebar = ({ data }) => {

    // if( !data ) return null

    return (
        <div className={styles.sideBlock}>
            { checkProp(data, 'type') 
                &&
                <div className={styles.objectType}> {getPropertyType(data.type)} </div>
            }
            <div className={styles.aucStatus}>
                <p>Статус</p>
                <div className={styles.lotStatus}>{ !!data?.status && setExecutionStatus( data.status ) }</div>
            </div>
            <div className={styles.aucStatus}>
                <p>Ідентифікатор контрактингу</p>
                <div className={styles.lotStatus}>{ !!data?.executionId && data.executionId }</div>
            </div>
            {/* {checkProp(data,'valuesCharacteristics') && data.valuesCharacteristics.secondListAbsoluteValue.amount !== 0 ? <div className={styles.value}>
                <div className={styles.valueText}>Орендна ставка, {data.valuesCharacteristics.secondListAbsoluteValue.valueAddedTaxIncluded ? 'з ПДВ' : 'без ПДВ'}</div>
                <div className={styles.valueAmount}>{data.valuesCharacteristics.secondListAbsoluteValue.amount} <span className={styles.valueCurrency}>{getCurrency(data.valuesCharacteristics.secondListAbsoluteValue.currency)}</span></div>
            </div> : ''} */}
            <div className={styles.auctionSteps}>
                {/* <h2>Кроки створення</h2>
                <div className={styles.stepBlock}>
                    <div className={styles.stepIcon} datatmp={assetCreationStep}>
                        { assetCreationStep === 0 ? activeStepIcon : pastStepIcon }
                    </div>
                    <div className={styles.stepInfo}>
                        <h3>Заповніть загальну інформацію</h3>
                        <p>Заповніть дані ІП: назву, опис, інформацію про вартість, наявність рішень, додайте рахунки, тощо.</p>
                    </div>
                </div>
                <div className={styles.stepBlock}>
                    <div className={styles.stepIcon}>
                        { assetCreationStep < 1 ? inactiveStepIcon : assetCreationStep === 1 ? activeStepIcon : pastStepIcon}
                    </div>
                    <div className={styles.stepInfo}>
                        <h3 className={assetCreationStep < 2 ? styles.inactive : []}>Додайте документацію</h3>
                    </div>
                </div>
                <div className={styles.stepBlock}>
                    <div className={styles.stepIcon}>
                        { assetCreationStep < 3 ? inactiveStepIcon : assetCreationStep === 3 ? activeStepIcon : pastStepIcon}
                    </div>
                    <div className={styles.stepInfo}>
                        <h3 className={assetCreationStep < 3 ? styles.inactive : []}>Опублікуйте на Прозорро</h3>
                        <p>Надішліть дані в центральну базу Prozorro</p>
                    </div>
                </div> */}
            </div>
        </div>
    );
}

export default ExecutionEditSidebar;