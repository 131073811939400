import React, {useEffect} from 'react';
import styles from './actionItemPage.module.css';
import {useSelector, useDispatch} from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { auctionGetId, leaseActionGetId, auctionsGetSearch } from '../../../redux/actions/auctions/auctions';
import { setActionDeletePopup } from '../../../redux/actions/leaseActions/leaseActions';
import { checkProp, dateTransform, getIdentifierType, getUrAdress } from '../../../utils/check';
import FullInfoBlock from '../objectItemPage/blocks/fullInfoBlock/fullInfoBlock'
import HalfInfoBlock from '../objectItemPage/blocks/halfInfoBlock/halfInfoBlock'
import DocItem from '../objectItemPage/blocks/docItem/documentItem'
import DeletePopup from './blocks/actions/deletePopup/deletePopup'
import ContactPoint from '../../elements/contactPoint/contactPoint';
// import InstructionBlock from '../instructionBlock/instructionBlock'
import ActionCreate from '../actionCreate/actionCreate'
import { urlGetAction, urlGetMyAction } from '../../../utils/endPoints';

const ActionItemPage = () => {
    const dispatch = useDispatch()
    const data = useSelector(state => state.start.leaseActionGetId)
    const relatedObject = useSelector(state => state.start.auctionsGetId)
    const token = useSelector(state => state.start.token)
    const actionDeletePopup = useSelector(state => state.start.actionDeletePopup)
    const location = useLocation()

    let lastPath = ''
    let relatedActions = []

    useEffect(() => {
        getData()
        window.scrollTo(0, 0)
        return () => {
            dispatch( { type: 'LEASE_ACTION_GET_ID', payload: null } )
          }
      }, [])

    let objId = location.pathname.split('/')[3]

    function getData() {
        if (data === null) {
            let tmp =  location.pathname.split('/')
            if ( location.pathname.indexOf('related') !== -1) {
                tmp = tmp[4]
                objId = tmp
            } else { 
                tmp = tmp[3]
                objId = tmp
            }
            if (tmp.length >= 24) {
                dispatch( leaseActionGetId( urlGetAction( tmp )) );
            } else {
                if (token !== null) {
                    dispatch( leaseActionGetId( urlGetMyAction(tmp), token.access_token));
                }
            }
        }
    }

    function getRelatedObject(relatedObjectId) {
        if (relatedObject === null) {
            let url = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/public/registry_object/${relatedObjectId}`
            dispatch( auctionGetId(url) )
        }
    }

    function getRelatedActs() {
        for (let key in data.relatedEntity) {
            if (data.relatedEntity[key].entity_type === 'action') {
                relatedActions.push(data.relatedEntity[key].id)
            }
        }
    }

    let stlGenInfo = styles.objNavItem
    let stlActions = styles.objNavItem

    if (location.pathname.indexOf('/actionItem/') !== -1) {
        stlGenInfo = styles.activeNavItem
        stlActions = styles.objNavItem
        lastPath = 'Загальна інформація'
    }
    if (location.pathname.indexOf('related') !== -1) {
        stlGenInfo = styles.objNavItem
        stlActions = styles.activeNavItem
        lastPath = 'Пов`язані дії'
    }

        
    function getActionType(a) {
        switch (a) {
            case 'requestAnswer':
                return 'Запит для отримання відповіді'
            case 'intentionNotification':
                return 'Повідомлення про намір'
            case 'decisionPublication':
                return 'Публікація рішення'
            case 'decisionChange':
                return 'Зміна рішення'
            case 'petition':
                return 'Клопотання'
            case 'noAuctionNotification':
                return 'Інформаційне повідомлення про передачу об’єкта оренди без проведення аукціону'
            default: return
        }
    }

    function getEnityRole(a) { // тип об'єкта
        switch(a) {
            case 'propertyOwner':
                return `Балансоутримувач`
            case 'sellingEntity':
                return `Орендодавець`
            case 'governer':
                return `Уповноважений орган управління`
            case 'heritageController':
                return `Орган охорони культурної спадщини`
            default: return
        }
    }

    function getManageOrganizations(source) {
            return (
                <>
                    {/* <h3 className={styles.infoBlockTitle}>{title}</h3> */}
                    <div className={styles.infoBlockInner}>
                        <div className={styles.infoBlockInnerTitle}>
                            <h3>{source.name.uk_UA}</h3>
                            </div>
                        {checkProp(source.identifier, 'scheme') && checkProp(source.identifier, 'id')
                            ? <FullInfoBlock
                                title={getIdentifierType(source.identifier.scheme)}
                                data={source.identifier.id}
                            /> : null
                        }
                        {checkProp(source.address, 'addressID') && checkProp(source.address.addressID, 'id')
                            ? <FullInfoBlock
                                title='КОАТУУ'
                                data={source.address.addressID.id}
                            /> : null
                        }
                        {checkProp(source, 'address')
                           ? <FullInfoBlock
                                title='Юридична адреса'
                                data={getUrAdress(source)}
                            /> : null
                        }
                        {checkProp(source, 'contactPoint')
                            ? <ContactPoint data={source} /> : null
                        }
                    </div>

                </>
            )
        
    }

    function getRelatedEntity( entities ){
        let tmp = []
        const SideLink =( { data } )=> {
            return (
                <div className={styles.sideLinked}>
                        <p className={styles.desc}>
                            {data.desc}
                        </p>
                        <p className={styles.link}>
                            <Link to={ data.link }>
                                {data.id}
                            </Link>
                        </p>
                    </div>
            )
        }
        for ( let key in entities ){
            if ( entities[key].entity_type === 'registry' ){
                tmp = [...tmp, { id: entities[key].id, desc: 'Пов’язаний об’єкт', link: `/lease/object/${entities[key].id}`}]
            }
            if ( entities[key].entity_type === 'action' ){
                tmp = [...tmp, { id: entities[key].id, desc: 'Пов’язана дія', link: `/lease/actionItem/${entities[key].id}`}]
            }
            if ( entities[key].entity_type === 'lease_request' ){
                tmp = [...tmp, { id: entities[key].id, desc: 'Пов’язана заява', link: `/lease/lease_request/${entities[key].id}`}]
            }
        }
        return tmp.map( i => <SideLink data={ i } key={i.id} /> )
    }

    if (!!data && typeof data !== 'undefined') {
        getRelatedActs()
        
        const Navigator = (props) => {

            return(
                <div className={styles.navBlock}>
                    <ul>
                        <li key={1}>
                            <Link to={`/lease/actions_list`} >
                                Перелік дій
                            </Link>
                        </li>
                        <li key={2} className={styles.liTitle}>Дія № {data.leaseActionId ? data.leaseActionId : data.id}</li>
                        <li key={3}> {lastPath}</li>
                    </ul>
                    {data.leaseActionId || window.location.pathname.indexOf('update') !== -1 ? 
                        <h1 className={styles.objTitle}>Дія № {data.leaseActionId}</h1>
                        :
                        <div className={styles.titleButonWrap}>
                            <h1 className={styles.objTitle}>Дія № {data.id}</h1>
                            <div className={styles.buttonWrapper}>
                                <button className={styles.buttonBlue}>Опублікувати в Prozorro</button>
                                <Link to={`/lease/actionItem/${data.id}/update`} className={styles.buttonEdit}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M17.861 7.59611L18.9757 6.48135L16.5187 4.02428L15.4039 5.13904L17.861 7.59611ZM16.4542 9.00288L13.9971 6.54581L4.80565 15.7373L4.24014 18.7599L7.26272 18.1944L16.4542 9.00288ZM20.4419 5.13399C21.186 5.87812 21.186 7.08458 20.4419 7.82871L8.45174 19.8189C8.30989 19.9607 8.12847 20.0564 7.93129 20.0933L3.17816 20.9826C2.49083 21.1112 1.88885 20.5092 2.01744 19.8218L2.90674 15.0687C2.94363 14.8715 3.03928 14.6901 3.18113 14.5483L15.1713 2.5581C15.9154 1.81397 17.1219 1.81397 17.866 2.5581L20.4419 5.13399ZM20.0099 21H11.9901C10.67 21 10.67 19 11.9901 19H20.0099C21.33 19 21.33 21 20.0099 21Z" fill="#92929D"/>
                                    </svg>
                                </Link>
                                <button className={styles.buttonDelete} onClick={() => dispatch(setActionDeletePopup(true))}>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M5.66331 2.7L6.33539 0.683772C6.4715 0.27543 6.85364 0 7.28407 0H12.6841C13.1145 0 13.4966 0.27543 13.6328 0.683772L14.3048 2.7H18.984C19.5363 2.7 19.984 3.14772 19.984 3.7C19.984 4.25229 19.5363 4.7 18.984 4.7H18.127L17.3931 17.1762C17.2998 18.7619 15.9867 20 14.3983 20H5.56988C3.98145 20 2.66833 18.7619 2.57506 17.1762L1.84116 4.7H1C0.447715 4.7 0 4.25229 0 3.7C0 3.14772 0.447715 2.7 1 2.7H5.66331ZM7.7715 2.7H12.1966L11.9633 2H8.00483L7.7715 2.7ZM16.1235 4.7H3.84462L4.57161 17.0587C4.6027 17.5873 5.0404 18 5.56988 18H14.3983C14.9277 18 15.3654 17.5873 15.3965 17.0587L16.1235 4.7ZM11.686 7.23762C11.7205 6.68641 12.1952 6.2675 12.7464 6.30195C13.2977 6.3364 13.7166 6.81117 13.6821 7.36238L13.2321 14.5624C13.1977 15.1136 12.7229 15.5325 12.1717 15.4981C11.6205 15.4636 11.2016 14.9888 11.236 14.4376L11.686 7.23762ZM8.73212 14.4376C8.76657 14.9888 8.34766 15.4636 7.79645 15.4981C7.24524 15.5325 6.77047 15.1136 6.73602 14.5624L6.28602 7.36238C6.25157 6.81117 6.67048 6.3364 7.22169 6.30195C7.7729 6.2675 8.24767 6.68641 8.28212 7.23762L8.73212 14.4376Z" fill="#FFA047"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    }
                    <div className={styles.objNav}>
                        <Link to={`/lease/actionItem/${objId}`}>
                            <div className={stlGenInfo}>
                                Загальна інформація
                            </div>
                        </Link>
                        <Link to={`/lease/actionItem/${objId}/related`}>
                            <div className={stlActions}>
                                Пов'язані дії ({relatedActions.length})
                            </div>
                        </Link>
                    </div>
                </div>
            )
        }
 
        const SideBlock = (props)=> {

            return (
                <>
                    <div className={styles.sideBlock}>
                        <h2>
                            {getActionType(data.type)}
                        </h2>
                         {/* <div className={styles.aucStatus}>
                            <p>Статус</p>
                            <p className={styles.actionStatus}>{data.leaseActionId ? 'Опубліковано' : 'Чернетка'}</p>
                        </div> */}
                        {data.leaseActionId ?
                            <div className={styles.lotInfoBlock}>
                                <p>Ідентифікатор у ЦБД</p>
                                <p className={styles.lotInfo}>{data.leaseActionId}</p>
                            </div>
                        : ''}
                        { checkProp(data, 'relatedEntity') &&
                                getRelatedEntity( data.relatedEntity )
                        }
                    </div>
                </>
                )
        }

        const GeneralInfo = (props) => {
            return (
                <>
                    <div className={styles.infoBlock + ' ' + styles.generalInfo}>
                        <div className={styles.infoBlockTitle}>
                            <h3>Загальна інформація</h3>
                        </div>
                        {checkProp(data, 'datePublished') ?
                            <HalfInfoBlock
                                title='Дата публікації'
                                data={dateTransform(data.datePublished, 'time')}
                            /> : null }
                        {checkProp(data, 'dateModified') ?
                        <HalfInfoBlock
                            title='Остання дата зміни'
                            data={dateTransform(data.dateModified, 'time')}
                            /> : null }
                        {checkProp(data, 'type') ?
                            <HalfInfoBlock
                                title='Тип дії'
                                data={getActionType(data.type)}
                            /> : null }
                        { checkProp(data, 'description') ?
                            <FullInfoBlock
                                title='Опис дії'
                                data={data.description}
                            /> : null
                        }
                        <h3>Інформація про особу, що вчинила дію</h3>
                        { checkProp(data, 'actingEntityRole') !== null ?
                            <FullInfoBlock
                                title='Роль організації, що виконує дію'
                                data={getEnityRole(data.actingEntityRole)}
                            /> : null
                        }
                        { getManageOrganizations(data.actingEntity) }
                        { (checkProp(data, 'documents') && data.documents.length > 0) ?
                            <div className={styles.infoBlock}>
                                <div className={styles.infoBlockTitle}>
                                    <h3>{`Перелік документів (${data.documents.length})`}</h3>
                                </div>
                                <div style={{display: 'flex', flexFlow: 'column', gap: '10px', flex: 1 }}>
                                    { data.documents.map(( item ) => <DocItem data={item} key={item.id} /> )}
                                </div>
                            </div> : null
                        }
                        
                    </div>
                </>
            )
        }

        function mainBlock() {
            if (location.pathname.indexOf('related') !== -1) {
                return <div className={styles.nothing}>Наразі жодних дій не знайдено</div>
            } else if (location.pathname.indexOf('update') !== -1) {
                return <ActionCreate />
            } else {
                return <GeneralInfo />
            }
            // return <ActionInfo />
        }

        if (data.leaseActionId) {
            return (
                <div className={styles.wrapPage}>
                    <Navigator />
                    <div className={styles.mainBlock}>
                        <div className={styles.objContent}>
                            {mainBlock()}
                        </div>
                        <SideBlock />
                    </div>
                </div>
            )
        } else {
            return (
                <div className={styles.wrapPage}>
                    <Navigator />
                    <div className={styles.mainBlock}>
                        <SideBlock />
                        <div className={styles.objContent}>
                            {mainBlock()}
                        </div>
                    </div>
                    {actionDeletePopup ? <DeletePopup actionId={data.id} /> : ''}
                </div>
            )
        }

    } else {
        getData()
    }
    return null
}

export default ActionItemPage