import React from "react";
import Button from "../../../../../../../elements/buttons/button";
import { sendMyLeaseContract } from "../../../../../../../../utils/contracts/contractsUtils";
import { useDispatch, useSelector } from "react-redux";
import { getIdentifierType } from "../../../../../../../../utils/check";

const ValidateSendLeaseContract =( { data, setEditContract })=> {

    const dispatch = useDispatch()
    const objectData = useSelector(state => state.start.objectGet)
    const token = useSelector(state => state.start.token)

    function validateSendDataLeaseContract( data ){
        let tmpErr = []
        function setRegExp(type){
            switch( type ){
                case 'UA-EDR': return /^[0-9]{8}$/
                case 'UA-ID-CARD': return /^[0-9]{9}$/
                case 'UA-IPN': return /^[0-9]{10}$/
                case 'UA-IPN': return /^[0-9]{10}$/
                case 'UA-PASSPORT': return /[A-Я|A-Z]{2}[0-9]{6}$/
            }
        }
        const regId =( type )=> new RegExp( setRegExp( type ) )
        const regEmail = new RegExp(/\S+@\S+\.\S+/)
        const regPhone = new RegExp(/^\+380\d{9}$/)

        if ( !data?.contractNumber || data.contractNumber.length < 1 ) tmpErr = [...tmpErr, 'Заповніть номер договору']
        if ( !data?.title?.uk_UA || data.title.uk_UA.length < 1 ) tmpErr = [...tmpErr, 'Заповніть назву договору']
        if ( !data?.contractMethod ) tmpErr = [...tmpErr, 'Заповніть метод укладання договору']
        if ( !data?.dateSigned ) tmpErr = [...tmpErr, 'Заповніть дату підписання договору']
        if ( !data?.contractTime ) tmpErr = [...tmpErr, 'Заповніть термін дії договору']
        if ( !data?.contractTime?.dateFrom || data.contractTime.dateFrom === null ) tmpErr = [...tmpErr, 'Заповніть дату початку дії договору']
        if ( !data?.contractTime?.dateTill || data.contractTime.dateTill === null ) tmpErr = [...tmpErr, 'Заповніть кінцеву дату дії договору']
        if ( data.buyers.length === 0 ) {
            tmpErr = [...tmpErr, 'Додайте хоча б одного орендаря']
        }
        for ( let a in data.buyers) {
            if ( !!data?.buyers[a]?.identifier?.id && !data.buyers[a].identifier.id.match( regId( data.buyers[a].identifier.scheme ) ) ) {
                tmpErr = [...tmpErr, `Введіть валідний ${ getIdentifierType( data.buyers[a].identifier.scheme ) }`]
            }
            if ( !data?.buyers[a]?.contactPoint?.email ) {
                tmpErr = [...tmpErr, 'Введіть валідну адресу електронної пошти контактної особи']
            }
            if ( !!data?.buyers[a]?.contactPoint?.email && !data.buyers[a].contactPoint.email.match(regEmail) ) {
                tmpErr = [...tmpErr, 'Введіть валідну адресу електронної пошти контактної особи']
            }
            if ( !data?.buyers[a]?.contactPoint?.name ) {
                tmpErr = [...tmpErr, 'Введіть ім\'я контактної особи']
            }
            if ( !data?.buyers[a]?.address?.streetAddress ) {
                tmpErr = [...tmpErr, 'Введіть адресу( вулицю )']
            }
            if ( !data?.buyers[a]?.address?.region ) {
                tmpErr = [...tmpErr, 'Введіть адресу( область )']
            }
            if ( !!data?.buyers[a]?.contactPoint?.telephone && !data.buyers[a].contactPoint.telephone.match( regPhone )) {
                tmpErr = [...tmpErr, 'Введіть валідний номер телефону контактної особи']
            }
        }
        if ( data.status === 'active') {
            let tmpState = false
            for ( let key in data.documents){ if ( data.documents[key].documentType === 'contractSigned' ) tmpState = true }
            if ( !tmpState ) tmpErr = [...tmpErr, 'Щоб опублікувати договір зі статусом "Діючий" потрібно додати документ "Підписаний договір"']
        }
        const errorList = <div>
                            <div style={{fontSize: '21px', fontWeight: '600'}}>Для публікації контракту:</div>
                            <ul>{ tmpErr.map( i => <li style={{textAlign: 'left'}} key={i}>{i}</li>) }</ul>
                        </div>

        if (tmpErr.length === 0) sendMyLeaseContract( objectData.id, data.id, token.access_token, dispatch, setEditContract )
        else dispatch( { type: 'UNIVERSAL_ERROR', payload: errorList } )
        if (tmpErr.length === 0) setEditContract( prev => ({...prev, data: null, edit: false }))
    }

    return <>
            { !data?.datePublished &&
                <Button height='42px' onClick={ ()=> validateSendDataLeaseContract( data ) }>Опублікувати</Button>
            }
        </>
}

export default ValidateSendLeaseContract