import React, { useState } from 'react';
import styles from './objectTabItem.module.css';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { checkProp, isMongo, getRegistryType } from '../../../../utils/check';
import CreateActionPopup from '../../leaseAction/createLeaseAction/createActionPopUp';

const ObjectTabItem = ( {data, types, ...props} ) => {
    
    const objectTypesList = useSelector(state => state.start.objectTypesList);

    const [dropDomnMenu, setdropDomnMenu] = useState(false)
    const [objPopUp, setObjPopup] = useState(false)
    const [ createAction, setCreateAction ] = useState(false)

    function setType(type) {
        if(objectTypesList !== null || typeof objectTypesList !== 'undefined'){
            let typeTranslate = objectTypesList.find((item) => {
                return item.name === type;
            });
            if (typeof typeTranslate !== 'undefined') {
                return typeTranslate.description.uk_UA
            } else {
                return '';
            }
        }
    }

    function createAuctionBtn(data) {
        if ( data.registryObjectId && data.statusesDecisions.listType === 'First' ) {
            // Для організації аукціону на новий договір оренди потрібно: - присвоїти об'єкту тип переліку "Перелік першого типу"
                return <li>
                        <a 
                            href={`${process.env.REACT_APP_END_POINT_DTO}/create_auctions/bridge/${data.id}`}
                            // href={`${process.env.REACT_APP_END_POINT_DTO}/create_auctions/bridge/${data.registryObjectId}`}
                            target='_blank'
                         >
                            <span style={{color: '#139DD9', fontWeight: '600'}}>Створити аукціон</span>
                        </a>
            </li>
        }
        if (
            data.registryObjectId &&
            ( data.statusesDecisions.listType === 'Undefined' || !data.statusesDecisions.listType )
            && !!data.relatedOrganizations.currentTenants
        ) {
            // Для організації аукціону на новий договір оренди потрібно: - присвоїти об'єкту тип переліку "Перелік першого типу"
                return <li>
                        <a 
                            href={`${process.env.REACT_APP_END_POINT_DTO}/create_auctions/bridge/${data.id}`}
                            // href={`${process.env.REACT_APP_END_POINT_DTO}/create_auctions/bridge/${data.registryObjectId}`}
                            target='_blank'
                         >
                            <span style={{color: '#139DD9', fontWeight: '600'}}>Cтворити аукціон на продовження договору оренди</span>
                        </a>
            </li>
        }
        return null
    }

    function dropDowmMenu(props) {
        if ( dropDomnMenu ) {
            return <div className={styles.dropDown}>
                <div className={styles.dropdownContent}>
                    <ul>
                        <li><Link to={ !data.registryObjectId ? `/lease/create/${data.id}` : `/lease/object/${data.registryObjectId}`}>Відкрити</Link></li>
                        {data.apiIdentifier !== null ? <li><Link to={`/lease/create/${data.id}`}>Редагувати</Link></li> : null}
                        <li><Link to={`/lease/create/${data.id}/objectItems`}>Об'єкти</Link></li>
                        { ( !!data?.registryObjectItems && data.registryObjectItems.length > 0 ) &&
                            <li>
                                <Link to={`/lease/create/${data.id}/documents`}>Документи</Link>
                            </li>
                        }
                        { !!data?.registryObjectId && 
                             <li><Link to={`/lease/create/${data.id}/contracts`}>Контракти</Link></li>
                        }
                        { !!data?.registryObjectId &&
                            <li onClick={ ()=> {
                                setCreateAction( true )
                                setdropDomnMenu( false )
                            }}>
                                <div style={{width: '100%', fontWeight: '600', fontSize: '14px', color: 'var(--blue)', cursor: 'pointer'}}>
                                    Створити дію
                                </div>
                            </li>
                        }
                        {/* {data.apiIdentifier !== null ? <li><Link to={`/lease/create/${data.id}/leaseRequests`}>Заяви</Link></li> : <li className={styles.inactive}>Заяви</li> } */}
                        {/* {data.apiIdentifier !== null ? <li><Link to={'/'}>Дії</Link></li> : <li className={styles.inactive}>Дії</li>} */}
                        
                        { createAuctionBtn(data) }
                    </ul>
                </div>
                <div className={styles.dropdownBlock} onClick={()=> setdropDomnMenu(!dropDomnMenu)}></div>
            </div>
        }
        return null
    }

    const objectPopUp = ( items, title )=> { return (
         <div className={styles.popUpOn}>
                    <div className={styles.objectPopUp}>
                        <div className={styles.closeBtn} onClick={()=> setObjPopup(!objPopUp)}>
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path className={styles.closeBtnSvg} fillRule="evenodd" clipRule="evenodd" d="M8.41421 6.99989L13.364 11.9496C13.7545 12.3402 13.7545 12.9733 13.364 13.3639C12.9734 13.7544 12.3403 13.7544 11.9497 13.3639L7 8.41411L2.05025 13.3639C1.65973 13.7544 1.02656 13.7544 0.636039 13.3639C0.245514 12.9733 0.245515 12.3402 0.636039 11.9496L5.58579 6.99989L0.636039 2.05015C0.245515 1.65962 0.245514 1.02646 0.636039 0.635934C1.02656 0.245409 1.65973 0.245409 2.05025 0.635934L7 5.58568L11.9497 0.635934C12.3403 0.245409 12.9734 0.245409 13.364 0.635934C13.7545 1.02646 13.7545 1.65962 13.364 2.05015L8.41421 6.99989Z" fill="#92929D"/>
                            </svg>
                        </div>
                        <p className={styles.popUpTitle}>
                            { title && title}
                            <span>( Кількість об'єктів: {items.length} )</span>
                        </p>
                            { items &&
                                <ol>
                                    {
                                    items.length > 0 ?
                                            items.map( i => <li key={i.id}>{ `${isMongo(i.basicInfo.title)}, ${isMongo(i.placing.address.locality)}, ${isMongo(i.placing.address.streetAddress)}` }</li> )
                                        :
                                            <div className={styles.tableEmpty}>У Вас немає жодного об'єкта.</div>
                                    }
                                </ol>
                            }
                    </div>
                <div className={styles.popUpBlock} onClick={()=> setObjPopup(!objPopUp)}></div>
            </div>
        )}

    if (!data) return null

    return (
        <div className={styles.objTabItem}>
            <div className={styles.tdName}>
                <Link className={styles.aName} to={!data.registryObjectId ? `/lease/create/${data.id}` : `/lease/object/${data.id}`}>{ isMongo(data.title) }</Link>
                <p>
                    {/* Об'єкт №: {data.id}:  */}
                <a onClick={() => setObjPopup(!objPopUp)} className={styles.link}>Кількість об'єктів ({data.registryObjectItems.length})</a></p>
                {data.registryObjectId ? <div className={styles.obgId}>{data.registryObjectId}</div> : null}
            </div>
            <div className={styles.tdType}>
                <div style={{fontWeight: '600'}}>{data.registryObjectItems.length > 0 ? setType(data.registryObjectItems[0].itemType) : setType(data.type) }</div>
                <div style={{fontSize: '12px', color: '#92929D'}}>{`Тип переліку: ${ data.statusesDecisions && getRegistryType(data.statusesDecisions.listType)}`}</div>
            </div>
            <div className={styles.tdStatus}>{!data.registryObjectId ? 'Чернетка' : <div style={{color: '#00B191'}}><b>Опубліковано</b></div>}</div>
            <div className={styles.tdRent}>
                { !!data?.valuesCharacteristics?.secondListAbsoluteValue && checkProp(data.valuesCharacteristics.secondListAbsoluteValue, 'amount') 
                ? `${data.valuesCharacteristics.secondListAbsoluteValue.amount}грн` 
                : null
                }
            </div>
            {/* <div className={styles.tdAction}>{checkProp(data, 'registryActions') && data.registryActions.length > 0 ? data.registryActions.length : '0' }</div>
            <div className={styles.tdDeclaration}>{checkProp(data, 'leaseRequests') && data.leaseRequests.length > 0 ? data.leaseRequests.length : '0' }</div> */}
            <div className={styles.tdBtnWrap}>
                <div className={dropDomnMenu ? styles.btnActive : styles.tdBtn} onClick={()=> setdropDomnMenu(!dropDomnMenu)}>
                    <svg width="40" height="40" xmlns="http://www.w3.org/2000/svg" viewBox="-11 1 46 4">
                        <g id="Слой_2" data-name="Слой 2">
                            <g id="Слой_1-2" data-name="Слой 1">
                                <path className={styles.svgIcon} d="M5.33,2.67A2.67,2.67,0,1,0,2.67,5.34,2.66,2.66,0,0,0,5.33,2.67Z"/>
                                <path className={styles.svgIcon} d="M14.67,2.67A2.67,2.67,0,1,0,12,5.34,2.67,2.67,0,0,0,14.67,2.67Z"/>
                                <path className={styles.svgIcon} d="M24,2.67a2.67,2.67,0,1,0-2.67,2.67A2.66,2.66,0,0,0,24,2.67Z"/>
                            </g>
                        </g>
                    </svg>
                </div>
            </div>
            { dropDowmMenu(props) }
            { objPopUp && objectPopUp(data.registryObjectItems, isMongo(data.title)) }
            { createAction && <CreateActionPopup obj={ data } close={ ()=> setCreateAction( false ) } /> }
        </div>
    )
}

export default ObjectTabItem