import React from 'react';
import styles from './elems.module.css';
import FullInfoBlock from '../../../../elements/fullInfoBlock/fullInfoBlock';
import HalfInfoBlock from '../../../../elements/halfInfoBlock/halfInfoBlock';
import { dateTransform, getOwnershipType, isMongo } from '../../../../../utils/check';
import InfoBlockСollapser from '../../../../elements/infoBlockСollapser/infoBlockСollapser';
import AnnouncementDecisions from './decisions';
import AnnouncementRelatedEntities from './relatedEntities';
import { getAnnouncementDocType } from '../../utils';
import ViewDocItem from '../../../../elements/documents/view/viewDocumentItem';
import AnnouncementInitialProps from './initialProps';
import AnnouncementxSpecs from './xSpecs';
import ProducedEntities from './producedEntities';
import { getAnnDocHistory } from '../../../../../utils/endPoints'

const AnnouncementGeneral = ( { data } ) => {

    let tmpSaleCond = 'Не визначено'
    if (data.saleCondition === 'yes') tmpSaleCond = 'Так'
    if (data.saleCondition === 'no') tmpSaleCond = 'Ні'
    const generalInfo = <div className={styles.infoBlock + ' ' + styles.generalInfo}>
                                <div className={styles.infoBlockTitle}>
                                    {/* <h3>Загальна інформація</h3> */}
                                </div>
                                    {/* { !!data?.title && <FullInfoBlock
                                            title='Назва'
                                            data={ isMongo(data.title) }
                                        />
                                    } */}
                                    <div className={styles.infoBlockContent}>
                                        {!!data?.description &&
                                            <FullInfoBlock
                                                title='Опис'
                                                data={ isMongo(data.description)}
                                            />
                                        }
                                        <HalfInfoBlock
                                            title='Дата публікації'
                                            data={ dateTransform(data.datePublished, 'time') }
                                        />
                                        <HalfInfoBlock
                                            title='Дата останньої зміни'
                                            data={ dateTransform(data.dateModified, 'time') }
                                        />
                                        { !!data?.saleCondition &&
                                            <HalfInfoBlock
                                                title='Наявність умов продажу'
                                                data={tmpSaleCond}
                                            />
                                        }
                                    </div>
                            </div>

    return (
            <div className={styles.blockWrap}>
                { generalInfo }
                { !!data?.relatedEntities && data.relatedEntities.length > 0 &&
                    <InfoBlockСollapser 
                        blockTitle={ 'Список пов\'язаних сутностей' }
                        content={ <AnnouncementRelatedEntities data={ data.relatedEntities } /> }
                    />
                }
                { !!data?.relatedEntities && data.relatedEntities.length > 0 &&
                    <InfoBlockСollapser 
                        blockTitle={ 'Список створених сутностей' }
                        content={ <ProducedEntities data={ data.producedEntities } /> }
                    />
                }
                
                <InfoBlockСollapser 
                    blockTitle={'Рішення органу приватизації про затвердження умов продажу'}
                    content={ <AnnouncementDecisions data={data.decisions} /> }
                />
                <InfoBlockСollapser 
                    blockTitle={'Характеристики аукціону'}
                    content={ <AnnouncementInitialProps data={ data.initialProps} /> }
                />
                <InfoBlockСollapser 
                    blockTitle={'Уточнення до періодів'}
                    content={ <AnnouncementxSpecs data={ data.extraSpecs} periods={ data.periods } /> }
                />
                {/* <InfoBlockСollapser 
                    blockTitle={ 'Організації задіяні у керуванні об`єктом' }
                    content={ <AssetOrganizations data={data} /> }
                /> */}
                {/* { !!data?.disqualifiedBids && data?.disqualifiedBids.length > 0 &&
                    <InfoBlockСollapser 
                        blockTitle={ 'Список учасників, які були дискваліфіковані в будь якому пов`язаному аукціоні' }
                        content={ <AssetDisqualifiedBids data={ data.disqualifiedBids } /> }
                    />
                } */}
                
                { data?.documents &&
                    <InfoBlockСollapser 
                        blockTitle={ <>Документи { data.documents.length > 0 && `( ${data.documents.length} )` }</> }
                        content={   
                                <div style={{display: 'flex', gap: '10px', flexFlow: 'column', width: '100%'}}>
                                    {
                                        data.documents.length > 0 
                                    ?
                                        // data.documents.map( doc => <AnnouncementDocItem data={doc} key={doc.id} /> )
                                        data.documents.map( doc => <ViewDocItem data={doc} key={doc.id} 
                                            specs={{
                                                objId: data.id,
                                                docType: getAnnouncementDocType(doc.documentType),
                                                //historyURL: `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/announcement/${data.id}/documents/${doc.id}/history`
                                                //historyURL: `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/announcement/${data.id}/documents/${doc.id}/history`
                                                historyURL: getAnnDocHistory(data.id, doc.id)
                                            }}/> )
                                    : 'Документів не знайдено'
                                    }
                                </div>
                        }
                    />
                }
                <div className={styles.infoBlock}>
                    <a href='https://prozorro.sale/info/elektronni-majdanchiki-ets-prozorroprodazhi-cbd2' target='blank'>
                        Перелік та реквізити авторизованих електронних майданчиків
                    </a>
                </div>
            </div>
        )
}

export default AnnouncementGeneral