import React,{useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '../../../../../elements/buttons/button';
import styles from './announcementDocuments.module.css';
import AddDocPopup from '../../../../../elements/documents/addDocPopup/addDocPopup';
import CreateDocItem from '../../../../../elements/documents/docItem/documentItem';
import { postMyAnnouncementDraftDocument, getMyAnnouncementDraftDocument, getMyAnnouncementDoc, postMyAnnouncementDoc, getAnnDocHistory } from '../../../../../../utils/endPoints';
import { setAnnouncementDocTypes, setAnnouncementDocTypesLP } from './utils';
import { announcementDocDelete } from '../../../../../../redux/asset/assetActions';
import { isDraftObject } from '../../../../../../utils/check';
import WarningBlock from '../../../../../elements/warningBlock';

const AnnouncementDocuments =({ data })=> {
    const [addPopup, setAddPopup] = useState(false)
    const assetItem = useSelector(state => state.asset.assetItem)
    const announcementItem = useSelector(state => state.asset.announcementItem)
    const token = useSelector( state => state.start.token )
    const dispatch = useDispatch()

    const docProps = {
        dispatch: ( res )=> dispatch( { payload: res, type: 'ANNOUNCEMENT_GET_ID'}),
        docOf: announcementTypeDoc(),
        object: announcementItem,
        docList: (announcementItem.pipelineMethod === "announcement-large"
            || announcementItem.pipelineMethod === "announcement-large-fast"
            || announcementItem.pipelineMethod === "announcement-large-manual"
            || announcementItem.pipelineMethod === "announcement-large-ultrafast")
                ? setAnnouncementDocTypesLP()
                : setAnnouncementDocTypes(),
        url: {
            patch: isDraftObject(announcementItem.id) ? getMyAnnouncementDraftDocument : getMyAnnouncementDoc,
            post: isDraftObject(announcementItem.id) ? postMyAnnouncementDraftDocument : postMyAnnouncementDoc,
            delete: ( id ) => dispatch( announcementDocDelete( announcementItem.id, id, token.access_token ) ),
            history: getAnnDocHistory
        }
    }

    function announcementTypeDoc(){
        if(announcementItem.pipelineMethod === "announcement-large"
        || announcementItem.pipelineMethod === "announcement-large-fast"
        || announcementItem.pipelineMethod === "announcement-large-manual"
        || announcementItem.pipelineMethod === "announcement-large-ultrafast"){
            return 'large_announcement'
        }
        return 'announcement'
    }

    function isEditable( data ){
        let view = true
        if ( !!data && !data.status ) return false
        if ( !!data && data.status === 'pending' && !!data?.documents ){
            data.documents.forEach( i => { if ( i.documentType === 'clarifications') return false })
        }
       return true
    }

    if ( !data.documents ) return (
        <div className={styles.blockWrap}>
            { addPopup && <AddDocPopup asset={ announcementItem } close={ ()=> setAddPopup( false )} docProps={docProps} /> }
        <div className={styles.infoBlock}>
                <div className={styles.docContentWrap}>
                    <div className={styles.empty}>Наразі ще немає доданих документів</div>
                    <div className={styles.addButtonBlock}>
                        <Button onClick={ ()=> setAddPopup( prev => !prev )}>Додати документ</Button>
                    </div>
                </div>
            </div>
        </div>
    )

    return (
        <div className={styles.blockWrap}>
            { addPopup && <AddDocPopup asset={ announcementItem } close={ ()=> setAddPopup( false )} docProps={docProps} /> }
            <div className={styles.infoBlock}>
                <div className={styles.docContentWrap}>
                    { isEditable( data ) && <WarningBlock data={'Щоб редагувати опубліковане інформаційне повідомлення необхідно додати документ "Документ з інформацією про виправлення помилок"'}/>}
                    { data.documents.length === 0 && <div className={styles.empty}>Наразі ще немає доданих документів</div> }
                    { data.documents.length > 0 && <div className={styles.title}>Додані документи</div> }
                    <div className={styles.docList}>
                        { data.documents.map( i => <CreateDocItem data={i} key={i.id} docProps={docProps} /> ) }
                    </div>
                    <div className={styles.addButtonBlock}>
                        <Button onClick={ ()=> setAddPopup( prev => !prev )}>Додати документ</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AnnouncementDocuments