import React from 'react';
import styles from './elems.module.css';
import { useDispatch, useSelector } from 'react-redux';

import FullInfoBlock from '../../../../elements/fullInfoBlock/fullInfoBlock';
import HalfInfoBlock from '../../../../elements/halfInfoBlock/halfInfoBlock';
import { dateTransform, isMongo } from '../../../../../utils/check';
import { getExecutionOrderDocType } from '../../utils';
import PopDownBlock from '../../../../elements/popdownBlock/popDownBlock';
// import BuyerItem from './buyerItem';
import ViewDocItem from '../../../../elements/documents/view/viewDocumentItem';

const ExecusionOrder = ( { data } ) => {
    const executionItem = useSelector(state => state.asset.executionItem)

    return <div className={styles.infoBlockOrgContent}>
                        {!!data?.number &&
                            <HalfInfoBlock
                                title='Номер договору'
                                data={ data.number}
                            />
                        }
                        {!!data?.dateSigned &&
                            <HalfInfoBlock
                                title={'Дата наказу'}
                                data={ dateTransform( data.dateSigned, 'time' ) }
                            />
                        }
                        {!!data?.comment &&
                            <FullInfoBlock
                                title='Коментар'
                                data={ isMongo(data.comment)}
                            />
                        }
                        {/* { !!data.buyers && 
                            <PopDownBlock title={'Орендар'} 
                                content={
                                    data.buyers.map( i => <BuyerItem data={i} /> )
                                }
                            />
                        } */}
                        { ( !!data.documents && data.documents.length > 0 ) && 
                            <PopDownBlock title={`Документи (${data.documents.length})`} 
                                content={
                                    data.documents.map( i => <ViewDocItem 
                                                                key={i.id}
                                                                data={ i } 
                                                                specs={{
                                                                    objId: data.id,
                                                                    docType: getExecutionOrderDocType(i.documentType),
                                                                    historyURL: `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/executions/${executionItem.id}/order/document/${i.id}/history`
                                                                }}
                                                            /> )
                                }
                            />
                        }
        </div>
}

export default ExecusionOrder