import React, {useState, useEffect} from 'react'
import styles from './ecpCheckBlock.module.css'
import KEPTypeSelect from '../addDocPopup/docTypeSelect/docTypeSelect';
import Input from '../../../inputs/input';

import {kepMain, kepMainEdit} from './kep'

const ESignCheckBlock = ( props ) => {
    const [chBoxEsign, setChBoxEsign] = useState(false)
    // const [nameEsign, setNameEsign] = useState('Завантажити електронний підпис')
    const [KEPType, setKEPType] = useState(null)
    const [KEPFile, setKEPFile] = useState(null)
    const [KEPTypeList, setKEPTypeList] = useState(null)
    const [KEPpass, setKEPpass] = useState('')
    // const [file, setFile] = useState(props.dataFilesAtribute.fileFile)

    if (chBoxEsign) {
        if (KEPTypeList === null) {
            epListCertificateAuthority(props, setKEPTypeList)
            optionCA( KEPTypeList)
        }
    }

    function ecpBlockInputs( ) {
        let kepType = {
            label: 'КНЕДП/АЦСК',
            target: KEPType,
            action: setKEPType,
            necessarily: true,
            docList: optionCA( KEPTypeList),
            disabled: false
        }

        return (
            <>
                <label className={styles.btnESign}>
                    { KEPFile ? (KEPFile.name.length > 45 ? KEPFile.name.substr(0, 45) + '...' : KEPFile.name) : 'Завантажити електронний підпис' }
                    <input type="file"
                        name="file"
                        id="eSignFile"
                        onInput={(e) => {
                            e.preventDefault()
                            setKEPFile( e.target.files[0] )
                            // setNameEsign(KEPFile.name.length > 45 ? KEPFile.name.substr(0, 45) + '...' : KEPFile.name)
                        }}>
                    </input>
                </label>
                { listCA( kepType)}
                <Input 
                    label='Пароль від електронного підпису'
                    value={ KEPpass} 
                    onChange={ setKEPpass }
                    type='password'
                    required
                />
                { buttonsBlock(props, { file: KEPFile, type: KEPType, pass: KEPpass }) }
            </>)
    }
    return (
        <div className={styles.wrapEsign}>
            <div className={styles.chekbox}>
                { props.active ? <div
                                    className={chBoxEsign ? styles.pseudoCheckboxOn : styles.pseudoCheckboxOff}
                                    onClick={() => {
                                        setChBoxEsign(!chBoxEsign)
                                        if (!props.dataFilesAtribute.viewBtns.state) {
                                            props.dataFilesAtribute.viewBtns.set(true)
                                            // epListCertificateAuthority(props, setKEPTypeList)
                                        } else {
                                            props.dataFilesAtribute.viewBtns.set(false)
                                        }
                                    }}
                                >
                                </div>
                              :
                    <div className={styles.pseudoCheckboxInActive}></div>
                }
                {/* {disabledCheckbox(props, epListCertificateAuthority, file)} */}
                <div className={ props.active ? styles.chekboxLabel : styles.chekboxLabelinActive }>Електронний підпис
                    {/* <span>Не потребує зовсім, або підписаний в іншому сервісі (Наприклад, Документ.Онлайн)</span> */}
                </div>
            </div>
            {chBoxEsign &&
                <>
                    {ecpBlockInputs( )}
                </>
            }
        </div>
    )
}

function buttonsBlock(props, KEPdata){
    return (
        <div className={styles.bottomPart}>
            <div className={styles.btnCancel}
                onClick={
                    ()=>{
                        if(props.dataFilesAtribute.viewBtns.state){
                            props.dataFilesAtribute.viewBtns.set(false)
                            props.dataFilesAtribute.actionNo()
                        }else{
                            props.dataFilesAtribute.actionNo()
                        }
                    }
                }
            >
                Скасувати
            </div>
            { viewButtons(props, KEPdata) }
        </div>
    )
}

function viewButtons(props, KEPdata) {
    if(
        props.dataFilesAtribute.fileName !== '' &&
        props.dataFilesAtribute.fileName !== null &&
        props.dataFilesAtribute.fileType !== null &&
        props.dataFilesAtribute.fileType !== 'null' &&
        KEPdata.file !== null &&
        KEPdata.type !== null &&
        KEPdata.pass !== ''
    ){
        return (
            <div className={styles.btnDone}
                onClick={
                    async () => {
                        if(props.dataFilesAtribute.id === null){
                            await kepMain(props, KEPdata)
                        }else{
                            await kepMainEdit(props, KEPdata)
                        }
                        await props.dataFilesAtribute.viewBtns.set(false)
                    }
                }
            >
                Зберегти
            </div>
        )
    }
    return null
}

function listCA( kepType){
    return  <KEPTypeSelect
                data={kepType}
            />
}

function optionCA( KEPTypeList) {
    let tmp = null
    if(KEPTypeList !== null){
        if(KEPTypeList.length > 0){
            tmp = KEPTypeList.map(
                (i, count) => {
                    // return <option key={count} value={i.id}>{i.name}</option>
                    return {value: i.id, name: i.name}
                }
            )
        }
    }
    return tmp
}

//Получение списка выдавателей КЕП
async function epListCertificateAuthority(props, setKEPTypeList){
    let url = `https://caas-api.dzo.com.ua/ccs/api/v1/certificateAuthority/supported`;
        const response = await fetch(url, {
        method: "GET",
    })
    .then(
        (response) => {
            return response.json()
        }
    )
    .catch(console.error)
    await setKEPTypeList(response.ca)
}

export default ESignCheckBlock