import styles from './inactiveObjectPopup.module.css';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setInactiveObjectPopup } from '../../../../redux/actions/object/object';

const InactiveObjectPopup = () => {

    const dispatch = useDispatch();
    const inactiveObjectPopup = useSelector(state => state.start.inactiveObjectPopup);

    return (
        <div className={styles.backdrop}>
            <div className={styles.wrapper}>
                <div className={styles.popupBody}>
                    <button className={styles.closeButton} onClick={() => {dispatch(setInactiveObjectPopup(false))}}></button>
                    <div className={styles.title}>Неактивно</div>
                    <div>Можливість опублікувати в Prozorro буде доступна після заповнення всіх обов’язкових полів на кроках Загальна інформація, Додавання об’єктів та Додавання документації.</div>
                    <div className={styles.buttonWrapper}>
                        <button className={styles.buttonCancel} onClick={() => {dispatch(setInactiveObjectPopup(false))}}>Зрозуміло</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InactiveObjectPopup;