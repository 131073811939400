import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { sendAnnouncementDraft } from '../../../../../../utils/endPoints';
import Button from '../../../../../elements/buttons/button'
import Loader from '../../../../../loader/loader';
import UniPopUp from '../../../../../popups/uniPopUp/uniPopUp'
import { validateAsset, validateAssetLP } from './validation'

const SendAssetToProzoro =({ data, asset })=> {
    const token = useSelector(state => state.start.token)
    const userProfiles = useSelector(state => state.start.userProfiles)
    const navigate = useNavigate()

    const [popUpDisabled, setPopUpDisabled] = useState( false )
    const [api, setApi] = useState( { loader: false, data: '' } )
    const [errorsPopup, setErrorsPopup] = useState( 
        {   errors: [],
            state: false
        } )

    function sendFunction( announcement, asset ){
        if ( !!validateAsset( announcement, asset )
            && (data.pipelineMethod !== "announcement-large"
                && data.pipelineMethod !== "announcement-large-fast"
                && data.pipelineMethod !== "announcement-large-manual"
                && data.pipelineMethod !== "announcement-large-ultrafast"
            )
        ) {
            setErrorsPopup( prev => ({ ...prev, state: true }) )
            setErrorsPopup( prev => ({ ...prev, errors: validateAsset( announcement, asset ) }) )
        } else if ( !!validateAssetLP( announcement, asset )
            && (data.pipelineMethod === "announcement-large"
                && data.pipelineMethod === "announcement-large-fast"
                && data.pipelineMethod === "announcement-large-manual"
                && data.pipelineMethod === "announcement-large-ultrafast"
            )
        ) {
            setErrorsPopup( prev => ({ ...prev, state: true }) )
            setErrorsPopup( prev => ({ ...prev, errors: validateAssetLP( announcement, asset ) }) )
        } else {
            setApi( prev => ({...prev, loader: true }))
            fetch( sendAnnouncementDraft( data.id ), {
                    method: 'GET',
                    headers: { 'Authorization': 'Bearer ' + token.access_token, 'Content-Type': 'application/json' }
                })
                .then( res => {
                    if ( res.ok ) {
                        setApi( prev => ({...prev, loader: false }))
                        setApi( prev => ({...prev, data: 'Інформаційне повідомлення успішно опубліковано' }))
                        setApi( prev => ({...prev, ok: true }))
                    }
                    return res.json() 
                })
                .then( data => {
                    // let json = res.json()
                    if ( data?.message ) {
                        setApi( prev => ({...prev, loader: false }))
                        let tmpJSON = JSON.parse(data.message[0])
                        let tmpArr = []

                        if(data.message !== null && data.message.length > 0 && data.message[0].includes('decisions')){
                            switch (true){
                                case (data.message[0].includes('decisionTitle') && data.message[0].includes('decisionNumber')):
                                if(data.message[0].includes("String value is too short")){
                                    setApi( prev => ({...prev, data: data.message.map( (i) => {
                                        return (
                                            <>
                                                <div style={{fontWeight: '600'}}>Для публікації інформаційного повідомлення необхідно:</div>
                                                <div key={i}>Найменування рішення: Значення рядка занадто коротке</div>
                                                <div key={i}>Номер рішення: Значення рядка занадто коротке</div>
                                            </>
                                        )
                                    }
                                    ) }))
                                }
                                break
                                case (data.message[0].includes('decisionTitle')):
                                if(data.message[0].includes("String value is too short")) {
                                    setApi(prev => ({
                                        ...prev, data: data.message.map((i) => {
                                                return (
                                                    <>
                                                        <div style={{fontWeight: '600'}}>Для публікації інформаційного
                                                            повідомлення необхідно:
                                                        </div>
                                                        <div key={i}>Найменування рішення: Значення рядка занадто коротке
                                                        </div>
                                                    </>
                                                )
                                            }
                                        )
                                    }))
                                }
                                break
                            case(data.message[0].includes('decisionNumber')):
                                if(data.message[0].includes("String value is too short")){
                                    setApi( prev => ({...prev, data: data.message.map( (i) => {
                                        return (
                                            <>
                                                <div style={{fontWeight: '600'}}>Для публікації інформаційного повідомлення необхідно:</div>
                                                <div key={i}>Номер рішення: Значення рядка занадто коротке</div>
                                            </>
                                        )
                                    }
                                    ) }))
                                }

                            }
                        }else if(data.message !== null && data.message.length > 0 && data.message[0].includes('extraSpecs')){
                            if(data.message[0].includes('b_day') || data.message[0].includes('c_day') || data.message[0].includes('hour') || data.message[0].includes('minute') || data.message[0].includes('second')){
                                setApi( prev => ({...prev, data: data.message.map( (i) => {
                                    return (
                                        <>
                                            <div style={{fontWeight: '600'}}>Для публікації інформаційного повідомлення необхідно:</div>
                                            <div key={i}>Необхідно обрати одиниці виміру періоду на подачу пропозиції: календарні або робочі дні.</div>
                                        </>
                                    )
                                }
                                ) }))
                            }else if(data.message[0].includes("tenderPeriod duration should be larger then 3 business days")){
                                setApi( prev => ({...prev, data: data.message.map( (i) => {
                                    return (
                                        <>
                                            <div style={{fontWeight: '600'}}>Для публікації інформаційного повідомлення необхідно:</div>
                                            <div key={i}>Тривалість періоду між першим/другим та другим/третім повторним аукціоном має бути від 3 до 6 робочих днів</div>
                                        </>
                                    )
                                }
                                ) }))
                            }else if(data.message[0].indexOf("periods") !== -1
                                && data.message[0].indexOf("startDate") !== -1
                                && data.message[0].indexOf("must be greater than or equal to") !== -1
                            ){
                                setApi( prev => ({...prev, data:
                                    (
                                        <>
                                            <div style={{fontWeight: '600'}}>Для публікації інформаційного повідомлення необхідно:</div>
                                            <div>вказати правильну дату початку аукціону</div>
                                        </>
                                    )
                                }))
                            }
                        //}else if(data.message !== null && data.message.length > 0 && data.message[0] === '{"relatedEntities":[{"type":"large_asset","_id":"6…with documentType=illustration is required"]}}}]}'){
                        }else if(data.message !== null){
                            if(data.message[0].indexOf("itemProps") !== -1 && data.message[0].indexOf("This field is required.") !== -1){
                                setApi( prev => ({...prev, data:
                                    (
                                        <>
                                            <div style={{fontWeight: '600'}}>Для публікації інформаційного повідомлення необхідно:</div>
                                            <div>заповнити обовʼязкові поля ОБʼЄКТУ: кількість та одиниці виміру обʼєкта, адресу та географічні координати місця розташування, технічні характеристики, інформацію щодо реєстрації</div>
                                        </>
                                    )
                                }))
                            }else if(data.message[0].indexOf("extraSpecs") !== -1
                                //&& data.message[0].indexOf("periods") !== -1
                                //&& data.message[0].indexOf("startDate") !== -1
                                //&& data.message[0].indexOf("must be greater than or equal to") !== -1
                            ){
                                setApi( prev => ({...prev, data:
                                    (
                                        <>
                                            <div style={{fontWeight: '600'}}>Для публікації інформаційного повідомлення необхідно:</div>
                                            <div>вказати правильну дату початку аукціону</div>
                                        </>
                                    )``
                                }))
                            }else if(data.message[0].indexOf("initialProps") !== -1
                            && data.message[0].indexOf("bankAccounts") !== -1
                            && data.message[0].indexOf("Please provide at least 3 items.") !== -1
                            ){
                                setApi( prev => ({...prev, data:
                                    (
                                        <>
                                            <div style={{fontWeight: '600'}}>Для публікації інформаційного повідомлення необхідно:</div>
                                            <div>додати банківські рахунки для: реєстраційного, гарантійного внеску та плати за лот</div>
                                        </>
                                    )
                                }))
                            }else if(data.message[0].indexOf("saleCondition") !== -1
                            && data.message[0].indexOf("This field is required") !== -1
                            ){
                                setApi( prev => ({...prev, data:
                                    (
                                        <>
                                            <div style={{fontWeight: '600'}}>Для публікації інформаційного повідомлення необхідно:</div>
                                            <div>заповнити поле "Наявність умов продажу"</div>
                                        </>
                                    )
                                }))
                            }else if( data.message === "Об'єкт вже створено в ЦБД"){
                                setApi( prev => ({...prev, data:
                                    (
                                        <>
                                            <div style={{fontWeight: '600'}}>{data.message}</div>
                                        </>
                                    )
                                }))
                            }else if(data.message[0].indexOf("relatedEntities") !== -1 && data.message[0].indexOf("status") !== -1 && data.message[0].indexOf("Related asset should be in pending state") !== -1){
                                setApi( prev => ({...prev, data:
                                    (
                                        <>
                                            <div style={{fontWeight: '600'}}>Для публікації інформаційного повідомлення необхідно:</div>
                                            <div>Пов'язаний об'єкт повинен перебувати у статусі Об'єкт опубліковано</div>
                                        </>
                                    )
                                }))
                            }else{
                                // setApi( prev => ({...prev, data:
                                //     (
                                //         <>
                                //             {/*<div style={{fontWeight: '600'}}>Для публікації інформаційного повідомлення необхідно:</div>*/}
                                //             {/*<div>завантажити документ з типом "Ілюстрації" до ОБʼЄКТУ великої приватизації</div>*/}
                                //             {setApi( prev => ({...prev, data: data.message.map( i => <div key={i}>{i}</div>) }))}
                                //         </>
                                //     )
                                // }))
                                setApi( prev => ({...prev, data: data.message.map( i => <div key={i}>{i}</div>) }))    
                            }
                        }else{
                            setApi( prev => ({...prev, data: data.message.map( i => <div key={i}>{i}</div>) }))    
                        }
                    }
                })
                .catch( error => {
                    setApi( prev => ({...prev, loader: false }))
                    setApi( prev => ({...prev, data: error }))
                })
        }
    }

    function disabledBtn( asset ){
        return false
    }


    if ( !!data?.objectId ) return null

    if ( api.loader ) return <Loader />
    if ( api.data ) return <UniPopUp 
                                type={1}
                                close={ ()=> {
                                    setApi( prev => ({...prev, data: '' }))
                                    if ( api.ok ) navigate('/asset/my_announcements/')
                                }}
                                data={api.data}
                            />

    return (
        <>
            { popUpDisabled && 
                <UniPopUp 
                    type={1}
                    close={ ()=> setPopUpDisabled(false) } 
                    data={'Заповніть всі обов\'язкові поля, додайте Об\'єкти та документи, щоб опублікувати об\'єкт'} 
                /> 
            }
            { errorsPopup.state && 
                <UniPopUp 
                    type={1}
                    close={ ()=> setErrorsPopup( prev => ({...prev, state: false})) }
                    data={
                        <>
                            <div  style={{fontWeight: '600'}}>Для публікації інформаційного повідомлення необхідно:</div>
                            <ul style={{textAlign: 'left'}}>
                                { errorsPopup.errors.map( i => <li key={i.toString()}>{i}</li>) }
                            </ul>
                        </>
                    }
                /> 
            }
            {userProfiles !== null && userProfiles.isFinAuth === true
                ? <Button 
                    height={'50px'} width={'200px'} 
                    // disabled={ disabledBtn() }
                    onClick={ ()=> { disabledBtn( data ) ? setPopUpDisabled(true) : sendFunction( data, asset ) } }
                >
                    Опублікувати в Prozorro
                </Button>
                : null
            }
        </>
    )

}

export default SendAssetToProzoro