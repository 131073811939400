import React, {useEffect, useState} from 'react';
import styles from './pagination.module.css';

import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { objectsGetMy } from '../../../redux/actions/object/object';

const PaginatorSimple = ({ data, url, get, ...props}) => {
    const [page, setPage] = useState(1)
    const { pageNum } = useParams();
    const [pages, setPages] = useState(10)
    // const [itemsLength, setItemsLength] = useState(0)

    const dispatch = useDispatch()
    const token = useSelector(state => state.start.token);

    // useEffect(async () => {
    //     let header = {
    //         'Authorization': 'Bearer ' + token,
    //         'Content-Type': 'application/json',
    //     }
    //     let tmpLength = await fetch(`${url}?onlyCount="1"`, header)
    //     setItemsLength(tmpLength)
    // }, [])

    // useEffect( () => {
    //     buttonElem(1)
    // }, [pages])
    

    function buttonElem(page) {
        let tmp = page
        setPage(tmp)
        let path = `${url}?limit=${pages}&offset=${tmp*pages-pages}`
        dispatch( get(path, token.access_token))
        window.scrollTo(0, 370)
        // window.location.pathname = window.location.pathname.at(-1) + `/page=${tmp}`
    }

    const tmpArrowPrew =()=> {
        if (page > 1) {
            return <div className={styles.pageBackNav} onClick={()=>{buttonElem(page-1)}}>
                <svg width="7" height="12" xmlns="http://www.w3.org/2000/svg" className={styles.svgIcon} viewBox="0 0 7 12">
                    <g id="Слой_2" data-name="Слой 2">
                        <g id="Слой_1-2" data-name="Слой 1">
                        <path className={styles.svgIcon} d="M2.41,6l4.3,4.29a1,1,0,1,1-1.42,1.42l-5-5a1,1,0,0,1,0-1.42l5-5A1,1,0,0,1,6.71,1.71Z" fill="#92929D"/></g></g>
                </svg>
            </div>
        }else{
            return null
        }
    }
    
    const tmpArrowNext =()=> { //TODO: тут така тєма, що як би є 10 айтемів, але воно показує, що є наступна сторінка (pages - 1) ??!
        if( data.length > (pages - 1) ){
            return <div className={styles.pageFwdNav} onClick={ ()=>{ buttonElem(page+1) } }>
                <svg width="7" height="12" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7 12">
                    <g id="Слой_2" data-name="Слой 2">
                        <g id="Слой_1-2" data-name="Слой 1">
                            <path className={styles.svgIcon} d="M4.59,6,.29,1.71A1,1,0,0,1,1.71.29l5,5a1,1,0,0,1,0,1.42l-5,5A1,1,0,0,1,.29,10.29Z" fill="#92929D"/></g></g>
                </svg>
            </div>
        }else{
            return null
        }
    }

    function pageNavCounter() {
            return (
                <div className={styles.pageNavCounter} >
                    <div className={styles.pageNumber}>{pages}</div>
                    <div className={styles.arrowsCountainer}>
                        <div className={styles.counterUp}
                            onClick={  () => {
                                setPages(pages == 30 ? 10 : pages + 10)
                                // setTimeout(() => buttonElem(1, pages < 30 ? pages + 10 : 10), 400)
                            }}>
                            <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path className={styles.svgIcon} fillRule="evenodd" clipRule="evenodd" d="M7.5 6C7.91202 6 8.14721 5.52962 7.9 5.2L4.4 0.533333C4.2 0.266666 3.8 0.266667 3.6 0.533333L0.1 5.2C-0.147214 5.52962 0.0879774 6 0.5 6L7.5 6Z" fill="#92929D" />
                            </svg>
                        </div>
                        <div className={styles.counterDn}
                            onClick={ () => {
                                setPages(pages == 10 ? 10 : pages - 10)
                                // setTimeout(() => buttonElem(1, pages > 10 ? pages - 10 : 10), 400)
                            }}>
                            <svg width="8" height="6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.59 6.37">
                                <defs>
                                </defs>
                                <g id="Слой_2" data-name="Слой 2"><g id="Слой_1-2" data-name="Слой 1">
                                    <path className={styles.svgIcon} d="M9.09,0a.5.5,0,0,1,.39.81L5.18,6.18a.5.5,0,0,1-.78,0L.11.81A.5.5,0,0,1,.5,0Z" fill="#92929D" />
                                </g>
                                </g>
                            </svg>
                        </div>
                    </div>
                </div>
            )
    }

    if (!data || typeof data === 'undefined' || data.length < 1) return null
    if (data !== null && typeof data !== 'undefined' && data.length > 0 ) {
        return (
            <div className={styles.paginationWrap}>
                <div className={styles.pagination}>
                    {/* <div className={styles.pageDiscription}>
                        <span className="">{tmpMinCurentPage}-{maxItems > (myPage * pagesQuantity) ? myPage * pagesQuantity : maxItems} </span> з
                    <span className=""> {maxItems} </span>
                        {itemsName}
                    </div> */}
                    <div className={styles.pagePagesList}>
                        {tmpArrowPrew()}
                        <ul>
                            <li className={styles.font_active_page}>{page}</li>
                        </ul>
                        {tmpArrowNext()}
                    </div>
                    {pageNavCounter()}
                </div>
            </div>
        )
    }
}

export default PaginatorSimple
