import React,{useEffect} from 'react';
import styles from './announcementCreate.module.css';
import { Link, useLocation } from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import AnnouncementCreateTitle from './elems/titleBar/createTitleBar';
import AnnouncementSideBarCreate from './sidebar/sideBarCreate';
import AnnouncementGeneralCreate from './elems/generalCreate';
import { getMyRedemptionDraft, getMyAnnouncement, getMyAsset, getMyRedemption } from '../../../../utils/endPoints';
import { assetGetId, announcementGetId } from '../../../../redux/asset/assetActions';
import Loader from '../../../loader/loader';
import { isDraftObject } from '../../../../utils/check';
import AnnouncementDocuments from './elems/documents/announcementDocuments';

const AnnouncementCreate =( { create } )=> {
    const token = useSelector(state => state.start.token);
    // const userProfile = useSelector(state => state.start.userProfiles);
    const assetItem = useSelector(state => state.asset.assetItem)
    const announcementItem = useSelector(state => state.asset.announcementItem)
    const dispatch = useDispatch()
    const { pathname } = useLocation()


    useEffect(() => {
      if ( !create ) {
        const id = pathname.split('/')[4]
        const url =( id )=> {
          if ( isDraftObject(id)) return getMyRedemptionDraft( id )
          if ( !isDraftObject(id)) return getMyRedemption( id )
        }
        dispatch( announcementGetId( url( id ), token.access_token))
      }
      return () => {
        dispatch({type: 'ANNOUNCEMENT_GET_ID', payload: null})
      }
    }, [])

    useEffect(() => {
      if ( !!announcementItem ) dispatch( assetGetId( getMyAsset( announcementItem.relatedEntities[0]._id ), token.access_token))
      return () => {
        dispatch({type: 'ASSET_GET_ID', payload: null})
      }
    }, [ announcementItem ])

    function setGeneral(){
      if ( create ) return <AnnouncementGeneralCreate data={ null } create={ create } />
      if ( !create && !!announcementItem && pathname === `/asset/redemption/edit/${announcementItem.id}/documents` ) return <AnnouncementDocuments data={ announcementItem } />
      if ( !create && !!announcementItem && pathname === `/asset/redemption/edit/${announcementItem.id}` ) return <AnnouncementGeneralCreate data={ announcementItem } />
      else return <Loader />
    }

    return (
        <div className={styles.wrap}>
            <AnnouncementCreateTitle data={ !create ? announcementItem : null } create={ create } />
            <div className={styles.container}>
                <AnnouncementSideBarCreate data={ !create ? announcementItem : null } />
                { setGeneral() }
            </div>
        </div>
    )
}

export default AnnouncementCreate