import React,{ useState, useEffect } from 'react';
import styles from './itemProps.module.css'
import {useSelector, useDispatch} from 'react-redux';
import { checkProp, isMongo, getValues } from '../../../../../../../../../utils/check';
import Input from '../../../../../../../../inputs/input';
import InputTextArea from '../../../../../../../../inputs/inputTextArea';
import InputSelect from '../../../../../../../../inputs/inputSelect';
import InputDate from '../../../../../../../../inputs/inputDate';
import Button from '../../../../../../../../elements/buttons/button';
import Localization from '../localization/localization';
import Checkbox from '../../../../../../../../inputs/checkBox';
import { dictionaryGet, getUnitCodes, getAdditionalClassificatorDM } from '../../../../../../../../../utils/check';
import { objectItemCreate, objectItemUpdate } from '../../../../../../../../../utils/objects/objectsUtils';
import Loader from '../../../../../../../../loader/loader';

const VehicleItemPropsEdit =({ data, classification, close })=> {
    const objectData = useSelector(state => state.start.objectGet);
    const token = useSelector(state => state.start.token);

    const objectTransmissionTypeList = useSelector(state => state.start.objectTransmissionTypeList);
    const objectFuelTypeList = useSelector(state => state.start.objectFuelTypeList);
    const unitCodeList = useSelector(state => state.start.dictionaryUnitCode);
    const dispatch = useDispatch()

    const [loader, setLoader] = useState( false )

    const [title, setTitle] = useState( data?.basicInfo.title ? isMongo(data.basicInfo.title) : null )
    const [description, setDescription] = useState( data?.basicInfo.description ? isMongo(data.basicInfo.description) : null )
    const [location, setLocation] = useState( data?.placing.location ? data.placing.location : null )
    const [address, setAdress] = useState( data?.placing.address ? data.placing.address : null )

    const [brand, setBrand] = useState( data?.vehicleProps?.brand ? data.vehicleProps.brand : null)
    const [model, setModel] = useState( data?.vehicleProps?.model ? data.vehicleProps.model : null)
    const [productionYear, setProductionYear] = useState( data?.vehicleProps?.productionYear ? data.vehicleProps.productionYear : null)
    const [engineCapacity, setEngineCapacity] = useState( data?.vehicleProps?.engineCapacity ? data.vehicleProps.engineCapacity : null)
    const [fuelType, setFuelType] = useState( data?.vehicleProps?.fuelType ? data.vehicleProps.fuelType : null)
    const [transmission, setTransmission] = useState( data?.vehicleProps?.transmission ? data.vehicleProps.transmission : null)
    const [kilometrage, setKilometrage] = useState( data?.vehicleProps?.kilometrage ? data.vehicleProps.kilometrage : null)
    const [identificationNumber, setIdentificationNumber] = useState( data?.vehicleProps?.identificationNumber ? data.vehicleProps.identificationNumber : null)
    const [condition, setCondition] = useState( data?.vehicleProps?.condition ? data.vehicleProps.condition : null)
    const [configuration, setConfiguration] = useState( data?.vehicleProps?.configuration ? data.vehicleProps.configuration : null)
    const [damagePresence, setDamagePresence] = useState( checkProp(data?.vehicleProps, 'damagePresence') ? data.vehicleProps.damagePresence : false)
    const [damagedDescription, setDamagedDescription] = useState( data?.vehicleProps?.damagedDescription ? data.vehicleProps.damagedDescription : null)
    const [color, setColor] = useState( data?.vehicleProps?.color ? data.vehicleProps.color : null)

    // const addClassif = getAdditionalClassificatorDM(classification.id)

    useEffect( () => {
        if ( objectFuelTypeList === null ) {
            dispatch( dictionaryGet('fuelType', 'OBJECT_GET_FUEL_TYPE_LIST') );
        }
        if ( objectTransmissionTypeList === null ) {
            dispatch( dictionaryGet('transmissionType', 'OBJECT_GET_TRANSMISSION_TYPE_LIST') );
        }
    }, []);

    const requestBody = {
        itemType: 'vehicle',
        basicInfo: {
            description: { uk_UA: description },
            title: { uk_UA: title },
            classification: classification,
        },
        placing: {
            location: location,
            address: address
        },
        vehicleProps: {
            // itemPropsType: 'vehicle',
            brand: brand,
            model: model,
            productionYear: productionYear,
            engineCapacity: +engineCapacity,
            fuelType: fuelType,
            transmission: transmission,
            kilometrage: +kilometrage,
            identificationNumber: identificationNumber,
            condition: condition,
            configuration: configuration,
            damagePresence: damagePresence,
            damagedDescription: damagePresence ? damagedDescription : null,
            color: color
        }
    }

    function sendItemData( data ){
        const body = JSON.stringify(requestBody)
        if ( !data ) dispatch ( objectItemCreate(objectData.id, body, token.access_token ))
        if ( !!data ) dispatch ( objectItemUpdate(objectData.id, data.id, body, token.access_token ))
        setLoader( true )
        close()
    }

    function viewButtons(){
        return (
            <div className={styles.lineButtons}>
                <Button
                    data={'Зберегти зміни'}
                    color={'blue'}
                    onClick={
                        () => { sendItemData( data ) }
                    }
                />
                <Button
                    data={'Закрити'}
                    color={'gray'}
                    onClick={ close }
                />
            </div>
        )
    }

    if( loader ) return <Loader />
    return (
        <>
            <Input
                label="Назва об'єкта"
                value={ title }
                onChange={ event => setTitle( event ) }
                reqiured
            />
            <InputTextArea
                value={description}
                // disabled={blokedInput(props, '01')}
                label="Опис об'єкту"
                required
                onChange={ event => setDescription(event) }
                reqiured
            />
            {/* <InputSelect
                label="Стан державної реєстрації об'єкту"
                value={ registrationStatus }
                onChange={ event =>  setRegistrationStatus( event ) }
                options={ assetRegistrationStatus }
                dictionary
                reqiured
            />
            <div className={styles.twoInLine}>
                <Input
                    label="Номер запису"
                    value={ registrationID }
                    onChange={ event =>  setRegistrationID( event ) }
                    reqiured={ registrationStatus === 'complete' ? true : false }
                />
                <InputDate 
                    data={{
                        label: 'Дата реєстрації',
                        value: registrationDate,
                        onChange: event =>  setRegistrationDate( event ),
                        reqiured: registrationStatus === 'complete' ? true : false
                    }}
                />
            </div>
            <div className={styles.twoInLine}>
                <Input
                    label="Кількість одиниць"
                    value={ quantity }
                    onChange={ event => setQuantity(event) }
                    reqiured
                    // disabled={blokedInput(props, '11')}
                />
                <InputSelect
                    label={"Одиниці виміру об'єкта"}
                    value={ unit }
                    onChange={ event => setUnit(event) }
                    options={ getUnitList( unitCodeList ) }
                    finder
                    reqiured
                />
            </div> */}
            <div className={styles.semiTitle}>Характеристики транспортного засобу</div>
            <Input
                label="Марка транспортного засобу"
                value={ brand }
                onChange={ event => setBrand(event) }
            />
            <Input
                label="Модель транспортного засобу"
                value={ model }
                onChange={ event => setModel(event) }
                required
            />
            <div className={styles.twoInLine}>
                <Input
                    label="Рік випуску"
                    value={ productionYear }
                    onChange={ event => setProductionYear(event) }
                    required
                />
                <Input
                    label="Об’єм двигуна, см³"
                    value={ engineCapacity }
                    onChange={ event => setEngineCapacity(event) }
                />
            </div>
            <div className={styles.twoInLine}>
                <InputSelect
                    label={"Тип палива"}
                    value={ fuelType }
                    onChange={ event => setFuelType(event) }
                    options={ objectFuelTypeList }
                    dictionary={true}
                />
                <InputSelect
                    label={"Тип коробки передач"}
                    value={ transmission }
                    onChange={ event => setTransmission(event) }
                    options={ objectTransmissionTypeList }
                    dictionary={true}
                />
            </div>
            <div className={styles.twoInLine}>
                <Input
                    label="Пробіг, км³"
                    value={ kilometrage }
                    onChange={ event => setKilometrage(event) }
                />
                <Input
                    label="Номер кузова або шасі"
                    value={ identificationNumber }
                    onChange={ event => setIdentificationNumber(event) }
                />
            </div>
            <Input
                label="Технічний стан"
                value={ condition }
                onChange={ event => setCondition(event) }
            />
            <Input
                label="Комплектація"
                value={ configuration }
                onChange={ event => setConfiguration(event) }
            />
            <Checkbox 
                label="Потреба у ремонті"
                value={ damagePresence }
                onChange={ event => setDamagePresence(event) }
            />
            { damagePresence &&
                <InputTextArea
                    label="Опис пошкоджень"
                    value={ damagedDescription }
                    onChange={ event => setDamagedDescription(event) }
                />
            }
            <Input
                label="Колір"
                value={ color }
                onChange={ event => setColor(event) }
            />

            <Localization address={address} location={location} setLocation={setLocation} setAdress={setAdress} classification={classification} />

            { viewButtons() }
        </>
    )
}

export default VehicleItemPropsEdit
