import React, { useState } from 'react'
import {useSelector, useDispatch} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { myLeaseRequestEdit } from '../../../../../../utils/endPoints';
import Button from '../../../../../elements/buttons/button'
import Loader from '../../../../../loader/loader';
import UniPopUp from '../../../../../popups/uniPopUp/uniPopUp'
import { validateLR } from './validation'

const SendLeaseRequestToProzoro =({ data })=> {
    const token = useSelector(state => state.start.token)
    const userProfiles = useSelector(state => state.start.userProfiles)
    const navigate = useNavigate()

    const [popUpDisabled, setPopUpDisabled] = useState( false )
    const [api, setApi] = useState( { loader: false, data: '' } )
    const [errorsPopup, setErrorsPopup] = useState( 
        {   errors: [],
            state: false
        } )

    function sendFunction( request ){
        validateLR( request )
        if ( !!validateLR( request )) {
            setErrorsPopup( prev => ({ ...prev, state: true }) )
            setErrorsPopup( prev => ({ ...prev, errors: validateLR( request ) }) )
        } else {
            setApi( prev => ({...prev, loader: true }))
            fetch( `${myLeaseRequestEdit( data.id )}/send`, {
                    method: 'GET',
                    headers: { 'Authorization': 'Bearer ' + token.access_token, 'Content-Type': 'application/json' }
                })
                .then( res => {
                    if ( res.ok ) {
                        setApi( prev => ({...prev, loader: false }))
                        setApi( prev => ({...prev, data: 'Заяву успішно опубліковано' }))
                        setApi( prev => ({...prev, ok: true }))
                    }
                    return res.json() 
                })
                .then( data => {
                    // let json = res.json()
                    if ( data?.message ) {
                        setApi( prev => ({...prev, loader: false }))
                        setApi( prev => ({...prev, data: data?.message.map( i => <div key={i}>{i}</div>) }))
                    }
                })
                .catch( error => {
                    setApi( prev => ({...prev, loader: false }))
                    setApi( prev => ({...prev, data: error }))
                })
            navigate( '/lease/my_requests', { replace: true } )
        }
    }

    function disabledBtn( request ){
        return false
    }


    // if (!!data?.assetId) return null

    if ( api.loader ) return <Loader />
    if ( api.data ) return <UniPopUp 
                                type={1}
                                close={ ()=> {
                                    setApi( prev => ({...prev, data: '' }))
                                    if ( api.ok ) navigate('/lease/my_requests')
                                }}
                                data={ api.data } 
                            />

    return (
        <>
            { popUpDisabled && 
                <UniPopUp 
                    type={1}
                    close={ ()=> setPopUpDisabled(false) } 
                    data={'Заповніть всі обов\'язкові поля та додайте документи, щоб опублікувати заяву'} 
                /> 
            }
            { errorsPopup.state && 
                <UniPopUp 
                    type={1}
                    close={ ()=> setErrorsPopup( prev => ({...prev, state: false})) }
                    data={
                        <>
                            <div  style={{fontWeight: '600'}}>Для публікації об'єкта необхідно:</div>
                            <ul style={{textAlign: 'left'}}>
                                { errorsPopup.errors.map( i => <li key={i.toString()}>{i}</li>) }
                            </ul>
                        </>
                    }
                /> 
            }
            {userProfiles !== null && userProfiles.isFinAuth === true
                ? <Button 
                    height={'50px'} width={'200px'} 
                    // disabled={ disabledBtn() }
                    onClick={ ()=> { disabledBtn( data ) ? setPopUpDisabled(true) : sendFunction( data ) } }
                >
                    Опублікувати в Prozorro
                </Button>
                : null
            }
        </>
    )

}

export default SendLeaseRequestToProzoro