import { 
    //Documents
    DOCUMENT_GET,
    DOCUMENTS_GET,
    DOCUMENT_POST,
    DOCUMENT_PUT,
    DOCUMENT_PATCH,
    DOCUMENT_DELETE,

    CHANGE_DOCUMENT_ID,
    CHANGE_DOCUMENT_TITLE,
    CHANGE_DOCUMENT_DESCRIPTION,
    CHANGE_DOCUMENT_LANG,
    CHANGE_DOCUMENT_TYPE,

    CREATE_DOCUMENT_FILE_NAME,

    DOCUMENT_COUNNT_ARR_FILES,
    DOCUMENT_COUNNT_FILES,

    DOCUMENT_BID_BLOCK_STATUS,
    DOCUMENT_BID_PUBLIC_NAME,

    OLD_ARR_DOCUMENT_GET,

    POPUP_ADD_DOCUMENT,
    POPUP_UPDATE_DOCUMENT,
    POPUP_DELETE_DOCUMENT,
    ATTACH_FILE_BUTTON,
    ATTACH_FILE_BLOCK,

    DOCUMENT_OF_LIST,
    DOCUMENT_TOKEN,
    OBJECT_GET,
    REQUEST_GET_MY
} from "../../types";

import {getAPI, postAPI, putAPI, deleteAPI, patchAPI} from '../crud';
import {setLocal} from '../support';

//Documents
export function documentPost(_url, token, datas){
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/document`,
        token: token,
        actionType: DOCUMENT_POST,
        data: datas,
        header: {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }
    return postAPI(data);
}

export function documentGet(_url, token){
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/document/${_url.id}`,
        token: token,
        actionType: DOCUMENT_GET,
        header: {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }
    return getAPI(data);
}

export function documentPut(_url, token, datas){
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/document/${_url.id}`,
        token: token,
        actionType: DOCUMENT_PUT,
        data: datas,
        header: {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }
    return putAPI(data);
}

export function documentDelete(_url, token, action){
    const data = {
        url: _url,
        actionType: OBJECT_GET,
        header: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        // secondAction: action(),
    }
    return deleteAPI(data);
}

export function documentsGet(_url, token){
    const data = {
        url: _url,
        actionType: DOCUMENTS_GET,
        header: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        }
    }
    return getAPI(data);
}

export function changeDocumentId(value) {
    return {
        type: CHANGE_DOCUMENT_ID,
        payload: value
    }
}

export function changeDocumentTitle(value) {
    return {
        type: CHANGE_DOCUMENT_TITLE,
        payload: value
    }
}

export function changeDocumentDescription(value) {
    return {
        type: CHANGE_DOCUMENT_DESCRIPTION,
        payload: value
    }
}

export function changeDocumentLang(value) {
    return {
        type: CHANGE_DOCUMENT_LANG,
        payload: value
    }
}

export function changeDocumentFileName(value) {
    return {
        type: CREATE_DOCUMENT_FILE_NAME,
        payload: value
    }
}


export function changeDocumentArrFiles(value) {
    return {
        type: DOCUMENT_COUNNT_ARR_FILES,
        payload: value
    }
}

/*export function changeDocumentCountArrFiles(value) {
    return {
        type: DOCUMENT_COUNNT_FILES,
        payload: value
    }
}*/

export function changeDocumentCountArrFiles(value) {
    return async dispatch => {
        dispatch({type: DOCUMENT_COUNNT_ARR_FILES, payload: value})
    }
}

export function changeDocumentBidBlockStatus(value) {
    return {
        type: DOCUMENT_BID_BLOCK_STATUS,
        payload: value
    }
}

export function changeDocumentBidPublicName(value) {
    return {
        type: DOCUMENT_BID_PUBLIC_NAME,
        payload: value
    }
}

export function changeDocumentType(value) {
    return {
        type: CHANGE_DOCUMENT_TYPE,
        payload: value
    }
}

export function oldArrDocumentGet(url, procedureId){
    const data = {
        //url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/document/${_url.id}`,
        url: `${url}/api/v1/uk/document/proxy/history?url=/api/procedures/${procedureId}/documents/history`,
        //url: `${}https://procedure-staging.prozorro.sale/api/procedures/${procedureId}/documents/history`,
        actionType: OLD_ARR_DOCUMENT_GET,
        header: {
            'Content-Type': 'application/json'
        }
    }
    return getAPI(data);
}

export function documentTokenGet(_url, document, token) {
    return async dispatch => {
        const formData = new FormData();
        formData.append('documentType', document.type);
        formData.append('file', document.file);
        formData.append('scope', document.scope);
        const response = await fetch(_url, {
            method: "POST",
            body: formData,
            headers: {
                'Authorization': 'Bearer ' + token,
                // 'Content-Type': 'application/json',
            }
        }).catch(console.error);
        const json = await response.json();
        dispatch({type: DOCUMENT_TOKEN, payload: json});
    }
}

export function documentTokenClear() {
    return dispatch => {
        dispatch({type: DOCUMENT_TOKEN, payload: null})
    }
}
export function setPopupAddDocument(value) {
    return {
        type: POPUP_ADD_DOCUMENT,
        payload: value
    }
}
export function setPopupUpdateDocument(value) {
    return {
        type: POPUP_UPDATE_DOCUMENT,
        payload: value
    }
}
export function setPopupDeleteDocument(value) {
    return {
        type: POPUP_DELETE_DOCUMENT,
        payload: value
    }
}

export function setAttachFileButton(value) {
    return {
        type: ATTACH_FILE_BUTTON,
        payload: value
    }
}

export function setAttachFileBlock(value) {
    return {
        type: ATTACH_FILE_BLOCK,
        payload: value
    }
}
export function objectDocumentPost(_url, body, token, action){
    const data = {
        url: _url,
        actionType: OBJECT_GET,
        body: body,
        header: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'Application/json'
        },
        secondAction: action()
    }
    return postAPI(data);
}

export function requestDocumentPost(_url, body, token, action){
    const data = {
        url: _url,
        actionType: REQUEST_GET_MY,
        body: body,
        header: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'Application/json'
        },
        secondAction: action()
    }
    return postAPI(data);
}

export function objectDocumentPatch(_url, body, token, action){
    const data = {
        url: _url,
        actionType: DOCUMENT_PATCH,
        body: body,
        header: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        secondAction: action()
    }
    return patchAPI(data);
}

export function documentOfListGet(_url ,token) {
    const data = {
        url: _url,
        actionType: DOCUMENT_OF_LIST,
        header: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        }
    }
    return getAPI(data);
}

export function objectPostToProzoro(_url, body, token){
    const data = {
        url: _url,
        actionType: OBJECT_GET,
        body: body,
        header: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        // secondAction: action
    }
    return postAPI(data);
}