import React from "react";
import styles from './elems.module.css';
import FullInfoBlock from "../../../../elements/fullInfoBlock/fullInfoBlock";
import HalfInfoBlock from "../../../../elements/halfInfoBlock/halfInfoBlock";
import { checkProp, getAddedTax, getCurrency, isMongo } from "../../../../../utils/check";
import AnnouncementBankItem from "./bankItem";


const AnnouncementInitialProps =({ data, periods })=> {

    return (
        <>
            <div className={styles.sectionGray}>
                <HalfInfoBlock
                    title={'Стартова ціна'}
                    width='33'
                    data={`${data.value.amount} ${ getCurrency( data.value.currency) } ${ getAddedTax(data.value)}`}
                />
                <HalfInfoBlock
                    title={'Гарантійний внесок'}
                    width='33'
                    data={`${data.guarantee.amount} ${ getCurrency( data.guarantee.currency) }`}
                />
                <HalfInfoBlock
                    title={'Реєстраційний внесок'}
                    width='33'
                    data={`${data.registrationFee.amount} ${ getCurrency( data.registrationFee.currency) }`}
                />
            </div>
            { !!data?.minNumberOfQualifiedBids && <HalfInfoBlock
                title={'Мінімальна кількість заяв'}
                data={ data.minNumberOfQualifiedBids }
                width='33'
            />
            }
            <HalfInfoBlock
                title={'Розмір кроку аукціону'}
                width='33'
                data={`${data.minimalStep.amount} ${ getCurrency( data.minimalStep.currency) }`}
            />
            { checkProp(data, 'valueAddedTaxCharged' ) && 
                <HalfInfoBlock
                    title={'ПДВ на фінальну суму'}
                    data={ data.valueAddedTaxCharged ? 'Нараховується' : 'Не нараховується'}
                    width='33'
                />
            }
            { !!data?.accessDetails && <FullInfoBlock
                title={'Порядок ознайомлення з майном, час і місце проведення огляду об’єкта'}
                data={ isMongo(data.accessDetails) }
            />
            }
            { !!data.dutchStep && 
                <div className={styles.sectionGray}>
                    <HalfInfoBlock
                        title={'Кількість кроків'}
                        width='33'
                        data={ data.dutchStep.dutchStepQuantity }
                    />
                    <HalfInfoBlock
                        title={'Відсоток зниження, %'}
                        width='33'
                        data={ data.dutchStep.dutchStepPercent }
                    />
                    { !!data?.dutchStep.dutchStepValue && 
                        <HalfInfoBlock
                            title={'Розмір кроку голландського раунду'}
                            width='33'
                            data={ `${data.dutchStep.dutchStepValue.amount} ${ getCurrency( data.dutchStep.minimdutchStepValuealStep.currency) }` }
                        />
                    }
                </div>
            }
            {   !!data?.bankAccounts && 
                <div className={styles.contentList}>
                    { data.bankAccounts.map( account => (
                                <AnnouncementBankItem data={account} key={account.accounts[0].id} />
                            )
                        )
                    }
                </div>
            }
        </>
    )

}

export default AnnouncementInitialProps