import React, { useState, useEffect } from "react"
import styles from './decisionItem.module.css';
import { isMongo } from "../../../../../../utils/check"
import Input from "../../../../../inputs/input"
import InputDate from "../../../../../inputs/inputDate"
import CloselIcon from "../../../../../elements/svg/closelIcon";

const DecisionItem =({ data, decisions, setData, index })=> {
    const [decisionTitle, setDecisionTitle] = useState( !!data?.decisionTitle ? data.decisionTitle : '')
    const [decisionNumber, setDecisionNumber] = useState( !!data?.decisionNumber ? data.decisionNumber : '')
    const [decisionDate, setDecisionDate] = useState( !!data?.decisionDate ? data.decisionDate : '')
    const [decisionOf, setDecisionOf] = useState( !!data?.decisionOf ? data.decisionOf : '')

    useEffect(() => {
        let tmp = decisions
        tmp[index].decisionTitle = decisionTitle !== null && decisionTitle !== '' ? decisionTitle : null
        tmp[index].decisionNumber = decisionNumber !== null && decisionNumber !== '' ? decisionNumber : null
        tmp[index].decisionDate = decisionDate !== null && decisionDate !== '' ? decisionDate : null
        tmp[index].decisionOf = decisionOf !== null && decisionOf !== '' ? decisionOf : null
        setData(tmp)
    }, [decisionTitle, decisionNumber, decisionDate, decisionOf])

    return (
        <div className={styles.itemWrap}>
            {/*<div className={styles.closeWrap}>
                <div className={styles.closeBtn}
                    title={ 'Видалити рішення'}
                    onClick={ async () => {
                        let tmp = decisions
                        tmp.splice( index, 1 )
                        await setData( null )
                        setData( tmp )
                    }}
                >
                    <CloselIcon />
                </div>
            </div>*/}
            <Input 
                label='Найменування рішення' 
                value={ decisionTitle }
                onChange={ e => setDecisionTitle(e) }
                reqiured
            />
            <div style={{display: 'flex', width: '100%', gap: '15px'}}>
                <Input 
                    label='Номер рішення'
                    value={ decisionNumber }
                    onChange={ e => setDecisionNumber( e ) }
                    reqiured
                />
                <Input 
                    label='Тип рішення'
                    value={ decisionOf === "large_redemption" ? "Великий Викуп" : decisionOf }
                    onChange={ e => setDecisionOf( e ) }
                    disabled
                />
                <InputDate 
                    data={{
                        label: 'Дата рішення',
                        value: decisionDate,
                        onChange: e => setDecisionDate( e ),
                        reqiured: true
                    }}
                />
            </div>
        </div>
    )
}

export default DecisionItem