import React, { useState, useEffect } from 'react';
import styles from './contracts.module.css';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import LeaseContractItemCard from './contractItem/view/contractItemCard';
import LeaseContractItemEdit from './contractItem/edit/contractItemEdit';
import LeaseContractItemAdd from './contractItem/edit/contractItemAdd';
import Button from '../../../../../elements/buttons/button';
import Loader from '../../../../../loader/loader';
import { getMyLeaseContracts } from '../../../../../../utils/contracts/contractsUtils';
import LeaseContractListItem from './contractListItem/contractsListItem';

const UserLeaseContracts = ({ object })=> {
    const [ editContract, setEditContract ] = useState( { edit: false, data: null } )
    const myLeaseContracts = useSelector(state => state.start.myLeaseContracts);
    const token = useSelector(state => state.start.token);
    const dispatch = useDispatch();
    const { pathname } = useLocation()

    useEffect(() => {
        let id = pathname.split('/')[3]
        if ( !editContract.edit ) dispatch( getMyLeaseContracts( id, token.access_token ) )
        return () => {
          dispatch({payload: null, type: 'LEASE_CONTRACTS_GET_MY'})
        }
      }, [ editContract ])

    const contractsList =()=> {
        return (
            <>
                <div className={styles.titleWrap}>
                    <div className={styles.title}>Перелік договорів</div>
                    <div style={{ display: 'flex', flexDirection: 'row-reverse', flex: 1 }}>
                        <Button height={'45px'} onClick={ () => setEditContract({ edit: 'create', data: null }) }>Додати договір</Button>
                    </div>
                </div>
                <div className={styles.docListContainer}>
                    { ( !!myLeaseContracts && myLeaseContracts.length === 0 ) &&
                        <div style={{ display: 'flex', alignItems: 'center', flex: 1, width: '100%', margin: '60px 0' }}>
                            <div style={{ display: 'flex', fontSize: '24px', flex: 1 }}>Додайте договори</div>
                        </div>
                    }
                    {
                        ( !!myLeaseContracts && myLeaseContracts.length > 0 ) &&
                        <div style={{ display: 'flex', flexFlow: 'column', flex: 1, width: '100%', gap: '10px' }}>
                            { myLeaseContracts
                                            // .filter( i => !i.datePublished )
                                            .map( ( item ) => <LeaseContractListItem 
                                                data={ item } 
                                                key={ item.id } 
                                                edit={ ()=> setEditContract( prev => ({ ...prev, data: item, edit: 'edit' }) ) }
                                                setEditContract={ setEditContract }
                                            /> )
                            //                 .map( ( item ) => <LeaseContractItemCard 
                            //                     data={ item } key={ item.id } 
                            //                     edit={ ()=> setEditContract( prev => ({ ...prev, data: item, edit: 'edit' }) ) }
                            //                     reload={ ()=> {
                            //                         dispatch( getMyLeaseContracts( object.id, token.access_token ) )
                            //                         // setEditContract( { edit: false, data: null } )
                            //                     }}
                            //                 />
                            // )
                            }
                            {/* { object.contracts.map( ( item ) => <LeaseContractItemCard 
                                                data={ item } key={ item.id } 
                                                edit={ ()=> setEditContract( prev => ({ ...prev, data: item, edit: 'edit' }) ) }
                                                reload={ ()=> {
                                                    dispatch( getMyLeaseContracts( object.id, token.access_token ) )
                                                    // setEditContract( { edit: false, data: null } )
                                                }}
                                            />
                            )} */}
                        </div>
                    }
                </div>
            </>
        )
    }

    return (
        <div className={styles.wrapper}>
            { ( editContract.edit === 'create' && !editContract.data ) && 
                <LeaseContractItemAdd 
                    close={ () => {
                            setEditContract( prev => ({ ...prev, data: null, edit: false })) 
                            // dispatch( getMyLeaseContracts( object.id, token.access_token ) )
                            window.scrollTo(0, 0)
                        }
                    }
                    reload={ ()=> dispatch( getMyLeaseContracts( object.id, token.access_token ) ) }
                />
            }
            { ( editContract.edit === 'edit' ) &&
                <LeaseContractItemEdit
                    data={ editContract.data }
                    // id={ editContract.data }
                    close={ () => {
                            // dispatch( getMyLeaseContracts( object.id, token.access_token ) )
                            setEditContract( prev => ({ ...prev, data: null, edit: false }))
                            window.scrollTo(0, 0)
                        }
                    }
                    // reload={ ()=> dispatch( getMyLeaseContracts( object.id, token.access_token ) ) }
                    setEditContract={ setEditContract }
                />
            }
            { ( editContract.edit === 'view' ) &&
                <LeaseContractItemCard
                    data={ editContract.data }
                    // id={ editContract.data }
                    close={ () => {
                            // dispatch( getMyLeaseContracts( object.id, token.access_token ) )
                            setEditContract( prev => ({ ...prev, data: null, edit: false }))
                            window.scrollTo(0, 0)
                        }
                    }
                    // reload={ ()=> dispatch( getMyLeaseContracts( object.id, token.access_token ) ) }
                    setEditContract={ setEditContract }
                />
            }
            { !editContract.edit && contractsList() }
            { ( !myLeaseContracts && !editContract.edit ) && <Loader small /> }
        </div>
    )

}

export default UserLeaseContracts