import React from 'react';
import styles from './elems.module.css';
import FullInfoBlock from '../../../../elements/fullInfoBlock/fullInfoBlock';
import HalfInfoBlock from '../../../../elements/halfInfoBlock/halfInfoBlock';
import { getIdentifierType, isMongo } from '../../../../../utils/check';
import ContactPoint from '../../../../elements/contactPoint/contactPoint';

const AssetOrganizationItem = ( { title, data } ) => {

    return (
            <div className={styles.organizationWrap} >
                 <h3>{ title }</h3>
                <div className={styles.infoBlock}>
                    <div className={styles.infoBlockTitle}>
                        <div  className={styles.organizationTitle}>{ isMongo(data?.name) }</div>
                    </div>
                    <div className={styles.infoBlockContent}>
                        <HalfInfoBlock title={ getIdentifierType(data?.identifier?.scheme) } data={data?.identifier?.id} />
                        <HalfInfoBlock title={ 'Інформація щодо підтвердження повноважень' } data={data?.representativeInfo} />
                        <FullInfoBlock 
                            title="Юридична адреса" 
                            data={`${data.address.postalCode}, 
                                ${isMongo(data.address.countryName)}, 
                                ${isMongo(data.address.region)}, 
                                ${isMongo(data.address.locality)}, 
                                ${isMongo(data.address.streetAddress)}`
                            } 
                        />
                        { data?.contactPoint && <ContactPoint data={data.contactPoint} /> }
                    </div>
                </div>
            </div>
        )
}

export default AssetOrganizationItem