import React from 'react';
import styles from './checkBox.module.css';

const Checkbox = ({ onChange, value, label, disabled, ...props }) => {

    function setStyle(){
        if( disabled ) return `${styles.check} ${styles.disabled} ${styles.option}`
        return `${styles.check} ${styles.option}`
    }

     return (
        <>
            <div className={styles.inPutInfo}>
                <label className={setStyle()}>
                    <input 
                        checked={value}
                        className={styles.checkInput}
                        onChange={() => onChange(!value)}
                        type='checkbox'
                        disabled={ disabled }
                    >
                    </input>
                    <span className={styles.checkBox} ></span>
                    { label }{ props.children }
                </label>
            </div>
            {props.descr ? <div className={styles.descript}>{props.descr}</div> : ''}
        </>
    )
}


export default Checkbox