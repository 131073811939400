import axios from "axios";
import React from "react";
import {da} from "date-fns/locale";

export function getAPI(data) {
    return async dispatch => {
        const response = await fetch(data.url, {
            method: 'GET', // или 'PUT'
            headers: data.header
        });
        const json = await response.json()
        if (response.status !== 200) dispatch( {type: 'UNIVERSAL_ERROR', payload: `Помилка ${response.status} - ${json.message}` });
        // if (json.message) {
        // // //     dispatch({ type: data.actionType, payload: json })
        // } else {
            dispatch({ type: data.actionType, payload: json })
        //}
        if (response.status === 200 && data.secondAction) {
            data.secondAction();
        }
    }
}

export async function getMeData(url, setData, token) {
        const response = await fetch(url, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        })
        const json = await response.json();
        setData(json)
}

export async function getDataToMe( url, set, token ){
    try {
        const data = await axios.get( url, 
          { headers: { 
                'Content-Type': 'application/json',
                'Authorization': token.access_token 
            }
          })
          set( data.data )
    } catch (err) {
    }
}

export async function getDataPub( url, set ){
    try {
        const data = await axios.get( url, 
          { headers: { 
                'Content-Type': 'application/json',
                // 'Authorization': token.access_token 
            }
          })
          set( data.data )
    } catch (err) {
    }
}

export function postAPI(data) {
    return async dispatch => {
        const response = await fetch(data.url, {
            method: 'POST',
            body: data.body,
            headers: data.header
        });
        const json = await response.json();
        switch (response.status) {
            case 200:
                if (data.redirect) {
                    window.location.href = data.redirect;
                    try {
                        window.history.pushState(null, null, data.redirect);
                        return;
                    } catch (e) {
                    }
                    window.location.hash = '#' + data.redirect;
                }
                if (!!data.navigate) {
                    data.navigate(json.id)
                    // return json
                }
                dispatch({type: data.actionType, payload: json});
                if (data.secondAction) {
                    data.secondAction(response.id);
                }
                if (data.objectCreateRedirect) {
                    window.location.href = data.objectCreateRedirect + json.id + '/objectItems';
                    try {
                        window.history.pushState(null, null, data.objectCreateRedirect + json.id + '/objectItems');
                        return;
                    } catch (e) {
                        //
                    }
                    window.location.hash = '#' + data.objectCreateRedirect + json.id + '/objectItems';
                }
                break
            case 400:
                if ( json?.message ) {
                    let errors=[]
                    switch (true) {
                        case typeof json.message === 'object':
                        case typeof json.message === 'array':
                            for (const property in json.message) {
                                errors.push(`${property}: ${json.message[property]}`);
                            }
                            break;
                        case typeof json.message === 'string':
                        default:
                            errors.push(json.message)
                    }
                    console.log(errors)
                    dispatch({type: 'UNIVERSAL_ERROR', payload: errors?.map( i => <div key={i}>{i}</div>)});
                } else {
                    dispatch({type: 'UNIVERSAL_ERROR', payload: `Помилка ${response.status} - ${json.message}`});
                }
                break;
            default:
                dispatch({type: 'UNIVERSAL_ERROR', payload: `Помилка ${response.status} - ${json.message}`});
        }
    }
}

export function putAPI(data) {
    return async dispatch => {
        const response = await fetch(data.url, {
            method: 'PUT',
            body: data.body,
            headers: data.header
        });
        const json = await response.json();
        if (response.status === 200 && data.redirect) {
            window.location.href = data.redirect;
        }
        dispatch({type: data.actionType, payload: json});
        if (response.status === 200 && data.secondAction) {
            data.secondAction();
        }
    }
}

export function patchAPI(data) {
    return async dispatch => {
            const response = await fetch(data.url, {
                method: 'PATCH',
                body: data.body,
                headers: data.header
            });
        const json = await response.json();

        if (response.status === 200 && data.redirect) {
            window.location.href = data.redirect;
        }
        if (response.status === 200 && !!data.navigate) {
            data.navigate()
        }
        // dispatch( {type: data.actionType, payload: json} )
        if (response.status === 200){
            if(json !== null && json.relatedEntities !== null){
                if(Array.isArray(json.relatedEntities)){
                    if(json.relatedEntities.length > 0){
                        if(json?.relatedEntities[0]?.type === "large_asset") dispatch( {type: 'UNIVERSAL_ERROR', payload: `Зміни збережено` });
                    }
                }else{
                    if(json?.relatedEntities?.type === "large_asset") dispatch( {type: 'UNIVERSAL_ERROR', payload: `Зміни збережено` });
                }
            }
            dispatch( {type: data.actionType, payload: json} );            
        }
        if (response.status !== 200) dispatch( {type: 'UNIVERSAL_ERROR', payload: `Помилка ${response.status} - ${json.message}` });
        if (response.status === 200 && data.secondAction) {
            data.secondAction();
        }
    }
}
export function patchAPINotNavigation(data) {
    return async dispatch => {
            const response = await fetch(data.url, {
                method: 'PATCH',
                body: data.body,
                headers: data.header
            });
        const json = await response.json();

        if (response.status === 200 && data.redirect) {
            //window.location.href = data.redirect;
        }
        if (response.status === 200 && !!data.navigate) {
            //data.navigate()
        }
        // dispatch( {type: data.actionType, payload: json} )
        if (response.status === 200){
            if(json !== null && json.relatedEntities !== null){
                if(json.relatedEntities.length > 0){
                    if(json?.relatedEntities[0]?.type === "large_asset")
                    dispatch( {type: 'UNIVERSAL_ERROR', payload: `Зміни збережено` });
                }
            }
            dispatch( {type: data.actionType, payload: json} );            
        }
        if (response.status !== 200) dispatch( {type: 'UNIVERSAL_ERROR', payload: `Помилка ${response.status} - ${json.message}` });
        if (response.status === 200 && data.secondAction) {
            data.secondAction();
        }
    }
}

export function deleteAPICancel(data) {
    return async dispatch => {
            const response = await fetch(data.url, {
                method: 'POST',
                headers: data.header
            });
        const json = await response.json();
        if ( response.status === 200 ) {
            dispatch({type: data.actionType, payload: json});
        }
        if (json.success && data.redirect) {
            window.location.href = data.redirect;
            if ( !!data.navigate() ) data.navigate()
        }
        if (response.status === 200 && data.secondAction) {
            data.secondAction();
        }
        if (!!json?.massage ) {
        }
    }
}

export function deleteAPI(data) {
    return async dispatch => {
            const response = await fetch(data.url, {
                method: 'DELETE',
                headers: data.header
            });
        const json = await response.json();
        if ( response.status === 200 ) {
            dispatch({type: data.actionType, payload: json});
        }
        if (json.success && data.redirect) {
            window.location.href = data.redirect;
            if ( !!data.navigate() ) data.navigate()
        }
        if (response.status === 200 && data.secondAction) {
            data.secondAction();
        }
        if (!!json?.massage ) {
        }
    }
}

// AXIOS API >>>

export async function axiosDeleteAPI( data ){
    data.dispatch({ payload: true, type: 'SET_LOADER' })
    try {
        const responce = await axios.delete( data.url,
            {
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${data.token}`,
                }
            })
            if ( responce.status === 200 ){
                if (!!data?.set) data.set(responce.data)
                data.dispatch({ payload: false, type: 'SET_LOADER' })
                if ( !!data?.success ) data.success( responce.data )
            }
    } catch (err) {
        data.dispatch({ payload: false, type: 'SET_LOADER' })
        data.dispatch( {type: 'UNIVERSAL_ERROR', payload: `Помилка: ${ err.message + ' - ' + isTheObject(err) }` });
    }
}

export async function axiosGetAPI( data ){
    data.dispatch({ payload: true, type: 'SET_LOADER' })
    try {
        const responce = await axios.get( data.url,
            {
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${data.token}`,
                }
            })
            if ( responce.status === 200 ){
                if (!!data?.set) data.set(responce.data)
                data.dispatch({ payload: false, type: 'SET_LOADER' })
                if ( !!data?.success ) data.success( responce.data )
                // if ( !!data?.redirect ) data.redirect( responce.data.id )
            }
    } catch (err) {
        data.dispatch({ payload: false, type: 'SET_LOADER' })
        data.dispatch( {type: 'UNIVERSAL_ERROR', payload: `Помилка ${ err.message + ' - ' + isTheObject(err) }` });
    }
}

export async function axiosPostAPI( data ){
    data.dispatch({ payload: true, type: 'SET_LOADER' })
    try {
        const responce = await axios.post( data.url, data.body,
            { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${data.token}` } })
            if ( responce.status === 200 ){
                if (!!data?.set) data.set(responce.data)
                data.dispatch({ payload: false, type: 'SET_LOADER' })
                if ( !!data?.success ) data.success( responce.data )
            }
    } catch (err) {
        data.dispatch({ payload: false, type: 'SET_LOADER' })
        data.dispatch( {type: 'UNIVERSAL_ERROR', payload: `Помилка: ${ err.message + ' - ' + isTheObject(err) }` });
    }
}

export async function axiosPostFileAPI( data ){
    data.dispatch({ payload: true, type: 'SET_LOADER' })
    try {
        const responce = await axios.post( data.url, data.body,
            { headers: { 'Authorization': `Bearer ${data.token}` } })
            if ( responce.status === 200 ){
                // if ( !!data?.set ) data.set( responce.data )
                return responce.data
            }
    } catch (err) {
        data.dispatch( {type: 'UNIVERSAL_ERROR', payload: `Помилка: ${ err.message + ' - ' + isTheObject(err) }` });
    }
    data.dispatch({ payload: false, type: 'SET_LOADER' })
}

export async function axiosPatchAPI( data ){
    data.dispatch({ payload: true, type: 'SET_LOADER' })
    try {
        const responce = await axios.patch( data.url,  data.body,
            { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${data.token}` } })
            if ( responce.status === 200 ){
                if ( !!data?.set ) data.set( responce.data )
                data.dispatch({ payload: false, type: 'SET_LOADER' })
                if ( !!data?.success ) data.success( responce.data )
            }
    } catch (err) {
        data.dispatch({ payload: false, type: 'SET_LOADER' })
        data.dispatch( {type: 'UNIVERSAL_ERROR', payload: `Помилка: ${ err.message + ' - ' + isTheObject(err) }` });
    }
}

function isTheObject( err ) {
    let error = 'Виникла помилка'
    if ( err?.response?.data?.message ) error = err.response.data.message
    if ( Array.isArray( error ) ) return error.map( i => <li key={i}>i</li> )
    else return error
}

// AXIOS INPUT POST, PATCH, DATA SAMPLE:
// {
//     url: isDraftObject( data.id ) ? urlPatchLeaseContractDrafts( objectData.id, data.id ) : urlPatchLeaseContract( objectData.id, data.id ),
//     dispatch: dispatch,
//     set: ( data )=> setEditContract( prev => ({...prev, data: data })),
//     body: resBody,
//     // redirect: ()=> navigate(`/lease/create/${objectData.id}/contracts`), - depricated
//     token: token.access_token,
//     success: ()=> dispatch( {type: 'UNIVERSAL_ERROR', payload: `Приватизацію скасовано!` }) - optional
// }