import React from 'react';
import styles from './notAuthUser.module.css';
import {useSelector} from 'react-redux';

import {FAQ_TITLE_TOP, FAQ_TITLE_BOTTOM} from '../../../../model/lang/topMenu.js';

import SvgNotAuthUser from './notAuthUser.svg';

const NotAuthUser = () => {

    const langFlag = useSelector(state => state.start.langFlag);

    return (
        <div className={styles.wrapFaq} >
            <a className={styles.korusne_link} href="https://www.dto.com.ua/helpful/presentations">
                <div className={styles.wrap_link}>
                    <div className={styles.korusne_img}>
                        <img src={SvgNotAuthUser} alt='' />
                        <div className={styles.korusne_title}>{FAQ_TITLE_TOP[langFlag]}</div>
                    </div>
                    <div className={styles.korusne_text}>{FAQ_TITLE_BOTTOM[langFlag]}</div>
                </div>
            </a>
        </div>
    )
}

export default NotAuthUser;