import React, {useState} from 'react';
import styles from './actionItemCard.module.css';
import { Link } from 'react-router-dom';
import { checkProp, getIdentifierType, dateTransform } from '../../../../../utils/check'
import InfoModal from '../../product/infoIconBlock/infoIconBlock'


const ActionItemCard = (props) => {
    const [discriptExpand, setDiscriptExpand] = useState(styles.objDiscription)
    const [moreBtn, setMoreBtnBtn] = useState('Більше')

    const data = props.item
    const actingSublect  = propsChecker(data.actingEntity) //суб'єкт дії 
    const publishDate = propsChecker(data.datePublished) // дата публікації
    const modifDate = propsChecker(data.dateModified) // дата зміни
    const actingEntityRole = propsChecker(data.actingEntityRole) // дата зміни

    const actionType = getActionType(data.type)


    function propsChecker(x) {
        if (x === undefined || x === 'undefined' || x === null || !x) {return ''}
        else {return x}
    }
    //бейдж "Ваш обьект"
    function yourObjectBadge() {
        if (0) {
            return <div className={styles.yourObjBadge}>Вашa дія</div>
        } else {return ''}
    }

    // тип дії
    function getActionType(a) {
        switch(a) {
            case 'requestAnswer':
                return `Запит для отримання відповіді`

            case 'intentionNotification':
                return `Повідомлення про намір`

            case 'decisionPublication':
                return `Публікація рішення`

            case 'decisionChange':
                return `Зміна рішення`

            case 'petition':
                return `Клопотання`

            case 'noAuctionNotification':
                // "Інформаційне повідомлення про передачу об’єкта оренди без проведення аукціону"
                return `Повідомлення про передачу об’єкта оренди без проведення аукціону`

            default:
                return a
        }
    }

    function getRoleType(a) {
        switch(a) {
            case 'propertyOwner':
                return `Балансоутримувач`

            case 'sellingEntity':
                return `Орендодавець`

            case 'governer':
                return `Уповноважений орган управління`

            case 'heritageController':
                return `Орган охорони культурної спадщини`

            default:
                return a
        }
    }

    function getDescription(a) {

        return (    
                <>
                    <div className={discriptExpand}>
                        {a}
                    </div>
                {a.length > 240 ? <div className={styles.objMore}
                    onClick={( ) => {
                        if (discriptExpand === styles.objDiscription) {
                            setDiscriptExpand(styles.objDiscriptionExt)
                            setMoreBtnBtn('Меньше')
                        } else {
                            setDiscriptExpand(styles.objDiscription)
                            setMoreBtnBtn('Більше')
                        }
                    }}
                >{moreBtn}</div> : null}
                </>
        )
    }

    return(
        <>
            <div className={styles.objItem}>
                <div className={styles.centralBlock}>
                    <div className={styles.topBlock}>
                        <div className={styles.objIdAndType}>
                            <div className={styles.objIdAndType_id}>
                                {/* <Link className={styles.link} to = {`/lease/actionItem/${data.id}`}>
                                    {checkProp(data, 'leaseActionId') ? data.id : null}
                                </Link> */}
                            </div>
                            {yourObjectBadge()}
                            <div className={styles.objIdAndType_type}>
                                {actionType}
                            </div>
                        </div>
                        <div className={styles.objTitle}>
                            <Link className={styles.link} to = {`/lease/actionItem/${data.id}`}>
                                Дія - <span>{data.leaseActionId}</span>
                            </Link>
                        </div>
                        { checkProp(data, 'relatedEntity') && Object.keys(data.relatedEntity).length > 0 &&  data.relatedEntity !== null ?
                            <Link className={styles.link} to={`/lease/object/${data.relatedEntity[0].id}`}>
                                <div className={styles.objType}><span>Пов'язаний об'єкт</span></div>
                                    {/* {`${data.relatedEntity[0].id}`} */}
                                </Link>
                             : null
                        }

                        { checkProp(data, 'description') ? getDescription(data.description) : null }

                    </div>
                    <div className={styles.bottomBlock}>
                        <div className={styles.objOwner}>
                            <span>Інформація про особу, що вчинила дію:</span>
                            {actingSublect.name.uk_UA}
                            <InfoModal 
                                infodata={
                                    <>
                                        <p>Роль організації, що виконує дію: {getRoleType(actingEntityRole)}</p>
                                        <p>Контактна особа: {actingSublect.contactPoint.name.uk_UA}</p>
                                        <p>{actingSublect.contactPoint.telephone}, {actingSublect.contactPoint.email}</p>
                                        <p>Область: {actingSublect.address.region.uk_UA}</p>
                                        <p>{getIdentifierType(actingSublect.identifier.scheme)}: {actingSublect.identifier.id}</p>
                                    </>
                                }
                            />
                        </div>

                    </div>
                </div>
                <div className={styles.rightBlock}>
                    {/* {setStatus(data.statusesDecisions.statusInList)} */}
                    <div className={`${styles.rightBlock_Status} ${styles.colorDark}`}>
                        Опубліковано
                    </div>
                    <div className={styles.rightBlock_Date}>
                        <div className={styles.rightBlock_Start}>
                            <div className={styles.rightBlock_date_text}>Дата публікації</div>
                            {/* {dateTransform(publishDate)} */}
                            <div className={styles.rightBlock_date_time}>{dateTransform(publishDate)}
                                <span className={styles.rightBlock_date_timeSpan}>{dateTransform(publishDate, 'onlyTime')}</span>
                            </div>
                        </div>
                        <div className={styles.rightBlock_Start}>
                            <div className={styles.rightBlock_date_text}>Остання дата зміни</div>
                            <div className={styles.rightBlock_date_time}>{dateTransform(modifDate)}
                                <span className={styles.rightBlock_date_timeSpan}>{dateTransform(modifDate, 'onlyTime')}</span>
                            </div>
                        </div>
                    </div>
                    <div className={styles.rightBlock_CostAndButtons}>
                        <div className={styles.buttonsLine}>
                            <Link className={styles.link} to = {`/lease/actionItem/${data.id}`}>
                                <div className={styles.buttonView}>Переглянути</div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div> 
        </>
        )
}

export default ActionItemCard