import React from "react";
import styles from './elems.module.css';
import FullInfoBlock from "../../../../elements/fullInfoBlock/fullInfoBlock";
import HalfInfoBlock from '../../../../elements/halfInfoBlock/halfInfoBlock';
// import HalfInfoBlock from "../../../../elements/halfInfoBlock/halfInfoBlock";
import { getCurrency, isMongo, setTypeOfBankAccount, getPaymentType } from "../../../../../utils/check";
import PopDownBlock from "../../../../elements/popdownBlock/popDownBlock";


const AnnouncementBankItem =({ data })=> {

    function setAccounts( accounts ){
        return accounts.map( acc => (
                <div className={styles.sectionGray} key={acc.id}>
                    <div style={{width: '100%', fontWeight: '600', marginBottom: '15px'}}>{ isMongo(acc.bankName) }</div>
                    <FullInfoBlock
                        title={'Опис'}
                        data={`${ isMongo(acc.description)}`}
                    />
                    { !!acc?.accountHolder?.identifier &&
                        <FullInfoBlock
                            title={'Банківські реквизити одержувача'}
                            data={`${ isMongo(acc.accountHolder.identifier.legalName)} - ${ acc.accountHolder.identifier.id } - ${ acc.accountHolder.identifier.scheme }` }
                        />
                    }
                    { !!acc?.currency &&
                        <FullInfoBlock
                            title={'Валюта'}
                            data={`${ acc.currency }` }
                        />
                    }
                    <div style={{width: '100%', fontWeight: '600', marginBottom: '15px', fontSize: '14px'}}>
                        Банківські реквізити:
                    </div>
                    {
                        acc.accountIdentifications.map( item => (
                            <div key={item.id} >
                                <FullInfoBlock
                                    title={ setTypeOfBankAccount( item.scheme ) }
                                    data={ item.id }
                                />
                                <FullInfoBlock
                                    title={ "Опис" }
                                    data={ item.description }
                                />
                            </div>
                        ))
                    }
                </div>
            )
        )
    }

    function setBancks( data ){
        return data.map(
            (i, count) => (
                <div key={count} className={styles.infoBlock}>
                    <div className={styles.infoBlockTitle}>{ getPaymentType( i.accountType ) }</div>
                    <PopDownBlock title={'Реквізити'} content={ setAccounts( i.accounts ) }/>
                </div>
            )
        )
    }

    return (<>{ setBancks(data) }</>)

}

export default AnnouncementBankItem