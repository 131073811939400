import React, {useState, useEffect} from 'react';
import styles from './buttonFilters.module.css';
import Checkbox from '../../../../inputs/checkBox';
import Button from '../../../../elements/buttons/button';
import { getLeaseRequestType } from '../../../../../utils/requests/requestsUtils';

const LeaseRequestsTypeSearch = ({ setParams, arr })=> {

    const [popup, setPopup] = useState( false )
    const [ type ] = useState( getLeaseRequestType('dict') )

        const popUp = (
                <div className={styles.wrapMain}>
                    <div className={styles.wrap}>
                        <h3 className={styles.filterTitle}>Тип заяви</h3>
                        <div className={styles.content}>
                            { type.map( item => <Checkbox 
                                    value={ ( !!arr?.type && arr.type.includes(item.value) ) ? true : false }
                                    label={ item.name }
                                    key={ item.value }
                                    onChange={ ()=> setParams( prev => {
                                        if ( !!arr?.type && arr.type.includes(item.value) ){
                                            let tmp = prev.type
                                            return {...prev, type: tmp.filter( i => i !== item.value ) }
                                        } else {
                                            if ( !!prev?.type && !!prev?.type.length === 0) {
                                                return {...prev, type: [...type, item.value] }
                                            }
                                            if ( !prev?.type) return {...prev, type: [ item.value ] }
                                            else {
                                                let tmp = prev.type
                                                tmp = [...tmp, item.value]
                                                return {...prev, type: tmp }
                                            }
                                        }
                                    } )}
                                />)
                            }
                        </div>
                        <div className={styles.bottomPart}>
                            <Button 
                                color='blue'
                                onClick={() => setPopup( prev => !prev) }>
                                Готово
                            </Button>
                        </div>
                    </div>
                </div>
            )

    return (
        <>
            <div
                className={styles.searchFilterBlock_status}
                onClick={ () => setPopup( prev => !prev) }
            >
                <div>Тип заяви</div>
                { ( !!arr?.type && arr.type.length > 0 )&& 
                    <div style={{borderLeft: '1px dotted #BFBFC6', paddingLeft: '5px', marginLeft: '5px'}}>
                        { arr.type.length }
                    </div> 
                }
            </div>
            { popup && popUp}
        </>
    )
}

export default LeaseRequestsTypeSearch
