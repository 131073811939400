import { checkProp } from "../../../utils/check"

export function getExecutionStatus(status){
    const dictionary = {
            "pending": { "uk_UA": "Очікує приватизації", "en_US": "Pending privatization" },
            "active": { "uk_UA": "Приватизація Об’єкта завершена", "en_US": "Privatization completed" },
            "sold": { "uk_UA": "Умови приватизації виконано", "en_US": "Privatization fulfilled" },
            "deleted": { "uk_UA": "Продаж скасовано", "en_US": "Privatization cancelled" }
        }
    if ( status === 'dict' ) {
        let tmpArr = []
        for (let key in dictionary ){
            let tmp = { value: key, name: dictionary[key].uk_UA }
            tmpArr = [ ...tmpArr, tmp ]
        }
        return tmpArr
    }
    if( checkProp(dictionary, status ) ) return dictionary[status].uk_UA
    if( !status ) return "Чернетка"
}

export function setExecutionStatus(status){
    switch(status) {
        case 'pending':
            return  <div style={{color: 'var(--brightBlue)'}}>
                    { getExecutionStatus(status) }
                    </div>
        case 'active':
            return <div style={{color: 'var(--red)'}}>
                    { getExecutionStatus(status) }
                    </div>
        case 'sold':
            return <div style={{color: 'var(--green)'}}>
                    { getExecutionStatus(status) }
                    </div>
        case 'deleted':
            return <div style={{color: 'var(--lightGray)'}}>
                    { getExecutionStatus(status) }
                    </div>
        default:
            return <div style={{color: 'var(--darkGray)'}}>
                        ЧЕРНЕТКА
                    </div>
    }

}

export function getExecutionContractStatus(status){
    const dictionary = {
        "active": { "uk_UA": "Актуальний", "en_US": "Actual" },
        "cancelled": { "uk_UA": "Архівний", "en_US": "Archive" },
        "pending": { "uk_UA": "Опублікований", "en_US": "Published" }
    }

    if( checkProp(dictionary, status ) ) return dictionary[status].uk_UA
    if( !status ) return "Чернетка"
}

export function getExecutionType(type){
    const dictionary = {
            "Executions": { "uk_UA": "Мала приватизація", "en_US": "Small privatization" },
            "large_Executions": { "uk_UA": "Велика приватизація", "en_US": "Large privatization" },
        }
    if ( type === 'dict' ) {
        let tmpArr = []
        for (let key in dictionary ){
            let tmp = { value: key, name: dictionary[key].uk_UA }
            tmpArr = [ ...tmpArr, tmp ]
        }
        return tmpArr
    }
    if( checkProp(dictionary, type ) ) return dictionary[type].uk_UA
    if( !type ) return "Чернетка"
}

export function getExecutionContractDocType(type){
    const dictionary = {
        "additionalAgreement": { "uk_UA": "Додаткова угода", "en_US": "Additional agreement" },
        "contractSigned": { "uk_UA": "Підписаний договір", "en_US": "Signed contract" },
        "contractNotice": { "uk_UA": "Повідомлення про договір", "en_US": "Contract notice" },
        "contractAnnexe": { "uk_UA": "Додатки до договору", "en_US": "Contract annexe" }
    }
    if ( type === 'dict' ) {
        let tmpArr = []
        for (let key in dictionary ){
            let tmp = { value: key, name: dictionary[key].uk_UA }
            tmpArr = [ ...tmpArr, tmp ]
        }
        return tmpArr
    }
    if( checkProp(dictionary, type ) ) return dictionary[type].uk_UA
    if( !type ) return type
}

export function getExecutionOrderDocType(type){
    const dictionary = {
        "order": { "uk_UA": "Наказ про приватизацію", "en_US": "Privatization order" },
        "addition": { "uk_UA": "Додаток", "en_US": "Addition" },
        "digitalSignature": { "uk_UA": "Цифровий підпис", "en_US": "Digital signature" }
    }

    if ( type === 'dict' ) {
        let tmpArr = []
        for (let key in dictionary ){
            let tmp = { value: key, name: dictionary[key].uk_UA }
            tmpArr = [ ...tmpArr, tmp ]
        }
        return tmpArr
    }
    if( checkProp(dictionary, type ) ) return dictionary[type].uk_UA
    if( !type ) return type
}

export function getExCompletionDocType(type){
    const dictionary = {
        "decontrolReason": { "uk_UA": "Підстави для зняття з контролю", "en_US": "Grounds for decontrol" },
        "addition": { "uk_UA": "Додаток", "en_US": "Addition" },
        "digitalSignature": { "uk_UA": "Цифровий підпис", "en_US": "Digital signature" }
    }

    if ( type === 'dict' ) {
        let tmpArr = []
        for (let key in dictionary ){
            let tmp = { value: key, name: dictionary[key].uk_UA }
            tmpArr = [ ...tmpArr, tmp ]
        }
        return tmpArr
    }
    if( checkProp(dictionary, type ) ) return dictionary[type].uk_UA
    if( !type ) return type
}

export function getExCancellationDocType(type){
    const dictionary = {
        "cancellationDetails": { "uk_UA": "Причини непідписання наказу", "en_US": "Cancellation details" },
        "addition": { "uk_UA": "Додаток", "en_US": "Addition" },
        "digitalSignature": { "uk_UA": "Цифровий підпис", "en_US": "Digital signature" }
    }


    if ( type === 'dict' ) {
        let tmpArr = []
        for (let key in dictionary ){
            let tmp = { value: key, name: dictionary[key].uk_UA }
            tmpArr = [ ...tmpArr, tmp ]
        }
        return tmpArr
    }
    if( checkProp(dictionary, type ) ) return dictionary[type].uk_UA
    if( !type ) return type
}