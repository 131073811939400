import {
    SET_LOADER,
    //Registries
    LEASE_REQESTS_GET,
    LEASE_ACTIONS_GET,
    LEASE_ACTION_GET_ID,
    REG_ACTIONS_COUNT_GET,
    LEASE_REQESTS_COUNT_GET,
    AUCTIONS_GET,
    AUCTIONS_GET_FLAG,
    AUCTIONS_GET_ID,
    REG_PAGINATOR_COUNT_GET,
    REG_PAGINATOR_PER_PAGE,
    OBJECT_CONTRACTS_GET,
    //SEARCH
    SET_SEARCH_URL,
    SET_SEARCH_FILTERS_COUNT,
    SET_STATUS_STRING,
    SET_SEARCH_REGISTRY_ID,
    SET_SEARCH_REGISTRY_STATUS,
    SET_DATE_FROM,
    SET_DATE_TILL,
    SET_SEARCH_REGISTRY_IDENTYFIER,
    SET_SEARCH_REGISTRY_DESC_KEYWD,
    //asset
    SET_SEARCH_ASSET_TYPE,
    SET_SEARCH_ASSET_STATUS,
    //announcements
    SET_SEARCH_ANNOUNCEMENTS_TYPE,
    SET_SEARCH_REDEMPTIONS_TYPE,
    //object
    SET_SEARCH_OBJECT_TYPE,
    SET_SEARCH_OBJECT_LIST_TYPE,
    SET_SEARCH_ACTION_TYPE,
    SET_SEARCH_REQUEST_TYPE,
    SET_SEARCH_CONTRACT_TYPE,
    SET_SEARCH_RELATED_OBJECT_ID,
    SET_SEARCH_RELATED_REQUEST_ID,
    SET_SEARCH_RELATED_ACTION_ID,
    SET_SEARCH_CONTRACT_ID,
    SET_SEARCH_REQUEST_ID,
    SET_SEARCH_ACTION_ID,
    SET_ALL_RESET,
    

    SET_ARRAY_SORT_LIST,
    // AUCTIONS_GET_ADD,
    SET_SEARCH_ARH_STATUS,
    SET_SEARCH_NEW_ARH_STATUS,
    SET_SEARCH_VIDJET_STATUS,
    SET_SEARCH_VIDJET_ORGANIZATOR_STRING,
    SET_SEARCH_VIDJET_ORGANIZATOR_ARR_GET,
    SET_SEARCH_VIDJET_ORGANIZATOR_ARR,
    SET_SEARCH_VIDJET_ORGANIZATOR_ARR_NEW,
    SET_SEARCH_VIDJET_ORGANIZATOR,
    SET_SEARCH_VIDJET_BUYERS_STRING,
    SET_SEARCH_VIDJET_BUYERS_ARR_GET,
    SET_SEARCH_VIDJET_BUYERS_ARR,
    SET_SEARCH_VIDJET_BUYERS_ARR_NEW,
    SET_SEARCH_VIDJET_BUYERS,
    SET_SEARCH_VIDJET_CLASSIFICATION_STRING,
    SET_SEARCH_VIDJET_CLASSIFICATION_ARR_GET,
    SET_SEARCH_VIDJET_CLASSIFICATION_ARR,
    SET_SEARCH_VIDJET_CLASSIFICATION_ARR_NEW,
    SET_SEARCH_VIDJET_CLASSIFICATION,
    SET_GMDN_CLASSIFICATION_ARR_GET,
    SET_GMDN_CLASSIFICATION_ARR,
    SET_GMDN_CLASSIFICATION_ARR_NEW,
    SET_DK_CLASSIFICATION_ARR_GET,
    SET_DK_CLASSIFICATION_ARR,
    SET_DK_CLASSIFICATION_ARR_NEW,
     

    SET_SEARCH_VIDJET_SIMPLE_FILTER,
    SET_SEARCH_VIDJET_SIMPLE_FILTER_REGION_OPEN,
    SET_SEARCH_VIDJET_SIMPLE_FILTER_REGION_PROC,
    SET_SEARCH_VIDJET_SIMPLE_FILTER_REGION_NEW_PROC,
    SET_SEARCH_VIDJET_SIMPLE_FILTER_TYPE_SUBJECT_PROCUREMENT,
    SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_TYPE_SUBJECT_PROCUREMENT,
    SET_SEARCH_VIDJET_TYPE_SUBJECT_PROCUREMENT_OPEN,
    SET_SEARCH_VIDJET_SIMPLE_FILTER_TYPE_PAYMENT,
    SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_TYPE_PAYMENT,
    SET_SEARCH_VIDJET_TYPE_PAYMENT_OPEN,
    SET_SEARCH_VIDJET_SIMPLE_FILTER_PROCUREMENT_METHOD_TYPE_OPEN,
    SET_SEARCH_VIDJET_SIMPLE_FILTER_PROCUREMENT_METHOD_TYPE_PROC,
    SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_PROCUREMENT_METHOD_TYPE_PROC,
    SET_SEARCH_VIDJET_SIMPLE_FILTER_VALUE_AMOUNT_FROM,
    SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_VALUE_AMOUNT_FROM,
    SET_SEARCH_VIDJET_SIMPLE_FILTER_VALUE_AMOUNT_TO,
    SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_VALUE_AMOUNT_TO,
    SET_SEARCH_VIDJET_SIMPLE_FILTER_VALUE_AMOUNT_OPEN,
    SET_SEARCH_VIDJET_SIMPLE_ENQUIRY_PERIOD_FROM,
    SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_ENQUIRY_PERIOD_FROM,
    SET_SEARCH_VIDJET_SIMPLE_FILTER_ENQUIRY_PERIOD_TO,
    SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_ENQUIRY_PERIOD_TO,
    SET_SEARCH_VIDJET_SIMPLE_FILTER_ENQUIRY_PERIOD_OPEN,
    SET_SEARCH_VIDJET_SIMPLE_AUCTION_PERIOD_FROM,
    SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_AUCTION_PERIOD_FROM,
    SET_SEARCH_VIDJET_SIMPLE_FILTER_AUCTION_PERIOD_TO,
    SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_AUCTION_PERIOD_TO,
    SET_SEARCH_VIDJET_SIMPLE_FILTER_AUCTION_PERIOD_OPEN,
    SET_SEARCH_VIDJET_SIMPLE_TENDER_PERIOD_FROM,
    SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_TENDER_PERIOD_FROM,
    SET_SEARCH_VIDJET_SIMPLE_FILTER_TENDER_PERIOD_TO,
    SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_TENDER_PERIOD_TO,
    SET_SEARCH_VIDJET_SIMPLE_FILTER_TENDER_PERIOD_OPEN,
    SET_SEARCH_VIDJET_SIMPLE_FILTER_VALUE_SUMM_FROM,
    SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_VALUE_SUMM_FROM,
    SET_SEARCH_VIDJET_SIMPLE_FILTER_VALUE_SUMM_TO,
    SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_VALUE_SUMM_TO,
    SET_SEARCH_VIDJET_SIMPLE_FILTER_VALUE_SUMM_OPEN,
    SET_SEARCH_VIDJET_SIMPLE_PLANS_TENDER_PERIOD_FROM,
    SET_SEARCH_VIDJET_SIMPLE_PLANS_NEW_TENDER_PERIOD_FROM,
    SET_SEARCH_VIDJET_SIMPLE_PLANS_TENDER_PERIOD_TO,
    SET_SEARCH_VIDJET_SIMPLE_PLANS_NEW_TENDER_PERIOD_TO,
    SET_SEARCH_VIDJET_SIMPLE_PLANS_TENDER_PERIOD_OPEN,
    SET_SEARCH_VIDJET_DJERELO_FIN_ARR,
    SET_SEARCH_VIDJET_DJERELO_FIN_ARR_NEW,
    SET_SEARCH_VIDJET_DJERELO_FIN,
    SET_SEARCH_VIDJET_ROZPORYADNUK_KOSHTIV_STRING,
    SET_SEARCH_VIDJET_ROZPORYADNUK_KOSHTIV_ARR_GET,
    SET_SEARCH_VIDJET_ROZPORYADNUK_KOSHTIV_ARR,
    SET_SEARCH_VIDJET_ROZPORYADNUK_KOSHTIV_ARR_NEW,
    SET_SEARCH_VIDJET_ROZPORYADNUK_KOSHTIV,

    SET_SEARCH_SORT_LIST_OPEN,
    SET_SEARCH_SORT_LIST_ARRAY,

    
    SET_MY_CURRENT_PAGE,
    SET_MY_CABINET_PAGE,

    SET_SINGL_PASSWORD,

    ALL_AUCTION_BIDS_ARR,
    ALL_AUCTION_BIDS_AUCTION_ID,

    WORK_MY_PAGE_CURRENT_AUCTION,
    WORK_MY__API_PAGE_NEXT_AUCTION,

    EDIT_AUCTION_FROM_ID,

    SET_END_POINT,

    UNIVERSAL_ERROR,
    
    AUCTIONS_GET_MY_ID,


    SET_KEY_INTERVAL,


    SET_POPUP_LOGIN,
    SET_SEARCH_TEXT_STRING,
    SET_SEARCH_TYPE_KEYWORD,
    SET_SEARCH_DATE_START,
    SET_SEARCH_DATE_END,
    SET_SEARCH_SORT,

    AUCTIONS_GET_SEND,

    SET_AUCTIONS_PATH,
    SET_CATEGORY_AUCTION,
    SET_PRICE_FROM,
    SET_PRICE_FROM_BAG,
    SET_PRICE_TO,
    SET_PRICE_TO_BAG,

    AUC_TEST,
    CHANGE_MEASURES,

    CHANGE_LANG,
    CHANGE_ADVANCED_FIND,
    BID_AWARDS,
    BID_QUANTITY,
    CREATE_AUCTION_TAB,

    CREATE_CHANGET_AUCTION,

    NUMBER_PAGE_LOT,

    AUCTIONS_SEARCH,

    AUCTIONS_SEARCH_MIN,
    AUCTIONS_SEARCH_COUNT,

    AUCTIONS_GET_MY,

    AUCTIONS_CURENT_PAGE,
    AUCTIONS_STATUS,

    TOKEN_POST,
    TOKEN_CLEAR,
    USER_PROFILES_GET,
    SET_PROFILE_NULL,

    SET_LOGO,
    LEASE_CONTRACTS_GET_MY,

    OBJECTS_GET_MY,
    OBJECT_GET_TYPES,
    OBJECT_GET_RESTRICTIONS,
    OBJECT_GET_INTENDED_USE,
    OBJECT_GET_LIST_TYPES,
    OBJECT_GET_STATUS_IN_LIST,
    OBJECT_GET_REGISTRATION_STATE_LIST,
    OBJECT_GET_VERIFICATION_3_LIST,
    OBJECT_GET_OWNERSHIP_TYPE,
    OBJECT_GET_UA_INDENTIFIERS_LIST,
    OBJECT_GET_ENCUMBRANCES_LIST,
    OBJECT_GET_FUEL_TYPE_LIST,
    GET_DICTIONARIES_UNIT_CODE,
    OBJECT_GET_TRANSMISSION_TYPE_LIST,
    OBJECT_GET_FLOOR_TYPE_LIST,
    OBJECT_GET_POWER_SUPPLY_CLASS_LIST,
    OBJECT_GET_CONSTRUCTION_TECHNOLOGY_LIST,
    ASSETT_GET_FLOOR_TYPE_LIST,
    SET_OBJECT_CREATION_STEP,

    ASSETT_GET_REGISTRATION_STATUS,
    DRAFT_OBJECT,
    OBJECT_CREATE,
    OBJECT_UPDATE,
    OBJECT_GET,
    OBJECT_DELETE,
    OBJECT_ADDRESS,
    SEARCH_ADDRESS,
    OBJECT_PAGE_CREATE,
    OBJECT_VALUES_CHARACTERISTICS_UPDATE,
    OBJECT_LEASE_RULES_UPDATE,
    OBJECT_STATUSES_DECISIONS_UPDATE,
    OBJECT_RELATED_ORGANIZATIONS_UPDATE,
    OBJECT_RELATED_ORGANIZATIONS_DELETE,
    INACTIVE_OBJECT_POPUP,
    OBJECT_DELETE_POPUP,

    ACTIONS_GET_MY,
    ACTIONS_GET_TYPE,
    ACTIONS_GET_ENTITY_ROLE,
    SET_ACTION_TYPE,
    ACTION_CREATE,
    ACTION_UPDATE,
    ACTION_DELETE,
    ACTION_DELETE_POPUP,
    ACTION_RELATED_OBJECTS,
    POPUP_LOCATION,

    REQUESTS_GET_MY,
    SET_POPUP_ADD_REQUEST,
    LEASE_REQUEST_GET_TYPE,
    LEASE_REQUEST_CREATE,
    LEASE_REQUEST_EDIT,
    REQUEST_GET_MY,

    //ORGANIZATIONS
    ORGANIZATION_GET,
    ORGANIZATIONS_GET_LIST,
    POPUP_CHOOSE_ORGANIZATION,
    POPUP_ADD_ORGANIZATION,
    KOATUU_FIND,
    //PROPERTY OWNER
    PROPERTY_OWNER,
    PROPERTY_OWNER_NAME,
    PROPERTY_OWNER_IDENTIFIER_IDENTIFIER,
    PROPERTY_OWNER_IDENTIFIER_SCHEME,
    PROPERTY_OWNER_IDENTIFIER_LEGAL_NAME,
    PROPERTY_OWNER_ADDRESS_IDENTIFIER,
    PROPERTY_OWNER_ADDRESS_NAME,
    PROPERTY_OWNER_ADDRESS_SCHEME,
    PROPERTY_OWNER_ADDRESS_STREET_ADDRESS,
    PROPERTY_OWNER_ADDRESS_LOCALITY,
    PROPERTY_OWNER_ADDRESS_REGION,
    PROPERTY_OWNER_ADDRESS_POSTAL_CODE,
    PROPERTY_OWNER_ADDRESS_COUNTRY,
    PROPERTY_OWNER_ADDRESS_CONTACT_NAME,
    PROPERTY_OWNER_ADDRESS_CONTACT_EMAIL,
    PROPERTY_OWNER_ADDRESS_CONTACT_TELEPHONE,
    PROPERTY_OWNER_ADDRESS_CONTACT_FAX,
    PROPERTY_OWNER_ADDRESS_CONTACT_URL,
    PROPERTY_OWNER_REPRESENTATIVE_INFO,
    //SELLING ENTITY
    SELLING_ENTITY,
    SELLING_ENTITY_NAME,
    SELLING_ENTITY_IDENTIFIER_IDENTIFIER,
    SELLING_ENTITY_IDENTIFIER_SCHEME,
    SELLING_ENTITY_IDENTIFIER_LEGAL_NAME,
    SELLING_ENTITY_ADDRESS_IDENTIFIER,
    SELLING_ENTITY_ADDRESS_NAME,
    SELLING_ENTITY_ADDRESS_SCHEME,
    SELLING_ENTITY_ADDRESS_STREET_ADDRESS,
    SELLING_ENTITY_ADDRESS_LOCALITY,
    SELLING_ENTITY_ADDRESS_REGION,
    SELLING_ENTITY_ADDRESS_POSTAL_CODE,
    SELLING_ENTITY_ADDRESS_COUNTRY,
    SELLING_ENTITY_ADDRESS_CONTACT_NAME,
    SELLING_ENTITY_ADDRESS_CONTACT_EMAIL,
    SELLING_ENTITY_ADDRESS_CONTACT_TELEPHONE,
    SELLING_ENTITY_ADDRESS_CONTACT_FAX,
    SELLING_ENTITY_ADDRESS_CONTACT_URL,
    SELLING_ENTITY_REPRESENTATIVE_INFO,
    //GOVERNER
    GOVERNER,
    GOVERNER_NAME,
    GOVERNER_IDENTIFIER_IDENTIFIER,
    GOVERNER_IDENTIFIER_SCHEME,
    GOVERNER_IDENTIFIER_LEGAL_NAME,
    GOVERNER_ADDRESS_IDENTIFIER,
    GOVERNER_ADDRESS_NAME,
    GOVERNER_ADDRESS_SCHEME,
    GOVERNER_ADDRESS_STREET_ADDRESS,
    GOVERNER_ADDRESS_LOCALITY,
    GOVERNER_ADDRESS_REGION,
    GOVERNER_ADDRESS_POSTAL_CODE,
    GOVERNER_ADDRESS_COUNTRY,
    GOVERNER_ADDRESS_CONTACT_NAME,
    GOVERNER_ADDRESS_CONTACT_EMAIL,
    GOVERNER_ADDRESS_CONTACT_TELEPHONE,
    GOVERNER_ADDRESS_CONTACT_FAX,
    GOVERNER_ADDRESS_CONTACT_URL,
    GOVERNER_REPRESENTATIVE_INFO,
    //CURRENT_TENANT
    CURRENT_TENANT,
    CURRENT_TENANT_NAME,
    CURRENT_TENANT_IDENTIFIER_IDENTIFIER,
    CURRENT_TENANT_IDENTIFIER_SCHEME,
    CURRENT_TENANT_IDENTIFIER_LEGAL_NAME,
    CURRENT_TENANT_ADDRESS_IDENTIFIER,
    CURRENT_TENANT_ADDRESS_NAME,
    CURRENT_TENANT_ADDRESS_SCHEME,
    CURRENT_TENANT_ADDRESS_STREET_ADDRESS,
    CURRENT_TENANT_ADDRESS_LOCALITY,
    CURRENT_TENANT_ADDRESS_REGION,
    CURRENT_TENANT_ADDRESS_POSTAL_CODE,
    CURRENT_TENANT_ADDRESS_COUNTRY,
    CURRENT_TENANT_ADDRESS_CONTACT_NAME,
    CURRENT_TENANT_ADDRESS_CONTACT_EMAIL,
    CURRENT_TENANT_ADDRESS_CONTACT_TELEPHONE,
    CURRENT_TENANT_ADDRESS_CONTACT_FAX,
    CURRENT_TENANT_ADDRESS_CONTACT_URL,
    CURRENT_TENANT_REPRESENTATIVE_INFO,

    //DOCUMENTS
    DOCUMENT_OF_LIST,
    POPUP_ADD_DOCUMENT,
    POPUP_UPDATE_DOCUMENT,
    POPUP_DELETE_DOCUMENT,
    ATTACH_FILE_BUTTON,
    ATTACH_FILE_BLOCK,
    DOCUMENT_TOKEN,
    DOCUMENT_DELETE,
    DOCUMENT_PATCH,
    DOCUMENTS_GET,

    OBJECT_ITEM_CREATE,
    OBJECT_ITEM_DELETE,
    OBJECT_ITEM_UPDATE,
    OBJECT_ITEM_DELETE_POPUP,
    OBJECT_ITEM_ID_UPDATE,
    OBJECT_ITEM_PAGE,
    OBJECT_ITEM_TITLE,
    OBJECT_ITEM_DESCRIPTION,
    OBJECT_ITEM_TYPE,
    OBJECT_ITEM_CLASSIFICATION_SCHEME,
    OBJECT_ITEM_CLASSIFICATION_DESCRIPTION,
    OBJECT_ITEM_CLASSIFICATION_ID,
    OBJECT_GET_CAV_LIST,
    OBJECT_ITEM_LOCATION_LATITUDE,
    OBJECT_ITEM_LOCATION_LONGITUDE,
    OBJECT_ITEM_ADDRESS_SCHEME,
    OBJECT_ITEM_ADDRESS_IDENTIFIER,
    OBJECT_ITEM_ADDRESS_NAME,
    OBJECT_ITEM_STREET_ADDRESS,
    OBJECT_ITEM_LOCALITY,
    OBJECT_ITEM_REGION,
    OBJECT_ITEM_POSTAL_CODE,

    JOINT_PROPERTY_COMPLEX_QUANTITY_AND_NOMENCLATURE,
    JOINT_PROPERTY_COMPLEX_WORKPLACES_QUANTITY,
    JOINT_PROPERTY_COMPLEX_BUILDINGS_INFORMATION,
    JOINT_PROPERTY_COMPLEX_LAND_PLOT_INFORMATION,
    JOINT_PROPERTY_COMPLEX_MAIN_OBLIGATIONS,
    JOINT_PROPERTY_COMPLEX_LEASE_APPROVER_WEBSITE,
    JOINT_PROPERTY_COMPLEX_LAND_AREA,
    JOINT_PROPERTY_COMPLEX_CADASTRAL_NUMBER,
    JOINT_PROPERTY_COMPLEX_OWNERSHIP_TYPE,
    JOINT_PROPERTY_COMPLEX_ENCUMBRANCES,
    JOINT_PROPERTY_COMPLEX_JOINT_OWNERSHIP,
    JOINT_PROPERTY_COMPLEX_UTILITIES_AVIABILITY,
    JOINT_PROPERTY_COMPLEX_LAND_INTENDED_USE,

    VEHICLE_BRAND,
    VEHICLE_MODEL,
    VEHICLE_IDENTIFICATION_NUMBER,
    VEHICLE_CONDITION,
    VEHICLE_PRODUCTION_YEAR,
    VEHICLE_ENGINE_CAPACITY,
    VEHICLE_FUEL_TYPE,
    VEHICLE_TRANSMISSION,
    VEHICLE_KILOMETRAGE,
    VEHICLE_CONFIGURATION,
    VEHICLE_DAMAGE_PRESENCE,
    VEHICLE_DAMAGE_DESCRIPTION,
    VEHICLE_COLOR,

    REAL_ESTATE_TOTAL_BUILDING_AREA,
    REAL_ESTATE_CONSTRUCTION_YEAR,
    REAL_ESTATE_TOTAL_OBJECT_AREA,
    REAL_ESTATE_USABLE_AREA,
    REAL_ESTATE_LOCATION_IN_BUILDING,
    REAL_ESTATE_FLOORS,
    REAL_ESTATE_GENERAL_CONDITION,
    REAL_ESTATE_SERVICE_ELECTRICITY,
    REAL_ESTATE_POWER_SUPPLY_CAPACITY,
    REAL_ESTATE_POWER_SUPPLY_CLASS,
    REAL_ESTATE_SERVICE_WATER,
    REAL_ESTATE_SERVICE_SEWERAGE,
    REAL_ESTATE_SERVICE_GAS,
    REAL_ESTATE_SERVICE_CENTRAL_HEATING,
    REAL_ESTATE_SERVICE_AUTONOMOUS_HEATING,
    REAL_ESTATE_SERVICE_HEATING_COUNTER,
    REAL_ESTATE_SERVICE_VENTILATION,
    REAL_ESTATE_SERVICE_AIR_CONDITIONING,
    REAL_ESTATE_SERVICE_PHONE,
    REAL_ESTATE_SERVICE_TV,
    REAL_ESTATE_SERVICE_INTERNET,
    REAL_ESTATE_SERVICE_ELEVATOR,
    REAL_ESTATE_SERVICE_SECURITY_ALARM,
    REAL_ESTATE_SERVICE_FIRE_ALARM,
    REAL_ESTATE_SERVICE_DESCRIPTION,
    REAL_ESTATE_SERVICE_ACCOUNTING,
    REAL_ESTATE_LAND_TAX,
    REAL_ESTATE_LIVING_AREA,
    REAL_ESTATE_KITCHEN_AREA,
    REAL_ESTATE_CONSTRUCTION_TECHNOLOGY,
    REAL_ESTATE_LAND_AREA,
    
} from './types';

const initialState = {
     //Registries
    leaseRequestsGet: null,
    leaseRequestsCountGet: null,
    auctionsGet: null,
    auctionsGetFlag: false,
    auctionsGetId: null,
    leaseActionsGet: null,
    leaseActionGetId: null,
    regActionsCountGet: null,
    regPaginatorCountGet: null,
    regPaginatorPerPage: 10,
    objContractsGet: null,
    //
    loader: false,


    myPage: 1,
    myCabinetPage: 0,

        search: {
        //
        //
        registry: {
            filtersCount: 0,
            descriptionKeyword: '',
            identyfier: '',
            relatedObjId: '',
            relatedRequestId: '',
            relatedActionId: '',
            objectId: '',
            dateFrom: '',
            dateTill: '',
            type: [
                { id: 'realEstate', title: "Об'єкт нерухомості", onOff: false },
                { id: 'vehicle', title: "Транспортний засіб", onOff: false },
                // { id: 'land', title: "Земельна ділянкa", onOff: false },
                { id: 'otherProperty', title: "Інше окреме індивідуально визначене майно", onOff: false },
                { id: 'jointPropertyComplex', title: "Об'єкт єдиного майнового комплексу", onOff: false },
            ],
            typeString: 'SET_SEARCH_OBJECT_TYPE',
            listType: [
                { id: 'First', title: "Перелік першого типу", onOff: false },
                { id: 'Second', title: "Перелік другого типу", onOff: false },
                { id: 'Undefined', title: "Не визначено", onOff: false },
            ],
            status:[
                { id: 'waiting', title: "Очікує включення в перелік", onOff: false },
                { id: 'approved', title: "Включено в перелік", onOff: false },
                { id: 'inactive', title: "Неактивний", onOff: false },
                { id: 'qualification', title: "Визначення орендаря", onOff: false },
                { id: 'sold', title: "Орендовано", onOff: false },
            ],
            
        },
            statusString: '',
        leaseAction: {
            id: '',
            type: [
                { id: 'requestAnswer', title: "Запит для отримання відповіді", onOff: false },
                { id: 'intentionNotification', title: "Повідомлення про намір", onOff: false },
                { id: 'decisionPublication', title: "Публікація рішення", onOff: false },
                { id: 'decisionChange', title: "Зміна рішення", onOff: false },
                { id: 'petition', title: "Клопотання", onOff: false },
                { id: 'noAuctionNotification', title: "Інформаційне повідомлення про передачу об’єкта оренди без проведення аукціону", onOff: false },
            ],
            typeString: 'SET_SEARCH_ACTION_TYPE',
        },
        leaseRequest: {
            id: '',
            type: [
                { id: 'newLease', title: "Заява про включення до переліку", onOff: false },
                { id: 'lease', title: "Заява на оренду об'єкта включеного в перелік", onOff: false },
                { id: 'agreementExtension', title: "Заява про продовження договору оренди", onOff: false },
            ],
            typeString: 'SET_SEARCH_REQUEST_TYPE',
        },
        leaseContract: {
            id: '',
            type: [
                { id: 'extension', title: "Продовження договору", onOff: false },
                { id: 'listTypeSecond', title: "Передача в оренду з переліку другого типу", onOff: false },
                { id: 'auction', title: "Передача в оренду через аукціон", onOff: false },
                { id: 'signedBeforeETS', title: "Публікація договорів оренди укладених до введення в дію переліків в ЕТС", onOff: false },
            ],
            typeString: 'SET_SEARCH_CONTRACT_TYPE',
        },
        regionProc: [
            {id: 'Автономна Республіка Крим', title: "АВТОНОМНА РЕСПУБЛІКА КРИМ/М.СІМФЕРОПОЛЬ", onOff: false},
            {id: 'Вінницька область', title: "ВІННИЦЬКА ОБЛАСТЬ/М.ВІННИЦЯ", onOff: false},
            {id: 'Волинська область', title: "ВОЛИНСЬКА ОБЛАСТЬ/М.ЛУЦЬК", onOff: false},
            {id: 'Дніпропетровська область', title: "ДНІПРОПЕТРОВСЬКА ОБЛАСТЬ/М.ДНІПРО", onOff: false},
            {id: 'Донецька область', title: "ДОНЕЦЬКА ОБЛАСТЬ/М.ДОНЕЦЬК", onOff: false},
            {id: 'Житомирська область', title: "ЖИТОМИРСЬКА ОБЛАСТЬ/М.ЖИТОМИР", onOff: false},
            {id: 'Закарпатська область', title: "ЗАКАРПАТСЬКА ОБЛАСТЬ/М.УЖГОРОД", onOff: false},
            {id: 'Запорізька область', title: "ЗАПОРІЗЬКА ОБЛАСТЬ/М.ЗАПОРІЖЖЯ", onOff: false},
            {id: 'Івано-Франківська область', title: "ІВАНО-ФРАНКІВСЬКА ОБЛАСТЬ/М.ІВАНО-ФРАНКІВСЬК", onOff: false},
            {id: 'Київська область', title: "КИЇВСЬКА ОБЛАСТЬ/М.КИЇВ", onOff: false},
            {id: 'Кіровоградська область', title: "КІРОВОГРАДСЬКА ОБЛАСТЬ/М.КРОПИВНИЦЬКИЙ", onOff: false},
            {id: 'Луганська область', title: "ЛУГАНСЬКА ОБЛАСТЬ/М.ЛУГАНСЬК", onOff: false},
            {id: 'Львівська область', title: "ЛЬВІВСЬКА ОБЛАСТЬ/М.ЛЬВІВ", onOff: false},
            {id: 'Миколаївська область', title: "МИКОЛАЇВСЬКА ОБЛАСТЬ/М.МИКОЛАЇВ", onOff: false},
            {id: 'Одеська область', title: "ОДЕСЬКА ОБЛАСТЬ/М.ОДЕСА", onOff: false},
            {id: 'Полтавська область', title: "ПОЛТАВСЬКА ОБЛАСТЬ/М.ПОЛТАВА", onOff: false},
            {id: 'Рівненська область', title: "РІВНЕНСЬКА ОБЛАСТЬ/М.РІВНЕ", onOff: false},
            {id: 'Сумська область', title: "СУМСЬКА ОБЛАСТЬ/М.СУМИ", onOff: false},
            {id: 'Тернопільська область', title: "ТЕРНОПІЛЬСЬКА ОБЛАСТЬ/М.ТЕРНОПІЛЬ", onOff: false},
            {id: 'Харківська область', title: "ХАРКІВСЬКА ОБЛАСТЬ/М.ХАРКІВ", onOff: false},
            {id: 'Херсонська область', title: "ХЕРСОНСЬКА ОБЛАСТЬ/М.ХЕРСОН", onOff: false},
            {id: 'Хмельницька область', title: "ХМЕЛЬНИЦЬКА ОБЛАСТЬ/М.ХМЕЛЬНИЦЬКИЙ", onOff: false},
            {id: 'Черкаська область', title: "ЧЕРКАСЬКА ОБЛАСТЬ/М.ЧЕРКАСИ", onOff: false},
            {id: 'Чернівецька область', title: "ЧЕРНІВЕЦЬКА ОБЛАСТЬ/М.ЧЕРНІВЦІ", onOff: false},
            {id: 'Чернігівська область', title: "ЧЕРНІГІВСЬКА ОБЛАСТЬ/М.ЧЕРНІГІВ", onOff: false},
            {id: 'Київ', title: "М.КИЇВ", onOff: false},
            {id: 'Севастополь', title: "М.СЕВАСТОПОЛЬ", onOff: false},
        ],
        regionProcNew: [
            {id: 'Автономна Республіка Крим', title: "АВТОНОМНА РЕСПУБЛІКА КРИМ/М.СІМФЕРОПОЛЬ", onOff: false},
            {id: 'Вінницька область', title: "ВІННИЦЬКА ОБЛАСТЬ/М.ВІННИЦЯ", onOff: false},
            {id: 'Волинська область', title: "ВОЛИНСЬКА ОБЛАСТЬ/М.ЛУЦЬК", onOff: false},
            {id: 'Дніпропетровська область', title: "ДНІПРОПЕТРОВСЬКА ОБЛАСТЬ/М.ДНІПРО", onOff: false},
            {id: 'Донецька область', title: "ДОНЕЦЬКА ОБЛАСТЬ/М.ДОНЕЦЬК", onOff: false},
            {id: 'Житомирська область', title: "ЖИТОМИРСЬКА ОБЛАСТЬ/М.ЖИТОМИР", onOff: false},
            {id: 'Закарпатська область', title: "ЗАКАРПАТСЬКА ОБЛАСТЬ/М.УЖГОРОД", onOff: false},
            {id: 'Запорізька область', title: "ЗАПОРІЗЬКА ОБЛАСТЬ/М.ЗАПОРІЖЖЯ", onOff: false},
            {id: 'Івано-Франківська область', title: "ІВАНО-ФРАНКІВСЬКА ОБЛАСТЬ/М.ІВАНО-ФРАНКІВСЬК", onOff: false},
            {id: 'Київська область', title: "КИЇВСЬКА ОБЛАСТЬ/М.КИЇВ", onOff: false},
            {id: 'Кіровоградська область', title: "КІРОВОГРАДСЬКА ОБЛАСТЬ/М.КРОПИВНИЦЬКИЙ", onOff: false},
            {id: 'Луганська область', title: "ЛУГАНСЬКА ОБЛАСТЬ/М.ЛУГАНСЬК", onOff: false},
            {id: 'Львівська область', title: "ЛЬВІВСЬКА ОБЛАСТЬ/М.ЛЬВІВ", onOff: false},
            {id: 'Миколаївська область', title: "МИКОЛАЇВСЬКА ОБЛАСТЬ/М.МИКОЛАЇВ", onOff: false},
            {id: 'Одеська область', title: "ОДЕСЬКА ОБЛАСТЬ/М.ОДЕСА", onOff: false},
            {id: 'Полтавська область', title: "ПОЛТАВСЬКА ОБЛАСТЬ/М.ПОЛТАВА", onOff: false},
            {id: 'Рівненська область', title: "РІВНЕНСЬКА ОБЛАСТЬ/М.РІВНЕ", onOff: false},
            {id: 'Сумська область', title: "СУМСЬКА ОБЛАСТЬ/М.СУМИ", onOff: false},
            {id: 'Тернопільська область', title: "ТЕРНОПІЛЬСЬКА ОБЛАСТЬ/М.ТЕРНОПІЛЬ", onOff: false},
            {id: 'Харківська область', title: "ХАРКІВСЬКА ОБЛАСТЬ/М.ХАРКІВ", onOff: false},
            {id: 'Херсонська область', title: "ХЕРСОНСЬКА ОБЛАСТЬ/М.ХЕРСОН", onOff: false},
            {id: 'Хмельницька область', title: "ХМЕЛЬНИЦЬКА ОБЛАСТЬ/М.ХМЕЛЬНИЦЬКИЙ", onOff: false},
            {id: 'Черкаська область', title: "ЧЕРКАСЬКА ОБЛАСТЬ/М.ЧЕРКАСИ", onOff: false},
            {id: 'Чернівецька область', title: "ЧЕРНІВЕЦЬКА ОБЛАСТЬ/М.ЧЕРНІВЦІ", onOff: false},
            {id: 'Чернігівська область', title: "ЧЕРНІГІВСЬКА ОБЛАСТЬ/М.ЧЕРНІГІВ", onOff: false},
            {id: 'Київ', title: "М.КИЇВ", onOff: false},
            {id: 'Севастополь', title: "М.СЕВАСТОПОЛЬ", onOff: false},
        ],
        procurementMethodTypeProc: [
            {id: 'aboveThresholdUA', title: "Відкриті торги", onOff: false},
            {id: 'esco', title: "Відкриті торги для закупівлі енергоресурсу", onOff: false},
            {id: 'aboveThresholdEU', title: "Відкриті торги з публікацією англійською мовою", onOff: false},
            {id: 'reporting', title: "Закупівля без використання електронної системи", onOff: false},
            {id: 'reporting', title: "Закупівлі по COVID-19", onOff: false},
            {id: 'priceQuotation', title: "Запит ціни пропозиції", onOff: false},
            {id: 'competitiveDialogueUA', title: "Конкурентний діалог 1-ий етап", onOff: false},
            {id: 'competitiveDialogueEU', title: "Конкурентний діалог з публікацією англійською мовою 1-ий етап", onOff: false},
            {id: 'competitiveDialogueUA.stage2', title: "Конкурентний діалог 2-ий етап", onOff: false},
            {id: 'competitiveDialogueEU.stage2', title: "Конкурентний діалог з публікацією англійською мовою 2-ий етап", onOff: false},
            {id: 'negotiation', title: "Переговорна процедура", onOff: false},
            {id: 'negotiation.quick', title: "Переговорна процедура (скорочена)", onOff: false},
            {id: 'aboveThresholdUA.defense', title: "Переговорна процедура для потреб оборони", onOff: false},
            {id: 'belowThreshold', title: "Спрощені закупівлі", onOff: false},
            {id: 'belowThreshold', title: "Спрощені / Допорогові закупівлі", onOff: false},
            {id: 'simple.defense', title: "Спрощені торги із застосуванням електронної системи", onOff: false},
            {id: 'closeFrameworkAgreementUA', title: "Укладання рамкової угоди", onOff: false},
            {id: 'reporting', title: "Prozorro Market", onOff: false},
            {id: 'closeFrameworkAgreementSelectionUA', title: "Відбір для закупівлі за рамковою угодою", onOff: false},
        ],
        searchVidjetSimpleFilterProcurementMethodTypeOpen: false,
        
        filterValueAmountFrom: '',
        filterValueAmountFromNew: '',
        filterValueAmountTo: '',
        filterValueAmountToNew: '',
        searchVidjetSimpleFilterValueAmountOpen: false,

        enquiryPeriodTo: '',
        enquiryPeriodToNew: '',
        enquiryPeriodFrom: '',
        enquiryPeriodFromNew: '',
        searchVidjetSimpleFilterEnquiryPeriodOpen: false,

        auctionPeriodTo: '',
        auctionPeriodToNew: '',
        auctionPeriodFrom: '',
        auctionPeriodFromNew: '',
        searchVidjetSimpleFilterAuctionPeriodOpen: false,

        tenderPeriodTo: '',
        tenderPeriodToNew: '',
        tenderPeriodFrom: '',
        tenderPeriodFromNew: '',
        searchVidjetSimpleFilterTenderPeriodOpen: false,

        filterValueSummFrom: '',
        filterValueSummFromNew: '',
        filterValueSummTo: '',
        filterValueSummToNew: '',
        searchVidjetSimpleFilterValueSummOpen: false,

        plansTenderPeriodTo: '',
        plansTenderPeriodToNew: '',
        plansTenderPeriodFrom: '',
        plansTenderPeriodFromNew: '',
        plansTenderPeriodOpen: false,

        openSortList: false,
        /*sortListArr: [
            {name: 'Спочатку найновіші створені', status: true, findParamName: 'date', findSortStatus: 'DESC', flagTend: true, flagPlan: true, flagDog: true},
            {name: 'Спочатку найстарші створені', status: false, findParamName: 'date', findSortStatus: 'ASC', flagTend: true, flagPlan: true, flagDog: true},
            {name: 'Спочатку найнижча очікувана вартість', status: false, findParamName: 'value.amount', findSortStatus: 'DESC', flagTend: true, flagPlan: true, flagDog:false},
            {name: 'Спочатку найвища очікувана вартість', status: false, findParamName: 'value.amount', findSortStatus: 'ASC', flagTend: true, flagPlan: true, flagDog: false},
            {name: 'Спочатку найближчі аукціони', status: false, findParamName: 'auctionPeriod.startDate', findSortStatus: 'DESC', flagTend: true, flagPlan: false, flagDog: false},
            {name: 'Спочатку найпізніші аукціони', status: false, findParamName: 'auctionPeriod.startDate', findSortStatus: 'ASC', flagTend: true, flagPlan: false, flagDog: false},
            {name: 'Спочатку найнижча сума договору', status: false, findParamName: 'value.amount', findSortStatus: 'DESC', flagTend: false, flagPlan: false, flagDog: true},
            {name: 'Спочатку найвища сума договору', status: false, findParamName: 'value.amount', findSortStatus: 'ASC', flagTend: false, flagPlan: false, flagDog: true},
        ],*/
        sortListArr: null,

        openDjereloFin: false,
        djerelFinArr: [
            {id: 'state', name: 'Державний бюджет України', status: false},
            {id: 'crimea', name: 'Бюджет Автономної Республіки Крим', status: false},
            {id: 'local', name: 'Місцевий бюджет', status: false},
            {id: 'own', name: 'Власний бюджет (кошти від господарської діяльності підприємства)', status: false},
            {id: 'fund', name: 'Бюджет цільових фондів', status: false},
            {id: 'loan', name: 'Кредити та позики міжнародних валютно-кредитних організацій', status: false},
            {id: 'other', name: 'Інше', status: false},
        ],
        djerelFinArrNew: [
            {id: 'state', name: 'Державний бюджет України', status: false},
            {id: 'crimea', name: 'Бюджет Автономної Республіки Крим', status: false},
            {id: 'local', name: 'Місцевий бюджет', status: false},
            {id: 'own', name: 'Власний бюджет (кошти від господарської діяльності підприємства)', status: false},
            {id: 'fund', name: 'Бюджет цільових фондів', status: false},
            {id: 'loan', name: 'Кредити та позики міжнародних валютно-кредитних організацій', status: false},
            {id: 'other', name: 'Інше', status: false},
        ],

        rozporyadnukKoshtivSearchString: '',
        rozporyadnukKoshtivArrGet: null,
        rozporyadnukKoshtivArr: [],
        rozporyadnukKoshtivArrNew: [],
        rozporyadnukKoshtiv: false,

        url: 'registry_objects',
        order: "asc",
        //orderBy: "string",
        orderBy: null, //по какому полю сортировать для верхнего поиска
        authorName: null,
        auctionId: null,
        status: 'null',
        //status: null,
        region: null, //Регион
        //category: "string",
        category: null,//'timberEnglish', 
        classification: null,
        priceCurrency: null,
        //priceCurrency: null,
        //priceFrom: 0,
        priceFrom: null,
        priceFromBag: false,
        //priceTo: 0,
        priceTo: null,
        priceToBag: false,
        //measures: "string",
        measures: null, //одиници вимыру
        //measuresFrom: 0,
        measuresFrom: null,
        //measuresTo: 0
        measuresTo: null,
        textSting: '',
        selectTypeKeyword: null,
        startDateFrom: null,
        startDateTo: null,


        /*"order": "asc",       сортировка
        "orderBy": "string",    любой критерий по которому ищем в базовый поиск
        "keyword": "string",    по словам по которому ищем в базовый поиск
        "authorName": "string", по создателю по которому ищем в базовый поиск
        "auctionId": "string",  по айди аукциона по которому ищем в базовый поиск
        "itemId": "string",     по айди итема по которому ищем в базовый поиск
        "region": "string",     по региону --- не пашет
        "status": "active_rectification",   по статусу
        "category": "string",   по категории
        "priceCurrency": "UAH", валюта
        "priceFrom": 0,         мин цена
        "priceTo": 0,           макс цена
        "measures": "string",   ед.измерения
        "measuresFrom": 0,      ед.измерения от
        "measuresTo": 0,        ед.измерения до
        "startDateFrom": "string",  дата начала
        "startDateTo": "string",    дата окончания
        "classification": "string",     классификатор
        "additionalClassifications": [  доп классификатор
            "string"
        ]*/
    },
    setMask: '',

    workMyAuction: {
        pageCurrentAuction: 0,
        pageNextAuction: 0,
    },

    deleteDateCancelAuction: null,
    universalError: null,
    
    carryType: null,
    contactGet: null,
    contactsGet: null,
    contractAuctionIdGet: null,
    contractGet: null,
   
    // Documents
    docGet: null,
    documentGet: null,
    documentDelete: null,
    documentPatch: null,
    documentsGet: null,
    documentOfList: null,
    documentUploadProzoro: null,
    popupAddDocument: false,
    popupUpdateDocument: null,
    popupDeleteDocument: null,
    attachFileButton: true,
    attachFileBlock: false,
    documentToken: null,

    identifierGet: null,
    identifiersGet: null,
    keySetIntervalRefresh: null,
    langFlag: 0,
    
    logo: null,
    lots: null,
    maxCostTender: 0,
    menuOnOff: false,
    minCostTender: 0,
    mobileMenu: false,
    oldArrDocument: null,
    oldUserGet: null,
    oldUsersGet: null,
    pageNumber: null,
    popUpAuctionCreate: null,
    popUpAuctionError: null,
    popUpAwardApprowe: null,
    popUpAwardError: null,
    popUpAwardReject: null,
    popUpBankAccount: false,
    popUpBankAccountComplite: false,
    popUpBankAccounErrort: null,
    popUpBankAccountSheme: null,
    popUpBidError: null,
    popUpContractApprowe: null,
    popUpContractError: null,
    popUpDelAuc: null,
    popUpSendAuc: null,
    popUpStatus: {
        login: false,
    },
    procuringEntitiesGet: null,
    procuringEntityGet: null,

    searchCount: {
        count: null,
        countOld: null,
        curentPage: 0,
    },
    searchElemOn: null,
    token: null,
    singlPassword: null,
    unitChange: '',
    unitGet: null,
    unitsGet: null,
    userProfiles: null,

    //Organizations
    organizationGet: null,
    organizationsGetList: null, // not delete
    sellingEntity: null,
    popupChooseOrganization: false,
    popupAddOrganization: false,
    koatuuList: null,
    propertyOwner: null,
    governer: null,
    currentTenant: null,
    currentTenants: null,
    myLeaseContracts: null,

    //Object creation
    myObjectsList: null,
    objectTypesList: [
        {name: "jointPropertyComplex",
            description: {
                en_US: "Joint property complex",
                uk_UA: "ЄМК"
            },
            applience: null
        },
        {name: "otherProperty",
            description: {
                en_US: "Other property",
                uk_UA: "Інше окреме індивідуально визначене майно"
            },
            applience: null
        },
        {name: "realEstate",
            description: {
                en_US: "Real estate",
                uk_UA: "Нерухоме майно"
            },
            applience: null
        },
        {name: "vehicle",
            description: {
                en_US: "Vehicle",
                uk_UA: "Транспортний засіб"
            },
            applience: null
        }
    ],
    objectRestrictionsList: null,
    objectIntendedUseList: null,
    objectListTypesList: null,
    objectStatusInList: null,
    objectRegistrationStateList: null,
    objectVerification3List: null,
    objectOwnershipTypeList: null,
    objectUaIdentifiersList: null,
    objectEncumbrancesList: null,
    objectFuelTypeList: null,
    dictionaryUnitCode: null,
    objectTransmissionTypeList: null,
    objectFloorTypeList: null,
    objectPowerSupplyClassList: null,
    objectConstructionTechnologyList: null,
    objectCreationStep: 0,

    assetRegistrationStatus: null,
    // floorTypeList: null,

    draftObject: null,
    popupLocation: false,
    objectCreate: null,
    objectUpdate: null,
    objectGet: null,
    objectDelete: null,
    objectAddress: {
        region: null,
        address: null,
        postcode: null,
        lat: 50.43954227525455,
        lng: 30.48697302566131,
    },
    regionList: [
            {value: 'Автономна Республіка Крим', name: "АВТОНОМНА РЕСПУБЛІКА КРИМ/М.СІМФЕРОПОЛЬ"},
            {value: 'Вінницька область', name: "ВІННИЦЬКА ОБЛАСТЬ/М.ВІННИЦЯ"},
            {value: 'Волинська область', name: "ВОЛИНСЬКА ОБЛАСТЬ/М.ЛУЦЬК"},
            {value: 'Дніпропетровська область', name: "ДНІПРОПЕТРОВСЬКА ОБЛАСТЬ/М.ДНІПРО"},
            {value: 'Донецька область', name: "ДОНЕЦЬКА ОБЛАСТЬ/М.ДОНЕЦЬК"},
            {value: 'Житомирська область', name: "ЖИТОМИРСЬКА ОБЛАСТЬ/М.ЖИТОМИР"},
            {value: 'Закарпатська область', name: "ЗАКАРПАТСЬКА ОБЛАСТЬ/М.УЖГОРОД"},
            {value: 'Запорізька область', name: "ЗАПОРІЗЬКА ОБЛАСТЬ/М.ЗАПОРІЖЖЯ"},
            {value: 'Івано-Франківська область', name: "ІВАНО-ФРАНКІВСЬКА ОБЛАСТЬ/М.ІВАНО-ФРАНКІВСЬК"},
            {value: 'Київська область', name: "КИЇВСЬКА ОБЛАСТЬ/М.КИЇВ"},
            {value: 'Кіровоградська область', name: "КІРОВОГРАДСЬКА ОБЛАСТЬ/М.КРОПИВНИЦЬКИЙ"},
            {value: 'Луганська область', name: "ЛУГАНСЬКА ОБЛАСТЬ/М.ЛУГАНСЬК"},
            {value: 'Львівська область', name: "ЛЬВІВСЬКА ОБЛАСТЬ/М.ЛЬВІВ"},
            {value: 'Миколаївська область', name: "МИКОЛАЇВСЬКА ОБЛАСТЬ/М.МИКОЛАЇВ"},
            {value: 'Одеська область', name: "ОДЕСЬКА ОБЛАСТЬ/М.ОДЕСА"},
            {value: 'Полтавська область', name: "ПОЛТАВСЬКА ОБЛАСТЬ/М.ПОЛТАВА"},
            {value: 'Рівненська область', name: "РІВНЕНСЬКА ОБЛАСТЬ/М.РІВНЕ"},
            {value: 'Сумська область', name: "СУМСЬКА ОБЛАСТЬ/М.СУМИ"},
            {value: 'Тернопільська область', name: "ТЕРНОПІЛЬСЬКА ОБЛАСТЬ/М.ТЕРНОПІЛЬ"},
            {value: 'Харківська область', name: "ХАРКІВСЬКА ОБЛАСТЬ/М.ХАРКІВ"},
            {value: 'Херсонська область', name: "ХЕРСОНСЬКА ОБЛАСТЬ/М.ХЕРСОН"},
            {value: 'Хмельницька область', name: "ХМЕЛЬНИЦЬКА ОБЛАСТЬ/М.ХМЕЛЬНИЦЬКИЙ"},
            {value: 'Черкаська область', name: "ЧЕРКАСЬКА ОБЛАСТЬ/М.ЧЕРКАСИ"},
            {value: 'Чернівецька область', name: "ЧЕРНІВЕЦЬКА ОБЛАСТЬ/М.ЧЕРНІВЦІ"},
            {value: 'Чернігівська область', name: "ЧЕРНІГІВСЬКА ОБЛАСТЬ/М.ЧЕРНІГІВ"},
            {value: 'Київ', name: "М.КИЇВ"},
            {value: 'Севастополь', name: "М.СЕВАСТОПОЛЬ"},
        ],
    addressSearch: null,
    objectPage: 'create',
    objectItemPage: 'create',
    objectValuesCharacteristicsUpdate: null,
    leaseRulesUpdate: null,
    objectStatusesDecisionsUpdate: null,
    objectRelatedOrganizationsUpdate: null,
    objectRelatedOrganizationsDelete: null,
    inactiveObjectPopup: false,
    objectDeletePopup: false,
    objectCavList: null,
    startObject: {
        title: null,
        type: null,
        registryDecision: {
            xVerificationDocuments: null,
            verificationPresence: false
        },
        statusesDecisions: {
            listType: null,
            statusInList: null,
            registrationState: null,
            registrationRequisites: null,
            culturalHeritage: false,
            culturalHeritageCondition: null,
            propertyOwnerApproval: {
                xVerificationDocuments: null,
                verificationPresence: false
            },
            sellingEntityApproval: {
                xVerificationDocuments: null,
                verificationPresence: false
            },
            governingEntityApproval: {
                xVerificationDocuments: null,
                verificationPresence: false
            },
            maintenanceRepairAgreement: {
                xVerificationDocuments: null,
                verificationPresence: false
            },
            privatizationDecision: {
                xVerificationDocuments: null,
                verificationPresence: false
            },
            investmentDecision: {
                xVerificationDocuments: null,
                verificationPresence: false
            },
            heritageApproval: {
                xVerificationDocuments: null,
                verificationPresence: false
            }
        },
        leaseRules: {
            schedule: {
                hoursBy: {
                    by: null,
                    value: null
                },
                daysBy: {
                    by: null,
                    value: null
                },
                other: null,
                periods: []
            },
            leaseDuration: null,
            intendedUseRestrictionMethod: null,
            intendedUseRestrictionDescription: null,
            additionalLeaseConditions: null,
            subleaseAllowed: false
        },
        valuesCharacteristics: {
            initialBookValue: {
                currency: 'UAH',
                amount: null,
                valueAddedTaxIncluded: false
            },
            residualBookValue: {
                currency: 'UAH',
                amount: null,
                valueAddedTaxIncluded: false
            },
            marketValue: {
                currency: 'UAH',
                amount: null,
                valueAddedTaxIncluded: false
            },
            marketValueValuationDate: null,
            marketValueValuationDetails: null,
            secondListMethodology: null,
            secondListAbsoluteValue: {
                currency: 'UAH',
                amount: null,
                valueAddedTaxIncluded: false
            },
            secondListPercentValue: null,
            secondListDecisionDescription: null
        }
    },
    objectItemUpdate: null,
    objectItemCreate: {
        itemType: "",
        basicInfo: {
            title: {
                uk_UA: "",
            },
            description: {
                uk_UA: "",
            },
            classification: {
                scheme: "",
                description: "",
                id: ""
            },
        },
        jpcProps: {
            quantityAndNomenclature: "",
            workplacesQuantity: "",
            buildingsInformation: "",
            landPlotInformation: "",
            mainObligations: "",
            landProps: {
                landArea: "",
                cadastralNumber: "",
                ownershipType: "",
                encumbrances: "",
                jointOwnership: "",
                utilitiesAvailability: "",
                landIntendedUse: ""
            },
            leaseApproverWebsite: ""
        },
        vehicleProps: {
            brand: null,
            model: null,
            identificationNumber: null,
            condition: null,
            productionYear: null,
            engineCapacity: null,
            fuelType: null,
            transmission: null,
            kilometrage: null,
            configuration: null,
            damagePresence: false,
            damagedDescription: null,
            color: null
        },
        reProps: {
            totalBuildingArea: null,
            constructionYear: null,
            totalObjectArea: null,
            usableArea: null,
            locationInBuilding: null,
            floors: null,
            generalCondition: null,
            serviceElectricity: false,
            serviceWater: false,
            serviceSewerage: false,
            serviceGas: false,
            serviceCentralHeating: false,
            serviceAutonomousHeating: false,
            serviceHeatingCounter: false,
            serviceVentilation: false,
            serviceAirConditioning: false,
            servicePhone: false,
            serviceTV: false,
            serviceInternet: false,
            serviceElevator: false,
            serviceSecurityAlarm: false,
            serviceFireAlarm: false,
            servicesDescription: null,
            servicesAccounting: null,
            landTax: null,
            livingArea: null,
            kitchenArea: null,
            constructionTechnology: null,
            landArea: null
        },
        placing: {
            location: {
                latitude: "",
                longitude: "",
            },
            address: {
                addressIdentifier: {
                    scheme: "koatuu",
                    identifier: "",
                    name: ""
                },
                streetAddress: "",
                locality: "",
                region: "",
                postalCode: "",
                countryName: { uk_UA: "Україна" }
            }
        },
    },
    objectItemDeletePopup: false,
    objectItemIdUpdate: null,

    //Actions creation
    myActionsList: null,
    actionTypesList: null,
    actionEntityRolesList: null,
    setActionType: null,
    actionCreate: null,
    actionUpdate: null,
    actionDelete: null,
    actionDeletePopup: false,
    actionRelatedObjects: null,

    //Lease request creation
    myRequestsList: null,
    popupAddLeaseRequest: false,
    requestTypesList: null,
    requestCreate: null,
    requestEdit: null,
    myRequest: null,
}

export const startReducer = (state = initialState, action) => {
    switch (action.type) {
        case TOKEN_POST:
            localStorage.setItem('token', JSON.stringify(action.payload))
            return { ...state, token: action.payload, auctionsGetFlag: false }
        case TOKEN_CLEAR:
            let tmpClearToken = {
                access_token: "",
                expires_in: 3600,
                refresh_token: "",
                timeStamp: 0,
                token_type: "",
            }
            localStorage.setItem('token', JSON.stringify(tmpClearToken))
            return { ...state, token: action.payload }
        case SET_KEY_INTERVAL:
            return { ...state, keySetIntervalRefresh: action.payload }
        case USER_PROFILES_GET:
            return { ...state, userProfiles: action.payload }
        case SET_PROFILE_NULL:
            return { ...state, userProfiles: null}
        case SET_SINGL_PASSWORD:
            return { ...state, singlPassword: action.payload }

        case SET_LOADER:
            return { ...state, loader: action.payload }

        case AUCTIONS_GET:
            return { ...state, auctionsGet: action.payload }
        
        case AUCTIONS_GET_ID:
            // if(action.payload.hasOwnProperty('message')){
            //     return { ...state, auctionsGetIdNotFound: true}
            // }else{
            return { ...state, auctionsGetId: action.payload }
        // }
        
        case LEASE_REQESTS_GET:
            return { ...state, leaseRequestsGet: action.payload }
        
        case LEASE_ACTIONS_GET:
            return { ...state, leaseActionsGet: action.payload }

        case LEASE_ACTION_GET_ID:
            return { ...state, leaseActionGetId: action.payload }
        
        case REG_ACTIONS_COUNT_GET:
            return { ...state, regActionsCountGet: action.payload }
        
        case LEASE_REQESTS_COUNT_GET:
            return { ...state, leaseRequestsCountGet: action.payload }
        
        case REG_PAGINATOR_COUNT_GET:
            return { ...state, regPaginatorCountGet: action.payload }
        
        case REG_PAGINATOR_PER_PAGE:
            return { ...state, regPaginatorPerPage: action.payload }
        
        case OBJECT_CONTRACTS_GET:
            return { ...state, objContractsGet: action.payload }
        
        // SEARCH
        
        case SET_SEARCH_FILTERS_COUNT:
            return { ...state, search: { ...state.search, registry: { ...state.search.registry, filtersCount: action.payload } } }
        
        case SET_DATE_FROM:
            return { ...state, search: { ...state.search, registry: { ...state.search.registry, dateFrom: action.payload } } }
        
        case SET_DATE_TILL:
            return { ...state, search: { ...state.search, registry: { ...state.search.registry, dateTill: action.payload } } }
        
        case SET_STATUS_STRING:
            return { ...state, search: {...state.search, statusString: action.payload } }
        
        
        case SET_SEARCH_REGISTRY_ID:
            return { ...state, search: { ...state.search, registry: { ...state.search.registry, objectId: action.payload } } }
        
        case SET_SEARCH_REGISTRY_STATUS:
            return { ...state, search: { ...state.search, registry: { ...state.search.registry, status: action.payload } } }
        
        case SET_SEARCH_REGISTRY_IDENTYFIER:
            return { ...state, search: { ...state.search, registry: { ...state.search.registry, identyfier: action.payload } } }
        
        case SET_SEARCH_REGISTRY_DESC_KEYWD:
            return { ...state, search: { ...state.search, registry: { ...state.search.registry, descriptionKeyword: action.payload } } }


        
        case SET_SEARCH_ASSET_TYPE:
            return { ...state, search: { ...state.search, assets: { ...state.search.assets, type: action.payload } } }

        case SET_SEARCH_ASSET_STATUS:
            return { ...state, search: { ...state.search, assets: { ...state.search.assets, status: action.payload } } }

        case SET_SEARCH_ANNOUNCEMENTS_TYPE:
            return { ...state, search: { ...state.search, announcements: { ...state.search.announcements, type: action.payload } } }

        case SET_SEARCH_REDEMPTIONS_TYPE:
            return { ...state, search: { ...state.search, redemptions: { ...state.search.redemptions, type: action.payload } } }


        
        case SET_SEARCH_ACTION_TYPE:
            return { ...state, search: { ...state.search, leaseAction: { ...state.search.leaseAction, type: action.payload } } }
        
        case SET_SEARCH_REQUEST_TYPE:
            return { ...state, search: { ...state.search, leaseRequest: { ...state.search.leaseRequest, type: action.payload } } }
        
        case SET_SEARCH_CONTRACT_TYPE:
            return { ...state, search: { ...state.search, leaseContract: { ...state.search.leaseContract, type: action.payload } } }
                
        case SET_SEARCH_ACTION_ID:
            return { ...state, search: { ...state.search, leaseAction: { ...state.search.leaseAction, id: action.payload } } }
        
        case SET_SEARCH_OBJECT_TYPE:
            return { ...state, search: { ...state.search, registry: { ...state.search.registry, type: action.payload } } }
        
        case SET_SEARCH_OBJECT_LIST_TYPE:
            return { ...state, search: { ...state.search, registry: { ...state.search.registry, listType: action.payload } } }
        
        case SET_SEARCH_RELATED_OBJECT_ID:
            return { ...state, search: { ...state.search, registry: { ...state.search.registry, relatedObjId: action.payload } } }
        
        case SET_SEARCH_RELATED_REQUEST_ID:
            return { ...state, search: { ...state.search, registry: { ...state.search.registry, relatedRequestId: action.payload } } }
        
        case SET_SEARCH_RELATED_ACTION_ID:
            return { ...state, search: { ...state.search, registry: { ...state.search.registry, relatedActionId: action.payload } } }

        case SET_SEARCH_REQUEST_ID:
            return { ...state, search: { ...state.search, leaseRequest: { ...state.search.leaseRequest, id: action.payload } } }
        
        case SET_SEARCH_CONTRACT_ID:
            return { ...state, search: { ...state.search, leaseContract: { ...state.search.leaseContract, id: action.payload } } }
        
        case SET_ALL_RESET:
            return { ...state, search: action.payload }
        
        
        
        case AUCTIONS_GET_SEND:
            //return { ...state,  logo: action.payload}
            break

        case SET_MY_CABINET_PAGE:
            return { ...state, myCabinetPage: action.payload}
        case SET_MY_CURRENT_PAGE:
            return { ...state, myPage: action.payload}

        case SET_END_POINT:
            return { ...state, endPoint: action.payload}
        case ALL_AUCTION_BIDS_ARR:
            return { ...state, auctionsAllBids: {...state.auctionsAllBids, arr: action.payload}}
        case ALL_AUCTION_BIDS_AUCTION_ID:
            return { ...state, auctionsAllBids: {...state.auctionsAllBids, auctionId: action.payload}}
        case WORK_MY_PAGE_CURRENT_AUCTION:
            return { ...state, workMyAuction: {...state.workMyAuction, pageCurrentAuction: action.payload}}
        case WORK_MY__API_PAGE_NEXT_AUCTION:
            return { ...state, workMyAuction: {...state.workMyAuction, pageNextAuction: action.payload}}

        case UNIVERSAL_ERROR:
            return { ...state, universalError: action.payload}

        case SET_LOGO:
            return { ...state,  logo: action.payload}
        
        case SET_POPUP_LOGIN:
            return { ...state,  popUpStatus: {...state.popUpStatus, login: action.payload}}


        case SET_SEARCH_URL:
            return { ...state,  search: {...state.search, url: action.payload}}
        case SET_SEARCH_VIDJET_STATUS:
            return { ...state,  search: {...state.search, searchVidjetStatus: action.payload}}


        case SET_SEARCH_VIDJET_ORGANIZATOR_STRING:
            return { ...state,  search: {...state.search, searchVidjetOrganizatorSearchString: action.payload}}
        case SET_SEARCH_VIDJET_ORGANIZATOR_ARR_GET:
            return { ...state,  search: {...state.search, searchVidjetOrganizatorArrGet: action.payload}}
        case SET_SEARCH_VIDJET_ORGANIZATOR_ARR:
            return { ...state,  search: {...state.search, searchVidjetOrganizatorArr: action.payload}}
        case SET_SEARCH_VIDJET_ORGANIZATOR_ARR_NEW:
            return { ...state,  search: {...state.search, searchVidjetOrganizatorArrNew: action.payload}}
        case SET_SEARCH_VIDJET_ORGANIZATOR:
            return { ...state,  search: {...state.search, searchVidjetOrganizator: action.payload}}
        case SET_SEARCH_VIDJET_BUYERS_STRING:
            return { ...state,  search: {...state.search, searchVidjetBuyersSearchString: action.payload}}
        case SET_SEARCH_VIDJET_BUYERS_ARR_GET:
            return { ...state,  search: {...state.search, searchVidjetBuyersArrGet: action.payload}}
        case SET_SEARCH_VIDJET_BUYERS_ARR:
            return { ...state,  search: {...state.search, searchVidjetBuyersArr: action.payload}}
        case SET_SEARCH_VIDJET_BUYERS_ARR_NEW:
            return { ...state,  search: {...state.search, searchVidjetBuyersArrNew: action.payload}}
        case SET_SEARCH_VIDJET_BUYERS:
            return { ...state,  search: {...state.search, searchVidjetBuyers: action.payload}}





        case SET_SEARCH_VIDJET_CLASSIFICATION_STRING:
            return { ...state,  search: {...state.search, searchVidjetClassificationSearchString: action.payload}}
        case SET_SEARCH_VIDJET_CLASSIFICATION_ARR_GET:
            return { ...state,  search: {...state.search, searchVidjetClassificationArrGet: action.payload}}
        case SET_SEARCH_VIDJET_CLASSIFICATION_ARR:
            return { ...state,  search: {...state.search, searchVidjetClassificationArr: action.payload}}
        case SET_SEARCH_VIDJET_CLASSIFICATION_ARR_NEW:
            return { ...state,  search: {...state.search, searchVidjetClassificationArrNew: action.payload}}
        case SET_SEARCH_VIDJET_CLASSIFICATION:
            return { ...state,  search: {...state.search, searchVidjetClassification: action.payload}}

        case SET_GMDN_CLASSIFICATION_ARR_GET:
            return { ...state,  search: {...state.search, gmdnClassificationArrGet: action.payload}}
        case SET_GMDN_CLASSIFICATION_ARR:
            return { ...state,  search: {...state.search, gmdnClassificationArr: action.payload}}
        case SET_GMDN_CLASSIFICATION_ARR_NEW:
            return { ...state,  search: {...state.search, gmdnClassificationArrNew: action.payload}}
            
        case SET_DK_CLASSIFICATION_ARR_GET:
            return { ...state,  search: {...state.search, dkClassificationArrGet: action.payload}}
        case SET_DK_CLASSIFICATION_ARR:
            return { ...state,  search: {...state.search, dkClassificationArr: action.payload}}
        case SET_DK_CLASSIFICATION_ARR_NEW:
            return { ...state,  search: {...state.search, dkClassificationArrNew: action.payload}}



        case SET_SEARCH_VIDJET_SIMPLE_FILTER:
            return { ...state,  search: {...state.search, searchVidjetSimpleFilter: action.payload}}
        case SET_SEARCH_VIDJET_SIMPLE_FILTER_REGION_OPEN:
            return { ...state,  search: {...state.search, searchVidjetSimpleFilterRegionOpen: action.payload}}
        case SET_SEARCH_VIDJET_SIMPLE_FILTER_REGION_PROC:
            return { ...state,  search: {...state.search, regionProc: action.payload}}
        case SET_SEARCH_VIDJET_SIMPLE_FILTER_REGION_NEW_PROC:
            return { ...state,  search: {...state.search, regionProcNew: action.payload}}
        case SET_SEARCH_VIDJET_SIMPLE_FILTER_TYPE_SUBJECT_PROCUREMENT:
            return { ...state,  search: {...state.search, typeSubjectProcurement: action.payload}}
        case SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_TYPE_SUBJECT_PROCUREMENT:
            return { ...state,  search: {...state.search, typeSubjectProcurementNew: action.payload}}
        case SET_SEARCH_VIDJET_TYPE_SUBJECT_PROCUREMENT_OPEN:
            return { ...state,  search: {...state.search, typeSubjectProcurementOpen: action.payload}}
        case SET_SEARCH_VIDJET_SIMPLE_FILTER_PROCUREMENT_METHOD_TYPE_OPEN:
            return { ...state,  search: {...state.search, searchVidjetSimpleFilterProcurementMethodTypeOpen: action.payload}}
        case SET_SEARCH_VIDJET_SIMPLE_FILTER_PROCUREMENT_METHOD_TYPE_PROC:
            return { ...state,  search: {...state.search, procurementMethodTypeProc: action.payload}}
        case SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_PROCUREMENT_METHOD_TYPE_PROC:
            return { ...state,  search: {...state.search, procurementMethodTypeProcNew: action.payload}}

            
        case SET_SEARCH_VIDJET_SIMPLE_FILTER_VALUE_AMOUNT_FROM:
            return { ...state,  search: {...state.search, filterValueAmountFrom: action.payload}}
        case SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_VALUE_AMOUNT_FROM:
            return { ...state,  search: {...state.search, filterValueAmountFromNew: action.payload}}
        case SET_SEARCH_VIDJET_SIMPLE_FILTER_VALUE_AMOUNT_TO:
            return { ...state,  search: {...state.search, filterValueAmountTo: action.payload}}
        case SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_VALUE_AMOUNT_TO:
            return { ...state,  search: {...state.search, filterValueAmountToNew: action.payload}}
        case SET_SEARCH_VIDJET_SIMPLE_FILTER_VALUE_AMOUNT_OPEN:
            return { ...state,  search: {...state.search, searchVidjetSimpleFilterValueAmountOpen: action.payload}}

        case SET_SEARCH_VIDJET_SIMPLE_ENQUIRY_PERIOD_FROM:
            return { ...state,  search: {...state.search, enquiryPeriodFrom: action.payload}}
        case SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_ENQUIRY_PERIOD_FROM:
            return { ...state,  search: {...state.search, enquiryPeriodFromNew: action.payload}}
        case SET_SEARCH_VIDJET_SIMPLE_FILTER_ENQUIRY_PERIOD_TO:
            return { ...state,  search: {...state.search, enquiryPeriodTo: action.payload}}
        case SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_ENQUIRY_PERIOD_TO:
            return { ...state,  search: {...state.search, enquiryPeriodToNew: action.payload}}
        case SET_SEARCH_VIDJET_SIMPLE_FILTER_ENQUIRY_PERIOD_OPEN:
            return { ...state,  search: {...state.search, searchVidjetSimpleFilterEnquiryPeriodOpen: action.payload}}

        case SET_SEARCH_VIDJET_SIMPLE_AUCTION_PERIOD_FROM:
            return { ...state,  search: {...state.search, auctionPeriodFrom: action.payload}}
        case SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_AUCTION_PERIOD_FROM:
            return { ...state,  search: {...state.search, auctionPeriodFromNew: action.payload}}
        case SET_SEARCH_VIDJET_SIMPLE_FILTER_AUCTION_PERIOD_TO:
            return { ...state,  search: {...state.search, auctionPeriodTo: action.payload}}
        case SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_AUCTION_PERIOD_TO:
            return { ...state,  search: {...state.search, auctionPeriodToNew: action.payload}}
        case SET_SEARCH_VIDJET_SIMPLE_FILTER_AUCTION_PERIOD_OPEN:
            return { ...state,  search: {...state.search, searchVidjetSimpleFilterAuctionPeriodOpen: action.payload}}

        case SET_SEARCH_VIDJET_SIMPLE_TENDER_PERIOD_FROM:
            return { ...state,  search: {...state.search, tenderPeriodFrom: action.payload}}
        case SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_TENDER_PERIOD_FROM:
            return { ...state,  search: {...state.search, tenderPeriodFromNew: action.payload}}
        case SET_SEARCH_VIDJET_SIMPLE_FILTER_TENDER_PERIOD_TO:
            return { ...state,  search: {...state.search, tenderPeriodTo: action.payload}}
        case SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_TENDER_PERIOD_TO:
            return { ...state,  search: {...state.search, tenderPeriodToNew: action.payload}}
        case SET_SEARCH_VIDJET_SIMPLE_FILTER_TENDER_PERIOD_OPEN:
            return { ...state,  search: {...state.search, searchVidjetSimpleFilterTenderPeriodOpen: action.payload}}

        case SET_SEARCH_VIDJET_SIMPLE_FILTER_VALUE_SUMM_FROM:
            return { ...state,  search: {...state.search, filterValueSummFrom: action.payload}}
        case SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_VALUE_SUMM_FROM:
            return { ...state,  search: {...state.search, filterValueSummFromNew: action.payload}}
        case SET_SEARCH_VIDJET_SIMPLE_FILTER_VALUE_SUMM_TO:
            return { ...state,  search: {...state.search, filterValueSummTo: action.payload}}
        case SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_VALUE_SUMM_TO:
            return { ...state,  search: {...state.search, filterValueSummToNew: action.payload}}
        case SET_SEARCH_VIDJET_SIMPLE_FILTER_VALUE_SUMM_OPEN:
            return { ...state,  search: {...state.search, searchVidjetSimpleFilterValueSummOpen: action.payload}}
        




        case SET_SEARCH_VIDJET_SIMPLE_PLANS_TENDER_PERIOD_FROM:
            return { ...state,  search: {...state.search, plansTenderPeriodFrom: action.payload}}
        case SET_SEARCH_VIDJET_SIMPLE_PLANS_NEW_TENDER_PERIOD_FROM:
            return { ...state,  search: {...state.search, plansTenderPeriodFromNew: action.payload}}
        case SET_SEARCH_VIDJET_SIMPLE_PLANS_TENDER_PERIOD_TO:
            return { ...state,  search: {...state.search, plansTenderPeriodTo: action.payload}}
        case SET_SEARCH_VIDJET_SIMPLE_PLANS_NEW_TENDER_PERIOD_TO:
            return { ...state,  search: {...state.search, plansTenderPeriodToNew: action.payload}}
        case SET_SEARCH_VIDJET_SIMPLE_PLANS_TENDER_PERIOD_OPEN:
            return { ...state,  search: {...state.search, plansTenderPeriodOpen: action.payload}}


        case SET_SEARCH_SORT_LIST_OPEN:
            return { ...state,  search: {...state.search, openSortList: action.payload}}
        case SET_SEARCH_SORT_LIST_ARRAY:
            return { ...state,  search: {...state.search, openSortList: action.payload}}
        case SET_ARRAY_SORT_LIST:
            return { ...state,  search: {...state.search, sortListArr: action.payload}}

        case SET_SEARCH_VIDJET_SIMPLE_FILTER_TYPE_PAYMENT:
            return { ...state,  search: {...state.search, paymentType: action.payload}}
        case SET_SEARCH_VIDJET_SIMPLE_FILTER_NEW_TYPE_PAYMENT:
            return { ...state,  search: {...state.search, paymentTypeNew: action.payload}}
        case SET_SEARCH_VIDJET_TYPE_PAYMENT_OPEN:
            return { ...state,  search: {...state.search, paymentTypeOpen: action.payload}}

        case SET_SEARCH_VIDJET_DJERELO_FIN_ARR:
            return { ...state,  search: {...state.search, djerelFinArr: action.payload}}
        case SET_SEARCH_VIDJET_DJERELO_FIN_ARR_NEW:
            return { ...state,  search: {...state.search, djerelFinArrNew: action.payload}}
        case SET_SEARCH_VIDJET_DJERELO_FIN:
            return { ...state,  search: {...state.search, openDjereloFin: action.payload}}

        case SET_SEARCH_VIDJET_ROZPORYADNUK_KOSHTIV_STRING:
            return { ...state,  search: {...state.search, rozporyadnukKoshtivSearchString: action.payload}}
        case SET_SEARCH_VIDJET_ROZPORYADNUK_KOSHTIV_ARR_GET:
            return { ...state,  search: {...state.search, rozporyadnukKoshtivArrGet: action.payload}}
        case SET_SEARCH_VIDJET_ROZPORYADNUK_KOSHTIV_ARR:
            return { ...state,  search: {...state.search, rozporyadnukKoshtivArr: action.payload}}
        case SET_SEARCH_VIDJET_ROZPORYADNUK_KOSHTIV_ARR_NEW:
            return { ...state,  search: {...state.search, rozporyadnukKoshtivArrNew: action.payload}}
        case SET_SEARCH_VIDJET_ROZPORYADNUK_KOSHTIV:
            return { ...state,  search: {...state.search, rozporyadnukKoshtiv: action.payload}}

        case SET_SEARCH_ARH_STATUS:
            return { ...state,  search: {...state.search, statusProc: action.payload}}
        case SET_SEARCH_NEW_ARH_STATUS:
            return { ...state,  search: {...state.search, statusProcNew: action.payload}}

            
        case SET_SEARCH_SORT:
            return { ...state,  search: {...state.search, order: action.payload}}
        case SET_SEARCH_TEXT_STRING:
            return { ...state,  search: {...state.search, textSting: action.payload}}
        case SET_SEARCH_TYPE_KEYWORD:
            return { ...state,  search: {...state.search, selectTypeKeyword: action.payload}}
        case SET_SEARCH_DATE_START:
            return { ...state,  search: {...state.search, startDateFrom: action.payload}}
        case SET_SEARCH_DATE_END:
            return { ...state,  search: {...state.search, startDateTo: action.payload}}
        case SET_CATEGORY_AUCTION:
            return { ...state,  search: {...state.search, category: action.payload}}
        case SET_PRICE_FROM:
            return { ...state,  search: {...state.search, priceFrom: action.payload}}
        case SET_PRICE_FROM_BAG:
            return { ...state,  search: {...state.search, priceFromBag: action.payload}}
        case SET_PRICE_TO:
            return { ...state,  search: {...state.search, priceTo: action.payload}}
        case SET_PRICE_TO_BAG:
            return { ...state,  search: {...state.search, priceToBag: action.payload}}
        case SET_AUCTIONS_PATH:
            return { ...state,  aucyionsPath: action.payload}
        case CHANGE_LANG:
            return { ...state,  langFlag: action.payload}
        case CHANGE_ADVANCED_FIND:
            return { ...state,  find: action.payload}
        case BID_AWARDS:
            return { ...state,  bidAward: action.payload}
        case BID_QUANTITY:
            return { ...state,  bidQuantity: action.payload}
            
        case CREATE_AUCTION_TAB:
            return { ...state,  createAuctionTab: action.payload}
        case CREATE_CHANGET_AUCTION:
            return {...state,  auctionsMy: action.payload}
        case EDIT_AUCTION_FROM_ID:
            return {...state,  auctionsMy: action.payload}
            
        case NUMBER_PAGE_LOT:
            return { ...state,  pageNumber: action.payload}

        case AUCTIONS_GET_FLAG:
            return { ...state, auctionsGetFlag: action.payload}

        case AUC_TEST:
            return { ...state, auctionsGet: action.payload}
        case AUCTIONS_SEARCH:
            if(action.payload.hasOwnProperty('message')){
                return { ...state, auctionsGet: null}
            }else{
                return { ...state, auctionsGet: action.payload}
            }

        case AUCTIONS_SEARCH_MIN:
            return { ...state, auctionsGet: action.payload}
        case AUCTIONS_SEARCH_COUNT:
            if(action.payload.hasOwnProperty('message')){
                return { ...state, searchCount: {...state.searchCount, count: null}}
            }else{
                return { ...state, searchCount: {...state.searchCount, count: action.payload.count}}
            }
        case AUCTIONS_GET_MY:
            return { ...state, auctionsGetMy: action.payload }

        
        case AUCTIONS_GET_MY_ID:
            return { ...state, auctionsGetMy: action.payload}
        case AUCTIONS_CURENT_PAGE:
            return { ...state, searchCount: {...state.searchCount, curentPage: action.payload}}
        case AUCTIONS_STATUS:
            let tmp1 = null;
            if(action.payload !== 'null'){
                tmp1 = action.payload
                
            }
            return { ...state, search: {...state.search, status: tmp1}}
        case CHANGE_MEASURES:
            return { ...state, search: {...state.search, measures: action.payload}}
            
        case LEASE_CONTRACTS_GET_MY:
            return { ...state, myLeaseContracts: action.payload}
        case OBJECTS_GET_MY:
            return { ...state, myObjectsList: action.payload}
        case OBJECT_GET_TYPES: 
            return { ...state, objectTypesList: action.payload}
        case OBJECT_GET_RESTRICTIONS:
            return { ...state, objectRestrictionsList: action.payload}
        case OBJECT_GET_INTENDED_USE:
            return { ...state, objectIntendedUseList: action.payload}
        case OBJECT_GET_LIST_TYPES:
            return { ...state, objectListTypesList: action.payload}
        case OBJECT_GET_STATUS_IN_LIST:
            return { ...state, objectStatusInList: action.payload}
        case OBJECT_GET_REGISTRATION_STATE_LIST:
            return { ...state, objectRegistrationStateList: action.payload}
        case OBJECT_GET_VERIFICATION_3_LIST: 
            return { ...state, objectVerification3List: action.payload}
        case OBJECT_GET_OWNERSHIP_TYPE:
            return { ...state, objectOwnershipTypeList: action.payload}
        case OBJECT_GET_UA_INDENTIFIERS_LIST:
            return { ...state, objectUaIdentifiersList: action.payload}
        case OBJECT_GET_ENCUMBRANCES_LIST:
            return { ...state, objectEncumbrancesList: action.payload}
        case OBJECT_GET_FUEL_TYPE_LIST:
            return { ...state, objectFuelTypeList: action.payload}
        case GET_DICTIONARIES_UNIT_CODE:
            return { ...state, dictionaryUnitCode : action.payload}
        case OBJECT_GET_TRANSMISSION_TYPE_LIST:
            return { ...state, objectTransmissionTypeList: action.payload}
        case OBJECT_GET_FLOOR_TYPE_LIST:
            return { ...state, objectFloorTypeList: action.payload}
        case OBJECT_GET_POWER_SUPPLY_CLASS_LIST:
            return { ...state, objectPowerSupplyClassList: action.payload}
        case OBJECT_GET_CONSTRUCTION_TECHNOLOGY_LIST:
            return { ...state, objectConstructionTechnologyList: action.payload}
        case OBJECT_GET_CAV_LIST:
            return { ...state, objectCavList: action.payload}
        case ASSETT_GET_REGISTRATION_STATUS:
            return { ...state, assetRegistrationStatus: action.payload}

        case SET_OBJECT_CREATION_STEP:
            return { ...state, objectCreationStep: action.payload}
        case DRAFT_OBJECT:
            return{ ...state, draftObject: action.payload}
        case OBJECT_CREATE:
            return { ...state, objectCreate: action.payload}
        case OBJECT_UPDATE:
            return { ...state, objectUpdate: action.payload}
        case OBJECT_GET:
            return { ...state, objectGet: action.payload}
        case OBJECT_DELETE:
            return { ...state, objectDelete: action.payload}
        case OBJECT_ADDRESS:
            return {...state, objectAddress: action.payload}
        case SEARCH_ADDRESS:
            return {...state, addressSearch: action.payload}
        case OBJECT_PAGE_CREATE:
            return {...state, objectPage: action.payload}
        case OBJECT_VALUES_CHARACTERISTICS_UPDATE:
            return {...state, objectValuesCharacteristicsUpdate: action.payload}
        case OBJECT_LEASE_RULES_UPDATE:
            return {...state, leaseRulesUpdate: action.payload}
        case OBJECT_STATUSES_DECISIONS_UPDATE:
            return {...state, objectStatusesDecisionsUpdate: action.payload}
        case OBJECT_RELATED_ORGANIZATIONS_UPDATE:
            return { ...state, objectRelatedOrganizationsUpdate: action.payload}
        case OBJECT_RELATED_ORGANIZATIONS_DELETE:
            return { ...state, objectRelatedOrganizationsDelete: action.payload}
        case INACTIVE_OBJECT_POPUP:
            return { ...state, inactiveObjectPopup: action.payload}
        case OBJECT_DELETE_POPUP:
            return { ...state, objectDeletePopup: action.payload}
        case ORGANIZATIONS_GET_LIST:
            return { ...state, organizationsGetList: action.payload}

        // case OBJECT:
        //     return { ...state, object: action.payload}

        case ACTIONS_GET_MY:
            return { ...state, myActionsList: action.payload}
        case ACTIONS_GET_TYPE:
            return { ...state, actionTypesList: action.payload}
        case ACTIONS_GET_ENTITY_ROLE:
            return { ...state, actionEntityRolesList: action.payload}
        case SET_ACTION_TYPE:
            return { ...state, setActionType: action.payload}
        case ACTION_CREATE:
            return { ...state, actionCreate: action.payload}
        case ACTION_UPDATE:
            return { ...state, actionUpdate: action.payload}
        case ACTION_DELETE:
            return { ...state, actionDelete: action.payload}
        case ACTION_DELETE_POPUP:
            return { ...state, actionDeletePopup: action.payload}
        case ACTION_RELATED_OBJECTS:
            return { ...state, actionRelatedObjects: action.payload}
        case POPUP_LOCATION:
            return { ...state, popupLocation: action.payload}

        case REQUESTS_GET_MY:
            return { ...state, myRequestsList: action.payload}
        case SET_POPUP_ADD_REQUEST:
            return { ...state, popupAddLeaseRequest: action.payload}
        case LEASE_REQUEST_GET_TYPE:
            return { ...state, requestTypesList: action.payload}
        case LEASE_REQUEST_CREATE:
            return { ...state, requestCreate: action.payload}
        case LEASE_REQUEST_EDIT:
            return { ...state, requestEdit: action.payload}
        case REQUEST_GET_MY:
            return { ...state, myRequest: action.payload}
        

        
        default: return state
    }
}
