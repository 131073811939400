import React, {useEffect, useState} from 'react';
import styles from './actionsBlock.module.css';
import InfoModal from '../../../catalog/product/infoIconBlock/infoIconBlock'
import {Link} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { leaseActionsGet } from '../../../../../redux/actions/auctions/auctions';
import { auctionGetId } from '../../../../../redux/actions/auctions/auctions';
import { checkProp, dateTransform } from '../../../../../utils/check';

const ActionsBlock = (props) => {
    const dispatch = useDispatch()
    const actions = useSelector(state => state.start.leaseActionsGet)
    const data = useSelector(state => state.start.auctionsGetId)
    const token = useSelector(state => state.start.token)
    const userProfiles = useSelector(state => state.start.userProfiles)
    const [more, setMore] = useState(styles.actDiscription)
    const [morebtn, setMoreBtn] = useState('Більше')

    useEffect(() => {
        getLeaseActions()
        window.scrollTo(0, 0)
    }, [])
    
    function getLeaseActions() {
        let url = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/public/registry_object/${data.id}/lease_actions`
        dispatch(leaseActionsGet(url))
    }

    function getActionType(a) {
        switch (a) {
            case 'requestAnswer':
                return 'Запит для отримання відповіді'
            case 'intentionNotification':
                return 'Повідомлення про намір'
            case 'decisionPublication':
                return 'Публікація рішення'
            case 'decisionChange':
                return 'Зміна рішення'
            case 'petition':
                return 'Клопотання'
            case 'noAuctionNotification':
                return 'Інформаційне повідомлення про передачу об’єкта оренди без проведення аукціону'
        }
    }

    const ActionItem = (props) => {

        function dateTransformer(a) {
                return (
                    <div className={styles.rightBlock_date_time}>{dateTransform(a)}
                        <span className={styles.rightBlock_date_timeSpan}>{dateTransform(a, 'onlyTime')}</span>
                    </div>
                )

        }

        function getDocList(a) {
        let tmp = []
            for (let key in a) {
                tmp.push(a[key].title.uk_UA)
            }
            tmp = tmp.map(item => <li key={item}>{item}</li> )
            return tmp
        }

        return (
                <div className={styles.actItem}>
                    <div className={styles.centralBlock}>
                        <div className={styles.topBlock}>
                            <div className={styles.actIdAndType}>
                                <div className={styles.actIdAndType_id}>{props.item.leaseActionId}</div>
                                <div className={styles.actIdAndType_type}>{getActionType(props.item.type)}</div>
                            </div>
                            <div className={styles.actTitle}>Дія</div>
                            {checkProp(props.item, 'documents') && props.item.documents !== null && Object.keys(props.item.documents).length > 0
                                ?  <div className={styles.docLinkd}>
                                Документи:<span>{Object.keys(props.item.documents).length}</span>
                                    <InfoModal
                                        infodata={<ul>{getDocList(props.item.documents)}</ul>}
                                    />
                                    </div>
                                : null}
                            {checkProp(props.item, 'description') && props.item.description ?
                                <> 
                                    <div className={more}>
                                        {props.item.description}
                                    </div>
                                    {props.item.description.length > 300
                                        ? <div className={styles.actMore}
                                            onClick={() => {
                                                if (more === styles.actDiscription){
                                                    setMore(styles.actDiscriptionExp)
                                                    setMoreBtn('Меньше')
                                                }  else {
                                                    setMore(styles.actDiscription)
                                                    setMoreBtn('Більше')
                                                }
                                            }}
                                        >{morebtn}</div> : null}
                                </> : null
                            }
                        </div>
                        <div className={styles.bottomBlock}>
                        <div className={styles.actUserInfo}>
                            {checkProp(props.item, 'actingEntity') ? <>
                                <span>Інформація про особу, що вчинила дію:</span>
                                <div className={styles.actUserName}>{props.item.actingEntity.name.uk_UA}
                                    {checkProp(props.item.actingEntity, 'contactPoint') ?
                                        <InfoModal
                                            info={props.item.actingEntity}
                                        /> : null
                                    }
                                </div> </> : null
                            }
                            </div>
                        </div>
                    </div>
                    <div className={styles.rightBlock}>
                        <div className={styles.rightBlock_Status}>
                            Опубліковано
                        </div>
                    <div className={styles.rightBlock_Date}>
                        { checkProp(props.item, 'datePublished') && props.item.datePublished !== null ?
                            <div className={styles.rightBlock_Start}>
                                <div className={styles.rightBlock_date_text}>Дата публікації</div>
                                {dateTransformer(props.item.datePublished)}
                            </div> : null
                        }
                        { checkProp(props.item, 'dateModified') && props.item.dateModified !== null ?
                            <div className={styles.rightBlock_Start}>
                                <div className={styles.rightBlock_date_text}>Остання дата зміни</div>
                                {dateTransformer(props.item.dateModified)}
                            </div> : null
                        }
                        </div>
                        <div className={styles.rightBtnsBlock}>
                            <Link className={styles.link} to={`/lease/actionItem/${props.item.id}`}>
                            <div className={styles.detailsBtn}
                                onClick={() => {
                                    let data = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/public/lease_action/${props.item.id}`
                                    dispatch({payload: null, type: 'AUCTIONS_GET_ID'})
                                    dispatch(auctionGetId(data))
                                }}>Детальніше</div>
                            </Link>
                        </div>
                    </div>
                </div>
        )
    }

    if (!!actions && actions.length > 0 ) {

        return (
            <>
                <h2 className={styles.titleDecPg}>Дії</h2>
                { actions.map(( item, count ) => {return <ActionItem key={count} item={item}/>}) }
                {/* {userProfiles !== null && userProfiles.canCreateActions !== null && userProfiles.canCreateActions ?
                    <Link to={`/lease/object/${data.id}/actions/create`} className={styles.buttonAddAction}>Додати дію</Link>
                : ''} */}
            </>
        )
    } else {
        return (
            <>
                <div className={styles.nothing}>Наразі жодних дій не знайдено</div>
                {/* {userProfiles !== null && userProfiles.canCreateActions !== null && userProfiles.canCreateActions ?
                    <Link to={`/lease/object/${data.id}/actions/create`} className={styles.buttonAddAction}>Додати дію</Link>
                : ''} */}
            </>
        )
    }
}

export default ActionsBlock