import React, {useState} from 'react';
import { useSelector } from 'react-redux';
import styles from './leaseRequestItemCard.module.css';
import { checkProp, dateTransform, isMongo } from '../../../../../utils/check';
import { Link, useNavigate } from 'react-router-dom';
import InfoModal from '../../product/infoIconBlock/infoIconBlock'

const RequestItemCard = ( props ) => {
    const user = useSelector(state => state.start.userProfiles)

    const data = props.item
    const description = checkProp(data, 'description') ? data.description : null
    const requestor = propsChecker(data.requestor) //суб'єкт заяви
    const renterInfo  = propsChecker(data.sellingEntity) //орендодавець
    const publishDate = propsChecker(data.datePublished) // дата публікації
    const modifDate = propsChecker(data.dateModified) // дата зміни
    const [discriptExpand, setDiscriptExpand] = useState(styles.objDiscription)
    const [moreBtn, setMoreBtnBtn] = useState('Більше')

    const navigate = useNavigate()

    function propsChecker(x, y = '') {
        if (x === undefined || x === 'undefined' || x === null || !x) {return y}
        else {return x}
    }

    function checkReqestor(requestor) {
        if (requestor === undefined || requestor === 'undefined' || requestor === null || !requestor) {
            return ''
        }
        else{
            return(
                <div className={styles.objOwner}>
                <span>Інформація про заявника:</span>
                {requestor}
                <InfoModal 
                    info={
                        <InfoModal
                            info={requestor}
                        />
                    }
                />
            </div>
            )
        }
    }

    function yourRequestBadge() {
        let status = false
        if (!user) return null
        if (user && data && data.sellingEntity) {
            if ( checkProp(data.sellingEntity, 'identifier') && data.sellingEntity.identifier.id === user.organization.identifier.identifier) {
                status = true
            }
        }
        if (status) return <div className={styles.yourObjBadge}>Ваша заява</div>
        else return null 
    }

    function getRequestType(a) {
        switch(a) {
            case 'newLease':
                return `Заява про включення до переліку`

            case 'lease':
                return `Заява на оренду об'єкта включеного в перелік`

            case 'agreementExtension':
                return `Заява про продовження договору оренди`

            default:
                return a
        }
    }

    function getDescription(a) {
        let tmpDescription = isMongo(a)

        return (    
                <>
                    <div className={discriptExpand}>
                        { tmpDescription }
                    </div>
                { tmpDescription.length > 240 ? <div className={styles.objMore}
                    onClick={( ) => {
                        if (discriptExpand === styles.objDiscription) {
                            setDiscriptExpand(styles.objDiscriptionExt)
                            setMoreBtnBtn('Меньше')
                        } else {
                            setDiscriptExpand(styles.objDiscription)
                            setMoreBtnBtn('Більше')
                        }
                    }}
                >{moreBtn}
                </div> : null}
                </>
        )
    }

    return(
        <>
            <div className={styles.objItem}>
                <div className={styles.centralBlock}>
                    <div className={styles.topBlock}>
                        <div className={styles.objIdAndType}>
                            <div className={styles.objIdAndType_id} onClick={ ()=> navigate(`/lease/lease_request/${data.leaseRequestId}`)}>
                                {data.leaseRequestId}
                            </div>
                            {yourRequestBadge()}
                            <div className={styles.objIdAndType_type}>
                                { getRequestType(data.type) }
                            </div>
                        </div>
                        <div className={styles.objTitle} onClick={ ()=> navigate(`/lease/lease_request/${data.leaseRequestId}`) } >
                                Заява
                        </div>
                        <Link className={styles.link} to={`/lease/object/${data.relatedObjectId}`}>
                            <div className={styles.objType}><span>Пов'язаний об'єкт</span>
                                    {/* {`${data.relatedObjectId}`} */}
                            </div>
                        </Link>

                            { checkProp(data, 'description') ? getDescription( data.description ) : null }
                        
                    </div>
                    <div className={styles.bottomBlock}>
                        <div className={styles.objOwner}>
                            <span>Інформація про орендодавця:</span>
                            {checkProp(data, 'sellingEntity') ? data.sellingEntity.name.uk_UA : null}
                            { checkProp(data, 'sellingEntity') ?
                                <InfoModal
                                info={data.sellingEntity}
                                /> : null
                            }
                        </div>
                        {checkReqestor(requestor)}
                    </div>
                </div>
                <div className={styles.rightBlock}>
                    {/* {setStatus(data.statusesDecisions.statusInList)} */}
                    <div className={`${styles.rightBlock_Status} ${styles.colorDark}`}>
                        Опубліковано
                    </div>
                    <div className={styles.rightBlock_Date}>
                        <div className={styles.rightBlock_Start}>
                            <div className={styles.rightBlock_date_text}>Дата публікації</div>
                            <div className={styles.rightBlock_date_time}>{dateTransform(publishDate)}
                                <span className={styles.rightBlock_date_timeSpan}>{dateTransform(publishDate, 'onlyTime')}</span>
                            </div>
                        </div>
                        <div className={styles.rightBlock_Start}>
                            <div className={styles.rightBlock_date_text}>Остання дата зміни</div>
                            <div className={styles.rightBlock_date_time}>{dateTransform(modifDate)}
                                <span className={styles.rightBlock_date_timeSpan}>{dateTransform(modifDate, 'onlyTime')}</span>
                            </div>
                        </div>
                    </div>
                    <div className={styles.rightBlock_CostAndButtons}>
                        <div className={styles.buttonsLine}>
                            <Link className={styles.link} 
                            to={`/lease/lease_request/${data.leaseRequestId}`}
                            // to={`/lease/object/${data.relatedObjectId}/lease_requests#${data.leaseRequestId}`}
                            >
                                <div className={styles.buttonView}>Переглянути</div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div> 
        </>
        )
}

export default RequestItemCard