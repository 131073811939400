import React, {useState, useEffect} from 'react';
import { checkProp } from '../../../../../utils/check';
import Button from '../../../../elements/buttons/button';
import Input from '../../../../inputs/input';
import InputDate from '../../../../inputs/inputDate';
import styles from './buttonFilters.module.css';

    const Popup =( { setParams, params, setPopup } )=> {
        const [executionId, setExecutionId] = useState( checkProp( params, 'executionId' ) ? params.executionId : null )
        const [title, setTitle] = useState( checkProp( params, 'title' ) ? params.title : null )
        // const [description, setDescription] = useState( checkProp( params, 'description' ) ? params.description : null )
        const [datePublishedFrom, setDatePublishedFrom] = useState( checkProp( params, 'datePublishedFrom' ) ? params.datePublishedFrom : null )
        const [datePublishedTo, setDatePublishedTo] = useState( checkProp( params, 'datePublishedTo' ) ? params.datePublishedTo : null )
    
            return (
                    <div className={styles.wrapMain}>
                        <div className={styles.wrap}>
                            <h3 className={styles.filterTitle}>Розширений пошук</h3>
                            <div className={styles.content}>
                                <Input 
                                    label='Пошук по ідентифікатору контрактингу'
                                    value={ executionId }
                                    onChange={ e => setExecutionId(e) }
                                />
                                <Input 
                                    label='Пошук по назві контрактингу'
                                    value={ title }
                                    onChange={ e => setTitle(e) }
                                />
                                {/* <Input 
                                    label='Пошук по опису контрактингу' 
                                    value={description}
                                    onChange={ e => setDescription(e)}
                                /> */}
                                <InputDate
                                    data={{
                                        label: 'Дата публікації, з',
                                        value: datePublishedFrom,
                                        onChange: e => setDatePublishedFrom( e ),
                                        // time: true
                                    }}
                                />
                                <InputDate
                                    data={{
                                        label: 'Дата публікації, по',
                                        value: datePublishedTo,
                                        onChange: e => setDatePublishedTo( e ),
                                        // time: true
                                    }}
                                />
                            </div>
                            <div className={styles.bottomPart}>
                                <Button 
                                    color='gray'
                                    onClick={() => setPopup( prev => !prev) }>
                                    Закрити
                                </Button>
                                <Button
                                    color='blue'
                                    onClick={() => {
                                        setParams( prev => ({...prev,
                                            executionId: executionId,
                                            title: title,
                                            // description: description,
                                            datePublishedFrom: datePublishedFrom,
                                            datePublishedTo: datePublishedTo
                                        }
                                        ) )
                                        setPopup( prev => !prev )
                                    }}>
                                    Застосувати
                                </Button>
                            </div>
                        </div>
                    </div>
                )
    }

const ExecutionsAdvancedSearch = ({ setParams, params })=> {
    const [popup, setPopup] = useState( false )

    return (
        <>
            <div
                className={styles.searchFilterBlock_status}
                onClick={ () => setPopup( prev => !prev) }
            >
                <div>Розширений пошук</div>
                {/* { ( !!arr?.status && arr.status.length > 0 )&&  */}
                    <div style={{borderLeft: '1px dotted #BFBFC6', paddingLeft: '5px', marginLeft: '5px'}}>
                        {/* { arr.status.length } */}
                    </div> 
                
            </div>
            { popup && <Popup setParams={ setParams } params={ params } setPopup={ setPopup } /> }
        </>
    )
}

export default ExecutionsAdvancedSearch
