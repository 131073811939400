export function setCurrentUrl(data) {
    if(data.url === 'tender_searches'){
        return 'https://oldtest.dzo.com.ua/api/tenders'
    }else if(data.url === "plans"){
        return 'https://oldtest.dzo.com.ua/api/plans'
    }else if(data.url === "contracts"){
        return 'https://oldtest.dzo.com.ua/api/contracts'
    }
    return null
}

export function setTypeFilter(data, status){
    let str = ''
    if(status === true){
        for(let key in data){
            if(data[key].onOff === true){
                str += `&type[]=${data[key].status}`
            }
        }
    }else{
        for(let key in data){
            if(data[key].onOff === false){
                str += `&type[]=${data[key].status}`
            }
        }
    }
    return str
}

export function setGetRequestParameterStatus(data, status){
    let str = ''
    if(status === true){
        for(let d = 0; d < data.statusProc.length; d++){
            if(data.statusProc[d].onOff === true){
                str += `&status[]=${data.statusProc[d].status}`
            }
        }
    }else{
        for(let d = 0; d < data.statusProcNew.length; d++){
            if(data.statusProcNew[d].onOff === true){
                str += `&status[]=${data.statusProcNew[d].status}`
            }
        }
    }
    return str
}

export function setGetRequestParameterClassification(data, status){
    let str = ''
    if(status === true){
        for(let d = 0; d < data.searchVidjetClassificationArr.length; d++){
            if(data.searchVidjetClassificationArr[d].onOff === true){
                str += `&classification[]=${data.searchVidjetClassificationArr[d].id}`
            }
        }
    }else{
        for(let d = 0; d < data.searchVidjetClassificationArrNew.length; d++){
            if(data.searchVidjetClassificationArrNew[d].onOff === true){
                str += `&classification[]=${data.searchVidjetClassificationArrNew[d].id}`
            }
        }
    }
    return str
}

export function setGetRequestParameterProcuringEntity(data, status){
    let str = ''
    if(status === true){
        for(let d = 0; d < data.searchVidjetOrganizatorArr.length; d++){
            if(data.searchVidjetOrganizatorArr[d].onOff === true){
                str += `&procuringEntity[]=${data.searchVidjetOrganizatorArr[d].id}`
            }
        }
    }else{
        for(let d = 0; d < data.searchVidjetOrganizatorArrNew.length; d++){
            if(data.searchVidjetOrganizatorArrNew[d].onOff === true){
                str += `&procuringEntity[]=${data.searchVidjetOrganizatorArrNew[d].id}`
            }
        }
    }
    return str
}

export function setGetRequestParameterRegion(data, status){
    let str = ''
    if(status === true){
        for(let d = 0; d < data.regionProc.length; d++){
            if(data.regionProc[d].onOff === true){
                str += `&items.deliveryAddress.region[]=${data.regionProc[d].id}`
            }
        }
    }else{
        for(let d = 0; d < data.regionProcNew.length; d++){
            if(data.regionProcNew[d].onOff === true){
                str += `&items.deliveryAddress.region[]=${data.regionProcNew[d].id}`
            }
        }
    }
    return str
}

export function setGetRequestParameterTypeSubjectProcurement(data, status){
    if(status === true){
        if(data.typeSubjectProcurement !== 'null'){
            return `&mainProcurementCategory[]=${data.typeSubjectProcurement.id}`
        }
    }else{
        if(data.typeSubjectProcurementNew !== 'null'){
            return `&mainProcurementCategory[]=${data.typeSubjectProcurementNew.id}`
        }
    }
    return ''
}

export function setGetRequestParameterPaymentType(data, status){
    if(status === true){
        if(data.paymentType !== 'null'){
            return `&milestones.code[]=${data.paymentType}`
        }
    }else{
        if(data.paymentTypeNew !== 'null'){
            return `&milestones.code[]=${data.paymentTypeNew}`
        }
    }
    return ''
}

export function setGetRequestParameterProcurementMethodType(data, status){
    let str = ''
    if(status === true){
        for(let d = 0; d < data.procurementMethodTypeProc.length; d++){
            if(data.procurementMethodTypeProc[d].onOff === true){
                if(data.procurementMethodTypeProc[d].title === "Відбір для закупівлі за рамковою угодою"){
                    str += `&procurementMethodType[]=${data.procurementMethodTypeProc[d].id}&procurementMethod[]=selective`
                }else if(data.procurementMethodTypeProc[d].title === "Спрощені закупівлі"){
                    str += `&procurementMethodType[]=${data.procurementMethodTypeProc[d].id}&procurementMethodRationale[]=simple`
                }else if(data.procurementMethodTypeProc[d].title === "Закупівлі по COVID-19"){
                    str += `&procurementMethodType[]=${data.procurementMethodTypeProc[d].id}&procurementMethodRationale[]=COVID-19`
                }else if(data.procurementMethodTypeProc[d].title === "Prozorro Market"){
                    str += `&procurementMethodType[]=${data.procurementMethodTypeProc[d].id}&procurementMethodRationale[]=catalogue`
                }else{
                    str += `&procurementMethodType[]=${data.procurementMethodTypeProc[d].id}`
                }
            }
        }
    }else{
        for(let d = 0; d < data.procurementMethodTypeProcNew.length; d++){
            if(data.procurementMethodTypeProcNew[d].onOff === true){
                if(data.procurementMethodTypeProcNew[d].title === "Відбір для закупівлі за рамковою угодою"){
                    str += `&procurementMethodType[]=${data.procurementMethodTypeProcNew[d].id}&procurementMethod[]=selective`
                }else if(data.procurementMethodTypeProcNew[d].title === "Спрощені закупівлі"){
                    str += `&procurementMethodType[]=${data.procurementMethodTypeProcNew[d].id}&procurementMethodRationale[]=simple`
                }else if(data.procurementMethodTypeProcNew[d].title === "Закупівлі по COVID-19"){
                    str += `&procurementMethodType[]=${data.procurementMethodTypeProcNew[d].id}&procurementMethodRationale[]=COVID-19`
                }else if(data.procurementMethodTypeProcNew[d].title === "Prozorro Market"){
                    str += `&procurementMethodType[]=${data.procurementMethodTypeProcNew[d].id}&procurementMethodRationale[]=catalogue`
                }else{
                    str += `&procurementMethodType[]=${data.procurementMethodTypeProcNew[d].id}`
                }
            }
        }
    }
    return str
}

export function setGetRequestValueAmountFrom(data, status){
    if(status === true){
        if(data.filterValueAmountFrom !== ''){
            return `&value.amount.from=${data.filterValueAmountFrom}`
        }
    }else{
        if(data.filterValueAmountFromNew !== ''){
            return `&value.amount.from=${data.filterValueAmountFromNew}`
        }
    }
    return ''
}

export function setGetRequestValueAmountTo(data, status){
    if(status === true){
        if(data.filterValueAmountTo !== ''){
            return `&value.amount.to=${data.filterValueAmountTo}`
        }
    }else{
        if(data.filterValueAmountToNew !== ''){
            return `&value.amount.to=${data.filterValueAmountToNew}`
        }
    }
    return ''
}

export function setGetRequestEnquiryPeriodFrom(data, status){
    if(status === true){
        if(data.enquiryPeriodFrom !== ''){
            return `&enquiryPeriod.from=${data.enquiryPeriodFrom}`
        }
    }else{
        if(data.enquiryPeriodFromNew !== ''){
            return `&enquiryPeriod.from=${data.enquiryPeriodFromNew}`
        }
    }
    return ''
}

export function setGetRequestEnquiryPeriodTo(data, status){
    if(status === true){
        if(data.enquiryPeriodTo !== ''){
            return `&enquiryPeriod.to=${data.enquiryPeriodTo}`
        }
    }else{
        if(data.enquiryPeriodToNew !== ''){
            return `&enquiryPeriod.to=${data.enquiryPeriodToNew}`
        }
    }
    return ''
}

export function setGetRequestAuctionPeriodFrom(data, status){
    if(status === true){
        if(data.auctionPeriodFrom !== ''){
            return `&auctionPeriod.from=${data.auctionPeriodFrom}`
        }
    }else{
        if(data.auctionPeriodFromNew !== ''){
            return `&auctionPeriod.from=${data.auctionPeriodFromNew}`
        }
    }
    return ''
}

export function setGetRequestAuctionPeriodTo(data, status){
    if(status === true){
        if(data.auctionPeriodTo !== ''){
            return `&auctionPeriod.to=${data.auctionPeriodTo}`
        }
    }else{
        if(data.auctionPeriodToNew !== ''){
            return `&auctionPeriod.to=${data.auctionPeriodToNew}`
        }
    }
    return ''
}

export function setGetRequestTenderPeriodFrom(data, status){
    if(status === true){
        if(data.tenderPeriodFrom !== ''){
            return `&auctionPeriod.from=${data.tenderPeriodFrom}`
        }
    }else{
        if(data.tenderPeriodFromNew !== ''){
            return `&auctionPeriod.from=${data.tenderPeriodFromNew}`
        }
    }
    return ''
}

export function setGetRequestTenderPeriodTo(data, status){
    if(status === true){
        if(data.tenderPeriodTo !== ''){
            return `&auctionPeriod.to=${data.tenderPeriodTo}`
        }
    }else{
        if(data.tenderPeriodToNew !== ''){
            return `&auctionPeriod.to=${data.tenderPeriodToNew}`
        }
    }
    return ''
}

export function setGetRequestValueSummFrom(data, status){
    if(status === true){
        if(data.filterValueSummFrom !== ''){
            return `&value.amount.from=${data.filterValueSummFrom}`
        }
    }else{
        if(data.filterValueSummFromNew !== ''){
            return `&value.amount.from=${data.filterValueSummFromNew}`
        }
    }
    return ''
}

export function setGetRequestValueSummTo(data, status){
    if(status === true){
        if(data.filterValueSummTo !== ''){
            return `&value.amount.to=${data.filterValueSummTo}`
        }
    }else{
        if(data.filterValueSummTo !== ''){
            return `&value.amount.to=${data.filterValueSummTo}`
        }
    }
    return ''
}

export function setGetRequestPlansTenderPeriodFrom(data, status){
    if(status === true){
        if(data.tenderPeriodFrom !== ''){
            return `&auctionPeriod.from=${data.plansTenderPeriodTo}`
        }
    }else{
        if(data.tenderPeriodFromNew !== ''){
            return `&auctionPeriod.from=${data.plansTenderPeriodToNew}`
        }
    }
    return ''
}

export function setGetRequestPlansTenderPeriodTo(data, status){
    if(status === true){
        if(data.plansTenderPeriodTo !== ''){
            return `&tender.tenderPeriod.startDate.from=${data.plansTenderPeriodTo}`
        }
    }else{
        if(data.plansTenderPeriodToNew !== ''){
            return `&tender.tenderPeriod.startDate.to=${data.plansTenderPeriodToNew}`
        }
    }
    return ''
}

export function setOrder(data){
    for(let i = 0; i < data.length; i++){
        if(data[i].status === true){
            return `&order[${data[i].findParamName}]=${data[i].findSortStatus}`
        }
    }
    return ''
}


export function setGetRequestParameterRozporyadnukKoshtiv(data, status){
    let str = ''
    if(status === true){
        for(let d = 0; d < data.rozporyadnukKoshtivArr.length; d++){
            if(data.rozporyadnukKoshtivArr[d].onOff === true){
                str += `&budgetaries[]=${data.rozporyadnukKoshtivArr[d].id}`
            }
        }
    }else{
        for(let d = 0; d < data.rozporyadnukKoshtivArrNew.length; d++){
            if(data.rozporyadnukKoshtivArrNew[d].onOff === true){
                str += `&budgetaries[]=${data.rozporyadnukKoshtivArrNew[d].id}`
            }
        }
    }
    return str
}

export function setGetRequestParameterDjereloFin(data, status){
    let str = ''
    if(status === true){
        for(let d = 0; d < data.djerelFinArr.length; d++){
            if(data.djerelFinArr[d].onOff === true){
                str += `&budget.breakdown.title[]=${data.djerelFinArr[d].id}`
            }
        }
    }else{
        for(let d = 0; d < data.djerelFinArrNew.length; d++){
            if(data.djerelFinArrNew[d].onOff === true){
                str += `&budget.breakdown.title[]=${data.djerelFinArrNew[d].id}`
            }
        }
    }
    return str
}

export function setArraySortList(data) {
    if(data === 'tender_searches'){
        return [
            {name: 'Спочатку найновіші створені', status: true, findParamName: 'date', findSortStatus: 'DESC'},
            {name: 'Спочатку найстарші створені', status: false, findParamName: 'date', findSortStatus: 'ASC'},
            {name: 'Спочатку найближчі аукціони', status: false, findParamName: 'auctionPeriod.startDate', findSortStatus: 'DESC'},
            {name: 'Спочатку найпізніші аукціони', status: false, findParamName: 'auctionPeriod.startDate', findSortStatus: 'ASC'},
            {name: 'Спочатку найнижча очікувана вартість', status: false, findParamName: 'value.amount', findSortStatus: 'ASC'},
            {name: 'Спочатку найвища очікувана вартість', status: false, findParamName: 'value.amount', findSortStatus: 'DESC'},
        ]
    }else if(data === "plans"){
        return [
            {name: 'Спочатку найновіші створені', status: true, findParamName: 'datePublished', findSortStatus: 'DESC'},
            {name: 'Спочатку найстарші створені', status: false, findParamName: 'datePublished', findSortStatus: 'ASC'},
            {name: 'Спочатку найнижча очікувана вартість', status: false, findParamName: 'budget.amount', findSortStatus: 'ASC'},
            {name: 'Спочатку найвища очікувана вартість', status: false, findParamName: 'budget.amount', findSortStatus: 'DESC'},
        ]
    }else if(data === "contracts"){
        return [
            {name: 'Спочатку найновіші створені', status: true, findParamName: 'dateSigned', findSortStatus: 'DESC'},
            {name: 'Спочатку найстарші створені', status: false, findParamName: 'dateSigned', findSortStatus: 'ASC'},
            {name: 'Спочатку найнижча сума договору', status: false, findParamName: 'value.amount', findSortStatus: 'ASC'},
            {name: 'Спочатку найвища сума договору', status: false, findParamName: 'value.amount', findSortStatus: 'DESC'},
        ]
    }
    return null
}