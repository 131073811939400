import React from 'react';
import styles from './notFound.module.css';

import {connect} from 'react-redux';

import {
    setArhStatusAuction,
    openVidjetBuyersAuctionArr,
    openVidjetOrganizatorAuctionArr,
    openVidjetClassificationAuctionArr,
    openVidjetSimpleFilterAuctionRegionProc,
    openVidjetProcurementMethodTypeProc,
    openVidjetSimpleFilterAuctionTypeSubjectProcurement,
    openVidjetSimpleFilterAuctionTypePayment,
    openVidjetfilterEnquiryPeriodFrom,
    openVidjetfilterEnquiryPeriodTo,
    openVidjetfilterAuctionPeriodFrom,
    openVidjetfilterAuctionPeriodTo,
    openVidjetfilterTenderPeriodFrom,
    openVidjetfilterTenderPeriodTo,
    openVidjetfilterValueAmountFrom,
    openVidjetfilterValueAmountTo,
    setSearchTextSting,
    auctionsGetSearch,
} from '../../../redux/actions/auctions/auctions'

import {
    setCurrentUrl,
    setArraySortList,
} from '../../../redux/filters'

import {
    changeMyCurentPage,
    changeArraySortList,
} from '../../../redux/actions.js'


const NotFound = ( props ) => {
    return (
        <div className={styles.NoPurchaseWrap}>
            <div className={styles.NoPurchase}>
                <h3>Інформації не знайдено</h3>
                <p>За вашим запитом 
                    <span className={styles.MyReqest}> '{props.search.textSting}' </span> 
                    нічого не знайдено
                </p>
                <p>Спробуйте змінити запит або змініть фільтри</p>
                <div
                    className={styles.ResetBtn}
                    onClick={
                        ()=>{ 
                            //handleClear(props)
                            document.location.reload();
                        }
                    }
                >
                    Очистити пошуковий запит
                </div>
                
            </div>
        </div>
    )
}

function handleClear(props){
    props.changeArraySortList(setArraySortList(props.search.url))
    ///Очистка статус
    props.setArhStatusAuction([
        {status: 'active.tendering', title: 'Подання пропозицій', onOff: false, type: 1},
        {status: 'active.auction', title: 'Аукціон', onOff: false, type: 1},
        {status: 'active.qualification', title: 'Кваліфікація переможця', onOff: false, type: 1},
        {status: 'active.rectification', title: 'Підписання договору', onOff: false, type: 1},
        {status: 'complete', title: 'Торги завершено', onOff: false, type: 1},
        {status: 'unsuccessful', title: 'Торги не відбулися', onOff: false, type: 1},
        {status: 'cancelled', title: 'Торги скасовано', onOff: false, type: 1},
        {status: 'pending', title: 'Очікується підписання Договору', onOff: false, type: 2},
        {status: 'active', title: 'Договір підписано', onOff: false, type: 2},
        {status: 'cancelled', title: 'Договір скасовано', onOff: false, type: 2},
        {status: 'terminated', title: 'Договір виконано', onOff: false, type: 2},
    ])
    //Очистка Поставщик
    props.openVidjetBuyersAuctionArr([])
    //Очистка Организация
    props.openVidjetOrganizatorAuctionArr([])
    //Очистка Классификатор
    props.openVidjetClassificationAuctionArr([])
    //Регион
    props.openVidjetSimpleFilterAuctionRegionProc([
        {id: 'Автономна Республіка Крим', title: "АВТОНОМНА РЕСПУБЛІКА КРИМ/М.СІМФЕРОПОЛЬ", onOff: false},
        {id: 'Вінницька область', title: "ВІННИЦЬКА ОБЛАСТЬ/М.ВІННИЦЯ", onOff: false},
        {id: 'Волинська область', title: "ВОЛИНСЬКА ОБЛАСТЬ/М.ЛУЦЬК", onOff: false},
        {id: 'Дніпропетровська область', title: "ДНІПРОПЕТРОВСЬКА ОБЛАСТЬ/М.ДНІПРО", onOff: false},
        {id: 'Донецька область', title: "ДОНЕЦЬКА ОБЛАСТЬ/М.ДОНЕЦЬК", onOff: false},
        {id: 'Житомирська область', title: "ЖИТОМИРСЬКА ОБЛАСТЬ/М.ЖИТОМИР", onOff: false},
        {id: 'Закарпатська область', title: "ЗАКАРПАТСЬКА ОБЛАСТЬ/М.УЖГОРОД", onOff: false},
        {id: 'Запорізька область', title: "ЗАПОРІЗЬКА ОБЛАСТЬ/М.ЗАПОРІЖЖЯ", onOff: false},
        {id: 'Івано-Франківська область', title: "ІВАНО-ФРАНКІВСЬКА ОБЛАСТЬ/М.ІВАНО-ФРАНКІВСЬК", onOff: false},
        {id: 'Київська область', title: "КИЇВСЬКА ОБЛАСТЬ/М.КИЇВ", onOff: false},
        {id: 'Кіровоградська область', title: "КІРОВОГРАДСЬКА ОБЛАСТЬ/М.КРОПИВНИЦЬКИЙ", onOff: false},
        {id: 'Луганська область', title: "ЛУГАНСЬКА ОБЛАСТЬ/М.ЛУГАНСЬК", onOff: false},
        {id: 'Львівська область', title: "ЛЬВІВСЬКА ОБЛАСТЬ/М.ЛЬВІВ", onOff: false},
        {id: 'Миколаївська область', title: "МИКОЛАЇВСЬКА ОБЛАСТЬ/М.МИКОЛАЇВ", onOff: false},
        {id: 'Одеська область', title: "ОДЕСЬКА ОБЛАСТЬ/М.ОДЕСА", onOff: false},
        {id: 'Полтавська область', title: "ПОЛТАВСЬКА ОБЛАСТЬ/М.ПОЛТАВА", onOff: false},
        {id: 'Рівненська область', title: "РІВНЕНСЬКА ОБЛАСТЬ/М.РІВНЕ", onOff: false},
        {id: 'Сумська область', title: "СУМСЬКА ОБЛАСТЬ/М.СУМИ", onOff: false},
        {id: 'Тернопільська область', title: "ТЕРНОПІЛЬСЬКА ОБЛАСТЬ/М.ТЕРНОПІЛЬ", onOff: false},
        {id: 'Харківська область', title: "ХАРКІВСЬКА ОБЛАСТЬ/М.ХАРКІВ", onOff: false},
        {id: 'Херсонська область', title: "ХЕРСОНСЬКА ОБЛАСТЬ/М.ХЕРСОН", onOff: false},
        {id: 'Хмельницька область', title: "ХМЕЛЬНИЦЬКА ОБЛАСТЬ/М.ХМЕЛЬНИЦЬКИЙ", onOff: false},
        {id: 'Черкаська область', title: "ЧЕРКАСЬКА ОБЛАСТЬ/М.ЧЕРКАСИ", onOff: false},
        {id: 'Чернівецька область', title: "ЧЕРНІВЕЦЬКА ОБЛАСТЬ/М.ЧЕРНІВЦІ", onOff: false},
        {id: 'Чернігівська область', title: "ЧЕРНІГІВСЬКА ОБЛАСТЬ/М.ЧЕРНІГІВ", onOff: false},
        {id: 'Київ', title: "М.КИЇВ", onOff: false},
        {id: 'Севастополь', title: "М.СЕВАСТОПОЛЬ", onOff: false},
    ])
    //Тип процедур
    props.openVidjetProcurementMethodTypeProc([
        {id: 'aboveThresholdUA', title: "Відкриті торги", onOff: false},
            {id: 'esco', title: "Відкриті торги для закупівлі енергоресурсу", onOff: false},
            {id: 'aboveThresholdEU', title: "Відкриті торги з публікацією англійською мовою", onOff: false},
            {id: 'reporting', title: "Закупівля без використання електронної системи", onOff: false},
            {id: 'reporting', title: "Закупівлі по COVID-19", onOff: false},
            {id: 'priceQuotation', title: "Запит ціни пропозиції", onOff: false},
            {id: 'competitiveDialogueUA', title: "Конкурентний діалог 1-ий етап", onOff: false},
            {id: 'competitiveDialogueEU', title: "Конкурентний діалог з публікацією англійською мовою 1-ий етап", onOff: false},
            {id: 'competitiveDialogueUA.stage2', title: "Конкурентний діалог 2-ий етап", onOff: false},
            {id: 'competitiveDialogueEU.stage2', title: "Конкурентний діалог з публікацією англійською мовою 2-ий етап", onOff: false},
            {id: 'negotiation', title: "Переговорна процедура", onOff: false},
            {id: 'negotiation.quick', title: "Переговорна процедура (скорочена)", onOff: false},
            {id: 'aboveThresholdUA.defense', title: "Переговорна процедура для потреб оборони", onOff: false},
            {id: 'belowThreshold', title: "Спрощені закупівлі", onOff: false},
            {id: 'belowThreshold', title: "Спрощені / Допорогові закупівлі", onOff: false},
            {id: 'simple.defense', title: "Спрощені торги із застосуванням електронної системи", onOff: false},
            {id: 'closeFrameworkAgreementUA', title: "Укладання рамкової угоди", onOff: false},
            {id: 'reporting', title: "Prozorro Market", onOff: false},
            {id: 'closeFrameworkAgreementSelectionUA', title: "Відбір для закупівлі за рамковою угодою", onOff: false},
    ])
    //Від предмета закупівлі
    props.openVidjetSimpleFilterAuctionTypeSubjectProcurement('null')
    //Тип оплаты
    props.openVidjetSimpleFilterAuctionTypePayment('null')
    //Сума
    props.openVidjetfilterValueAmountFrom('')
    props.openVidjetfilterValueAmountTo('')
    //Даті
    props.openVidjetfilterEnquiryPeriodFrom('')
    props.openVidjetfilterEnquiryPeriodTo('')
    props.openVidjetfilterAuctionPeriodFrom('')
    props.openVidjetfilterAuctionPeriodTo('')
    props.openVidjetfilterTenderPeriodFrom('')
    props.openVidjetfilterTenderPeriodTo('')
    props.setSearchTextSting('')
    let data = {}
    data.path = setCurrentUrl(props.search)
    data.domen = process.env.REACT_APP_END_POINT_BD
    data.version = 'v1'
    data.local = props.langFlag
    data.params = `?page=1`
    let header = {
        'Content-Type': 'application/json'
    }
    window.scrollTo(0, 370)
    props.auctionsGetSearch(data, header)
    props.changeMyCurentPage(1)
}

const mapStateToProps = state => {
    return {
        search: state.start.search,
    }
}

const mapDispatchToProps = {
    setArhStatusAuction,
    openVidjetBuyersAuctionArr,
    openVidjetOrganizatorAuctionArr,
    openVidjetClassificationAuctionArr,
    openVidjetSimpleFilterAuctionRegionProc,
    openVidjetProcurementMethodTypeProc,
    openVidjetSimpleFilterAuctionTypeSubjectProcurement,
    openVidjetSimpleFilterAuctionTypePayment,
    openVidjetfilterEnquiryPeriodFrom,
    openVidjetfilterEnquiryPeriodTo,
    openVidjetfilterAuctionPeriodFrom,
    openVidjetfilterAuctionPeriodTo,
    openVidjetfilterTenderPeriodFrom,
    openVidjetfilterTenderPeriodTo,
    openVidjetfilterValueAmountFrom,
    openVidjetfilterValueAmountTo,
    setSearchTextSting,
    changeArraySortList,

    setCurrentUrl,
    auctionsGetSearch,
    changeMyCurentPage,
}

export default connect(mapStateToProps, mapDispatchToProps)(NotFound)