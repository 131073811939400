import React,{ useState, useEffect } from 'react';
import styles from './itemProps.module.css'
import {useSelector, useDispatch} from 'react-redux';
import { checkProp, isMongo, dictionaryGet, getAdditionalClassificatorDM } from '../../../../../../../../../utils/check';
import Input from '../../../../../../../../inputs/input';
import InputTextArea from '../../../../../../../../inputs/inputTextArea';
import InputSelect from '../../../../../../../../inputs/inputSelect';
import InputDate from '../../../../../../../../inputs/inputDate';
import Button from '../../../../../../../../elements/buttons/button';
import Localization from '../localization/localization';
import Checkbox from '../../../../../../../../inputs/checkBox';
import { objectItemCreate, objectItemUpdate } from '../../../../../../../../../utils/objects/objectsUtils';
import Loader from '../../../../../../../../loader/loader';

const RealEstatePropsEdit =({ data, classification, close })=> {
    
    const objectData = useSelector(state => state.start.objectGet);
    const token = useSelector(state => state.start.token);

    const constructionTechnologyList = useSelector(state => state.start.objectConstructionTechnologyList)
    // const objectOwnershipTypeList = useSelector(state => state.start.objectOwnershipTypeList)
    // const assetRegistrationStatus = useSelector(state => state.start.assetRegistrationStatus)
    const objectPowerSupplyClassList = useSelector(state => state.start.objectPowerSupplyClassList)
    const floorTypeList = useSelector(state => state.start.objectFloorTypeList)
    const dispatch = useDispatch()

    const [loader, setLoader] = useState( false )

    const [title, setTitle] = useState( data?.basicInfo.title ? isMongo(data.basicInfo.title) : null )
    const [description, setDescription] = useState( data?.basicInfo.description ? isMongo(data.basicInfo.description) : null )
    const [location, setLocation] = useState( data?.placing.location ? data.placing.location : null )
    const [address, setAdress] = useState( data?.placing.address ? data.placing.address : null )

    const [totalBuildingArea, setTotalBuildingArea] = useState( data?.reProps?.totalBuildingArea ? data.reProps.totalBuildingArea : null)
    const [totalObjectArea, setTotalObjectArea] = useState( data?.reProps?.totalObjectArea ? data.reProps.totalObjectArea : null)
    const [usableArea, setUsableArea] = useState( data?.reProps?.usableArea ? data.reProps.usableArea : null)
    const [constructionYear, setConstructionYear] = useState( data?.reProps?.constructionYear ? data.reProps.constructionYear : null)
    const [constructionTechnology, setConstructionTechnology] = useState( data?.reProps?.constructionTechnology ? data.reProps.constructionTechnology : null)
    const [livingArea, setLivingArea] = useState( data?.reProps?.livingArea ? data.reProps.livingArea : null)
    const [kitchenArea, setKitchenArea] = useState( data?.reProps?.kitchenArea ? data.reProps.kitchenArea : null)
    const [landArea, setLandArea] = useState( data?.reProps?.landArea ? data.reProps.landArea : null)
    const [locationInBuilding, setLocationInBuilding] = useState( data?.reProps?.locationInBuilding ? data.reProps.locationInBuilding : null)
    const [floors, setFloors] = useState( data?.reProps?.floors ? data.reProps.floors : null)
    const [generalCondition, setGeneralCondition] = useState( data?.reProps?.generalCondition ? data.reProps.generalCondition : null)
    const [serviceElectricity, setServiceElectricity] = useState( checkProp(data,'reProps') && checkProp(data.reProps, 'serviceElectricity') ? data.reProps.serviceElectricity : false)
    const [powerSupplyCapacity, setPowerSupplyCapacity] = useState( data?.reProps?.powerSupplyCapacity ? data.reProps.powerSupplyCapacity : null)
    const [powerSupplyClass, setPowerSupplyClass] = useState( data?.reProps?.powerSupplyClass ? data.reProps.powerSupplyClass : null)
    const [serviceWater, setServiceWater] = useState( checkProp(data,'reProps') && checkProp(data.reProps, 'serviceWater') ? data.reProps.serviceWater : false)
    const [serviceSewerage, setServiceSewerage] = useState( checkProp(data,'reProps') && checkProp(data.reProps, 'serviceSewerage') ? data.reProps.serviceSewerage : false)
    const [serviceGas, setServiceGas] = useState( checkProp(data,'reProps') && checkProp(data.reProps, 'serviceGas') ? data.reProps.serviceGas : false)
    const [serviceCentralHeating, setServiceCentralHeating] = useState( checkProp(data,'reProps') && checkProp(data.reProps, 'serviceCentralHeating') ? data.reProps.serviceCentralHeating : false)
    const [serviceAutonomousHeating, setServiceAutonomousHeating] = useState( checkProp(data,'reProps') && checkProp(data.reProps, 'serviceAutonomousHeating') ? data.reProps.serviceAutonomousHeating : false)
    const [serviceHeatingCounter, setServiceHeatingCounter] = useState( checkProp(data,'reProps') && checkProp(data.reProps, 'serviceHeatingCounter') ? data.reProps.serviceHeatingCounter : false)
    const [serviceVentilation, setServiceVentilation] = useState( checkProp(data,'reProps') && checkProp(data.reProps, 'serviceVentilation') ? data.reProps.serviceVentilation : false)
    const [serviceAirConditioning, setServiceAirConditioning] = useState( checkProp(data,'reProps') && checkProp(data.reProps, 'serviceAirConditioning') ? data.reProps.serviceAirConditioning : false)
    const [servicePhone, setServicePhoneg] = useState( checkProp(data,'reProps') && checkProp(data.reProps, 'servicePhone') ? data.reProps.servicePhone : false)
    const [serviceTV, setServiceTV] = useState( checkProp(data,'reProps') && checkProp(data.reProps, 'serviceTV') ? data.reProps.serviceTV : false)
    const [serviceInternet, setServiceInternet] = useState( checkProp(data,'reProps') && checkProp(data.reProps, 'serviceInternet') ? data.reProps.serviceInternet : false)
    const [serviceElevator, setServiceElevator] = useState( checkProp(data,'reProps') && checkProp(data.reProps, 'serviceElevator') ? data.reProps.serviceElevator : false)
    const [serviceSecurityAlarm, setServiceSecurityAlarm] = useState( checkProp(data,'reProps') && checkProp(data.reProps, 'serviceSecurityAlarm') ? data.reProps.serviceSecurityAlarm : false)
    const [serviceFireAlarm, setServiceFireAlarm] = useState( checkProp(data,'reProps') && checkProp(data.reProps, 'serviceFireAlarm') ? data.reProps.serviceFireAlarm : false)
    const [servicesDescription, setServicesDescription] = useState( checkProp(data,'reProps') && checkProp(data.reProps, 'servicesDescription') ? data.reProps.servicesDescription : null)
    const [servicesAccounting, setServicesAccounting] = useState( checkProp(data,'reProps') && checkProp(data.reProps, 'servicesAccounting') ? data.reProps.servicesAccounting : null)
    const [landTax, setLandTax] = useState( checkProp(data,'reProps') && checkProp(data.reProps, 'landTax') ? data.reProps.landTax : null)
    
    useEffect( () => {
        // if ( assetRegistrationStatus === null ) {
        //     dispatch( dictionaryGet('registrationStatus', 'ASSETT_GET_REGISTRATION_STATUS') );
        // }
        if ( constructionTechnologyList === null ) {
            dispatch( dictionaryGet('constructionTechnology', 'OBJECT_GET_CONSTRUCTION_TECHNOLOGY_LIST') );
        }
        if ( objectPowerSupplyClassList === null ) {
            dispatch( dictionaryGet('powerSupplyClass', 'OBJECT_GET_POWER_SUPPLY_CLASS_LIST') );
        }
        if ( floorTypeList === null ) {
            dispatch( dictionaryGet('floorType', 'OBJECT_GET_FLOOR_TYPE_LIST') );
        }

    }, []);

    const requestBody = {
        itemType: 'realEstate',
        basicInfo: {
            description: { uk_UA: description },
            title: { uk_UA: title },
            classification: classification,
        },
        placing: {
            location: location,
            address: address
        },
        reProps: {
            // itemPropsType: 'realEstate',
            totalBuildingArea: +totalBuildingArea,
            totalObjectArea: +totalObjectArea,
            usableArea: +usableArea,
            constructionYear: constructionYear,
            constructionTechnology:constructionTechnology, //dic
            livingArea: livingArea,
            kitchenArea: kitchenArea,
            landArea: landArea,
            locationInBuilding: locationInBuilding, // dic
            floors: floors,
            generalCondition: generalCondition,
            serviceElectricity: serviceElectricity,
            powerSupplyCapacity: powerSupplyCapacity,
            powerSupplyClass: powerSupplyClass, // dic
            serviceWater: serviceWater,
            serviceSewerage: serviceSewerage,
            serviceGas: serviceGas,
            serviceCentralHeating: serviceCentralHeating,
            serviceAutonomousHeating: serviceAutonomousHeating,
            serviceHeatingCounter: serviceHeatingCounter,
            serviceVentilation: serviceVentilation,
            serviceAirConditioning: serviceAirConditioning,
            servicePhone: servicePhone,
            serviceTV: serviceTV,
            serviceInternet: serviceInternet,
            serviceElevator: serviceElevator,
            serviceSecurityAlarm: serviceSecurityAlarm,
            serviceFireAlarm: serviceFireAlarm,
            servicesDescription: servicesDescription,
            servicesAccounting: servicesAccounting,
            landTax: landTax
        }
    }

    function sendItemData( data ){
        const body = JSON.stringify(requestBody)
        if ( !data ) dispatch ( objectItemCreate(objectData.id, body, token.access_token ))
        if ( !!data ) dispatch ( objectItemUpdate(objectData.id, data.id, body, token.access_token ))
        setLoader( true )
        close()
    }

    function viewButtons(){
        return (
            <div className={styles.lineButtons}>
                <Button
                    data={'Зберегти зміни'}
                    color={'blue'}
                    onClick={
                        () => { sendItemData( data ) }
                    }
                />
                <Button
                    data={'Закрити'}
                    color={'gray'}
                    onClick={ close }
                />
            </div>
        )
    }

    if( loader ) return <Loader />
    return (
        <>
            <Input
                label="Назва об'єкта"
                value={ title }
                onChange={ event => setTitle( event ) }
                reqiured
            />
            <InputTextArea
                value={description}
                // disabled={blokedInput(props, '01')}
                label="Опис об'єкта"
                required
                onChange={ event => setDescription(event) }
                reqiured
            />
            <div className={styles.semiTitle}>Характеристики нерухомого майна</div>
                <Input
                    label="Загальна площа об'єкту оренди, кв.м."
                    value={ totalObjectArea }
                    onChange={ event => {
                        if(Number(event) <= 60362800){
                            let tmp = event.replace(/,/gi, '.')
                            setTotalObjectArea(tmp)
                        }
                    }}
                    reqiured
                />
                <Input
                    label="Загальна площа будівлі, до складу якої входить об'єкт оренди, кв.м."
                    value={ totalBuildingArea }
                    onChange={ event => { if (Number(event) <= 60362800){
                        let tmp = event.replace(/,/gi, '.')
                        setTotalBuildingArea(tmp) }
                    }}
                    // disabled={blokedInput(props, '11')}
                />
                
            <div className={styles.twoInLine}>
            <Input
                    label="Корисна площа об'єкту оренди, кв.м."
                    value={ usableArea }
                    onChange={ event => {
                        if ( Number(event) <= 60362800){
                            let tmp = event.replace(/,/gi, '.')
                            setUsableArea(tmp)
                        }
                    }}
                />
                <Input
                    label="Житлова площа, кв. м"
                    value={livingArea}
                    onChange={ event => { if (Number(event) <= 60362800){
                        let tmp = event.replace(/,/gi, '.')
                        setLivingArea(tmp) }
                    }}
                    // disabled={blokedInput(props, '11')}
                />
            </div>
            <div className={styles.twoInLine}>
                <Input
                    label="Площа кухні, кв. м."
                    value={ kitchenArea }
                    onChange={ event => {
                        if ( Number(event) <= 60362800){
                            let tmp = event.replace(/,/gi, '.')
                            setKitchenArea(tmp)
                        }
                    }}
                />
                <Input
                    label="Площа ділянки, кв. м"
                    value={landArea}
                    onChange={ event => { if (Number(event) <= 60362800){
                        let tmp = event.replace(/,/gi, '.')
                        setLandArea(tmp) }
                    }}
                    // disabled={blokedInput(props, '11')}
                />
            </div>
            <div className={styles.twoInLine}>
                <Input
                    label="Рік будівництва"
                    value={constructionYear}
                    onChange={ event => setConstructionYear(event) }
                    // disabled={blokedInput(props, '11')}
                />
                <InputSelect
                    label={"Технологія будівництва"}
                    value={ constructionTechnology }
                    onChange={ event => setConstructionTechnology(event) }
                    options={ constructionTechnologyList }
                    dictionary
                />
            </div>
            <div className={styles.twoInLine}>
                <Input
                    label="Номер поверху або поверхів"
                    value={floors}
                    onChange={ event => setFloors(event) }
                    // disabled={blokedInput(props, '11')}
                />
                <InputSelect
                    label={"Місце розташування об’єкта в будівлі"}
                    value={ locationInBuilding }
                    onChange={ event => setLocationInBuilding(event) }
                    options={ floorTypeList }
                    dictionary
                />
            </div>
            <InputTextArea
                label="Технічний стан об'єкта оренди"
                value={generalCondition}
                onChange={ event => setGeneralCondition(event) }
                // disabled={blokedInput(props, '11')}
            />
            <Checkbox
                label="Чи приєднаний об'єкт оренди до електромережі"
                value={serviceElectricity}
                onChange={ event => setServiceElectricity(event) }
                // disabled={blokedInput(props, '11')}
            />
            { serviceElectricity &&
                <div className={styles.twoInLine}>
                    <Input
                        label="Потужність електромережі, кВт"
                        value={powerSupplyCapacity}
                        onChange={ event => { if (Number(event) <= 60362800){
                            let tmp = event.replace(/,/gi, '.')
                            setPowerSupplyCapacity(tmp) }
                        }}
                        // disabled={blokedInput(props, '11')}
                    />
                    <InputSelect
                        label={"Ступінь потужності електромережі"}
                        value={ powerSupplyClass }
                        onChange={ event => setPowerSupplyClass(event) }
                        options={ objectPowerSupplyClassList }
                        dictionary
                    />
                </div>
            }
            <div className={styles.twoInLine}>
                <Checkbox
                    label="Водозабезпечення присутнє"
                    value={serviceWater}
                    onChange={ event => setServiceWater(event) }
                    // disabled={blokedInput(props, '11')}
                />
                <Checkbox
                    label="Каналізація присутня"
                    value={serviceSewerage}
                    onChange={ event => setServiceSewerage(event) }
                    // disabled={blokedInput(props, '11')}
                />
            </div>
            <div className={styles.twoInLine}>
                <Checkbox
                    label="Газифікація присутня"
                    value={serviceGas}
                    onChange={ event => setServiceGas(event) }
                    // disabled={blokedInput(props, '11')}
                />
                <Checkbox
                    label="Централізоване опалення присутнє"
                    value={serviceCentralHeating}
                    onChange={ event => setServiceCentralHeating(event) }
                    // disabled={blokedInput(props, '11')}
                />
            </div>
            <div className={styles.twoInLine}>
                <Checkbox
                    label="Автономне опалення присутнє"
                    value={serviceAutonomousHeating}
                    onChange={ event => setServiceAutonomousHeating(event) }
                    // disabled={blokedInput(props, '11')}
                />
                <Checkbox
                    label="Лічильник опалення присутній"
                    value={serviceHeatingCounter}
                    onChange={ event => setServiceHeatingCounter(event) }
                    // disabled={blokedInput(props, '11')}
                />
            </div>
            <div className={styles.twoInLine}>
                <Checkbox
                    label="Вентиляція присутня"
                    value={serviceVentilation}
                    onChange={ event => setServiceVentilation(event) }
                    // disabled={blokedInput(props, '11')}
                />
                <Checkbox
                    label="Кондиціонування присутнє"
                    value={serviceAirConditioning}
                    onChange={ event => setServiceAirConditioning(event) }
                    // disabled={blokedInput(props, '11')}
                />
            </div>
            <div className={styles.twoInLine}>
                <Checkbox
                    label="Телефонізація присутня"
                    value={servicePhone}
                    onChange={ event => setServicePhoneg(event) }
                    // disabled={blokedInput(props, '11')}
                />
                <Checkbox
                    label="Телебачення присутнє"
                    value={serviceTV}
                    onChange={ event => setServiceTV(event) }
                    // disabled={blokedInput(props, '11')}
                />
            </div>
            <div className={styles.twoInLine}>
                <Checkbox
                    label="Інтернет присутній"
                    value={serviceInternet}
                    onChange={ event => setServiceInternet(event) }
                    // disabled={blokedInput(props, '11')}
                />
                <Checkbox
                    label="Ліфт присутній"
                    value={serviceElevator}
                    onChange={ event => setServiceElevator(event) }
                    // disabled={blokedInput(props, '11')}
                />
            </div>
            <div className={styles.twoInLine}>
                <Checkbox
                    label="Охоронна сигналізація присутня"
                    value={serviceSecurityAlarm	}
                    onChange={ event => setServiceSecurityAlarm(event) }
                    // disabled={blokedInput(props, '11')}
                />
                <Checkbox
                    label="Пожежна сигналізація присутня"
                    value={serviceFireAlarm}
                    onChange={ event => setServiceFireAlarm(event) }
                    // disabled={blokedInput(props, '11')}
                />
            </div>
            <InputTextArea
                label="Додаткова інформація щодо комунікацій, що є в об'єкті"
                value={servicesDescription}
                onChange={ event => setServicesDescription( event ) }
                // disabled={blokedInput(props, '11')}
            />
            <InputTextArea
                label="Інформація про наявність окремих особових рахунків на об'єкт оренди, відкритих постачальниками комунальних послуг, або інформація про порядок участі орендаря у компенсації балансоутримувачу витрат на оплату комунальних послуг"
                value={servicesAccounting}
                onChange={ event => setServicesAccounting( event ) }
                // disabled={blokedInput(props, '11')}
            />
            <InputTextArea
                label="Інформація щодо компенсації балансоутримувачу сплати земельного податку за користування земельною ділянкою, на якій розташований об'єкт оренди"
                value={landTax}
                onChange={ event => setLandTax( event ) }
                // disabled={blokedInput(props, '11')}
            />


            <Localization address={address} location={location} setLocation={setLocation} setAdress={setAdress} classification={classification} />
            { viewButtons() }
        </>
    )
}

export default RealEstatePropsEdit
