import React, {useState, useEffect} from 'react';
import styles from './buttonFilters.module.css';
import Checkbox from '../../../../inputs/checkBox';
import Button from '../../../../elements/buttons/button';

    const Popup = ({ setParams, arr, setPopup }) => {
        
        const [status] = useState( [
            { value: 'waiting', name: 'Очікується', check: false },
            { value: 'approved', name: 'Включено в перелік', check: false },
            { value: 'inactive', name: 'Неактивний', check: false },
            { value: 'active', name: 'Опубліковано оголошення', check: false },
            { value: 'qualification', name: 'Визначення орендаря', check: false },
            { value: 'sold', name: 'Орендовано', check: false },
        ])

            return (
                    <div className={styles.wrapMain}>
                        <div className={styles.wrap}>
                            <h3 className={styles.filterTitle}>Статус об'єкта</h3>
                            <div className={styles.content}>
                                { status.map( item => <Checkbox 
                                        value={ ( !!arr?.status && arr.status.includes(item.value) ) ? true : false }
                                        label={ item.name }
                                        onChange={ ()=> setParams( prev => {
                                            if ( !!arr?.status && arr.status.includes(item.value) ){
                                                let tmp = prev.status
                                                return {...prev, status: tmp.filter( i => i !== item.value ) }
                                            } else {
                                                if ( !!prev?.status && !!prev?.status.length === 0) {
                                                    return {...prev, status: [...status, item.value] }
                                                }
                                                if ( !prev?.status) return {...prev, status: [ item.value ] }
                                                else {
                                                    let tmp = prev.status
                                                    tmp = [...tmp, item.value]
                                                    return {...prev, status: tmp }
                                                }
                                            }
                                        } )}
                                    />)
                                }
                            </div>
                            <div className={styles.bottomPart}>
                                <Button 
                                    color='blue'
                                    onClick={() => setPopup( prev => !prev) }>
                                    Готово
                                </Button>
                            </div>
                        </div>
                    </div>
                )
    }

const LeaseObjectStatusSearch = ({ setParams, arr })=> {
    const [popup, setPopup] = useState( false )

    return (
        <>
            <div
                className={styles.searchFilterBlock_status}
                onClick={ () => setPopup( prev => !prev) }
            >
                <div>Статус об'єкта</div>
                { ( !!arr?.status && arr.status.length > 0 )&& 
                    <div style={{borderLeft: '1px dotted #BFBFC6', paddingLeft: '5px', marginLeft: '5px'}}>
                        { arr.status.length }
                    </div> 
                }
            </div>
            { popup && <Popup setParams={ setParams } arr={ arr } setPopup={ setPopup } />}
        </>
    )
}

export default LeaseObjectStatusSearch
