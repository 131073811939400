import React from 'react';
import { isMongo } from '../../../../../utils/check';
import styles from './halfInfoBlock.module.css';
import { Link } from 'react-router-dom';

const HalfInfoBlock =( { title, data, width = '50', moreData, link} )=> {
    if (typeof data === 'undefined') return null

    return (
        <div className={styles.blockHalf} style={{width: `${width}%`}}>
            <div className={styles.description}>{title}</div>
            { 
                !!link
                ? <a href={ link } target='blank'><div className={styles.data} style={{color: 'var(--blue)'}}>{isMongo(data)}</div></a> 
                : <div className={styles.data}>{isMongo(data)}</div>
            }
            { !!moreData ? <div className={styles.data}>{moreData}</div> : null }
        </div>
    )
}

export default HalfInfoBlock