import React from 'react';
import styles from './announcementItemCard.module.css';
import { useSelector } from 'react-redux';
import {Link, useNavigate} from 'react-router-dom';
import InfoModal from '../infoIconBlock/infoIconBlock'
import { checkProp, numFormat, getCurrency, getAddedTax, dateTransform, 
    isMongo, setAnnouncementStatus } from '../../../../../utils/check'
// import moment from 'moment';

const AnnouncementItemCard = ( { item } ) => {
    const user = useSelector(state => state.start.userProfiles)
    const navigate = useNavigate()

    //бейдж "Ваш обьект"
    function yourObjectBadge() {
        let status = false
        if (!user) return null
        if (user && item && item.assetCustodian) {
            if ( !!item?.assetCustodian?.identifier?.id && item.assetCustodian.identifier.id === user.organization.identifier.identifier) {
                status = true
            }
        }
        if (status) return <div className={styles.yourObjBadge}>Ваше повідомлення</div>
        else return null 
    }

    function getAmount() {
        if (checkProp(item, 'initialProps')) {
            if (checkProp(item.initialProps, 'value')) {
            
                let blockTmp = item.initialProps.value
                let amount = checkProp(blockTmp, 'amount') ? numFormat.format(blockTmp.amount) : ' не вказано'

                return (
                    <>
                        <div className={styles.costText}>{`Стартова ціна, ${getAddedTax(blockTmp.valueAddedTaxIncluded)}`}
                        </div>
                        <div className={styles.costSumm}>
                            {`${amount} ${getCurrency(blockTmp.currency)}`}
                        </div>
                    </>
                )
            }
        } else {
            return (
                <>
                    <div className={styles.costText}>{`Стартова ціна`}
                    </div>
                    <div className={styles.costSumm}>
                        {`не вказано`}
                    </div>
                </>
            )
        }
    }
    function getType( type ){
        switch(type){
            case 'asset': return 'Об\'єкт МП'
            case 'large_asset': return 'Об\'єкт ВП'
            default: return type
        }
    }

    return(
            <div className={styles.objItem}>
                <div className={styles.leftBlock}>
                    <div className={styles.unactive}></div>
                </div>
                <div className={styles.centralBlock}>
                    <div className={styles.topBlock}>
                        <div className={styles.objIdAndType}>
                            <div className={styles.objIdAndType_id} >
                                {item.objectId.startsWith('UA-')
                                    ?
                                    <a target={"_blank"} href={`https://prozorro.sale/planning/${item.objectId}/`}>{ item.objectId }</a>
                                    :
                                    <Link to={`/asset/announcement/${item.objectId}`}>{ item.objectId }</Link>
                                }
                            </div>
                            { yourObjectBadge() }
                            { checkProp(item, 'title') && <div style={{marginLeft: 'auto', color: 'var(--darkGray)'}}>Інформаційне повідомлення</div> }
                        </div>
                            <div className={styles.objTitle} >
                                {item.objectId.startsWith('UA-')
                                    ?
                                    <a target={"_blank"} href={`https://prozorro.sale/planning/${item.objectId}/`}>{ item.objectId }</a>
                                    :
                                    <Link to={`/asset/announcement/${item.objectId}`}>
                                        { !!item?.title ? isMongo( item.title ) : 'Інформаційне повідомлення' }
                                    </Link>
                                }
                            </div>
                        <div className={styles.objDiscription}>
                            {/* { item?.assetItems.map( item => <li key={item.id}>{ isMongo(item.classification.description) }</li> ) } */}
                        </div>
                    </div>
                    <div className={styles.bottomBlock}>
                        <div style={{color: '#696974'}}>Пов'язані сутності:</div>
                        { item.relatedEntities.map( i => <div className={styles.objOwner} key={i.objectId}>
                                                            <Link to={`/asset/${i.objectId}`}>{`${getType(i.type)} - ${i.objectId}`}</Link>
                                                        </div>)
                        }
                        {/* <div className={styles.objOwner}>
                            { !!item?.assetHolder && 
                                <>
                                    <span>Балансоутримувач:</span>
                                    { isMongo(item.assetHolder.name) }
                                        <InfoModal
                                            info={ item.assetHolder }
                                        /> 
                                    </>
                            }
                        </div> */}
                    </div>
                </div>
                <div className={styles.rightBlock}>
                <div className={`${styles.rightBlock_Status}`}>{ item?.status && setAnnouncementStatus( item.status ) }</div>
                    <div className={styles.rightBlock_Date}>
                        <div className={styles.rightBlock_Start}>
                            <div className={styles.rightBlock_date_text}>Дата публікації</div>
                            <div className={styles.rightBlock_date_time}>{ dateTransform( item.datePublished ) }
                                <span className={styles.rightBlock_date_timeSpan}>{dateTransform( item.datePublished, 'onlyTime' )}</span>
                            </div>
                        </div>
                        <div className={styles.rightBlock_Start}>
                            <div className={styles.rightBlock_date_text}>Дата зміни</div>
                            <div className={styles.rightBlock_date_time}>{ dateTransform( item.dateModified ) }
                                <span className={styles.rightBlock_date_timeSpan}>{dateTransform( item.dateModified, 'onlyTime' )}</span>
                            </div>
                        </div>
                    </div>
                    <div className={styles.rightBlock_CostAndButtons}>
                        { getAmount() }
                    </div>
                    <div className={styles.buttonsLine}>
                            <div className={styles.buttonView}
                                onClick={()=>{
                                    navigate(`/asset/announcement/${item.objectId}`)
                                }}>
                                    Переглянути
                            </div>
                    </div>
                </div>
            </div> 
    )
    
}

export default AnnouncementItemCard