import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FullInfoBlock from '../../../../../../elements/fullInfoBlock/fullInfoBlock';
import HalfInfoBlock from '../../../../../../elements/halfInfoBlock/halfInfoBlock';
import PopDownBlock from '../../../../../../elements/popdownBlock/popDownBlock';
import { checkProp, dictionaryGet, getTranslate } from '../../../../../../../utils/check';

const AssetItemPropsView =({ item })=> {

    const dispatch = useDispatch();
    const objectFuelTypeList = useSelector(state => state.start.objectFuelTypeList);
    const objectTransmissionTypeList = useSelector(state => state.start.objectTransmissionTypeList);
    const objectOwnershipTypeList = useSelector(state => state.start.objectOwnershipTypeList);
    const objectEncumbrancesList = useSelector(state => state.start.objectEncumbrancesList);
    const objectConstructionTechnologyList = useSelector(state => state.start.objectConstructionTechnologyList);
    const floorTypeList = useSelector(state => state.start.objectFloorTypeList)
    const objectPowerSupplyClassList = useSelector(state => state.start.objectPowerSupplyClassList)

    useEffect( () => {
        if ( objectOwnershipTypeList === null ) {
            dispatch( dictionaryGet('ownershipType', 'OBJECT_GET_OWNERSHIP_TYPE') );
        }
        if ( objectFuelTypeList === null ) {
            dispatch( dictionaryGet('fuelType', 'OBJECT_GET_FUEL_TYPE_LIST') );
        }
        if ( objectTransmissionTypeList === null ) {
            dispatch( dictionaryGet('transmissionType', 'OBJECT_GET_TRANSMISSION_TYPE_LIST') );
        }
        if ( objectEncumbrancesList === null ) {
            dispatch( dictionaryGet('encumbrances', 'OBJECT_GET_ENCUMBRANCES_LIST') );
        }
        if ( objectConstructionTechnologyList === null ) {
            dispatch( dictionaryGet('constructionTechnology', 'OBJECT_GET_CONSTRUCTION_TECHNOLOGY_LIST') );
        }
        if ( floorTypeList === null ) {
            dispatch( dictionaryGet('floorType', 'OBJECT_GET_FLOOR_TYPE_LIST') );
        }
        if ( objectPowerSupplyClassList === null ) {
            dispatch( dictionaryGet('powerSupplyClass', 'OBJECT_GET_POWER_SUPPLY_CLASS_LIST') );
        }
    }, []);

    const vehicleProps =( itemProps )=> {
        return (
            <>
                { !!itemProps.brand  &&
                    <HalfInfoBlock title='Марка транспортного засобу' data={ itemProps.brand} />
                }
                { !!itemProps.model  &&
                    <HalfInfoBlock title='Модель транспортного засобу' data={ itemProps.model} />
                }
                { !!itemProps.condition  &&
                    <FullInfoBlock title='Технічний стан' data={ itemProps.condition} />
                }
                { !!itemProps.identificationNumber  &&
                    <HalfInfoBlock title='Номер кузова або шасі' data={ itemProps.identificationNumber} />
                }
                { !!itemProps.productionYear  &&
                    <HalfInfoBlock title='Рік випуску' data={ itemProps.productionYear} />
                }
                { !!itemProps.engineCapacity &&
                    <HalfInfoBlock title='Об’єм двигуна' data={`${ itemProps.engineCapacity} см³`} />
                }
                { !!itemProps.fuelType  &&
                    <HalfInfoBlock title='Тип палива' data={ getTranslate( itemProps.fuelType ,objectFuelTypeList ) } />
                }
                { !!itemProps.transmission  &&
                    <HalfInfoBlock title='Тип коробки передач' data={ getTranslate( itemProps.transmission, objectTransmissionTypeList ) } />
                }
                { !!itemProps.kilometrage &&
                    <HalfInfoBlock title='Пробіг' data={`${ itemProps.kilometrage} км`} />
                }
                { !!itemProps.configuration  &&
                    <HalfInfoBlock title='Комплектація' data={ itemProps.configuration} />
                }
                { !!itemProps.color  &&
                    <HalfInfoBlock title='Колір' data={ itemProps.color} />
                }
                { !!itemProps.damagePresence && 
                    <>
                        <HalfInfoBlock title='Потреба у ремонті' data="Присутня" />
                        { !!itemProps.damagedDescription  &&
                            <FullInfoBlock title='Опис пошкоджень' data={ itemProps.damagedDescription} />
                        }
                    </>
                }
            </>)
    }

    const realEstateProps =( itemProps )=> {
        return (
            <>
            { checkProp(itemProps,'landArea') &&
                <HalfInfoBlock title='Площа ділянки' data={`${ itemProps.landArea} кв.м.`} />
            }
            { checkProp(itemProps,'constructionYear') &&
                <HalfInfoBlock title='Рік будівництва' data={ itemProps.constructionYear} />
            }
            { checkProp(itemProps,'generalCondition') &&
                <FullInfoBlock title='Технічний стан об`єкта' data={ itemProps.generalCondition} />
            }
            { checkProp(itemProps, 'constructionTechnology') &&
                    <HalfInfoBlock
                        title='Технологія будівництва'
                        data={ getTranslate(itemProps.constructionTechnology,objectConstructionTechnologyList ) }
                    />
            }
            { checkProp(itemProps,'totalBuildingArea') &&
                <HalfInfoBlock title='Загальна площа будівлі, до складу якої входить об`єкт' data={`${ itemProps.totalBuildingArea} кв.м.`} />
            }
            { checkProp(itemProps,'totalObjectArea') &&
                <HalfInfoBlock title='Загальна площа об`єкту *' data={`${ itemProps.totalObjectArea} кв.м.`} />
            }
            { checkProp(itemProps,'usableArea') &&
                <HalfInfoBlock title='Корисна площа об`єкту' data={`${ itemProps.usableArea} кв.м.`} />
            }
            { checkProp(itemProps,'livingArea') &&
                <HalfInfoBlock title='Житлова площа' data={`${ itemProps.livingArea} кв.м.`} />
            }
            { checkProp(itemProps,'kitchenArea') &&
                <HalfInfoBlock title='Площа кухні' data={ itemProps.kitchenArea} />
            }
            { checkProp(itemProps,'locationInBuilding') &&
                <HalfInfoBlock title='Місце розташування об’єкта в будівлі' data={ getTranslate( itemProps.locationInBuilding, floorTypeList )} />
            }
            { checkProp(itemProps,'floors') &&
                <HalfInfoBlock title='Номер поверху або поверхів' data={ itemProps.floors} />
            }
            { checkProp(itemProps,'powerSupplyCapacity') &&
                <HalfInfoBlock title='Чи приєднаний об`єкт до електромережі' data='Так' />
            }
            { checkProp(itemProps,'powerSupplyCapacity') &&
                    <HalfInfoBlock title='Потужність електромережі' data={`${ itemProps.powerSupplyCapacity} кВт`} />
            }
            { checkProp(itemProps,'powerSupplyClass') ?
                    <HalfInfoBlock title='Ступінь потужності електромережі' data={  getTranslate(itemProps.powerSupplyClass,objectPowerSupplyClassList) } />
             : <HalfInfoBlock title='Чи приєднаний об`єкт до електромережі' data='Ні' />
            }

            <HalfInfoBlock title='Водозабезпечення присутнє' data={ itemProps.serviceWater ? 'Так' : 'Ні'} />
            <HalfInfoBlock title='Каналізація присутня' data={ itemProps.serviceSewerage ? 'Так' : 'Ні'} />
            <HalfInfoBlock title='Газифікація присутня' data={ itemProps.serviceGas ? 'Так' : 'Ні'} />
            <HalfInfoBlock title='Централізоване опалення присутнє' data={ itemProps.serviceCentralHeating ? 'Так' : 'Ні'} />
            <HalfInfoBlock title='Автономне опалення присутнє' data={ itemProps.serviceAutonomousHeating ? 'Так' : 'Ні'} />
            <HalfInfoBlock title='Лічильник опалення присутній' data={ itemProps.serviceHeatingCounter ? 'Так' : 'Ні'} />
            <HalfInfoBlock title='Вентиляція присутня' data={ itemProps.serviceVentilation ? 'Так' : 'Ні'} />
            <HalfInfoBlock title='Кондиціонування присутнє' data={ itemProps.serviceAirConditioning ? 'Так' : 'Ні'} />
            <HalfInfoBlock title='Телефонізація присутня' data={ itemProps.servicePhone ? 'Так' : 'Ні'} />
            <HalfInfoBlock title='Телебачення присутнє' data={ itemProps.serviceTV ? 'Так' : 'Ні'} />
            <HalfInfoBlock title='Інтернет присутній' data={ itemProps.serviceInternet ? 'Так' : 'Ні'} />
            <HalfInfoBlock title='Ліфт присутній' data={ itemProps.serviceElevator ? 'Так' : 'Ні'} />
            <HalfInfoBlock title='Охоронна сигналізація присутня' data={ itemProps.serviceSecurityAlarm ? 'Так' : 'Ні'} />
            <HalfInfoBlock title='Пожежна сигналізація присутня' data={ itemProps.serviceFireAlarm ? 'Так' : 'Ні'} />
            { checkProp(itemProps,'servicesDescription') &&
                    <FullInfoBlock
                        title='Додаткова інформація щодо комунікацій, що є в об`єкті'
                        data={ itemProps.servicesDescription} 
                    />
            }
            { checkProp(itemProps,'servicesAccounting') &&
                    <FullInfoBlock
                        title='Інформація про наявність окремих особових рахунків на об`єкт, відкритих постачальниками комунальних послуг'
                        data={ itemProps.servicesAccounting} 
                    />
            }
            { checkProp(itemProps,'landTax') &&
                    <FullInfoBlock title='Інформація щодо компенсації балансоутримувачу сплати земельного податку за користування земельною ділянкою, на якій розташований об`єкт'
                        data={ itemProps.landTax} 
                    />
            }
        </>
            )
    }

    function hasTrueOrFalse(data){
        if(data) return "Наявні"
        return "Відсутні"
    }

    const jointPropertyComplexProps =( itemProps )=> {
        return (
            <>
                { checkProp(itemProps,'ownershipType') &&
                    <HalfInfoBlock title='Тип власності' data={ getTranslate( itemProps.landProps.ownershipType, objectOwnershipTypeList )} />
                }
                { checkProp(itemProps.landProps,'landArea') &&
                    <HalfInfoBlock title='Площа ділянки' data={`${ itemProps.landProps.landArea} га`} />
                }
                { checkProp(itemProps.landProps,'cadastralNumber') &&
                    <HalfInfoBlock title='Кадастровий номер' data={ itemProps.landProps.cadastralNumber} />
                }

                { checkProp(itemProps.landProps,'hasEncumbrances') &&
                    <HalfInfoBlock title='Наявність обтяжень' data={hasTrueOrFalse(itemProps.landProps.hasEncumbrances)} />
                }
                { checkProp(itemProps.landProps,'encumbrances') &&
                    <HalfInfoBlock title='Інформація про наявність обтяжень' data={ getTranslate( itemProps.landProps.encumbrances, objectEncumbrancesList )} />
                }
                { checkProp(itemProps.landProps,'hasJointOwnership') &&
                    <HalfInfoBlock title='Наявність співвласників' data={hasTrueOrFalse(itemProps.landProps.hasJointOwnership)} />
                }
                { checkProp(itemProps.landProps,'jointOwnership') &&
                    <HalfInfoBlock title='Інформація про співвласників' data={ itemProps.landProps.jointOwnership} />
                }
                { checkProp(itemProps.landProps,'hasUtilitiesAvailability') &&
                    <HalfInfoBlock title='Наявність інженерних мереж' data={hasTrueOrFalse(itemProps.landProps.hasUtilitiesAvailability)} />
                }
                { checkProp(itemProps.landProps,'utilitiesAvailability') &&
                    <HalfInfoBlock title='Інформація про інженерні мережі' data={ itemProps.landProps.utilitiesAvailability} />
                }
                { checkProp(itemProps.landProps,'landIntendedUse') &&
                    <HalfInfoBlock title='Поточне використання земельної ділянки' data={ itemProps.landProps.landIntendedUse} />
                }
                { checkProp(itemProps,'quantityAndNomenclature') &&
                    <FullInfoBlock title='Обсяг та основна номенклатура продукції (робіт, послуг), у тому числі експортної' data={ itemProps.quantityAndNomenclature} />
                }
                { checkProp(itemProps,'workplacesQuantity') &&
                    <HalfInfoBlock title='Кількість робочих місць' data={`${ itemProps.workplacesQuantity} осіб`} />
                }
                { checkProp(itemProps,'buildingsInformation') &&
                    <FullInfoBlock title='Відомості про будівлі (споруди, приміщення) єдиного майнового комплексу в обсязі, передбаченому для інформації про нерухоме майно' data={ itemProps.buildingsInformation} />
                }
                { checkProp(itemProps,'landPlotInformation') &&
                    <FullInfoBlock title='Відомості про земельну ділянку, на якій розташовано єдиний майновий комплекс' data={ itemProps.landPlotInformation} />
                }
                { checkProp(itemProps,'mainObligations') &&
                    <FullInfoBlock title='Основні зобов’язання (договірні та позадоговірні)' data={ itemProps.mainObligations} />
                }
                { checkProp(itemProps,'leaseApproverWebsite') &&
                    <FullInfoBlock title='Посилання на веб-сторінку орендодавця, на якій розміщена додаткова інформація про єдиний майновий комплекс' data={ itemProps.leaseApproverWebsite} />
                }
            </>
        )
    }

    const landProps =( itemProps )=> {
        return (
            <>
                { checkProp(itemProps,'landArea') &&
                    <HalfInfoBlock title='Площа ділянки' data={`${itemProps.landArea} га`} />
                }
                { checkProp(itemProps,'cadastralNumber') &&
                    <HalfInfoBlock title='Кадастровий номер' data={itemProps.cadastralNumber} />
                }
                { checkProp(itemProps,'ownershipType') &&
                    <HalfInfoBlock title='Тип власності' data={ getTranslate( itemProps.ownershipType, objectOwnershipTypeList ) } />
                }
                
                { checkProp(itemProps,'hasEncumbrances') &&
                    <HalfInfoBlock title='Наявність обтяжень' data={hasTrueOrFalse(itemProps.hasEncumbrances)} />
                }
                { checkProp(itemProps,'encumbrances') &&
                    <HalfInfoBlock title='Інформація про наявність обтяжень' data={ getTranslate( itemProps.encumbrances, objectEncumbrancesList )} />
                }
                { checkProp(itemProps,'hasJointOwnership') &&
                    <HalfInfoBlock title='Наявність співвласників' data={hasTrueOrFalse(itemProps.hasJointOwnership)} />
                }
                { checkProp(itemProps,'jointOwnership') &&
                    <HalfInfoBlock title='Інформація про співвласників' data={ itemProps.jointOwnership} />
                }
                { checkProp(itemProps,'hasUtilitiesAvailability') &&
                    <HalfInfoBlock title='Наявність інженерних мереж' data={hasTrueOrFalse(itemProps.hasUtilitiesAvailability)} />
                }
                { checkProp(itemProps,'utilitiesAvailability') &&
                    <HalfInfoBlock title='Інформація про інженерні мережі' data={ itemProps.utilitiesAvailability} />
                }

                { checkProp(itemProps,'landIntendedUse') &&
                    <HalfInfoBlock title='Поточне використання земельної ділянки' data={itemProps.landIntendedUse} />
                }
            </>
        )
    }

        function setItemContent( classif ){
        let id = classif[0] + classif[1]
        switch( id ){
            case '04': return 'realEstate'
            case '05': return 'jointPropertyComplex'
            case '06': return 'land'
            case '34': return 'vehicle'
            default: return null
        }
    }

    function titleSwitcher(type){
        switch(type){
            case 'vehicle': return 'Технічні характеристики транспортного засобу'
            case 'realEstate': return 'Технічні характеристики нерухомості'
            case 'jointPropertyComplex': return 'Технічні характеристики єдиного майнового комплексу'
            case 'land': return 'Технічні характеристики земельної ділянки'
            default: return null
        }
    }

    function propsSwitcher(type){
        switch(type){
            case 'vehicle': return vehicleProps(item.itemProps)
            case 'realEstate': return realEstateProps(item.itemProps)
            case 'jointPropertyComplex': return jointPropertyComplexProps(item.itemProps)
            case 'land': return landProps(item.itemProps)
            default: return null
        }
    }

    if ( !setItemContent( item.classification.id ) ) return null

    return (
        <PopDownBlock 
            title={ titleSwitcher( setItemContent( item.classification.id )) }
            content={ propsSwitcher( setItemContent( item.classification.id )) }
        />
    )
}

export default AssetItemPropsView