import React, { useState } from 'react';
import styles from '../elems.module.css';
import { useSelector, useDispatch } from 'react-redux';

import FullInfoBlock from '../../../../../elements/fullInfoBlock/fullInfoBlock';
import HalfInfoBlock from '../../../../../elements/halfInfoBlock/halfInfoBlock';
import { dateTransform, isMongo, getAddedTax, getCurrency, getFormatedValue } from '../../../../../../utils/check';
import { getExecutionContractStatus, getExecutionContractDocType } from '../../../utils';
// import PopDownBlock from '../../../../elements/popdownBlock/popDownBlock';
import BuyerEditItem from './buyerEditItem';
import ViewDocItem from '../../../../../elements/documents/view/viewDocumentItem';
import AddDocPopup from '../../../../../elements/documents/addDocPopup/addDocPopupExec';
import CreateDocItem from '../../../../../elements/documents/docItem/documentItemExec';
import Button from '../../../../../elements/buttons/button';
import EditContract from './addEditContract/editBuyer';
import AddContract from './addEditContract/addBuyer';
import UniPopUp from '../../../../../popups/uniPopUp/uniPopUp';

import DeleteIcon from '../../../../../elements/svg/deleteIcon';
//import RedemptionCanceletionPopup from '../cancelationPopUp/canceletionPopupContracts'
import { setRedemptionDocTypes } from './utils';

import { redemptionDocDelete, redemptionDocDeleteContract } from '../../../../../../redux/asset/assetActions';

import {postMyExecutionsDocContract, getMyExecutionsDocContract, getMyExecutionsDocContractHistory} from '../../../../../../utils/endPoints'

const ExecusionContractItem = ( { item, count } ) => {
    const token = useSelector( state => state.start.token )
    const executionItem = useSelector(state => state.asset.executionItem)

    const [ editPopup, setEditPopup ] = useState( false )
    const [ addPopup, setAddPopup ] = useState( false )
    const [ docPopup, setDocPopup ] = useState( false )
    const [ compliteContractPopup, setCompliteContractPopup ] = useState( false )
    const dispatch = useDispatch()

    const docProps = {
        dispatch: ( res )=> dispatch( { payload: res, type: 'EXECUTION_GET_ID'}),
        docOf: 'contract',
        object: executionItem,
        contract: item,
        docList: setRedemptionDocTypes( item.status ),
        url: {
            patch: getMyExecutionsDocContract,
            post: postMyExecutionsDocContract,
            patchContract: getMyExecutionsDocContract,
            postContract: postMyExecutionsDocContract,
            // delete: ( id, contractId=null ) => {
            //     if(contractId === null){
            //         dispatch( redemptionDocDelete( announcementItem.id, id, token.access_token ) )
            //     }else{
            //         dispatch( redemptionDocDeleteContract( announcementItem.id, contractId, id, token.access_token ) )
            //     }
            // },
            history: getMyExecutionsDocContractHistory
        }
    }

    async function setStatus(){
        //dispatch( announcementUpdate( urlContract(data.id, data.contracts[0].id) , JSON.stringify(bodyContract), token.access_token))
        const url = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/executions/${executionItem.id}/contracts/${item.id}/status/active`
        const response = await fetch(url, {
            method: 'PATCH',
            headers: {
                'Authorization': 'Bearer ' + token.access_token,
                'Content-Type': 'application/json'
            }
        }).catch(console.error)
        const json = await response.json();
        if(json.hasOwnProperty("message") !== true){
            await dispatch({type: 'EXECUTION_GET_ID', payload: json})
        }
    }

    function openSetStatus(){
        let tmp = false
        if(item.documents !== null && item.documents.length > 0){
            for(let j = 0; j < item.documents.length; j++){
                if(item.documents[j].documentType === 'additionalAgreement'){
                    tmp = true
                }
            }
        }else{
            tmp = false
        }
        if(item.status === "pending" && tmp === true){
            return true
        }
        return false
    }

    return (
        <div className={styles.contractItem} key={item.id} >
            { compliteContractPopup && <UniPopUp 
                    type={2}
                    data={'Попередній договір буде переведено в статус архівний і робота з ним буде заблокована. Ви впевнені?'} 
                    close={ ()=> setCompliteContractPopup( false ) }
                    action={ ()=> {
                            setStatus()
                            setCompliteContractPopup( false )
                        }
                    }
                /> 
            }
            { docPopup && <AddDocPopup asset={ item } close={ ()=> setDocPopup( false )} docProps={docProps} contractId={item.id}/> }


            { editPopup &&
                <EditContract data={item} setData={ "setData" } index={"index"} close={ ()=> setEditPopup( false ) } />
            }
            { addPopup &&
                <AddContract data={item} setData={ "setData" } index={"index"} close={ ()=> setAddPopup( false ) } />
            }
            <div className={styles.infoBlockTitle}>
                <div className={styles.organizationTitle}></div>
                {item.status === 'active' && (count === executionItem.contracts.length - 1)
                    ? <div style={{width:"30%"}} >
                        <Button onClick={ ()=> setAddPopup( true ) } >
                            Новий договір
                        </Button>
                    </div>
                    : null
                }
                {item.status === 'pending'
                    ? <div>
                        <Button onClick={ ()=> setEditPopup( true ) } >
                            Редагувати
                        </Button>
                    </div>
                    : null
                }
            </div>
            {!!item?.contractNumber &&
                <HalfInfoBlock
                    title='Номер договору'
                    data={ item.contractNumber}
                />
            }
            {!!item?.status &&
                <HalfInfoBlock
                title={'Статус договору'}
                data={ getExecutionContractStatus( item.status ) }
                />
            }
            {!!item?.title &&
                <FullInfoBlock
                    title='Назва договору'
                    data={ isMongo(item.title)}
                />
            }
            {!!item?.description &&
                <FullInfoBlock
                    title='Опис договору'
                    data={ isMongo(item.description)}
                />
            }
            {!!item?.contractTime &&
                <HalfInfoBlock
                    title='Строк виконання умов приватизації'
                    data={ `${dateTransform(item.contractTime.dateFrom)} - ${dateTransform(item.contractTime.dateTill)}`}
                />
            }
            {!!item?.dateSigned &&
                <HalfInfoBlock
                    title={'Дата підписання договору'}
                    data={ dateTransform(item.dateSigned, 'time') }
                />
            }
            {!!item?.value &&
                <HalfInfoBlock
                    title={'Вартість договору'}
                    data={ getFormatedValue(item.value) }
                />
            }
            {!!item?.contractTotalValue &&
                <HalfInfoBlock
                    title={'Заключна вартість договору'}
                    data={ getFormatedValue( item.contractTotalValue ) }
                />
            }
            {!!item?.datePublished &&
                <HalfInfoBlock
                    title={'Дата створення об\'єкту договору'}
                    data={ dateTransform(item.datePublished, 'time') }
                />
            }
            {!!item?.dateModified &&
                <HalfInfoBlock
                title={'Остання дата редагування договору'}
                data={ dateTransform(item.dateModified, 'time') }
                />
            }
            { openSetStatus()
                ? <div className={styles.infoBlockTitle}>
                    <div>
                        <Button onClick={ ()=> setDocPopup( () => {
                            //setStatus()
                            setCompliteContractPopup(true)
                        } ) } >
                            Опублікувати наказ
                        </Button>
                    </div>
                </div>
                : null
            }


            { !!item.buyers && 
                <div className={styles.linedBlock} >
                    <div className={styles.infoBlockTitle}>Покупець</div>
                    { item.buyers.map( i => <BuyerEditItem data={i} key={i.id} editUser={item.status !== 'active' && item.status !== 'cancelled'} contractId={item}/>) }
                </div>
            }
            { item.status !== "cancelled" && item.status !== "active"
                ? <div className={styles.infoBlockTitle}>
                    <div>
                        <Button onClick={ ()=> setDocPopup( true ) } >
                            Додати документи
                        </Button>
                    </div>
                </div>
                : null
            }
            { ( !!item.documents && item.documents.length > 0 ) ? 
                <div className={styles.linedBlock} >
                    <div className={styles.infoBlockTitle}>Документи</div>
                    <div className={styles.docList}>
                        {
                            item.documents.map( i => <CreateDocItem 
                                key={i.id}
                                data={i}
                                docProps={docProps}
                                contractId={item.id}
                                />
                            )
                            
                        }
                    </div>
                </div> : 
                null
            }
        </div>
    )
}

export default ExecusionContractItem