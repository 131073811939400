import React, { useEffect, useState } from 'react';
import styles from './chooseOrganization.module.css';
import { useDispatch, useSelector } from 'react-redux';
import AddOrganizationNEW from '../addOrganizationNEW/addOrganizationNEW';

const ChooseOrganizationItem = ({ item, close, organizationDataPrepare, deleteOrganization, setData }) => {
    const [popupEdit, setPopupEdit] = useState( false )

return (
        <li key={ item.id } >
            <div className={styles.orgName} 
                onClick={() => {
                    organizationDataPrepare( item )
                    // setData(item)
                    close()
                }}
            >
                {item.name}
            </div>
            <div className={styles.buttonWrapper}>
                <button className={styles.button} onClick={() => setPopupEdit(true)}>
                    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M15.861 5.59595L16.9757 4.4812L14.5187 2.02413L13.4039 3.13889L15.861 5.59595ZM14.4542 7.00273L11.9971 4.54566L2.80565 13.7371L2.24014 16.7597L5.26272 16.1942L14.4542 7.00273ZM18.4419 3.13384C19.186 3.87796 19.186 5.08443 18.4419 5.82856L6.45174 17.8187C6.30989 17.9606 6.12847 18.0562 5.93129 18.0931L1.17816 18.9824C0.490832 19.111 -0.111152 18.509 0.0174442 17.8217L0.90674 13.0686C0.943632 12.8714 1.03928 12.69 1.18113 12.5481L13.1713 0.557942C13.9154 -0.186184 15.1219 -0.186184 15.866 0.557942L18.4419 3.13384ZM18.0099 18.9999H9.9901C8.66997 18.9999 8.66997 16.9999 9.9901 16.9999H18.0099C19.33 16.9999 19.33 18.9999 18.0099 18.9999Z" fill="#92929D"/>
                    </svg>
                </button>
            </div>
            <div className={styles.buttonWrapper}>
                
                <button className={styles.button} onClick={() => deleteOrganization(item.id)}>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M5.47975 3.46496L6.11823 1.54955C6.24753 1.16162 6.61057 0.899963 7.01948 0.899963H12.1495C12.5584 0.899963 12.9214 1.16162 13.0507 1.54955L13.6892 3.46496H18.1344C18.6591 3.46496 19.0844 3.89029 19.0844 4.41496C19.0844 4.93964 18.6591 5.36496 18.1344 5.36496H17.3202L16.623 17.2173C16.5344 18.7237 15.287 19.9 13.778 19.9H5.391C3.88199 19.9 2.63453 18.7237 2.54591 17.2173L1.84872 5.36496H1.04961C0.524939 5.36496 0.0996094 4.93964 0.0996094 4.41496C0.0996094 3.89029 0.524939 3.46496 1.04961 3.46496H5.47975ZM7.48253 3.46496H11.6864L11.4648 2.79996H7.7042L7.48253 3.46496ZM15.417 5.36496H3.752L4.44263 17.1058C4.47217 17.6079 4.88799 18 5.391 18H13.778C14.281 18 14.6968 17.6079 14.7263 17.1058L15.417 5.36496ZM11.2013 7.77571C11.2341 7.25206 11.6851 6.85409 12.2087 6.88682C12.7324 6.91954 13.1304 7.37058 13.0976 7.89422L12.6701 14.7342C12.6374 15.2579 12.1864 15.6558 11.6627 15.6231C11.1391 15.5904 10.7411 15.1394 10.7738 14.6157L11.2013 7.77571ZM8.39513 14.6157C8.42785 15.1394 8.02988 15.5904 7.50623 15.6231C6.98259 15.6558 6.53155 15.2579 6.49883 14.7342L6.07132 7.89422C6.0386 7.37058 6.43657 6.91954 6.96022 6.88682C7.48386 6.85409 7.9349 7.25206 7.96763 7.77571L8.39513 14.6157Z" fill="#FFA047"/>
                    </svg>
                </button>
            </div> 
            { popupEdit && <AddOrganizationNEW data={ item } setData={ setData } close={ ()=> setPopupEdit(false) } /> }
        </li> 
    )
}

export default ChooseOrganizationItem