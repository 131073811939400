import React from "react"

const EditIcon =( { color = '#92929D', style, widthHeight = [20, 20] })=> {
    return <svg style={{display:'flex'}}
                width={widthHeight[0]} 
                height={widthHeight[1]} 
                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.33 25.33">
                <g id="Слой_2" data-name="Слой 2">
                    <g id="Слой_1-2" data-name="Слой 1">
                        <path className={style} 
                        fill={color} 
                        fillRule="evenodd" d="M21.15,7.46,22.63,6,19.36,2.7,17.87,4.18ZM19.27,9.34,16,6.06,3.74,18.32l-.75,4,4-.76Zm5.32-5.16a2.54,2.54,0,0,1,0,3.59l-16,16a1.32,1.32,0,0,1-.69.36L1.57,25.31A1.33,1.33,0,0,1,0,23.76l1.19-6.34a1.4,1.4,0,0,1,.36-.69l16-16a2.54,2.54,0,0,1,3.59,0ZM24,25.33H13.32a1.33,1.33,0,0,1,0-2.66H24A1.33,1.33,0,0,1,24,25.33Z"/>
                    </g>
                </g>
            </svg>
    
    
}

export default EditIcon